import React from 'react';

import getVideoId from 'get-video-id';
import Block from '../../common/atoms/Block';
import styled from '@emotion/styled';
import { layout, space } from 'styled-system';
import { Flex } from 'rebass';

const StyledIframe = styled.iframe`
  width: 100%;
  ${props => props.theme.mq.tabletP} {
    width: 400px;
  }
  height: 400px;
  ${layout};
  ${space};
`;

const VideoContainer = styled(Flex)`
  background: ${props => props.theme.colors.background.light};
  justify-content: center;
`;

export const VideoBase = React.memo(({ url, className }) => {
  if (url) {
    const videoData = getVideoId(url);
    const id = videoData.id;
    const service = videoData.service;
    const vimeoEmbedUrl = 'https://player.vimeo.com/video/' + id;
    const youtubeEmbedUrl = 'https://www.youtube.com/embed/' + id;
    if (!id) {
      return <div>Missing YouTube URL</div>;
    }
    if (service === 'vimeo') {
      return (
        <VideoContainer className={className}>
          <StyledIframe
            src={vimeoEmbedUrl}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen="true"
          ></StyledIframe>
        </VideoContainer>
      );
    }
    if (service === 'youtube') {
      return (
        <VideoContainer className={className}>
          <StyledIframe
            src={youtubeEmbedUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="true"
          ></StyledIframe>
        </VideoContainer>
      );
    }
  }
  return null;
});

export default ({ data: { url } }) => {
  return (
    <Block>
      <VideoBase url={url} />
    </Block>
  );
};
