import {
  formatDate,
  fromNativeDate,
  getAge,
  getAgeDuration,
  now,
  toDateOnly,
} from '~lib/dates';
import { open } from '~common/services/modalService';
import { dropEmpties } from '~lib/util';
import { path } from 'lodash/fp';
const cloneDeep = require('lodash/cloneDeep');

export const getDobDate = form => {
  const { dobYear, dobMonth, dobDay } = form.values;
  return fromNativeDate(new Date(dobYear, dobMonth - 1, dobDay));
};

export const openModal = (content, { padding, ...props } = {}) => {
  return open({
    content,
    padding:
      padding === true
        ? {
            xs: '40px 37px',
            tabletP: '40px 60px',
          }
        : padding || 0,
    minWidth: {
      xs: 1,
      tabletP: '575px',
    },
    width: {
      xs: 1,
      tabletP: '575px',
    },
    showCloseIcon: false,
    disableOverlayClick: true,
    centerV: {
      xs: true,
      tabletP: false,
    },
    ...props,
  });
};

export const getPersonAge = form => {
  return getAge(getDobDate(form));
};

export const getPersonAgeDuration = form => {
  return getAgeDuration(getDobDate(form));
};

export const formatDob = form => {
  return formatDate(getDobDate(form));
};

export const getPersonFullName = person => {
  if (!person) {
    return '';
  }
  return `${person.firstName}${
    person.middleName ? ` ${person.middleName}` : ''
  } ${person.lastName}`;
};

export const getPromotionProductType = criteria => {
  criteria = criteria || {};
  if (criteria.hospitalProduct && criteria.extrasProduct) {
    return 'both';
  }

  if (criteria.hospitalProduct) {
    return 'hospital';
  }

  return 'extras';
};

export const hasPartnerIncluded = criteria =>
  ['COUPLE', 'FAMILY'].includes(path('status.value')(criteria));

const includeEntryAge = criteria =>
  criteria?.lhcExempt === false ? criteria.entryAge : undefined;

export const criteriaToQuery = criteria => {
  if (!criteria) {
    return undefined;
  }

  return dropEmpties({
    state: path('region.value')(criteria),
    status: path('status.value')(criteria),
    tier: criteria.tier && Number(criteria.tier.value),
    hospitalProduct: criteria.hospitalProduct,
    extrasProduct: criteria.extrasProduct,
    efc: criteria.efc && criteria.efc.value,
    resultType: criteria.resultType || 'CURRENT',
    dob: criteria.yourDob?.value,
    paymentFrequency: criteria.paymentFrequency,
    startCoverDate: toDateOnly(criteria.startCoverDate),
    entryAge: includeEntryAge(criteria),
    lhcExempt: criteria.lhcExempt,
    partner:
      hasPartnerIncluded(criteria) &&
      ((criteria.partner && criteria.partnerDob) || criteria.partnerDob)
        ? dropEmpties({
            dob: path('partnerDob.value')(criteria),
            ...criteria.partner,
            entryAge: includeEntryAge(criteria?.partner),
          })
        : undefined,
  });
};

export const dropTypename = object => {
  const clonedObject = cloneDeep(object);

  (function remove(targetObject) {
    if (targetObject.__typename) {
      delete targetObject.__typename;
    }

    Object.values(targetObject).forEach(value => {
      if (value && typeof value === 'object') {
        remove(value);
      }
    });
  })(clonedObject);

  return clonedObject;
};

export const serializeMaf = async ({ formId, steps, criteria }) => {
  const stepsPayload = {};
  for (const step of steps) {
    let result = step.context.serialize();
    if (!result instanceof Promise && result != null) {
      result = Promise.resolve(result);
    }
    if (result) {
      stepsPayload[step.value] = dropEmpties(await result);
    }
  }

  const quoteInput = criteriaToQuery(criteria);

  quoteInput.entryAge =
    stepsPayload.yourDetails?.personalDetails?.firstHospitalCoverAge;

  return dropTypename(
    dropEmpties({
      id: formId,
      quoteInput,
      form: stepsPayload,
    })
  );
};

export const is31OrUnder = date => {
  const age = getAge(date);
  const currentMonth = now().get('month') + 1;
  return (age === 31 && currentMonth < 7) || age < 31;
};

export const whenNull = (value1, value2) => {
  if (value1 == null) {
    return value2 == null ? null : value2;
  }
  return value1;
};

export const isPartnerTransferringFromAnotherFund =
  privateHealthDetailsForm => {
    return whenNull(
      privateHealthDetailsForm.values
        .partnerAndDependentsTransferringFromAnotherFund,
      null
    );
  };

export const canDependantUseMembersHealthFund = privateHealthDetailsForm => {
  return !!(
    privateHealthDetailsForm.values.isTransferringFromAnotherFund &&
    privateHealthDetailsForm.values
      .partnerAndDependentsTransferringFromAnotherFund
  );
};

export const isProfessionEligible = (membershipOption, subMembershipOption) => {
  // Check if the person's profession matches the criteria
  if (
    membershipOption === 'StudyingMedicineorHealth' ||
    subMembershipOption === 'Intern' ||
    subMembershipOption === 'Resident'
  ) {
    return false;
  }
  return true;
};
