import { useEffect, useRef } from 'react';
import { noop } from '~lib/util';
import { debounce } from 'lodash';
import useEventListener from '~lib/hooks/useEventListener';

const asUnit = value => (typeof value === 'string' ? value : `${value}px`);

export default ({
  distanceFromTop = 20,
  type = 'top',
  animate = true,
  offset = 0,
  fromTop,
  onSticky = noop,
  onBackToNormal = noop,
  onScroll: onScrollHook = noop,
  ignore,
} = {}) => {
  const ref = useRef(null);
  const initial = useRef();

  const backToInitialPosition = debounce(() => {
    if (ref?.current?.style) {
      ref.current.style.position = initial?.current?.position;
      ref.current.style.top = initial?.current?.top;
      ref.current.style.bottom = initial?.current?.bottom;
      onBackToNormal(ref?.current);
    }
    if (initial?.current) {
      initial.current.scrollY = undefined;
      initial.current.prevScrollY = undefined;
    }
  }, 0);

  const onScroll = () => {
    if (!ref?.current?.style) {
      return;
    }

    if (ignore) {
      backToInitialPosition();
      return;
    }

    const rect = ref.current?.getBoundingClientRect();

    if (!rect) {
      return;
    }

    ref.current.style.zIndex = 999;
    if (!initial?.current) {
      initial.current = {
        position: ref.current.style?.position,
        top: ref.current.style?.top,
        bottom: ref.current.style?.bottom,
        scrollY: undefined,
        prevScrollY: undefined,
        rect: {
          top: rect.top,
          bottom: rect.bottom,
          x: rect.x,
          y: rect.y,
          height: rect.height,
          width: rect.width,
        },
      };
    }

    if (
      initial?.current?.prevScrollY === undefined && initial?.current?.scrollY
        ? window.scrollY + (ref?.current?.clientHeight || 0) + 5 <
          initial?.current?.scrollY
        : window.scrollY < (initial?.current?.scrollY || 0)
    ) {
      backToInitialPosition();

      return;
    }

    initial.current.prevScrollY = initial?.current?.scrollY;

    // TODO: ADD SUPPORT FOR BOTTOM STICKING
    if (
      rect.top < 0 ||
      (rect.top > distanceFromTop && initial?.current?.scrollY != null)
    ) {
      if (initial?.current?.scrollY == null) {
        initial.current.scrollY = window.scrollY;
      }
      if (animate) {
        ref.current.style.transition = 'all 200ms ease-out';
      }
      ref.current.style.position = 'fixed';

      const newTopValue = fromTop == null ? window.scrollY + offset : fromTop;

      if (rect.height + newTopValue > document.body.clientHeight) {
        return;
      }

      ref.current.style.top = asUnit(newTopValue);
      ref.current.style.width =
        ref.current.style.width || `${initial?.current?.rect?.width}px`;
      const stickyHandlerResult = ref.current ? onSticky(ref.current) : false;
      if (stickyHandlerResult === false) {
        backToInitialPosition();
      }
    }

    onScrollHook(ref.current, rect);
  };

  useEffect(() => {
    onScroll();
  }, []);

  useEventListener('scroll', onScroll);

  return ref;
};
