import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledBlock = styled.section`
  counter-reset: section;
  background-color: ${props => props.theme.colors.lightNeutral50};
  padding: 64px 0;

  ${props => props.theme.mq.sm} {
    padding: 100px 0;
  }
`;

const StyledContainer = styled.div`
  padding-left: ${props => props.theme.sizes.gap};
  padding-right: ${props => props.theme.sizes.gap};
  max-width: ${props => props.theme.sizes.container};
  width: 100%;
  margin: 0 auto;

  h3 {
    &:not(:first-of-type) {
      margin-top: 64px;

      ${props => props.theme.mq.sm} {
        margin-top: 100px;
      }
    }
  }
`;

const Section = ({ children }) => {
  return (
    <StyledBlock>
      <StyledContainer>{children}</StyledContainer>
    </StyledBlock>
  );
};

Section.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default Section;
