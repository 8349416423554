import { useState, useEffect, useRef } from 'react';
import useReactiveRef from './useRectiveRef';
import ResizeObserver from 'resize-observer-polyfill';

const useClientRect = ({ onChange }) => {
  const { ref, element } = useReactiveRef();
  const [clientRect, setClientRect] = useState();

  const observerRef = useRef(
    new ResizeObserver(changes => {
      const clientRect = changes[0].target.getBoundingClientRect();
      const computedStyled = window?.getComputedStyle(changes[0].target);

      //TODO: calculate top and bottom as well
      const leftSpacing =
        (parseInt(computedStyled['padding-left']) || 0) +
        (parseInt(computedStyled['margin-left']) || 0);

      const recalculatedRect = {
        top: clientRect.top,
        y: clientRect.y,
        right: clientRect.right,
        bottom: clientRect.bottom,
        x: clientRect.x + leftSpacing,
        left: clientRect.left + leftSpacing,
      };

      setClientRect({
        ...recalculatedRect,
        ...(typeof onChange === 'function' ? onChange(clientRect) : undefined),
      });
    })
  );

  useEffect(() => {
    if (element) {
      observerRef.current.disconnect();
      observerRef.current.observe(element, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }

    const observer = observerRef?.current;

    return () => {
      observer?.disconnect();
    };
  }, [element]);

  return {
    ref,
    clientRect,
  };
};

export default useClientRect;
