import React from 'react';
import LightBox from '../atoms/LightBox';
import styled from '@emotion/styled';
import { css, Global } from '@emotion/core';
import { useMobileScreen } from '~lib/responsive';

const StyledModal = styled(LightBox)`
  max-height: 100%;
  max-width: 100%;
  box-shadow: 8px 0px 6px -6px rgba(0, 0, 0, 0.5);
`;

const WorkingModal = ({
  visible,
  children,
  onClose,
  title,
  height,
  width,
  mobileHeight,
  mobileWidth,
  ...props
}) => {
  const isMobile = useMobileScreen();

  const dimensions = isMobile
    ? {
        width: mobileWidth || '100%',
        height: mobileHeight || 'auto',
      }
    : {
        width: width || '640px',
        height: height || '568px',
      };

  return (
    <>
      {visible && (
        <Global
          styles={css`
            #___gatsby {
              filter: blur(1px);
            }
          `}
        />
      )}
      <StyledModal
        animation="moveInFromTop"
        visible={visible}
        onClose={onClose}
        {...dimensions}
        {...props}
        title={title}
      >
        {children}
      </StyledModal>
    </>
  );
};

export default WorkingModal;
