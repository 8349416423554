import useChangeCover from '~OMS/my-cover/hooks/useChangeCover';
import { find, map, path, pipe, whereEq } from 'lodash/fp';
import { pathOr } from '~lib/util';
import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { MyCoverMembershipDetails } from 'queries/oms/mycover.graphql';
import {
  getExtrasProduct,
  getHospitalProduct,
  getSortedPersons,
  isChangeCoverAllowed,
  isExtrasOnly,
  isHospitalOnly,
  isS2IMembership,
  isSubsidisedExtrasMembership,
  isPartiallySubsidisedExtras,
  getPartiallySubsidisedSavingAmount,
  isFullySubsidisedExtras,
} from '~OMS/response-selectors';
import useCoverMenu from '~OMS/my-cover/hooks/useCoverMenu';

const getCurrentPlanInclusions = type => (products, plan) => {
  return pipe(
    path(['coverProducts', 'products', type]),
    find(
      whereEq({
        id: path(`${type}Product.id`)(plan),
      })
    ),
    pathOr([], 'inclusions'),
    map(node => ({
      ...node,
      tier: node.inclusion.tier || 'other',
    }))
  )(products);
};

const getCurrentHospitalInclusions = getCurrentPlanInclusions('hospital');
const getCurrentExtrasInclusions = getCurrentPlanInclusions('extras');

export default () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const myCoverDetails = useQuery(MyCoverMembershipDetails);
  const subNavItems = useCoverMenu();

  const changeCoverData = useChangeCover({
    disableProductValidation: true,
    queryOnlyMembershipRates: true,
  });
  const sortedPersons = useMemo(() => {
    if (!myCoverDetails) {
      return undefined;
    }

    return getSortedPersons(myCoverDetails?.data);
  }, [myCoverDetails]);

  const plan = pathOr({}, 'data.oms.membership.plan')(myCoverDetails);

  const hospital = useMemo(() => {
    return getCurrentHospitalInclusions(changeCoverData, plan);
  }, [changeCoverData, plan]);

  const extras = useMemo(() => {
    return getCurrentExtrasInclusions(changeCoverData, plan);
  }, [changeCoverData, plan]);

  if (!myCoverDetails?.data) {
    return {
      state: {
        error: myCoverDetails?.error,
        loading:
          myCoverDetails?.loading ||
          (changeCoverData && changeCoverData?.loading),
      },
      actions: {},
    };
  }

  const promoExtrasProduct =
    path('coverProducts.selections.extras.product')(changeCoverData) || [];

  const promoHospitalProduct =
    path('coverProducts.selections.hospital.product')(changeCoverData) || [];

  const coverStatus = pathOr('', 'cover.status.value')(changeCoverData);

  const excess = path('coverProducts.excess')(changeCoverData);

  const paymentFrequencyLabel = {
    WEEKLY: '/ wk',
    MONTHLY: '/ mo',
    QUARTERLY: '/ qtr',
    HALF_YEARLY: '/ 6mo',
    YEARLY: '/ yr',
  }[plan.paymentFrequency];

  const s2iMembership = isS2IMembership(myCoverDetails?.data);

  return {
    state: {
      excessLoading: path('coverProducts.quote.loading')(changeCoverData),
      loading: myCoverDetails?.loading,
      myCoverDetails: myCoverDetails?.data,
      persons: sortedPersons,
      error: myCoverDetails?.error,
      paymentFrequencyLabel,
      hospital,
      extras,
      coverStatus,
      promoExtrasProduct,
      promoHospitalProduct,
      activeIndex,
      excess,
      showExtrasPromoCard: isHospitalOnly(myCoverDetails?.data),
      showHospitalPromoCard: isExtrasOnly(myCoverDetails?.data),
      extrasOnly: isExtrasOnly(myCoverDetails?.data),
      hospitalOnly: isHospitalOnly(myCoverDetails?.data),
      subNavItems,
      s2iMembership,
      extrasProduct: getExtrasProduct(myCoverDetails.data),
      hospitalProduct: {
        ...getHospitalProduct(myCoverDetails.data),
        gapCoverage:
          changeCoverData?.coverProducts?.selections?.hospital?.product
            ?.gapCoverage,
      },
      isSubsidisedExtras: isSubsidisedExtrasMembership(myCoverDetails?.data),
      isChangeCoverAllowed: isChangeCoverAllowed(myCoverDetails?.data),
      isPartiallySubsidised: isPartiallySubsidisedExtras(myCoverDetails?.data),
      isFullySubsidised: isFullySubsidisedExtras(myCoverDetails?.data),
      getPartiallySubsidisedSavingAmount: getPartiallySubsidisedSavingAmount(
        myCoverDetails?.data
      ),
    },
    actions: {
      setActiveIndex,
      refetch: myCoverDetails?.refetch,
    },
  };
};
