import React from 'react';
import { Box } from 'rebass';
import RebateApplicationSection from './RebateApplicationSection';
import CheckBoxControl from '~common/molecules/form-controls/CheckBoxControl';
import { formatDate, now } from '~lib/dates';
import styled from '@emotion/styled';
import AppFormLabel from '~common/molecules/FormLabel';
import Link from '~common/atoms/Link';

const StyledCheckBoxControl = styled(CheckBoxControl)`
  &&&& .control-wrapper {
    align-items: flex-start;
  }
`;

const Declaration = ({ FormField, handleChange }) => {
  return (
    <RebateApplicationSection title="Declaration">
      {() => (
        <>
          <FormField
            onChange={handleChange}
            labelPosition="right"
            component={StyledCheckBoxControl}
            label={({ LabelContainer }) => (
              <Box>
                <LabelContainer>
                  I declare that the information I have provided in this form is
                  complete and correct. I understand that giving false or
                  misleading information is a serious offence.
                </LabelContainer>
              </Box>
            )}
            name="declarationAccepted"
          />
          <AppFormLabel mb="20px" ml={28}>
            Date of declaration: <strong>{formatDate(now())}</strong>
          </AppFormLabel>
          <AppFormLabel>
            The privacy and security of your personal information is important
            to us, and is protected by law. We need to collect this information
            so we can process and manage your applications and payments, and
            provide services to you. We only share your information with other
            parties where you have agreed, or where law allows or requires it.
            For more information, go to{' '}
            <Link
              external
              to="https://www.servicesaustralia.gov.au/your-right-to-privacy"
            >
              Your right to privacy - Services Australia
            </Link>
          </AppFormLabel>
        </>
      )}
    </RebateApplicationSection>
  );
};

export default Declaration;
