import { TextResponsive } from '~common/atoms/typography';
import { Field, Formik } from 'formik';
import validateForm from '~OMS/payments/organisms/ProcessPaymentForm/validation';
import CenterBox from '~common/atoms/CenterBox';
import { PAYMENT_MODES } from '~OMS/payments/constants';
import {
  PaymentAmountControl,
  PaymentDateControl,
} from '~OMS/payments/molecules/PaymentChecks';
import {
  ButtonSwitchControl,
  ExpiryDateControl,
  InputControl,
} from '~common/molecules/form-controls';
import CardNumberControl from '~OMS/payments/molecules/CardNumberControl';
import CvvField from '~OMS/payments/molecules/CvvField';
import ConfirmButton from '~OMS/payments/atoms/ConfirmButton';
import { Button } from '~common/atoms/Button';
import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import OneOffPaymentTitle from '~OMS/payments/atoms/OneOffPaymentTitle';
import { Alert } from '~common/molecules/AlertBox';
import { Element } from 'react-scroll';
import { scrollTo } from '~lib/util';

const paymentTypes = [
  {
    id: PAYMENT_MODES.DATE,
    text: 'Date',
  },
  {
    id: PAYMENT_MODES.AMOUNT,
    text: 'Amount',
  },
];

const initialForm = {
  paymentMode: PAYMENT_MODES.AMOUNT,
  amount: '',
  date: undefined,
  cardNumber: '',
  cvv: '',
  expiry: '',
  cardHolder: '',
};

const StyledPaymentModeSwitcher = styled(ButtonSwitchControl)`
  &&& {
    margin-bottom: 32px;
  }
  .control-content {
    display: flex;
    justify-content: center;
  }
`;

const OneOffPaymentForm = ({
  onSubmit,
  isFormShown,
  toggleForm = false,
  loading,
  error,
  coveredUntil,
  paymentChecks,
}) => {
  const [isValidating, setValidating] = useState(false);
  const focusToFirstInput = () => {
    if (formRef.current) {
      const amountInput = formRef.current.querySelector('[name=amount]');
      if (amountInput) {
        amountInput.focus();
      }
    }
  };

  useEffect(() => {
    if (isFormShown) {
      scrollTo('form', {
        offset: -120,
      });
      focusToFirstInput();
    }
  }, [isFormShown]);

  const formRef = useRef(null);

  return (
    <>
      <OneOffPaymentTitle mb={3} />
      <TextResponsive color="light" mb={4}>
        Pay up until a date, or enter your own amount.
      </TextResponsive>

      {isFormShown ? (
        <Formik
          onSubmit={onSubmit}
          initialValues={initialForm}
          validate={values => {
            setValidating(true);
            return validateForm(paymentChecks)(values).finally(() => {
              setValidating(false);
            });
          }}
        >
          {({ handleChange, handleSubmit, isValid, values, ...others }) => {
            return (
              <form
                ref={formRef}
                onSubmit={handleSubmit}
                onChange={form => {
                  handleChange(form);
                }}
                className="full-width"
              >
                <Element name="form">
                  <Field
                    name="paymentMode"
                    render={props => (
                      <CenterBox>
                        <StyledPaymentModeSwitcher
                          {...props}
                          width={236}
                          buttons={paymentTypes}
                        />
                      </CenterBox>
                    )}
                  />

                  <Element name="paymentDateAmount">
                    {values.paymentMode === PAYMENT_MODES.AMOUNT ? (
                      <Field
                        component={PaymentAmountControl}
                        name="amount"
                        label="Payment amount"
                        labelPosition="left"
                      />
                    ) : (
                      <Field
                        render={props => (
                          <PaymentDateControl
                            {...props}
                            labelPosition="left"
                            label="Payment date"
                            coveredUntil={coveredUntil}
                          />
                        )}
                        name="date"
                      />
                    )}
                  </Element>

                  <Field
                    name="cardHolder"
                    label="Name on card"
                    labelPosition="left"
                    component={InputControl}
                  />
                  <Field
                    component={CardNumberControl}
                    name="cardNumber"
                    label="Card number"
                    labelPosition="left"
                  />
                  <CvvField cardNumber={values.cardNumber} />
                  <Field
                    name="expiry"
                    label="Expiry date"
                    labelPosition="left"
                    component={ExpiryDateControl}
                  />

                  {error && (
                    <Alert
                      alignItems="flex-start"
                      mb={4}
                      type={error.type || 'error'}
                      title={
                        error.title ||
                        (error.title !== false && 'Payment declined')
                      }
                    >
                      {error.message}
                    </Alert>
                  )}

                  <CenterBox>
                    {!error && (
                      <ConfirmButton
                        data-id="process-payment-btn"
                        loading={loading}
                        disabled={!isValid || isValidating}
                      >
                        Process payment
                      </ConfirmButton>
                    )}
                  </CenterBox>
                </Element>
              </form>
            );
          }}
        </Formik>
      ) : (
        <Button
          data-id="open-payment-form"
          width={233}
          variant="secondary"
          onClick={toggleForm}
        >
          Pay now
        </Button>
      )}
    </>
  );
};

export default OneOffPaymentForm;
