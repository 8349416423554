import React, { useEffect, useMemo } from 'react';
import { getAge } from '~lib/dates';
import DobQuestion from './DobQuestion';
import { getDob, GetField } from './DateOfBirthUtils';
import { scrollTo } from '~lib/util';
import { useMobileScreenOnly } from '~lib/responsive';

const DobForm = ({
  values,
  subtitle,
  title,
  errors,
  inputName,
  alertName,
  discountMessage,
  name,
  hasPartner,
  ...props
}) => {
  const isMobile = useMobileScreenOnly();
  const getField = GetField(inputName);

  const dayValue = getField(values, 'Day');
  const monthValue = getField(values, 'Month');
  const yearValue = getField(values, 'Year');
  const dayError = getField(errors, 'Day');
  const monthError = getField(errors, 'Month');
  const yearError = getField(errors, 'Year');

  const age = useMemo(() => {
    if (dayError || monthError || yearError) {
      return undefined;
    }
    const dob = getDob(values, inputName);
    return getAge(dob);
    //eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [dayValue, monthValue, yearValue, dayError, monthError, yearError]);

  useEffect(() => {
    if (!yearError && yearValue) {
      if (isMobile) {
        scrollTo(
          inputName === 'yourDob' && hasPartner ? 'partnerDob' : 'nav-buttons',
          {
            offset: -200,
          }
        );
      }
    }
  }, [yearError, yearValue, inputName, hasPartner, isMobile]);

  return (
    <DobQuestion
      alertName={alertName}
      age={age}
      subtitle={subtitle}
      name={name}
      inputName={inputName}
      title={title}
      discountMessage={discountMessage}
      {...props}
    />
  );
};

export default DobForm;
