import React from 'react';
import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';
import { Flex, Box, Text } from 'rebass';
import moment from 'moment';

import useTimedLogout from '../hooks/useTimedLogout';
import { Button } from '~common/atoms/Button';

const StyledTimedLogout = styled.div`
  position: fixed;
  top: 2px;
  left: 0;
  right: 0;
  width: calc(100% - 8px);
  margin: 0 auto;
  max-width: 1220px;
  min-height: 50px;
  border-radius: 6px;
  line-height: 20px;
  display: ${props => (props.isVisible ? 'block' : 'none')};
  background: ${props => props.theme.colors.background.dark};
  z-index: ${props => props.zIndex.timedLogout};
`;

const TimedLogout = ({ theme }) => {
  const { state, resetTimer } = useTimedLogout();

  return (
    <StyledTimedLogout {...theme} isVisible={state.isVisible}>
      <Flex justifyContent="center" flexWrap="wrap" alignItems="center">
        <Box px={17} py={10} width={{ xs: 1, sm: 'auto' }}>
          <Text textAlign={{ xs: 'center', sm: 'left' }}>
            Logging you out in{' '}
            {moment.utc(state.counter * 1000).format('mm:ss')}.
          </Text>
        </Box>
        <Flex
          justifyContent="center"
          px={17}
          py={10}
          width={{ xs: 1, sm: 'auto' }}
        >
          <Button variant="primaryInverted" onClick={resetTimer}>
            Keep me logged in
          </Button>
        </Flex>
      </Flex>
    </StyledTimedLogout>
  );
};

export default withTheme(TimedLogout);
