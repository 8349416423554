import React from 'react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';
import { css } from '@emotion/core';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import Inclusions from '~lib/CoverProducts/Inclusions';
import { noop } from '~lib/util';
import InclusionsToggler from '~lib/CoverProducts/InclusionsToggler';
import { ProductDetail, ProductTabs } from '~lib/CoverProducts/index';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: 0%;
`;

const ProductBrowser = props => {
  const ref = React.useRef(null);
  const [isSticky, setSticky] = React.useState(false);
  const [inclusionWidth, setInclusionWidth] = React.useState(0);
  React.useEffect(() => {
    const el = ref.current;

    const scrollHandler = debounce(() => {
      const rect = el.getBoundingClientRect();
      setSticky(rect.top <= 100 && rect.top >= -2800);
    }, 0);

    if (el) {
      setInclusionWidth(el.clientWidth);

      const rect = el.getBoundingClientRect();
      if (rect.top <= 100 && rect.top >= -2800 && props.open) {
        props.setHidden(true);
      } else {
        props.setHidden(false);
      }

      window.removeEventListener('scroll', scrollHandler);
      window.addEventListener('scroll', scrollHandler);
    }
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [inclusionWidth, isSticky, props, ref]);

  return (
    <Container className="product-browser">
      <ProductTabs
        products={props.products}
        paymentFrequency={props.paymentFrequency}
        selected={props.selectedProduct}
        current={props.currentProduct}
        onChange={props.onChange}
        type={props.type}
      />
      {
        <Flex
          flexDirection="column"
          css={css`
            height: 100%;
          `}
        >
          <ProductDetail
            price={props.price}
            paymentFrequency={props.paymentFrequency}
            status={props.status}
            product={props.selected}
            products={props.products}
            variant={props.selectedVariant}
            onChange={props.onChange}
          />
          <div ref={ref}>
            <InclusionsToggler
              type={props?.selected?.type}
              open={props.open}
              onInclusionsToggle={props.onInclusionsToggle}
              isSticky={isSticky}
              inclusionWidth={inclusionWidth}
            >
              {() => (
                <Inclusions
                  type={props?.selected?.type}
                  product={props.selected}
                  products={props.products.filter(
                    p => p.variants.filter(v => v.onSale).length
                  )}
                  isSticky={isSticky}
                  onProductChange={props.onChange}
                  inclusionWidth={inclusionWidth}
                />
              )}
            </InclusionsToggler>
          </div>
        </Flex>
      }
    </Container>
  );
};

ProductBrowser.propTypes = {
  onChange: PropTypes.func.isRequired,
  open: PropTypes.any,
  paymentFrequency: PropTypes.any,
  products: PropTypes.any,
  selected: PropTypes.any,
  selectedProduct: PropTypes.any,
  currentProduct: PropTypes.any,
  selectedVariant: PropTypes.any,
  setHidden: PropTypes.func,
  status: PropTypes.any.isRequired,
  onInclusionsToggle: PropTypes.func.isRequired,
  type: PropTypes.any.isRequired,
  price: PropTypes.any.isRequired,
};

ProductBrowser.defaultProps = {
  setHidden: noop,
};

export default ProductBrowser;
