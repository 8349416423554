import React, { useEffect, useMemo, useRef, useState } from 'react';
import { PaymentFingerprintQuery } from 'queries/oms/payments.graphql';
import { useLazyQuery, useQuery } from '@apollo/client';
import useToggle from '~lib/hooks/useToggle';
import sentry, { addBreadCrumb } from '~lib/sentry';
import { dropEmpties } from '~lib/util';
import { PAYMENT_MODES, PAYMENT_RESULT_CODES } from '~OMS/payments/constants';
import { pipe, path } from 'lodash/fp';
import {
  describeErrorCode,
  UndetectedPaymentResultMessage,
} from './paymentErrorMessages';
import {
  BasicPaymentDetails,
  ValidatePaymentFingerprint,
} from 'queries/oms/payments.graphql';
import {
  amountTextToNumber,
  sanitizeCardNumber,
} from '~OMS/payments/paymentUtils';
import usePaymentChecksContext from '~OMS/payments/hooks/usePaymentChecksContext';
import { scrollTo } from '~lib/util';
import { submitNabPayment } from '~common/services/nabService';
import { UnexpectedNabResponseError } from '~common/errors';

const getRedirectUrl = () => `${window.location.origin}/oms/payments/`;

const getIp = ip => {
  // for local environment the API sends ::ffff:127.0.0.1 as a local address which represents valid transitional notation of expressing ipv4 in ipv6
  // NAB does not recognize it so running the additional parsing
  // TODO: remove this function once it is handled on API
  if (process.env.NODE_ENV !== 'development') {
    return ip;
  }
  console.log('IP from fingerprint', ip);
  let splitIndex = ip.lastIndexOf(':');
  splitIndex = splitIndex === -1 ? 0 : splitIndex + 1;
  return ip.slice(splitIndex);
};

const getPayload = (form, additionalServerGeneratedDetails) => {
  const { cardNumber, cvv, expiry } = form;
  const {
    merchantId,
    timestamp,
    transactionType,
    reference,
    ip,
    fingerprint,
    callback,
    callbackHash,
    personId,
    membershipId,
    amount,
    name,
  } = additionalServerGeneratedDetails;

  const [expiryMonth, expiryYear] = expiry.split('/');
  return dropEmpties({
    EPS_MERCHANT: merchantId,
    EPS_TXNTYPE: transactionType,
    EPS_AMOUNT: amount,
    amount: amount,
    EPS_REFERENCEID: reference,
    EPS_TIMESTAMP: timestamp,
    EPS_FINGERPRINT: fingerprint,
    EPS_CARDNUMBER: sanitizeCardNumber(cardNumber),
    EPS_EXPIRYMONTH: expiryMonth,
    EPS_EXPIRYYEAR: expiryYear,
    EPS_CCV: cvv,
    EPS_RESULTURL: getRedirectUrl(),
    EPS_IP: getIp(ip),
    EPS_REDIRECT: 'TRUE',
    EPS_CALLBACKURL: callback,
    EPS_CALLBACKPARAMS: callback ? 'TRUE' : 'FALSE',
    personId,
    membershipId,
    callbackHash,
    name,
  });
};

export default () => {
  const paymentChecks = usePaymentChecksContext();
  const [isFormShown, toggle] = useToggle();
  const [paymentData, setPaymentData] = useState();
  const [loading, setLoading] = useState(false);
  const fingerPrintDetailsRef = useRef();
  const [paymentAmount, setPaymentAmount] = useState();
  const [
    validateFingerprint,
    {
      data: isFingerprintValidData,
      loading: validatingFingerprint,
      error: fingerprintValidationFailure,
    },
  ] = useLazyQuery(ValidatePaymentFingerprint);

  const {
    data: basicPaymentDetails,
    loading: paymentDetailsLoading,
    refetch: refetchPaymentDetails,
  } = useQuery(BasicPaymentDetails);

  const formRef = useRef(null);

  const refetchPaymentDetailsAfter = ms => {
    setTimeout(() => {
      refetchPaymentDetails();
    }, ms);
  };

  const setError = () => {
    const alert = {
      message: <UndetectedPaymentResultMessage />,
      title: false,
      type: 'warning',
    };
    setPaymentData({
      summarycode: PAYMENT_RESULT_CODES.UKNOWN,
      ...alert,
    });
  };
  const [getFingerprint] = useLazyQuery(PaymentFingerprintQuery, {
    fetchPolicy: 'no-cache',
    onCompleted: pipe(path('oms.paymentFingerprint'), details => {
      fingerPrintDetailsRef.current = details;
      return requestTransaction(formRef.current, details);
    }),
  });

  const isPaymentFingerprintValid = useMemo(
    () => path('oms.validatePaymentFingerprint')(isFingerprintValidData),
    [isFingerprintValidData]
  );

  useEffect(() => {
    if (!path('fingerprint')(paymentData)) {
      return;
    }

    validateFingerprint({
      variables: {
        input: {
          fingerprint: paymentData.fingerprint,
          reference: paymentData.refid,
          summaryCode: Number(paymentData.summarycode),
          timestamp: paymentData.timestamp,
          amount: fingerPrintDetailsRef.current.amount,
          callbackHash: fingerPrintDetailsRef.current.callbackHash,
          name: fingerPrintDetailsRef.current.name,
        },
      },
    });
  }, [paymentData, validateFingerprint]);

  const requestTransaction = (form, additionalTransactionRequestDetails) => {
    const payload = getPayload(form, additionalTransactionRequestDetails);

    console.log('SENDING PAYMENT REQUEST', payload);
    addBreadCrumb({
      category: 'ONE_OFF_PAYMENT',
      message: 'Submitting NAB payment',
      data: {
        payload,
      },
    });

    return submitNabPayment(payload)
      .then(setPaymentData)
      .then(() => refetchPaymentDetailsAfter(100))
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        console.error('submitNabPayment failed', error);
        if (error instanceof UnexpectedNabResponseError) {
          refetchPaymentDetailsAfter(5000);
        }
        setLoading(false);
        setError();
        sentry(error);
      });
  };

  const getAmount = async form => {
    const amountPromise =
      form.paymentMode === PAYMENT_MODES.DATE
        ? paymentChecks.amount.actions.check(form.date)
        : Promise.resolve({
            amount: form.amount,
          });

    const { amount, isBellowMinimumAmount } = await amountPromise;

    if (isBellowMinimumAmount) {
      //eslint-disable-next-line no-throw-literal
      throw {
        code: 'MINIMUM_PAYMENT_AMOUNT_REACHED',
      };
    }

    const { isMaximumPeriodExceeded } = await paymentChecks.date.actions.check(
      amount
    );

    if (isMaximumPeriodExceeded) {
      //eslint-disable-next-line no-throw-literal
      throw {
        code: 'MAXIMUM_PAYMENT_DATE_EXCEEDED',
      };
    }

    return amount;
  };

  const submitPayment = async form => {
    setLoading(true);
    setPaymentData(undefined);
    formRef.current = form;
    try {
      const amount = await getAmount(form);
      //as per https://airteam.atlassian.net/browse/DWM-712 it seems NAB does not respond with payment amount in success response on production
      //therefore storing the payment amount on the state so it can be accessed after successful payment
      setPaymentAmount(amount);
      getFingerprint({
        variables: {
          input: {
            amount: amountTextToNumber(amount),
            name: form.cardHolder,
          },
        },
      });
    } catch (error) {
      setLoading(false);
      scrollTo('paymentDateAmount', {
        timeout: 0,
        duration: 500,
      });
      console.error(error);
    }
  };

  const parsedPaymentData = useMemo(() => {
    if (!paymentData) {
      return undefined;
    }

    return {
      code: paymentData.summarycode,
      message: paymentData.restext,
      ...formRef.current,
      ...paymentData,
    };
  }, [paymentData]);

  const error = useMemo(() => {
    if (!parsedPaymentData) {
      return undefined;
    }

    if (
      parsedPaymentData.code === PAYMENT_RESULT_CODES.UKNOWN &&
      parsedPaymentData.rescode !== '515'
    ) {
      return {
        message: parsedPaymentData.message,
        title: parsedPaymentData.title,
        type: parsedPaymentData.type,
      };
    }

    if (fingerprintValidationFailure) {
      return {
        message: `The unexpected error happened while verifying the payment response ${fingerprintValidationFailure.message}`,
      };
    }

    if (validatingFingerprint || !isFingerprintValidData) {
      return undefined;
    }

    if (!isPaymentFingerprintValid) {
      return {
        message: `We were not able to verify the sender of the payment response.`,
      };
    }

    const errorData =
      parsedPaymentData.code !== PAYMENT_RESULT_CODES.APPROVED
        ? {
            code: parsedPaymentData.code,
            ...describeErrorCode(
              parsedPaymentData.rescode,
              parsedPaymentData.message
            ),
          }
        : undefined;

    // Ignore backend callback errors, these are handled in the backend.
    return errorData;
    //eslint-disable-next-line
  }, [
    fingerprintValidationFailure,
    isFingerprintValidData,
    parsedPaymentData,
    validatingFingerprint,
  ]);

  const success = useMemo(() => {
    return (
      parsedPaymentData &&
      !fingerprintValidationFailure &&
      isPaymentFingerprintValid &&
      parsedPaymentData.code === PAYMENT_RESULT_CODES.APPROVED &&
      !error
    );
  }, [
    parsedPaymentData,
    fingerprintValidationFailure,
    isPaymentFingerprintValid,
    error,
  ]);

  return {
    state: {
      loading: loading || validatingFingerprint,
      isFormShown,
      paymentData: parsedPaymentData,
      error,
      paymentAmount,
      basicPaymentDetails: {
        date: path('oms.payments.date')(basicPaymentDetails),
        dueDate: path('oms.payments.dueDate')(basicPaymentDetails),
        paidToDate: path('oms.payments.paidToDate')(basicPaymentDetails),
        amount: path('oms.payments.amount')(basicPaymentDetails),
      },
      paymentDetailsLoading,
      success,
    },
    actions: {
      submitPayment,
      toggleForm: toggle,
    },
    paymentChecks,
  };
};
