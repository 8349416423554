import React, { useContext } from 'react';
import { ContactUsContext } from '~OMS/contact-us/ContactUsContext';
import ActionWithIcon from '~common/atoms/ActionWithIcon';

const RequestCallbackWithIcon = ({ isAnomUser, iconProps, ...props }) => {
  const { openRequestCallback } = useContext(ContactUsContext);
  return (
    <ActionWithIcon
      onClick={() => {
        openRequestCallback({
          isAnomUser,
        });
      }}
      text="Request a callback"
      iconProps={{
        name: 'request-callback',
        ...iconProps,
      }}
      {...props}
    />
  );
};

export default RequestCallbackWithIcon;
