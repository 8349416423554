import { useEffect, useState, useRef } from 'react';
import { globalHistory } from '@reach/router';
import { navigate } from 'gatsby';

export default ({ location: { search, pathname }, ignore = [] }) => {
  const [initialPath] = useState(pathname);
  const [listenerSet, setListenerSet] = useState(false);
  const cleanup = useRef();

  useEffect(() => {
    if (cleanup?.current && search?.includes('confirmExit=true')) {
      cleanup?.current();
    }
  }, [pathname, initialPath, search]);

  useEffect(() => {
    if (!listenerSet && initialPath === '/quote') {
      const callback = globalHistory.listen(({ location }) => {
        if (
          !location?.state?.showConfirmationModal &&
          !ignore.includes(location.pathname)
        ) {
          return navigate(`/quote#confirmExit`, {
            state: { showConfirmationModal: true },
          });
        }
      });
      cleanup.current = callback;
      setListenerSet(true);
    }
  }, [ignore, initialPath, listenerSet, navigate, pathname]);

  return cleanup?.current;
};
