import React from 'react';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import NumberFormat from 'react-number-format';
import { Flex, Box } from 'rebass';
import { LGHidden } from 'components/OMS/constants/helpers';
import HTMLContent from '../../../Elements/HTMLContent';
import { P1, P2, P3 } from '~common/atoms/typography';
import { currency } from '~lib/filters';
import InfoIcon from '~common/molecules/InfoIcon';
import moment from 'moment';

const Bar = styled.div`
  height: 20px;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  background: ${props => props.theme.colors.lightNeutral200};

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    margin: auto;
    background: ${props => props.theme.colors.lightNeutral200};
    border-radius: 6px;
  }
`;

const Usage = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  width: ${props => props.percentage}%;
  background: ${props => props.background};
  border-radius: 6px;
`;

const Breakdown = styled.div`
  border: 2px solid ${props => props.colors.borders};
  padding: 16px;
`;

//some weird shit here as per https://airteam.atlassian.net/browse/DWM-689
const UsedBox = ({ used, limit, title, extrasProductId }) => {
  return null;
  return (
    <Box
      order={{ lg: 4 }}
      width={{ lg: 60 }}
      pl={{
        xs: '10px',
        md: 0,
      }}
    >
      {limit === 0 && used === 0 ? (
        <>
          <LGHidden>Used: </LGHidden>
          {extrasProductId === 'extras-cover-total-extras' &&
          title === 'General dental services'
            ? 'Unlimited'
            : 'N/A'}
        </>
      ) : (
        <>
          <LGHidden>Used:</LGHidden>{' '}
          <NumberFormat
            value={used}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </>
      )}
    </Box>
  );
};

const MoreInfo = ({ tooltip }) => {
  return tooltip && <InfoIcon tooltip={<HTMLContent markdown={tooltip} />} />;
};

const Remaining = ({
  remaining,
  limit,
  used,
  extrasProductId,
  title,
  waitingPeriod,
}) => {
  if (
    (limit === 0 &&
      used === 0 &&
      extrasProductId === 'extras-cover-total-extras' &&
      title === 'General dental services') ||
    !limit
  ) {
    return (
      <P1 inline fontSize={5} variant="semiBold">
        Unlimited
      </P1>
    );
  }

  if (waitingPeriod?.active) {
    return (
      <P1 inline fontSize={5} variant="semiBold">
        Waiting period expires{' '}
        {moment(waitingPeriod.endDate).format('DD/MM/YYYY')}
      </P1>
    );
  }

  return (
    <P2 mb="4px" variant="medium" color="neutral500">
      <P1 inline fontSize={5} variant="semiBold">
        {currency(remaining)}
      </P1>{' '}
      remaining of {currency(limit)}
    </P2>
  );
};

const Used = ({ used }) => {
  return (
    <P3 mt="8px" color="neutral500" variant="medium">
      Used: {currency(used)}
    </P3>
  );
};

const UsageLine = ({
  remaining,
  waitingPeriod,
  limit,
  used,
  title,
  theme,
  extrasProductId,
  ...props
}) => {
  return (
    <Box {...props}>
      <Remaining
        title
        used={used}
        waitingPeriod={waitingPeriod}
        remaining={remaining}
        limit={limit}
        extrasProductId={extrasProductId}
      />
      <Bar>
        <Usage
          background={theme.colors.secondary}
          percentage={!limit ? 100 : 100 - (used / limit) * 100}
          {...theme}
        />
      </Bar>
      <Used used={used} />
    </Box>
  );
};

const UsageGraph = ({
  theme,
  title,
  subtitle,
  tooltip,
  expiry,
  limit,
  used,
  extrasProductId,
  remaining,
  waitingPeriod,
  benefits,
  ...props
}) => {
  return (
    <Box {...props}>
      <Box>
        <Flex mb="10px" alignItems="center" justifyContent="space-between">
          <P2
            variant="semiBold"
            color="neutral900"
            width={{ sm: 1 / 2, lg: 'auto' }}
          >
            {title}
            {waitingPeriod.active ? '*' : ''}
            {subtitle ? (
              <div
                css={css`
                  margin-top: 4px;
                  font-size: 13px;
                `}
              >
                {subtitle}
              </div>
            ) : null}
          </P2>
          <MoreInfo tooltip={tooltip} />
        </Flex>

        <UsageLine
          extrasProductId={extrasProductId}
          waitingPeriod={waitingPeriod}
          limit={limit}
          remaining={remaining}
          theme={theme}
          used={used}
          mb="10px"
          order={{ sm: 5, lg: 2 }}
        />
      </Box>
      {benefits && benefits.length ? (
        <Breakdown {...theme}>
          {benefits.map((benefit, i) => (
            <Box py="20px">
              <Flex
                justifyContent={{ xs: 'space-between', sm: 'space-between' }}
                alignItems="center"
                flexWrap="wrap"
                key={i}
              >
                <P2
                  variant="semiBold"
                  color="neutral900"
                  width={{ sm: 1 / 2, lg: 'auto' }}
                >
                  {benefit.title}
                  {benefit.waitingPeriod.active ? '*' : ''}
                  {benefit.subtitle ? (
                    <div
                      css={css`
                        margin-top: 4px;
                        font-size: 13px;
                      `}
                    >
                      {benefit.subtitle}
                    </div>
                  ) : null}
                </P2>
                <MoreInfo tooltip={benefit.tooltip} />
              </Flex>

              <UsageLine
                extrasProductId={extrasProductId}
                theme={theme}
                mt={{ xs: '12px', lg: 0 }}
                mb={{ xs: '10px', lg: 0 }}
                order={{ sm: 5, lg: 2 }}
                used={benefit.used}
                limit={benefit.limit}
                remaining={benefit.remaining}
                waitingPeriod={benefit.waitingPeriod}
              />
            </Box>
          ))}
        </Breakdown>
      ) : null}
    </Box>
  );
};

export default withTheme(UsageGraph);
