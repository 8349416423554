import { required, asciiOnly } from '~lib/formValidation';
import { cardNumberValidator } from '~OMS/payments/molecules/CardNumberControl';
import { cvvValidator } from '~OMS/payments/molecules/CvvControl';
import { expiryDateValidator } from '~common/molecules/form-controls/validators';
import { string } from 'yup';
import { detectCard } from '~OMS/payments/cardDetector';

const cardNumberValidatorInstance = cardNumberValidator();

export const creditCardValidation = {
  cardHolder: [
    asciiOnly('Card name contains unsupported characters'),
    required('Name on card is required'),
  ],
  cardNumber: [
    required('Card number is required'),
    value => {
      if (!detectCard(value)) {
        //TODO: generate this message based on the list of supported cards from cardDetector module
        //eslint-disable-next-line no-throw-literal
        throw 'Only Visa and Master Card are supported';
      }
    },
    value => {
      cardNumberValidatorInstance.validateSync(value);
    },
  ],
  cvv: [
    required('Cvv is required'),
    value => cvvValidator().validateSync(value),
  ],
  expiry: [
    required('Expiry date is required'),
    value => expiryDateValidator().validateSync(value),
  ],
};

export const accountNameValidator = () =>
  string()
    .max(40, 'Account name has to be less than 41 characters long')
    .matches(/^([\x00-\x7F]+)$/g, 'Account name contains invalid characters')
    .required('Account name is required field');
