import React, { useContext, useState } from 'react';
import { Flex, Box } from 'rebass';
import { Formik, Field } from 'formik';
import FormTitle from '~OMS/styles/FormTitle';
import FormText from '~OMS/styles/FormText';
import SSOFormContainer from '~OMS/styles/SSOFormContainer';
import { InputControl } from '~common/molecules/form-controls/index';
import AlertBox from '~common/molecules/AlertBox';
import { Button } from '~common/atoms/Button';
import * as Yup from 'yup';
import { AuthContext } from '../../../../context/sso';

const initialForm = {
  code: '',
};

const validation = Yup.object().shape({
  code: Yup.string().required().length(6).trim(),
});

function MfaWrapper() {
  const { loginConfirm, resendMfa, clearMfa, mfa, loading, error } =
    useContext(AuthContext);

  const [resendLoading, setResendLoading] = useState(false);

  const phoneNumber = mfa?.user?.challengeParam?.CODE_DELIVERY_DESTINATION;

  return (
    <SSOFormContainer>
      <FormTitle>We've sent you a verification code</FormTitle>
      <Box width={1} mb="20px">
        <FormText>
          We've sent a code to your mobile ending in{' '}
          <strong>{phoneNumber?.substr(phoneNumber?.length - 4)}</strong>.
        </FormText>
      </Box>
      <Box width={1} mb="20px">
        <FormText>
          If this number is incorrect, please call us or update your details in
          your Online Member Services account.
        </FormText>
      </Box>
      <Formik
        initialValues={initialForm}
        validationSchema={validation}
        onSubmit={({ code }) => loginConfirm(code)}
      >
        {({ handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Field
              component={InputControl}
              label="Enter 6-digit code"
              name="code"
              focus
              trim
            />

            <Box width={1} mb="40px">
              <Button
                variant="primaryLink"
                type="button"
                loading={resendLoading}
                wrapperProps={{
                  paddingLeft: resendLoading ? '30px' : '0',
                  '&&&& button': {
                    textDecoration: 'underline',
                  },
                }}
                onClick={async () => {
                  setResendLoading(true);
                  setFieldValue('code', '', false);
                  await resendMfa();
                  setResendLoading(false);
                }}
              >
                Resend Code
              </Button>
            </Box>

            <Flex flexDirection={{ xs: 'column', lg: 'row' }}>
              <Box
                width={{ md: '100%', lg: '48%' }}
                mb={{
                  xs: 2,
                  md: 0,
                }}
                mr={{ xs: '0', lg: '10px' }}
              >
                <Button
                  type="button"
                  variant="primaryInverted"
                  css={{ width: '100%' }}
                  onClick={() => {
                    clearMfa();
                  }}
                >
                  Back to sign in
                </Button>
              </Box>
              <Box
                width={{ md: '100%', lg: '48%' }}
                mb="30px"
                ml={{ xs: '0', lg: '10px' }}
              >
                <Button
                  loading={loading}
                  showOnlyLoaderWhenActive
                  type="submit"
                  variant="primary"
                  css={{ width: '100%' }}
                >
                  Submit
                </Button>
              </Box>
            </Flex>
          </form>
        )}
      </Formik>
      {error && (
        <AlertBox error="We are experiencing a temporary connection issue. Please try again later." />
      )}
    </SSOFormContainer>
  );
}

export default MfaWrapper;
