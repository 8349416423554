import React from 'react';
import styled from '@emotion/styled';
import { useThemeUI } from 'theme-ui';
import Icon from '~common/atoms/Icon';
import useContactDetails from '~lib/hooks/sanity/useContactDetails';
import WorkingTime from '~common/molecules/WorkingTime';

const StyledContact = styled.div`
  padding-top: 40px;
  margin-top: -12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  > * {
    margin-top: 12px !important;
    font-size: ${props => props.theme.fontSizes[3]}px !important;
    line-height: 21px !important;
    color: ${props => props.theme.colors.text} !important;
    text-align: center !important;
  }

  a {
    display: flex;
    align-items: center;
    margin-right: 8px;
    font-weight: 600;
    text-decoration: none;
    color: ${props => props.theme.colors.primary} !important;
  }
`;

const Contact = () => {
  const { theme } = useThemeUI();
  const sanityContactDetails = useContactDetails();

  return (
    <StyledContact>
      <a href={`tel:${sanityContactDetails?.phone}`}>
        <Icon
          fill={theme.colors.primary}
          name="oms-phone"
          width="15px"
          height="20px"
          style={{ marginRight: 10 }}
        />
        {sanityContactDetails?.phone}
      </a>
      <WorkingTime />
    </StyledContact>
  );
};

export default Contact;
