import React from 'react';

import styled from '@emotion/styled';
import { useMobileScreen } from '~lib/responsive';
import { useThemeUI } from 'theme-ui';
import useLocalStorage from '~lib/hooks/useLocalStorage';
import { Box } from 'rebass';
import { css } from '@emotion/core';
import Icon from '../../common/atoms/Icon';
import SanityRichTextContent from '../../common/molecules/SanityRichTextContent';

const BannerHtmlContent = styled(SanityRichTextContent)`
  ${props => props.theme.mq.mdInverted} {
    padding-right: ${props => props.theme.fontSizes[5]}px;
  }
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  right: ${props => props.theme.fontSizes[2]}px;
  top: 50%;
  transform: translateY(-50%);
`;

const WarningBanner = ({ data } = {}) => {
  const isMobile = useMobileScreen();
  const { theme } = useThemeUI();
  const key = `warning_banner_visible: ${
    JSON.stringify(data.message) || 'warning_banner_visible'
  }`;
  const [bannerShownLocalStorage, setBannerShown] = useLocalStorage(key, true);

  const bannerShown = data.show && bannerShownLocalStorage;

  const hideBanner = () => {
    setBannerShown(false);
  };

  return (
    bannerShown && (
      <Box
        css={css`
          min-height: 50px;
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1px;
          text-align: center;
          padding: ${theme.space[3]}px ${theme.space[3]}px;
          background: ${data.backgroundColor || theme.colors.primary};
        `}
      >
        <BannerHtmlContent
          forceColor={data.backgroundColor === '#f6f6f6' ? 'black' : 'white'}
          content={data.message}
        />

        <CloseIcon
          onClick={hideBanner}
          clickable
          stroke={
            data.backgroundColor === '#f6f6f6'
              ? theme.colors.black
              : theme.colors.white
          }
          name="close"
          height={isMobile ? '20px' : '15px'}
          width={isMobile ? '20px' : '15px'}
        />
      </Box>
    )
  );
};

export default WarningBanner;
