import Block from '../../common/atoms/Block';
import React from 'react';

const ContactUsBlock = props => (
  <Block
    redefinePr={{
      xl: 324,
    }}
    redefinePl={{
      xl: 324,
    }}
    {...props}
  />
);

export default ContactUsBlock;
