import React from 'react';
import { P3 } from '../atoms/typography';

const S2IMembershipPaymentMessage = props => {
  return (
    <P3 variant="medium" {...props}>
      The cost of your Lite Extras Cover is fully subsidised by Avant.
    </P3>
  );
};

export default S2IMembershipPaymentMessage;
