import React from 'react';
import { useMobileScreen } from '~lib/responsive';
import MobileHeader from './MobileHeader/index';
import DesktopHeader from './DesktopHeader/index';
import { usePageContext } from '~lib/providers/PageProvider';
import CampaignHeader from './CampaignHeader';

const Header = props => {
  const isMobile = useMobileScreen();
  const { isCampaign } = usePageContext();
  if (isCampaign) {
    return <CampaignHeader {...props} />;
  }

  return isMobile ? <MobileHeader {...props} /> : <DesktopHeader {...props} />;
};

export default Header;
