import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';

export const Form = ({ children, form, validate, ...props }) => {
  const formikFormRef = useRef({});
  const { values, touched } = form || {};

  useEffect(() => {
    if (values && formikFormRef.current) {
      Object.entries(values).forEach(([fieldName, fieldValue]) => {
        if (formikFormRef.current.values[fieldName] !== fieldValue) {
          formikFormRef.current.setFieldValue(fieldName, fieldValue);
        }
      });
    }
  }, [values]);

  useEffect(() => {
    if (touched && formikFormRef.current) {
      Object.entries(touched).forEach(([fieldName, isTouched]) => {
        if (formikFormRef.current.touched[fieldName] !== isTouched) {
          formikFormRef.current.setFieldTouched(fieldName, isTouched);
        }
      });
    }
  }, [touched]);

  const originalHandleChangeRef = useRef();
  const handleChangeRef = useRef();

  return (
    <Formik validate={(form && form.validate) || validate} {...props}>
      {formikForm => {
        formikFormRef.current = formikForm;
        if (originalHandleChangeRef.current !== formikForm.handleChange) {
          const originalHandleChange = formikForm.handleChange.bind(formikForm);
          formikForm.handleChange = evt => {
            originalHandleChange(evt);

            // wait for the next tick once  formik does all the internal updates
            const change = {
              name: evt.target.name,
              value: evt.target.value,
            };
            setTimeout(() => {
              form.handleChange(change, formikFormRef.current);
            }, 0);
          };
          handleChangeRef.current = formikForm.handleChange;
        } else {
          formikForm.handleChange = handleChangeRef.current;
        }

        return children(formikForm);
      }}
    </Formik>
  );
};
