import React, { useEffect, useRef, createRef, useState } from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';
import { keyframes } from '@emotion/core';

import drawArc from '~lib/drawArc';
import { Caption } from '~common/atoms/typography';

const DrawAnimation = props => {
  return keyframes`
  to {
    stroke-dashoffset: ${props};
  }
`;
};

const UsagePath = styled.path`
  stroke-width: 6px;
  stroke-linecap: round;
  stroke-dasharray: ${props => props.totalLength};
  stroke-dashoffset: ${props => props.totalLength};
  animation: ${props =>
    typeof props.targetLength !== 'undefined'
      ? DrawAnimation(props.targetLength)
      : undefined};
  animation-fill-mode: forwards;
  animation-delay: 1s;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
`;

// Due to a Safari bug, (you cannot animate stroke-dasharray
// with negative values) we have to flip (scaleX) the SVG
// if we want to animate it.
const Circle = styled.svg`
  width: 140px;
  height: 140px;
  fill: none;
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(-1);
  ${UsagePath} {
    stroke: ${props =>
      props.waitingPeriodActive
        ? `${props.theme.colors.neutral100} !important`
        : '#000'};
  }

  &:nth-of-type(1) {
    ${UsagePath} {
      stroke: ${props => props.theme.colors.primary};
    }
  }
  &:nth-of-type(2) {
    ${UsagePath} {
      stroke: ${props => props.theme.colors.secondary};
    }
  }
  &:nth-of-type(3) {
    ${UsagePath} {
      stroke: ${props => props.theme.colors.circleArcGraphOrange};
    }
  }
  &:nth-of-type(4) {
    ${UsagePath} {
      stroke: ${props => props.theme.colors.error};
    }
  }
`;

const TotalPath = styled.path`
  stroke-width: 4px;
  stroke-linecap: round;
  stroke: ${props => props.theme.colors.borders};
`;

const Label = styled(Caption)`
  position: absolute;
  top: ${props => props.offset - 3}px;
  left: 50%;
  transform: translateX(calc(-100% - 10px));
  margin: auto;
  white-space: nowrap;
  }
`;

const CircleArcGraph = ({ data }) => {
  const radius = 67;
  const elRef = useRef([...data].map(() => createRef()));
  const [circles, setCircleLength] = useState(data);
  const handleRender = x => {
    setCircleLength(x);
  };

  useEffect(() => {
    const newCircles = circles.map((e, i) => {
      return {
        ...e,
        length: elRef.current[i].current.getTotalLength(),
      };
    });
    handleRender(newCircles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex justifyContent="center" className="circle-arc-graph">
      <Box
        css={{
          width: '140px',
          height: '140px',
          position: 'relative',
          marginBottom: '20px',
        }}
      >
        {circles.map((e, i) => {
          const r = radius - i * 15;
          const totalArc = drawArc(70, 70, r, 90, 360);
          const usage = 100 - e.remaining;
          return (
            <React.Fragment key={i}>
              <Circle waitingPeriodActive={e.waitingPeriod.active}>
                <TotalPath d={totalArc} />
                <UsagePath
                  ref={elRef.current[i]}
                  d={totalArc}
                  totalLength={e.length}
                  targetLength={e.length * (usage / 100)}
                />
              </Circle>
              <Label variant="medium" offset={i * 15}>
                {Math.floor(e.remaining)}% {e.title}
                {e.waitingPeriod.active ? '*' : ''}
              </Label>
            </React.Fragment>
          );
        })}
      </Box>
    </Flex>
  );
};

export default CircleArcGraph;
