import React from 'react';
import SanityRichTextContent from '../../common/molecules/SanityRichTextContent';
import Block from '../../common/atoms/Block';

const RichTextContent = ({ data: { blockContent }, next = {}, ...props }) => {
  return (
    <Block
      redefineMb={
        next._type === 'downloadsComponent'
          ? {
              lg: 50,
            }
          : undefined
      }
    >
      <SanityRichTextContent content={blockContent} {...props} />
    </Block>
  );
};

export default RichTextContent;
