import React from 'react';
import { Flex, Box } from 'rebass';
import { withTheme } from 'emotion-theming';
import Icon from '~common/atoms/Icon';
import styled from '@emotion/styled';

const StyledMessage = styled(Box)`
  font-weight: ${props => (props.emphasize ? 'bold' : '')};
  font-size: ${props =>
    props.emphasize ? props.theme.fontSizes[3] : props.theme.fontSizes[2]}px;
`;

function SimpleSuccessMessage({ message, emphasize, theme, ...props }) {
  return (
    <Flex {...props} alignItems="center">
      <Box mr={3}>
        <Icon
          height="30px"
          width="30px"
          name="success-circle-tick"
          fill={theme.colors.green}
        />
      </Box>
      <StyledMessage emphasize={emphasize}>{message}</StyledMessage>
    </Flex>
  );
}

export default withTheme(SimpleSuccessMessage);
