import React from 'react';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { Element } from 'react-scroll';
import Question from '../../Question';
import NavButtons from '../../NavButtons';
import useDateOfBirth from './useDateOfBirth';
import DobForm from './DobForm';
import styled from '@emotion/styled';

const StyledNavButtons = styled(NavButtons)`
  ${props => (props.hasPartner ? 'bottom: unset;' : '')}
`;

const DateOfBirth = ({ form, hasPartner, onChange, onNext, onBack }) => {
  const { state, actions, validate } = useDateOfBirth({
    form,
    hasPartner,
    onChange,
    onNext,
  });

  return (
    <Formik
      enableReinitialize
      initialValues={state.initialValues}
      onSubmit={actions.handleSubmit}
      isInitialValid={props => isEmpty(validate(props.initialValues))}
      validate={validate}
    >
      {({ handleSubmit, isValid, values, errors }) => {
        return (
          <Question>
            <form onSubmit={handleSubmit}>
              <DobForm
                hasPartner={hasPartner}
                values={values}
                errors={errors}
                alertName="question-yourDob-alert"
                title="What's your date of birth?"
                subtitle="Your date of birth will be used to determine your Australian Government Rebate, and your eligibility for an Age-Based Discount."
                inputName="yourDob"
                discountMessage="You're entitled to a youth discount of up to 10% of your hospital premium."
                pb={0}
                pt={0}
              />

              {hasPartner && (
                <Element name="partnerDob">
                  <DobForm
                    hasPartner={hasPartner}
                    values={values}
                    errors={errors}
                    alertName="question-partnerDob-alert"
                    title="What's your partner's date of birth"
                    subtitle="Your partner's date of birth will be used to determine your Australian Government Rebate, and your partner's eligibility for an Age-Based Discount."
                    inputName="partnerDob"
                    discountMessage="Your partner is entitled to a youth discount of up to 10% of your hospital premium."
                    pb={0}
                    pt={0}
                  />
                </Element>
              )}

              <StyledNavButtons
                mt={14}
                hasPartner={hasPartner}
                nextDisabled={!isValid}
                onBack={onBack}
                onNext={handleSubmit}
              />
            </form>
          </Question>
        );
      }}
    </Formik>
  );
};

export default DateOfBirth;
