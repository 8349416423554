import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button } from '~common/atoms/Button';

const StyledButtonGroup = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding-top: 40px;
  margin-top: -20px;

  @media (min-width: 480px) {
    flex-flow: row nowrap;
    justify-content: center;
  }

  > * {
    margin-top: 20px !important;

    &:first-of-type {
      order: 2;
    }

    &:last-of-type {
      order: 1;
    }

    @media (min-width: 480px) {
      max-width: 160px;
      width: 100%;

      &:not(:last-of-type) {
        margin-right: 20px;
      }

      &:first-of-type {
        order: 1;
      }

      &:last-of-type {
        order: 2;
      }
    }
  }
`;

const Actions = ({ onApply, onEmailQuote }) => {
  return (
    <StyledButtonGroup>
      <Button onClick={onEmailQuote} variant="primaryInverted" width="100%">
        Email quote
      </Button>
      <Button
        icon="chevron-right-thin"
        iconPosition="right"
        onClick={onApply}
        variant="secondary"
        width="100%"
      >
        Apply now
      </Button>
    </StyledButtonGroup>
  );
};

Actions.propTypes = {
  onApply: PropTypes.func.isRequired,
  onEmailQuote: PropTypes.func.isRequired,
};

export default Actions;
