import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';
import { useThemeUI } from 'theme-ui';
import ModalWrapper from '~common/molecules/ModalWrapper';
import Icon from '~common/atoms/Icon';
import { Button } from '~common/atoms/Button';
import { H2, P2, P } from '~common/atoms/typography';

const StyledButton = styled.button`
  ${props => props.theme.buttons.minimal};
  position: absolute;
  top: 44px;
  right: 40px;

  > * {
    pointer-events: none;
  }
`;

const ConfirmModal = ({ open, toggleOpen }) => {
  const { theme } = useThemeUI();

  const closeButton = (
    <StyledButton
      type="button"
      onClick={() => {
        toggleOpen(false);
      }}
    >
      <Icon name="closeX" fill={theme.colors.primary} />
    </StyledButton>
  );

  return (
    <ModalWrapper
      toggleModal={open}
      setToggleModal={() => toggleOpen(!open)}
      width="650px"
      closeButton={closeButton}
    >
      <Box px={52} py={24}>
        <H2 mb={40} color={theme.colors.primary}>
          Are you sure you want to delete your online account?
        </H2>
        <P2 mb={40}>
          By proceeding with this request, we will remove your access to both
          the Doctors' Health Fund mobile app and Online Member Services. This
          means that you will no longer be able to use your current login
          credentials. Do you want to continue?
        </P2>
        <Flex style={{ gridGap: 16 }}>
          <Button type="submit" variant="primary">
            <P style={{ fontSize: '18px', lineHeight: '18px' }}>
              Yes, delete my account
            </P>
          </Button>
          <Button
            variant="primaryInverted"
            type="button"
            onClick={() => toggleOpen(!open)}
            mr={16}
          >
            <P style={{ fontSize: '18px', lineHeight: '18px' }}>No, cancel</P>
          </Button>
        </Flex>
      </Box>
    </ModalWrapper>
  );
};

export default ConfirmModal;
