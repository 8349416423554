import React, { useEffect, useState } from 'react';
import { Box, Flex } from 'rebass';
import styled from '@emotion/styled';
import { P2, StandardText } from '~common/atoms/typography';
import InfoIcon from '~common/molecules/InfoIcon';
import { CreateControl } from '~common/molecules/form-controls/Control';
import AppFormLabel from '~common/molecules/FormLabel';
import RadioGroup from '~common/atoms/RadioGroup';
import Radio from '~common/atoms/Radio';
import { css } from '@emotion/core';
import { useThemeUI } from 'theme-ui';
import { PanelBody, PanelHeader } from '../Panel';
import { useMobileScreenOnly } from '~lib/responsive';

const StyledOptionHeader = styled(PanelHeader)`
  justify-content: space-between;
  cursor: pointer;
`;

const OptionsPanel = PanelBody;

const StyledRadioGroup = styled(RadioGroup)`
  width: 100%;
`;

const generateColumns = (list, maxLength, isMobile) => {
  if (isMobile) {
    return [list];
  }
  const columns = [];
  list.forEach((item, index) => {
    if (index % maxLength === 0) {
      columns.push([item]);
    } else {
      columns[columns.length - 1].push(item);
    }
  });

  return columns;
};

const StyledRadio = styled(Radio)`
  &&& .ant-radio-inner {
    height: 20px;
    width: 20px;
    &::after {
      height: 12px;
      width: 12px;
      transform: scale(1) translate(-50%, -50%);
      margin: 0;
    }
  }

  &.ant-radio-wrapper {
    white-space: normal !important;
    span:nth-child(2) {
      font-size: ${props => props.theme.fontSizes[3]}px;
      color: ${props => props.theme.colors.neutral800};
    }
  }
`;

const Options = ({ options = [] }) => {
  const isMobile = useMobileScreenOnly();
  const columns = generateColumns(options, 4, isMobile);
  return (
    <Flex width={1}>
      {columns.map((column, columnIndex) => (
        <Flex
          key={columnIndex}
          width={1 / columns.length}
          flexDirection="column"
        >
          {column.map((option, index) => (
            <Box key={index} mb={index !== column.length - 1 ? 20 : 0}>
              <StyledRadio value={option.value} key={option.value}>
                {option.label}
              </StyledRadio>
            </Box>
          ))}
        </Flex>
      ))}
    </Flex>
  );
};

const StyledInfoIcon = styled(InfoIcon)`
  &&&& svg g path {
    transition: all 300ms ease-out;
  }
`;

const OrDivider = props => {
  const { theme } = useThemeUI();
  return (
    <Flex
      justifyContent="center"
      css={css`
        border-top: 1px solid ${theme.colors.borders};
        position: relative;
        z-index: 0;
      `}
      {...props}
    >
      <StandardText
        css={css`
          position: relative;
          z-index: 1;
          background: ${theme.colors.white};
          top: -11px;
          padding: 0 5px;
        `}
        fontSize={2}
        rColor="neutral500"
      >
        OR
      </StandardText>
    </Flex>
  );
};

const renderOptions = ({
  options,
  theme,
  onChange,
  value,
  selected,
  setSelected,
  name,
  onBlur,
}) => {
  return options.map(option => {
    const optionSelected = option.value === selected.value;
    return (
      <Box key={option.value}>
        <StyledOptionHeader
          onClick={() => {
            const evt = {
              value: option.value,
              optionValue:
                option.options && option.options.length === 1
                  ? option.options[0].value
                  : selected.value === option.value
                  ? selected.optionValue
                  : '',
            };
            if (onChange) {
              onChange(evt);
            } else {
              setSelected(evt);
            }
          }}
          my="2px"
          type={optionSelected ? 'primary' : 'ternary'}
        >
          <P2
            variant="medium"
            bold={optionSelected}
            color={optionSelected ? 'white' : 'neutral800'}
          >
            {option.label}
          </P2>
          {option.helpText && (
            <StyledInfoIcon
              selected={optionSelected}
              fill={optionSelected ? theme.colors.white : theme.colors.primary}
              position="top"
              name="help"
              height="16px"
              width="16px"
              tooltip={option.helpText}
            />
          )}
        </StyledOptionHeader>
        {optionSelected && option.subLabel && optionSelected && (
          <OptionsPanel>
            <AppFormLabel mb={option.options ? 20 : -12}>
              {option.subLabel}
            </AppFormLabel>
            <StyledRadioGroup
              name={name}
              onBlur={onBlur}
              value={value.optionValue}
              onChange={evt => {
                if (onChange) {
                  onChange({
                    value: option.value,
                    optionValue: evt.target.value,
                  });
                } else {
                  setSelected({
                    value: option.value,
                    optionValue: evt.target.value,
                  });
                }
              }}
            >
              <Options options={option.options} />
            </StyledRadioGroup>
          </OptionsPanel>
        )}
      </Box>
    );
  });
};

const QuestControl = ({
  value,
  options = [],
  orOptions = [],
  onChange,
  name,
  onBlur,
  ...props
}) => {
  const { theme } = useThemeUI();

  const [selected, setSelected] = useState({
    value: '',
    optionValue: '',
  });
  useEffect(() => {
    setSelected(
      value || {
        value: '',
        optionValue: '',
      }
    );
  }, [value]);

  return (
    <Box {...props}>
      {renderOptions({
        options,
        theme,
        onChange,
        value,
        selected,
        setSelected,
        name,
        onBlur,
      })}
      {!!orOptions.length && <OrDivider mt={21} />}
      {renderOptions({
        options: orOptions,
        theme,
        onChange,
        value,
        selected,
        setSelected,
        name,
        onBlur,
      })}
    </Box>
  );
};

export default CreateControl(QuestControl);
