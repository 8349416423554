import React from 'react';
import { Flex, Box } from 'rebass';
import { useThemeUI } from 'theme-ui';
import { useColumnContext } from '~lib/providers/ColumnProvider';
import { usePageContext } from '~lib/providers/PageProvider';

export const defaultBlockSpacing = {
  pr: {
    xs: 15,
    tabletP: 25,
    tabletL: 25,
    lg: 70,
    xl: 120,
  },
  pl: {
    xs: 15,
    tabletP: 25,
    tabletL: 25,
    lg: 70,
    xl: 120,
  },
};

const Block = (props, ref) => {
  const {
    children,
    innerProps,
    type = 'light',
    redefinePl,
    redefinePr,
    redefineMb,
    ignoreCampaignLayout,
    bg: customBg,
    ...styleProps
  } = props;

  const { theme } = useThemeUI();
  const columnContext = useColumnContext() || {};
  const { isHomePage, isCampaign } = usePageContext();

  const applyCampaignLayout = isCampaign && !ignoreCampaignLayout;
  const widths = {
    ...theme.containerWidths,
  };
  const bg =
    customBg ||
    {
      light: 'white',
      dark: theme.colors.lightNeutral100,
    }[type];
  if (props.snug) {
    widths.xl = widths.lg;
  }

  return (
    <Flex
      ref={ref}
      mb={
        !isHomePage
          ? {
              xs: 64,
              lg: 90,
              ...redefineMb,
            }
          : undefined
      }
      pl={{
        xs: columnContext.sideMenuLayout ? 0 : defaultBlockSpacing.pl.xs,
        tabletP: columnContext.sideMenuLayout
          ? 0
          : defaultBlockSpacing.pl.tabletP,
        tabletL: columnContext.sideMenuLayout
          ? 50
          : defaultBlockSpacing.pl.tabletL,
        lg: columnContext.sideMenuLayout ? 55 : defaultBlockSpacing.pl.lg,
        xl: columnContext.sideMenuLayout ? 102 : defaultBlockSpacing.pl.xl,
        ...redefinePl,
      }}
      pr={
        columnContext && columnContext.sideMenuLayout
          ? 0
          : {
              xs: defaultBlockSpacing.pr.xs,
              tabletP: defaultBlockSpacing.pr.tabletP,
              tabletL: defaultBlockSpacing.pr.tabletL,
              lg: defaultBlockSpacing.pr.lg,
              xl: defaultBlockSpacing.pr.xl,
              ...redefinePr,
            }
      }
      alignItems="center"
      justifyContent={
        applyCampaignLayout && columnContext.sideMenuLayout
          ? 'flex-start'
          : 'center'
      }
      bg={bg}
      {...styleProps}
    >
      <Box width={widths} {...innerProps}>
        {children}
      </Box>
    </Flex>
  );
};

export default React.forwardRef(Block);
