import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { MembershipRatesQuery } from '../../../queries/oms/rates.graphql';

const isStaffMemberRate = rate => {
  const STAFF_MEMBER_PREFIX = 'STF_';
  return rate.startsWith(STAFF_MEMBER_PREFIX);
};

export const useRates = (
  cover,
  { query, extractRates, rateType, resultType, includeOffSale }
) => {
  const shouldSkipQuery =
    !cover ||
    ['region', 'status', 'dob'].some(field => {
      const value = get(cover, `${field}.value`);
      return value == null || value === '';
    });

  const rateTypePayload = rateType || cover.rateType || 'BOTH';

  const { data, ...rest } = useQuery(query, {
    skip: shouldSkipQuery,
    variables: {
      input: {
        state: cover.region?.value,
        status: cover.status?.value,
        rateType: rateTypePayload,
        dob: cover.dob?.value,
        efc: get(cover, 'efc.value'),
        tier: parseInt(cover.tier?.value || '0', 10),
        resultType: resultType || cover.resultType || 'CURRENT',
        paymentFrequency: cover.paymentFrequency,
        hospitalProducts:
          rateTypePayload !== 'EXTRAS_ONLY'
            ? cover.hospitalProducts
            : undefined,
        extrasProducts:
          rateTypePayload !== 'HOSPITAL_ONLY'
            ? cover.extrasProducts
            : undefined,
      },
    },
  });

  const rates = useMemo(() => {
    if (!data) {
      return undefined;
    }

    if (!cover.rateCode) {
      return extractRates(data);
    }

    const isStaffMember = isStaffMemberRate(cover.rateCode);

    return extractRates(data).filter(rate =>
      isStaffMember
        ? isStaffMemberRate(rate.code)
        : !isStaffMemberRate(rate.code)
    );
  }, [cover.rateCode, data, extractRates]);

  const dataClone = useMemo(() => {
    return (
      data && {
        ...data,
      }
    );
  }, [data]);

  return {
    ...rest,
    data:
      dataClone &&
      // keep the reference unchanged as that is the behavior of useQuery hook
      Object.assign(dataClone, {
        rates,
      }),
  };
};

export const useMembershipRates = (cover, options) => {
  return useRates(cover, {
    query: MembershipRatesQuery,
    extractRates: data => data.oms.rates,
    ...options,
  });
};
