import React from 'react';
import { css } from '@emotion/core';
import { Flex } from 'rebass';
import { Element } from 'react-scroll';
import Icon from '~common/atoms/Icon';
import SanityRichTextContent from '../../common/molecules/SanityRichTextContent';
import { useThemeUI } from 'theme-ui';
import { P1 } from '~common/atoms/typography';

const Question = ({ title, selected, onClick }) => {
  const { theme } = useThemeUI();
  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      onClick={onClick}
      css={css`
        cursor: pointer;
      `}
      pr={2}
    >
      <P1 variant="semiBold" color="neutral900">
        {title}
      </P1>

      <Icon
        clickable
        name={selected ? 'chevron-up' : 'chevron-down'}
        fill={theme.colors.black}
        width="21px"
        height="21px"
      />
    </Flex>
  );
};

const Answer = ({ selected, body }) => {
  const { theme } = useThemeUI();
  return selected ? (
    <SanityRichTextContent
      content={body}
      css={css`
        margin-top: 17px;
        * {
          font-size: ${theme.fontSizes[3]}px;
          font-weight: 500;
          color: ${theme.colors.neutral500};
        }
      `}
    />
  ) : null;
};

const FAQBrowser = ({ product, selected, setSelected, ...props }) => {
  const questions = product.content.faq;
  const { theme } = useThemeUI();

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="stretch"
      bg="white"
      {...props}
    >
      {questions
        .filter(question => !question.link)
        .map((question, index, list) => {
          const isSelected = selected === question.title;

          return (
            <Element name={question.title} key={`${question.title}`}>
              <Flex
                key={question.title}
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="stretch"
                py="17px"
                px="20px"
                css={css`
                  cursor: ${isSelected ? 'auto' : 'pointer'};
                  border-top: 1px solid ${theme.colors.neutral50};
                  border-bottom: 1px solid
                    ${index === list.length - 1
                      ? theme.colors.neutral50
                      : 'transparent'};
                `}
                onClick={() => !isSelected && setSelected(question.title)}
              >
                <Question
                  key={question.title}
                  title={question.title}
                  selected={isSelected}
                  onClick={() =>
                    isSelected ? setSelected(null) : setSelected(question.title)
                  }
                />
                <Answer selected={isSelected} body={question.body} />
              </Flex>
            </Element>
          );
        })}
    </Flex>
  );
};

export default FAQBrowser;
