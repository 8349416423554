import React, { useEffect, useRef, useState } from 'react';
import { Button as RebassButton, Flex, Box } from 'rebass';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { withTheme } from 'emotion-theming';

import { shouldForwardProp } from '~lib/util';
import Icon from '~common/atoms/Icon';

const spinnerColors = theme => variant =>
  ({
    //TODO: remove those once rebranding is completed [START]
    orange: theme.colors.white,
    orangeThin: theme.colors.white,
    orangeRoundThin: theme.colors.white,
    'orange-round': theme.colors.white,
    'orangeThin-inverted': theme.colors.primary,
    'blueThin-inverted': theme.colors.secondary,
    'white-round': theme.colors.secondary,
    //TODO: remove those once rebranding is completed [END]
    blue: theme.colors.white,
    primary: theme.colors.white,
    primaryInverted: theme.colors.primary,
    secondary: theme.colors.white,
    secondaryInverted: theme.colors.secondary,
    white: theme.colors.primary,
    whiteInverted: theme.colors.white,
    primaryLink: theme.colors.primary,
    secondaryLink: theme.colors.secondary,
  }[variant]);

const StyledWrapper = styled(Flex, { shouldForwardProp })`
  width: ${props => props.width || '100%'};
  position: relative;
  pointer-events: ${props => (props.loading ? 'none' : '')};
  opacity: ${props => (props.loading ? 0.8 : 1)};
  transition: opacity 300ms ease-in-out;

  &&&& button {
    text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  }
`;

const loadingAnimation = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

const StyledLoader = styled(Box)`
  position: relative;
  transform: translate(-35px, -16px);

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 6px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: ${loadingAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${props => spinnerColors(props.theme)(props.variant)}
      transparent transparent transparent;
  }
  div:nth-of-type(1) {
    animation-delay: -0.45s;
  }
  div:nth-of-type(2) {
    animation-delay: -0.3s;
  }
  div:nth-of-type(3) {
    animation-delay: -0.15s;
  }
`;

const iconProps = size =>
  ({
    large: {
      height: '20px',
      width: '20px',
    },
  }[size]);

export const Button = withTheme(
  ({
    children,
    width = 'fit-content',
    theme,
    variant,
    className,
    loading = false,
    uppercase = false,
    delay = 0,
    naturalWidth = false,
    size = 'medium',
    showOnlyLoaderWhenActive = false,
    wrapperProps,
    icon,
    iconPosition = 'left',
    ...props
  }) => {
    const [delayedLoading, setLoading] = useState(loading);
    const timeoutRef = useRef();
    useEffect(() => {
      clearTimeout(timeoutRef.current);
      if (!loading) {
        setLoading(false);
        return;
      }

      timeoutRef.current = setTimeout(() => {
        setLoading(true);
      }, delay);
    }, [delay, loading]);

    return (
      <StyledWrapper
        uppercase={uppercase}
        width={width}
        className={className}
        loading={loading}
        {...wrapperProps}
      >
        <RebassButton
          data-size={size}
          width={naturalWidth ? 'auto' : 1}
          variant={variant}
          {...props}
        >
          <Flex justifyContent="center" alignItems="center" flexDirection="row">
            {delayedLoading ? (
              <StyledLoader variant={variant} theme={theme} className="loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </StyledLoader>
            ) : null}
            <Box>
              {showOnlyLoaderWhenActive && delayedLoading ? (
                '...'
              ) : icon && iconPosition === 'left' ? (
                <Flex alignItems="center" className="button-with-icon">
                  <Icon
                    width="16px"
                    height="16px"
                    name={icon}
                    {...iconProps(size)}
                    fill={spinnerColors(theme)(variant)}
                    mr="8px"
                  />{' '}
                  {children}
                </Flex>
              ) : icon && iconPosition === 'right' ? (
                <Flex alignItems="center" className="button-with-icon">
                  {children}{' '}
                  <Icon
                    width="16px"
                    height="16px"
                    name={icon}
                    {...iconProps(size)}
                    fill={spinnerColors(theme)(variant)}
                    ml="8px"
                  />
                </Flex>
              ) : 
                children
              }
            </Box>
          </Flex>
        </RebassButton>
      </StyledWrapper>
    );
  }
);
