import React from 'react';
import Layout from 'components/Layout';
import { withTheme } from 'emotion-theming';
import Navbar from '~common/organisms/Navbar';
import Wrapper from '~common/molecules/Wrapper';
import SubmitClaims from '~OMS/claims/templates/SubmitClaims';
import CheckMembership from '~common/organisms/CheckMembership';
import OmsFooter from '~OMS/OmsFooter';

const SubmitClaimsPage = props => {
  return (
    <Layout>
      <CheckMembership>
        <Navbar location={props.location} />
        <Wrapper pb={60}>
          <SubmitClaims />
        </Wrapper>
        <OmsFooter />
      </CheckMembership>
    </Layout>
  );
};

export default withTheme(SubmitClaimsPage);
