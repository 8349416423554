import React from 'react';
import styled from '@emotion/styled';
import Block from '../atoms/Block';
import useSanityImage from '~lib/hooks/useSanityImage';

const BackgroundImage = ({
  data,
  transform,
  as = Block,
  children,
  ...props
}) => {
  const { imageSrc } = useSanityImage({ src: data, transform });

  const StyledBackground = styled(as)`
    &&&& {
      background-image: url(${props => props.src});
      background-size: ${props => props.backgroundSize || 'cover'};
      background-repeat: no-repeat;
      background-position: ${props =>
        props.hotspot
          ? `${props.hotspot.x * 100}% ${props.hotspot.y * 100}%}`
          : 'right'};

      @media screen and (max-width: 768px) {
        background-position: 50% center;
      }
    }
  `;

  //eslint-disable-next-line
  return (
    <StyledBackground
      src={imageSrc}
      hotspot={data.hotspot}
      backgroundSize={data.backgroundSize}
      {...props}
    >
      {children}
    </StyledBackground>
  );
};

export default BackgroundImage;
