import styled from '@emotion/styled';
import Link from '../Link';
import { space, color, typography } from 'styled-system';
import Logo from '~common/atoms/Logo';

export const StyledLink = styled(Link)`
  text-decoration: none;
  transition: all 100ms ease-out;
  cursor: pointer;
  ${space};
  ${color};
  ${typography};
`;

export const MenuItem = styled(StyledLink)`
  font-size: ${props =>
    props.header ? props.theme.fontSizes[3] : props.theme.fontSizes[4]}px;
  line-height: 1.2;
  font-weight: 600;
  color: ${props => props.theme.colors.primary};
  white-space: nowrap;
  ${space};
  ${color};
  ${typography};
`;

export const MobileLogo = styled(Logo)`
  display: flex;
  margin-right: auto;
`;
