import useSanityEligibility from '~lib/hooks/sanity/useSanityEligibility';
import { useMemo } from 'react';
import useForm from '~lib/hooks/useForm';
import eligibilityValidation from '../validation/eligibilityValidation';
import { dropProps } from '~lib/util';

export default (_, { persistKey }) => {
  const showRamsayQuestions = process.env.BRAND_NAME === 'RAMSAY';
  const form = useForm(
    {},
    {
      onChange: {
        profession: () => {
          return {
            existingAvantMember: undefined,
          };
        },
      },
      validate: eligibilityValidation({ showRamsayQuestions }),
      persistKey,
    }
  );

  const cmsData = useSanityEligibility();

  const membershipOptions = useMemo(() => {
    return (cmsData.membershipOptions || []).map(option => ({
      value: option.id,
      label: option.title,
      subLabel: option.question,
      helpText: option.helpText,
      options: (option.options || []).map(subOption => ({
        value: subOption.id,
        label: subOption.title,
      })),
    }));
  }, [cmsData]);

  return {
    form,
    showRamsayQuestions,
    membershipOptions,
    serialize: () => {
      return dropProps(
        ['ramsayEmployee', 'existingAvantMember'],
        form.validValues
      );
    },
    deserialize: data => {
      return {
        ...data,
        ...(data.employeeId ? { ramsayEmployee: true } : undefined),
        existingAvantMember: ['Intern', 'Resident'].includes(
          data.profession.optionValue
        )
          ? false
          : undefined,
      };
    },
  };
};
