import React from 'react';
import styled from '@emotion/styled';
import { Box } from 'rebass';
import Icon from './Icon';
import Block from '../../common/atoms/Block';
import { useMobileScreenOnly } from '~lib/responsive';

const StyledDivider = styled(Box)`
  position: relative;
  border-top: 2px solid ${props => props.theme.colors.borders};
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
`;

export const DividerBase = ({ data: { icon }, ...props }) => {
  const isMobileOnly = useMobileScreenOnly();
  return !isMobileOnly ? (
    <StyledDivider width={1} {...props}>
      {icon && <StyledIcon data={icon} width="50px" height="50px" />}
    </StyledDivider>
  ) : null;
};

const Divider = ({ data }) => {
  const isMobileOnly = useMobileScreenOnly();
  return !isMobileOnly ? (
    <Block>
      <DividerBase data={data} />
    </Block>
  ) : null;
};

export default Divider;
