import React from 'react';

const CoverProduct = ({ heading, browser, isVisible }) => {
  return (
    <>
      {heading}
      {isVisible && browser}
    </>
  );
};

export default CoverProduct;
