import React from 'react';
import { FormPanel } from '../../common/Panel';
import BsbControl from '~OMS/payments/molecules/BsbControl';
import {
  InputControl,
  NumberInputControl,
} from '~common/molecules/form-controls';
import CheckBoxControl from '~common/molecules/form-controls/CheckBoxControl';
import { StandardText } from '~common/atoms/typography';
import { DEBIT_TYPE } from '../../constants';

const ClaimPaymentForm = ({ Field, handleChange, values }) => {
  return (
    <FormPanel title="Claim payments">
      <StandardText mb={22}>
        How would you like us to pay any claim benefits back to you?
      </StandardText>
      {values.debitType !== DEBIT_TYPE.CREDIT_CARD && (
        <Field
          onChange={handleChange}
          name="useBankAccountDetails"
          label="Use same account as above"
          labelPosition="right"
          component={CheckBoxControl}
        />
      )}
      {(values.debitType === DEBIT_TYPE.CREDIT_CARD ||
        !values.useBankAccountDetails) && (
        <>
          <Field
            onChange={handleChange}
            name="claimBsb"
            label="BSB"
            component={BsbControl}
          />
          <Field
            onChange={handleChange}
            name="claimAccountNumber"
            bold
            label="Account number"
            maxDigits={9}
            component={NumberInputControl}
          />
          <Field
            onChange={handleChange}
            name="claimAccountName"
            bold
            trim
            label="Account name"
            component={InputControl}
          />
        </>
      )}
    </FormPanel>
  );
};

export default ClaimPaymentForm;
