import React from 'react';
import WorkingModal from '~common/molecules/WorkingModal';
import { Box, Flex } from 'rebass';
import styled from '@emotion/styled';
import { useThemeUI } from 'theme-ui';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import Icon from '~common/atoms/Icon';
import { Button } from '~common/atoms/Button';
import { H2 } from '~common/atoms/typography';

const Heading = styled(Flex)`
  position: relative;
`;

const Close = styled(Icon)`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const CompareProductsModal = ({ onClose, product = {}, visible }) => {
  const { theme } = useThemeUI();
  return (
    <WorkingModal
      mobileWidth="92%"
      p={0}
      showHeader={false}
      visible={visible}
      onClose={onClose}
    >
      <Heading
        justifyContent="center"
        alignItems="center"
        pt={34}
        pb={20}
        px={20}
      >
        <H2 variant="medium" color="primary">
          {product.content?.title}
        </H2>

        <Close
          clickable
          onClick={onClose}
          height="16px"
          width="16px"
          fill={theme.colors.primary}
          stroke={theme.colors.primary}
          name="close"
        />
      </Heading>

      <Box px="20px" pb="20px">
        <SanityRichTextContent content={product.content?.body} />
        <Button
          width={1}
          mt="20px"
          variant="primary"
          size="large"
          onClick={onClose}
        >
          Okay
        </Button>
      </Box>
    </WorkingModal>
  );
};

export default CompareProductsModal;
