import RadioButtonGroup from './RadioButtonGroup';
import styled from '@emotion/styled';

const YesNoChoice = styled(RadioButtonGroup)`
  &&& .choice-container {
    justify-content: center;
    button:first-of-type {
      margin-right: 16px;
    }
    button {
      width: calc(50% - 8px);
    }
  }
`;

export default YesNoChoice;
