import Question from './Question';
import QuoteAlert from './QuoteAlert';
import React from 'react';

const AlertQuestion = ({
  children,
  alert,
  alertPosition,
  alertName,
  ...props
}) => {
  return (
    <Question className="alert-question" {...props}>
      {children}
      {alert && (
        <QuoteAlert
          alertPosition={alertPosition}
          name={alertName}
          className="quote-alert"
          mt={2}
        >
          {alert}
        </QuoteAlert>
      )}
    </Question>
  );
};

AlertQuestion.displayName = 'AlertQuestion';

export default AlertQuestion;
