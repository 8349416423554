import * as Sentry from '@sentry/browser';

const tags = {
  env: process.env.NODE_ENV,
  site_url: process.env.DHF_BASE_URL,
  site_id: process.env.SITE_ID,
  site_name: process.env.SITE_NAME,
  build_context: process.env.CONTEXT,
  build_id: process.env.BUILD_ID,
  deploy_id: process.env.DEPLOY_ID,
  deploy_url: process.env.DEPLOY_URL,
  deploy_primeUrl: process.env.DHF_BASE_URL,
  git_url: process.env.REPOSITORY_URL,
  git_commit: process.env.COMMIT_REF,
  git_branch: process.env.BRANCH,
};

const sentry = (error, extra = {}) => {
  Sentry.configureScope(scope => {
    Object.keys(tags).map(key => scope.setTag(key, tags[key]));
    Object.keys(extra).map(key => scope.setExtra(key, extra[key]));
  });

  Sentry.captureException(error);
};

export const addBreadCrumb = breadcrumb => {
  return Sentry.addBreadcrumb({
    level: Sentry.Severity.Info,
    ...breadcrumb,
  });
};

export default sentry;
