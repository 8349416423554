import React from 'react';
import { withTheme } from 'emotion-theming';
import { useQuery } from '@apollo/client';
import Row from '~common/molecules/Row';
import Col from '~common/molecules/Col';
import Card from '~common/molecules/Card';
import Loading from '~common/atoms/Loading';
import Profile from '~OMS/dashboard/molecules/Profile';
import DashboardContact from '~OMS/dashboard/molecules/DashboardContact';
import CardExtrasUsage from '~OMS/dashboard/molecules/CardExtrasUsage';
import CardClaims from '~OMS/dashboard/molecules/CardClaims';
import CardPayments from '~OMS/dashboard/molecules/CardPayments';
import CardRecentMessages from '~OMS/dashboard/molecules/CardRecentMessages';
import CardQuicklinks from '~OMS/dashboard/molecules/CardQuicklinks';
import { DashboardQuery } from 'queries/oms/dashboard';

const Dashboard = props => {
  const { loading, error, data } = useQuery(DashboardQuery);
  if (error) {
    console.error(error);

    return null;
  }

  const userHasExtras =
    !loading && ['BOTH', 'EXTRAS_ONLY'].includes(data.oms.membership.plan.type);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Row alignItems="flex-start">
            <Col width={1}>
              <Profile />
            </Col>
          </Row>
          <Row>
            <Col
              width={{
                xs: 1,
              }}
            >
              <Row>
                {userHasExtras ? (
                  <Col
                    width={{
                      xs: 1,
                      sm: 1 / 2,
                      md: 1 / 3,
                    }}
                    order={1}
                    py="20px"
                    extendPr={{
                      md: 0,
                    }}
                  >
                    <Card>
                      <CardExtrasUsage />
                    </Card>
                  </Col>
                ) : null}

                <Col
                  width={{
                    xs: 1,
                    sm: 1 / 2,
                    md: !userHasExtras ? 1 / 2 : 1 / 3,
                  }}
                  order={2}
                  py="20px"
                  extendPr={{
                    md: 0,
                  }}
                >
                  <Card>
                    <CardClaims />
                  </Card>
                </Col>

                <Col
                  width={{
                    xs: 1,
                    sm: 1 / 2,
                    md: !userHasExtras ? 1 / 2 : 1 / 3,
                  }}
                  order={3}
                  py="20px"
                >
                  <Card>
                    <CardPayments />
                  </Card>
                </Col>

                <Col
                  width={{
                    xs: 1,
                    sm: !userHasExtras ? 1 / 2 : 1 / 2,
                    md: 1 / 2,
                    lg: 1 / 4,
                  }}
                  order={{
                    xs: 4,
                    sm: 5,
                    md: 4,
                  }}
                  py="20px"
                  extendPr={{
                    md: 0,
                  }}
                >
                  <Card>
                    <CardRecentMessages eyebrow="Recent Documents" />
                  </Card>
                </Col>

                <Col
                  width={{
                    xs: 1,
                    sm: 1 / 2,
                    md: 1 / 2,
                  }}
                  order={{
                    xs: 5,
                    sm: 4,
                    xl: 5,
                  }}
                  py="20px"
                >
                  <Card>
                    <CardQuicklinks
                      location={props.location}
                      plan={data.oms.membership.plan}
                    />
                  </Card>
                </Col>
                <Col
                  width={{
                    xs: 1,
                    sm: 1 / 2,
                    md: 1,
                    lg: 1 / 4,
                  }}
                  py="20px"
                  extendPl={{
                    md: 0,
                  }}
                  order={6}
                >
                  <Card>
                    <DashboardContact />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default withTheme(Dashboard);
