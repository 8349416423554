import { useMutation } from '@apollo/client';
import { updateActivity } from '~common/storeUpdaters';
import { path } from 'lodash/fp';
import { UpdatePaymentsMutation } from 'queries/oms/payments.graphql';
import { PaymentPolicyQuery } from 'queries/oms/payments.graphql';
import { cloneDeep } from '@apollo/client/utilities';

export default () => {
  const mutation = useMutation(UpdatePaymentsMutation, {
    update: (store, response) => {
      updateActivity({
        mutationName: 'updatePayments',
        mapActivity: path('updatePayments.activity'),
      })(store, response);

      const currentPaymentsState = cloneDeep(
        store.readQuery({
          query: PaymentPolicyQuery,
        })
      );

      currentPaymentsState.oms.payments = response.data.updatePayments.payments;
      store.writeQuery({
        query: PaymentPolicyQuery,
        data: currentPaymentsState,
      });
    },
  });

  return mutation;
};
