import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import NumberFormat from 'react-number-format';
import Icon from '~common/atoms/Icon';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import { toWordCase } from '../../shared/utils';

const StyledArticle = styled.article`
  border: 2px solid ${props => props.theme.colors.neutral100};
  background-color: ${props => props.theme.colors.white};
  position: relative;
`;

const StyledContainer = styled.div`
  padding: 24px;
`;

const StyledTop = styled.div`
  ${props => props.theme.mq.smInverted} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const StyledLeft = styled.div``;

const StyledRight = styled.div`
  flex: 1;
`;

const StyledBottom = styled.div``;

const StyledType = styled.p`
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 18px;
  color: ${props => props.theme.colors.neutral500};
  padding-bottom: 8px;

  ${props => props.theme.mq.sm} {
    padding-bottom: 0;
    position: absolute;
    top: -26px;
    left: 0;
  }
`;

const StyledTitle = styled.h3`
  font-weight: 700;
`;

const StyledSubtitle = styled.h3`
  line-height: 29px;
`;

const StyledExcess = styled.p`
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 18px;
  margin-top: 8px;
`;

const StyledPrice = styled.div`
  color: ${props => props.theme.colors.text};
  font-weight: 400;
  font-size: ${props => props.theme.fontSizes[3]}px;
  line-height: 36px;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  span {
    font-weight: 600;
    font-size: ${props => props.theme.fontSizes[12]}px;
    line-height: 41px;
    margin-right: 4px;
  }

  ${props => props.theme.mq.smInverted} {
    justify-content: flex-end;
  }
`;

const StyledList = styled.ul`
  margin-top: 16px;

  ${props => props.theme.mq.smInverted} {
    display: none;
  }
`;

const StyledListItem = styled.li`
  display: flex;

  ~ li {
    margin-top: 16px;
  }

  p {
    font-size: ${props => props.theme.fontSizes[2]}px;
    line-height: 18px;
  }
`;

const StyledIcon = styled(Icon)`
  align-items: flex-start;
  margin-top: 5px;
  margin-right: 10px;
`;

const ProductCard = ({
  excess,
  price,
  paymentFrequency,
  subtitle,
  summaryPoints,
  title,
  type,
}) => {
  return (
    <StyledArticle data-id={`${type}-card`}>
      <StyledContainer>
        <StyledTop>
          <StyledLeft>
            {type ? (
              <StyledType data-id={`product-tile-${type}-title`}>
                {type}
              </StyledType>
            ) : null}
            <StyledTitle data-id={`card-${type}-title`}>{title}</StyledTitle>
            <StyledSubtitle data-id={`card-${type}-subtitle`}>
              {subtitle}
            </StyledSubtitle>
          </StyledLeft>
          <StyledRight>
            <StyledPrice data-id={`${type}-price`}>
              <NumberFormat
                value={price}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />{' '}
              {toWordCase(paymentFrequency)}
            </StyledPrice>
          </StyledRight>
        </StyledTop>
        <StyledBottom>
          {type === 'hospital' ? (
            <StyledExcess>
              with {excess ? `$${excess}` : 'no'} excess
            </StyledExcess>
          ) : null}
          {summaryPoints ? (
            <StyledList>
              {summaryPoints.map(summaryPoint => (
                <StyledListItem key={summaryPoint._key}>
                  <StyledIcon name="green-tick" originalColorSchema />
                  <SanityRichTextContent content={summaryPoint.content} />
                </StyledListItem>
              ))}
            </StyledList>
          ) : null}
        </StyledBottom>
      </StyledContainer>
    </StyledArticle>
  );
};

ProductCard.propTypes = {
  excess: PropTypes.number,
  price: PropTypes.number,
  subtitle: PropTypes.string.isRequired,
  summaryPoints: PropTypes.array,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default ProductCard;
