import { useQuery } from '@apollo/client';
import { PromotionsQuery } from 'queries/promotions.graphql';
import moment from 'moment';
import { path } from 'lodash/fp';

const prepare = params => {
  const joinDate = path('variables.input.joinDate')(params);
  if (!joinDate) {
    return params;
  }

  return {
    ...params,
    variables: {
      input: {
        ...params.variables.input,
        joinDate: moment(joinDate).format('YYYY-MM-DD'),
      },
    },
    fetchPolicy: 'network-only',
  };
};

export default params => {
  const { data, ...rest } = useQuery(PromotionsQuery, prepare(params));

  return {
    data: data && data.promotions,
    ...rest,
  };
};
