import React from 'react';
import { withTheme } from 'emotion-theming';
import { Box } from 'rebass';

export const Col = withTheme(({ theme, extendPr, extendPl, ...props }) => {
  return (
    <Box
      pr={{
        xs: theme.padding.xs.paddingLeft,
        md: theme.padding.paddingLeft,
        ...extendPr,
      }}
      pl={{
        xs: theme.padding.xs.paddingLeft,
        md: theme.padding.paddingLeft,
        ...extendPl,
      }}
      {...props}
    >
      {props.children}
    </Box>
  );
});

export default Col;
