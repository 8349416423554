import React from 'react';
import Loading from '../atoms/Loading';
import useMembership from '../hooks/useMembership';
import { Container, Message } from 'components/Elements/ErrorPage';
import { useLocale } from '~common/locales';

const CheckMembership = ({ children }) => {
  const {
    loading,
    error,
    membership,
    isActive,
    personIsActive,
    isMaintenance,
  } = useMembership();

  const hambsError = error?.graphQLErrors
    ?.map(e => e?.extensions?.exception)
    .filter(e => e?.name === 'HAMBSError')[0];

  const { getLocaleString } = useLocale();

  if (loading) {
    return (
      <Container logo={false} actions={false}>
        <Loading width="60px" minHeight="140px" />
        <Message
          align="center"
          text={getLocaleString('oms.errorPage.loading')}
        />
      </Container>
    );
  }

  if (isMaintenance) {
    return (
      <Container logo={true} actions={false}>
        <Message
          type="error"
          text={getLocaleString('oms.errorPage.maintenance')}
        />
      </Container>
    );
  }

  if (error) {
    console.error(error);
    let text;
    if (hambsError?.status && hambsError.status !== 'INTERNAL_SERVER_ERROR') {
      text = getLocaleString(`oms.errorPage.hambsError.${hambsError.status}`);
    }
    return (
      <Container logo={true} actions={true} requestId={true}>
        <Message
          type="error"
          text={text || getLocaleString('oms.errorPage.error')}
        />
      </Container>
    );
  }

  if (!isActive) {
    const message = {
      TERMINATED: getLocaleString('oms.errorPage.terminated'),
      SUSPENDED: getLocaleString('oms.errorPage.suspended'),
    }[membership.coverStatus];
    return (
      <Container logo={true} actions={true}>
        <Message type="error" text={message} />
      </Container>
    );
  }

  if (!personIsActive) {
    const message = getLocaleString('oms.errorPage.personInactive');
    return (
      <Container logo={true} actions={true}>
        <Message type="error" text={message} />
      </Container>
    );
  }

  return children;
};

export default CheckMembership;
