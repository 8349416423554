import React from 'react';
import Layout from 'components/Layout';
import Navbar from '~common/organisms/Navbar';
import Wrapper from '~common/molecules/Wrapper';
import ManagePayments from '~OMS/payments/templates/ManagePayments';
import CheckMembership from '~common/organisms/CheckMembership';
import withQueryParams from '~lib/hocs/withQueryParams';
import OmsFooter from '~OMS/OmsFooter';

const PaymentsPage = props => {
  return (
    <Layout>
      <CheckMembership>
        <Navbar location={props.location || { pathname: '/oms' }} />

        <Wrapper>
          <ManagePayments queryParams={props.queryParams} />
        </Wrapper>
      </CheckMembership>
      <OmsFooter />
    </Layout>
  );
};

export default withQueryParams(PaymentsPage);
