import React, { useState } from 'react';
import HeaderLine from '~common/atoms/HeaderLine';
import SubNav from '~common/molecules/SubNav';
import { ControlledExpandable } from '~common/molecules/Expandable';
import { StandardText } from '~common/atoms/typography';
import MyDetailsSection from '~OMS/my-cover/change-cover/MyDetailsSection/MyDetailsSection';
import QuoteResults from '~OMS/my-cover/change-cover/Quote/QuoteResults';
import QuoteForm from '~OMS/my-cover/change-cover/Quote/QuoteForm';
import {
  ChangeCoverProvider,
  useChangeCoverContext,
} from '~OMS/my-cover/change-cover/state/ChangeCoverProvider';
import useCoverMenu from '~OMS/my-cover/hooks/useCoverMenu';
import ApplicationForm from '~OMS/my-cover/change-cover/ApplicationForm/ApplicationForm';
import Link from '~common/atoms/Link';
import useFiles from '~lib/hooks/sanity/useFiles';

const ChangeCoverInner = () => {
  const menu = useCoverMenu();
  const termsAndConditionsPdf = useFiles('hospitalFreeExtrasTermsConditions');
  const { state, actions } = useChangeCoverContext();
  const [openSections, setOpenSections] = useState({
    myDetails: true,
    coverDetails: true,
    quoteSummary: false,
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const toggle = (name, value) => {
    value = typeof value === 'undefined' ? !openSections[name] : value;
    const nextState = { ...openSections, [name]: value };

    if (name === 'quoteSummary') {
      nextState.myDetails = !value;
      nextState.coverDetails = !value;
    } else if (formSubmitted) {
      nextState.quoteSummary = false;
    }
    setOpenSections(nextState);
  };

  return state.activeView !== 'step3' ? (
    <>
      <HeaderLine title="My Cover">
        <SubNav data={menu} />
      </HeaderLine>
      <StandardText mt={50} fontSize={21} lineHeight="25px" bold={true}>
        What is subsidised extras?
      </StandardText>
      <StandardText my={40}>
        Access a 2 year extras subsidy as an Avant Intern/RMO.
      </StandardText>
      <StandardText mt={40}>
        As you currently hold an Indemnity Insurance Policy with Avant, you are
        eligible to upgrade your Lite Extras Cover to a combined Hospital +
        Extras policy, where the cost of your Extras will be subsidised by Avant
        for a two-year period (this is based on your continued membership with
        Avant during this time). We have three levels of Hospital + Extras cover
        to choose from, each of our Extras policies are much more comprehensive
        than Lite Extras Cover, allowing you to access higher limits and
        additional benefits as part of this offer.
      </StandardText>
      <StandardText mt={40}>
        For full Terms & Conditions{' '}
        <Link newTab to={termsAndConditionsPdf?.url} external>
          click here.
        </Link>
      </StandardText>
      <ControlledExpandable
        expanded={openSections.myDetails}
        toggle={() => toggle('myDetails')}
        mt={50}
        divider
        header={<StandardText fontSize={3}>My details</StandardText>}
        body={<MyDetailsSection />}
      />
      <ControlledExpandable
        expanded={openSections.coverDetails}
        toggle={() => toggle('coverDetails')}
        divider
        header={<StandardText fontSize={3}>Cover details</StandardText>}
        body={
          <QuoteForm
            onSubmit={(...args) => {
              setFormSubmitted(true);
              toggle('quoteSummary', true);
              actions.calculateCover(...args);
            }}
            form={state.coverDetails}
            onChange={actions.setCoverDetails}
          />
        }
      />
      {formSubmitted && (
        <ControlledExpandable
          expanded={openSections.quoteSummary}
          toggle={() => toggle('quoteSummary')}
          divider
          header={<StandardText fontSize={3}>Quote summary</StandardText>}
          body={
            <QuoteResults
              onSubmit={actions.joinNow}
              form={state.coverDetails}
              onChange={(name, value) => {
                actions.setCoverDetails(currentState => ({
                  ...currentState,
                  [name]: value,
                }));
              }}
            />
          }
        />
      )}
    </>
  ) : (
    <ApplicationForm />
  );
};

const ChangeCover = () => {
  return (
    <ChangeCoverProvider>
      <ChangeCoverInner />
    </ChangeCoverProvider>
  );
};

export default ChangeCover;
