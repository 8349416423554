import React from 'react';
// import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';
import { Flex, Box, Button } from 'rebass';
import { Link } from 'gatsby';

import FormTitle from '~OMS/styles/FormTitle';
import FormText from '~OMS/styles/FormText';
import SSOFormContainer from '~OMS/styles/SSOFormContainer';

// SSO Invite Others page wrapper component
function InviteOthersWrapper() {
  return (
    <SSOFormContainer>
      <FormTitle>Nearly there</FormTitle>
      <Flex>
        <Box width={1} mt="20px" mb="35px">
          <FormText>
            We've sent a verification email to{' '}
            <strong>tarahicko@gmail.com</strong>. Please click on the link to
            verify your email address before logging in again with your new ID.
          </FormText>
        </Box>
      </Flex>
      <Flex>
        <Box width={1} mb="30px">
          <strong style={{ fontSize: '21px' }}>Who else needs access?</strong>
        </Box>
      </Flex>
      <Flex>
        <Box width={1} mb="40px">
          <FormText>
            Other members on this policy will no longer be able to log in using
            the membership number. Please invite other members who need online
            access.
          </FormText>
        </Box>
      </Flex>
      <Flex>
        <Box width={1} mb="35px">
          <Button variant="orange" css={{ width: '100%' }} as={Link} to="/oms/">
            Invite others
          </Button>
        </Box>
      </Flex>
      <Flex>
        <Box width={1} css={{ textAlign: 'center' }}>
          <Link to="/oms/sso/login" style={{ color: '#191f28' }}>
            Skip for now
          </Link>
        </Box>
      </Flex>
    </SSOFormContainer>
  );
}

export default withTheme(InviteOthersWrapper);
