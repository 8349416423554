import React, { useContext } from 'react';
import { Flex, Box } from 'rebass';

import DateOfBirth from '~common/molecules/DateOfBirth';
import FormTitle from '~OMS/styles/FormTitle';
import FormText from '~OMS/styles/FormText';
import SSOFormContainer from '~OMS/styles/SSOFormContainer';
import { Formik, Form, Field } from 'formik';
import { Button } from '~common/atoms/Button';
import {
  InputControl,
  PasswordInputControl,
} from '~common/molecules/form-controls';
import { FormValidator, required } from '~lib/formValidation';
import HorizontalCenter from '~common/atoms/HorizontalCenter';
import validators from '~common/molecules/form-controls/validators';
import { Alert } from '~common/molecules/AlertBox';
import { AuthContext } from '../../../../context/sso';

const initialForm = {
  firstName: '',
  lastName: '',
  day: '',
  month: '',
  year: '',
  password: '',
  confirmPassword: '',
};

const passwordValidator = message => [
  required(message),
  value => {
    validators.password().validateSync(value);
  },
];

const validation = FormValidator({
  validation: {
    password: passwordValidator('Password is required field'),
    confirmPassword: [
      ...passwordValidator('Please re-enter your password'),
      (value, _, form) => {
        if (value !== form.password) {
          throw 'Re-entered password does not match';
        }
      },
    ],
  },
});

function VerifyInvitation({ queryParams }) {
  const { error, loading, verifyInvitationFlow } = useContext(AuthContext);

  const submitForm = form => {
    verifyInvitationFlow(queryParams.token, {
      lastName: form.lastName,
      dob: `${form.year}/${form.month}/${form.day}`,
      password: form.password,
    });
  };

  return (
    <SSOFormContainer>
      <FormTitle>Create your ID</FormTitle>
      <Flex>
        <Box width={1} mt="20px" mb="30px">
          <FormText>
            Thanks for verifying your email. Please complete your registration
            by filling in the following:
          </FormText>
        </Box>
      </Flex>
      <Formik
        onSubmit={submitForm}
        validate={validation}
        initialValues={initialForm}
      >
        {() => (
          <Form>
            <Field label="Last name" name="lastName" component={InputControl} />
            <DateOfBirth />
            <Field
              label="Create password"
              name="password"
              component={PasswordInputControl}
              validateStrength
            />
            <Field
              label="Re-Enter Password"
              name="confirmPassword"
              component={PasswordInputControl}
            />

            {error && (
              <Alert mb={4} type="error">
                {error}
              </Alert>
            )}

            <HorizontalCenter>
              <Button
                loading={loading}
                width={{
                  xs: 1,
                  md: 1 / 3,
                }}
                type="submit"
                variant="primary"
              >
                Next
              </Button>
            </HorizontalCenter>
          </Form>
        )}
      </Formik>
    </SSOFormContainer>
  );
}

export default VerifyInvitation;
