import React from 'react';
import Block from '../../common/atoms/Block';
import Heading from './Heading';
import { useThemeUI } from 'theme-ui';
import { Flex } from 'rebass';
import { ButtonsBase } from './Buttons';
import ProductCard from './ProductCard';
import CenterBox from '~common/atoms/CenterBox';

const PopularProducts = ({ data: { title, actions, products } }) => {
  const { theme } = useThemeUI();

  return (
    <Block
      bg={theme.colors.lightNeutral100}
      py={{
        xs: 64,
        lg: 100,
      }}
    >
      <Heading data={title} />

      <Flex
        flexDirection={{
          xs: 'column',
          tabletP: 'row',
        }}
        justifyContent="center"
      >
        {products.map((product, index) => {
          return (
            <ProductCard
              type={product.product.content.type}
              key={index}
              data={product.product.content}
              index={index}
              cardsCount={products.length}
            />
          );
        })}
      </Flex>
      <CenterBox>
        <ButtonsBase
          mt={{
            xs: 40,
            tabletP: 48,
          }}
          data={actions}
        />
      </CenterBox>
    </Block>
  );
};

export default PopularProducts;
