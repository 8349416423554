import React, { useState } from 'react';
import DatePicker from '~common/atoms/DatePicker';
import checkPaymentFactory from '~OMS/payments/molecules/PaymentChecks/checkPaymentFactory';
import PaymentAmountInfo from '~OMS/payments/molecules/PaymentAmountInfo';
import { compareDateOnly, toDate } from '~lib/dates';
import { MINIMUM_ALLOWED_PAYMENT_AMOUNT } from '~OMS/payments/constants';
import styled from '@emotion/styled';

const checkIfDisabled = currentlyCoveredUntil => date => {
  return compareDateOnly(currentlyCoveredUntil, date) > 0;
};

const StyledDatePicker = styled(DatePicker)`
  &&& {
    ${props => props.theme.mq.md} {
      width: calc(100% - 8px);
    }
  }
`;

export default checkPaymentFactory({
  buttonDataId: 'calculate-amount-btn',
  InputComponent: ({ coveredUntil, ...props }) => {
    const [mode, setMode] = useState();
    return (
      <StyledDatePicker
        {...props}
        mode={mode}
        onPanelChange={(value, mode) => {
          const newMode = mode === 'year' ? 'date' : mode;
          setMode(newMode);
        }}
        defaultPickerValue={toDate(coveredUntil)}
        disabledDate={checkIfDisabled(coveredUntil)}
      />
    );
  },
  checkButtonText: 'Calculate amount',
  fromPaymentCheck: data => data.amount,
  renderInfoAlert: ({
    inputValue,
    checkData,
    isMaximumPeriodExceeded,
    isBellowMinimumAmount,
  }) => (
    <PaymentAmountInfo amount={checkData} date={inputValue}>
      {isBellowMinimumAmount ? (
        <strong>
          Minimum allowed payment amount is ${MINIMUM_ALLOWED_PAYMENT_AMOUNT}
        </strong>
      ) : null}
    </PaymentAmountInfo>
  ),
});
