import useFeatureToggle from '~lib/hooks/useFeatureToggle';

const FeatureToggle = ({ render, name }) => {
  const featureToggleState = useFeatureToggle();
  const getContent = () => {
    return typeof render === 'function' ? render() : render;
  };
  return featureToggleState[name] ? getContent() : null;
};

export default FeatureToggle;
