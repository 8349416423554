import React from 'react';
import { Text } from 'rebass';
import { themePirmaryText } from '../../styles/colors';

const SSOText = ({ theme, children, style, ...props }) => {
  return (
    <Text
      mb={3}
      css={{ lineHeight: '1.31', ...style }}
      color={themePirmaryText}
      {...props}
    >
      {children}
    </Text>
  );
};

export default SSOText;
