import { useEffect } from 'react';
import { isRunningOnClientSide } from '~lib/util';

export default () => {
  useEffect(() => {
    if (isRunningOnClientSide()) {
      window.scrollTo({
        top: 0,
        left: 0,
      });
    }
  }, []);
};
