import React, { useMemo } from 'react';
import Icon from '../../../../common/atoms/Icon';
import { Flex } from 'rebass';
import { StandardText } from '../../../../common/atoms/typography';
import styled from '@emotion/styled';
import { fileSize } from '~lib/filters';

const StyledFileItem = styled(Flex)`
  border-bottom: 1px solid ${props => props.theme.colors.borders};
  ${props => props.theme.mq.mdInverted} {
    width: 100%;
  }
`;

const StyledRemoveIcon = styled(Icon)`
  pointer-events: ${props => (props.disableRemoval ? 'none' : 'all')};
`;

const FileItem = ({ file = {}, theme, onRemove, disableRemoval, ...props }) => {
  const size = useMemo(() => fileSize(file.size), [file]);
  return (
    <StyledFileItem
      py={2}
      theme={theme}
      alignItems="center"
      justifyContent="space-between"
      {...props}
    >
      <Flex alignItems="center">
        <Icon
          originalColorSchema
          name="file"
          width="24px"
          height="30px"
          mr={2}
        />
        <StandardText fontSize={1}>{file.name}</StandardText>
      </Flex>
      <Flex alignItems="center">
        <StandardText fontSize={1} mr={2}>
          {size}
        </StandardText>
        <StyledRemoveIcon
          disableRemoval={disableRemoval}
          clickable
          onClick={onRemove}
          fill={theme.colors.neutral500}
          name="circle-cross"
          width="15px"
        ></StyledRemoveIcon>
      </Flex>
    </StyledFileItem>
  );
};

export default React.memo(FileItem);
