import axios from 'axios';
const CancelToken = axios.CancelToken;

const searchUrl = `${process.env.DHF_SWIFTTYPE_BASE_URL}/engines/${process.env.DHF_SWIFTTYPE_ENGINE}/search`;

export default () => {
  let requestToken;
  const search = ({ query, ...otherParams }) => {
    if (requestToken) {
      requestToken.cancel(
        'Query canceled due to another query taking the place'
      );
    }

    if (!query) {
      return Promise.resolve({
        results: [],
      });
    }

    requestToken = CancelToken.source();
    return axios
      .post(
        searchUrl,
        {
          query,
          result_fields: {
            title: {
              raw: {
                size: 200,
              },
              snippet: {
                size: 200,
              },
            },
            content: {
              snippet: {
                size: 200,
              },
            },
            type: {
              raw: {
                size: 200,
              },
            },
            slug: {
              raw: {
                size: 200,
              },
              snippet: {
                size: 200,
              },
            },
          },
          ...otherParams,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.DHF_SWIFTTYPE_SEARCH_KEY}`,
          },
          cancelToken: requestToken.token,
        }
      )
      .then(({ data }) => {
        return data;
      });
  };

  return {
    search,
  };
};
