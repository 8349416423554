import React from 'react';
import useContactDetails from '~lib/hooks/sanity/useContactDetails';
import Icon from '../../../common/atoms/Icon';
import styled from '@emotion/styled';
import { useThemeUI } from 'theme-ui';

const StyledCallUsButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CallUsButton = props => {
  const { phone } = useContactDetails();
  const { theme } = useThemeUI();
  return (
    <StyledCallUsButton href={`tel:${phone}`} {...props}>
      <Icon clickable fill={theme.colors.primary} name="phone" width="17px" />
    </StyledCallUsButton>
  );
};

export default CallUsButton;
