import React from 'react';
import { Box } from 'rebass';
import { mapStackTrace } from 'sourcemapped-stacktrace';
import ErrorStackParser from 'error-stack-parser';
import sentry from '~lib/sentry';
import styled from '@emotion/styled';

import WorkingModal from './WorkingModal';
import { Button } from '../atoms/Button';
import CenterBox from '../atoms/CenterBox';
import { Alert } from './AlertBox';

const ErrorMessage = styled.strong`
  display: block;
  font-size: 15px;
  margin: 10px 0;
`;

const FileName = styled.strong`
  display: block;
  font-size: ${props => props.theme.fontSizes[1]}px;
  margin: 10px 0;
  color: #111;
  word-break: break-all;
`;

const StackTrace = styled.ul`
  margin: 10px 0;
`;

const StackFrame = styled.li`
  list-style: none;
  margin-left: 10px;
  font-size: ${props => props.theme.fontSizes[1]}px;
  font-weight: 400;
  line-height: 18px;
  color: #111;
  word-break: break-all;
`;

export class ModalErrorBoundary extends React.Component {
  static defaultProps = {
    editorScheme: 'vscode',
    getClearStateHandle: undefined,
  };
  constructor(props) {
    super(props);

    this.state = {
      error: undefined,
      componentStack: '',
      modalVisible: false,
      mapped: false,
    };

    if (props.getClearErrorHandle) {
      props.getClearErrorHandle(this.clearError.bind(this));
    }

    if (props.error) {
      const error = this.props.error;
      const stackLines = error.stack.split('\n');

      // There's no stack, only the error message.
      if (stackLines.length < 2) {
        this.state = { error, modalVisible: true, mapped: true };
        return;
      }

      // Webpack "eval" devtool is already formatted correctly.
      const isWebpackEval = stackLines[1].search(/\(webpack:\/{3}/) !== -1;
      if (isWebpackEval) {
        this.state = { error, modalVisible: true, mapped: true };
        return;
      }

      // Other eval devtools need to be parsed
      const isOtherEval = stackLines[1].search(/\(eval at/) !== -1;
      if (isOtherEval) {
        const fixedLines = [stackLines.shift()];

        for (const stackLine of stackLines) {
          const evalStackLine = stackLine.match(
            /(.+)\(eval at (.+) \(.+?\), .+(:[0-9]+:[0-9]+)\)/
          );

          if (evalStackLine) {
            const [, atSomething, file, rowColumn] = evalStackLine;
            fixedLines.push(`${atSomething} (${file}${rowColumn})`);
          } else {
            fixedLines.push(stackLine);
          }
        }

        error.stack = fixedLines.join('\n');
        this.state = { error, modalVisible: true, mapped: true };
      }
    }
  }

  componentDidMount() {
    if (this.props.error) {
      this.mapError(this.props.error);
    }
  }

  clearError() {
    if (this.state.error) {
      this.setState({
        error: undefined,
        componentStack: '',
        modalVisible: false,
        mapped: false,
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    if (error) {
      const { onError } = this.props;

      if (onError && typeof onError === 'function') {
        onError.call(this, error, errorInfo);
      }

      sentry(error, errorInfo);
      this.mapError(error, errorInfo);
    }
  }

  mapError(error, info) {
    mapStackTrace(error.stack, mappedStack => {
      error.stack = mappedStack.join('\n');

      this.setState({
        error,
        componentStack: info ? info.componentStack : '',
        modalVisible: true,
        mapped: true,
      });
    });
  }

  getFilename(error) {
    try {
      const frames = ErrorStackParser.parse(error);

      if (frames && frames.length) {
        const { fileName, lineNumber, columnNumber } = frames[0];

        // Remove webpack prefixes
        let formattedFilename = fileName
          .replace('webpack://', '.')
          .replace('webpack-internal://', '');

        // Remove webpack loader info
        const loaderIndex = formattedFilename.lastIndexOf('!');
        formattedFilename =
          loaderIndex < 0
            ? formattedFilename
            : formattedFilename.substr(loaderIndex + 1);

        // Remove prefix from absolute paths
        if (formattedFilename.indexOf('/' === 0)) {
          formattedFilename = formattedFilename.substr(1);
        }

        // Remove prefix from relative paths
        if (formattedFilename.indexOf('./' === 0)) {
          formattedFilename = formattedFilename.substr(2);
        }

        // Append line/column number
        if (lineNumber) {
          formattedFilename = `${formattedFilename}:${lineNumber}`;

          if (columnNumber) {
            formattedFilename = `${formattedFilename}:${columnNumber}`;
          }
        }

        return formattedFilename;
      }
    } catch (e) {}

    return undefined;
  }

  handleModalClose = () => {
    this.setState({
      modalVisible: false,
    });
  };

  render() {
    const { error, componentStack } = this.state;

    if (!error) {
      return this.props.children;
    }

    const filename = this.getFilename(error);
    console.error(error);

    return (
      <Box>
        <WorkingModal
          height="auto"
          visible={this.state.modalVisible}
          onClose={this.handleModalClose}
          title="Oops! Something went wrong!"
          width="940px"
          p="20px"
        >
          <Alert
            type="error"
            mb={4}
            mt={4}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Box>
              <ErrorMessage>{error.toString()}</ErrorMessage>
              {filename ? <FileName>{filename}</FileName> : null}

              <StackTrace>
                {componentStack.split('\n').map((line, idx) => (
                  <StackFrame key={idx}>{line}</StackFrame>
                ))}
              </StackTrace>
            </Box>
          </Alert>
          <CenterBox>
            <Button
              variant="primaryInverted"
              width={{
                xs: 1,
                md: 188,
              }}
              onClick={this.handleModalClose}
            >
              Close
            </Button>
          </CenterBox>
        </WorkingModal>
      </Box>
    );
  }
}
