import React, { useMemo, useState } from 'react';
import { withTheme } from 'emotion-theming';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Flex, Box, Text } from 'rebass';
import { isEmpty, prop, groupBy } from 'lodash/fp';
import Icon from '~common/atoms/Icon';
import { tierInfo } from '~lib/inclusionConstants';
import { useThemeUI } from 'theme-ui';
import InclusionsToggler from '~lib/CoverProducts/InclusionsToggler';
import useToggle from '~lib/hooks/useToggle';
import { StandardText } from '../../../../common/atoms/typography';
import SanityRichTextContent from '../../../../common/molecules/SanityRichTextContent';

const StyledContainer = styled(Box)`
  ${props => props.theme.mq.sm} {
    margin: 0;
    border: 2px solid ${props => props.theme.colors.borders};
  }
`;

const StyledIntro = styled.div`
  padding: 30px 25px;
  ${props => props.theme.mq.tabletP} {
    min-height: ${props => (props.isPartiallySubsidised ? '280px' : '300px')};
  }
`;

const StyledAccordion = styled.div`
  border-top: 1px solid #ccc;
  border-bottom: 1px solid transparent;
  line-height: 22px;
  cursor: pointer;
  background: ${props => props.bg};
  position: relative;
`;

const StyledArrow = styled.div`
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 8px;
  transform: ${props => (props.isActive ? 'rotate(180deg)' : undefined)};
`;

const tierLegend = ['other', 'basic', 'bronze', 'silver', 'gold'];

const getIcon = (theme, type = 'restricted') =>
  ({
    included: {
      name: 'oms-included',
      color: theme.colors.green,
    },
    'included-p': {
      name: 'oms-included',
      color: theme.colors.green,
    },
    excluded: {
      color: theme.colors.error,
      name: 'oms-excluded',
    },
    restricted: {
      name: 'restricted',
      color: theme.colors.warning,
    },
    'restricted-accident': {
      name: 'restricted',
      color: theme.colors.warning,
    },
  }[type.toLowerCase()]);

const descriptionStyle = css`
  &,
  & p,
  & li {
    font-size: 13px;
  }

  p,
  li {
    margin-bottom: 8px;
  }

  ul,
  ol {
    margin-top: 8px;
  }
`;

const renderInclusionsDesc = ({
  product,
  inclusion,
  descriptionStyle,
  description,
}) => {
  if (product.id === 'extras-cover-lite-extras') {
    return description ? (
      <SanityRichTextContent css={descriptionStyle} content={description} />
    ) : (
      inclusion.description && (
        <StandardText css={descriptionStyle}>
          {inclusion.description}
        </StandardText>
      )
    );
  }

  return inclusion.description ? (
    <StandardText css={descriptionStyle}>{inclusion.description}</StandardText>
  ) : (
    descriptionStyle && (
      <SanityRichTextContent content={description} css={descriptionStyle} />
    )
  );
};

const InclusionsAccordion = ({
  type,
  product,
  description,
  inclusion = {},
}) => {
  const { theme } = useThemeUI();
  const [isActive, setActiveIndex] = useState(false);

  const icon = getIcon(theme, type);
  return (
    <StyledAccordion bg={isActive ? '#f7f7f7' : 'transparent'}>
      <Flex
        css={css`
          position: relative;
          display: grid;
          grid-template-columns: min-content auto;
          grid-column-gap: 30px;
        `}
        alignItems="center"
        onClick={() => setActiveIndex(!isActive)}
        py="8px"
        pl="30px"
      >
        <Box width="17px">
          <Icon name={icon.name} fill={icon.color} width="17px" height="17px" />
        </Box>
        <Box>
          <Box pr={35}>{inclusion.title}</Box>
          <StyledArrow isActive={isActive}>
            <Icon name="oms-chevron" fill="#000" width="12px" height="7.2px" />
          </StyledArrow>
        </Box>
      </Flex>
      <Flex
        backgroundColor="#f7f7f7"
        css={{ display: isActive ? 'flex' : 'none' }}
      >
        <Box px={30} pb={10}>
          {renderInclusionsDesc({
            product,
            description,
            inclusion,
            descriptionStyle,
          })}
        </Box>
      </Flex>
    </StyledAccordion>
  );
};

const InclusionsList = ({
  theme,
  children,
  inclusions = [],
  coverage,
  product,
  type,
  ...props
}) => {
  const sortedInclusions = useMemo(() => {
    return groupBy(prop('tier'))(inclusions);
  }, [inclusions]);
  const showTiers = useMemo(
    () => sortedInclusions && Object.keys(sortedInclusions).length !== 1,
    [sortedInclusions]
  );

  const [inclusionsShown, toggleInclusions] = useToggle();

  return (
    <StyledContainer {...props}>
      <StyledIntro {...props}>{children}</StyledIntro>
      {sortedInclusions && !isEmpty(sortedInclusions) && (
        <InclusionsToggler
          type={type}
          hideOnMobile={false}
          open={inclusionsShown}
          onInclusionsToggle={() => {
            toggleInclusions();
          }}
        >
          {() => {
            return tierLegend.map((tier, i) => (
              <div key={i}>
                {sortedInclusions[tier] ? (
                  <>
                    {showTiers ? (
                      <Box
                        pl={15}
                        css={{ backgroundColor: tierInfo[tier].color }}
                      >
                        <Text
                          fontSize={1}
                          fontWeight={600}
                          lineHeight="30px"
                          color={tierInfo[tier].textColor || 'white'}
                        >
                          {tierInfo[tier].label}
                        </Text>
                      </Box>
                    ) : null}

                    {sortedInclusions[tier].map((e, i) => (
                      <InclusionsAccordion
                        product={product}
                        userCoverage={coverage}
                        key={i}
                        {...e}
                      />
                    ))}
                  </>
                ) : null}
              </div>
            ));
          }}
        </InclusionsToggler>
      )}
    </StyledContainer>
  );
};

export default withTheme(InclusionsList);
