import React from 'react';
import { FormPanel } from '../../common/Panel';
import { ButtonSwitchControl } from '~common/molecules/form-controls';
import BankAccountForm from './BankAccountForm';
import CreditCardForm from './CreditCardForm';
import styled from '@emotion/styled';
import FormField from '~common/molecules/FormField';
import CheckBoxControl from '~common/molecules/form-controls/CheckBoxControl';
import Link from '~common/atoms/Link';
import { PAYMENT_TYPE } from '~lib/constants';
import useFiles from '~lib/hooks/sanity/useFiles';

const debitTypes = [
  {
    id: PAYMENT_TYPE.BANK_ACCOUNT,
    text: 'Bank account',
  },
  {
    id: PAYMENT_TYPE.CREDIT_CARD,
    text: 'Credit card',
  },
];

const StyledDebitTypeField = styled(FormField)`
  margin-bottom: 45px;
`;

const DebitDetailsForm = ({
  Field,
  handleChange,
  values,
  debitDayDifferentToStartCoverDate,
}) => {
  const directDebitServiceAgreement = useFiles('directDebitServiceAgreement');
  return (
    <FormPanel title="Direct debit for premium payments">
      <StyledDebitTypeField
        onChange={handleChange}
        name="debitType"
        component={ButtonSwitchControl}
        buttons={debitTypes}
      />

      {values.debitType === PAYMENT_TYPE.BANK_ACCOUNT ? (
        <BankAccountForm
          debitDayDifferentToStartCoverDate={debitDayDifferentToStartCoverDate}
          Field={Field}
          values={values}
          handleChange={handleChange}
        />
      ) : (
        <CreditCardForm
          debitDayDifferentToStartCoverDate={debitDayDifferentToStartCoverDate}
          values={values}
          Field={Field}
          handleChange={handleChange}
        />
      )}
      <Field
        onChange={handleChange}
        name="directDebitAgreements"
        labelPosition="right"
        component={CheckBoxControl}
        label={({ LabelContainer }) => (
          <LabelContainer>
            I have read and agree to the{' '}
            <Link to={directDebitServiceAgreement?.url} external>
              Direct debit agreement
            </Link>
          </LabelContainer>
        )}
      />
    </FormPanel>
  );
};

export default DebitDetailsForm;
