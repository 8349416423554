import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';
import { withTheme } from 'emotion-theming';
import cx from 'classnames';

const BORDER_RADIUS = '30px';

const StyledButton = styled(Box)`
  appearance: none;
  border: none;
  border-radius: ${BORDER_RADIUS};
  min-width: 96px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  color: ${props =>
    props.active ? props.theme.colors.white : props.theme.colors.text.light};
  border-color: ${props => (props.active ? '' : 'transparent')};
  text-transform: none;
  font-weight: 600;
  background: ${props =>
    props.active ? props.theme.colors[props.color] : props.theme.colors.white};
  line-height: 13px;
  font-size: ${props => props.theme.fontSizes[1]}px;
  padding-top: 7px;
  padding-bottom: 7px;
  transition: all 300ms ease-in-out;
  &:hover {
    background: ${props => (props.active ? '' : props.theme.colors.white)};
    color: ${props => (props.active ? '' : props.theme.colors.text.light)};
  }
`;

const StyledButtonSwitch = styled(Flex)`
  border: 1px solid ${props => props.theme.colors.borders};
  border-radius: ${BORDER_RADIUS};
  align-items: center;
  padding: 2px;
  background: ${props => props.theme.colors.white};
`;

const ButtonSwitch = ({
  buttons,
  className,
  theme,
  onChange = () => {},
  value,
  color = 'orange',
  ...props
}) => {
  const [activeButtonId, setActive] = useState(null);

  useEffect(() => {
    setActive(value);
  }, [value]);

  return (
    <StyledButtonSwitch
      className={className}
      p={1}
      justifyContent="space-between"
      {...props}
    >
      {buttons.map(button => (
        <StyledButton
          data-id={button.id}
          key={button.id}
          as="button"
          color={color}
          theme={theme}
          aria-pressed={activeButtonId === button.id}
          active={activeButtonId === button.id}
          onClick={() => {
            onChange(button.id);
            setActive(button.id);
          }}
          className={cx('option', {
            active: activeButtonId === button.id,
            inactive: activeButtonId !== button.id,
          })}
        >
          {button.text}
        </StyledButton>
      ))}
    </StyledButtonSwitch>
  );
};

export default withTheme(ButtonSwitch);
