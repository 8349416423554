import { useApolloClient } from '@apollo/client';
import { path } from 'lodash/fp';
import {
  isCreditCardChangeApproved,
  sanitizeCardNumber,
} from '~OMS/payments/paymentUtils';
import { postFormDataForm } from '~lib/util';
import { CHANGE_CREDIT_CARD_STATUS } from '~OMS/payments/constants';
import { MafCreditCardCrn } from 'queries/maf.graphql';

export default () => {
  const apolloClient = useApolloClient();

  const getCrnData = () => {
    return apolloClient
      .query({
        query: MafCreditCardCrn,
        fetchPolicy: 'network-only',
      })
      .then(result => {
        return path('data.maf.crn')(result);
      });
  };

  const manageCard = async (values, errors, action, retries = 0) => {
    const {
      crn,
      fingerprint,
      merchantId,
      action: nabAction,
      timestamp,
      resultUrl,
    } = await getCrnData();
    const [expiryMonth, expiryYear] = values.expiry.split('/');

    const payload = {
      EPS_MERCHANT: merchantId,
      EPS_CRN: crn,
      EPS_RESULTURL: resultUrl,
      EPS_FINGERPRINT: fingerprint,
      EPS_ACTION: nabAction,
      EPS_TIMESTAMP: timestamp,
      EPS_TYPE: 'CRN',
      EPS_CARDNUMBER: sanitizeCardNumber(values.cardNumber),
      EPS_EXPIRYYEAR: expiryYear,
      EPS_EXPIRYMONTH: expiryMonth,
    };
    let response;
    try {
      response = await postFormDataForm('/nab/crn/manage', payload);
    } catch (error) {
      console.error(error);
    }

    const responseStatus = path('status')(response);

    if (isCreditCardChangeApproved(response) || !responseStatus) {
      return {
        crn: responseStatus == null ? undefined : crn,
      };
    }

    if (responseStatus === CHANGE_CREDIT_CARD_STATUS.NOT_FOUND) {
      return manageCard(values, errors, 'ADD', retries + 1);
    }

    if (responseStatus === CHANGE_CREDIT_CARD_STATUS.NOT_UNIQUE) {
      return manageCard(values, errors, 'ADD', retries + 1);
    }

    console.error('Saving credit card details failed', response, payload);
    return undefined;
  };

  return {
    manageCard,
  };
};
