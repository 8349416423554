import React, { useState } from 'react';

import ButtonSwitch from '~common/molecules/ButtonSwitch';
import { whereEq } from 'lodash/fp';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import Block from '~common/atoms/Block';
import { Box } from 'rebass';

export const TabsBase = ({ data, ...props }) => {
  const { tabs = [] } = data;
  const [selectedId, setSelected] = useState(tabs[0]?._key);

  const selected = tabs.find(
    whereEq({
      _key: selectedId,
    })
  );

  return (
    <Box {...props}>
      <ButtonSwitch
        color="blue"
        mb={4}
        onChange={setSelected}
        buttons={tabs.map(tab => ({
          id: tab._key,
          text: tab.title,
        }))}
        value={selectedId}
      ></ButtonSwitch>
      <SanityRichTextContent content={selected.content} />
    </Box>
  );
};

export default ({ data, ...props }) => {
  return (
    <Block {...props}>
      <TabsBase data={data} />
    </Block>
  );
};
