import React from 'react';
import AFBlock from '../../AFBlock';
import Icon from '~common/atoms/Icon';
import { H1, P1 } from '~common/atoms/typography';
import { Box, Flex } from 'rebass';
import { useThemeUI } from 'theme-ui';
import { useMobileScreenOnly } from '~lib/responsive';
import styled from '@emotion/styled';
import { useLocale } from '~common/locales';
import useSanityEligibility from '~lib/hooks/sanity/useSanityEligibility';
import useHeroImage from '~common/hooks/useHeroImage';
import HeroImage from '../../../PageBlocks/HeroImage';

const StyledHeroBackground = styled(HeroImage)`
  background: ${props => props.theme.colors.blue50};
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    height: 557px;
  }

  transform: inherit !important;
  width: inherit !important;
  min-width: inherit !important;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -100vw;
    bottom: 0;
    z-index: -1;
    width: 100vw;
    background: ${props => props.theme.colors.blue50};
  }
`;

const StyledHeroBackgroundInner = styled(Box)`
  margin-left: 50px;
`;

const SplashTitle = ({ theme }) => {
  const { getLocaleString } = useLocale();
  const organisationName = getLocaleString('common.organisationName').split(
    ' '
  );

  return (
    <Box
      textAlign={{
        xs: 'center',
        tabletP: 'left',
      }}
      mb={{
        xs: 20,
        tabletP: 0,
      }}
      mr={{
        xs: 0,
        tabletP: 16,
      }}
      maxWidth={{
        tabletP: 488,
      }}
      width={1}
    >
      <H1 inline color={theme.colors.primary} variant="semiBold">
        {' '}
        Thanks for choosing{' '}
        {organisationName.slice(0, organisationName.length - 2).join(' ')}
      </H1>{' '}
      <H1 variant="semiBoldItalic" inline color={theme.colors.secondary}>
        {organisationName.slice(-2).join(' ')}
      </H1>
    </Box>
  );
};

const benefits = [
  'Your medicare number',
  'Your current health fund details',
  'Personal details of any dependants',
  'Your payment or bank details',
];

const Tick = styled(Icon)`
  text {
    fill: ${props => props.theme.colors.tick};
  }
`;

const Benefits = ({ theme }) => {
  return (
    <Flex flexDirection="column">
      {benefits.map((benefit, index) => (
        <Flex
          key={index}
          alignItems="center"
          mb={index !== benefits.length - 1 ? '19px' : 0}
        >
          <Tick
            originalColorSchema
            name="green-tick"
            height="20px"
            width="20px"
          />
          <P1 variant="medium" color={theme.colors.black} ml="10px">
            {benefit}
          </P1>
        </Flex>
      ))}
    </Flex>
  );
};

const Desktop = ({ theme, heroBannerImage }) => {
  const { containerRef, containerWidth } = useHeroImage({
    subContainerClassName: 'maf-hero-banner',
  });
  const isMobile = useMobileScreenOnly();

  const content = (
    <>
      <P1
        width={1}
        maxWidth={415}
        mb="15.5px"
        variant="medium"
        color={theme.colors.black}
      >
        Before you begin your application make sure you have the following
        information on hand:
      </P1>
      <Benefits theme={theme} />
    </>
  );

  return (
    <>
      <AFBlock
        py={57}
        pt={0}
        pb={0}
        type="light"
        ref={containerRef}
        innerProps={{
          className: 'maf-hero-banner',
          display: 'flex',
          flexDirection: {
            xs: 'column',
            tabletP: 'row',
          },
        }}
      >
        <Flex
          alignItems="center"
          width={{
            xs: '100%',
            tabletP: '50%',
          }}
          minWidth={{
            xs: '100%',
            tabletP: '50%',
          }}
        >
          <SplashTitle theme={theme} />
        </Flex>
        {!isMobile && (
          <StyledHeroBackground
            image={heroBannerImage}
            containerWidth={containerWidth}
          >
            <StyledHeroBackgroundInner>{content}</StyledHeroBackgroundInner>
          </StyledHeroBackground>
        )}
      </AFBlock>
      {isMobile && (
        <AFBlock py={30} bg="blue50">
          {content}
        </AFBlock>
      )}
    </>
  );
};

export default props => {
  const { theme } = useThemeUI();
  const { heroBannerImage } = useSanityEligibility();

  return <Desktop heroBannerImage={heroBannerImage} theme={theme} {...props} />;
};
