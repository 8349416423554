import React, { useEffect, useState } from 'react';
import { scroller } from 'react-scroll';
import {
  MobileTabSwitcher,
  ProductBrowser,
  QuoteOverview,
  useCoverProducts,
} from '~lib/CoverProducts';
import { css } from '@emotion/core';
import { Flex } from 'rebass';
import Loading from '~common/atoms/Loading';
import CenterBox from '~common/atoms/CenterBox';
import StyledCoverProducts from '~OMS/my-cover/organisms/StyledCoverProducts';
import { isRunningOnClientSide } from '~lib/util';
import { StandardText, StandardHeading } from '~common/atoms/typography';
import CallUsNumber from '~common/molecules/CallUsNumber';

const QuoteResults = ({ onChange, onSubmit, ...props }) => {
  const [init, setInit] = useState(false);
  const {
    products,
    quote,
    selections,
    activeTab,
    setActiveTab,
    hospitalInclisionsOpen,
    toggleHospitalInclusions,
    extrasInclusionsOpen,
    toggleExtrasInclusions,
    selectedProduct,
    isExtrasVisible,
    isHospitalVisible,
    selectedRate,
    prices,
    setDefaultProduct,
  } = useCoverProducts(props.form, {
    getDefaultProducts: ({ status }) => {
      switch (status?.value) {
        case 'SINGLE':
        case 'COUPLE': {
          return {
            hospital: 'SS5',
            extras: 'B',
          };
        }
        default: {
          return {
            hospital: 'PS5',
            extras: 'E',
          };
        }
      }
    },
    onProductChange: (type, code) => {
      onChange(`${type}Product`, code);
    },
    onProductTypeChange: (type, rateType) => {
      onChange('rateType', rateType);
      if (type === 'extras') {
        onChange(`hospitalProduct`, undefined);
      } else if (type === 'hospital') {
        onChange('extrasProduct', undefined);
      }
    },
  });

  useEffect(() => {
    if (selectedProduct.hospital && !init) {
      // Redo the default once the hospital product is set
      // This is to ensure that the first extras product is set correctly
      setDefaultProduct('extras');
      setInit(true);
    }
  }, [init, products, setDefaultProduct]);

  const handleApply = async () => {
    onSubmit({
      ...props.form,
      hospitalProduct: selectedProduct.hospital || null,
      extrasProduct: selectedProduct.extras || null,
    });
  };

  const handleOnDetailsClick = () => {
    toggleExtrasInclusions(true);
    toggleHospitalInclusions(true);
    scroller.scrollTo('inclusions', {
      duration: 1000,
      delay: 0,
      isDynamic: true,
      offset: -70,
      smooth: 'easeInOutQuint',
    });
  };

  const renderContent = () => {
    if (quote.error) {
      console.error(quote.error);
      return `Error: ${quote.error.message}`;
    }

    if (quote.loading) {
      return (
        <CenterBox>
          <Loading size="90px" />
        </CenterBox>
      );
    }

    return (
      <>
        <QuoteOverview
          isLec
          emailQuote={false}
          submittingQuote={quote.loading}
          form={props.form}
          updateForm={onChange}
          setFormVisible={props.setFormVisible}
          onApply={handleApply}
          onDetails={handleOnDetailsClick}
          selections={selections}
          prices={prices}
          quote={quote}
          setSelected={onChange.bind(this, 'hospitalProduct')}
        />
        {isHospitalVisible && isExtrasVisible && (
          <MobileTabSwitcher activeTab={activeTab} onSelect={setActiveTab} />
        )}
        <StyledCoverProducts
          width={1}
          className="cover-products-no-space"
          activeTab={activeTab}
          showExtras={isExtrasVisible}
          showHospital={isHospitalVisible}
          hospitalProductsBrowser={
            <ProductBrowser
              price={prices?.hospital}
              products={products.hospital}
              selectedProduct={selectedProduct.hospital}
              paymentFrequency={props.form.paymentFrequency}
              status={props.form.status.value}
              type="hospital"
              selected={selections?.hospital?.product}
              selectedVariant={selections?.hospital?.variant}
              rate={selectedRate}
              open={hospitalInclisionsOpen}
              onInclusionsToggle={() => {
                toggleHospitalInclusions();
              }}
              onChange={onChange.bind(this, 'hospitalProduct')}
            />
          }
          extrasProductsBrowser={
            <ProductBrowser
              price={prices?.extras}
              products={products.extras}
              selectedProduct={selectedProduct.extras}
              paymentFrequency={props.form.paymentFrequency}
              status={props.form.status.value}
              type="extras"
              selected={selections?.extras?.product}
              selectedVariant={selections?.extras?.variant}
              open={extrasInclusionsOpen}
              onInclusionsToggle={() => {
                toggleExtrasInclusions();
              }}
              onChange={onChange.bind(null, 'extrasProduct')}
            />
          }
        ></StyledCoverProducts>
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          py="20px"
          px={{ xs: '8px', md: '20px' }}
          mt="20px"
          bg="#f7f7f7"
          css={css`
            border: 2px solid #dfdede;
          `}
        >
          <StandardHeading fontSize={18} bold={true} mb={2}>
            Looking for a higher level of Extras cover?
          </StandardHeading>
          <StandardText>
            You can combine any of our Hospital policies with a higher level of
            Extras cover and still receive a subsidy on your premium. For
            example Smart Starter Bronze Plus combined with Essential Extras, or
            Prime Choice Gold combined with Total Extras.
          </StandardText>
          <StandardText mt={2}>
            Contact us on <CallUsNumber inline bold /> to discuss the options
            available to you.
          </StandardText>
        </Flex>
      </>
    );
  };

  useEffect(() => {
    if (isRunningOnClientSide()) {
      window.scrollTo({ top: 0, left: 0 });
    }
  }, []);

  return renderContent();
};

export default QuoteResults;
