import React, { useCallback } from 'react';
import { StyledInput } from '~common/atoms/Input';
import { CreateControl } from '~common/molecules/form-controls/Control';

//TODO: the logic below is pretty handy and can be made as generic component, allowing passing the regex which will limit the allowable set of characters that the user can type in

const nameRegex = /[a-zA-Z-' ]/;
const alwaysAllowKeysMap = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
};

const alwaysAllowKeys = Object.values(alwaysAllowKeysMap);

const PersonNameControl = props => {
  const allowOnlyNameCharacters = useCallback(evt => {
    if (alwaysAllowKeys.includes(evt.keyCode) || nameRegex.test(evt.key)) {
      return;
    }

    evt.preventDefault();
  }, []);
  return <StyledInput trim onKeyDown={allowOnlyNameCharacters} {...props} />;
};

export default CreateControl(PersonNameControl);
