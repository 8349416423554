import React from 'react';
import styled from '@emotion/styled';

const StyledHeading = styled.h2`
  text-align: center;

  span {
    font-weight: bold;
  }
`;

const Heading = () => {
  return (
    <StyledHeading>
      You're <span>minutes</span> away from joining
    </StyledHeading>
  );
};

export default Heading;
