import React from 'react';
import { useThemeUI } from 'theme-ui';
import Icon from '~common/atoms/Icon';
import { TextResponsive } from '~common/atoms/typography';
import { Button } from '~common/atoms/Button';
import WarningModal from './WarningModal';
import { EFC_UPPER_AGE_LIMIT } from '~lib/constants';

const Over25Modal = ({ onRemove }) => {
  const { theme } = useThemeUI();
  return (
    <WarningModal>
      {() => (
        <>
          <Icon
            fill={theme.colors.error}
            name="stop-info"
            height="65px"
            width="65px"
          />
          <TextResponsive fontSize={31} lineHeight={1.2} my={32}>
            Can not cover {EFC_UPPER_AGE_LIMIT} years and over
          </TextResponsive>

          <TextResponsive lineHeight={1.2} fontSize={2} mb={32}>
            Dependents aged {EFC_UPPER_AGE_LIMIT} and over cannot be covered on
            a Family / Single Parent policy. They will need to take out their
            own health insurance policy.
          </TextResponsive>

          <Button
            maxWidth="100%"
            width="auto"
            variant="white-round"
            onClick={onRemove}
          >
            Remove this dependant but add others
          </Button>
        </>
      )}
    </WarningModal>
  );
};

export default Over25Modal;
