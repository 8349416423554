import { Field, Form, Formik } from 'formik';
import { InputControl } from '~common/molecules/form-controls';
import Confirmation from '~common/molecules/Confirmation';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import BsbControl from '~OMS/payments/molecules/BsbControl';
import { Alert } from '~common/molecules/AlertBox';
import { noop } from '~lib/util';
import { accountNameValidator } from '~OMS/payments/validators';

const validation = yup.object().shape({
  accountName: accountNameValidator(),
  accountBSB: yup.string().required('BSB is required'),
  accountNumber: yup.string().required('Account number is required'),
});

const EditForm = ({
  onCancel,
  onSubmit,
  initialForm,
  loading,
  alert,
  notExistingBsb = false,
}) => {
  let setFormErrors = noop;
  useEffect(() => {
    if (notExistingBsb) {
      setFormErrors({
        accountBSB: 'Please check this number',
      });
    }
  }, [notExistingBsb, setFormErrors]);
  return (
    <Formik
      validationSchema={validation}
      onSubmit={onSubmit}
      initialValues={initialForm}
    >
      {({ setErrors }) => {
        setFormErrors = setErrors;
        return (
          <Form className="full-width">
            <Field
              name="accountName"
              label="Account name"
              labelPosition="left"
              component={InputControl}
            />
            <Field
              name="accountBSB"
              label="BSB"
              labelPosition="left"
              component={BsbControl}
            />
            <Field
              name="accountNumber"
              label="Account number"
              labelPosition="left"
              component={InputControl}
            />

            {alert && alert.type !== 'success' && (
              <Alert mb={4} type={alert.type}>
                {alert.message}
              </Alert>
            )}

            <Confirmation loading={loading} onCancel={onCancel} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditForm;
