import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '~common/atoms/Button';

const Actions = ({ onApply }) => {
  return (
    <Button
      icon="chevron-right-thin"
      iconPosition="right"
      onClick={onApply}
      variant="white"
      size="small"
      width={{
        xs: '150px',
        tinyPhone: '180px',
      }}
    >
      Apply now
    </Button>
  );
};

Actions.propTypes = {
  onApply: PropTypes.func.isRequired,
};

export default Actions;
