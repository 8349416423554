import { mixed, string, number } from 'yup';
import * as Yup from 'yup';

function trim(x) {
  return x && x.trim ? x.trim() : '';
}

function formatPhoneE164(value) {
  return trim(value)
    .replace(/[()\s]/g, '')
    .replace(/^0/, '+61');
}

export const membershipNumber = (
  name = 'membershipId',
  message = 'Membership number is a 6 digit number'
) =>
  mixed().test({
    name,
    message,
    test: value => {
      return (
        number().positive().integer().isValidSync(value) &&
        string().length(6).isValidSync(value)
      );
    },
  });

export const username = (name = 'username') =>
  mixed().test({
    name,
    test: value => {
      const isValidEmail = string().email().isValidSync(value);
      const isValidMemberId = membershipNumber().isValidSync(value);

      return isValidEmail || isValidMemberId;
    },
    message: `${name} must be either valid email or membership number`,
  });

// according to AWS docs
const allowedSpecialCharacters = [
  '^',
  '$',
  '*',
  '.',
  '[',
  ']',
  '{',
  '}',
  '(',
  ')',
  '?',
  '-',
  '"',
  '!',
  '@',
  '#',
  '%',
  '&',
  '/',
  '\\',
  ',',
  '>',
  '<',
  "'",
  ':',
  ';',
  '|',
  '_',
  '~',
  '`',
];

export const specialCharsRegex = new RegExp(
  `[\\${allowedSpecialCharacters.join('\\')}]+`
);

export const MIN_PASSWORD_LENGTH = 8;

export const password = () =>
  string()
    .min(MIN_PASSWORD_LENGTH, 'Password must contain at least 8 characters')
    .matches(/\d+/, 'Password must contain at least one digit')
    .matches(/[a-z]+/, 'Password must contain at least one lowercase letter ')
    .matches(/[A-Z]+/, 'Password must contain at least one uppercase letter')
    .matches(
      specialCharsRegex,
      'Password must contain at least 1 special character'
    );

export const bsb = () =>
  string()
    // one character is dash "-"
    .min(7, 'BSB has exactly 6 digits')
    .max(7, 'BSB has exactly 6 digits')
    .matches(/\d{3}-\d{3}/g, 'Invalid BSB number');

const LAND_LINE_REGEX = /^\+61[1-9][0-9]{8}$/;

export const landLine = (message = 'Invalid land line number') =>
  string()
    .transform(formatPhoneE164)
    .matches(LAND_LINE_REGEX, message)
    .required();

const MOBILE_REGEX = /^\+614[0-9]{8}$/;

export const mobile = (message = 'Invalid mobile number') =>
  string().transform(formatPhoneE164).matches(MOBILE_REGEX, message);

export const landlineMobile = (message = 'Invalid phone number.') =>
  mixed().test({
    name: 'landlineMobile',
    test: value => {
      if (!value) {
        return true;
      }

      const transformed = formatPhoneE164(value);

      return (
        MOBILE_REGEX.test(transformed) || LAND_LINE_REGEX.test(transformed)
      );
    },
    message,
  });

export const day = () =>
  Yup.number()
    .integer()
    .min(1, 'Select between 1 and 31 for day')
    .max(31, 'Select between 1 and 31 for day');

export const month = () =>
  Yup.number()
    .min(1, 'Select between 1 and 12 for month')
    .max(12, 'Select between 1 and 12 for month');

export const year = () =>
  Yup.number()
    .integer()
    .min(1850, 'Invalid year')
    .max(new Date().getFullYear(), 'Year must not be in future');

export const dobValidator = (value, name, form) => {
  const validatingFragment = {
    [name]: Number(value),
  };
  const values = {
    dobDay: Number(form.dobDay),
    dobMonth: Number(form.dobMonth),
    dobYear: Number(form.dobYear),
    ...validatingFragment,
  };

  const date = new Date(
    values.dobYear,
    values.dobMonth - 1,
    values.dobDay || 1
  );

  if (Date.now() < date.getTime()) {
    throw 'Date of birth must not be in the future';
  }
};

export const email = () =>
  Yup.string()
    .email('Please enter a valid Email Address')
    .required('Email Address is required');

export const expiryDateValidator = ({ divider = '/' } = {}) =>
  mixed().test({
    name: 'expiryDate',
    test: (value = '') => {
      if (!value) {
        // leave this check to required validator
        return true;
      }
      const [month, year] = value.split(divider).map(Number);
      const currentDate = new Date();
      return (
        month <= 12 && month >= 1 && year >= currentDate.getFullYear() - 2000
      );
    },
    message: `Entered expiry date is not valid`,
  });

export const name = () =>
  Yup.string()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name');

export default {
  username,
  password,
  phone: landlineMobile,
  mobile,
  landLine,
  landlineMobile,
  membershipNumber,
  day,
  month,
  year,
  dobValidator,
  email,
  expiryDateValidator,
  bsb,
  name,
};
