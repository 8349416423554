import React from 'react';
import Layout from 'components/Layout';
import LegacyIDRetiredWrapper from '~OMS/SSO/organisms/LegacyIDRetiredWrapper';
import { withTheme } from 'emotion-theming';

const LegacyIDRetired = props => {
  return (
    <Layout>
      <LegacyIDRetiredWrapper />
    </Layout>
  );
};

export default withTheme(LegacyIDRetired);
