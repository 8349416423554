import React from 'react';
import Layout from 'components/Layout';
import ForgotPasswordWrapper from '~OMS/SSO/organisms/ForgotPasswordWrapper';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';

const StyledLayout = styled(Layout)`
  overflow: auto;
`;

const ForgotPassword = props => {
  return (
    <StyledLayout>
      <ForgotPasswordWrapper />
    </StyledLayout>
  );
};

export default withTheme(ForgotPassword);
