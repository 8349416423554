const data = [
  {
    id: 'home',
    icon: 'oms-home',
    iconSizes: {
      xs: {
        width: '33px',
        height: '28px;',
      },
      sm: {
        width: '38px',
        height: '32px',
      },
    },
    text: 'Home',
    href: '/oms',
  },
  {
    id: 'my-cover',
    icon: 'oms-umbrella',
    iconSizes: {
      xs: {
        width: '33px',
        height: '28px;',
      },
      sm: {
        width: '38px',
        height: '32px',
      },
    },
    text: 'My Cover',
    href: '/oms/my-cover',
    matchPartial: true,
  },
  {
    id: 'extras_usage',
    icon: 'oms-usage',
    iconSizes: {
      xs: {
        width: '33px',
        height: '28px;',
      },
      sm: {
        width: '38px',
        height: '32px',
      },
    },
    text: 'Extras',
    href: '/oms/extras-usage',
    matchPartial: true,
  },
  {
    id: 'claims',
    icon: 'quick-link',
    iconSizes: {
      xs: {
        width: '33px',
        height: '28px;',
      },
      sm: {
        width: '38px',
        height: '32px',
      },
    },
    text: 'Claims',
    matchPartial: true,
    href: '/oms/claims',
  },
  {
    id: 'payments',
    icon: 'oms-payments',
    iconSizes: {
      xs: {
        width: '33px',
        height: '28px;',
      },
      sm: {
        width: '38px',
        height: '32px',
      },
    },
    text: 'Payments',
    href: '/oms/payments',
    matchPartial: true,
  },
  {
    id: 'my-details',
    icon: 'oms-profile',
    iconSizes: {
      xs: {
        width: '33px',
        height: '28px;',
      },
      sm: {
        width: '38px',
        height: '32px',
      },
    },
    text: 'My Details',
    href: '/oms/my-details',
    matchPartial: true,
  },
  {
    id: 'my-documents',
    icon: 'oms-document',
    iconSizes: {
      xs: {
        width: '33px',
        height: '28px;',
      },
      sm: {
        width: '38px',
        height: '32px',
      },
    },
    text: 'Documents',
    href: '/oms/my-documents',
    matchPartial: true,
  },
];

export default data;

export const myCoverMenu = [
  {
    href: '/oms/my-cover',
    text: 'Cover details',
    id: 'cover-details',
  },
  {
    href: '/oms/my-cover/change',
    text: 'Compare cover',
    id: 'change-cover',
  },
];

export const claimsMenu = [
  {
    href: '/oms/claims',
    text: 'Make a claim',
    id: 'make-claim',
  },
  {
    href: '/oms/claims/history',
    text: 'Claims history',
    id: 'claims-history',
  },
];

export const paymentsMenu = [
  {
    href: '/oms/payments',
    text: 'Manage Payment',
    id: 'manage-payment',
  },
  // {
  //   href: '/oms/payments/history',
  //   text: 'Payment history',
  // },
];
