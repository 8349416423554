import React from 'react';
import styled from '@emotion/styled';
import { useThemeUI } from 'theme-ui';
import Icon from '~common/atoms/Icon';
import { EFC_UPPER_AGE_LIMIT } from '~lib/constants';

const StyledCallout = styled.aside`
  position: relative;
  margin-top: 24px;
  padding: 12px 16px 12px 56px;
  background-color: ${props => props.theme.colors.lightBlue};

  ${props => props.theme.mq.sm} {
    margin-top: 32px;
  }

  > div {
    position: absolute;
    top: 12px;
    left: 16px;
  }

  p {
    font-size: ${props => props.theme.fontSizes[2]}px;
    line-height: 18px;
    color: ${props => props.theme.colors.primary};
  }
`;

const Callout = () => {
  const { theme } = useThemeUI();
  return (
    <StyledCallout data-id="quote-callout">
      <Icon
        name="circle-fill-info"
        fill={theme.colors.primary}
        width="24px"
        height="24px"
      />
      <p>
        This quote includes an additional cost for Extended Family Cover, an
        optional upgrade which allows children who are not studying to be
        covered until they turn {EFC_UPPER_AGE_LIMIT}
      </p>
    </StyledCallout>
  );
};

export default Callout;
