import { capitalize } from '~lib/util';
import { PAYMENT_METHODS, PAYMENT_TYPE } from '~OMS/payments/constants';

export const parseDebitAccountType = type => {
  return type === 'BANK' ? PAYMENT_TYPE.BANK_ACCOUNT : PAYMENT_TYPE.CREDIT_CARD;
};

const infoMessage = message => ({
  type: 'info',
  message: `${message} Click save changes to change the way you pay the policy.`,
});

const byDebitAccountMessage = debitAccount => {
  if (debitAccount.type === 'BANK') {
    return infoMessage('You are currently paying with bank account.');
  }

  return infoMessage(
    `You are currently paying with ${capitalize(debitAccount.type)} card.`
  );
};

export const getChangePaymentPolicyInfoText = ({
  selectedPaymentMethod,
  debitAccount,
  activePaymentMethod,
  paymentType,
  isPayroll,
}) => {
  if (isPayroll && selectedPaymentMethod !== PAYMENT_METHODS.PAYROLL) {
    return {
      type: 'error',
      message:
        'After you save this change you will no longer be able to switch back to payroll via the site and will have to contact us directly.',
    };
  }
  switch (selectedPaymentMethod) {
    case PAYMENT_METHODS.INVOICE: {
      if (activePaymentMethod === PAYMENT_METHODS.INVOICE) {
        return undefined;
      }

      return byDebitAccountMessage(debitAccount);
    }

    case PAYMENT_METHODS.DIRECT_DEBIT: {
      if (activePaymentMethod === PAYMENT_METHODS.INVOICE) {
        return infoMessage(`You are currently paying with invoice.`);
      }

      const type = parseDebitAccountType(debitAccount.type);
      if (type !== paymentType) {
        return byDebitAccountMessage(debitAccount);
      }

      return undefined;
    }

    default: {
      return undefined;
    }
  }
};
