import React from 'react';
import Col from '~common/molecules/Col';
import { Row } from '~common/molecules/Row';
import CallUsNumber from '~common/molecules/CallUsNumber';
import RequestCallbackButton from '~common/molecules/RequestCallbackButton';
import { P2 } from '~common/atoms/typography';

const TalkToUsFooter = ({ isS2IMembership }) => {
  return (
    <Row
      mt={5}
      pb={4}
      flexDirection={{
        xs: 'column',
        md: 'row',
      }}
      justifyContent="space-around"
      alignItems="center"
    >
      <Col
        textAlign={{
          xs: 'center',
          md: 'left',
        }}
        mb={{
          xs: 3,
          md: 0,
        }}
      >
        <P2 variant="medium">
          {isS2IMembership
            ? 'Talk to us about your Hospital cover options on'
            : 'Talk to us about your options on'}{' '}
          <CallUsNumber inline bold fontSize={3} />{' '}
        </P2>
      </Col>
      <RequestCallbackButton
        mr={{
          xs: 0,
          md: 2,
        }}
        mb={{
          xs: 2,
          md: 0,
        }}
      ></RequestCallbackButton>
    </Row>
  );
};

export default TalkToUsFooter;
