import React, { useMemo } from 'react';
import { componentRegistry } from './PageBlocks';
import { last, path, pipe } from 'lodash/fp';
import usePromotions from '~lib/hooks/sanity/usePromotions';

const PageBlocksRenderer = ({ container, metaData = {}, next, prev }) => {
  const { data: promotionsData } = usePromotions({
    skip: path('slug.current')(container) !== 'home',
    variables: {
      input: {
        lens: 'homePage',
      },
    },
  });

  const blocks = useMemo(() => {
    return path('eligible')(promotionsData) &&
      path('slug.current')(container) === 'home'
      ? container.content
          .map((block, index) => {
            if (path('content.promoBanner')(promotionsData)) {
              return index === 3
                ? [promotionsData.content.promoBanner, block]
                : block;
            }

            return block;
          })
          .flat()
      : container?.content;
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotionsData, container?.slug, container?.content]);

  return (blocks || []).map((block, index) => {
    const Block = componentRegistry[block._type];
    if (!Block) {
      console.warn('Missing renderer for ', block._type);
      return null;
    }
    let prevBlock = index === 0 && prev ? prev : blocks[index - 1];
    let nextBlock =
      index === blocks.length - 1 && next ? next : blocks[index + 1];

    if (path('_type')(nextBlock) === 'sharedBlockComponent') {
      nextBlock = path('block.content[0]')(nextBlock);
    }

    if (path('_type')(prevBlock) === 'sharedBlockComponent') {
      prevBlock = pipe(path('block.content'), last)(prevBlock);
    }

    return (
      <Block
        key={block._key}
        data={block || {}}
        metaData={{
          ...metaData,
          page: container._type === 'page' ? container : metaData.page,
          context: container,
        }}
        index={index}
        blocks={blocks}
        prev={prevBlock}
        next={nextBlock}
      />
    );
  });
};

export default PageBlocksRenderer;
