import React from 'react';
import DebitDetailsForm from '../../../../../ApplicationForm/steps/Payments/DebitDetailsForm';
import ClaimPaymentForm from '../../../../../ApplicationForm/steps/Payments/ClaimPaymentForm';
import StepForm from '../../../../../ApplicationForm/common/components/StepForm';

const Payments = ({ appFormContext, context }) => {
  return (
    <StepForm
      showSaveButton={false}
      appFormContext={appFormContext}
      name="payments-form"
      title="Payment"
    >
      {({ Field, handleChange, values }) => (
        <>
          <DebitDetailsForm
            debitDayDifferentToStartCoverDate={
              context.debitDayDifferentToStartCoverDate
            }
            values={values}
            Field={Field}
            handleChange={handleChange}
          />

          <ClaimPaymentForm
            values={values}
            Field={Field}
            handleChange={handleChange}
          />
        </>
      )}
    </StepForm>
  );
};

export default Payments;
