import { useReducer } from 'react';
import { formatAddress } from '~lib/filters';
import { getAddress as addressify } from '~lib/util';

const types = {
  close: 'CLOSE',
  setValue: 'SET_VALUE',
  setQuery: 'SET_QUERY',
  pickValue: 'PICK_VALUE',
  getOption: 'GET_OPTION',
  setOptions: 'SET_OPTIONS',
  nextOption: 'NEXT_OPTION',
  prevOption: 'PREV_OPTION',
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.close: {
      return {
        ...state,
        show: false,
      };
    }
    case types.setValue: {
      const { value: v } = action;
      const value = typeof v === 'string' ? v : formatAddress(v);
      return {
        ...state,
        value,
        show: false,
        options: [],
        highlight: 0,
        suggestion: 0,
        searching: false,
      };
    }
    case types.setQuery: {
      return {
        ...state,
        show: true,
        options: [],
        highlight: 0,
        suggestion: 0,
        searching: true,
        value: action.value,
      };
    }
    case types.pickValue: {
      return {
        ...state,
        show: false,
        options: [],
        suggestion: 0,
        searching: false,
        value: action.value,
      };
    }
    case types.setOptions: {
      return {
        ...state,
        show: true,
        highlight: 0,
        searching: false,
        options: action.options.map(addressify),
      };
    }
    case types.nextOption: {
      const highlight = (state.highlight + 1) % state.options.length;
      return {
        ...state,
        highlight,
      };
    }
    case types.prevOption: {
      const highlight = state.highlight
        ? state.highlight - 1
        : state.options.length - 1;
      return {
        ...state,
        highlight,
      };
    }
    default:
      return state;
  }
};

export const useStateReducer = initial => {
  const [state, dispatch] = useReducer(reducer, initial);
  return [
    state,
    {
      close: () => dispatch({ type: types.close }),
      nextOption: () => dispatch({ type: types.nextOption }),
      prevOption: () => dispatch({ type: types.prevOption }),
      setQuery: value => dispatch({ type: types.setQuery, value }),
      setValue: value => dispatch({ type: types.setValue, value }),
      pickValue: value => dispatch({ type: types.pickValue, value }),
      setOptions: options => dispatch({ type: types.setOptions, options }),
    },
  ];
};
