import { graphql, useStaticQuery } from 'gatsby';

const FeatureToggleQuery = graphql`
  query FeatureToggleQuery {
    sanityFeatureToggle {
      ratesToggle
      ratesToggleDate
      efcQuote
      saveMaf
      referrals
      sso
      disablePayByInvoice
      disableSubsidisedExtrasPopup
    }
  }
`;

export default () => {
  const result = useStaticQuery(FeatureToggleQuery);

  return {
    sso: process.env.DHF_FEATURE_SSO === 'true',
    ratesToggle: process.env.DHF_FEATURE_RATES_TOGGLE === 'true',
    efcQuote: process.env.DHF_FEATURE_EFC_QUOTE === 'true',
    ...result.sanityFeatureToggle,
  };
};
