import { Box } from 'rebass';
import Icon from '~common/atoms/Icon';
import React from 'react';

const FormulaSymbol = ({ name, theme }) => (
  <Box
    px={{
      tabletP: 34,
    }}
  >
    <Icon
      name={name}
      stroke={theme.colors.white}
      fill={theme.colors.white}
      height="9px"
      width="9px"
    />
  </Box>
);

export default FormulaSymbol;
