import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Section from './Section';
import Cards from './Cards';
import Actions from './Actions';

const StyledItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    position: relative;
    padding-right: 16px;
    flex: 1;

    ${props => props.theme.mq.sm} {
      padding-right: 48px;
      flex: 0 1 27%;
    }

    &:nth-of-type(1),
    &:nth-of-type(2) {
      ${props => props.theme.mq.smInverted} {
        display: none;
      }
    }

    &:last-of-type {
      padding-right: 0;
      flex: none;
      margin-left: auto;
    }

    /* plus sign */
    &:first-of-type {
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: calc(50% - 1px);
        transform: translateY(-50%);
        background-color: ${props => props.theme.colors.white};
        border-radius: 4px;
        z-index: 1;
      }

      &:before {
        width: 2px;
        height: 12px;
        right: 30px;
      }

      &:after {
        width: 12px;
        height: 2px;
        right: 25px;
      }
    }

    /* equal sign */
    &:nth-of-type(2) {
      &:before,
      &:after {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        right: 25px;
        background-color: ${props => props.theme.colors.white};
        border-radius: 4px;
        width: 12px;
        height: 2px;
      }

      &:before {
        top: calc(50% - 3px);
      }

      &:after {
        top: calc(50% + 3px);
      }
    }
  }
`;

const FloatingSummary = ({
  form,
  headerHeight,
  onApply,
  prices,
  productType,
  quote,
  selections,
  setSummaryHeight,
  showFloatingSummary,
}) => {
  return (
    <Section
      headerHeight={headerHeight}
      setSummaryHeight={setSummaryHeight}
      showFloatingSummary={showFloatingSummary}
    >
      <StyledItems>
        <Cards
          form={form}
          prices={prices}
          productType={productType}
          quote={quote}
          selections={selections}
        />
        <Actions onApply={onApply} />
      </StyledItems>
    </Section>
  );
};

FloatingSummary.propTypes = {
  form: PropTypes.object.isRequired,
  headerHeight: PropTypes.number.isRequired,
  onApply: PropTypes.func.isRequired,
  prices: PropTypes.object.isRequired,
  productType: PropTypes.string.isRequired,
  quote: PropTypes.object.isRequired,
  selections: PropTypes.object.isRequired,
  setSummaryHeight: PropTypes.func.isRequired,
  showFloatingSummary: PropTypes.bool.isRequired,
};

export default FloatingSummary;
