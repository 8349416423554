import React from 'react';

import Block from '../../../../common/atoms/Block';

const HeaderBlock = ({ children, onMouseEnter, onMouseLeave, ...props }) => (
  <Block
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    py={20}
    {...props}
  >
    {children}
  </Block>
);

export default HeaderBlock;
