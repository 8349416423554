import React from 'react';
import { Field, Formik } from 'formik';
import {
  NumberInputControl,
  validators,
} from '~common/molecules/form-controls';
import AlertBox, { Alert } from '~common/molecules/AlertBox';
import { get } from 'lodash';
import { Button } from '~common/atoms/Button';
import * as Yup from 'yup';
import FormTitle from '~OMS/styles/FormTitle';
import { StandardText } from '../../../../common/atoms/typography';
import { noop } from '~lib/util';
import { useMobileScreen } from '~lib/responsive';
import { useThemeUI } from 'theme-ui';

const validation = Yup.object().shape({
  membershipId: validators
    .membershipNumber()
    .required('Membership number is required'),
});

const initialForm = {
  membershipId: '',
};

const RegisterForAccessForm = ({
  onSubmit,
  error,
  loading,
  alert,
  getFieldValueSetter = noop,
  getErrorSetter = noop,
}) => {
  const isMobile = useMobileScreen();
  const { theme } = useThemeUI();
  return (
    <>
      <FormTitle>Register Account</FormTitle>
      {alert && (
        <Alert mb={3} type={alert.type}>
          {alert.message}
        </Alert>
      )}

      <StandardText mb={3}>
        Create a login to access your policy online if you are a new member
      </StandardText>

      <StandardText bold color="dark" mt="20px" fontSize="18px" mb={3}>
        Let's get started
      </StandardText>
      <Formik
        validationSchema={validation}
        initialValues={initialForm}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, setFieldValue, setErrors }) => {
          getFieldValueSetter(setFieldValue);
          getErrorSetter(setErrors);
          return (
            <form data-id="register-for-access-form" onSubmit={handleSubmit}>
              <Field
                component={NumberInputControl}
                maxDigits={6}
                label="Enter your membership number"
                name="membershipId"
                id="membershipId"
                onBlur={() => {}}
                onFocus={
                  () => {} /* required in case field focus() gets set manually - avoid form validation on init */
                }
              />

              {error && <AlertBox error={get(error, 'message')} />}

              <Button
                showOnlyLoaderWhenActive={!isMobile}
                uppercase={false}
                naturalWidth={!isMobile}
                loading={loading}
                variant={theme.createAccountButtonStyle}
                type="submit"
              >
                Create my account
              </Button>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default RegisterForAccessForm;
