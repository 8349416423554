import styled from '@emotion/styled';
import AntdRadioGroup from 'antd/es/radio/group';
export const RadioGroup = styled(AntdRadioGroup)`
  .ant-radio-inner:after {
    background: ${props => props.theme.colors.primary};
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: ${props => props.theme.colors.primary};
  }

  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${props => props.theme.colors.primary};
  }

  .ant-radio-inner {
    height: 20px;
    width: 20px;
    &::after {
      height: 12px;
      width: 12px;
      transform: scale(1) translate(-50%, -50%) !important;
      margin: 0;
    }
  }

  .ant-radio-wrapper {
    white-space: normal !important;
    span:nth-child(2) {
      font-size: ${props => props.theme.fontSizes[3]}px;
      color: ${props => props.theme.colors.neutral800};
    }
  }
`;

export default RadioGroup;
