import { graphql, useStaticQuery } from 'gatsby';

const RELATIONSHIP_QUERY = graphql`
  query RelationshipQuery {
    sanityApplicationFormRelationship {
      partnerRelationshipMapping {
        _key
        id
        title
      }
      dependantRelationshipMapping {
        _key
        id
        title
      }
    }
  }
`;

export default type => {
  const result = useStaticQuery(RELATIONSHIP_QUERY);
  const {
    partnerRelationshipMapping: partner,
    dependantRelationshipMapping: dependant,
  } = result.sanityApplicationFormRelationship;

  if (!type) {
    return partner.concat(dependant);
  }

  return type === 'partner' ? partner : dependant;
};
