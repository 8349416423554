import {
  CreateControl,
  errorStateStyle,
  backgroundColorStyle,
} from './Control';
import styled from '@emotion/styled';
import { focusStyles } from '../../styles/formControls';
import { css } from '@emotion/core';

export const textAreaStyles = props => css`
  border: 2px solid ${props.theme.colors.borders};
  height: 160px;
  width: 100%;
  font-size: ${props.theme.fontSizes[2]}px;
  ${errorStateStyle(props)};
  ${backgroundColorStyle(props)};
  padding: 6px 13px;
  ${focusStyles(props)};
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  resize: none;
  &:focus {
    outline: none;
  }
`;

const TextAreaControl = styled.textarea`
  ${textAreaStyles};
`;

export default CreateControl(TextAreaControl);
