import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Icon from '~common/atoms/Icon';
import { useThemeUI } from 'theme-ui';

const StyledBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  width: 100%;
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const StyledLabel = styled.label`
  padding-bottom: 4px;
  font-size: ${props => props.theme.fontSizes[3]}px;
`;

const StyledSelect = styled.select`
  border-radius: 0;
  border: 1px solid #c9c9c9;
  padding: 10px;
  font-size: ${props => props.theme.fontSizes[3]}px;
  appearance: none;
  width: 100%;
  background-color: #fff;
  color: ${props => props.theme.colors.text} !important;

  &:disabled {
    background-color: #f6f6f6;
    pointer-events: none;
  }
`;

const StyledOption = styled.option`
  font-size: ${props => props.theme.fontSizes[3]}px;
  color: ${props => props.theme.colors.black} !important;
`;

const StyledIcon = styled(Icon)`
  right: 10px;
  top: 50%;
  position: absolute;
  transform: translateY(-46%);
  z-index: 2;
  pointer-events: none;
`;

const StyledHelpText = styled.div`
  padding-top: 8px;
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 18px;
  color: ${props => props.theme.colors.neutral500};
`;

const Select = ({
  className,
  disabledText,
  helpText,
  id,
  isDisabled,
  label,
  name,
  onChange,
  options,
  value,
}) => {
  const { theme } = useThemeUI();

  return (
    <StyledBlock className={className}>
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
      <StyledWrapper>
        <StyledSelect
          disabled={isDisabled}
          id={id}
          name={name}
          onChange={onChange}
          value={value}
        >
          {isDisabled ? (
            <StyledOption value="">{disabledText}</StyledOption>
          ) : (
            options.length &&
            options.map(option => (
              <StyledOption key={option.label} value={option.value}>
                {option.label}
              </StyledOption>
            ))
          )}
        </StyledSelect>
        <StyledIcon
          fill={theme.colors.text}
          stroke={theme.colors.text}
          height="17px"
          width="17px"
          name="chevron-down"
        />
      </StyledWrapper>
      {helpText ? <StyledHelpText>{helpText}</StyledHelpText> : null}
    </StyledBlock>
  );
};

Select.defaultProps = {
  disabledText: 'No options available',
  isDisabled: false,
  onChange: () => null,
  value: '',
};

Select.propTypes = {
  className: PropTypes.string,
  disabledText: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  value: PropTypes.string.isRequired,
};

export default Select;
