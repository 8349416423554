import React from 'react';
import { Flex } from 'rebass';
import { StandardText } from '~common/atoms/typography';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';

const ReferFriendContent = ({
  omsText,
  referRef,
  referFriendCmsContent,
  referralCode,
}) => {
  return (
    <>
      <Flex className="full-width" flexDirection="column" ref={referRef}>
        <SanityRichTextContent
          content={omsText}
          interpolate={referFriendCmsContent}
        />

        <StandardText mt={3}>{'Your unique referral code is:'}</StandardText>

        <StandardText mt={3} bold>
          {referralCode}
        </StandardText>
      </Flex>
    </>
  );
};

export default ReferFriendContent;
