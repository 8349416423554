import ModalConfirmation from '~common/molecules/ModalConfirmation';
import React from 'react';
import { StandardText } from '~common/atoms/typography';

export default ({ onClose, onConfirm, ...props }) => {
  return (
    <ModalConfirmation
      title=" Changes to your premium"
      message={
        <>
          <StandardText mb={2} color="light2">
            Your premium may be updated to reflect your change of residential
            state. You will receive an email confirming this change shortly.
          </StandardText>

          <StandardText color="light2">
            Would you like to proceed with this change?
          </StandardText>
        </>
      }
      confirmText="Yes"
      cancelText="No"
      onConfirm={onConfirm}
      onCancel={onClose}
      {...props}
    />
  );
};
