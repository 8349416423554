import React from 'react';
import Icon from '~common/atoms/Icon';
import { H2, P2 } from '~common/atoms/typography';
import { Button } from '~common/atoms/Button';
import { useThemeUI } from 'theme-ui';
import { Box, Flex } from 'rebass';

const RecalculateInfo = ({
  onConfirm,
  message = 'Your input has activated a change to the price already quoted. Expect to see the other price update shortly',
}) => {
  const { theme } = useThemeUI();
  return (
    <Box>
      <Flex
        mb={{
          xs: 20,
          tabletP: 40,
        }}
        flexDirection={{
          xs: 'column',
          tabletP: 'row',
        }}
        alignItems="center"
      >
        <Icon
          mb={{
            xs: '10px',
            tabletP: 0,
          }}
          mr={{
            xs: 0,
            tabletP: '20px',
          }}
          fill={theme.colors.secondary}
          name="recalculate"
          height="31px"
          width="31px"
        />
        <H2 variant="medium" color="primary">
          Recalculating Price
        </H2>
      </Flex>

      <P2 variant="medium" mb={20}>
        {message}
      </P2>

      <Button
        size="large"
        width={{
          xs: '100%',
          tabletP: 'max-content',
        }}
        variant="primary"
        onClick={onConfirm}
      >
        OK
      </Button>
    </Box>
  );
};

export default RecalculateInfo;
