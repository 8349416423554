export const CLAIM_STATUS = {
  ALL: 'ALL',
  PROCESSED: 'PROCESSED',
  PENDING: 'PENDING',
  SUBMITTED: 'SUBMITTED',
};

export const SERVICE_TYPES = {
  ALL: 'ALL',
  HOSPITAL: 'HOSPITAL',
  MEDICAL: 'MEDICAL',
  EXTRAS: 'EXTRAS',
  VARIOUS: 'VARIOUS',
};

export const DATE_FILTER_PERIODS = [1, 3, 6, 12, 36];

export const HISTORY_PAGE_SIZE = 20;

export const SUBMITTED_CLAIM_RENDERED_ID = 'To be advised';
