import { Field, Form, Formik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { Flex } from 'rebass';
import { object } from 'yup';
import { Button } from '~common/atoms/Button';
import { InputControl, validators } from '~common/molecules/form-controls';
import { noop } from '~lib/util';
import ModalConfirmation from '~common/molecules/ModalConfirmation';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import { VIEWS } from '~OMS/my-details/hooks/useReferFriend';
import { useLocale } from '~common/locales';
import Link from '~common/atoms/Link';
import Loading from '~common/atoms/Loading';
import styled from '@emotion/styled';

const validation = object().shape({
  inviteeName: validators.name().required("Invitee's name is required"),
  inviteeEmail: validators.email().required("Invitee's email is required"),
});

const PreviewEmailContainer = styled(Flex)`
  border: 1px solid ${props => props.theme.colors.borders};
`;

const PreviewLink = styled(Link)`
  align-self: flex-end;
  color: ${props =>
    props.loading ? props.theme.colors.borders : props.theme.colors.primary};
`;

const ReferFriendForm = ({
  showErrors,
  disclaimerText,
  onConfirm,
  onSubmit,
  view,
  loading,
  onClose,
  onPreviewEmail,
  previewedEmail,
  emailPreviewLoading,
}) => {
  const { getLocaleString } = useLocale();

  let triggerFormValidation = noop;

  useEffect(() => {
    if (showErrors) {
      triggerFormValidation();
    }
  }, [showErrors, triggerFormValidation]);

  const previewLinkText = useMemo(() => {
    if (emailPreviewLoading) {
      return 'Preview loading';
    }

    return previewedEmail ? 'Refresh preview' : 'Preview email';
  }, [emailPreviewLoading, previewedEmail]);

  return (
    <Flex pt={22}>
      <Formik onSubmit={onSubmit} validationSchema={validation}>
        {({ isValid, validateForm, values }) => {
          triggerFormValidation = validateForm;
          return view === VIEWS.REFER_FRIEND ? (
            <Form className="full-width">
              <Field
                name="inviteeName"
                label="Invitee's Name"
                labelPosition="left"
                component={InputControl}
              />
              <Field
                name="inviteeEmail"
                label="Invitee's Email"
                labelPosition="left"
                component={InputControl}
              />
              <Flex mb={20} flexDirection="column" justifyContent="flex-end">
                <PreviewLink
                  loading={emailPreviewLoading}
                  external
                  to="#"
                  onClick={evt => {
                    if (emailPreviewLoading) {
                      return;
                    }
                    evt.preventDefault();
                    onPreviewEmail({
                      name: values.inviteeName,
                      email: values.inviteeEmail,
                    });
                  }}
                >
                  {previewLinkText}
                </PreviewLink>

                {emailPreviewLoading ? (
                  <Loading size="medium" />
                ) : (
                  previewedEmail && (
                    <PreviewEmailContainer width={1} mt="10px">
                      <iframe
                        width="100%"
                        height="600px"
                        srcDoc={previewedEmail}
                        title="Preview email"
                      ></iframe>
                    </PreviewEmailContainer>
                  )
                )}
              </Flex>
              <Flex
                flexDirection={{
                  xs: 'column',
                  md: 'row',
                }}
                justifyContent="center"
              >
                <Button
                  data-id="submit-refer-friend-btn"
                  type="submit"
                  variant="secondary"
                  width={{
                    xs: 1,
                    md: 188,
                  }}
                  loading={loading}
                  disabled={!isValid}
                >
                  Send email
                </Button>
              </Flex>
              <Flex
                flexDirection={{
                  xs: 'column',
                  md: 'row',
                }}
                justifyContent="center"
                mt={2}
              >
                <SanityRichTextContent content={disclaimerText} />
              </Flex>
            </Form>
          ) : view === VIEWS.REFER_FRIEND_SUCCESS ? (
            <ModalConfirmation
              confirmButtonVariant="secondary"
              message={`Your friend will receive an email from ${getLocaleString(
                'common.organisationName'
              )}`}
              onCancel={onClose}
              onConfirm={onConfirm}
              title="Thanks for your referral!"
              width="100%"
            />
          ) : (
            <ModalConfirmation
              confirmButtonVariant="secondary"
              confirmText="Close"
              message="Sorry, something went wrong there. Please try again"
              onCancel={onClose}
              onConfirm={onConfirm}
              title="Something went wrong"
              width="100%"
            />
          );
        }}
      </Formik>
    </Flex>
  );
};

export default ReferFriendForm;
