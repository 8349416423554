import React from 'react';
import { Flex } from 'rebass';
import styled from '@emotion/styled';

import ClaimsCard from '~OMS/claims/molecules/ClaimsCard';
import Link from '~common/atoms/Link';
import useFilesAndBranding from '~lib/hooks/sanity/useFilesAndBranding';

const StyledLink = styled(Link)`
  font-weight: bold;
`;

const Cards = props => {
  const { files: claimForm, branding } = useFilesAndBranding('claimForm');
  return (
    <Flex
      flexDirection={{
        xs: 'column',
        md: 'row',
      }}
      justifyContent="space-between"
      {...props}
    >
      <ClaimsCard
        width={{
          xs: 1,
          md: '45%',
        }}
        mr={{
          xs: 0,
          md: 4,
        }}
        mb={{
          xs: 4,
          md: 0,
        }}
        icon="use-mobile"
        title="Claim using the app"
        message={
          <>
            Submit receipts via your phone with the{' '}
            <StyledLink
              to={branding.appleStoreUrl}
              external={true}
              target="_blank"
            >
              iPhone
            </StyledLink>{' '}
            or{' '}
            <StyledLink
              to={branding.googleStoreUrl}
              external={true}
              target="_blank"
            >
              Android
            </StyledLink>{' '}
            app.
          </>
        }
      />
      <ClaimsCard
        width={{
          xs: 1,
          md: '45%',
        }}
        icon="contact-us"
        title="Mail, Email or Fax us"
        message={
          <>
            Download a claim form and send a completed copy to the email or
            postal address detailed at the top of the form.{' '}
            <StyledLink to={claimForm?.url} target="_blank" external>
              Download now
            </StyledLink>
          </>
        }
      />
    </Flex>
  );
};

export default Cards;
