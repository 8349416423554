import React, { useContext, useEffect } from 'react';
import { withTheme } from 'emotion-theming';
import { Box, Flex } from 'rebass';
import { noop } from '~lib/util';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import FormTitle from '~OMS/styles/FormTitle';
import FormText from '~OMS/styles/FormText';
import SSOFormContainer from '~OMS/styles/SSOFormContainer';
import { Formik, Field } from 'formik';
import {
  PasswordInputControl,
  validators,
} from '~common/molecules/form-controls/index';
import { AuthContext } from 'context/sso';
import AlertBox from '~common/molecules/AlertBox';
import { Button } from '~common/atoms/Button';
import Icon from '~common/atoms/Icon.js';

const StyledIcon = styled(Icon)`
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  transition: color 300ms ease-in-out;
`;

const validation = {
  oldPassword: validators.password().required('Old password is required'),
  newPassword: validators.password().required('New password is required'),
  newPasswordConfirm: validators
    .password()
    .required('Password confirm is required'),
};

const validateForm = ({ newPassword, newPasswordConfirm }) => {
  const errors = {};

  try {
    validation.newPassword.validateSync(newPassword);
  } catch (err) {
    errors.newPassword = err.errors;
  }

  try {
    validation.newPasswordConfirm.validateSync(newPasswordConfirm);
  } catch (err) {
    errors.newPasswordConfirm = err.errors;
  }

  if (newPassword !== newPasswordConfirm) {
    errors.newPasswordConfirm = 'Confirm password must match the new password';
  }

  return errors;
};

const initialForm = {
  oldPassword: '',
  newPassword: '',
  newPasswordConfirm: '',
};

function ChangePasswordWrapper({ location, theme, state }) {
  let setFormValues = noop;
  let formValues;
  const { changePassword, error, loading } = useContext(AuthContext);

  useEffect(() => {
    if (!location || !location.state) {
      return;
    }

    // pre-populate member id
    setFormValues({
      ...formValues,
      user: location.state.membershipId,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <SSOFormContainer>
      <Link
        to={
          location && location.state
            ? location.state.prevPath
            : '/oms/sso/login'
        }
      >
        <StyledIcon
          // onClick={handleClose}
          stroke={theme.colors.black}
          name="close"
          height="30px"
          width="30px"
        />
      </Link>

      <FormTitle>Change password</FormTitle>
      <Box width={1} mt="20px" mb="30px">
        <FormText>Please enter the password you wish to use to login.</FormText>
      </Box>
      <Formik
        initialValues={initialForm}
        validate={validateForm}
        onSubmit={changePassword}
      >
        {({ handleSubmit, resetForm, setValues, values }) => {
          setFormValues = setValues;
          formValues = values;
          return (
            <form onSubmit={handleSubmit}>
              <Field
                name="oldPassword"
                label="Old Password"
                component={PasswordInputControl}
              />
              <Field
                name="newPassword"
                label="Create Password"
                component={PasswordInputControl}
                validateStrength
              />
              <Field
                name="newPasswordConfirm"
                label="Re-enter password"
                component={PasswordInputControl}
              />
              <Flex justifyContent="space-between" width={1} mt={1}>
                <Button
                  type="button"
                  onClick={() => resetForm()}
                  variant="primaryInverted"
                  mr={3}
                >
                  Clear
                </Button>
                <Button
                  loading={loading}
                  type="submit"
                  variant="primary"
                  css={{ width: '100%' }}
                >
                  Set password
                </Button>
              </Flex>
            </form>
          );
        }}
      </Formik>
      {error && <AlertBox error={error.message} />}
    </SSOFormContainer>
  );
}

export default withTheme(ChangePasswordWrapper);
