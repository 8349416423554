import React from 'react';
import WorkingModal from '~common/molecules/WorkingModal';
import { StandardText, H2 } from '../../../common/atoms/typography';
import CenterBox from '~common/atoms/CenterBox';
import { Button } from '~common/atoms/Button';

const spacing = '20px';

const QuotePopupMessage = ({ visible, onClose, quoteForm }) => {
  const isSingleParent = quoteForm.status.value === 'SINGLE_PARENT';

  return (
    <WorkingModal
      visible={visible}
      onClose={onClose}
      mobileHeight="50%"
      height="300px"
      data-id="quote-popup-message"
    >
      {isSingleParent ? (
        <>
          <H2>Important note about this quote</H2>
          <StandardText mt={4} mb={spacing} color="light">
            Your health insurance premium will not increase in 2020. The quote
            shown is based on the current rate which will not increase as part
            of our 1 October premium rate increase.
          </StandardText>
        </>
      ) : (
        <>
          <H2>Important note about this quote</H2>
          <StandardText mt={4} mb={spacing} color="light">
            As part of our continued support to those affected by COVID-19, we
            have deferred premium increases for Victorian members to 1 January
            2021. The quote shown is based on the current rate and will not
            increase as part of our 1 October premium rate increase.
          </StandardText>
        </>
      )}

      <CenterBox mt={42}>
        <Button
          data-id="agree-btn"
          onClick={() => {
            onClose();
          }}
          width={{ xs: 1, md: 180 }}
          variant="primaryInverted"
        >
          Close
        </Button>
      </CenterBox>
    </WorkingModal>
  );
};

export default QuotePopupMessage;
