import useFormModes from '~OMS/payments/hooks/useFormModes';
import { ClaimAccountDetailsQuery } from 'queries/oms/payments.graphql';
import { useQuery } from '@apollo/client';
import { path } from 'lodash/fp';
import useUpdatePaymentDetailsMutation from '~OMS/payments/hooks/useUpdatePaymentDetailsMutation';
import { useMemo, useState } from 'react';
import { isNonExistingBsb } from '~OMS/payments/paymentUtils';
import { NON_EXISTING_BSB_MESSAGE } from '~OMS/payments/constants';
import { dropEmpties } from '~lib/util';

const getPayload = form =>
  dropEmpties({
    effectiveDate: form.effectiveDate,
    claimAccount: {
      type: 'BANK',
      accountName: form.accountName,
      bankDetails: {
        accountBSB: form.accountBSB,
        accountNumber: form.accountNumber,
      },
    },
  });

export default () => {
  const formModes = useFormModes();
  const { data, loading } = useQuery(ClaimAccountDetailsQuery);
  const [updateData, setUpdateData] = useState();
  const [
    updatePayments,
    { loading: changingDetails, error: unexpectedUpdateError },
  ] = useUpdatePaymentDetailsMutation();

  const changeDetails = async form => {
    const payload = getPayload(form);
    const { data } = await updatePayments({
      variables: {
        input: payload,
      },
    });

    if (data.updatePayments.success) {
      formModes.actions.viewDetails();
    }

    setUpdateData(data);
  };

  const notExistingBsb = useMemo(
    () => isNonExistingBsb(unexpectedUpdateError),
    [unexpectedUpdateError]
  );

  const alert = useMemo(() => {
    if (changingDetails || (!unexpectedUpdateError && !updateData)) {
      return undefined;
    }

    if (unexpectedUpdateError) {
      return {
        type: 'error',
        message: notExistingBsb
          ? NON_EXISTING_BSB_MESSAGE
          : unexpectedUpdateError.message || unexpectedUpdateError,
      };
    }
    if (path('updatePayments.success')(updateData)) {
      return {
        type: 'success',
        message: 'Claims bank details have been successfully updated.',
      };
    }

    return {
      type: 'error',
      message: updateData.updatePayments.message,
    };
  }, [changingDetails, notExistingBsb, unexpectedUpdateError, updateData]);

  const viewDetails = () => {
    setUpdateData(undefined);
    formModes.actions.viewDetails();
  };

  return {
    state: {
      ...formModes.state,
      notExistingBsb,
      alert,
      loading,
      changingDetails,
      data: path('oms.payments.claimAccount.details')(data),
    },
    actions: {
      startEditing: formModes.actions.startEditing,
      viewDetails,
      changeDetails,
    },
  };
};
