import React, { useState } from 'react';
import { Box } from 'rebass';
import SaveForLaterSuccessModal from '../SaveForLaterSuccessModal';
import SaveForLaterMissingDetailsModal from '../SaveForLaterMissingDetailsModal';
import LeavePageModal from './LeavePageModal';
import ErrorModal from './ErrorModal';

const SaveForLaterModal = ({
  initialForm,
  onCancel,
  onConfirm,
  onSave,
  callUsNumber,
  workingTime,
  leaving,
  saveCompleted = false,
  allDataProvided,
  ...props
}) => {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState();
  const [successShown, showSuccess] = useState(saveCompleted);
  const [confirmationShown, showConfirmation] = useState(leaving);
  const [finalForm, setFinalForm] = useState(initialForm);

  const handleCancel = () => {
    if (!saving) {
      onCancel();
    }
  };

  let content;

  const handleSubmit = form => {
    setSaving(true);
    return onSave(form)
      .then(() => {
        setFinalForm(current => ({
          ...current,
          ...form,
        }));
        showSuccess(true);
        setSaving(false);
      })
      .catch(error => {
        setSaving(false);
        console.error(error);
        setError(error);
      });
  };

  if (confirmationShown) {
    content = (
      <LeavePageModal
        saving={saving}
        onCancel={handleCancel}
        onConfirm={() => {
          showConfirmation(false);
        }}
      />
    );
  } else if (error) {
    content = <ErrorModal onOk={onCancel} isSaveForLater={true} />;
  } else {
    content = successShown ? (
      <SaveForLaterSuccessModal
        onConfirm={onConfirm}
        email={finalForm.email}
        callUsNumber={callUsNumber}
        workingTime={workingTime}
      />
    ) : (
      <SaveForLaterMissingDetailsModal
        saving={saving}
        initialForm={initialForm}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    );
  }

  return <Box {...props}>{content}</Box>;
};

export default SaveForLaterModal;
