import { useEffect, useState, useMemo, useCallback } from 'react';
import { pathOr, path, whereEq, pipe, find, map } from 'lodash/fp';
import { useMutation, useQuery } from '@apollo/client';
import { dropEmpties, swap } from '~lib/util';
import { PERSON_ROLE } from '~OMS/my-details/contants';
import {
  MyDetailsQuery,
  UpdatePersonMutation,
  InviteMemberMutation,
} from 'queries/oms/myDetails.graphql';
import { DrawerQuery } from 'queries/oms/common.graphql';
import { updateActivity } from '~common/storeUpdaters';
import { getSortedByPerson } from '~OMS/response-selectors';
import { cloneDeep } from '@apollo/client/utilities';

const isPrimary = (member = {}) => member.role === PERSON_ROLE.MEMBER;
const isSecondary = (member = {}) => member.role === PERSON_ROLE.PARTNER;
const isDependent = (member = {}) => member.role === PERSON_ROLE.DEPENDANT;

const EMPTY_ARRAY = [];

const getUpdateMemberPayload = (id, form, memberToUpdate = {}) =>
  dropEmpties({
    id,
    // cognito force lowercase
    // don't send out email if it hasn't changed as there is an email change throttling on the API side
    email:
      form.email.toLowerCase() === memberToUpdate?.email?.toLowerCase()
        ? undefined
        : form.email.toLowerCase(),
    mobilePhone: form.mobilePhone,
    homePhone: form.homePhone,
    workPhone: form.workPhone,
  });

const getDetailsUpdatedMessage = (memberCard, payload) => {
  const { member, isCurrentUser } = memberCard;
  return member.email !== payload.email && payload.email && isCurrentUser
    ? `We have just sent an email to ${payload.email}. Verify this email address belongs to you by clicking on the link inside. Once verified you will be asked to log in again using your new email address.`
    : memberCard.canBeInvited
    ? `${member.firstName}'s contact details have been updated. Please click invite to submit the request.`
    : `${member.firstName}'s details have been saved successfully.`;
};

const useMembersCards = expandInitial => {
  const { data, loading } = useQuery(MyDetailsQuery);
  const [updateMember] = useMutation(UpdatePersonMutation);
  const [inviteMemberMutation] = useMutation(InviteMemberMutation);
  const members = useMemo(
    () =>
      pipe(
        pathOr(EMPTY_ARRAY, 'oms.membership.persons'),
        map(member => ({
          ...member,
          onlineAccess:
            member.onlineAccess &&
            (member.emailVerified || member.mobilePhoneVerified),
        }))
      )(data),
    [data]
  );

  const user = path('oms.user', data);
  const [memberCards, setMemberCards] = useState({});

  const setCard = (memberId, changes) => {
    setMemberCards(currentState => ({
      ...currentState,
      [memberId]: {
        ...currentState[memberId],
        ...changes,
      },
    }));
  };

  const isCurrentUser = useCallback(
    (member = {}) => user.personId === member.id,
    [user],
    []
  );

  const getCard = memberId => {
    return memberCards[memberId];
  };

  const getMember = memberId => getCard(memberId).member;

  useEffect(() => {
    setMemberCards(currentMemberCards => {
      return members.reduce(
        (acc, member) => ({
          ...acc,
          [member.id]: {
            // dependent users can not be invited at the moment, this is after go live feature
            expanded:
              expandInitial &&
              expandInitial === member.id &&
              !isDependent(member),
            ...currentMemberCards[member.id],
            isCurrentUser: isCurrentUser(member),
            isMainMember: isPrimary(member),
            isDependentMember: isDependent(member),
            member,
          },
        }),
        {}
      );
    });
  }, [expandInitial, isCurrentUser, members, user]);

  const toggleCard = (memberId, expanded) => {
    const card = getCard(memberId);
    setCard(memberId, {
      expanded: expanded != null ? expanded : !card.expanded,
      showErrors: expanded ? getMember(memberId).showErrors : false,
      alert: undefined,
    });
  };

  const saveMemberDetails = async (memberId, details, canBeInvited) => {
    const memberCard = getCard(memberId);
    setCard(memberId, {
      loading: true,
      alert: undefined,
    });

    const memberToUpdate = members.find(member => member.id === memberId);

    const payload = getUpdateMemberPayload(memberId, details, memberToUpdate);
    await updateMember({
      variables: {
        input: payload,
      },
      update: (store, { data: { updatePerson } }) => {
        if (!updatePerson.success) {
          setCard(memberId, {
            loading: false,
            showErrors: true,
            alert: {
              type: 'error',
              message: updatePerson.message,
            },
          });

          return;
        }
        const currentDetails = cloneDeep(
          store.readQuery({ query: MyDetailsQuery })
        );
        currentDetails.oms.membership.persons = swap(
          updatePerson.person,
          'id',
          currentDetails.oms.membership.persons
        );
        store.writeQuery({ query: MyDetailsQuery, data: currentDetails });

        const data = cloneDeep(store.readQuery({ query: DrawerQuery }));
        data.oms.activities.unshift(updatePerson.activity);
        store.writeQuery({ query: DrawerQuery, data });

        setCard(memberId, {
          loading: false,
          showErrors: false,
          alert: {
            type: 'success',
            message: getDetailsUpdatedMessage(
              {
                ...memberCard,
                canBeInvited,
              },
              payload
            ),
          },
        });
      },
    }).catch(error => {
      setCard(memberId, {
        loading: false,
        showErrors: true,
        alert: {
          type: 'error',
          message: error.message,
        },
      });
    });
  };

  const inviteMemberUpdate = (store, { data }) => {
    const response = data.invitePerson;

    if (response.success) {
      updateActivity({
        mutationName: 'invitePerson',
        mapActivity: path('invitePerson.activity'),
      });

      const data = cloneDeep(store.readQuery({ query: MyDetailsQuery }));
      data.oms.membership.persons = swap(
        response.person,
        'id',
        data.oms.membership.persons
      );

      store.writeQuery({ query: MyDetailsQuery, data });
    }
  };

  const inviteMemberBase = memberPayload => {
    setCard(memberPayload.id, {
      inviting: true,
      alert: undefined,
    });
    return inviteMemberMutation({
      variables: {
        input: memberPayload,
      },
      update: inviteMemberUpdate,
    })
      .then(({ data }) => {
        return data.invitePerson;
      })
      .then(response => {
        return response.success ? response : Promise.reject(response.message);
      })
      .then(({ person }) => {
        setCard(person.id, {
          expanded: true,
          alert: {
            type: 'info',
            message: `Invitation has been sent to ${person.firstName}'s email address: ${person.email}.`,
          },
        });
      })
      .then(() => {
        setCard(memberPayload.id, {
          inviting: false,
        });
      })
      .catch(error => {
        setCard(memberPayload.id, {
          inviting: false,
        });
        setCard(memberPayload.id, {
          alert: {
            type: 'error',
            message: `Invitation has failed. Please try again.`,
          },
        });
      });
  };

  const inviteMemberWithCheck = memberId => {
    const { email, mobilePhone } = memberCards[memberId].member;
    if (!email || !mobilePhone) {
      setCard(memberId, {
        expanded: true,
        showErrors: true,
        alert: {
          type: 'error',
          message: `Please update any missing or incorrect data before we invite ${
            getCard(memberId).member.firstName
          }.`,
        },
      });
      return undefined;
    }

    return inviteMemberBase({
      id: memberId,
      email,
      mobile: mobilePhone,
    });
  };

  const inviteMember = (memberId, form) => {
    return inviteMemberBase({
      id: memberId,
      email: form.email,
      mobile: form.mobilePhone,
    });
  };

  // selectors
  const memberAccessAllowed = useMemo(() => {
    return !user || !members
      ? false
      : pipe(
          find(
            whereEq({
              id: user.personId,
            })
          ),
          member => isPrimary(member) || isSecondary(member)
        )(members);
  }, [members, user]);

  const sortedCards = useMemo(() => {
    const cards = Object.values(memberCards);

    return getSortedByPerson('member')(cards);
  }, [memberCards]);

  return {
    toggleCard,
    inviteMember,
    inviteMemberWithCheck,
    saveMemberDetails,
    memberCards: sortedCards,
    membersLoading: loading,
    memberAccessAllowed,
  };
};

export default useMembersCards;
