import useLocalStorage from '~lib/hooks/useLocalStorage';
import React, { useMemo } from 'react';
import { path } from 'lodash/fp';
import { openModal } from '../../common/appFormUtills';
import RecalculateInfo from '../../common/components/modals/RecalculateInfo';
import { navigate } from 'gatsby';

export default () => {
  const [criteriaRaw, setCriteriaRaw, removeCriteria] = useLocalStorage('form');

  const criteria = useMemo(() => {
    if (
      !['region', 'status', 'dob', 'tier'].every(prop => criteriaRaw?.[prop])
    ) {
      navigate('/quote');
      return undefined;
    }

    return {
      ...criteriaRaw,
      paymentFrequency: path('paymentFrequency', criteriaRaw) || 'MONTHLY',
    };
  }, [criteriaRaw]);

  const setCriteria = (data, { notifyPriceChange = false } = {}) => {
    if (!notifyPriceChange) {
      setCriteriaRaw(data);
    } else {
      openModal(
        ({ close }) => (
          <RecalculateInfo
            onConfirm={() => {
              setCriteriaRaw(data);
              close();
            }}
          />
        ),
        {
          padding: true,
        }
      );
    }
  };

  return {
    criteria,
    setCriteria,
    removeCriteria,
  };
};
