import React from 'react';
import { H2, P } from '../../../../common/atoms/typography';
import Link from '~common/atoms/Link';

const ChangeCoverIntroS2I = () => (
  <>
    <H2 pb={20}>Need Hospital Cover?</H2>
    <P mb={20}>
      We have a range of quality Hospital covers designed for different stages
      of life, like our{' '}
      <Link to="/hospital-cover-smart-starter">Smart Starter Bronze Plus</Link>
       hospital cover which is designed specifically for young doctors. It
      covers you for the things you're likely to need and nothing
      you don't (such as hip replacements and cataracts).
    </P>
    <H2 pb={20}>Aged 18 – 29? You are eligible for the youth discount.</H2>
    <P mb={20}>
      If you are aged 18 – 29 you can save up to 10% on your hospital cover
      premium with the Australian Government's youth discount initiative. This
      discount stays with you until you turn 41, so it's beneficial to take our
      cover soon rather than later so that you can maximise your savings. This
      is a voluntary discount and not all health funds have adopted this scheme.
    </P>
    <H2 pb={20}>Avoid the Medicare Levy Surcharge.</H2>
    <P>
      If you're single and earning over $97,000, or in a family with a combined
      income of over $194,000, and don't have the appropriate level of hospital
      cover, then you may be required to pay the Medicare Levy Surcharge.
      Avoid the Medicare Levy Surcharge by upgrading to 
      <Link to="/compare-hospital-products">Hospital cover</Link>.
    </P>
  </>
);

export default ChangeCoverIntroS2I;
