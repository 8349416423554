import React from 'react';
import { StandardText } from '../../../../common/atoms/typography';

const FormTitle = ({ children, ...props }) => {
  return (
    <StandardText {...props} bold fontSize={21} lineHeight="21px">
      {children}
    </StandardText>
  );
};

export default FormTitle;
