import React from 'react';
import { DropDownControl } from '~common/molecules/form-controls';
import { Option } from '~common/atoms/DropDown';
import { Field } from 'formik';
import {
  PAYMENT_FREQUENCIES_PER_METHOD,
  PAYMENT_METHODS,
} from '~OMS/payments/constants';

const PaymentFrequencyField = ({
  name = 'frequency',
  paymentMethod = PAYMENT_METHODS.DIRECT_DEBIT,
  labelPosition = 'left',
  label = 'Payment Frequency',
}) => {
  return (
    <Field
      name={name}
      render={props => (
        <DropDownControl label={label} labelPosition={labelPosition} {...props}>
          {PAYMENT_FREQUENCIES_PER_METHOD[paymentMethod].map(frequency => (
            <Option key={frequency.id} value={frequency.id}>
              {frequency.text}
            </Option>
          ))}
        </DropDownControl>
      )}
    />
  );
};

export default PaymentFrequencyField;
