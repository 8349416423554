import React, { useState, useCallback, forwardRef } from 'react';
import { backgroundColorStyle, CreateControl } from './Control';
import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';

import Link from '~common/atoms/Link';
import { Button } from '../../atoms/Button';
import { useMobileScreen } from '~lib/responsive';
import { windowHasSelection } from '~lib/util';
import { StyledInput } from '../../atoms/Input';
import PasswordStrengthIndicator from '../PasswordStrengthIndicator';
import { ActiveListener } from 'react-event-injector/dist/es2015/ActiveListener';

const StyledPasswordInputWrapper = styled(Flex)`
  width: 100%;
  align-items: center;
  position: relative;
  border: 1px solid ${props => props.theme.colors.borders};
  line-height: 24px;
  font-size: ${props => props.theme.fontSizes[2]}px;
  transition: all 500ms ease-in-out;
  ${props =>
    props.error ? `border-color: ${props.theme.colors.error} !important` : ''};
  ${backgroundColorStyle};
  -webkit-appearance: none;
  && input {
    border: none;
    background: none;
  }

  #com-1password-op-button {
    display: none !important;
  }
`;

const TogglePasswordBox = styled(Box)`
  color: ${props => props.theme.colors.text.light};
  position: absolute;
  height: 30px;
  right: 2px;
  min-width: 61px;
  border-radius: 5px;
  background: ${props => props.theme.colors.background.gray};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  font-size: ${props => props.theme.fontSizes[1]}px;
`;

const PasswordInput = React.forwardRef(
  ({ theme, error, innerRef, validateStrength, ...props }, ref) => {
    const [showPassword, togglePassword] = useState(false);
    const [showStrengthIndicator, toggleStrengthIndicator] = useState(false);
    const [currentPW, setCurrentPW] = useState('');

    const handleTogglePassword = useCallback(
      evt => {
        evt.preventDefault();
        togglePassword(!showPassword);
      },
      [showPassword]
    );

    const handlePasswordKey = e => {
      setCurrentPW(e.target.value);
      if (e.target.value.length) {
        toggleStrengthIndicator(true);
      } else {
        toggleStrengthIndicator(false);
      }
    };

    return (
      <>
        <StyledPasswordInputWrapper error={error}>
          <StyledInput
            theme={theme}
            {...props}
            autoComplete="off"
            innerRef={innerRef}
            type={showPassword ? 'text' : 'password'}
            onKeyUp={e => {
              if (validateStrength) {
                handlePasswordKey(e);
                if (e.keyCode === 13) {
                  toggleStrengthIndicator(false);
                }
              }
            }}
            onBlur={() =>
              validateStrength ? toggleStrengthIndicator(false) : undefined
            }
          />
          <TogglePasswordBox theme={theme} onClick={handleTogglePassword}>
            {showPassword ? 'hide' : 'show'}
          </TogglePasswordBox>
        </StyledPasswordInputWrapper>
        {validateStrength && showStrengthIndicator && (
          <PasswordStrengthIndicator password={currentPW} />
        )}
      </>
    );
  }
);

const ChangePasswordBtn = styled(Button)`
  text-decoration: none !important;
  flex-grow: 1;
  flex-basis: 0;
`;

const ChangePasswordInput = styled(StyledInput)`
  border-width: 2px;
  flex-grow: 1;
  flex-basis: 0;
  margin-right: 16px;
`;

const ChangePassword = ({ className, onPasswordChange, theme, ...props }) => {
  const isMobileScreen = useMobileScreen();

  return (
    <Flex className="className" justifyContent="space-between">
      <ChangePasswordInput
        autoComplete="off"
        disabled
        theme={theme}
        {...props}
        type="password"
      />
      <Link
        css={{ textDecoration: 'none' }}
        to="/oms/sso/changePassword"
        state={{ prevPath: '/oms/my-details' }}
      >
        <ChangePasswordBtn type="button" variant="secondaryInverted">
          Change {!isMobileScreen && 'Password'}
        </ChangePasswordBtn>
      </Link>
    </Flex>
  );
};

const InputControlBasic = CreateControl(StyledInput);

export const InputControl = forwardRef(({ maxChars, ...props }, ref) => (
  <InputControlBasic
    onKeyPress={evt => {
      const { nativeEvent } = evt;
      if ([13].includes(nativeEvent.keyCode)) {
        return;
      }
      if (
        maxChars != null &&
        (nativeEvent.target.value + nativeEvent.key).length > maxChars
      ) {
        evt.preventDefault();
        evt.stopPropagation();
      }
    }}
    ref={ref}
    {...props}
  />
));

export const PasswordInputControl = CreateControl(PasswordInput);
export const NumberInput = forwardRef(({ maxDigits, field, ...props }, ref) => {
  const [isFocused, setFocused] = useState(false);
  const { onBlur, onFocus, onKeyPress } = field || {};
  return (
    <ActiveListener
      onWheel={evt => {
        // disable scrolling when focused as it causes the value to change
        if (isFocused) {
          evt.preventDefault();
        }
      }}
    >
      <InputControl
        onFocus={evt => {
          setFocused(true);
          if (onFocus) {
            onFocus(evt);
          }
        }}
        onBlur={evt => {
          setFocused(false);
          if (onBlur) {
            onBlur(evt);
          }
        }}
        onKeyPress={evt => {
          const { nativeEvent } = evt;
          if ([13].includes(nativeEvent.keyCode)) {
            return;
          }

          if (
            Number.isNaN(Number(nativeEvent.key)) ||
            (!windowHasSelection() &&
              maxDigits != null &&
              (nativeEvent.target.value + nativeEvent.key).length > maxDigits)
          ) {
            evt.preventDefault();
            evt.stopPropagation();
          } else if (onKeyPress) {
            onKeyPress(evt);
          }
        }}
        ref={ref}
        field={field}
        {...props}
        type="number"
      />
    </ActiveListener>
  );
});
export const ChangePasswordControl = CreateControl(ChangePassword);
