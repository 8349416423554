import moment from 'moment';
import tiers from '~lib/constants/tiers';
import { auStates } from '~lib/constants';

const valueConversions = {
  status: value => {
    switch (value?.toUpperCase().replace(/\s+/g, '_')) {
      case 'SGL':
      case 'SINGLE':
        return { value: 'SINGLE', label: 'Single', icon: 'status-single' };
      case 'CPL':
      case 'COUPLE':
        return { value: 'COUPLE', label: 'Couple', icon: 'status-couple' };
      case 'FAM':
      case 'FAMILY':
      case 'FAMILY_EXTENDED':
        return { value: 'FAMILY', label: 'Family', icon: 'status-family' };
      case 'SPFAM':
      case 'SINGLE_PARENT':
      case 'SINGLE_PARENT_EXTENDED':
        return {
          value: 'SINGLE_PARENT',
          label: 'Single Parent',
          icon: 'status-single-parent',
        };
      case 'SINGLE_PARENT_DISABILITY':
        return {
          value: 'SINGLE_PARENT_DISABILITY',
          label: 'Single Parent - Disability',
          icon: 'status-single-parent',
        };
      case 'FAMILY_DISABILITY':
        return {
          value: 'FAMILY_DISABILITY',
          label: 'Family - Disability',
          icon: 'status-family',
        };
      default:
        return undefined;
    }
  },
  tier: (value, data) => {
    if (!value || !['0', '1', '2', '3'].includes(value)) {
      return undefined;
    }

    const status = ['SINGLE', 'Sgl'].some(
      val =>
        val ===
        (typeof data.membershipClass === 'object'
          ? data.membershipClass.value
          : data.membershipClass)
    )
      ? 'SINGLE'
      : 'FAMILY';

    return tiers[status].find(tier => tier.value === value);
  },
  efc: (value, data) => {
    if (
      !value ||
      !['true', 'false'].includes(value) ||
      !data.membershipClass ||
      !['FAMILY', 'SINGLE_PARENT'].includes(
        data.membershipClass.replace('_EXTENDED', '')
      )
    ) {
      return undefined;
    }

    return {
      label: value === 'true' ? 'Yes' : 'No',
      value: value === 'true' ? true : false,
    };
  },
  dob: value => {
    if (!value) {
      return undefined;
    }

    const dob = moment(
      value,
      [moment.ISO_8601, 'DD/MM/YYYY', 'DD-MM-YYYY', 'DD/MM/YY', 'DD-MM-YY'],
      true
    );

    if (!dob.isValid()) {
      return undefined;
    }

    const now = moment();
    const age = moment.duration(now.diff(dob));

    return {
      value,
      label: dob.format('Do MMMM YYYY'),
      age: age.years(),
    };
  },
  partnerDob: value => {
    if (!value) {
      return undefined;
    }

    const dob = moment(
      value,
      [moment.ISO_8601, 'DD/MM/YYYY', 'DD-MM-YYYY', 'DD/MM/YY', 'DD-MM-YY'],
      true
    );

    if (!dob.isValid()) {
      return undefined;
    }

    const now = moment();
    const age = moment.duration(now.diff(dob));

    return {
      value,
      label: dob.format('Do MMMM YYYY'),
      age: age.years(),
    };
  },
  region: value =>
    auStates.map(state => state.value).some(v => v === value)
      ? {
          label: value,
          value,
        }
      : undefined,
  paymentFrequency: value =>
    ['MONTHLY', 'QUARTERLY', 'HALF_YEARLY', 'YEARLY'].some(v => v === value)
      ? value
      : 'MONTHLY',
  resultType: value =>
    ['CURRENT', 'FUTURE'].some(v => v === value) ? value : 'CURRENT',
  hospitalProduct: value => value,
  extrasProduct: value => value,
};

export default valueConversions;
