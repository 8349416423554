import { Flex } from 'rebass';
import ClaimStatus from '~OMS/claims/atoms/ClaimStatus';
import { StandardText } from '../../../../common/atoms/typography';
import { shortWordyDateTimeFormat, shortWordyFormat } from '~lib/dates';
import React from 'react';
import styled from '@emotion/styled';
import ClaimsHistoryRow from '~OMS/claims/molecules/atoms/ClaimsHistoryRow';
import { CLAIM_STATUS } from '~OMS/claims/constants';
import ClaimBenefit from '~OMS/claims/atoms/ClaimBenefit';

const StyledFooter = styled(ClaimsHistoryRow)`
  background: ${props => props.theme.colors.iceBlue};
`;

const ClaimFooter = ({ claim, isMobile }) => {
  return (
    <StyledFooter
      py={3}
      alignItems={{
        xs: 'flex-start',
        md: 'center',
      }}
      flexDirection={{
        xs: 'column',
        md: 'row',
      }}
      justifyContent={{
        xs: 'flex-start',
        md: 'space-between',
      }}
    >
      <Flex
        justifyContent="space-between"
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
        align-items="center"
      >
        <ClaimStatus
          mb={{
            xs: 2,
            md: 0,
          }}
          mr={4}
          claim={claim}
        />
        {[CLAIM_STATUS.PROCESSED, CLAIM_STATUS.SUBMITTED].includes(
          claim.status
        ) && (
          <StandardText fontSize={1}>
            {claim.status === CLAIM_STATUS.PROCESSED ? 'Paid' : 'Submitted'} on{' '}
            {isMobile ? (
              <strong>
                {(claim.status === CLAIM_STATUS.PROCESSED
                  ? shortWordyFormat
                  : shortWordyDateTimeFormat)(claim.claimDate)}
              </strong>
            ) : (
              (claim.status === CLAIM_STATUS.PROCESSED
                ? shortWordyFormat
                : shortWordyDateTimeFormat)(claim.claimDate)
            )}
          </StandardText>
        )}
      </Flex>
      <Flex
        mt={{
          xs: 2,
          md: 0,
        }}
        alignItems={{
          xs: 'flex-start',
          tabletP: 'center',
        }}
      >
        <StandardText mr={1} fontSize={1}>
          Total Benefit{' '}
        </StandardText>
        <ClaimBenefit
          bold
          fontSize={isMobile ? 1 : 2}
          benefit={claim.benefitsPaid}
          status={claim.status}
        />
      </Flex>
    </StyledFooter>
  );
};

export default ClaimFooter;
