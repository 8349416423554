import React from 'react';
import styled from '@emotion/styled';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import css from '@emotion/css';
import { Box } from 'rebass';

const styles = props => css`
  padding: 16px;
  border-radius: 4px;
  background: ${props.theme.colors.success50};
  border-left: 4px solid ${props.theme.colors.success400};
  transform: translateY(-16px);
  line-height: 1.5;
  margin-bottom: 4px;
  * {
    color: ${props.theme.colors.success900};
    font-size: ${props.theme.fontSizes[2]}px;
  }
`;

const AsRichText = styled(SanityRichTextContent)`
  ${styles}
`;

const OrdinaryText = styled(Box)`
  ${styles}
`;

export default ({ asRichText = true, content, ...props }) => {
  if (asRichText) {
    return <AsRichText content={content} {...props} />;
  }

  return <OrdinaryText {...props}>{content}</OrdinaryText>;
};
