import React from 'react';
import { useThemeUI } from 'theme-ui';
import { navigate } from 'gatsby';
import { useApplicationFormContext } from './state/ApplicationFormProvider';
import useFeatureToggle from '~lib/hooks/useFeatureToggle';
import Header from '~common/molecules/SimpleHeader';

const PageBanner = () => {
  const { theme } = useThemeUI();
  const appFormContext = useApplicationFormContext();
  const featureToggle = useFeatureToggle();
  const onNavigateToHome = () => {
    if (
      appFormContext.actions.saveApplicationForm &&
      !appFormContext.state.applicationSent &&
      featureToggle.saveMaf
    ) {
      appFormContext.actions.saveApplicationForm({
        leaving: true,
        onCancel: () => {
          navigate('/');
        },
      });
    } else {
      appFormContext.actions.clearApplicationForm();
      navigate('/');
    }
  };

  return (
    <Header
      logo={{
        onClick: onNavigateToHome,
        height: 48,
      }}
      fixed={false}
    />
  );
};

export default PageBanner;
