import React from 'react';
import Icon from '../../../../common/atoms/Icon';
import { Flex } from 'rebass';
import { StandardText } from '../../../../common/atoms/typography';

const alerts = theme => ({
  info: {
    color: theme.colors.secondary,
    icon: 'clock',
  },
  error: {
    color: theme.colors.error,
    icon: 'alert',
  },
});

const SubmitClaimAlerts = ({ type = 'info', message = '', theme }) => {
  const { color, icon } = alerts(theme)[type];
  return (
    <Flex alignItems="center" flexDirection="row">
      <Icon fill={color} mr={2} name={icon} height="20px" width="20px" />
      <StandardText color={color} fontSize={1}>
        {message}
      </StandardText>
    </Flex>
  );
};

export default SubmitClaimAlerts;
