import React from 'react';
import { Field, Form, Formik } from 'formik';
import { formatDate } from '~lib/dates';
import formModeSwitch from '~lib/hocs/formModeSwitch';
import BoldReadOnlyControl from '~common/molecules/form-controls/BoldReadOnlyControl';
import { getFrequencyLabel } from '~OMS/payments/organisms/PaymentPolicyForm/formMappers';
import { currency } from '~lib/filters';

const PayrollViewFields = ({ values }) => (
  <>
    <Field
      name="frequency"
      label="Frequency"
      labelPosition="left"
      component={BoldReadOnlyControl}
    />

    <Field
      name="paymentDate"
      label="Payment Date"
      labelPosition="left"
      component={BoldReadOnlyControl}
    />
  </>
);

const ViewForm = ({ initialForm, onEditDetails }) => (
  <Formik onSubmit={onEditDetails} initialValues={initialForm}>
    {({ values }) => (
      <Form className="full-width">
        <PayrollViewFields values={values} />
      </Form>
    )}
  </Formik>
);

const view = {
  Component: ViewForm,
  mapInitialForm: (initialForm = {}) => {
    return {
      ...initialForm,
      frequency: `${getFrequencyLabel(initialForm)} ${currency(
        initialForm.paymentAmount
      )}`,
      paymentDate: formatDate(initialForm.paidToDate),
    };
  },
};

const Index = formModeSwitch({
  view,
  edit: view,
});

export default Index;
