import React, { useState } from 'react';
import HeaderLine from '~common/atoms/HeaderLine';
import { withTheme } from 'emotion-theming';
import { useQuery } from '@apollo/client';
import MyDocumentsTable from '~OMS/my-documents/molecules/MyDocumentsTable';
import { P, StandardText } from '../../../../common/atoms/typography';
import { Box } from 'rebass';
import Loading from '~common/atoms/Loading';
import InfoCardWithIcon from '~OMS/my-documents/molecules/InfoCardWithIcon';
import { DocumentsQuery } from 'queries/oms/documents';
import ResponsiveFlex from '~common/atoms/ResponsiveFlex';
import styled from '@emotion/styled';
import Wrapper from '~common/molecules/Wrapper';
import MyDocumentsRow from '~OMS/my-documents/atoms/MyDocumentsRow';
import { pathOr } from '~lib/util';

const filters = [
  {
    id: 'ALL',
    text: 'All',
  },
  {
    id: 'POLICY',
    text: 'Policy Updates',
  },
  {
    id: 'BILL',
    text: 'Bills',
  },
  {
    id: 'TAX',
    text: 'Tax',
  },
];

const StyledMyDocumentsWrapper = styled(Wrapper)`
  &&& {
    padding-left: 0;
    padding-right: 0;
  }
`;

const renderContent = ({ data, loading, currentFilter }) => {
  return loading ? (
    <Loading pt={80} pb={40} />
  ) : (
    <MyDocumentsTable
      documents={pathOr(
        [],
        'oms.documents'
      )(data).filter(
        doc => currentFilter === 'ALL' || doc.category === currentFilter
      )}
    />
  );
};

const MyDocumentsPage = ({ theme }) => {
  const [currentFilter] = useState(filters[0].id);
  const { loading, error, data } = useQuery(DocumentsQuery);

  if (error) {
    console.error(error);
  }

  return (
    <StyledMyDocumentsWrapper>
      <MyDocumentsRow>
        <HeaderLine theme={theme} title="My Documents" />
        <StandardText>
          <P theme={theme} fontSize={3} mt={'20px'} mb="35px">
            Correspondence, policy updates & tax statements.{' '}
          </P>
        </StandardText>
      </MyDocumentsRow>

      <MyDocumentsRow mb={3}>
        {renderContent({ data, loading, currentFilter })}
      </MyDocumentsRow>
      <MyDocumentsRow>
        <StandardText mb={3}>
          <P theme={theme}>
            Please contact us for any older statements not shown.
          </P>
        </StandardText>

        <ResponsiveFlex
          pb={1}
          mt={{
            xs: 4,
            md: 5,
          }}
          justifyContent="space-between"
        >
          <Box
            width={{
              xs: 1,
              md: 1 / 2,
            }}
            mr={{
              xs: 0,
              md: 5,
            }}
            mb={{
              xs: 4,
              md: 0,
            }}
          >
            <InfoCardWithIcon
              icon="external-link"
              heading="Government Initiatives"
              to="/tax-and-rebates"
              target="_blank"
              external={true}
            >
              Learn more about the Australian Government's rebate on private
              health insurance and how it affects your taxes.
            </InfoCardWithIcon>
          </Box>
          <Box width={{ xs: 1, md: 1 / 2 }}>
            <InfoCardWithIcon
              icon="external-link"
              heading="Quarterly Newsletters"
              to="/VitalSigns"
              target="_blank"
              external={true}
            >
              Read our quarterly issue of ‘VitalSigns’ to keep informed about
              important health fund updates, helpful policy information and
              interesting health industry news stories.
            </InfoCardWithIcon>
          </Box>
        </ResponsiveFlex>
      </MyDocumentsRow>
    </StyledMyDocumentsWrapper>
  );
};

export default withTheme(MyDocumentsPage);
