import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import styled from '@emotion/styled';

const Switch = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justifty-content: space-between;
  min-height: 24px;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 17px;
  padding: 4px;
  margin: 0;
  background-color: ${props => props.theme.colors.white};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  > button {
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  }

  &.switch-large {
    min-height: 40px;
    border-radius: 25px;
  }
  &.switch-border {
    border: 1px solid ${props => props.theme.colors.white};
  }
`;

const Label = styled.button`
  appearance: none;
  outline: none;
  border: none;
  display: block;
  padding: 3px 10px;
  color: ${props =>
    props.active
      ? props.theme.colors.white
      : props => props.theme.colors.primary};
  background-color: ${props =>
    props.active ? props.theme.colors.primary : props.theme.colors.white};
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  line-height: 16px;
  border-radius: 15px;

  &:first-of-type {
    border-radius: ${props => (props.active ? '15px' : '15px 0 0 15px')};
  }
  &:last-of-type {
    border-radius: ${props => (props.active ? '15px' : '0 15px 15px 0')};
  }

  &.label-large {
    border-radius: 25px;
    padding: 11px 18px;
    font-size: ${props => props.theme.fontSizes[2]}px;

    @media screen and (max-width: 992px) {
      padding: 11px 14px;
      font-size: 14px;
    }
  }
`;

const ToggleSwitch = props => {
  const buttonRef = React.createRef();
  const [selected, setSelected] = useState(
    props.defaultValue || props.values[0].value
  );

  useEffect(() => {
    setSelected(props.defaultValue);
  }, [props.defaultValue]);

  const onClick = selection => {
    console.log(selection);
    if (props.disabled) {
      return;
    }

    setSelected(selection);

    if (props.onChange) {
      props.onChange(selection);
    }
  };

  const switchClassName = classnames({
    [props.className]: !!props.className,
    switch: true,
    'switch-disabled': props.disabled,
    'switch-large': props.size === 'large',
    'switch-border': !!props.border,
  });

  return (
    <Switch
      type="button"
      role="switch"
      id={props.id}
      data-id={props.id}
      name={props.name}
      aria-labelledby={props.ariaLabelledby}
      aria-label={props.ariaLabel}
      disabled={props.disabled}
      tabIndex={props.tabIndex}
      ref={buttonRef}
      className={switchClassName}
    >
      {props.values.map((btn, index) => {
        const isSelected = selected === btn.value;

        const labelClassName = classnames({
          'label-large': props.size === 'large',
          'label-selected': isSelected,
        });

        return (
          <Label
            data-id={`option-${index}`}
            key={`${btn.value}`}
            className={labelClassName}
            active={isSelected}
            onClick={() => onClick(btn.value)}
          >
            {btn.label}
          </Label>
        );
      })}
    </Switch>
  );
};

ToggleSwitch.defaultProps = {
  disabled: false,
  border: false,
  defaultValue: false,
  values: [
    { label: 'On', value: true },
    { label: 'Off', value: false },
  ],
};

export default ToggleSwitch;
