import React from 'react';
import PropTypes from 'prop-types';
import Section from './Section';
import Heading from './Heading';
import Items from './Items';
import Actions from './Actions';
import Contact from './Contact';

const JoinUs = ({ onApply, onEmailQuote }) => {
  return (
    <Section>
      <Heading />
      <Items />
      <Actions onApply={onApply} onEmailQuote={onEmailQuote} />
      <Contact />
    </Section>
  );
};

JoinUs.propTypes = {
  onApply: PropTypes.func.isRequired,
};

export default JoinUs;
