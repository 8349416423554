import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button } from '~common/atoms/Button';

const StyledButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 16px;
  margin: -8px;

  @media (min-width: 768px) {
    display: none;
  }

  > * {
    flex: 0 1 100%;
    margin: 8px;
  }
`;

const MobileActions = ({ onApply, onEmailQuote }) => {
  return (
    <StyledButtonGroup>
      <Button
        icon="chevron-right-thin"
        iconPosition="right"
        onClick={onApply}
        variant="white"
        size="small"
      >
        Apply now
      </Button>
      <Button
        onClick={onEmailQuote}
        variant="whiteInverted"
        size="small"
      >
        Email quote
      </Button>
    </StyledButtonGroup>
  );
};

MobileActions.propTypes = {
  onApply: PropTypes.func.isRequired,
};

export default MobileActions;
