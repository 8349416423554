import React from 'react';
import { Flex } from 'rebass';
import styled from '@emotion/styled';
import ResponsiveImage from '~common/atoms/ResponsiveImage';
import { CreateControl } from '~common/molecules/form-controls/Control';
import { MaskedInput } from '~common/atoms/Input';
import { SUPPORTED_CARDS } from '~OMS/payments/cardDetector';
import { mixed } from 'yup';
import { NON_DIGIT_REGEX } from '~lib/regexes';
const CvvInput = styled(MaskedInput)`
  width: 80px;
  ${props => props.theme.mq.md} {
    width: 103px;
  }
`;

const CreditCardsWrapper = styled(Flex)`
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translateY(50%);
`;

const CreditCardImg = styled(ResponsiveImage)`
  border-radius: 10px;
  height: 45px;
  border: 2px solid
    ${props => (props.highlight ? props.theme.colors.primary : 'transparent')};
  transition: border 300ms ease-in-out;
`;

const MasterCardImg = styled(CreditCardImg)`
  margin-right: ${props => props.theme.space[2]}px;
`;

const cvvMask = [/\d/, /\d/, /\d/];

const CvvControl = ({ card = {}, cardsClassName, ...props }) => {
  return (
    <Flex
      justifyContent="space-between"
      css={{
        position: 'relative',
      }}
    >
      <CvvInput keepCharPositions mask={cvvMask} maxlength={3} {...props} />
      <CreditCardsWrapper className={cardsClassName}>
        <MasterCardImg
          highlight={card.type === SUPPORTED_CARDS.masterCard}
          data-id="card-logo-master-card"
          src="master-card"
        />
        <CreditCardImg
          data-id="card-logo-visa"
          highlight={card.type === SUPPORTED_CARDS.visa}
          src="visa-card"
        />
      </CreditCardsWrapper>
    </Flex>
  );
};

export default CreateControl(CvvControl);

export const cvvValidator = () =>
  mixed().test({
    name: 'cvv',
    test: (value = '') => {
      if (!value) {
        return true;
      }

      value = value.replace(NON_DIGIT_REGEX, '');
      return value.length === 3;
    },
    message: 'The CVV number needs to have 3 digits',
  });
