import React, { useEffect, useState } from 'react';
import { Box, Flex } from 'rebass';

import { useMobileScreenOnly } from '~lib/responsive';
import SanityImage from '../../common/molecules/SanityImage';
import CenterBox from '~common/atoms/CenterBox';
import Block from '../../common/atoms/Block';
import { usePageContext } from '~lib/providers/PageProvider';
import { useColumnContext } from '~lib/providers/ColumnProvider';
import { ButtonsBase } from './Buttons';
import css from '@emotion/css';
import { useThemeUI } from 'theme-ui';
import useHeroImage from '~common/hooks/useHeroImage';
import HeroImage from './HeroImage';
import useHighlightedText from '~common/hooks/useHighlightedText';
import { H1 } from '~common/atoms/typography';
import styled from '@emotion/styled';
import useEventListener from '~lib/hooks/useEventListener';
import { execOnClientOnly } from '~lib/util';

const HeroBlockTitle = ({ title, textIndent = false }) => {
  const { theme } = useThemeUI();
  const highlightedTitle = useHighlightedText({
    text: title,
    color: theme.colors.secondary,
    wordsToHighlight: [2, 3],
  });

  return (
    <H1
      variant="medium"
      maxWidth={textIndent ? '60%' : { tabletP: '489px', lg: '590px' }}
      width={1}
      mb={2}
      color="primary"
    >
      {highlightedTitle}
    </H1>
  );
};

const HeroBlockActions = ({ actions = {} }) => (
  <Flex
    mt={4}
    flexDirection="row"
    alignItems="center"
    justifyContent="flex-start"
  >
    <ButtonsBase
      flexDirection="row"
      size="large"
      data={{
        ...actions,
        buttons: (actions.buttons || []).slice(0, 2),
      }}
      buttonProps={{
        maxHeight: 54,
        mb: 0,
        css: css`
          white-space: nowrap;
        `,
      }}
    />
  </Flex>
);

const TextIndentWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const HeroDesktop = ({
  data: { title, actions, titleColor, backgroundImage, textIndent },
  next,
  metaData,
  ...props
}) => {
  const { containerRef, containerWidth } = useHeroImage();
  const { isCampaign } = usePageContext();
  const columnContext = useColumnContext() || {};
  const margins = columnContext.sideMenuLayout
    ? {
        ml: {
          xs: 25,
          tabletP: 25,
          tabletL: 50,
          lg: 102,
        },
      }
    : undefined;

  return (
    <>
      <Block
        {...margins}
        mb={next._type === 'richTextComponent' ? 50 : undefined}
        ignoreCampaignLayout
        pt={{ xs: 0, lg: 0 }}
        pb={{ xs: 0, lg: 0 }}
        bg="lightNeutral100"
        ref={containerRef}
        innerProps={{
          className: 'inner-container',
          height: '555px',
          display: 'flex',
          flexDirection: {
            xs: 'column',
            tabletP: 'row',
          },
        }}
        {...props}
      >
        <Box
          pt={90}
          pb={150}
          width={1 / 2}
          minWidth="50%"
          css={css`
            white-space: break-spaces;
            ${isCampaign
              ? `
              display: flex;
              flex-flow: column nowrap;
              justify-content: center;
              padding-top: 0;
              padding-bottom: 0;
            `
              : ''}
          `}
        >
          <TextIndentWrapper
            condition={textIndent}
            wrapper={children => (
              <CenterBox
                flexDirection="column"
                alignItems="start"
                px={{ xl: 200, lg: 140 }}
              >
                {children}
              </CenterBox>
            )}
          >
            <HeroBlockTitle
              color={titleColor}
              textIndent={textIndent}
              title={title}
            />
            <HeroBlockActions actions={actions} />
          </TextIndentWrapper>
        </Box>

        <HeroImage containerWidth={containerWidth} image={backgroundImage} />
      </Block>
    </>
  );
};

const StyledMobileImage = styled(Box)`
  clip-path: ${props =>
    `path('m ${props.width} 23 C 229 -10 127 15 1 73 v 375 s ${props.width} 0 ${props.width} 0 v -217 Z')`};
`;

const HeroMobile = ({
  data: { title, actions, titleColor, backgroundImage },
  next,
  ...props
}) => {
  const { isCampaign } = usePageContext();
  const { sideMenuLayout } = useColumnContext() || {};
  const [screenWidth, setScreenWidth] = useState();

  useEventListener('resize', () => {
    execOnClientOnly(() => {
      setScreenWidth(window.innerWidth);
    });
  });

  useEffect(() => {
    execOnClientOnly(() => {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  //TODO: move all this spacing configs to single accessible place
  const margins =
    (!isCampaign && sideMenuLayout) || next._type === 'richTextComponent'
      ? {
          text: {
            mb: 0,
            pt: 0,
          },
          image: {
            mb: 50,
            ml: 0,
          },
        }
      : {};

  return (
    <Flex flexDirection="column">
      <Block
        order={isCampaign ? 2 : 1}
        ignoreCampaignLayout
        flexDirection="column"
        pt={{ xs: isCampaign ? 22 : 0, lg: 0 }}
        pb={3}
        bg="background.white"
        {...margins.text}
        {...props}
      >
        <HeroBlockTitle color={titleColor} title={title} />
        <HeroBlockActions actions={actions} />
      </Block>
      <CenterBox {...margins.image} order={isCampaign ? 1 : 2}>
        <StyledMobileImage width={screenWidth}>
          <SanityImage
            style={{
              width: '100%',
              height: '375px',
            }}
            src={backgroundImage}
            transform={transformer =>
              transformer.width(375).height(375).fit('crop')
            }
          />
        </StyledMobileImage>
      </CenterBox>
    </Flex>
  );
};

export default props => {
  const isMobile = useMobileScreenOnly();
  return isMobile ? <HeroMobile {...props} /> : <HeroDesktop {...props} />;
};
