import useProductsQuery from '~lib/hooks/sanity/useProductsQuery';
import { useApolloClient } from '@apollo/client';
import { formatToISO8601DateOnly } from '~lib/dates';
import moment from 'moment';
import { ProductQuoteQuery } from 'queries/quote.graphql';
import { useEffect, useState } from 'react';

export default (type, rawProducts) => {
  const products = useProductsQuery(type, undefined, rawProducts);
  const [resultType, setResultType] = useState('CURRENT');

  const [finalProducts, setFinalProducts] = useState([]);
  const [rebatePercentage, setRebatePercentage] = useState();
  const [loading, setLoading] = useState(false);

  const client = useApolloClient();

  useEffect(() => {
    if (!products.length) {
      return;
    }

    setLoading(true);
    Promise.all(
      products
        .filter(p => p.content.variants.filter(v => v.onSale).length > 0)
        .map(product => {
          return client
            .query({
              query: ProductQuoteQuery,
              variables: {
                input: {
                  status: 'SINGLE',
                  state: 'NSW',
                  dob: formatToISO8601DateOnly(
                    moment().startOf('year').subtract(29, 'years')
                  ),
                  paymentFrequency: 'MONTHLY',
                  resultType,
                  [`${product.content.type}Product`]:
                    product.content.variants[0].code,
                },
              },
            })
            .then(({ data }) => {
              if (rebatePercentage == null) {
                setRebatePercentage(data.quote.rebatePercentage);
              }
              return {
                ...product,
                content: {
                  ...product.content,
                  variants: [
                    {
                      ...product.content.variants[0],
                      price: data.quote.totals,
                    },
                  ],
                  get allVariants() {
                    return this.variants;
                  },
                },
              };
            });
        })
    )
      .then(products => {
        setFinalProducts(products);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [products, resultType]);

  return {
    products: finalProducts,
    loading,
    rebatePercentage,
    resultType,
    actions: {
      setResultType,
    },
  };
};
