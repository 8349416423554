import React from 'react';

import { Formik, Field, Form } from 'formik';

import styled from '@emotion/styled';

import { ButtonSwitchControl } from '~common/molecules/form-controls/index';
import { StandardText } from '~common/atoms/typography';
import { Row, FirstCol, SecondCol, ThirdCol } from '../atoms/Layout';
import { equals } from 'lodash/fp';
import useCommunicationPreference from '~OMS/my-details/hooks/useCommunicationPreference';
import { Alert } from '~common/molecules/AlertBox';
import { COMMUNICATION_PREFERENCE } from '~OMS/my-details/contants';
import Confirmation from '~common/molecules/Confirmation';

const billingButtons = [
  {
    id: COMMUNICATION_PREFERENCE.POST,
    text: 'Post',
  },
  {
    id: COMMUNICATION_PREFERENCE.EMAIL,
    text: 'Email',
  },
];

const ShrinkedButtonSwitchControl = styled(ButtonSwitchControl)`
  &&& {
    .label {
      width: 80%;
    }

    .control: {
      width: 20%;
    }

    a {
      min-width: auto;
    }
  }
`;

const CommunicationPereferenceForm = ({
  onSubmit,
  initialForm,
  loading,
  alert,
}) => (
  <Formik initialValues={initialForm} onSubmit={onSubmit}>
    {({ values, resetForm }) => (
      <Form className="full-width">
        <Field
          name="billing"
          render={props => (
            <ShrinkedButtonSwitchControl
              label="Billing"
              labelPosition="left"
              buttons={billingButtons}
              {...props}
            />
          )}
        />

        <Field
          name="policy"
          render={props => (
            <ShrinkedButtonSwitchControl
              bottomSpacing={false}
              label="Policy related announcements"
              labelPosition="left"
              buttons={billingButtons}
              {...props}
            />
          )}
        />

        {alert && (
          <Alert mt={4} type={alert.type}>
            {alert.message}
          </Alert>
        )}

        <Confirmation
          loading={loading}
          disableConfirm={equals(initialForm, values)}
          disableCancel={equals(initialForm, values) || loading}
          onCancel={() => {
            resetForm();
          }}
        />
      </Form>
    )}
  </Formik>
);

const CommunicationPreference = props => {
  const { state, actions } = useCommunicationPreference();

  return (
    <Row alignItems="flex-start" {...props}>
      <FirstCol flexDirection="column">
        <StandardText fontWeight={600} fontSize={18} color="dark">
          Communication
        </StandardText>
      </FirstCol>
      <SecondCol
        css={{
          transform: 'translateY(-7px)',
        }}
      >
        {!state.myDetailsLoading && (
          <CommunicationPereferenceForm
            loading={state.updating}
            alert={state.alert}
            onSubmit={actions.updatePreferences}
            initialForm={{
              billing: state.preferences.billingCommunication,
              policy: state.preferences.policyCommunication,
            }}
          />
        )}
      </SecondCol>
      <ThirdCol></ThirdCol>
    </Row>
  );
};

export default CommunicationPreference;
