import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import valueConversions from '~lib/valueConversions';
import { path } from 'lodash/fp';
import { P2, StandardText } from '~common/atoms/typography';
import { Box } from 'rebass';
import { EFC_UPPER_AGE_LIMIT } from '~lib/constants';

const SummarySection = styled(P2)`
  display: inline;
  color: ${props =>
    props.highlight
      ? props.theme.colors.primary
      : props.theme.colors.neutral900};
  text-transform: ${props => props.transform || 'initial'};

  > strong {
    font-weight: 700;
  }
`;

const Summary = ({
  status,
  age,
  region,
  tier,
  efc,
  as = SummarySection,
  prefix = 'Based on a',
  ...rest
}) => {
  const As = as;
  tier = Number(tier);
  const [income, setIncome] = useState('');
  useEffect(() => {
    if (status === 'Single' && (!tier || tier === '0')) {
      setIncome('Below $97k');
    } else if (status === 'Single' && tier === 1) {
      setIncome('$97k - $113k');
    } else if (status === 'Single' && tier === 2) {
      setIncome('$113k - $151k');
    } else if (status === 'Single' && tier === 3) {
      setIncome('Over $151k');
    } else if (status !== 'Single' && tier === 0) {
      setIncome('Below $194k');
    } else if (status !== 'Single' && tier === 1) {
      setIncome('$194k - $226k');
    } else if (status !== 'Single' && tier === 2) {
      setIncome('$226k - $302k');
    } else if (status !== 'Single' && tier === 3) {
      setIncome('Over $302k');
    }
  }, [status, tier]);
  return (
    <Box {...rest}>
      <As>
        {prefix}{' '}
        <As highlight={true} transform="lowercase">
          {status && status === 'Single'
            ? `single person`
            : path('label', valueConversions.status(status))}
        </As>
        , living in{' '}
        <As highlight={true} transform="uppercase">
          {region}
        </As>
        {status === 'Family' || status === 'Couple'
          ? `, with a combined annual income of `
          : `, with an annual income of `}
        {income && income !== '' && (
          <As highlight={true} transform="lowercase">
            {income}
          </As>
        )}
        .
      </As>
      {efc && (
        <StandardText
          width={{
            xs: 1,
            md: '70%',
          }}
          mt={2}
          fontSize={1}
        >
          *This quote includes an additional cost for Extended Family Cover, an
          optional upgrade which allows children who are not studying to be
          covered until they turn {EFC_UPPER_AGE_LIMIT}.
        </StandardText>
      )}
    </Box>
  );
};

export default Summary;
