import { useEffect, useMemo, useState } from 'react';
import useStepper from '../../ApplicationForm/state/useApplicationForm/useStepper';
import { STEP_STATE } from '~lib/constants';
import { path } from 'lodash/fp';
import tiers from '../../../common/lib/constants/tiers';
import useFeatureToggle from '~lib/hooks/useFeatureToggle';
import {
  selectDobForSubmit,
  isEfcEligible as isEfcEligibleUtil,
  trackCustomEvent,
} from '~lib/util';
import usePromotions from '~lib/hooks/sanity/usePromotions';
import stepsRegistry from './steps';

const stepsList = Object.values(stepsRegistry);

export default ({ form, onChange, onSubmit }) => {
  const [activeStepIndex, setActiveStepIndex] = useState();
  const [confirmedSteps, setConfirmedSteps] = useState({});
  const { data: promotions } = usePromotions({
    variables: {
      input: {
        lens: 'quickQuotePage',
      },
    },
  });

  const hasPartner = useMemo(() => {
    return ['FAMILY', 'COUPLE'].includes(path('status.value')(form));
  }, [form]);

  const { efcQuote } = useFeatureToggle();

  const isEfcEligible = useMemo(() => {
    return efcQuote && isEfcEligibleUtil(path('status.value')(form));
  }, [efcQuote, form]);

  const tierChoices = useMemo(() => {
    return [{ value: undefined, label: '' }]
      .concat(
        form.status
          ? tiers[form.status.value === 'SINGLE' ? 'SINGLE' : 'FAMILY']
          : []
      )
      .map(item => ({
        ...item,
        description: undefined,
      }));
  }, [form?.status]);

  const setActiveStep = stepToSet => {
    const stepIndex = steps.findIndex(step => step.value === stepToSet.value);
    setActiveStepIndex(stepIndex);
  };

  const steps = useMemo(() => {
    return stepsList.map((step, index) => {
      const isValid = step.validate({ form, hasPartner, isEfcEligible });
      return {
        ...step,
        isValid,
        state: (() => {
          if (confirmedSteps[step.value] && isValid) {
            return index === activeStepIndex
              ? STEP_STATE.COMPLETED_ACTIVE
              : STEP_STATE.COMPLETED_INACTIVE;
          }

          return index === activeStepIndex
            ? STEP_STATE.ACTIVE
            : STEP_STATE.INACTIVE;
        })(),
      };
    });
  }, [activeStepIndex, confirmedSteps, form, hasPartner, isEfcEligible]);

  useEffect(() => {
    setConfirmedSteps(() =>
      steps.reduce((acc, step) => {
        return {
          ...acc,
          [step.value]: step.isValid,
        };
      }, {})
    );
    //eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const firstInvalidStep = steps.findIndex(step => !step.isValid);
    const initialStepIndex =
      firstInvalidStep >= 0 ? firstInvalidStep : steps.length - 1;
    setActiveStepIndex(initialStepIndex);
    //eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  const activeStep = steps[activeStepIndex];

  useEffect(() => {
    if (activeStep?.gaTrackingKey) {
      trackCustomEvent({
        category: 'Quick Quote Step Change',
        action: 'visiting',
        label: activeStep.gaTrackingKey,
      });
    }
  }, [activeStep?.gaTrackingKey]);

  const stepper = useStepper({
    activeStep,
    setActiveStep,
    setConfirmedSteps,
    confirmedSteps,
    steps,
  });

  const handleChange = onChange;
  const allValid = () => steps.every(step => step.isValid);

  const confirmStep = () => {
    if (activeStepIndex === steps.length - 1 && allValid()) {
      onSubmit({
        ...form,
        efc: isEfcEligible ? form.efc : undefined,
        dob: selectDobForSubmit(form),
      });
    } else {
      stepper.confirmStep();
    }
  };

  return {
    state: {
      activeStep,
      activeStepIndex,
      steps,
      hasPartner,
      tierChoices,
      isEfcEligible,
      promotions,
    },
    actions: {
      ...stepper,
      handleChange,
      confirmStep,
    },
  };
};
