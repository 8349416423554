import React from 'react';
import styled from '@emotion/styled';
import CenterBox from '~common/atoms/CenterBox';
const StyledPaymentFormWrapper = styled(CenterBox)`
  padding: 21px 21px;
  width: 100%;
  ${props => props.theme.mq.md} {
    width: 637px;
    padding: 21px 81px;
  }
  ${props =>
    props.boxed
      ? `
  border: 1px solid ${props.theme.colors.borders};
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.1)
  `
      : ''};
`;
const PaymentFormWrapper = ({ boxed = false, children, ...props }) => {
  return (
    <StyledPaymentFormWrapper flexDirection="column" boxed={boxed} {...props}>
      {children}
    </StyledPaymentFormWrapper>
  );
};

export default PaymentFormWrapper;
