import React from 'react';
import styled from '@emotion/styled';
import { useThemeUI } from 'theme-ui';
import { Flex } from 'rebass';
import { css } from '@emotion/core';
import { StandardText } from '~common/atoms/typography';
import WorkingTime from '~common/molecules/WorkingTime';
import CallUsWithIcon from '~common/molecules/CallUsWithIcon';
import useContactDetails from '~lib/hooks/sanity/useContactDetails';
import EmailUsWithIcon from '~common/molecules/EmailUsWithIcon';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import { useMobileScreenAndBigger } from '~lib/responsive';
import Block from '~common/atoms/Block';
import Logo from '~common/atoms/Logo';
import MembersHealthFundLogo from '~common/molecules/MembersHealthFundLogo';
import RequestCallbackWithIcon from '~common/molecules/RequestCallbackWithIcon';

const ContactDetailsText = styled(StandardText)`
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 1.3;
  font-weight: 500;
  color: ${props => props.theme.colors.white};
`;

const AlignWithMobileLogo = styled(Flex)`
  margin-left: 42px;
  ${props => props.theme.mq.tabletP} {
    margin-left: unset;
  }
`;

const IntroSegment = ({ header, ...props }) => (
  <AlignWithMobileLogo
    flexDirection="column"
    width={{
      sm: 224,
    }}
    pt={{
      xs: 32,
      sm: 'inherit',
    }}
    mr={{
      sm: '20px',
    }}
    {...props}
  >
    <StandardText
      textAlign="left"
      mb="16px"
      color="white"
      fontSize="18px"
      fontWeight={600}
      lineHeight={1.2}
    >
      {header}
    </StandardText>
    <WorkingTime
      textAlign="left"
      color="white"
      fontSize="16px"
      fontWeight={500}
    />
  </AlignWithMobileLogo>
);

const ActionsSegment = ({ isAnomUser, theme, ...props }) => (
  <AlignWithMobileLogo
    flexDirection="column"
    width={{
      sm: 224,
    }}
    pt={{
      xs: 32,
      sm: 'inherit',
    }}
    {...props}
  >
    <CallUsWithIcon
      mb="16px"
      textProps={{
        fontWeight: 600,
        fontSize: '16px',
      }}
      iconProps={{
        fill: theme.colors.white,
        stroke: 'none',
        mr: '21px',
      }}
    />
    <EmailUsWithIcon
      mb="16px"
      isAnomUser
      textProps={{
        fontWeight: 600,
        fontSize: '16px',
      }}
      iconProps={{
        fill: theme.colors.white,
        stroke: 'none',
      }}
    />

    <RequestCallbackWithIcon
      textProps={{
        fontWeight: 600,
        fontSize: '16px',
      }}
      iconProps={{
        fill: theme.colors.white,
        stroke: 'none',
      }}
    />
  </AlignWithMobileLogo>
);

const LogoSegment = ({ isMobileScreenAndBigger, ...props }) => (
  <Flex
    flexDirection="column"
    width={{
      xs: '100%',
      sm: '50%',
    }}
    {...props}
  >
    <Logo alternate height={50} />
    <AlignWithMobileLogo flexDirection="column">
      <MembersHealthFundLogo mt={8} />
      {isMobileScreenAndBigger && <OtherInformationSegment mt={20} />}
    </AlignWithMobileLogo>
  </Flex>
);

const OtherInformationSegment = ({ ...props }) => {
  const { abn, organisationName } = useContactDetails();

  return (
    <AlignWithMobileLogo flexDirection="column" {...props}>
      <ContactDetailsText mb={12}>
        © {new Date().getFullYear()} {organisationName}
      </ContactDetailsText>
      <ContactDetailsText>ABN {abn}</ContactDetailsText>
    </AlignWithMobileLogo>
  );
};

const Footer = ({
  header = 'Need help with your application?',
  isAnomUser = true,
  as,
  ...props
}) => {
  const { theme } = useThemeUI();
  const isMobileScreenAndBigger = useMobileScreenAndBigger();
  const { disclaimer } = useContactDetails();

  const Container = as || Block;

  return (
    <Container
      pt={{
        xs: 40,
        sm: 64,
      }}
      pb={64}
      mb={0}
      bg={theme.colors.primary}
      {...props}
    >
      <Flex width="100%" flexDirection="column">
        <Flex
          width="100%"
          flexDirection={{
            xs: 'column',
            sm: 'row',
          }}
          justifyContent={{
            xs: 'flex-start',
            sm: 'space-between',
          }}
        >
          <LogoSegment isMobileScreenAndBigger={isMobileScreenAndBigger} />
          <IntroSegment header={header} />
          <ActionsSegment isAnomUser={isAnomUser} theme={theme} />
        </Flex>
        {!isMobileScreenAndBigger && (
          <OtherInformationSegment
            mt={24}
            pt={24}
            sx={{
              borderTop: '1px solid rgba(255, 255, 255, 0.25)',
            }}
          />
        )}
      </Flex>
      {disclaimer && (
        <Flex mt={40} color="white">
          <SanityRichTextContent
            content={disclaimer?.blockContent}
            forceColor="white"
            css={css`
              &&& {
                p {
                  font-size: ${theme.fontSizes[2]}px;
                  color: ${theme.colors.white} !important;
                }

                a {
                  color: ${theme.colors.white} !important;
                  text-decoration: underline;
                }
              }
            `}
          />
        </Flex>
      )}
    </Container>
  );
};

export default Footer;
