import React from 'react';
// import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { withTheme } from 'emotion-theming';
import { Flex, Box, Button } from 'rebass';

import FormTitle from '~OMS/styles/FormTitle';
import SSOFormContainer from '~OMS/styles/SSOFormContainer';
import IconHeading from '~common/atoms/IconHeading';

// SSO Rquest Sent page wrapper component
function RequestSentWrapper() {
  return (
    <SSOFormContainer>
      <FormTitle>Request sent!</FormTitle>
      <Flex>
        <Box width={1} mt="30px" mb="40px">
          <IconHeading
            icon="circle-fill-tick"
            fill="#569b07"
            text="We've sent your request to the account holder of this policy. After they grant access you will receive a welcome email to your inbox."
            mb="0px"
          />
        </Box>
      </Flex>
      <Flex>
        <Box width={1} mb="30px">
          <Button
            variant="inverted"
            css={{ width: '100%' }}
            as={Link}
            to="/oms/sso/login"
          >
            Close window
          </Button>
        </Box>
      </Flex>
      <Flex>
        <Box width={1}>
          <Box css={{ textAlign: 'center' }}>
            <span style={{ paddingRight: '10px' }}>Still having problems?</span>
            <Link to="/oms/sso/login">Lets chat</Link>
          </Box>
        </Box>
      </Flex>
    </SSOFormContainer>
  );
}

export default withTheme(RequestSentWrapper);
