import { graphql, useStaticQuery } from 'gatsby';

const HEALTH_FUNDS_QUERY = graphql`
  query ApplicationFromHealthFundsQuery {
    sanityApplicationFormHealthFunds {
      healthFund {
        id
        title
        _key
      }
    }
  }
`;

export default () => {
  const result = useStaticQuery(HEALTH_FUNDS_QUERY);
  return result.sanityApplicationFormHealthFunds.healthFund;
};
