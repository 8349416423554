import React from 'react';
import RebateApplicationSection from './RebateApplicationSection';
import { formatDob, getPersonFullName } from '../../common/appFormUtills';
import { formatAddress } from '~lib/filters';
import { formatDate } from '~lib/dates';

const ClaimantDetails = ({
  privateHealthDetails,
  personalDetails,
  contactDetails,
  ...props
}) => {
  return (
    <RebateApplicationSection title="Claimant details" {...props}>
      {({ Field, Label, spacing }) => (
        <>
          <Label mb={spacing}>
            Please review the information you have provided
          </Label>
          {personalDetails.values.currentHealthFund && (
            <>
              <Label>Private health fund:</Label>
              <Field>{privateHealthDetails.values.currentHealthFund}</Field>
            </>
          )}

          <Label>Premium reduction commencement:</Label>
          <Field>
            {formatDate(privateHealthDetails.values.startCoverDate)}
          </Field>

          <Label>Full name:</Label>
          <Field>{getPersonFullName(personalDetails.values)}</Field>

          <Label>Date of birth:</Label>
          <Field>{formatDob(personalDetails)}</Field>

          <Label>Gender:</Label>
          <Field>{personalDetails.values.gender}</Field>

          <Label>Residential address:</Label>
          <Field>
            {formatAddress(contactDetails.values.residentialAddress)}
          </Field>

          {contactDetails.values.isPostalAddressDifferent && (
            <>
              <Label>Postal address:</Label>
              <Field>
                {formatAddress(contactDetails.values.postalAddress)}
              </Field>
            </>
          )}

          <Label>Contact phone:</Label>
          <Field>{contactDetails.values.phoneNumber}</Field>
        </>
      )}
    </RebateApplicationSection>
  );
};

export default ClaimantDetails;
