import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Flex, Box } from 'rebass';
import { withTheme } from 'emotion-theming';
import SanityImage from '~common/molecules/SanityImage';

const StyledTabs = styled(Flex)`
  align-items: flex-end;
`;

const Title = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
  color: ${props => props.theme.colors.text.light};

  @media screen and (max-width: 992px) {
    font-size: ${props => props.theme.fontSizes[1]}px;
  }
`;

const Coverage = styled.span`
  font-size: ${props => props.theme.fontSizes[2] + 2}px;
  font-weight: 400;
  line-height: 1.2;
  margin: 2px 0 10px;

  @media screen and (max-width: 992px) {
    font-size: ${props => props.theme.fontSizes[1]}px;
  }
`;

const Price = styled.span`
  font-size: ${props => props.theme.fontSizes[1]}px;
  color: ${props => props.theme.colors.text.light};
`;

const Badge = styled(SanityImage)`
  position: absolute;
  top: -19px;
  right: 6px;
  width: 35px;
  height: 47px;

  @media screen and (max-width: 992px) {
    right: -4px;
  }

  @media screen and (max-width: 370px) {
    display: none !important;
  }
`;

const Tab = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 16px 4px;
  color: ${props =>
    props.selected ? props.theme.colors.white : props.theme.colors.text.light};
  text-align: center;
  border: none;
  appearance: none;
  background-color: ${props =>
    props.selected
      ? props.theme.colors[props.type] || props.theme.colors.primary
      : props.theme.colors.background.gray};
  vertical-align: middle;
  cursor: pointer;
  outline: none;
  box-shadow: ${props => props.selected && '2px 2px 2px 0 rgba(0, 0, 0, 0.5)'};
  border-top: 12px solid
    ${props =>
      props.selected
        ? props.theme.colors[props.type] || props.theme.colors.primary
        : props.theme.colors.background.gray};
  z-index: ${props => (props.selected ? '18' : '10')};

  &:nth-of-type(2) {
    border-left: 1px solid
      ${props =>
        props.selected
          ? props.theme.colors[props.type] || props.theme.colors.primary
          : props.theme.colors.background.gray};
    border-right: 1px solid
      ${props =>
        props.selected
          ? props.theme.colors[props.type] || props.theme.colors.primary
          : props.theme.colors.background.gray};
  }

  &:last-of-type {
    border-right: none;
  }

  &:hover {
    background-color: ${props =>
      props.selected
        ? props.theme.colors[props.type] || props.theme.colors.primary
        : props.theme.colors.hover.light2};
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -30px;
    left: calc(50% - 20px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${props => (props.selected ? '30px 20px 0 20px' : '0')};
    border-color: ${props =>
        props.theme.colors[props.type] || props.theme.colors.primary}
      transparent transparent transparent;
    z-index: 18;
  }

  ${Title}, ${Coverage}, ${Price} {
    color: ${props => props.selected && props.theme.colors.text.white};
  }

  ${Price} {
    font-weight: ${props => props.selected && '700'};
  }

  ${Badge} {
    top: ${props => (props.selected ? '-19px' : '-7px')};
  }

  @media screen and (max-width: 992px) {
    padding: 5px 4px 7px 4px;
  }
`;

const StyledCurrentWrapper = styled(Flex)`
  width: 100%;
`;

const CurrentBox = styled(Flex)`
  width: 100%;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  height: ${props => props.theme.fontSizes[2]}px;
`;

const CurrentArrow = styled(Box)`
  transform: rotate(90deg);
`;

const hasVariant = (product, code) =>
  product.variants.some(variant => variant.onSale && variant.code === code);

const Tabs = props => {
  return (
    <>
      <StyledCurrentWrapper theme={props.theme} mb={2}>
        {props.products
          .filter(product => product.variants.filter(v => v.onSale).length > 0)
          .map(product => {
            const isCurrent = !!product.variants.find(
              variant => variant.code === props.current
            );
            const isSelected = hasVariant(product, props.selected);

            const variant = isSelected
              ? product.variants.find(
                  v => v.onSale && v.code === props.selected
                )
              : product.variants.filter(v => v.onSale)[0];
            return (
              <CurrentBox key={variant.code}>
                {isCurrent && (
                  <>
                    CURRENT <CurrentArrow ml={2}>&#10142;</CurrentArrow>
                  </>
                )}
              </CurrentBox>
            );
          })}
      </StyledCurrentWrapper>
      <StyledTabs className={classnames(props.className, 'product-tabs')}>
        {props.products
          .filter(product => product.variants.filter(v => v.onSale).length > 0)
          .map((product, index) => {
            const isSelected = hasVariant(product, props.selected);

            const variant = isSelected
              ? product.variants.find(
                  v => v.code === props.selected && v.onSale
                )
              : product.variants.filter(v => v.onSale)[0];

            return (
              <Tab
                type={props.type}
                data-id={`product-${product.type}-${index}`}
                key={variant.code}
                product={product}
                selected={isSelected}
                className={classnames('tab', {
                  selected: isSelected,
                })}
                onClick={() => {
                  props.onChange(variant.code);
                }}
              >
                {product.badge && <Badge src={product.badge} alt="" />}
                {product.title && <Title>{product.title}</Title>}
                {product.coverage && <Coverage>{product.coverage}</Coverage>}
              </Tab>
            );
          })}
      </StyledTabs>
    </>
  );
};

Tabs.propTypes = {
  className: PropTypes.any,
  onChange: PropTypes.any,
  paymentFrequency: PropTypes.any,
  products: PropTypes.any,
  rates: PropTypes.any,
  selected: PropTypes.any,
};

const ProductTabs = styled(Tabs)`
  display: flex;
  flex-direction: row;
  color: ${props => props.color};

  ${Tab}:first-of-type {
    ${'' /* flex: 1.5; */}
    border-left: none;
  }
`;

export default withTheme(ProductTabs);
