import React from 'react';
import get from 'lodash/get';
import { Flex, Box } from 'rebass';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';

import Link from '~common/atoms/Link';

import { DashboardClaimQuery } from 'queries/oms/dashboard';
import { formatDate } from '~lib/dates';
import { Button } from '~common/atoms/Button';
import { Caption, P2, P3 } from '~common/atoms/typography';
import Divider from '~common/atoms/Divider';
import Loading from '~common/atoms/Loading';
import IconHeading from '~common/atoms/IconHeading';
import { CLAIM_STATUS } from '~OMS/claims/constants';
import { formatClaimId } from '~OMS/claims/utils';

const StyledStatus = styled(Box)`
  background: ${props =>
    props.status === CLAIM_STATUS.PROCESSED
      ? props.theme.colors.success500
      : props.status === CLAIM_STATUS.SUBMITTED
      ? props.theme.colors.primary
      : props.theme.colors.secondary};
  padding: 2px 8px;
  border-radius: 2px;
`;

const Status = ({ status, ...props }) => {
  return (
    <StyledStatus status={status} {...props}>
      <Caption color="white" variant="semiBold">
        {status === CLAIM_STATUS.PROCESSED ? 'APPROVED' : status}
      </Caption>
    </StyledStatus>
  );
};

const CardClaims = () => {
  const { loading, error, data } = useQuery(DashboardClaimQuery, {
    variables: { input: { enableSubmittedClaims: true } },
  });

  if (error) {
    console.error(error);
    return null;
  }

  const mostRecentClaim = get(data, 'oms.membership.lastClaim.claim', null);
  const mostRecentClaimLine = get(mostRecentClaim, 'lines[0]', null);

  return (
    <Flex flexDirection="column" justifyContent={'space-between'} height="100%">
      <Box>
        <IconHeading icon="quick-link" text="Recent claim" />
        {loading ? (
          <Loading />
        ) : (
          <>
            {mostRecentClaim && mostRecentClaimLine ? (
              <>
                <P2 mb="4px" variant="semiBold">
                  {mostRecentClaimLine?.description}
                </P2>
                <P2 mb="4px" variant="medium">
                  Submitted: {formatDate(mostRecentClaim?.claimDate)}
                </P2>
                <P2 variant="medium" mb="4px">
                  Claim reference: #{formatClaimId(mostRecentClaim)}
                </P2>
                <Flex>
                  <P2 mr="4px" variant="medium">
                    Status:
                  </P2>
                  <Status status={mostRecentClaim?.status} />
                </Flex>
              </>
            ) : (
              <P2 variant="regular">You don't have recent claims</P2>
            )}
            <Divider my="16px" />
            <P3 color="neutral900" mb="8px" variant="medium">
              For a detailed view of all your claims from up to the past 3 years
              visit
            </P3>
            <Link to="/oms/claims/history">View claim history</Link>
          </>
        )}
      </Box>
      <Button
        mt="20px"
        as={Link}
        variant="secondary"
        size="medium"
        width={1}
        to="/oms/claims"
      >
        Make a claim
      </Button>
    </Flex>
  );
};

export default withTheme(CardClaims);
