import React, { useEffect } from 'react';
import ContactUsBlock from './ContactUsBlock';
import { Flex } from 'rebass';
import Icon from '../../common/atoms/Icon';
import { SectionTitle } from './Styles';
import EmailUsConfirmation from '~OMS/contact-us/EmailUsConfirmation';
import { MarketoForm } from '~common/organisms/Marketo';
import { useContactUsForms, VIEWS } from '~OMS/contact-us/useContactUsForms';

const EmailUsFormBlock = ({ theme }) => {
  const { state, submitEmailUsForm, setView } = useContactUsForms();
  useEffect(() => {
    setView(VIEWS.EMAIL_US);
  }, [setView]);
  return (
    <ContactUsBlock
      mb={0}
      pt={70}
      pb={{
        xs: 50,
        tabletP: 60,
        tabletL: 80,
        lg: 100,
      }}
      type="dark"
      redefinePl={{
        tabletP: 0,
        tabletL: 0,
        xl: 0,
        lg: 0,
      }}
      redefinePr={{
        tabletP: 0,
        tabletL: 0,
        xl: 0,
        lg: 0,
      }}
      innerProps={{
        xs: 1,
        width: 791,
      }}
    >
      <Flex
        alignItems="flex-start"
        flexDirection={{
          xs: 'column',
          tabletP: 'row',
        }}
      >
        <Flex
          width={{
            xs: 1,
            tabletP: 183,
          }}
          mr={{
            xs: 0,
            tabletP: 122,
          }}
          alignItems="center"
          mb={{
            xs: 44,
            tabletP: 0,
          }}
        >
          <Icon
            fill={theme.colors.background.dark}
            name="email"
            width="36px"
            height="36px"
          />
          <SectionTitle ml={2}>Email us</SectionTitle>
        </Flex>
        {state.view === VIEWS.EMAIL_US && (
          <MarketoForm
            formId={process.env.MARKETO_CONTACT_US_FORM_FORM_ID}
            width={{
              xs: 1,
              tabletP: 488,
            }}
            values={state.initialEmailUsFormValues}
            onSubmit={submitEmailUsForm}
          />
        )}
        {state.view === VIEWS.EMAIL_US_CONFIRM && (
          <EmailUsConfirmation
            onClose={() => {
              setView(VIEWS.EMAIL_US);
            }}
            confirmText="Enquire again"
          />
        )}
      </Flex>
    </ContactUsBlock>
  );
};

export default EmailUsFormBlock;
