import React from 'react';
import moment from 'moment';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import { Box } from 'rebass';

const TimelineItem = styled.div`
  padding: 10px 0 10px 40px;
  font-size: ${props => props.theme.fontSizes[1]}px;
  line-height: 16px;
  color: #545454;
  position: relative;
  &:nth-of-type(1) {
    &::before {
      top: 50%;
      height: 50%;
    }
  }
  &:last-child {
    &::before {
      height: 50%;
    }
  }
  &::before {
    content: '';
    position: absolute;
    left: 5px;
    width: 2px;
    height: 100%;
    top: 0;
    bottom: 0;
    background: ${props => props.colors.borders};
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid ${props => props.colors.borders};
  }
`;

const TimelineArrow = styled.div`
  background: #fff;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 34px;
  top: 0;
  bottom: 0;
  margin: auto;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  transform: rotate(-45deg) skew(10deg, 10deg);
`;
const TimelineContent = styled.div`
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid ${props => props.colors.borders};
`;

const Timeline = ({ theme, data }) => {
  return (
    <>
      {data.map((e, i) => (
        <TimelineItem key={i} {...theme}>
          <TimelineContent {...theme}>
            <Box>{e.body}</Box>
            <Box css={{ fontSize: 11 }}>
              {moment(e.date, moment.ISO_8601).format('h:mma DD/MM/YYYY')}
            </Box>
          </TimelineContent>
          <TimelineArrow {...theme}></TimelineArrow>
        </TimelineItem>
      ))}
    </>
  );
};

export default withTheme(Timeline);
