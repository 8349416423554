import {
  AsyncFormValidator,
  bsb,
  maxLength,
  required,
} from '~lib/formValidation';

import { verifyBsb } from '~common/services/kleberService';

import {
  accountNameValidator,
  creditCardValidation,
} from '~OMS/payments/validators';
import { DEBIT_TYPE } from '../constants';

const accountName = [
  required('Account name is required field'),
  value => {
    return accountNameValidator().validateSync(value);
  },
];

const filterOut = (validationArray, fieldsToFilterOut) => {
  return validationArray.filter(
    ([field]) => !fieldsToFilterOut.includes(field)
  );
};

const creditCardFields = Object.keys(creditCardValidation);

const paymentsValidation = () => {
  const bsbValidation = [
    required('BSB is required'),
    bsb(),
    value => {
      return verifyBsb(value).then(result => {
        if (!result || !result.isValid) {
          throw 'The BSB you have entered does not exist. Please double check it.';
        }
      });
    },
  ];

  const bankAccountValidation = {
    accountName,
    bsb: bsbValidation,
    accountNumber: [
      required('Account number is required field'),
      maxLength(9, 'Up to 9 digits allowed'),
    ],
  };

  const bankAccountFields = Object.keys(bankAccountValidation);

  return AsyncFormValidator({
    validation: {
      debitType: required('Please select how would you like to pay'),
      ...bankAccountValidation,
      ...creditCardValidation,
      paymentFrequency: required('Payment frequency is required field'),
      preferredDebitDay: required('Preferred debit day is required field'),
      claimBsb: bsbValidation,
      claimAccountNumber: required('Account number is required field'),
      claimAccountName: accountName,
      directDebitAgreements: required('Please accept direct debit agreement'),
    },
    transformValidation: (validationArray, form) => {
      let fieldsToFilterOut =
        form.debitType === DEBIT_TYPE.BANK_ACCOUNT
          ? creditCardFields
          : bankAccountFields;

      if (form.useBankAccountDetails) {
        fieldsToFilterOut = fieldsToFilterOut.concat([
          'claimBsb',
          'claimAccountNumber',
          'claimAccountName',
        ]);
      }

      return filterOut(validationArray, fieldsToFilterOut);
    },
  });
};

export default paymentsValidation;
