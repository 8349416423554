import { Flex } from 'rebass';
import { StandardText } from '../../../../common/atoms/typography';
import React from 'react';

const ClaimHeader = ({ columns, ...props }) => (
  <Flex mb={2} {...props}>
    {columns.map((column, index) => (
      <StandardText
        key={index}
        width={column.width}
        br={column.pr}
        fontSize={1}
        bold
      >
        {column.displayName}
      </StandardText>
    ))}
  </Flex>
);

export default ClaimHeader;
