import { useEffect, useMemo, useState } from 'react';
import {
  CHANGE_DETAILS_ACTION,
  PAYMENT_FORM_MODE,
  PAYMENT_METHODS,
  PAYMENT_TYPE,
} from '~OMS/payments/constants';
import useFormModes from '~OMS/payments/hooks/useFormModes';
import { PaymentPolicyQuery } from 'queries/oms/payments.graphql';
import { useQuery } from '@apollo/client';
import { chainedPath } from '~lib/util';
import useChangePaymentDetails from '~OMS/payments/hooks/useChangePaymentDetails/useChangePaymentDetails';
import {
  getChangePaymentPolicyInfoText,
  parseDebitAccountType,
} from '~OMS/payments/hooks/usePaymentPolicy/utils';
import { isPayrollPayment } from '~OMS/response-selectors';
import { NetworkStatus } from '@apollo/client';
import useMembership from '~common/hooks/useMembership';
import useFeatureToggle from '~lib/hooks/useFeatureToggle';

const getActivePaymentMethod = debitAccount => {
  return debitAccount ? PAYMENT_METHODS.DIRECT_DEBIT : PAYMENT_METHODS.INVOICE;
};

const getPaymentMethod = (currentUserSelection, debitAccount, isPayroll) => {
  if (currentUserSelection) {
    return currentUserSelection;
  }

  if (isPayroll) {
    return PAYMENT_METHODS.PAYROLL;
  }

  return getActivePaymentMethod(debitAccount);
};

export default () => {
  const {
    data,
    refetch: refetchPaymentPolicyQuery,
    networkStatus,
  } = useQuery(PaymentPolicyQuery, {
    notifyOnNetworkStatusChange: true,
  });

  const { isS2IMembership, loading: membershipLoading } = useMembership();

  const changePaymentDetails = useChangePaymentDetails();
  const { disablePayByInvoice } = useFeatureToggle();
  const [paymentMethod, setPaymentMethod] = useState();
  const [paymentType, setPaymentType] = useState();

  const isPayroll = isPayrollPayment(data);

  const payments = chainedPath('oms.payments')(data);
  const formModes = useFormModes();

  const getAction = form => {
    if (form.type === PAYMENT_TYPE.BANK_ACCOUNT) {
      return payments('debitAccount') &&
        payments('debitAccount.type') === 'Bank'
        ? CHANGE_DETAILS_ACTION.EDIT
        : CHANGE_DETAILS_ACTION.ADD;
    }

    return payments('debitAccount') && payments('debitAccount.type') !== 'Bank'
      ? CHANGE_DETAILS_ACTION.EDIT
      : CHANGE_DETAILS_ACTION.ADD;
  };

  const createDetailsChanger = changeDetailsImpl => {
    return async (...args) => {
      const { success } = (await changeDetailsImpl(...args)) || {};
      if (success) {
        formModes.actions.viewDetails();
      }
      if (isPayroll) {
        await refetchPaymentPolicyQuery();
      }
    };
  };

  const changeDebitDetails = createDetailsChanger(form => {
    const action = getAction(form);
    return changePaymentDetails.actions.submitDebitDetailsChange(form, action);
  });

  const changeInvoiceDetails = createDetailsChanger(
    changePaymentDetails.actions.submitInvoiceDetailsChange
  );

  const startEditing = () => {
    changePaymentDetails.actions.reset();
    formModes.actions.startEditing();
  };

  const selectedPaymentMethod = getPaymentMethod(
    paymentMethod,
    payments('debitAccount'),
    isPayroll
  );

  const initialPaymentType = parseDebitAccountType(
    payments('debitAccount.type')
  );

  const initialForm = {
    date: payments('date'),
    frequency: payments('frequency'),
    termsAndConditions: false,
    ...payments('debitAccount.details'),
    cardHolder: payments('debitAccount.details.holder'),
    cardNumber: payments('debitAccount.details.number'),
    sourceType: payments('debitAccount.type'),
    paidToDate: payments('paidToDate'),
    paymentAmount: payments('amount'),
    type: initialPaymentType,
  };

  const isActivePaymentMethod =
    getActivePaymentMethod(payments('debitAccount')) === selectedPaymentMethod;

  const reset = () => {
    changePaymentDetails.actions.reset();
    setPaymentType(initialPaymentType);
  };

  const paymentMethods = useMemo(() => {
    let methods = [
      {
        id: PAYMENT_METHODS.INVOICE,
        text: 'Invoice',
      },
      {
        id: PAYMENT_METHODS.DIRECT_DEBIT,
        text: 'Direct debit',
      },
    ];

    if (disablePayByInvoice) {
      methods = methods.filter(method => method.id !== PAYMENT_METHODS.INVOICE);
    }

    return isPayroll
      ? [
          {
            id: PAYMENT_METHODS.PAYROLL,
            text: 'Payroll',
          },
          ...methods,
        ]
      : methods;
  }, [isPayroll, disablePayByInvoice]);

  const selectPaymentMethod = method => {
    reset();
    if (getActivePaymentMethod(payments('debitAccount')) !== method) {
      formModes.actions.startEditing();
    }

    setPaymentMethod(method);
  };

  const changePaymentMethodWarningText = useMemo(() => {
    const debitAccount = payments('debitAccount');
    const activePaymentMethod = getActivePaymentMethod(debitAccount);
    return getChangePaymentPolicyInfoText({
      selectedPaymentMethod,
      activePaymentMethod,
      debitAccount,
      paymentType,
      isPayroll,
    });
  }, [isPayroll, paymentType, payments, selectedPaymentMethod]);

  const setPaymentTypeWrapper = type => {
    reset();
    setPaymentType(type);
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    setPaymentType(initialPaymentType);
  }, [data, initialPaymentType]);

  return {
    state: {
      ...changePaymentDetails.state,
      changePaymentMethodWarningText,
      isActivePaymentMethod,
      changingDetails:
        changePaymentDetails.state.loading ||
        networkStatus === NetworkStatus?.refetch,
      loading: networkStatus === NetworkStatus?.loading || membershipLoading,
      paymentMethod: selectedPaymentMethod,
      formMode: isPayroll ? PAYMENT_FORM_MODE.EDIT : formModes.state.formMode,
      data: initialForm,
      isPayroll,
      paymentMethods,
      isS2IMembership,
    },
    actions: {
      startEditing,
      viewDetails: formModes.actions.viewDetails,
      setPaymentMethod: selectPaymentMethod,
      changeDebitDetails,
      reset: changePaymentDetails.actions.reset,
      changeInvoiceDetails,
      setPaymentType: setPaymentTypeWrapper,
    },
  };
};
