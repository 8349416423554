import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { StandardText } from '~common/atoms/typography';

const WORKING_TIME_QUERY = graphql`
  {
    sanityContactDetails {
      _id
      workingTime {
        timezone
        hours {
          from
          to
        }
        days
      }
    }
  }
`;

const DAYS = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
};

const DAYS_LIST = Object.keys(DAYS);

export const formatDays = days => {
  if (days.every((day, index) => day === DAYS_LIST[index])) {
    const from = DAYS[days[0]];
    const to = DAYS[days[days.length - 1]];
    return `${from} to ${to}`;
  }

  return days.map(day => DAYS[day]).join(', ');
};

const formatWorkingTime = workingTime => {
  return `${workingTime.hours.from} to ${workingTime.hours.to} (${
    workingTime.timezone
  }), ${formatDays(workingTime.days)} - excluding NSW public holidays.`;
};

export const WorkingTimeSimple = ({ render }) => {
  const { sanityContactDetails } = useStaticQuery(WORKING_TIME_QUERY);
  return render({
    ...sanityContactDetails?.workingTime,
    days: formatDays(sanityContactDetails?.workingTime?.days),
  });
};

const WorkingTime = ({ render, ...props }) => {
  const { sanityContactDetails } = useStaticQuery(WORKING_TIME_QUERY);
  const workingTimeText = formatWorkingTime(sanityContactDetails.workingTime);
  return (
    <StandardText fontSize={2} {...props}>
      {render ? render(workingTimeText) : workingTimeText}
    </StandardText>
  );
};

export default WorkingTime;
