import React from 'react';
import { MaskedInput } from '~common/atoms/Input';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import PaymentAmountInfo from '~OMS/payments/molecules/PaymentAmountInfo';
import checkPaymentFactory from '~OMS/payments/molecules/PaymentChecks/checkPaymentFactory';

const currencyMask = createNumberMask({
  allowDecimal: true,
});

export default checkPaymentFactory({
  InputComponent: props => <MaskedInput mask={currencyMask} {...props} />,
  renderInfoAlert: ({ checkData, inputValue, isMaximumPeriodExceeded }) => (
    <PaymentAmountInfo amount={inputValue} date={checkData}></PaymentAmountInfo>
  ),
  checkButtonText: 'Calculate date',
  fromPaymentCheck: data => data.date,
  buttonDataId: 'calculate-date-btn',
});
