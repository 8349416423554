import React from 'react';
import styled from '@emotion/styled';
import { useThemeUI } from 'theme-ui';
import Icon from '~common/atoms/Icon';

const StyledItemsGroup = styled.ul`
  list-style-type: none;
  padding: 40px 0 0 0;
  margin-top: -32px;
  margin-left: auto;
  margin-right: auto;
  max-width: 615px;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;

  ${props => props.theme.mq.tinyPhone} {
    align-items: unset;
    flex-flow: row nowrap;
  }
`;

const StyledItem = styled.li`
  max-width: 130px;
  width: 100%;
  text-align: center;
  font-size: ${props => props.theme.fontSizes[3]}px;
  line-height: 21px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
`;

const StyledIcon = styled(Icon)`
  margin-bottom: 8px;
`;

const Items = () => {
  const { theme } = useThemeUI();
  return (
    <StyledItemsGroup>
      <StyledItem>
        <StyledIcon
          aria-hidden
          name="minutes"
          width="58px"
          height="58px"
          fill={theme.colors.primary}
        />
        Minutes of your time
      </StyledItem>
      <StyledItem>
        <StyledIcon
          aria-hidden
          name="medicare"
          width="58px"
          height="39px"
          fill={theme.colors.primary}
        />
        Your valid medicare card
      </StyledItem>
      <StyledItem>
        <StyledIcon
          aria-hidden
          name="credit-card"
          width="58px"
          height="58px"
          fill={theme.colors.primary}
        />
        Credit card or bank details
      </StyledItem>
    </StyledItemsGroup>
  );
};

export default Items;
