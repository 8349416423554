import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Element } from 'react-scroll';

import Icon from '~common/atoms/Icon';
import { tierInfo } from '~lib/inclusionConstants';
import { useInclusionsByTierQuery } from '~lib/hooks/sanity/useInclusionsQuery';
import { useMediaQuery } from 'react-responsive';
import {
  checkSelectedProduct,
  renderFeaturedTiers,
} from 'components/Quote/QuoteResultsNew/shared/utils';
import { useThemeUI } from 'theme-ui';

const StyledHeaderButton = styled.button`
  appearance: none;
  border: 0;
  border-radius: 0;
  position: relative;
  white-space: pre-line;
  width: 100%;
  font-size: ${props => props.theme.fontSizes[1]}px;
  line-height: 13px;
  padding: 8px 18px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out,
    0.2s color ease-in-out;
  background-color: ${props =>
    props.isSelectedProduct
      ? props.theme.colors.primary
      : props.theme.colors.lightBlue};
  border-color: ${props =>
    props.isSelectedProduct
      ? props.theme.colors.primary
      : props.theme.colors.lightBlue};
  color: ${props =>
    props.isSelectedProduct
      ? props.theme.colors.white
      : props.theme.colors.text};

  &:hover {
    background-color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
  }

  &:active {
    border-color: ${props => props.theme.colors.blue600};
    background-color: ${props => props.theme.colors.blue600};
    color: ${props => props.theme.colors.white};
  }

  &:disabled,
  &:disabled:hover {
    border-color: ${props => props.theme.colors.lightNeutral200};
    background-color: ${props => props.theme.colors.lightNeutral200};
    color: ${props => props.theme.colors.text};
  }

  > span {
    font-weight: normal;
    width: 100%;
  }
`;

const StyledToggle = styled.button`
  ${props => props.theme.buttons.minimal};
  background-color: ${props => props.theme.colors.blue50};
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes[3]}px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10.5px 0;
  width: 100%;
  text-decoration: none;
`;

const Toggle = ({ onInclusionsToggle, isDesktop, open }) => {
  const { theme } = useThemeUI();

  return (
    <StyledToggle onClick={onInclusionsToggle}>
      {isDesktop && open
        ? 'Show less'
        : isDesktop && !open
        ? 'Show more'
        : !isDesktop && open
        ? 'Hide comparisons'
        : 'Show comparisons'}
      <Icon
        name={open ? 'chevron-up-thin' : 'chevron-down-thin'}
        fill={theme.colors.primary}
        width="16px"
        height="8.5px"
        style={{ marginLeft: 10 }}
      />
    </StyledToggle>
  );
};

const StyledCoverInclusions = styled('table', {
  shouldForwardProp: propName =>
    !['isSelectedProduct', 'totalStickyHeight'].includes(propName),
})`
  width: 100%;

  tr {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    ${props => props.theme.mq.tabletP} {
      display: grid;
      grid-template-columns: ${({ cardsCount }) => {
        if (cardsCount >= 3) {
          return `repeat(${cardsCount + 1}, 1fr);`;
        }

        return `50% ${Array.from({ length: cardsCount })
          .map(() => `${50 / cardsCount}%`)
          .join(' ')}`;
      }};
    }
  }

  thead {
    position: sticky;
    top: ${props => props.totalStickyHeight}px;
    z-index: 3;

    th {
      flex: 1 1 33.33%;

      &:first-of-type {
        display: none;

        ${props => props.theme.mq.sm} {
          margin-right: auto;
          display: inherit;
        }
      }

      ${props => props.theme.mq.sm} {
        flex: 0 1 25%;
      }

      ${props => props.theme.mq.md} {
        flex: 0 1 25%;
      }
    }
  }

  tbody {
    ${props => props.theme.mq.smInverted} {
      border-left: 1px solid ${props => props.theme.colors.lightBlue};
      border-right: 1px solid ${props => props.theme.colors.lightBlue};
    }

    tr {
      flex-wrap: wrap;

      &[id] {
        margin-left: 0;
        display: flex;

        &:first-of-type {
          border-top: 1px solid ${props => props.theme.colors.lightBlue};
        }

        td {
          flex: 0 1 100%;
          text-align: left;
          font-size: ${props => props.theme.fontSizes[3]}px;
          line-height: 21px;
          font-weight: 600;
          margin-left: 0;
        }
      }
    }

    td {
      flex: 1 1 33.33%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8.5px 16px;
      border-bottom: 1px solid ${props => props.theme.colors.lightBlue};
      background-color: ${props => props.theme.colors.white};
      font-size: ${props => props.theme.fontSizes[2]}px;
      font-weight: 500;
      line-height: 18px;
      color: ${props => props.theme.colors.text};
      position: relative;

      &:first-of-type {
        justify-content: flex-start;
        flex: 0 1 100%;

        ${props => props.theme.mq.smInverted} {
          border-bottom: none;
        }

        ${props => props.theme.mq.sm} {
          flex: 0 1 25%;
        }
      }

      ${props => props.theme.mq.sm} {
        flex: 0 1 26%;
        border-left: 1px solid ${props => props.theme.colors.lightBlue};
        border-right: 1px solid ${props => props.theme.colors.lightBlue};
      }

      ${props => props.theme.mq.md} {
        flex: 0 1 25%;
      }
    }
  }
`;

const StyledRow = styled.tr`
  &:not(:last-of-type) {
    td:before {
      border-bottom: none;
    }
  }
`;

const StyledCell = styled('td', {
  shouldForwardProp: propName => !['isSelected'].includes(propName),
})`
  ${props => props.theme.mq.sm} {
    background-color: ${props =>
      props.isSelected
        ? 'rgba(233, 236, 245, 0.4)'
        : props.theme.colors.white} !important;
  }

  &:before {
    content: '';
    position: absolute;
    top: -40px;
    left: -1px;
    right: -1px;
    bottom: 0;
    pointer-events: none;
    z-index: 1;

    ${props => props.theme.mq.sm} {
      border: 1px solid
        ${props =>
          props.isSelected ? props.theme.colors.primary : 'transparent'};
      border-top: none;
    }
  }
`;

const CoverInclusions = ({
  onInclusionsToggle,
  open,
  products,
  selectedProductCode,
  totalStickyHeight,
  type,
  updateForm,
}) => {
  const isDesktop = useMediaQuery({
    query: `(min-width: 768px)`,
  });

  const inclusionTiers = useInclusionsByTierQuery(type);

  const handleClick = (isSelected, code) => {
    if (isSelected) {
      return;
    }
    updateForm(`${type}Product`, code);
  };

  // mobile hides them all when closed
  // desktop has to show some even when closed
  const tiersArray = open
    ? ['basic', 'bronze', 'silver', 'gold', 'other']
    : isDesktop && !open
    ? ['basic', 'other']
    : [];

  const onSaleProducts = products?.filter(product =>
    product.allVariants.some(v => v.onSale)
  );

  const renderInclusions = ({ tier, nodes, label, textColor, color }) => (
    <React.Fragment key={tier}>
      <tr id={tier} role="row">
        <td
          style={{ backgroundColor: color, color: textColor }}
          colSpan={products.length + 1}
        >
          {label}
        </td>
      </tr>
      {nodes.map((node, i) => {
        return (
          <StyledRow key={node.id} role="row">
            <td role="cell">{node.title}</td>
            {products
              .filter(
                product => product.allVariants.filter(v => v.onSale).length > 0
              )
              .map(p => {
                const { isSelected } = checkSelectedProduct(
                  p.variants,
                  selectedProductCode
                );

                const productInclusion = p.inclusions.find(
                  pi => pi.inclusion._id === node.id
                );

                const inclusionType =
                  productInclusion && productInclusion.type.toLowerCase();

                if (
                  inclusionType === 'included' ||
                  inclusionType === 'included-p'
                ) {
                  return (
                    <StyledCell key={p.id} role="cell" isSelected={isSelected}>
                      <span className="sr-only">Included</span>
                      <Icon
                        aria-hidden
                        name="circle-fill-tick"
                        fill="#569b07"
                        width="16px"
                        height="16px"
                      />
                      {inclusionType === 'included-p' ? <sup>**</sup> : null}
                    </StyledCell>
                  );
                }

                if (inclusionType === 'restricted') {
                  return (
                    <StyledCell key={p.id} role="cell" isSelected={isSelected}>
                      <span className="sr-only">Restricted</span>
                      <Icon
                        aria-hidden
                        name="restricted"
                        fill="#f5a623"
                        width="16px"
                        height="16px"
                      />
                    </StyledCell>
                  );
                }

                if (inclusionType === 'restricted-accident') {
                  return <td key={p.id}>accident only</td>;
                }

                if (inclusionType === 'excluded') {
                  return (
                    <StyledCell key={p.id} role="cell" isSelected={isSelected}>
                      <span className="sr-only">Excluded</span>
                      <Icon
                        aria-hidden
                        name="remove"
                        fill="#d0021b"
                        width="16px"
                        height="16px"
                      />
                    </StyledCell>
                  );
                }
              })}
          </StyledRow>
        );
      })}
    </React.Fragment>
  );

  return (
    <Element name={`quote-inclusions-${type}`}>
      {tiersArray.length ? (
        <StyledCoverInclusions
          cardsCount={onSaleProducts?.length}
          id={type}
          role="table"
          totalStickyHeight={totalStickyHeight}
        >
          <thead className="sr-only-md">
            <tr role="row">
              <th aria-hidden>&nbsp;</th>
              {onSaleProducts?.map(product => {
                const { isSelected, variant } = checkSelectedProduct(
                  product.variants,
                  selectedProductCode
                );
                return (
                  <th key={product.id}>
                    <StyledHeaderButton
                      onClick={() =>
                        product.isValid
                          ? handleClick(isSelected, variant.code)
                          : null
                      }
                      isSelectedProduct={isSelected}
                      disabled={!product.isValid}
                    >
                      {product.title} <span>{product.coverage}</span>
                    </StyledHeaderButton>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {renderFeaturedTiers(inclusionTiers)(renderInclusions)}

            {tiersArray.map(tier => {
              const nodes = inclusionTiers[tier];

              if (nodes) {
                // Extras dont have tiers, this ensures only the first
                // four are shown on desktop
                const formattedNodes =
                  !open && tier === 'other' ? nodes.slice(0, 4) : nodes;

                return renderInclusions({
                  tier,
                  nodes: formattedNodes,
                  label: tierInfo[tier].label,
                  textColor: tierInfo[tier].textColor || 'white',
                  color: tierInfo[tier].color,
                });
              }

              return null;
            })}
          </tbody>
        </StyledCoverInclusions>
      ) : null}
      <Toggle
        onInclusionsToggle={onInclusionsToggle}
        isDesktop={isDesktop}
        open={open}
      />
    </Element>
  );
};

CoverInclusions.propTypes = {
  onInclusionsToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
  selectedProductCode: PropTypes.string.isRequired,
  totalStickyHeight: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default CoverInclusions;
