import { css, keyframes } from '@emotion/core';

const slideOutKeyframes = keyframes`
  from {
     padding-top: 0;
     padding-bottom: 0;
     height: 0;
     opacity: 0;
  }
  to {
    padding-top: 25px;
    padding-bottom: 25px;
    height: 100%;
    opacity: 1;
  }
`;

const slideInKeyFrames = keyframes`
  from {
    padding-top: 25px;
    padding-bottom: 25px;
    height: 100%;
    opacity: 1;
  }
  to {
     padding-top: 0;
     padding-bottom: 0;
     height: 0;
     opacity: 0;
  }
`;

export const slideIn = () => css`
  animation: ${slideInKeyFrames} ease-out 300ms;
`;

export const slideOut = () => css`
  animation: ${slideOutKeyframes} ease-out 300ms;
`;
