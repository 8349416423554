import React, { useMemo } from 'react';
import RebateApplicationSection from './RebateApplicationSection';
import { formatDob, getPersonFullName } from '../../common/appFormUtills';
import { capitalize } from '~lib/util';
import { Box } from 'rebass';

const PolicyIndividuals = ({ dependants = [], partner = {}, ...props }) => {
  const showDivider = useMemo(() => {
    return partner.isValid || dependants.some(dependant => dependant.isValid);
  }, [dependants, partner]);

  return (
    <RebateApplicationSection divider={showDivider} {...props}>
      {({ Label, spacing }) => (
        <>
          {partner.isValid && (
            <>
              <Label>Full name: {getPersonFullName(partner.values)}</Label>
              <Label>Relationship: Spouse</Label>
              <Label mb={spacing}>Date of birth: {formatDob(partner)}</Label>
            </>
          )}

          {dependants.map(
            (dependant, index) =>
              dependant.isValid && (
                <Box key={index}>
                  <Label>
                    Full name: {getPersonFullName(dependant.values)}
                  </Label>
                  <Label>
                    Relationship: {capitalize(dependant.values.relationship)}
                  </Label>
                  <Label mb={spacing}>
                    Date of birth: {formatDob(dependant)}
                  </Label>
                </Box>
              )
          )}
        </>
      )}
    </RebateApplicationSection>
  );
};

export default PolicyIndividuals;
