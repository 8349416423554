import React from 'react';
import { withTheme } from 'emotion-theming';
import { Box, Flex } from 'rebass';
import { excessFilter } from '~lib/filters';
import Icon from '~common/atoms/Icon';
import { Caption, P1, P3 } from '../../../../common/atoms/typography';
import { path } from 'lodash/fp';
import { currency } from '~lib/filters';
import css from '@emotion/css';
import styled from '@emotion/styled';
import { useMobileScreenOnly } from '~lib/responsive';

const frequencyMap = {
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  HALF_YEARLY: 'Half yearly',
  YEARLY: 'Yearly',
};

const HospitalExcess = styled(P3)`
  width: 100%;
  text-align: center;

  ${props => props.theme.mq.sm} {
    text-align: left;
  }
`;

const SummaryBox = ({ title, children, ...props }) => {
  return (
    <Box
      mb={{
        xs: 20,
        tabletP: 0,
      }}
      {...props}
    >
      <Caption
        color="neutral500"
        css={css`
          text-transform: uppercase;
        `}
        mb="4px"
        variant="medium"
      >
        {title}
      </Caption>
      {children}
    </Box>
  );
};

const SignIcon = ({ name, theme }) => {
  return (
    <Icon
      css={css`
        transform: translateY(25px);
      `}
      mx="32px"
      name={name}
      fill={theme.colors.primary}
      width="14px"
      height="14px"
    />
  );
};

const CoverSummary = ({ theme, plan, rate, extrasOnly, excess }) => {
  const isMobile = useMobileScreenOnly();
  return (
    <Flex
      flexDirection={{
        xs: 'column',
        tabletP: 'row',
      }}
      justifyContent={{
        xs: 'center',
        tabletP: 'flex-start',
      }}
    >
      {plan.type === 'BOTH' || plan.type === 'HOSPITAL_ONLY' ? (
        <SummaryBox title="Hospital Cover">
          <P1 inline variant="bold" color="hospital">
            {path('hospitalProduct.title')(plan)}
          </P1>{' '}
          <P1 color="hospital" inline variant="light">
            {path('hospitalProduct.coverage')(plan)}
          </P1>
          {!extrasOnly && (
            <HospitalExcess color="neutral500" variant="medium">
              {excess ? 'Hospital Excess ' : ''}
              {excessFilter(excess)}
            </HospitalExcess>
          )}
        </SummaryBox>
      ) : null}

      {plan.type === 'BOTH' && !isMobile ? (
        <SignIcon theme={theme} name="oms-plus" />
      ) : null}

      {plan.type === 'BOTH' || plan.type === 'EXTRAS_ONLY' ? (
        <SummaryBox title="Extras Cover">
          <P1 variant="bold" color="extras">
            {plan.extrasProduct?.title}
          </P1>
        </SummaryBox>
      ) : null}

      {plan.type === 'BOTH' && !isMobile ? (
        <SignIcon theme={theme} name="oms-equals" />
      ) : null}

      <SummaryBox
        title={`${frequencyMap[plan.paymentFrequency]} Premium`}
        mb={0}
      >
        <P1 variant="semiBold" color="primary">
          {currency(path('totals.net')(rate))}
        </P1>
      </SummaryBox>
    </Flex>
  );
};

export default withTheme(CoverSummary);
