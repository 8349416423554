import React, { useContext, useEffect } from 'react';
import Icon from '~common/atoms/Icon';
import { useThemeUI } from 'theme-ui';
import CenterBox from '~common/atoms/CenterBox';
import { StandardText } from '~common/atoms/typography';
import { Box } from 'rebass';
import CallUsNumber from '~common/molecules/CallUsNumber';
import useScrollTop from '~lib/hooks/useScrollTop';
import { ContactUsContext } from '~OMS/contact-us/ContactUsContext';

const Confirmation = () => {
  const { openRequestCallback } = useContext(ContactUsContext);
  const { theme } = useThemeUI();
  const [, scrollTop] = useScrollTop();

  useEffect(() => {
    scrollTop();
  }, []);
  return (
    <Box
      width={{
        xs: 1,
        tabletL: 567,
        lg: '80%',
      }}
    >
      <CenterBox height="auto" flexDirection="column">
        <Icon
          fill={theme.colors.green}
          stroke={theme.colors.green}
          name="circle-fill-tick"
          height="50px"
          width="50px"
        />

        <StandardText my={20} bold fontSize={2} as="h2" color="dark">
          Thanks for your application
        </StandardText>
      </CenterBox>

      <StandardText mb={16} fontSize={3}>
        We’re working on processing your new policy details and will send you a
        confirmation email in 1-2 business days. Once you receive this email,
        you’ll be able to view and manage your new Hospital + Extras policy
        online. If you have any questions in the meantime, don’t hesitate to
        contact our Member Services team on{' '}
        <CallUsNumber inline color={theme.colors.blue} /> or{' '}
        <bold
          style={{ color: theme.colors.secondary, cursor: 'pointer' }}
          onClick={openRequestCallback}
        >
          {' '}
          request a call back.
        </bold>
      </StandardText>
    </Box>
  );
};

export default Confirmation;
