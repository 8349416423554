import React from 'react';
import { Box, Flex } from 'rebass';
import { css } from '@emotion/core';
import { Element } from 'react-scroll';
import Icon from '~common/atoms/Icon';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { P2 } from '~common/atoms/typography';
import { useThemeUI } from 'theme-ui';

const HideIcon = ({ type, theme }) => (
  <Icon
    data-id={`close-inclusions-${type}`}
    name="chevron-up"
    fill={theme.colors.primary}
    width="16px"
    height="16px"
    style={{ marginLeft: '10px' }}
  />
);

const OpenIcon = ({ type, theme }) => (
  <Icon
    data-id={`open-inclusions-${type}`}
    name="chevron-down"
    fill={theme.colors.primary}
    width="16px"
    height="16px"
    style={{ marginLeft: '10px' }}
  />
);

const InclusionsToggler = ({ children, type, ...props }) => {
  const { theme } = useThemeUI();
  return (
    <div>
      <Element name="inclusions">
        <Flex
          flexDirection="column"
          bg="white"
          className={classnames({
            'hide-mobile': props.hideOnMobile,
          })}
        >
          <Box onClick={props.onInclusionsToggle}>
            <P2
              variant="medium"
              bg="blue50"
              color="primary"
              textAlign="center"
              css={
                !!props.isSticky && props.open
                  ? css`
                      position: fixed;
                      top: 0px;
                      width: ${props.inclusionWidth
                        ? `${props.inclusionWidth}px`
                        : 'auto'};
                      z-index: 20;
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                      cursor: pointer;
                      height: 50px;
                      line-height: 50px;
                    `
                  : css`
                      position: relative;
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                      cursor: pointer;
                      height: 50px;
                      line-height: 50px;
                    `
              }
            >
              {props.open ? 'Hide' : 'Show'} Inclusions
              {props.open && <HideIcon theme={theme} type={type} />}
              {!props.open && <OpenIcon theme={theme} type={type} />}
              {/*<div*/}
              {/*  style={{*/}
              {/*    position: 'absolute',*/}
              {/*    top: 'calc(50% - 9px)',*/}
              {/*    right: '20px',*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <InfoIcon position="top" content={<Legend />} />*/}
              {/*</div>*/}
            </P2>
          </Box>
          {props.open && children()}
        </Flex>
      </Element>
    </div>
  );
};

InclusionsToggler.defaultProps = {
  hideOnMobile: true,
};

InclusionsToggler.propTypes = {
  onInclusionsToggle: PropTypes.func.isRequired,
  open: PropTypes.bool,
  inclusionWidth: PropTypes.number,
  children: PropTypes.func.isRequired,
  isSticky: PropTypes.bool,
  hideOnMobile: PropTypes.bool,
};

export default InclusionsToggler;
