import React from 'react';
import FilterButton from '~OMS/claims/atoms/FilterButton';
import { Flex } from 'rebass';
import { SERVICE_TYPES } from '~OMS/claims/constants';
import { useMobileScreen } from '~lib/responsive';
import { DropDown, Option } from '~common/atoms/DropDown';
const filters = [
  {
    id: SERVICE_TYPES.ALL,
    label: 'All',
    mobileLabel: 'All claims',
  },
  {
    id: SERVICE_TYPES.HOSPITAL,
    label: 'Hospital',
  },
  {
    id: SERVICE_TYPES.MEDICAL,
    label: 'Medical',
  },
  {
    id: SERVICE_TYPES.EXTRAS,
    label: 'Extras',
  },
];

const ServiceTypeFilter = ({ selectedType, onChange, theme, ...props }) => {
  const isMobile = useMobileScreen();
  return isMobile ? (
    <DropDown
      data-id="service-type-filters"
      value={selectedType}
      onChange={onChange}
      {...props}
    >
      {filters.map(filter => (
        <Option key={filter.id} value={filter.id}>
          {filter.mobileLabel || filter.label}
        </Option>
      ))}
    </DropDown>
  ) : (
    <Flex
      data-id="service-type-filters"
      flexDirection="row"
      alignItems="center"
      {...props}
    >
      {filters.map((type, index) => (
        <FilterButton
          data-id={type.id}
          theme={theme}
          key={type.id}
          mr={index !== filters.length - 1 ? 2 : 0}
          active={selectedType === type.id}
          onClick={() => {
            onChange(type.id);
          }}
        >
          {type.label}
        </FilterButton>
      ))}
    </Flex>
  );
};

export default React.memo(ServiceTypeFilter);
