import React from 'react';
import Control, { backgroundColorStyle, errorStateStyle } from './Control';
import styled from '@emotion/styled';
import { DropDown, Option } from '../../atoms/DropDown';

const AdaptedDropDown = styled(DropDown)`
  padding: 3px 0;
  border-width: 1px;
  border-radius: 0;
  ${backgroundColorStyle};
  ${errorStateStyle};
`;

function DropDownControl({ children, options, ...props }) {
  return (
    <Control {...props}>
      {({ field, theme, invalid, ...props }) => (
        <AdaptedDropDown error={invalid} theme={theme} {...field} {...props}>
          {options
            ? options.map(option => (
                <Option
                  key={`${option.value}-${option.label}`}
                  value={option.value}
                >
                  {option.label}
                </Option>
              ))
            : children}
        </AdaptedDropDown>
      )}
    </Control>
  );
}

export default DropDownControl;
