import React from 'react';
import { H3 } from '~common/atoms/typography';

const StepTitle = ({ children, ...props }) => {
  return (
    <H3 variant="semiBold" mb={24} {...props}>
      {children}
    </H3>
  );
};

export default StepTitle;
