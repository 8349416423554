import React from 'react';
import { ApplicationFormProvider } from './state/ApplicationFormProvider';
import { css, Global } from '@emotion/core';
import ActiveStep from '~OMS/my-cover/change-cover/ApplicationForm/steps/ActiveStep';

const ApplicationFormContent = () => {
  return (
    <>
      <ActiveStep />
    </>
  );
};

const appFormGlobalStyles = css``;

const ApplicationForm = ({ location }) => {
  return (
    <ApplicationFormProvider location={location}>
      <Global styles={appFormGlobalStyles} />
      <ApplicationFormContent />
    </ApplicationFormProvider>
  );
};

export default ApplicationForm;
