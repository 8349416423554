import React, { createRef, useEffect, useMemo, useRef, useState } from 'react';
import { Flex, Box } from 'rebass';
import Icon from '../atoms/Icon';
import styled from '@emotion/styled';
import { useThemeUI } from 'theme-ui';
import CenterBox from '~common/atoms/CenterBox';
import { css } from '@emotion/core';
import useEventListener from '~lib/hooks/useEventListener';
import { path } from 'lodash/fp';

const ArrowIcon = ({ className, direction = 'left', show, onClick }) => {
  const { theme } = useThemeUI();
  return (
    <CenterBox
      onClick={onClick}
      className={className}
      height={60}
      width={60}
      css={css`
        background: rgba(255, 255, 255, 0.5);
      `}
    >
      <Icon
        clickable={show}
        fill={theme.colors.dark}
        width="32px"
        height="32px"
        name={direction === 'left' ? 'arrow-left' : 'arrow-right'}
      />
    </CenterBox>
  );
};

const Arrow = styled(ArrowIcon)`
  z-index: 2;
  cursor: pointer;
  position: absolute;
  top: 106px;
  transition: all 500ms ease-in-out;
  opacity: ${props => (props.show ? 1 : 0)};
  ${props =>
    props.show
      ? `
    opacity: 1;
  `
      : `
    cursor: inherit;
    opacity: 0;
    pointer-events: none;
  `}
  ${props => (props.direction === 'left' ? `left: 0` : `right: 0`)};
`;

const getBoundingClientRect = ref => {
  if (!ref || !ref.current || !ref.current.getBoundingClientRect) {
    return {};
  }

  return ref.current.getBoundingClientRect();
};

const getTargetCardIndex = (scrollPosition, cards) => {
  if (scrollPosition < 0) {
    return 0;
  }
  if (scrollPosition > cards.length - 1) {
    return cards.length - 1;
  }
  return scrollPosition;
};

const CardsContainer = styled(Flex)`
  transition: all 500ms cubic-bezier(0.43, 0.22, 0.24, 1.08);
  transform: translateX(${props => props.scrollingAmount}px);
`;

const Carousel = ({
  children,
  currentSlide,
  enableNavigationButtons = true,
  containerCss,
  ...props
}) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const cardsContainerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState();
  const cards = React.Children.toArray(children);

  const scroll = (direction = 'left') => {
    const newPosition = scrollPosition + (direction === 'left' ? -1 : 1);
    setScrollPosition(newPosition);
  };

  useEffect(() => {
    if (currentSlide != null) {
      setScrollPosition(currentSlide);
    }
  }, [currentSlide]);

  const cardRefs = useRef(cards.map(() => createRef()));

  const scrollAmount = useMemo(() => {
    if (!cardRefs || !cardRefs.current) {
      return 0;
    }

    const targetCardIndex = getTargetCardIndex(scrollPosition, cards);
    const targetCard = cardRefs.current[targetCardIndex];
    const initCard = cardRefs.current[0];

    if (targetCard && targetCard.current && initCard && initCard.current) {
      const { x: initX } = getBoundingClientRect(initCard);
      const { x: nextX } = getBoundingClientRect(targetCard);
      return initX - nextX;
    }

    return 0;
  }, [scrollPosition, cardRefs]);

  const onResizeChange = () => {
    setContainerWidth(path('current.clientWidth')(cardsContainerRef));
  };

  useEffect(onResizeChange, [cardsContainerRef.current?.clientWidth]);
  useEventListener('resize', onResizeChange);

  const showScroll = useMemo(() => {
    if (!containerWidth || !enableNavigationButtons) {
      return false;
    }

    const firstCard = cardRefs.current[0];
    const lastCard = cardRefs.current[cardRefs.current.length - 1];

    const { x: initX } = getBoundingClientRect(firstCard);
    const { x: lastX, width: lastWidth } = getBoundingClientRect(lastCard);

    return containerWidth + initX < lastX + lastWidth;
  }, [containerWidth, enableNavigationButtons]);

  return (
    <Box
      css={css`
        position: relative;
        overflow-x: hidden;
      `}
      {...props}
    >
      {enableNavigationButtons && (
        <Arrow
          show={showScroll && scrollPosition > 0}
          onClick={() => scroll('left')}
          direction="left"
        />
      )}

      <CardsContainer
        ref={cardsContainerRef}
        scrollingAmount={scrollAmount}
        css={containerCss}
      >
        {React.Children.map(children, (card, index) => {
          return React.cloneElement(card, {
            ref: cardRefs.current[index],
          });
        })}
      </CardsContainer>
      {enableNavigationButtons && (
        <Arrow
          show={showScroll && scrollPosition < cards.length - 1}
          onClick={() => scroll('right')}
          direction="right"
        />
      )}
    </Box>
  );
};

export default Carousel;
