import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';
import useToggle from '~lib/hooks/useToggle';
import Link from '../../Link';
import { useThemeUI } from 'theme-ui';
import Icon from '~common/atoms/Icon';

const StyledIcon = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 16px 0;
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledMobileNavItem = styled(Flex)`
  width: 100%;
  flex-direction: column;

  &:not(:first-of-type) {
    ${StyledFirstLevelItemWrapper} {
      border-top: 1px solid ${props => props.theme.colors.neutral50};
    }
  }
`;

const StyledFirstLevelItemWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: stretch;
`;

const StyledSecondLevelItemWrapper = styled(Flex)`
  flex-direction: column;
  border-top: 1px solid ${props => props.theme.colors.neutral50};
`;

const StyledThirdLevelItemWrapper = styled(Flex)`
  flex-direction: column;
`;

const StyledFirstLevelItem = styled(StyledLink)`
  font-size: 18px;
  line-height: 21.5px;
  font-weight: 600;
  padding: 16px 0;
  width: calc(100% - 49px);
`;

const StyledSecondLevelItem = styled(StyledLink)`
  font-size: 16px;
  line-height: 22.5px;
  font-weight: 600;
  padding: 16px 16px 16px 32px;
  width: 100%;
`;

const StyledThirdLevelItem = styled(StyledLink)`
  font-size: 16px;
  line-height: 22.5px;
  font-weight: 500;
  padding: 16px 16px 16px 32px;
  width: 100%;
`;

const ExpandIcon = ({ expanded, toggle }) => {
  const { theme } = useThemeUI();

  return (
    <StyledIcon onClick={() => toggle()}>
      <Icon
        name={expanded ? 'chevron-up-thin' : 'chevron-down-thin'}
        fill={theme.colors.text}
        stroke={theme.colors.text}
        width="15px"
        sx={{ pointerEvents: 'none' }}
      />
    </StyledIcon>
  );
};

function MobileNavItem({ item }) {
  const [expanded, toggle] = useToggle(false);

  return (
    <StyledMobileNavItem>
      <StyledFirstLevelItemWrapper>
        <StyledFirstLevelItem data={item.link}>
          {item.title}
        </StyledFirstLevelItem>
        {item.items?.length ? (
          <ExpandIcon expanded={expanded} toggle={toggle} />
        ) : null}
      </StyledFirstLevelItemWrapper>

      {expanded && (
        <StyledSecondLevelItemWrapper>
          {item.items?.map((secondItem, i) => (
            <Fragment key={`${secondItem.title}-${i}`}>
              <StyledSecondLevelItem data={secondItem.link}>
                {secondItem.title}
              </StyledSecondLevelItem>

              <StyledThirdLevelItemWrapper>
                {secondItem.items?.map((thirdItem, i) => (
                  <StyledThirdLevelItem
                    key={`${thirdItem.title}-${i}`}
                    data={thirdItem.link}
                  >
                    {thirdItem.title}
                  </StyledThirdLevelItem>
                ))}
              </StyledThirdLevelItemWrapper>
            </Fragment>
          ))}
        </StyledSecondLevelItemWrapper>
      )}
    </StyledMobileNavItem>
  );
}

export default MobileNavItem;
