export const persons = [
  {
    id: 1,
    firstName: 'Tara',
  },
  {
    id: 2,
    firstName: 'Mark',
  },
  {
    id: 3,
    firstName: 'Luna',
  },
  {
    id: 4,
    firstName: 'Bodhi',
  },
];

export const addressName = '2a College Street, GLADESVILLE, NSW 2111';

export const days = '3 - 5';
