import { useMemo } from 'react';
import useAppFormAdditionalInformation from '~lib/hooks/sanity/useAppFormAdditionalInformation';

export default () => {
  const { partnerOrganisations } = useAppFormAdditionalInformation();

  const options = useMemo(() => {
    return partnerOrganisations.map(elem => ({
      value: elem.id,
      label: elem.title,
    }));
  }, [partnerOrganisations]);

  return options;
};
