import React from 'react';
import { Flex } from 'rebass';
import { css } from '@emotion/core';
import { useMobileScreen } from '~lib/responsive';
import { useThemeUI } from 'theme-ui';

const getArrowPosition = (position, isMobile) => {
  if (isMobile) {
    return '50%';
  }
  if (['left', 'right'].includes(position)) {
    return `calc(50% ${position === 'right' ? ' + 25%' : ' - 25%'})`;
  }

  return position;
};

const QuoteAlert = ({ children, alertPosition = 'right', ...props }) => {
  const isMobile = useMobileScreen();
  const { theme } = useThemeUI();

  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start"
      mt={3}
      px={{ xs: '8px', md: '20px' }}
      py="20px"
      css={css`
        position: relative;
        top: 10px;
        box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.3);
        background-color: ${theme.colors.background.light2};
        border-top: 2px solid ${theme.colors.primary};
        width: 100%;

        &::before {
          content: '';
          position: absolute;
          top: -15px;
          left: ${getArrowPosition(alertPosition, isMobile)};
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 15px solid ${theme.colors.primary};
        }
      `}
      {...props}
    >
      {children}
    </Flex>
  );
};

export default QuoteAlert;
