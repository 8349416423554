import React, { useContext } from 'react';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import UserNavbar from '../molecules/UserNavbar';
import Burger from '../atoms/Burger';
import Icon from '~common/atoms/Icon';
import Link from '~common/atoms/Link';
import { AuthContext } from 'context/sso';
import useFeatureToggle from '~lib/hooks/useFeatureToggle';
import TimedLogout from '~OMS/SSO/molecules/TimedLogout';
import Logo from '~common/atoms/Logo';
import ByBrand from '~common/atoms/ByBrand';
import { Box } from 'rebass';

const Container = styled(Box)`
  color: ${props => props.colors.white};
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  background: #fff;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  z-index: ${props => props.zIndex.navMobile};
  border-bottom: 1px solid ${props => props.colors.borders};
  ${props => props.mq.sm} {
    padding-left: 20px;
    padding-bottom: 0;
    padding-top: 0;
    z-index: ${props => props.zIndex.nav};
  }
`;

const StyledLogo = styled(Logo)`
  position: absolute;
  left: 45%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  ${props => props.theme.mq.sm} {
    display: none;
  }
`;

const Home = styled(Link)`
  width: 46px;
  height: 46px;
  position: absolute;
  right: 5px;
  padding: 10px;
  display: block;
  ${props => props.theme.mq.sm} {
    display: none;
  }
`;

const Tabs = styled.div`
  height: 100%;
  padding-top: 9px;
  display: none;
  position: relative;
  z-index: ${props => props.zIndex.navTabs};
  ${props => props.mq.sm} {
    display: flex;
  }
`;

const DhfTab = styled(Link)`
  z-index: 99;
  transform: translateY(1px);
  .cls-1 {
    fill: ${props => props.theme.colors.background.base};
    stroke: ${props => props.theme.colors.background.base};
  }
  .cls-4 {
    fill: ${props => props.theme.colors.primary};
  }
  .dhf-text {
    transform: translate(51px, 11px);
  }
`;

const AvantTab = styled.a`
  position: relative;
  z-index: 1;
  right: 20px;
  .cls-1 {
    stroke: ${props => props.theme.colors.background.base};
  }
`;

const Navbar = props => {
  const theme = props.theme;
  const { avant } = useContext(AuthContext);
  const { sso } = useFeatureToggle();

  return (
    <Container
      height={{
        xs: 60,
        tabletP: 'auto',
      }}
      {...theme}
    >
      <TimedLogout />
      <Burger />
      <ByBrand
        dhf={() => (
          <Tabs {...theme}>
            <DhfTab to="/" src="dhf">
              <Icon
                originalColorSchema
                height="46px"
                clickable={true}
                name="dhf-tab"
              />
            </DhfTab>
            {sso && (
              <AvantTab href="#" src="avant" onClick={avant.actions.goToAvant}>
                <Icon
                  originalColorSchema
                  height="45px"
                  clickable={true}
                  name="avant-tab"
                />
              </AvantTab>
            )}
          </Tabs>
        )}
      />
      <UserNavbar location={props.location} />
      <StyledLogo height={35} />
      <Home to="/oms/">
        <Icon
          name="oms-home"
          clickable={true}
          fill={theme.colors.primary}
          width="33px"
          height="27px"
        />
      </Home>
    </Container>
  );
};

export default withTheme(Navbar);
