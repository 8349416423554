import React from 'react';
import { Box, Flex } from 'rebass';
import styled from '@emotion/styled';
import Icon from '~common/atoms/Icon';
import useForm from '~lib/hooks/useForm';
import { hasPartner, isEfcEligible } from '~lib/util';
import { Form } from '~common/molecules/Form';
import FormField from '~common/molecules/FormField';
import { auStates, quoteStatuses, tiers } from '~lib/constants';
import { DropDownControl } from '~common/molecules/form-controls';
import { CheckEfcControl } from '../../../ApplicationForm/QuoteSummary/CheckEfcControl';
import { Button } from '~common/atoms/Button';
import DateOfBirth from '~common/molecules/DateOfBirth';
import { FormValidator, required } from '~lib/formValidation';
import { makeValidation } from '../../QuoteFormNew/steps/DateOfBirth/useDateOfBirth';
import { H2 } from '~common/atoms/typography';

const ModalContainer = styled(Box)``;

const FullWidthButton = styled(Button)`
  width: 100%;
  font-size: 16px;
  * {
    line-height: 1.3;
  }
`;

const ConfirmButton = styled(FullWidthButton)`
  margin-bottom: 16px;
`;

//TODO: remove the style below ones rebranding kicks in
const StyledDropDownControl = styled(DropDownControl)`
  &&& svg path {
    fill: ${props => props.theme.colors.dark};
    stroke: ${props => props.theme.colors.dark};
  }

  & select {
    background-color: #fff;
    color: #070707 !important;
  }
`;

const hasPartnerSet = form => hasPartner(form.status);

const EditDetailsModal = ({ initialForm, onConfirm, onCancel, ...props }) => {
  const form = useForm(initialForm, {
    validate: FormValidator({
      validation: {
        region: required('State is required field'),
        status: required('Cover type is required field'),
        tier: required('Annual income is required field'),
        ...makeValidation('dob'),
        ...makeValidation('partner'),
      },
      transformValidation: {
        // validate partner dob only if partner is eligible
        partnerDay: hasPartnerSet,
        partnerMonth: hasPartnerSet,
        partnerYear: hasPartnerSet,
      },
    }),
    onChange: {
      status(value) {
        if (!isEfcEligible(value) && form.values.efc) {
          return {
            efc: false,
          };
        }

        return undefined;
      },
    },
  });
  return (
    <ModalContainer data-id="edit-details-container" {...props}>
      <Flex mb={20} alignItems="center" justifyContent="space-between">
        <H2>Edit details</H2>
        <Icon
          data-id="close-edit-details-modal"
          name="closeX"
          height="24px"
          width="24px"
          originalColorSchema
          clickable
          onClick={onCancel}
        />
      </Flex>

      <Form
        form={form}
        onSubmit={values => {
          onConfirm(values);
        }}
      >
        {({ handleChange, handleSubmit, values }) => (
          <>
            <FormField
              onChange={handleChange}
              name="status"
              label="I'm"
              component={StyledDropDownControl}
              options={quoteStatuses}
            />

            <DateOfBirth
              Field={FormField}
              label="My date of birth is"
              dayField={{
                name: 'dobDay',
                onChange: handleChange,
                label: null,
              }}
              monthField={{
                name: 'dobMonth',
                onChange: handleChange,
                label: null,
              }}
              yearField={{
                name: 'dobYear',
                onChange: handleChange,
                label: null,
              }}
            />

            {hasPartner(values.status) && (
              <DateOfBirth
                Field={FormField}
                label="My partner's date of birth is"
                dayField={{
                  name: 'partnerDay',
                  onChange: handleChange,
                  label: null,
                }}
                monthField={{
                  name: 'partnerMonth',
                  onChange: handleChange,
                  label: null,
                }}
                yearField={{
                  name: 'partnerYear',
                  onChange: handleChange,
                  label: null,
                }}
              />
            )}

            <FormField
              onChange={handleChange}
              name="region"
              label={hasPartner(values.status) ? 'We live in' : 'I live in'}
              component={StyledDropDownControl}
              options={auStates}
            />
            <FormField
              onChange={handleChange}
              name="tier"
              label={
                hasPartner(values.status)
                  ? 'Our annual income is'
                  : 'My annual income is'
              }
              component={StyledDropDownControl}
              options={tiers[values.status]}
            />

            {isEfcEligible(values.status) && (
              <FormField
                name="efc"
                onChange={handleChange}
                component={CheckEfcControl}
                labelProps={{
                  fontSize: 16,
                }}
                infoIconProps={{
                  height: '18px',
                  width: '18px',
                }}
              />
            )}

            <ConfirmButton
              data-id="update-details-btn"
              onClick={handleSubmit}
              type="submit"
              text="Update details"
              variant="primary"
            >
              Update details
            </ConfirmButton>

            <FullWidthButton
              data-id="cancel-btn"
              text="Cancel"
              onClick={onCancel}
              variant="primaryInverted"
            >
              Cancel
            </FullWidthButton>
          </>
        )}
      </Form>
    </ModalContainer>
  );
};

export default EditDetailsModal;
