import { useState, useMemo, useEffect, useCallback } from 'react';
import useToggle from '~lib/hooks/useToggle';
import { whereEq, path } from 'lodash/fp';
import gql from 'graphql-tag.macro';
import { useQuery } from '@apollo/client';
import { pathOr } from '~lib/util';
import useQuickQuoteSettingsQuery from '~lib/hooks/sanity/useQuickQuoteSettingsQuery';
import { get, invert } from 'lodash';
import { useQuickQuote } from '~lib/CoverProducts/useQuote';
import { PRODUCT_TYPE } from '~lib/constants';
import { useMembershipRates } from '~lib/CoverProducts/useRates';
import defaultProductSelection from '~lib/CoverProducts/useCoverProducts/defaultProductSelection';

const getProductName = type => `${type}Product`;
const getOppositeProductName = type =>
  type === PRODUCT_TYPE.HOSPITAL ? `extrasProduct` : 'hospitalProduct';

const FULL_QUICK_QUOTE_SETTINGS_QUERY = gql`
  query QuickQuoteSettingsQuery {
    quickQuoteSettingsProducts {
      hospital {
        content {
          ID
          bannerImage
          body
          coverage
          description
          factSheet
          faq
          gapCoverage
          inclusions
          resultType
          slug
          sortOrder
          teaserImage
          title
          type
          variants
          summaryPoints
        }
      }
      extras {
        content {
          ID
          bannerImage
          body
          coverage
          description
          extrasUsageDisclaimer
          factSheet
          faq
          inclusions
          links
          resultType
          slug
          sortOrder
          teaserImage
          title
          type
          variants
          summaryPoints
        }
      }

      defaults {
        hospital {
          content {
            ID
            bannerImage
            body
            coverage
            description
            factSheet
            faq
            gapCoverage
            inclusions
            resultType
            slug
            sortOrder
            teaserImage
            title
            type
            variants
            summaryPoints
          }
        }
        extras {
          content {
            ID
            bannerImage
            body
            coverage
            description
            extrasUsageDisclaimer
            factSheet
            faq
            inclusions
            links
            resultType
            slug
            sortOrder
            teaserImage
            title
            type
            variants
            summaryPoints
          }
        }
      }
    }
  }
`;

//WHAT'S THIS FOR???
const validateProductClasses = (product, membershipClass) => {
  return product.variants.some(v => {
    if (v.classes && v.classes.length) {
      return v.classes.includes(membershipClass);
    }
    return true;
  });
};

const mapVariant = (type, rates, selectedOppositeProduct) => variant => {
  const rate = rates.find(
    rate =>
      rate[getProductName(type)] === variant?.code &&
      (selectedOppositeProduct
        ? rate[getOppositeProductName(type)] === selectedOppositeProduct
        : true)
  );

  return (
    rate && {
      ...variant,
      rate,
      price: {
        net: rate.prices[type],
        original: type === 'extras' ? rate.prices.originalExtras : null,
      },
    }
  );
};

const getProducts = (
  products,
  status,
  type,
  rates,
  disableValidation = false,
  selectedOppositeProduct
) => {
  return (
    products
      ?.filter(
        ({ content: product }) =>
          disableValidation ||
          (validateProductClasses(product, status) && product.ID)
      )
      ?.map(({ content: product }) => ({
        ...product,
        id: product.slug.current,
        allVariants: product.variants,
        variants: product.variants
          .map(mapVariant(type, rates, selectedOppositeProduct))
          .filter(Boolean),
      })) || []
  );
};

const validateProductRequirements = (
  product,
  selectedVariantCodeForOtherProductType
) => {
  return product.allVariants.every(variant => {
    if (variant.requiredProducts && variant.requiredProducts.length) {
      return variant.requiredProducts.includes(
        selectedVariantCodeForOtherProductType
      );
    }
    return true;
  });
};

const emptyCover = {
  region: {
    value: '',
  },
  status: {
    value: '',
  },
  dob: {
    value: '',
  },
  tier: {
    value: '',
  },
};

const rateTypeMap = {
  hospital: 'HOSPITAL_ONLY',
  extras: 'EXTRAS_ONLY',
  both: 'BOTH',
};

const getRatesHook = returnMembershipRates =>
  returnMembershipRates ? useMembershipRates : useQuickQuote;

const productTypeFromRateType = rateType => invert(rateTypeMap)[rateType];

const inferProductType = cover => {
  const productType = productTypeFromRateType(cover?.rateType);
  return (
    productType ||
    (() => {
      if (cover.hospitalProduct && cover.extrasProduct) {
        return 'both';
      }

      if (cover.extrasProduct) {
        return 'extras';
      }

      if (cover.hospitalProduct) {
        return 'hospital';
      }

      return 'both';
    })()
  );
};

export const useCoverProducts = (
  cover = emptyCover,
  {
    returnMembershipRates = false,
    onProductChange,
    disableProductValidation = false,
    getDefaultProducts,
    onProductTypeChange,
  } = {}
) => {
  const rateType = cover.rateType;
  const productType = useMemo(() => inferProductType(cover), [cover]);

  const useRatesHook = getRatesHook(returnMembershipRates);
  const ratesData = useRatesHook(cover, { rateType });
  const { data: quote, loading: quoteLoading, error: quoteError } = ratesData;

  const [activeTab, setActiveTab] = useState('hospital');
  const [hospitalInclisionsOpen, toggleHospitalInclusions] = useToggle(false);
  const [extrasInclusionsOpen, toggleExtrasInclusions] = useToggle(false);
  /**
   * useQuickQuoteSettingsQuery() uses getStaticQuery which adds the result to
   * every page in props.pageResources.staticQueryResults and the dom via a script tag.
   * useQuickQuoteSettingsQuery returns only the required for this hook to load.
   * the full query returns a payload that is over 11mb in size and should only be queried
   * when this hook is actually used
   */
  const [quickQuoteSettings, setQuickQuoteSettings] = useState(
    useQuickQuoteSettingsQuery()
  );
  const { data: qqFullData } = useQuery(FULL_QUICK_QUOTE_SETTINGS_QUERY);

  useEffect(() => {
    if (qqFullData?.quickQuoteSettingsProducts) {
      setQuickQuoteSettings(qqFullData.quickQuoteSettingsProducts);
    }
  }, [qqFullData]);

  getDefaultProducts = useMemo(
    () =>
      getDefaultProducts ||
      (() => {
        const defaultGenerators = defaultProductSelection(quickQuoteSettings);

        return defaultGenerators(cover.status?.value)(cover.dob);
      }),
    [cover.status?.value, cover.dob, getDefaultProducts, quickQuoteSettings]
  );

  const getDefaultProduct = useCallback(
    (products, type) => getDefaultProducts(cover)[type],
    [cover, getDefaultProducts]
  );

  const selectedProduct = useMemo(() => {
    return {
      hospital: cover.hospitalProduct || '',
      extras: cover.extrasProduct || '',
    };
  }, [cover.extrasProduct, cover.hospitalProduct]);

  const setProductType = type => {
    onProductTypeChange(type, rateTypeMap[type]);
  };

  const setSelected = useCallback(
    (type, code) => {
      onProductChange(type, code);
    },
    [onProductChange]
  );

  const products = useMemo(() => {
    if (!quote) {
      return {
        hospital: [],
        extras: [],
      };
    }

    const hospitalProducts = getProducts(
      quickQuoteSettings.hospital,
      cover.status?.value,
      'hospital',
      quote.rates,
      disableProductValidation,
      null
    );

    const extrasProducts = getProducts(
      quickQuoteSettings.extras,
      cover.status?.value,
      'extras',
      quote.rates,
      disableProductValidation,
      get(selectedProduct, 'hospital')
    );

    return {
      hospital: [...hospitalProducts],
      extras: [...extrasProducts],
    };
  }, [
    quote,
    quickQuoteSettings.hospital,
    quickQuoteSettings.extras,
    cover.status?.value,
    disableProductValidation,
    selectedProduct,
  ]);

  const validProducts = useMemo(() => {
    return {
      hospital: (products.hospital || []).map(product => ({
        ...product,
        isValid:
          !selectedProduct.extras ||
          validateProductRequirements(product, selectedProduct.extras),
      })),
      extras: (products.extras || []).map(product => ({
        ...product,
        isValid: validateProductRequirements(
          product,
          selectedProduct?.hospital
        ),
      })),
    };
  }, [
    products.extras,
    products.hospital,
    selectedProduct.extras,
    selectedProduct.hospital,
  ]);

  const setDefaultProduct = useCallback(
    type => {
      const defaultVariantCode = getDefaultProduct(validProducts[type], type);

      const isAvailable = !!validProducts[type]?.find(
        product =>
          product.isValid &&
          product.allVariants.some(
            whereEq({
              code: defaultVariantCode,
            })
          )
      );

      // if the default desired code is not available for any reason, pick the first valid one
      if (!isAvailable && validProducts[type]?.length) {
        const firstAvailableCode = validProducts?.[type]?.find(
          product => product.isValid && product.allVariants?.length
        )?.allVariants?.[0]?.code;
        setSelected(type, firstAvailableCode);
        return;
      }

      if (defaultVariantCode && isAvailable) {
        setSelected(type, defaultVariantCode);
      }
    },
    [getDefaultProduct, setSelected, validProducts]
  );

  const selections = useMemo(() => {
    const result = {
      hospital: {},
      extras: {},
    };

    return Object.keys(result).reduce((acc, productType) => {
      let product;
      let variant;
      if (selectedProduct[productType]) {
        product = products[productType].find(product =>
          product.variants.some(v => v.code === selectedProduct[productType])
        );
        if (product) {
          variant = product.variants.find(
            v => v.code === selectedProduct[productType]
          );
        }
      }

      acc[productType] = {
        product,
        variant,
      };

      return acc;
    }, result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedProduct,
    selectedProduct.extras,
    selectedProduct.hospital,
    products.extras,
    products.hospital,
  ]);

  const selectedRate = useMemo(() => {
    if (!quote) {
      return undefined;
    }

    const targetedProductType = ['both', 'hospital'].includes(productType)
      ? 'hospital'
      : 'extras';

    const mappedVariant = mapVariant(
      targetedProductType,
      quote?.rates,
      path(
        `${
          targetedProductType === 'extras' ? 'hospital' : 'extras'
        }.variant.code`
      )(selections)
    )(path(`${targetedProductType}.variant`)(selections));
    return mappedVariant?.rate;
  }, [productType, quote, selections, products]);

  const prices = useMemo(() => {
    return {
      hospital: {
        net: selectedRate?.prices?.hospital,
        original: null,
      },
      extras: {
        net: selectedRate?.prices?.extras,
        original: selectedRate?.prices?.originalExtras,
      },
      total: selectedRate?.prices?.total,
    };
  }, [selectedRate, products]);

  useEffect(() => {
    if (!products.extras?.length) {
      return;
    }
    const currentSelectedExtrasAvailable = products.extras.some(product =>
      product?.variants.find(variant => variant.code === selectedProduct.extras)
    );
    if (selectedProduct.hospital && !currentSelectedExtrasAvailable) {
      setSelected('extras', path('extras[0].variants[0].code')(products));
    }
  }, [
    selectedProduct.hospital,
    products.extras,
    products,
    selectedProduct.extras,
    setSelected,
  ]);

  useEffect(() => {
    ['extras', 'hospital'].forEach(type => {
      const coverProduct = cover[getProductName(type)];
      if (coverProduct && selectedProduct[type] !== coverProduct) {
        setSelected(type, cover[getProductName(type)]);
      } else if (
        !selectedProduct[type] &&
        (productType === type || productType === 'both')
      ) {
        setDefaultProduct(type);
      }
    });
  }, [
    cover.extrasProduct,
    productType,
    cover.hospitalProduct,
    validProducts,
    cover,
    selectedProduct,
    setSelected,
    setDefaultProduct,
  ]);

  useEffect(() => {
    ['hospital', 'extras'].forEach(type => {
      if (!selectedProduct[type]) {
        return;
      }

      if (!validProducts[type] || !validProducts[type].length) {
        return;
      }

      const selectedProductIsAvailable = !!validProducts[type].find(
        product =>
          product.isValid &&
          product.allVariants.some(
            whereEq({
              code: selectedProduct[type],
            })
          )
      );

      if (!selectedProductIsAvailable) {
        setDefaultProduct(type);
      }
    });
  }, [
    cover.status?.value,
    cover.dob?.age,
    validProducts,
    selectedProduct,
    setDefaultProduct,
  ]);

  return {
    quote: {
      error: quoteError,
      loading: quoteLoading,
      data: quote,
    },
    setDefaultProduct,
    productType,
    setProductType,
    activeTab,
    setActiveTab,
    hospitalInclisionsOpen,
    toggleHospitalInclusions,
    extrasInclusionsOpen,
    toggleExtrasInclusions,
    selectedProduct,
    setSelected,
    selections,
    products: validProducts,
    isExtrasVisible: productType !== 'hospital',
    isHospitalVisible: productType !== 'extras',
    excess: pathOr(null, 'hospital.variant.excess')(selections),
    selectedRate,
    prices,
  };
};
