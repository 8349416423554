import React from 'react';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import { Box } from 'rebass';

const Container = styled(Box)`
  width: 100%;
  background: #fff;
  border: 1px solid ${props => props.colors.borders};
  height: ${props => props.height};
  border-radius: 6px;
  padding: 30px;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
`;

Container.defaultProps = {
  height: '100%',
};

const Card = ({ theme, children, ...props }) => {
  return (
    <Container {...theme} {...props}>
      {children}
    </Container>
  );
};

export default withTheme(Card);
