import React, { useCallback } from 'react';
import { currency } from '~lib/filters';
import { get } from 'lodash';
import { BasicPaymentDetails } from 'queries/oms/payments.graphql';
import { useQuery } from '@apollo/client';

const PaymentDescriptionPartialSubsidy = ({ savingAmount }) => {
  const { data } = useQuery(BasicPaymentDetails);
  const { frequency } = get(data, 'oms.payments') || {};

  const maybePaymentFrequency = useCallback(
    () => (frequency ? frequency.toLowerCase().replace('_', ' ') : 'yearly'),
    [frequency]
  );

  return (
    <>
      The cost of your Extras cover is partially subsidised by Avant. You're
      saving&nbsp;<strong>{currency(savingAmount)}</strong>&nbsp;on your{' '}
      {maybePaymentFrequency(frequency)}&nbsp;premium.
    </>
  );
};

export default PaymentDescriptionPartialSubsidy;
