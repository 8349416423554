import React from 'react';
import { Box, Flex, Text } from 'rebass';
import Popover from 'components/Elements/Popover';
import Icon from '~common/atoms/Icon';
import { useThemeUI } from 'theme-ui';
import useSmoothScrollTop from '~lib/hooks/useSmoothScrollTop';
import { StandardText } from '~common/atoms/typography';

export default ({ hide, title, subtitle, help, children, name, ...props }) => {
  const { theme } = useThemeUI();
  useSmoothScrollTop();
  return (
    <Flex
      flexDirection="column"
      pt={{ xs: '50px', tabletP: '168px' }}
      pb={{ xs: 144, md: '150px' }}
      {...props}
    >
      <Flex
        flexDirection="row"
        justifyItems="flex-start"
        alignContent="flex-start"
      >
        {title && (
          <StandardText
            fontWeight="600"
            color="text.dark"
            fontSize={4}
            lineHeight="40px"
            mb="16px"
          >
            {title}
          </StandardText>
        )}
        {help ? (
          <Popover position="top" content={help}>
            <Icon
              name="help-circled"
              width="20px"
              height="20px"
              fill={theme.colors.secondary}
              style={{ marginLeft: '10px' }}
            />
          </Popover>
        ) : null}
      </Flex>
      {subtitle && (
        <Text fontSize={3} lineHeight="19px" color="text.neutral400" mb={24}>
          {subtitle}
        </Text>
      )}
      <Box maxWidth={{ tabletP: 476 }}>{children}</Box>
    </Flex>
  );
};
