import React from 'react';
import { currency, paymentFrequency } from '~lib/filters';
import { useQuery } from '@apollo/client';
import { BasicPaymentDetails } from 'queries/oms/payments.graphql';
import { get } from 'lodash';
import useMembership from '../hooks/useMembership';
import S2IMembershipPaymentMessage from './S2IMemebershipPaymentMessage';
import { Box, Flex } from 'rebass';
import { P2 } from '~common/atoms/typography';
import { formatDate } from '~lib/dates';
import { path } from 'lodash/fp';

const PaymentDescriptionRow = ({ label, value, ...props }) => {
  return (
    <Flex mb="15px" {...props}>
      <P2
        width={{
          xs: 1 / 2,
          tabletP: 234,
        }}
        color="neutral900"
        variant="medium"
      >
        {label}
      </P2>
      <P2
        width={{
          xs: 1 / 2,
          tabletP: 203,
        }}
        variant="medium"
        color="neutral500"
      >
        {value}
      </P2>
    </Flex>
  );
};

const PaymentDescription = () => {
  const { data } = useQuery(BasicPaymentDetails);
  const { isS2IMembership } = useMembership();
  const payments = get(data, 'oms.payments');
  if (isS2IMembership) {
    return <S2IMembershipPaymentMessage />;
  }

  if (!payments) {
    return null;
  }
  const { amount, frequency } = payments;

  return (
    <Box>
      <PaymentDescriptionRow
        label={`${paymentFrequency(frequency)} payment`}
        value={currency(amount)}
      />
      <PaymentDescriptionRow
        mb={0}
        label="Next payment"
        value={formatDate(
          path('oms.payments.paidToDate')(data) || path('paidToDate')(payments)
        )}
      />
    </Box>
  );
};

export default PaymentDescription;
