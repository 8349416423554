import React, { useContext, useEffect } from 'react';
import { withTheme } from 'emotion-theming';
import * as Yup from 'yup';
import { Box } from 'rebass';
import { Formik, Field } from 'formik';
import { get } from 'lodash';
import { noop } from '~lib/util';
import AlertBox from '~common/molecules/AlertBox';
import {
  InputControl,
  PasswordInputControl,
  validators,
} from '~common/molecules/form-controls';
import { Button } from '~common/atoms/Button';
import { AuthContext } from 'context/sso';

const validation = Yup.object().shape({
  email: validators.email(),
  mobile: validators.mobile().required('Mobile is a required field'),
  password: validators.password().required('Password is a required field'),
  passwordMatch: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords don't match")
    .required('Confirm Password is required'),
});

const VerificationStep2Form = ({ auth }) => {
  const authContext = useContext(AuthContext);
  console.log(auth);
  const { createLogin, error, loading } = authContext;

  let setFormValues = noop;
  let formValues;

  useEffect(() => {
    if (!auth) {
      return;
    }

    // set form fields and add username, pw for auth
    setFormValues({
      ...formValues,
      day: auth.day, // passed data from prev steps, except password
      month: auth.month,
      year: auth.year,
      verification: auth.verification,
      username: auth.username,
      oldPassword: auth.password, // don't mix up with next pw
      email: auth.email,
    });
    // eslint-disable-next-line
  }, [auth]);

  const initialForm = {
    email: '',
    mobile: '',
    password: '',
    passwordMatch: '',
    // avant_member: '',
  };

  // const options = [
  //   {
  //     id: '1',
  //     text: 'Yes',
  //   },
  //   {
  //     id: '0',
  //     text: 'No',
  //   },
  // ];

  return (
    <Formik
      validationSchema={validation}
      initialValues={initialForm}
      onSubmit={createLogin}
    >
      {({ handleSubmit, setValues, values }) => {
        setFormValues = setValues;
        formValues = values;

        return (
          <form onSubmit={handleSubmit}>
            <Field
              component={InputControl}
              placeholder="Please enter your email"
              label="New Username"
              name="email"
              id="email"
            />
            <Field
              component={InputControl}
              placeholder="Please enter your mobile number"
              label="Mobile Number"
              name="mobile"
              id="mobile"
            />

            <Box css={{ position: 'relative' }}>
              <Field
                component={PasswordInputControl}
                name="password"
                id="password"
                label="Create password"
                validateStrength
              />
            </Box>

            <Field
              component={PasswordInputControl}
              name="passwordMatch"
              id="passwordMatch"
              label="Re-enter password"
            />

            {/* not ready foe launch */}
            {/* <Field
              labelText="Are you also an Avant Mutual member?"
              component={ButtonSwitchControl}
              name="avant_member"
              if="avant_member"
              buttons={options}
            /> */}

            {error && <AlertBox error={get(error, 'message')} />}

            <Box width={1}>
              <Button
                loading={loading}
                variant="orange"
                css={{ width: '100%' }}
                type="submit"
              >
                Continue
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default withTheme(VerificationStep2Form);
