import styled from '@emotion/styled';
import { Flex } from 'rebass';
import { withTheme } from 'emotion-theming';

let Row = styled(Flex)`
  width: 100%;
  ${props => props.theme.mq.md} {
    width: 625px;
  }
`;

Row = withTheme(Row);

export { Row };
