import styled from '@emotion/styled';
import { useMobileScreen } from '~lib/responsive';
import {
  claimColumns,
  mobileClaimColumns,
} from '~OMS/claims/claimHistoryColumns';
import { Box, Flex } from 'rebass';
import { StandardText } from '../../../../common/atoms/typography';
import { columnValue } from '~OMS/claims/utils';
import React from 'react';
import Icon from '~common/atoms/Icon';
import ClaimsHistoryRow from '~OMS/claims/molecules/atoms/ClaimsHistoryRow';
import { useThemeUI } from 'theme-ui';
import CenterBox from '~common/atoms/CenterBox';
import { css } from '@emotion/core';
import { path } from 'lodash/fp';
import { CLAIM_STATUS } from '../constants';

const StyledClaimInfo = styled(ClaimsHistoryRow)`
  border-bottom: 2px solid ${props => props.theme.colors.iceBlue};
`;

const PlusIcon = ({ theme }) => (
  <CenterBox
    css={css`
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background: ${theme.colors.secondary};
      transform: translateY(-1px);
    `}
  >
    <Icon
      clickable
      name="oms-plus"
      fill={theme.colors.white}
      width="11px"
      height="11px"
    />
  </CenterBox>
);

const MinusIcon = styled(Icon)`
  transform: translateY(-1px);
  circle {
    fill: ${props => props.theme.colors.secondary};
  }
`;

const ClickableArea = styled(Flex)`
  cursor: pointer;
`;

const ClaimInfo = ({ claim, onToggleDetails, detailsShown }) => {
  const isMobile = useMobileScreen();
  const { theme } = useThemeUI();
  return (
    <StyledClaimInfo data-id={`claim-line-info-${claim.id}`}>
      {(isMobile ? mobileClaimColumns : claimColumns)
        .filter(
          column =>
            claim.status !== CLAIM_STATUS.SUBMITTED || column.name === 'id'
        )
        .map((column, index) => (
          <Box key={index} width={column.width} pr={column.pr}>
            <StandardText fontSize={1} bold>
              {column.displayName}
            </StandardText>
            {column.customRender ? (
              column.customRender({
                fontSize: 1,
                value: path(column.name)(claim),
                item: claim,
                claim,
              })
            ) : (
              <StandardText fontSize={1}>
                {columnValue(claim, column)}
              </StandardText>
            )}
          </Box>
        ))}
      {claim?.lines?.length ? (
        <ClickableArea
          data-id={`show-all-${claim.id}`}
          flex="1"
          onClick={() => onToggleDetails()}
          justifyContent="flex-end"
          alignItems="center"
        >
          {!isMobile && (
            <StandardText clickable bold color="secondary" fontSize={1} mr={2}>
              {detailsShown ? 'Show less' : 'Show all'}
            </StandardText>
          )}
          {detailsShown ? (
            <MinusIcon
              clickable
              name="minus"
              originalColorSchema
              width="18px"
              height="18px"
            />
          ) : (
            <PlusIcon theme={theme} />
          )}
        </ClickableArea>
      ) : undefined}
    </StyledClaimInfo>
  );
};

export default ClaimInfo;
