import { useMemo } from 'react';
import { FormValidator, required } from '~lib/formValidation';
import { validators } from '~common/molecules/form-controls';
import { formatToISO8601DateOnly, getAge, toDate } from '~lib/dates';
import { getDob, GetFieldName } from './DateOfBirthUtils';

export const makeValidation = (inputName = 'yourDob') => {
  const getFieldName = GetFieldName(inputName);
  return {
    [getFieldName('Day')]: [
      required('Day is required'),
      value => {
        validators.day().validateSync(value);
      },
    ],
    [getFieldName('Month')]: [
      required('Month is required'),
      value => {
        validators.month().validateSync(value);
      },
    ],
    [getFieldName('Year')]: [
      required('Year is required'),
      value => {
        validators.year().validateSync(value);
      },
    ],
  };
};

const yourDobValidation = makeValidation('yourDob');
const partnerDobValidation = makeValidation('partnerDob');

const validate = hasPartner => {
  const getPartnerFieldName = GetFieldName('partnerDob');
  return FormValidator({
    validation: {
      ...yourDobValidation,
      ...partnerDobValidation,
    },
    transformValidation: {
      [getPartnerFieldName('Day')]: () => hasPartner,
      [getPartnerFieldName('Month')]: () => hasPartner,
      [getPartnerFieldName('Year')]: () => hasPartner,
    },
  });
};

export default ({ form, hasPartner, onChange, onNext }) => {
  const toForm = (values, inputName) => {
    const dob = getDob(values, inputName);
    return {
      value: formatToISO8601DateOnly(dob),
      label: dob.format('Do MMMM YYYY'),
      age: getAge(dob),
    };
  };

  const fromForm = inputName => {
    const date = toDate(form[inputName]?.value);
    const getFieldName = GetFieldName(inputName);
    return {
      [getFieldName('Day')]: date.get('date'),
      [getFieldName('Month')]: date.get('month') + 1,
      [getFieldName('Year')]: date.get('year'),
    };
  };

  const initialValues = useMemo(() => {
    return {
      ...fromForm('yourDob'),
      ...(hasPartner ? fromForm('partnerDob') : undefined),
    };
  }, [form]);

  const handleSubmit = values => {
    const yourDob = toForm(values, 'yourDob');
    onChange('yourDob', yourDob);
    if (hasPartner) {
      onChange('partnerDob', toForm(values, 'partnerDob'));
    }
    onNext();
  };

  return {
    state: {
      initialValues,
    },
    toForm,
    fromForm,
    validate: validate(hasPartner),
    actions: {
      handleSubmit,
    },
  };
};
