import { useMemo, useState } from 'react';
import { groupBy, pipe } from 'lodash/fp';

export default ({ inclusions, type }) => {
  const [expandId, setExpandId] = useState();

  const tiers = useMemo(() => {
    return inclusions
      .filter(incl => incl.type.toLowerCase() === type)
      .reduce((acc, incl) => {
        const tier = incl?.inclusion?.tier || 'other';
        const mapped = {
          ...incl,
          id: incl._key,
          body: incl.description || incl.inclusion.body,
          type: incl.type.toLowerCase(),
          tier: incl.comparisonTier,
        };
        acc[tier] = Array.isArray(acc[tier])
          ? acc[tier].concat(mapped)
          : [mapped];
        return acc;
      }, {});
  }, [inclusions]);

  const sortedInclusions = useMemo(() => {
    return ['featured', 'basic', 'bronze', 'silver', 'gold', 'other']
      .map(tier => tiers[tier])
      .filter(Boolean)
      .flat();
  }, [tiers]);

  const columnLayout = useMemo(() => {
    return sortedInclusions.some(
      inclusion => inclusion?.benefitsPaid || inclusion?.annualLimitPerPerson
    )
      ? 'multiple'
      : 'single';
  }, [sortedInclusions]);

  const groupedInclusions = useMemo(() => {
    return pipe(
      groupBy(inclusion => inclusion?.groupKey || inclusion.id),
      inclusions => Object.values(inclusions)
    )(sortedInclusions);
  }, [sortedInclusions]);

  const select = inclusion => {
    if (inclusion.body) {
      setExpandId(expandId === inclusion.id ? undefined : inclusion.id);
    }
  };

  return {
    state: {
      groupedInclusions,
      columnLayout,
      expandId,
    },
    actions: {
      select,
    },
  };
};
