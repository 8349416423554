import React from 'react';
import { Flex } from 'rebass';
import MobileNav from './MobileNav';
import CallUsButton from '../CallUsButton';
import Block from '../../../../common/atoms/Block';
import SearchButton from '../SearchButton';
import useToggle from '~lib/hooks/useToggle';
import SearchPanel from '../SearchPanel';
import useStoredSearch from '~lib/hooks/useStoredSearch';
import { MobileLogo } from '../Styles';
import styled from '@emotion/styled';

const StyledFlex = styled(Flex)`
  gap: 8px;
  @media (min-width: 370px) {
    gap: 24px;
  }
`;

const MobileHeader = ({ data: { logo, ...data }, next }) => {
  const [
    isSearchPanelShown,
    toggleSearchPanel,
    showSearchPanel,
    hideSearchPanel,
  ] = useToggle(false);

  useStoredSearch({
    onInitialStoredSearch: showSearchPanel,
  });

  return (
    <>
      <Block pt={20} pb={20} mb={32} className="hide-desktop">
        <Flex width={1} alignItems="center" bg="white" sx={{ gridGap: 16 }}>
          <MobileNav data={data} logo={logo} />
          <MobileLogo height={32} logo={logo} />
          <StyledFlex>
            <CallUsButton />
            <SearchButton
              onClick={() => {
                toggleSearchPanel();
              }}
            />
          </StyledFlex>
        </Flex>
      </Block>
      {isSearchPanelShown && (
        <SearchPanel
          onClose={() => {
            hideSearchPanel();
          }}
        />
      )}
    </>
  );
};

export default MobileHeader;
