import { graphql, useStaticQuery } from 'gatsby';

const ELIGIBILITY_QUERY = graphql`
  query {
    sanityApplicationFormEligibility {
      heroBannerImage {
        hotspot {
          x
          y
          height
          width
        }
        asset {
          url
        }
      }
      membershipOptions {
        id
        title
        helpText
        question
        triggerAvantQuestions
        options {
          id
          title
          triggerAvantQuestions
        }
      }
    }
  }
`;

export default () => {
  const result = useStaticQuery(ELIGIBILITY_QUERY);
  return result.sanityApplicationFormEligibility;
};
