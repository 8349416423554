import React from 'react';
import Layout from 'components/Layout';

import Navbar from '~common/organisms/Navbar';
import Wrapper from '~common/molecules/Wrapper';

import WhatsCovered from '~OMS/my-cover/templates/WhatsCovered';
import CheckMembership from '~common/organisms/CheckMembership';
import OmsFooter from '~OMS/OmsFooter';

const MyUsageLimits = props => {
  return (
    <Layout>
      <CheckMembership>
        <Navbar location={props.location} />
        <Wrapper minHeight="100vh">
          <WhatsCovered location={props.location} />
        </Wrapper>
        <OmsFooter />
      </CheckMembership>
    </Layout>
  );
};

export default MyUsageLimits;
