import { graphql, useStaticQuery } from 'gatsby';
import { rewriteSanityUrl } from '~lib/sanity/sanityUtils';
import { useMemo } from 'react';

const FILES_QUERY = graphql`
  query FilesQuery {
    allSanityFileDownload {
      nodes {
        title
        fileKey
        file {
          asset {
            url
          }
        }
      }
    }
  }
`;

export const useFilesBase = (files, keys) => {
  keys = Array.isArray(keys) ? keys : [keys];
  return useMemo(() => {
    const matches = keys.length
      ? files.filter(file => keys.includes(file.fileKey))
      : files;

    const finalResult = matches.map(file => ({
      ...file,
      url: rewriteSanityUrl(file?.file?.asset?.url),
    }));

    if (finalResult.length === 1) {
      return finalResult[0];
    }

    if (!finalResult.length) {
      return undefined;
    }

    return finalResult;
  }, [files]);
};

export default keys => {
  keys = Array.isArray(keys) ? keys : [keys];
  const files = useStaticQuery(FILES_QUERY)?.allSanityFileDownload?.nodes;
  return useFilesBase(files, keys);
};
