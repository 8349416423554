import useForm from '~lib/hooks/useForm';
import additionalDetailsValidation from '../validation/additionalDetailsValidation';

export default () => {
  const form = useForm(
    {},
    {
      validate: additionalDetailsValidation(),
    }
  );

  const serialize = () => null;

  return {
    form,
    serialize,
  };
};
