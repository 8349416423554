import React, { useRef } from 'react';
import Icon from '~common/atoms/Icon';
import { StandardText } from '~common/atoms/typography';
import { Flex, Box } from 'rebass';
import RTPopover from 'react-tiny-popover';
import useToggle from '~lib/hooks/useToggle';
import { useThemeUI } from 'theme-ui';
import styled from '@emotion/styled';
import { isRunningOnClientSide } from '~lib/util';
const shareProviders = [
  {
    icon: 'twitter',
    label: 'Twitter',
    shareLink: (article, url) => {
      return `https://twitter.com/home?status=${url}`;
    },
  },
  {
    icon: 'linkedin',
    label: 'Linkedin',
    shareLink: (article, url) => {
      return `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${article.title}&summary=&source=`;
    },
  },
  {
    icon: 'facebook',
    label: 'Facebook',
    shareLink: (article, url) => {
      return `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    },
  },
  {
    icon: 'email-share',
    label: 'Email',
    shareLink: (article, url) => {
      return `mailto:info@example.com?&subject=${article.title}&body=${url}`;
    },
  },
];

const PopoverContainer = styled(Box)`
  padding: 16px;
  background: ${props => props.theme.colors.white};
  width: 148px;
`;

const StyledShareButton = styled.div`
  display: flex;
  width: 148px;
  align-items: center;
`;

const ShareButtonContainer = styled(Box)`
  .react-tiny-popover-container {
  }
`;

const ShareLink = styled(StandardText)`
  &&& {
    text-decoration: none;
    border-bottom: 1px solid ${props => props.theme.colors.secondary};
  }
`;

const getRootParentNode = node => {
  let parentNode = node;
  while (parentNode.parentNode) {
    parentNode = parentNode.parentNode;
  }

  return parentNode;
};

const ShareButton = ({ article, ...props }) => {
  const [isOpen, , open, close] = useToggle(false);
  const { theme } = useThemeUI();
  const shareIconRef = useRef(null);

  return (
    <ShareButtonContainer {...props}>
      <RTPopover
        containerClassName="share-button-popover"
        containerStyle={{
          boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.25)',
          borderRadius: '3px',
          border: `solid 1px ${theme.colors.borders}`,
        }}
        position="bottom"
        disableReposition
        isOpen={isOpen}
        onClickOutside={evt => {
          if (
            shareIconRef.current &&
            shareIconRef.current
              .querySelector('svg')
              .isEqualNode(getRootParentNode(evt.target))
          ) {
            return;
          }

          close();
        }}
        content={
          <PopoverContainer>
            {shareProviders.map((shareProvider, index) => (
              <Flex
                key={index}
                alignItems="center"
                mb={index !== shareProviders.length - 1 ? 14 : 0}
              >
                <Icon
                  width="18px"
                  height="18px"
                  clickable
                  name={shareProvider.icon}
                  fill={theme.colors.primary}
                />
                <ShareLink
                  as="a"
                  lineHeight={1.5}
                  href={shareProvider.shareLink(
                    article,
                    isRunningOnClientSide() ? window.location.href : '/'
                  )}
                  ml="10px"
                  color="blue2"
                  fontSize={14}
                  external
                  target="_blank"
                  clickable
                >
                  {shareProvider.label}
                </ShareLink>
              </Flex>
            ))}
          </PopoverContainer>
        }
      >
        <StyledShareButton
          onClick={() => {
            open();
          }}
          alignItems="flex-end"
        >
          <Icon
            ref={shareIconRef}
            name="share"
            fill={theme.colors.primary}
            clickable
            width="32px"
            height="32px"
          />
          <StandardText clickable color="blue2" ml="10px" fontSize={2} bold>
            Share article
          </StandardText>
        </StyledShareButton>
      </RTPopover>
    </ShareButtonContainer>
  );
};

export default ShareButton;
