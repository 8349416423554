import styled from '@emotion/styled';
import { CoverProducts } from '~lib/CoverProducts';

export default styled(CoverProducts)`
  &.cover-products-no-space {
    padding-top: 0;
    margin-top: 0;
  }

  .product-details {
    border: 2px solid ${props => props.theme.colors.borders};
    margin-top: ${props => props.theme.space[3]}px;
  }

  .product-tabs {
    button:not(.selected) {
      border: 0;
      background: ${props => props.theme.colors.background.light};
    }
  }

  .hospital-container {
  }

  .extras-container {
  }
`;
