import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';
import SanityImage from '~common/molecules/SanityImage';
import { H3, P2, P3 } from '~common/atoms/typography';
import Button from './Button';
import Link from './Link';
import { path } from 'lodash/fp';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import { getCardWidth } from './pageBlocksUtils';

const StyledCard = styled(Flex)`
  ${props =>
    props.showBorders
      ? `
    padding: 19px 19px 26px 19px;
    box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.3);
  `
      : ''}
`;

const StyledHorizontalCard = styled(Flex)``;

const StyledHorizontalCardImage = styled(SanityImage)`
  width: 100%;
  object-fit: cover;
  ${props => props.theme.mq.tabletP} {
    width: 50%;
    max-height: 365px;
    min-height: 365px;
    order: ${props => props.order};
  }
`;

const HorizontalCard = ({
  data: { image, title, titleColor = 'dark2', description, action },
  index,
  cardsCount,
  style,
  ...props
}) => {
  const order = {
    image: style === 'imageLeft' ? (index % 2 ? 2 : 1) : index % 2 ? 1 : 2,
    text: {
      xs: 'none',
      tabletP: style === 'imageLeft' ? (index % 2 ? 1 : 2) : index % 2 ? 2 : 1,
    },
  };

  return (
    <StyledHorizontalCard
      flexDirection={{
        xs: 'column',
        tabletP: 'row',
      }}
      alignItems="center"
      {...props}
    >
      <StyledHorizontalCardImage order={order.image} src={image} />
      <Box
        order={order.text}
        width={{
          xs: 1,
          tabletP: 1 / 2,
        }}
        pl={{
          xs: 20,
          tabletP: 60,
        }}
        pt={{
          xs: 30,
          tabletP: 60,
        }}
        pb={{
          xs: index === cardsCount -1 ? 0 : 60,
          tabletP: 60,
        }}
        pr={{
          xs: 20,
          tabletP: 60,
        }}
      >
        <Flex flexDirection="column" justifyContent="space-between">
          <Box>
            <H3 variant="semiBold" color="neutral900" mb={30}>
              {title}
            </H3>
            {typeof description === 'string' ? (
              <P3 variant="medium">{description}</P3>
            ) : (
              <SanityRichTextContent content={description} />
            )}
          </Box>
          {action && (
            <Flex mt={40} justifyContent="flex-start">
              <Button data={action} />
            </Flex>
          )}
        </Flex>
      </Box>
    </StyledHorizontalCard>
  );
};

const Card = (
  { data, style, index, imagePlaceholderCss, cardsCount, ...props },
  ref
) => {
  const { image, title, titleColor = 'dark', description, action } = data;
  if (['imageLeft', 'imageRight'].includes(style)) {
    return (
      <HorizontalCard
        data={data}
        style={style}
        index={index}
        cardsCount={cardsCount}
        {...props}
      />
    );
  }
  const showBorders = style === 'bordered';

  const width = getCardWidth(cardsCount);
  const actionLink = path('link')(action);
  return (
    <StyledCard
      data={actionLink}
      ref={ref}
      showBorders={showBorders}
      flexDirection="column"
      justifyContent="space-between"
      width={width}
      maxWidth={width}
      minWidth={width}
      {...props}
    >
      <Box>
        <Box css={imagePlaceholderCss} mb={20} height={238}>
          {image && (
            <Link data={actionLink}>
              <SanityImage
                resizeHeight={238}
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                }}
                src={image}
              />
            </Link>
          )}
        </Box>
        <Box>
          <H3 variant="semiBold" mb={20} color="neutral900">
            {title}
          </H3>
          {typeof description !== 'string' ? (
            <SanityRichTextContent content={description} />
          ) : (
            <P2 variant="medium" color="neutral500">
              {description}
            </P2>
          )}
        </Box>
      </Box>

      {action && (
        <Flex mt="24px" justifyContent="center">
          <Button data={action} />
        </Flex>
      )}
    </StyledCard>
  );
};

export default forwardRef(Card);
