import styled from '@emotion/styled';
import { themePirmaryDark } from './colors';

// This styled component is SSO page Heading title
const FormText = styled.p`
  color: ${themePirmaryDark};
  font-size: ${props => props.theme.fontSizes[2]}px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  ${props => props.inputCss}
`;

export default FormText;
