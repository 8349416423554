import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';
import { P2, Subheading } from '~common/atoms/typography';
import { css } from '@emotion/core';
import useToggle from '~lib/hooks/useToggle';

export const PanelHeader = styled(Flex)`
  justify-content: space-between;
  padding: 13px 10px;
  background: ${props => {
    switch (props.type) {
      case 'secondary': {
        return props.theme.colors.background.light;
      }
      case 'primary': {
        return props.theme.colors.secondary;
      }
      case 'ternary': {
        return props.theme.colors.white;
      }

      default: {
        return props.theme.colors.secondary;
      }
    }
  }};
  border: 1px solid
    ${props => {
      switch (props.type) {
        case 'secondary':
        case 'ternary': {
          return props.theme.colors.borders;
        }

        default: {
          return props.theme.colors.secondary;
        }
      }
    }};
  transition: all 300ms ease-out;
  cursor: pointer;
  border-start-start-radius: 4px;
  border-start-end-radius: 4px;
`;

export const PanelBody = styled(Box)`
  padding: 20px;
  border: 1px solid ${props => props.theme.colors.secondary};
  border-top: 0;
  border-end-start-radius: 4px;
  border-end-end-radius: 4px;
`;

export const Panel = ({
  header,
  title,
  onHeaderClick,
  type,
  children,
  ...props
}) => {
  return (
    <Box {...props}>
      <PanelHeader onClick={onHeaderClick} type={type}>
        {header || (
          <P2 variant="medium" color="white">
            {title}
          </P2>
        )}
      </PanelHeader>
      <PanelBody>{children}</PanelBody>
    </Box>
  );
};

export const FormPanel = ({
  header,
  title,
  onHeaderClick,
  type,
  children,
  expanded,
  inactiveHeaderTextColor = 'primary',
  ...props
}) => {
  const [expandedInternal, , show, hide] = useToggle(true);

  useEffect(() => {
    if (expanded == null) {
      return;
    }

    if (expanded) {
      show();
    } else {
      hide();
    }
  }, [expanded]);

  const headerType = (() => {
    if (expanded == null) {
      return type;
    }

    return expandedInternal ? 'primary' : 'ternary';
  })();
  return (
    <Box mb={45} {...props}>
      <PanelHeader
        onClick={() => {
          if (onHeaderClick) {
            onHeaderClick();
          }
        }}
        type={headerType}
      >
        {header || (
          <Subheading
            variant="semiBold"
            color={!expandedInternal ? inactiveHeaderTextColor : 'white'}
          >
            {title}
          </Subheading>
        )}
      </PanelHeader>
      {expandedInternal && (
        <PanelBody
          css={css`
            padding: 40px 49px;
          `}
          px={{
            xs: 20,
            tabletP: 50,
          }}
          py={{
            xs: 20,
            tabletP: 40,
          }}
        >
          {children}
        </PanelBody>
      )}
    </Box>
  );
};

export const SubFormPanel = styled(Box)`
  background: ${props => props.theme.colors.background.light};
  padding: 20px;
  margin-top: -10px;
  margin-bottom: 30px;
`;

export default Panel;
