import React from 'react';
import InclusionsList from '~OMS/my-cover/molecules/InclusionsList';
import { H3, P3, StandardText } from '~common/atoms/typography';
import ProductCardPrice from '~OMS/my-cover/molecules/ProductCardPrice';
import CenterBox from '~common/atoms/CenterBox';
import { Button } from '~common/atoms/Button';
import { Link } from 'gatsby';
import Eyebrow from '~common/atoms/Eyebrow';
import styled from '@emotion/styled';
import { path, pipe, find, whereEq } from 'lodash/fp';
import { pathOr } from '~lib/util';
import Loading from '~common/atoms/Loading';

const StyledInclusionsList = styled(InclusionsList)`
  background: ${props => props.theme.colors.background.light};
`;

const formatStatus = coverStatus =>
  ({
    SINGLE: 'yourself',
    FAMILY: 'your family',
    COUPLE: 'your spouse and yourself',
    SINGLE_PARENT: 'your children and yourself',
  }[coverStatus]);

const ProductPromoCard = ({
  product,
  paymentFrequency,
  coverStatus,
  title,
  description,
  instruction,
  eyebrow,
}) => {
  const amount = pipe(
    pathOr([], 'variants'),
    find(
      whereEq({
        onSale: true,
      })
    ),
    path('price.net')
  )(product);

  return (
    <StyledInclusionsList>
      <Eyebrow textAlign="center" className="hide-mobile">
        {eyebrow}
      </Eyebrow>
      <H3 variant="semiBold" py={20} textAlign="center">
        {title}
      </H3>
      <P3 variant="medium" color="dark" mb={3} textAlign="center">
        {description}
      </P3>
      <StandardText color="dark" textAlign="center" mb={3}>
        You can add {product.title} for {formatStatus(coverStatus)} for
      </StandardText>
      {!amount ? (
        <Loading size="small" />
      ) : (
        <ProductCardPrice amount={amount} paymentFrequency={paymentFrequency} />
      )}
      <CenterBox>
        <Button
          as={Link}
          to="/oms/my-cover/change"
          width={188}
          variant="primary"
        >
          {instruction}
        </Button>
      </CenterBox>
    </StyledInclusionsList>
  );
};

export default ProductPromoCard;
