import React from 'react';
import { CreateControl } from '~common/molecules/form-controls/Control';
import RadioGroup from '~common/atoms/RadioGroup';
import Radio from '~common/atoms/Radio';

import ResponsiveImage from '~common/atoms/ResponsiveImage';
import { Box, Flex } from 'rebass';
import Popover from '../../../Elements/Popover';

const CardType = ({ type, tooltip, label, ...props }) => (
  <Flex flexDirection="column" {...props}>
    <Popover position="top" content={tooltip}>
      <ResponsiveImage upTo={2} src={`medicare-card-${type}.png`} />
    </Popover>
    <Box mt={1}>
      <Radio value={type}>{label}</Radio>
    </Box>
  </Flex>
);

const MedicareCardType = ({ value, onChange, ...props }) => {
  return (
    <RadioGroup
      value={value}
      onChange={evt => {
        if (onChange) {
          onChange(evt.target.value);
        }
      }}
      {...props}
    >
      <Flex
        flexDirection={{
          xs: 'column',
          tabletP: 'row',
        }}
      >
        <CardType
          tooltip="This is the standard Medicare card issued to you if you are an Australian citizen or permanent resident."
          mr={4}
          type="green"
          label="Green"
          my={{
            xs: 3,
            tabletP: 0,
          }}
        />
        <CardType
          tooltip="This is the interim Medicare card issued to you if you are a temporary resident and are applying for permanent residency. These cards are issued 12-months at a time."
          type="blue"
          label="Blue"
        />
      </Flex>
    </RadioGroup>
  );
};

export default CreateControl(MedicareCardType);
