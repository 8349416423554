import { DrawerQuery } from 'queries/oms/common.graphql';
import { cloneDeep } from '@apollo/client/utilities';

export default ({ mutationName, mapActivity }) =>
  (store, response) => {
    if (response.data[mutationName].success) {
      const data = cloneDeep(store.readQuery({ query: DrawerQuery }));
      const activity = mapActivity(response.data);
      if (data && data.oms) {
        data.oms.activities.unshift(activity);
        store.writeQuery({ query: DrawerQuery, data });
      }
    }
  };
