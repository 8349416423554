import React from 'react';
import Block from '../../common/atoms/Block';
import Heading from './Heading';
import { useColumnContext } from '~lib/providers/ColumnProvider';

const HeadingBlock = props => {
  const { sideMenuLayout } = useColumnContext() || {};
  return (
    <Block pt={0} pb={0} mt={0} mb={0}>
      <Heading
        textAlign={sideMenuLayout ? 'left' : 'center'}
        mb={{
          xs: 30,
          md: 50,
        }}
        {...props}
      />
    </Block>
  );
};

export default HeadingBlock;
