import { useMutation } from '@apollo/client';
import React, { useCallback, useMemo, useState } from 'react';
import { path, pipe, pluck, sum, filter, uniqWith } from 'lodash/fp';
import { useThemeUI } from 'theme-ui';
import { throttle } from 'lodash';
import {
  MakeClaimMutation,
  ClaimsHistoryQuery,
} from 'queries/oms/claims.graphql';
import { DrawerQuery } from 'queries/oms/common.graphql';
import { ACCEPTED_FILE_TYPES, MAX_BATCH_SIZE } from '~OMS/claims/config';
import useToggle from '~lib/hooks/useToggle';
import EmailUs from '~common/molecules/EmailUs';
import { cloneDeep } from '@apollo/client/utilities';
import { fromBlob } from 'file-type/browser';

if (typeof window !== 'undefined' && !window.Buffer) {
  window.Buffer = require('buffer').Buffer;
}

const EXCEEDED_SIZE_LIMIT_MESSAGE =
  'You have exceeded the maximum total file(s) size. Try reducing files or updating separate batches.';

export const states = {
  INITIAL: 0,
  SUCCESS: 1,
  IN_PROGRESS: 2,
  UPLOAD_COMPLETE: 3,
};

const defaultClaimInput = personId => ({
  input: { personId, period: 36 },
});

const getInvalidFiles = filter(
  file => !ACCEPTED_FILE_TYPES.includes(path('fileType.ext')(file))
);

const getStatus = path('makeClaim.success');
const getMessage = path('makeClaim.message');
const toMegas = sizeInBytes => sizeInBytes / 1000 / 1000;

const getUnsupportedFileMessage = theme => (
  <>
    We’re having trouble accepting your file. Please email a copy of your
    receipt directly to{' '}
    <EmailUs color={theme.colors.error} bold fontSize={2} inline />. We
    apologise for any inconvenience.
  </>
);

const getMessageFromServerSideError = (error, theme) => {
  const code = path(['graphQLErrors', 0, 'extensions', 'code'])(error);
  if (
    [
      'UNSUPPORTED_FILE_TYPE_ERROR',
      'UNSUPPORTED_FILE_EXTENSION_ERROR',
    ].includes(code)
  ) {
    return getUnsupportedFileMessage(theme);
  }

  if (code === 'TOTAL_FILE_SIZE_EXCEEDED_ERROR') {
    return EXCEEDED_SIZE_LIMIT_MESSAGE;
  }

  if (error.networkError) {
    return (
      error.networkError.message ||
      'We are sorry. The network request has been terminated unexpectedly.'
    );
  }

  return error.message;
};

export default () => {
  const { theme } = useThemeUI();
  const [makeClaim, { data }] = useMutation(MakeClaimMutation);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [uploadedAmount, setUploadedAmount] = useState(0);
  const [files, setFiles] = useState([]);
  const [termsAccepted, toggleTerms, checkTerms, unCheckTerms] =
    useToggle(false);
  const [
    termsAndConditionsVisible,
    ,
    showTermsAndConditions,
    hideTermsAndConditions,
  ] = useToggle(false);

  const handleUserAgreement = useCallback(() => {
    checkTerms();
    hideTermsAndConditions();
  }, [checkTerms, hideTermsAndConditions]);

  const reset = () => {
    setError();
    setLoading(false);
    setUploadedAmount(0);
  };

  const detectFileTypes = files => {
    Promise.all(
      files.map(file =>
        fromBlob(file).then(fileType => {
          file.fileType = fileType;
          return file;
        })
      )
    ).then(filesWithFileType => {
      console.log(filesWithFileType);
      setFiles(filesWithFileType);
    });
  };

  const addFile = useCallback(
    (addedFiles, allFiles) => {
      const filesToAdd = uniqWith(
        (fileA, fileB) => fileA.name === fileB.name,
        [...files, ...allFiles]
      );
      reset();
      detectFileTypes(filesToAdd);
    },
    [files]
  );

  const totalFilesSize = useMemo(() => {
    return pipe(pluck('size'), sum)(files);
  }, [files]);

  const alert = useMemo(() => {
    if (error) {
      return {
        type: 'error',
        serverError: true,
        message: getMessageFromServerSideError(error, theme),
      };
    }
    const invalidFiles = getInvalidFiles(files) || [];
    if (invalidFiles.length) {
      return {
        type: 'error',
        message: getUnsupportedFileMessage(theme),
      };
    }

    if (toMegas(totalFilesSize) > MAX_BATCH_SIZE) {
      return {
        type: 'error',
        message: EXCEEDED_SIZE_LIMIT_MESSAGE,
      };
    }

    if (loading) {
      return {
        type: 'info',
        message: 'Upload in progress. Do not leave until completed...',
      };
    }

    if (getStatus(data) === false) {
      return {
        type: 'error',
        message: getMessage(data),
      };
    }

    return undefined;
  }, [error, files, totalFilesSize, loading, data]);

  const confirmationState = useMemo(() => {
    if (!uploadedAmount || !!error) {
      return states.INITIAL;
    }
    if (uploadedAmount < 100) {
      return states.IN_PROGRESS;
    }

    if (getStatus(data) === false) {
      return states.INITIAL;
    }

    if (getStatus(data) === true) {
      return states.SUCCESS;
    }

    if (uploadedAmount === 100) {
      return states.UPLOAD_COMPLETE;
    }

    return undefined;
  }, [data, error, uploadedAmount]);

  const removeFile = useCallback(fileId => {
    setFiles(currentFiles => currentFiles.filter(file => file.uid !== fileId));
  }, []);

  const startNewClaim = useCallback(() => {
    setFiles([]);
    reset();
  }, []);
  // Helper function to handle updating query data
  const updateStoreData = (store, query, item, propertyName, variables) => {
    const data = cloneDeep(store.readQuery({ query, variables }));
    if (data) {
      data.oms[propertyName].unshift(item);
      store.writeQuery({ query, variables, data });
    }
  };

  const submitClaim = useCallback(async () => {
    try {
      reset();
      const { data: result } = await makeClaim({
        variables: {
          input: {
            files,
          },
        },
        context: {
          hasUpload: true,
          fetchOptions: {
            onUploadProgress: throttle(({ progress }) => {
              setUploadedAmount(progress);
            }, 300),
          },
        },
        update: (store, { data: { makeClaim } }) => {
          updateStoreData(store, DrawerQuery, makeClaim.activity, 'activities');
          updateStoreData(
            store,
            ClaimsHistoryQuery,
            makeClaim.claim,
            'claims',
            defaultClaimInput(makeClaim.claim.personIds[0])
          );
        },
      });

      setLoading(false);

      if (getStatus(result)) {
        setFiles([]);
      }
    } catch (submitError) {
      console.error(submitError);
      reset();
      setError(submitError);
    }
  }, [files, makeClaim]);

  return {
    state: {
      termsAndConditionsVisible,
      confirmationState,
      alert,
      totalFilesSize,
      files,
      progress: uploadedAmount,
      loading,
      submitDisabled:
        !files.length ||
        (alert && alert.type === 'error' && !alert.serverError) ||
        loading,
      termsAccepted,
    },
    actions: {
      submitClaim,
      startNewClaim,
      removeFile,
      addFile,
      showTermsAndConditions,
      hideTermsAndConditions,
      checkTerms,
      unCheckTerms,
      toggleTerms,
      handleUserAgreement,
    },
  };
};
