import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import NumberFormat from 'react-number-format';

const StyledCard = styled.div``;

const StyledType = styled.p`
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSizes[0]}px;
  line-height: 13px;
  color: ${props => props.theme.colors.white};
`;

const StyledTitle = styled.p`
  padding-top: 4px;
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 18px;
  font-weight: 700;
  color: ${props => props.theme.colors.white};

  span {
    font-weight: 500;
    margin-left: 4px;
  }
`;

const StyledPrice = styled.p`
  ${props => props.theme.fontSizes[3]}px;
  line-height: 21px;
  font-weight: 600;
  color: ${props => props.theme.colors.white};
`;

const ProductCard = ({ price, subtitle, title, type }) => {
  return (
    <StyledCard>
      <StyledType>{type}</StyledType>
      <StyledTitle>
        {title}
        <span>{subtitle}</span>
      </StyledTitle>
      <StyledPrice>
        <NumberFormat
          value={price}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
          decimalScale={2}
          fixedDecimalScale={true}
        />
      </StyledPrice>
    </StyledCard>
  );
};

ProductCard.propTypes = {
  price: PropTypes.number,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ProductCard;
