import React from 'react';
import Icon from '../../../../common/atoms/Icon';
import { Flex } from 'rebass';
import { StandardText } from '../../../../common/atoms/typography';
import { WAIT_PERIOD } from '~OMS/claims/config';

const ClaimSubmitSuccess = ({ theme, ...props }) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Icon
        mb={4}
        width="50px"
        height="50px"
        fill={theme.colors.green}
        name="success-circle-tick"
      />
      <StandardText textAlign="center">
        Your claim was successfully submitted
      </StandardText>
    </Flex>
  );
};

export default ClaimSubmitSuccess;
