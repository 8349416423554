import React, { useState } from 'react';
import CenterBox from '~common/atoms/CenterBox';
import styled from '@emotion/styled';
import RecalculateInfo from './RecalculateInfo';

const StyledContainer = styled(CenterBox)`
  background: ${props => props.theme.colors.white};
`;

const WarningModal = ({ children, onConfirmRecalculateInfo }) => {
  const [recalculateInfoShown, showRecalculateInfo] = useState(false);
  return (
    <StyledContainer
      px={{
        xs: 30,
        tabletP: 60,
      }}
      py={{
        xs: 20,
        tabletP: 40,
      }}
      flexDirection="column"
      height="auto"
    >
      {!recalculateInfoShown || !onConfirmRecalculateInfo ? (
        children({
          showRecalculateInfo: () => {
            showRecalculateInfo(true);
          },
        })
      ) : (
        <RecalculateInfo onConfirm={onConfirmRecalculateInfo} />
      )}
    </StyledContainer>
  );
};

export default WarningModal;
