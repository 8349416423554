import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledBlock = styled.section`
  padding: 64px 0;

  ${props => props.theme.mq.sm} {
    padding: 100px 0;
  }
`;

const StyledContainer = styled.div`
  padding-left: ${props => props.theme.sizes.gap};
  padding-right: ${props => props.theme.sizes.gap};
  max-width: ${props => props.theme.sizes.container};
  width: 100%;
  margin: 0 auto;
`;

const Section = ({ children }) => {
  return (
    <StyledBlock>
      <StyledContainer>{children}</StyledContainer>
    </StyledBlock>
  );
};

Section.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default Section;
