import useToggle from '~lib/hooks/useToggle';
import { useMobileScreen } from '~lib/responsive';
import ClaimLines from '~OMS/claims/molecules/ClaimLines';
import ClaimFooter from '~OMS/claims/molecules/ClaimFooter';
import React from 'react';
import styled from '@emotion/styled';
import { Box } from 'rebass';
import ClaimInfo from '~OMS/claims/molecules/ClaimInfo';

const StyledClaimRecord = styled(Box)`
  border: 2px solid ${props => props.theme.colors.iceBlue};
`;

const ClaimRecord = ({ theme, claim, ...props }) => {
  const [detailsShown, toggleDetails] = useToggle(false);
  const isMobile = useMobileScreen();
  return (
    <StyledClaimRecord {...props}>
      <ClaimInfo
        isMobile={isMobile}
        detailsShown={detailsShown}
        onToggleDetails={toggleDetails}
        theme={theme}
        claim={claim}
      />
      {detailsShown && (
        <ClaimLines
          isMobile={isMobile}
          theme={theme}
          lines={claim.lines}
          claim={claim}
        />
      )}
      <ClaimFooter isMobile={isMobile} theme={theme} claim={claim} />
    </StyledClaimRecord>
  );
};

export default ClaimRecord;
