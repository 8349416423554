import React from 'react';
import { FancyCheckboxControl } from '~common/molecules/form-controls';
import { P3 } from '~common/atoms/typography';

const getOptions = lhcMessage => [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
    additionalInfo: lhcMessage ? (
      <>
        <P3 color="primary" variant="semiBold" mb={2}>
          Lifetime Health Cover (LHC)
        </P3>
        {lhcMessage}
      </>
    ) : null,
  },
];

const AnotherFundTransfer = ({ Field, onChange, name, label, lhcMessage }) => {
  return (
    <Field
      name={name}
      label={label}
      component={FancyCheckboxControl}
      options={getOptions(lhcMessage)}
      onChange={onChange}
    />
  );
};

export default AnotherFundTransfer;
