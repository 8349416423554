import React from 'react';
import { Flex } from 'rebass';

const CenterBox = ({ children, ...props }) => {
  return (
    <Flex
      width={1}
      height="100%"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      {children}
    </Flex>
  );
};

export default CenterBox;
