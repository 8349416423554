import React from 'react';
import { Heading, Text, Button } from 'rebass';
import { css } from '@emotion/core';
import { Element } from 'react-scroll';

import Icon from '~common/atoms/Icon';

import { path } from 'lodash/fp';
import tiers from '~lib/constants/tiers';
import { quoteStatuses } from '~lib/constants';
import styled from '@emotion/styled';
import Question from '../../../../../Quote/QuoteForm/Question';
import RadioButtonGroup from '../../../../../Quote/QuoteForm/RadioButtonGroup';
import { rebateText } from '../../../../../Quote/QuoteForm/styles';
import Link from '~common/atoms/Link';

const Block = styled.div`
  width: 100%;
`;

const QuoteForm = ({ form = {}, onChange, onSubmit }) => {
  const setValue = (question, field, value) => {
    onChange({
      ...form,
      [field]: value,
    });
  };

  const tierChoices = [{ value: undefined, label: '' }].concat(
    form.status
      ? tiers[form.status.value === 'SINGLE' ? 'SINGLE' : 'FAMILY']
      : []
  );

  return (
    <>
      <Block id="scroll-container" snug={true} bg="white">
        <Question
          name="question-status"
          title="Let's start by understanding what type of cover you need."
          pb={{
            xs: 50,
            md: 70,
          }}
        >
          <RadioButtonGroup
            data-id="status"
            name="status"
            availableStates={[{ value: undefined, label: '' }].concat(
              quoteStatuses
            )}
            defaultValue={form.status ? form.status.value : undefined}
            onChange={change => {
              setValue('status', 'status', change);
            }}
          />
        </Question>

        <Question
          hide={!form.status || !form.status.value}
          name="question-tier"
          title="Tax rebate details"
        >
          <Text
            textAlign="center"
            lineHeight="1.31"
            color="text.light"
            css={rebateText}
            mb={4}
          >
            You may be eligible to lower your health insurance costs with the
            Australian Government's rebate on private health insurance. Your
            government rebate tier is determined by your income and age.
          </Text>

          <Heading
            color="text.dark"
            fontSize={3}
            fontWeight="normal"
            lineHeight={1.2}
            mb={3}
          >
            {form.status && form.status.value === 'SINGLE'
              ? 'My annual income is'
              : 'Our combined annual income is'}
            :
          </Heading>
          <RadioButtonGroup
            data-id="tier"
            name="tier"
            availableStates={tierChoices}
            defaultValue={form.tier ? form.tier.value : undefined}
            onChange={change => {
              setValue('tier', 'tier', change);
            }}
          />
          {!path('tier.value')(form) && (
            <Text color="text.light" my={{ xs: 2, lg: 0 }}>
              <Link
                to="#"
                onClick={evt => {
                  evt.preventDefault();
                  setValue('tier', 'tier', tierChoices[1]);
                  onSubmit();
                }}
              >
                Skip question
              </Link>{' '}
              (Your quote will be calculated using the base tier)
            </Text>
          )}
        </Question>

        <Element name="question-calculate">
          {
            <div
              css={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding-top: 50px;
                padding-bottom: 80px;

                @media screen and (max-width: 992px) {
                  padding-bottom: 50px;
                }
              `}
            >
              <Button
                disabled={!(form.status && form.tier)}
                data-id="calculate-cover"
                variant="orange-round"
                onClick={() => {
                  onSubmit();
                }}
                ml={{ xs: 0, md: '15px' }}
                style={{ minWidth: '200px' }}
              >
                Calculate cover
                <Icon
                  ml={1}
                  name="arrow-right"
                  fill={!(form.status && form.tier) ? '#4a4a4a' : '#fff'}
                  width="19px"
                  height="21px"
                  style={{
                    float: 'right',
                    marginTop: '7px',
                    marginRight: '8px',
                  }}
                />
              </Button>
            </div>
          }
        </Element>
      </Block>
    </>
  );
};

export default QuoteForm;
