import React from 'react';
import styled from '@emotion/styled';
import { Box } from 'rebass';
import SanityImage from '~common/molecules/SanityImage';

const ImageContainer = styled(Box)`
  clip-path: ${props =>
    `path('M${props.width}.5,555.5V0.5H73.2C26.9,82.5,0.5,177.1,0.5,278c0,100.9,26.4,195.5,72.7,277.5H${props.width}.5z')`};

  ${props => props.theme.mq.tabletP} {
    ${props => {
      const margin = (window.innerWidth - props.containerWidth) / 2;
      const padding = 70;
      return `
    transform: translateX(${padding}px);
    width: calc(50% - ${padding}px + ${margin}px);
    min-width: calc(50% - ${padding}px + ${margin}px);
    `;
    }}
  }

  img {
    height: ${props => props.height}px;
    object-position: right;
  }
`;

const HeroImage = ({
  height = 555,
  image,
  containerWidth,
  children,
  ...props
}) => {
  return (
    containerWidth && (
      <ImageContainer
        height={height}
        containerWidth={containerWidth}
        width={containerWidth}
        {...props}
      >
        {image && <SanityImage src={image} />}
        {children}
      </ImageContainer>
    )
  );
};

export default HeroImage;
