import React from 'react';
import { css } from '@emotion/core';

import Layout from 'components/Layout';

import AppButton from 'components/Elements/AppButton';

import useProductsQuery from '~lib/hooks/sanity/useProductsQuery';
import { Footer, Header } from '../../components/PageBlocks';
import CmsPage from '../../components/Elements/CmsPage';

import Product from './Product';
import useEventListener from '~lib/hooks/useEventListener';

const ProductPage = props => {
  const { product, all: products } = useProductsQuery(
    props.pageContext.type,
    props.pageContext.slug,
    props.pageContext.products
  );
  const [isShown, setShown] = React.useState(false);

  useEventListener('scroll', () => {
    const scroll = window?.scrollY;
    scroll >= 510 ? setShown(true) : setShown(false);
  });
  return (
    <CmsPage pageContext={props.pageContext}>
      <Layout>
        <Header removeBottomMargin data={props.pageContext.header} />
        {product && (
          <Product
            product={product}
            pageContext={props.pageContext}
            products={products}
            footer={
              <Footer
                css={css`
                  position: relative;
                  z-index: 999;
                `}
                data={props.pageContext.footer}
              />
            }
          />
        )}
        {isShown && (
          <div className="hide-desktop">
            <AppButton
              style={{ zIndex: '50' }}
              variant="secondary"
              to="/quote"
              text="Get a quote"
              type="fixed"
            />
          </div>
        )}
      </Layout>
    </CmsPage>
  );
};

export default ProductPage;
