import React from 'react';
import { Flex, Box } from 'rebass';
import { withTheme } from 'emotion-theming';
import Icon from '~common/atoms/Icon';

import FormTitle from '~OMS/styles/FormTitle';

function SimpleErrorHeader({ title, theme, withIcon, ...props }) {
  return (
    <Flex {...props} alignItems="center">
      {withIcon && (
        <Box mr="40px">
          <Icon
            height="40px"
            width="40px"
            name="oms-excluded"
            fill={theme.colors.error}
          />
        </Box>
      )}

      <FormTitle lineHeight="1" marginReset>
        {title}
      </FormTitle>
    </Flex>
  );
}

export default withTheme(SimpleErrorHeader);
