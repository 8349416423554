import React, { useEffect, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { withTheme } from 'emotion-theming';

const ExtrasUsagePieChart = ({ theme, total, used }) => {
  const [isShown, show] = useState(true);
  const usedPercent = total ? (used / total) * 100 : 100;
  const remaining = total ? 100 - usedPercent : 0;

  useEffect(() => {
    show(false);
    setTimeout(() => {
      show(true);
    }, 0);
  }, [total, used]);
  return (
    isShown && (
      <PieChart
        animate
        data={[
          {
            title: 'remaining',
            value: remaining,
            color: theme.colors.lightNeutral200,
          },
          {
            title: 'usage',
            value: usedPercent,
            color: theme.colors.secondary,
          },
        ]}
      />
    )
  );
};

export default withTheme(ExtrasUsagePieChart);
