import React from 'react';
import styled from '@emotion/styled';
import { browserName, browserVersion } from 'react-device-detect';
import { Flex, Text } from 'rebass';

const FixedWarning = styled(Flex)`
  width: 100%;
  background-color: #fff8ea;
  position: fixed;
  top: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
`;

const Warning = styled(Flex)`
  width: 100%;
  background-color: #fff8ea;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
`;

const useBrowser = options => {
  options = options || {
    IE: 11,
    Firefox: 61,
    Opera: 56,
    Safari: 10,
    Chrome: 70,
  };

  const getName = () => {
    return browserName;
  };

  const getVersion = () => {
    return browserVersion;
  };

  const loginMessage = () => {
    const name = getName();
    const version = getVersion();
    let show = false;

    // eslint-disable-next-line array-callback-return
    Object.keys(options).map(o => {
      if (o === name) {
        if (options[name] >= Number(version)) {
          show = true;
        }
      }
    });

    return (
      show && (
        <FixedWarning justifyContent="center" p={3}>
          <div>
            {`Your web browser `}
            <Text
              display="inline"
              fontWeight="700"
            >{` (${getName()} ${getVersion()}) `}</Text>
            {` is not supported. Please login using a different browser.`}
          </div>
        </FixedWarning>
      )
    );
  };

  const message = () => {
    const name = getName();
    const version = getVersion();
    let show = false;

    // eslint-disable-next-line array-callback-return
    Object.keys(options).map(o => {
      if (o === name) {
        if (options[name] >= Number(version)) {
          show = true;
        }
      }
    });

    return (
      show && (
        <Warning justifyContent="center" p={3}>
          <div>
            {`Your web browser `}
            <Text
              display="inline"
              fontWeight="700"
            >{` (${getName()} ${getVersion()}) `}</Text>
            {` is not supported. Please use a different browser.`}
          </div>
        </Warning>
      )
    );
  };

  return {
    getName,
    getVersion,
    message,
    loginMessage,
  };
};

export default useBrowser;
