import React from 'react';
import Button from '../Button';
import { useThemeUI } from 'theme-ui';
import { useLocale } from '~common/locales';

const MemberLoginButton = props => {
  const { theme } = useThemeUI();
  const { getLocaleString } = useLocale();
  return (
    <Button
      {...props}
      data={{
        style: theme.memberLoginButtonStyle,
        link: {
          url: '/oms/sso/login',
        },
        text: getLocaleString('common.memberLoginButton'),
      }}
    />
  );
};

export default MemberLoginButton;
