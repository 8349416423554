import { isRunningOnClientSide } from '~lib/util';
import { useEffect } from 'react';

const useStoredSearch = ({ onInitialStoredSearch }) => {
  const getStoredSearch = () => {
    if (isRunningOnClientSide()) {
      const { hash = '' } = window.location;
      const [name, value] = hash.split('=');
      if (name === '#search') {
        return decodeURIComponent(value);
      }

      return undefined;
    }
    return undefined;
  };

  const setStoredSearch = searchText => {
    if (isRunningOnClientSide()) {
      window.location.assign(`#search=${encodeURIComponent(searchText)}`);
    }
  };

  useEffect(() => {
    const storedSearch = getStoredSearch();
    if (storedSearch && onInitialStoredSearch) {
      onInitialStoredSearch(storedSearch);
    }
  }, []);

  return {
    setStoredSearch,
    getStoredSearch,
  };
};

export default useStoredSearch;
