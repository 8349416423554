import React from 'react';
import Block from '../../../common/atoms/Block';
import { Flex } from 'rebass';
import NewsSideMenu from './NewsSideMenu';
import Article from './Article';
import { Footer, Header } from '../../../components/PageBlocks';
import CmsPage from '../../../components/Elements/CmsPage';
import ArticlesCarousel from './ArticlesCarousel';
import useClientRect from '~lib/hooks/useClientRect';

const Index = ({ pageContext }) => {
  const { ref, clientRect } = useClientRect({
    onChange: clientRect => ({
      width: document.documentElement.clientWidth - clientRect?.left - 70, // workaround for extra 70px that shows right navigation arrow
    }),
  });

  console.log({
    clientRect,
    ref,
  });

  return (
    <CmsPage pageContext={pageContext}>
      <Header data={pageContext.header} />
      <Block
        redefinePr={{
          xl: 0,
        }}
        redefinePl={{
          xl: 0,
        }}
      >
        <Flex
          flexDirection={{
            xs: 'column',
            tabletL: 'row',
          }}
        >
          <NewsSideMenu
            relatedArticles={pageContext.relatedArticles}
            mb={{
              xs: 24,
              tabletL: 0,
            }}
            pt={16}
            width={{
              xs: 1,
              tabletL: '40%',
              lg: '14%',
            }}
          />
          <Article
            ref={ref}
            pl={{
              xs: 0,
              tabletL: 80,
            }}
            nextArticles={pageContext.nextArticles}
            article={pageContext.article}
          />
        </Flex>
      </Block>
      {!!pageContext.nextArticles.length && (
        <ArticlesCarousel
          ml={{
            xs: 0,
            lg: clientRect?.left,
          }}
          width={{
            xs: 1,
            lg: clientRect?.width,
          }}
          mb={{
            xs: 60,
            tabletL: 80,
          }}
          articles={pageContext.nextArticles}
        />
      )}
      <Footer data={pageContext.footer} />
    </CmsPage>
  );
};

export default Index;
