import React from 'react';
import Link from '~common/atoms/Link';
import { Button } from '~common/atoms/Button';
import { useThemeUI } from 'theme-ui';

export default () => {
  const { theme } = useThemeUI();
  return (
    <>
      <Button
        variant={theme.upgradeCoverButtonStyle}
        size="small"
        css={{ minWidth: 230 }}
        as={Link}
        to={`/oms/my-cover/upgrade`}
      >
        You're eligible for a subsidised extras policy!
      </Button>
    </>
  );
};
