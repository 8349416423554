import React from 'react';
import { withTheme } from 'emotion-theming';

import { Box } from 'rebass';

import { H2 } from './typography';

const HeaderLine = ({ theme, title, children, ...props }) => {
  return (
    <Box mb="24px" {...props} css={{ position: 'relative' }}>
      <H2 color="primary" variant="semiBold">
        {title}
      </H2>
      {children}
    </Box>
  );
};

export default withTheme(HeaderLine);
