import { Field } from 'formik';
import CvvControl from '~OMS/payments/molecules/CvvControl';
import { Flex, Box } from 'rebass';
import { detectCard } from '~OMS/payments/cardDetector';
import React from 'react';
import InfoIcon from '~common/molecules/InfoIcon';
import { P3 } from '~common/atoms/typography';

export const Tooltip = (
  <Box p="2">
    <P3 color="white" variant="semiBold" mb="2">
      Visa®, Mastercard® cardholders:
    </P3>
    <P3 color="white">
      Turn your card over and look at the signature box. You should see either
      the entire 16-digit credit card number or just the last four digits
      followed by a special 3-digit code. This 3-digit code is your CVV number /
      Card Security Code.
    </P3>
  </Box>
);

const CvvField = ({ cardNumber }) => (
  <Field
    name="cvv"
    render={props => (
      <CvvControl
        label={
          <Flex alignItems="center">
            CVV <InfoIcon tooltip={Tooltip} position="left" ml={2} />
          </Flex>
        }
        labelPosition="left"
        card={detectCard(cardNumber)}
        {...props}
      />
    )}
  />
);

export default CvvField;
