import React from 'react';
import { useThemeUI } from 'theme-ui';
import Icon from '~common/atoms/Icon';
import { TextResponsive } from '~common/atoms/typography';
import { Button } from '~common/atoms/Button';
import WarningModal from './WarningModal';
import { EFC_MIN_AGE, EFC_UPPER_AGE_LIMIT } from '~lib/constants';

const EfcRemoveModal = ({ onReturn, onRemove }) => {
  const { theme } = useThemeUI();
  return (
    <WarningModal onConfirmRecalculateInfo={onRemove}>
      {({ showRecalculateInfo }) => (
        <>
          <Icon
            fill={theme.colors.error}
            name="stop-info"
            height="65px"
            width="65px"
          />
          <TextResponsive fontSize={31} lineHeight={1.2} my={32}>
            Extended Family Cover Upgrade (EFC)
          </TextResponsive>

          <TextResponsive lineHeight={1.2} fontSize={2} mb={32}>
            Your original quote was calculated based on Extended Family Cover,
            an optional upgrade that covers dependants over {EFC_MIN_AGE} who
            are not studying (until they turn {EFC_UPPER_AGE_LIMIT}).
          </TextResponsive>

          <TextResponsive bold fontSize={2} mb={20} lineHeight={1.2}>
            You have not entered any dependants over {EFC_MIN_AGE} that are not
            studying. Do you wish to remove Extended Family Cover?
          </TextResponsive>

          <Button
            width={1}
            variant="primary"
            mb={20}
            onClick={showRecalculateInfo}
          >
            Remove EFC
          </Button>
          <Button width={1} variant="primaryInverted" onClick={onReturn}>
            Add another dependant
          </Button>
        </>
      )}
    </WarningModal>
  );
};

export default EfcRemoveModal;
