import React, { useEffect } from 'react';
import HeaderLine from '~common/atoms/HeaderLine';
import { withTheme } from 'emotion-theming';
import ProcessPaymentForm from '~OMS/payments/organisms/ProcessPaymentForm';
import CenterBox from '~common/atoms/CenterBox';
import PaymentPolicyForm from '~OMS/payments/organisms/PaymentPolicyForm';
import ClaimPaymentForm from '~OMS/payments/organisms/ClaimPaymentForm';
import { Element, scroller } from 'react-scroll';
import { PaymentCheckProvider } from '~OMS/payments/context/PaymentCheckContext';
import useMembership from '~common/hooks/useMembership';

const scrollTo = name => {
  const commonConfig = {
    duration: 1000,
    delay: 0,
    isDynamic: true,
    offset: 0,
    smooth: 'easeInOutQuint',
  };
  const config = {
    claim: commonConfig,
    policy: {
      ...commonConfig,
      offset: 300,
    },
  }[name];

  scroller.scrollTo(name, config);
};

const ManagePayments = ({ theme, queryParams }) => {
  const { isS2IMembership } = useMembership();
  useEffect(() => {
    if (queryParams && queryParams.scroll) {
      scrollTo(queryParams.scroll);
    }
  }, [queryParams]);

  return (
    <>
      <HeaderLine mb={4} theme={theme} title="Payments"></HeaderLine>

      {isS2IMembership === false && (
        <Element name="policy">
          <CenterBox mb={4} flexDirection="column">
            <PaymentCheckProvider>
              <ProcessPaymentForm paymentResponse={queryParams} />
            </PaymentCheckProvider>
          </CenterBox>
        </Element>
      )}

      <PaymentPolicyForm mb={4} />

      <Element name="claim">
        <ClaimPaymentForm />
      </Element>
    </>
  );
};

export default withTheme(ManagePayments);
