/* eslint-disable consistent-return */
import React from 'react';
import * as Yup from 'yup';
import UpdateAddressFormFactory from './UpdateAddressFormFactory';
import ManualUpdateAddressFields from './ManualUpdateAddressFields';

// map data
const transformAddress = (address, type) => {
  if (type === 'address') {
    return {
      streetAddress: address.line1, // @TODO street address !== AddressLine - this can be empty
      suburb: address.suburb,
      state: address.state,
      postCode: address.postcode,
    };
  } else {
    return {
      postalStreetAddress: address.line1, // @TODO street address !== AddressLine - this can be empty
      postalSuburb: address.suburb,
      postalState: address.state,
      postalPostCode: address.postcode,
    };
  }
};
const AddressManualFields = ({ isPostalDifferentAsResidential }) => {
  return (
    <>
      <ManualUpdateAddressFields
        type="address"
        isPostalDifferentAsResidential={isPostalDifferentAsResidential}
      />
      {isPostalDifferentAsResidential && (
        <ManualUpdateAddressFields
          type="postalAddress"
          isPostalDifferentAsResidential={isPostalDifferentAsResidential}
        />
      )}
    </>
  );
};

const ManualUpdateAddressForm = UpdateAddressFormFactory(
  AddressManualFields,
  {
    streetAddress: '',
    suburb: '',
    state: 'NSW',
    postCode: '',
    postalStreetAddress: '',
    postalSuburb: '',
    postalState: 'NSW',
    postalPostCode: '',
    isPostalDifferentAsResidential: false,
  },
  Yup.object().shape({
    streetAddress: Yup.string().required('Street address is required'),
    suburb: Yup.string().required('Suburub is required'),
    state: Yup.string().required('State is required'),
    postCode: Yup.number().required('Postocode is required'),
    postalStreetAddress: Yup.string().required('Street address is required'),
    postalSuburb: Yup.string().required('Suburub is required'),
    postalState: Yup.string().required('State is required'),
    postalPostCode: Yup.number().required('Postocode is required'),
    isPostalDifferentAsResidential: Yup.boolean(),
  }),
  (
    address,
    postalAddress,
    detailedAddress,
    detailedPostalAddress,
    isPostalDifferentAsResidential,
    addressLine,
    postalAddressLine
  ) => {
    if (!detailedAddress) {
      // default is address
      return;
    }

    let tempAddress = '';
    let tempPostalAddress = '';

    if (isPostalDifferentAsResidential) {
      tempAddress = transformAddress(detailedAddress, 'address');
      tempAddress = transformAddress(
        { line1: '', suburb: '', state: '', postcode: '' },
        'postalAddress'
      );
    } else {
      // they are different, also tranform postalAddress
      tempAddress = transformAddress(detailedAddress, 'address');
      tempPostalAddress = transformAddress(
        detailedPostalAddress,
        'postalAddress'
      );
    }

    const { streetAddress, suburb, state, postCode } = tempAddress;
    const { postalStreetAddress, postalSuburb, postalState, postalPostCode } =
      tempPostalAddress;

    return {
      streetAddress, // AddressLine
      suburb, // Locality
      state, // State
      postCode, // Postcode
      postalStreetAddress,
      postalSuburb,
      postalState,
      postalPostCode,
      isPostalDifferentAsResidential: addressLine !== postalAddressLine,
    };
  }
);

export default ManualUpdateAddressForm;
