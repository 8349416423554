import styled from '@emotion/styled';
import Loading from '~common/atoms/Loading';

const StyledCard = styled.div`
  width: 100%;

  > * {
    min-height: auto !important;
  }
`;

const LoadingCard = () => {
  return (
    <StyledCard>
      <Loading size="small" />
    </StyledCard>
  );
};

export default LoadingCard;
