import { capitalize, chainedPath, pathOr } from '~lib/util';
import { whereEq, path, pipe, find } from 'lodash/fp';
import { useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useCoverProducts } from '~lib/CoverProducts';
import { ChangeMyCoverDetails } from 'queries/oms/mycover.graphql';
import useModalState from '~lib/hooks/useModalState';
import { ContactUsContext } from '~OMS/contact-us/ContactUsContext';

const toCover = ({ data } = {}) => {
  const plan = chainedPath('oms.membership.plan')(data);
  const membershipType = path('oms.membership.type')(data);
  const stateCode = pathOr('', 'oms.membership.membershipAddress.state')(data);
  return data
    ? {
        rateType: 'BOTH',
        rateCode: path('oms.membership.rate.id')(data),
        extrasProduct: path('oms.membership.rate.extras.code')(data),
        hospitalProduct: path('oms.membership.rate.hospital.code')(data),
        paymentFrequency: plan('paymentFrequency'),
        status: {
          value: membershipType || 'FAMILY',
          label: capitalize(membershipType),
        },
        dob: {
          value: pipe(
            pathOr([], 'oms.membership.persons'),
            find(
              whereEq({
                id: data.oms.user.personId,
              })
            ),
            pathOr('', ['dob'])
          )(data),
        },
        tier: {
          value: data.oms.membership.rebateTier,
        },
        region: {
          value: stateCode,
          label: stateCode,
        },
      }
    : undefined;
};

const useChangeCover = ({
  disableProductValidation,
  queryOnlyMembershipRates = false,
} = {}) => {
  const coverDetails = useQuery(ChangeMyCoverDetails);
  const [cover, setCover] = useState();
  const [membershipProducts, setMembershipProducts] = useState({});
  useEffect(() => {
    if (coverDetails?.data) {
      let value = toCover(coverDetails);
      if (queryOnlyMembershipRates) {
        value = {
          ...value,
          hospitalProducts: value.hospitalProduct && [value.hospitalProduct],
          extrasProducts: value.extrasProduct && [value.extrasProduct],
        };
      }
      setCover(value);
      setMembershipProducts({
        hospitalProduct: value.hospitalProduct,
        extrasProduct: value.extrasProduct,
      });
    }
  }, [coverDetails?.data]);
  const coverProducts = useCoverProducts(cover, {
    returnMembershipRates: true,
    disableProductValidation,
    onProductTypeChange: (_, rateType) => {
      const initialValues = toCover(coverDetails);
      const newCover = {
        ...cover,
        rateType,
      };

      const getProduct = type => {
        const productPropName = `${type}Product`;
        return {
          [productPropName]:
            cover[productPropName] || initialValues[productPropName],
          get [productPropName + 's']() {
            return queryOnlyMembershipRates
              ? this[productPropName] && [this[productPropName]]
              : undefined;
          },
        };
      };

      if (rateType === 'BOTH') {
        setCover({
          ...newCover,
          ...getProduct('extras'),
          ...getProduct('hospital'),
        });
      } else if (rateType === 'EXTRAS_ONLY') {
        setCover({
          ...newCover,
          ...getProduct('extras'),
          hospitalProduct: undefined,
          hospitalProducts: undefined,
        });
      } else {
        setCover({
          ...newCover,
          extrasProduct: undefined,
          extrasProducts: undefined,
          ...getProduct('hospital'),
        });
      }
    },
    onProductChange: (type, code) => {
      setCover(currentCover => {
        return {
          ...currentCover,
          [`${type}Products`]: queryOnlyMembershipRates ? [code] : undefined,
          [`${type}Product`]: code,
        };
      });
    },
  });
  const { openRequestCallback } = useContext(ContactUsContext);

  return {
    modal: useModalState(),
    requestCallback: {
      open: openRequestCallback,
    },
    coverProducts,
    cover,
    membershipProducts,
    loading: coverDetails.loading || path('quote.loading', coverProducts),
  };
};

export default useChangeCover;
