import React from 'react';
import Block from '~common/atoms/Block';
import { H2 } from '~common/atoms/typography';
import { ButtonsBase } from './Buttons';
import { Flex } from 'rebass';
import { path } from 'lodash/fp';
import { useColumnContext } from '~lib/providers/ColumnProvider';
import { useThemeUI } from 'theme-ui';
import HeroImage from './HeroImage';
import useHeroImage from '~common/hooks/useHeroImage';
import { useMobileScreenOnly } from '~lib/responsive';
import styled from '@emotion/styled';
import SanityImage from '~common/molecules/SanityImage';
import useHighlightedText from '~common/hooks/useHighlightedText';

const MobileImage = styled(SanityImage)`
  height: 250px;
`;

const BannerContent = ({ backgroundImage, textColor, actions, title }) => {
  return (
    <Flex
      maxHeight={backgroundImage ? 450 : undefined}
      width={1}
      flexDirection="column"
      alignItems={{
        xs: 'center',
        tabletP: backgroundImage ? 'flex-start' : 'center',
      }}
    >
      <H2
        variant="semiBold"
        mb={48}
        textAlign={{
          xs: 'center',
          tabletP: backgroundImage ? 'left' : 'center',
        }}
        color={textColor}
      >
        {title}
      </H2>

      <ButtonsBase size="large" data={actions} />
    </Flex>
  );
};

const BannerWithImage = ({
  margin,
  title,
  actions,
  theme,
  backgroundImage,
}) => {
  const columnContext = useColumnContext() || {};

  const { containerRef, containerWidth } = useHeroImage();
  const isMobile = useMobileScreenOnly();
  const highlightedTitle = useHighlightedText({
    text: title,
    color: theme.colors.secondary,
    wordsToHighlight: [2],
  });
  return (
    <>
      <Block
        ref={containerRef}
        ignoreCampaignLayout
        {...margin}
        innerProps={{
          display: 'flex',
          className: 'inner-container',
          flexDirection: {
            xs: 'column',
            tabletP: 'row',
          },
        }}
      >
        <Flex
          mt={{
            xs: 64,
            tabletP: 0,
          }}
          mb={{
            xs: 20,
            tabletP: 0,
          }}
          alignItems="center"
          width={{
            xs: '100%',
            tabletP: '50%',
          }}
          minWidth={{
            xs: '100%',
            tabletP: '50%',
          }}
        >
          <BannerContent
            backgroundImage={backgroundImage}
            theme={theme}
            actions={actions}
            textColor={theme.colors.primary}
            title={highlightedTitle}
          />
        </Flex>
        {!isMobile && (
          <HeroImage
            height={450}
            containerWidth={containerWidth}
            image={backgroundImage}
          />
        )}
      </Block>

      {isMobile && <MobileImage src={backgroundImage} />}
    </>
  );
};

const Banner = ({
  data: { title = 'center', actions, backgroundTheme, backgroundImage },
  next = {},
}) => {
  const { theme } = useThemeUI();

  const margin =
    path('_type')(next) === 'footerComponent'
      ? {
          mb: 0,
        }
      : undefined;

  return backgroundImage ? (
    <BannerWithImage
      backgroundImage={backgroundImage}
      title={title}
      actions={actions}
      theme={theme}
      margin={margin}
    />
  ) : (
    <Block
      py={{
        xs: 80,
        tabletP: 100,
      }}
      ignoreCampaignLayout
      {...margin}
      bg={
        {
          light: theme.colors.blue50,
          dark: theme.colors.blue600,
        }[backgroundTheme]
      }
      innerProps={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <BannerContent
        textColor={
          {
            light: theme.colors.light100,
            dark: theme.colors.white,
          }[backgroundTheme]
        }
        theme={theme}
        title={title}
        actions={actions}
      />
    </Block>
  );
};

export default Banner;
