import styled from '@emotion/styled';
import { themePirmaryText } from './colors';

const InputLabel = styled.label`
  font-size: ${props => props.theme.fontSizes[1]}px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: ${themePirmaryText};
  padding: 8px 0;
  display: block;
  ${props => props.labelCss}
`;

export default InputLabel;
