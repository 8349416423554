import React, { useContext, useMemo, createContext } from 'react';
import { whereEq } from 'lodash/fp';
const ColumnContext = createContext(undefined);

const ColumnProvider = ({ children, page, column, columns, index }) => {
  // const [state, setState] = useState();

  const sideMenuIsInPlay = useMemo(() => {
    return columns.some(column =>
      column.content.some(
        whereEq({
          _type: 'sideMenuComponent',
        })
      )
    );
  }, [columns]);

  return (
    <ColumnContext.Provider
      value={
        column && {
          columnData: column,
          columnIndex: index,
          sideMenuLayout: sideMenuIsInPlay && index === 1,
        }
      }
    >
      {children}
    </ColumnContext.Provider>
  );
};

export const useColumnContext = () => useContext(ColumnContext);

export { ColumnContext, ColumnProvider };
