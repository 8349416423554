import React from 'react';
import { Link } from 'gatsby';
import { withTheme } from 'emotion-theming';
import { Box, Button } from 'rebass';

import SSOFormContainer from '~OMS/styles/SSOFormContainer';
import SimpleSuccessHeader from '~common/atoms/SimpleSuccessHeader';
import SSOText from '~OMS/SSO/atoms/SSOText';
import CallUsNumber from '~common/molecules/CallUsNumber';
import { useMobileScreen } from '~lib/responsive';

function ResetEmailSentWrapper({ location = {}, ...props }) {
  const locationState = (location && location.state) || {};
  const isMobile = useMobileScreen();
  const callUsNumber = (
    <CallUsNumber
      css={{
        textDecoration: 'underline',
      }}
      inline
      bold
    />
  );
  return (
    <SSOFormContainer>
      <SimpleSuccessHeader withIcon title="Check your email" />

      <Box mt={3} mb={2}>
        <SSOText>
          Password reset instructions have been sent to your email address{' '}
          <strong>{locationState.email}</strong>.
        </SSOText>
      </Box>
      <Box width={1} mb="30px">
        <Button
          variant="primaryInverted"
          css={{ width: '100%' }}
          as={Link}
          state={locationState}
          to="/oms/sso/login"
        >
          Close window
        </Button>
      </Box>
      <Box width={1}>
        <Box css={{ textAlign: isMobile ? 'center' : 'left' }}>
          {isMobile ? (
            <>
              <Box>Still having problems?</Box> Talk to us on {callUsNumber}.
            </>
          ) : (
            <>Still having problems? Talk to us on {callUsNumber}.</>
          )}
        </Box>
      </Box>
    </SSOFormContainer>
  );
}

export default withTheme(ResetEmailSentWrapper);
