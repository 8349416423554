import React from 'react';
import { Flex, Box, Text } from 'rebass';
import { withTheme } from 'emotion-theming';
import Icon from '~common/atoms/Icon';
import styled from '@emotion/styled';

const Requirement = styled(Text)`
  color: ${props =>
    props.success ? props.theme.colors.green : props.theme.colors.error};
`;

function PasswordRequirement({ valid, rule, theme }) {
  return (
    <Flex alignItems="center" mb="2" mt="2">
      <Box mr={3}>
        <Icon
          height="10px"
          width="10px"
          name={valid ? 'circle-fill-tick' : 'circle-cross'}
          fill={valid ? theme.colors.green : theme.colors.error}
        />
      </Box>
      <Requirement fontSize={1} success={valid}>
        {rule}
      </Requirement>
    </Flex>
  );
}

export default withTheme(PasswordRequirement);
