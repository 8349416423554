import React from 'react';
import BsbControl from '~OMS/payments/molecules/BsbControl';
import {
  InputControl,
  NumberInputControl,
} from '~common/molecules/form-controls';
import PaymentFrequencyField from './PaymentFrequencyField';
import PreferredDebitDayField from './PreferredDebitDayField';

const BankAccountForm = ({
  Field,
  handleChange,
  debitDayDifferentToStartCoverDate,
}) => {
  return (
    <>
      <Field
        onChange={handleChange}
        name="bsb"
        label="BSB"
        component={BsbControl}
        focus
      />
      <Field
        onChange={handleChange}
        name="accountNumber"
        bold
        label="Account number"
        maxDigits={9}
        component={NumberInputControl}
      />
      <Field
        onChange={handleChange}
        name="accountName"
        bold
        label="Account name"
        component={InputControl}
        trim
      />

      <PaymentFrequencyField onChange={handleChange} />
      <PreferredDebitDayField
        debitDayDifferentToStartCoverDate={debitDayDifferentToStartCoverDate}
        onChange={handleChange}
      />
    </>
  );
};

export default BankAccountForm;
