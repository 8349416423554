import React from 'react';
import { path } from 'lodash/fp';
import {
  DatePickerControl,
  FancyCheckboxControl,
} from '~common/molecules/form-controls';
import defaultYesNoOptions from '../../common/defaultYesNoOptions';
import { Box } from 'rebass';
import CurrentHealthFundFields from '../../common/components/CurrentHealthFundFields';
import AdditionalInfo from '~common/atoms/AdditionalInfo';
import AnotherFundTransfer from '../../common/components/AnotherFundTransfer';
import { isStartCoverDateValid } from '../../validation/yourDetailsValidation';
import PromotionsBanner from '~common/molecules/PromotionsBanner';

const PrivateHealthDetailsForm = ({
  Field,
  promotions,
  handleChange,
  values,
  isFamilyPackage,
  criteria = {},
  appFormContext,
  isLEC,
}) => {
  const isTopCover =
    path('state.productSelections.hospital.variant.code')(appFormContext) ===
    'TC';

  const partnerAndDependentsCoveredLabels = {
    COUPLE: 'Is your partner covered by this policy?',
    SINGLE_PARENT: 'Are your dependents covered by this policy?',
    FAMILY: 'Is your partner and dependents covered by this policy?',
  };

  const partnerAndDependentsTransferringFromAnotherFundLabels = {
    COUPLE: 'Is your partner transferring from another health fund?',
    SINGLE_PARENT: 'Are your dependents transferring from another fund?',
    FAMILY: 'Is your partner and dependents transferring from another fund?',
  };

  const partnerAndDependentsTransferringFromAnotherFundLabels2 = {
    COUPLE: 'Is your partner transferring from another health fund?',
    SINGLE_PARENT:
      'Are they transferring from another private health insurance fund?',
    FAMILY: 'Are they transferring from another private health insurance fund?',
  };

  const showPartnerLhcMessage =
    values.partnerAndDependentsTransferringFromAnotherFund === false &&
    path('partner.lhcExempt')(criteria) === false;

  return (
    <>
      <Field
        name="startCoverDate"
        label="When would you like to start your cover?"
        component={DatePickerControl}
        onChange={handleChange}
        disabledDate={value => {
          return !isStartCoverDateValid(value);
        }}
      />
      {path('data.eligible')(promotions) &&
        !appFormContext.state.isUpgradeCover && (
          <PromotionsBanner content={promotions.data.content.joinDateMessage} />
        )}

      <AnotherFundTransfer
        Field={Field}
        name="isTransferringFromAnotherFund"
        label="Are you transferring from another fund?"
        lhcMessage={
          criteria.lhcExempt === false && appFormContext.messages.noOtherFundLhc
        }
        onChange={handleChange}
      />

      {values.isTransferringFromAnotherFund === false && isFamilyPackage && (
        <AnotherFundTransfer
          Field={Field}
          name="partnerAndDependentsTransferringFromAnotherFund"
          label={
            partnerAndDependentsTransferringFromAnotherFundLabels[
              appFormContext.state.status
            ]
          }
          onChange={handleChange}
          lhcMessage={
            path('partner.lhcExempt')(criteria) === false &&
            appFormContext.messages.partnerLhcMessage
          }
        />
      )}

      {isTopCover && values.isTransferringFromAnotherFund && (
        <AdditionalInfo mb={4}>
          If you are transferring from another Gold level policy, you will need
          to serve waiting periods before you can claim the AMA benefit.
          However, you will not need to serve waiting periods to be covered up
          to the Access Gap Cover Schedule.
        </AdditionalInfo>
      )}

      {values.isTransferringFromAnotherFund && (
        <>
          <Box pb={!isFamilyPackage ? '10px' : 0}>
            <CurrentHealthFundFields
              Field={Field}
              onChange={handleChange}
              names={{
                fund: 'currentHealthFund',
                membershipNumber: 'membershipNumber',
              }}
            />
          </Box>

          {isFamilyPackage && (
            <>
              <Field
                name="partnerAndDependentsCovered"
                label={
                  partnerAndDependentsCoveredLabels[appFormContext.state.status]
                }
                type="secondary"
                component={FancyCheckboxControl}
                options={defaultYesNoOptions}
                onChange={handleChange}
              />

              {values.partnerAndDependentsCovered === false && (
                <Field
                  onChange={handleChange}
                  label={
                    partnerAndDependentsTransferringFromAnotherFundLabels2[
                      appFormContext.state.status
                    ]
                  }
                  name="partnerAndDependentsTransferringFromAnotherFund"
                  component={FancyCheckboxControl}
                  options={[
                    {
                      label: 'Yes, different policy to me',
                      value: true,
                    },
                    {
                      label: 'No',
                      value: false,
                    },
                  ]}
                />
              )}
              {showPartnerLhcMessage && (
                <AdditionalInfo mt={2}>
                  {appFormContext.messages.partnerLhcMessage}
                </AdditionalInfo>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(PrivateHealthDetailsForm);
