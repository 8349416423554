import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import NumberFormat from 'react-number-format';
import { toWordCase } from '../../shared/utils';
import MobileActions from '../MobileActions';
import Popover from 'components/Elements/Popover';
import Icon from '~common/atoms/Icon';

const StyledArticle = styled.article`
  border: 2px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white} !important;
`;

const StyledContainer = styled.div`
  padding: 24px;
`;

const StyledTitle = styled.h3`
  font-weight: 700;
  color: ${props => props.theme.colors.white} !important;
`;

const StyledSubtitle = styled.h3`
  color: ${props => props.theme.colors.white} !important;
`;

const StyledPrice = styled.div`
  margin-top: 16px;
  font-weight: 400;
  font-size: ${props => props.theme.fontSizes[3]}px;
  line-height: 36px;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  span {
    font-weight: 600;
    font-size: ${props => props.theme.fontSizes[12]}px;
    line-height: 41px;
    margin-right: 4px;
  }
`;

const StyledTier = styled.p`
  margin-top: 4px;
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 18px;
  color: ${props => props.theme.colors.white} !important;
`;

const StyledCallout = styled.div`
  margin-top: 16px;
  position: relative;
  border-radius: 4px;
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.12);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 4px;
    background-color: rgba(255, 255, 255, 0.2);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  > div,
  a,
  span {
    font-size: ${props => props.theme.fontSizes[2]}px;
    line-height: 18px;
    color: ${props => props.theme.colors.white} !important;
  }

  a,
  span {
    font-weight: 600;
    text-decoration: none !important;

    &:hover,
    &:focus {
      text-decoration: underline !important;
    }
  }
`;

const StyledPopover = styled(Popover)`
  display: inline;
`;

const StyledDiscount = styled.div`
  margin-top: 16px;
  display: flex;
  position: relative;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  p {
    padding-left: 8px;
    font-size: ${props => props.theme.fontSizes[2]}px;
    line-height: 18px;
    font-weight: 600;
    color: ${props => props.theme.colors.white} !important;
  }
`;

const TotalPrice = ({ paymentFrequency, price }) => {
  return (
    <StyledPrice>
      <NumberFormat
        data-id="quote-total-price"
        decimalScale={2}
        displayType={'text'}
        fixedDecimalScale={true}
        prefix={'$'}
        thousandSeparator={true}
        value={price}
      />
      {toWordCase(paymentFrequency)}
    </StyledPrice>
  );
};

const TierInformation = ({ rebatePercentage, tier }) => {
  const tierLevel = tier !== '0' ? `tier ${tier}` : 'base tier';
  const rebateValue = (
    <NumberFormat
      decimalScale={3}
      displayType={'text'}
      suffix={'%'}
      thousandSeparator={true}
      value={rebatePercentage}
    />
  );

  return (
    <StyledTier>
      Includes a {tierLevel} rebate of {rebateValue}
    </StyledTier>
  );
};

const Callout = ({ isYouth }) => {
  const youthDiscountPopover = (
    <StyledPopover
      position="bottom"
      content="18 to 29 year-olds are entitled to a discount of up to 10% of
      their hospital insurance premium. Call 1800 226 126 to find out if you
      are eligible for a discount."
    >
      <span>youth discount</span>
    </StyledPopover>
  );

  const lifetimeHealthCoverPopover = (
    <StyledPopover
      position="bottom"
      content="A 2% Lifetime Health Cover (LHC) loading is added to your hospital
      premium for every year you didn't hold hospital cover after you turn 31.
      If you do not know your applicable loading %, this will be applied
      automatically once we process your application."
    >
      <span>lifetime health cover</span>
    </StyledPopover>
  );

  return (
    <StyledCallout>
      {isYouth ? (
        <div role="figure">
          You're entitled to a {youthDiscountPopover} of up to 10% of your
          hospital premium! This discount is not reflected in the quote shown
          above but will be applied once we receive your application.
        </div>
      ) : (
        <div role="figure">
          This quote does not include any applicable {youthDiscountPopover} or{' '}
          {lifetimeHealthCoverPopover} loading and may differ from your final
          premium.
        </div>
      )}
    </StyledCallout>
  );
};

const Discount = ({ paymentFrequency }) => {
  return (
    <StyledDiscount>
      <Icon name="discount" width="16px" height="16px" fill={'#fff'} />
      <p>
        {paymentFrequency === 'YEARLY'
          ? 'Your quote includes a discount of 2.5% as you have selected to pay annually'
          : 'Pay annually and receive a 2.5% discount'}
      </p>
    </StyledDiscount>
  );
};

const TotalCard = ({
  isYouth,
  onApply,
  onEmailQuote,
  paymentFrequency,
  price,
  rebatePercentage,
  subtitle,
  tier,
  title,
}) => {
  return (
    <StyledArticle data-id="total-card">
      <StyledContainer>
        <StyledTitle>{title}</StyledTitle>
        <StyledSubtitle>{subtitle}</StyledSubtitle>
        <TotalPrice
          data-id="total-price"
          paymentFrequency={paymentFrequency}
          price={price}
        />
        <TierInformation rebatePercentage={rebatePercentage} tier={tier} />
        <Callout isYouth={isYouth} />
        <Discount paymentFrequency={paymentFrequency} />
        <MobileActions onApply={onApply} onEmailQuote={onEmailQuote} />
      </StyledContainer>
    </StyledArticle>
  );
};

TotalCard.propTypes = {
  isYouth: PropTypes.bool.isRequired,
  onApply: PropTypes.func.isRequired,
  onEmailQuote: PropTypes.func.isRequired,
  paymentFrequency: PropTypes.string.isRequired,
  price: PropTypes.number,
  rebatePercentage: PropTypes.number.isRequired,
  subtitle: PropTypes.string.isRequired,
  tier: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default TotalCard;
