import React from 'react';

import { Footer, Header } from 'components/PageBlocks';
import RequestAccountDeleteForm from 'components/RequestAccountDeleteForm';
import Block from '~common/atoms/Block';
import Layout from 'components/Layout';

const RequestDeletePage = ({ pageContext }) => {
  return (
    <Layout>
      <Header
        metaData={{
          page: {
            _id: 'request-account-deletion',
          },
        }}
        data={pageContext.header}
      />
      <Block
        innerProps={{
          maxWidth: '768px',
          minHeight: 'calc(100vh - 500px)',
        }}
      >
        <RequestAccountDeleteForm />
      </Block>
      <Footer data={pageContext.footer} />
    </Layout>
  );
};

export default RequestDeletePage;
