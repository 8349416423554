import React from 'react';
import styled from '@emotion/styled';
import Loading from '~common/atoms/Loading';
import { withTheme } from 'emotion-theming';
import ExtrasInfo from '~OMS/claims/molecules/ExtrasInfo';
import UsageInfo from '~OMS/claims/molecules/UsageInfo';
import ResponsiveFlex from '~common/atoms/ResponsiveFlex';
import useClaimsExtras from '~OMS/claims/hooks/useClaimsExtras';

const StyledClaimsExtras = styled(ResponsiveFlex)`
  border-radius: 5px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.colors.background.base};
`;

const StyledUsageInfo = styled(UsageInfo)`
  ${props => props.theme.mq.mdInverted} {
    position: relative;
    top: -5px;
  }
`;

const width = {
  xs: 1,
  md: '45%',
};

const ClaimsExtras = ({ theme, ...props }) => {
  const { state, actions } = useClaimsExtras();
  const { selectedUsage, usage = [], loading } = state;
  const { setSelectedUsage } = actions;

  return usage.length ? (
    <StyledClaimsExtras
      alignItems="flex-start"
      justifyContent="space-between"
      theme={theme}
      px={{
        xs: 3,
        md: 5,
      }}
      py={{
        xs: 4,
        md: 5,
      }}
      {...props}
    >
      <ExtrasInfo
        text={state.extrasPromoText}
        mb={{
          xs: 5,
          md: 0,
        }}
        width={width}
      />
      {loading ? (
        <Loading />
      ) : (
        <StyledUsageInfo
          theme={theme}
          width={width}
          usage={usage}
          onSelect={setSelectedUsage}
          selected={selectedUsage}
        />
      )}
    </StyledClaimsExtras>
  ) : null;
};

export default withTheme(ClaimsExtras);
