import React from 'react';
import { H3 } from '~common/atoms/typography';
import CallUsNumber from '~common/molecules/CallUsNumber';
import { WorkingTimeSimple } from '~common/molecules/WorkingTime';

const NeedsAssistanceMessage = () => {
  return (
    <>
      <H3 variant="semiBold" color="primary">
        Your application requires assistance from one of our sales agents,
        please call us on{' '}
        <CallUsNumber render={({ phone }) => <strong>{phone}</strong>} />{' '}
        between{' '}
        <WorkingTimeSimple
          render={({ hours, timezone, days }) => (
            <>
              {hours.from} - {hours.to} {timezone}, {days}.
            </>
          )}
        />
      </H3>
    </>
  );
};

export default NeedsAssistanceMessage;
