import React from 'react';
import usePaymentChecks from '~OMS/payments/hooks/usePaymentChecks';

const PaymentCheckContext = React.createContext({});

const PaymentCheckProvider = ({ children }) => {
  const paymentChecks = usePaymentChecks();

  return (
    <PaymentCheckContext.Provider value={paymentChecks}>
      {children}
    </PaymentCheckContext.Provider>
  );
};

export { PaymentCheckContext, PaymentCheckProvider };
