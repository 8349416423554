import React, { useCallback, useRef } from 'react';
import { Flex, Box } from 'rebass';
import { Field as FormikField } from 'formik';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useThemeUI } from 'theme-ui';
import { HORIZONTAL_FORM, NumberInputControl } from './form-controls';
import { windowHasSelection } from '~lib/util';
import FormLabel from '~common/molecules/FormLabel';

const BirthInput = styled(NumberInputControl)`
  text-align: left;
  width: ${props => props.width};

  ::placeholder {
    font-weight: normal;
    text-align: center;
    color: #dfdede;
  }
  ::-webkit-input-placeholder {
    font-weight: normal;
    text-align: center;
    color: #dfdede;
  }
  ::-ms-input-placeholder {
    font-weight: normal;
    text-align: center;
    color: #dfdede;
  }
`;

const DobFieldsWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-column-gap: 8px;
`;

function DateOfBirth({
  Field: CustomField,
  disabled,
  dayField = { name: 'day' },
  monthField = { name: 'month' },
  yearField = { name: 'year' },
  label = 'Date of birth',
  labelStyle,
  labelPosition,
  focus,
  className,
}) {
  const { theme } = useThemeUI();
  const inputsWrapper = useRef(null);
  const Field = CustomField || FormikField;
  const elem = useCallback(name => {
    return inputsWrapper.current.querySelector(`[name=${name}]`);
  }, []);

  const select = useCallback(
    name => {
      const elemToSelect = elem(name);
      elemToSelect.focus();
      elemToSelect.select();
    },
    [elem]
  );

  const autoFocusNextField = useCallback(
    (event, max) => {
      if (event.target.value.length === max) {
        switch (event.target) {
          case elem(dayField.name): {
            select(monthField.name);
            break;
          }
          case elem(monthField.name): {
            select(yearField.name);
            break;
          }
          default: {
          }
        }
      }
    },
    [elem, select]
  );

  const handleKeyDown = useCallback(
    (e, max) => {
      const nativeEvent = e.nativeEvent;
      if (nativeEvent.code !== 'Enter' && !windowHasSelection()) {
        setTimeout(() => {
          autoFocusNextField(nativeEvent, max);
        }, 0);
      }
    },
    [autoFocusNextField]
  );

  return (
    <Flex
      flexDirection={{
        xs: 'column',
        tabletL: labelPosition === 'left' ? 'row' : 'column',
      }}
      className={className}
    >
      {label && (
        <Box
          css={css`
            ${theme.mq.tabletL} {
              transform: translateY(${labelPosition === 'left' ? '33px' : 0});
            }
          `}
          mb="18px"
          width={labelPosition === 'left' ? HORIZONTAL_FORM.labelWidth : 1}
        >
          <FormLabel style={{ ...labelStyle }}>{label}</FormLabel>
        </Box>
      )}
      <DobFieldsWrapper className="dob-fields-wrapper" ref={inputsWrapper}>
        {dayField !== false && (
          <Box className="dob-field-wrapper">
            <Field
              disabled={disabled}
              name="day"
              label="Day"
              placeholder="DD"
              component={BirthInput}
              maxDigits={2}
              onKeyDown={e => handleKeyDown(e, 2)}
              realNumber
              {...dayField}
            />
          </Box>
        )}
        {monthField !== false && (
          <Box className="dob-field-wrapper">
            <Field
              disabled={disabled}
              name="month"
              label="Month"
              placeholder="MM"
              maxDigits={2}
              component={BirthInput}
              onKeyDown={e => handleKeyDown(e, 2)}
              realNumber
              {...monthField}
            />
          </Box>
        )}
        <Box className="dob-field-wrapper">
          {yearField !== false && (
            <Field
              disabled={disabled}
              name="year"
              label="Year"
              placeholder="YYYY"
              component={BirthInput}
              maxDigits={4}
              onKeyDown={e => handleKeyDown(e, 4)}
              realNumber
              {...yearField}
            />
          )}
        </Box>
      </DobFieldsWrapper>
    </Flex>
  );
}

export default DateOfBirth;
