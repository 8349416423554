import React from 'react';
import { withTheme } from 'emotion-theming';

import Layout from 'components/Layout';
import Navbar from '~common/organisms/Navbar';
import Wrapper from '~common/molecules/Wrapper';

import CheckMembership from '~common/organisms/CheckMembership';
import OmsFooter from '~OMS/OmsFooter';
import CompareCover from '~OMS/my-cover/templates/CompareCover';
import allow from '~lib/hocs/allow';
import { isChangeCoverAllowed } from '~OMS/response-selectors';

const ChangeMyCover = props => {
  return (
    <Layout>
      <CheckMembership>
        <Navbar location={props.location} />
        <Wrapper minHeight="100vh" pb={0}>
          <CompareCover />
        </Wrapper>
        <OmsFooter />
      </CheckMembership>
    </Layout>
  );
};

export default withTheme(
  allow({
    to: data =>
      isChangeCoverAllowed({
        oms: data,
      }),
  })(ChangeMyCover)
);
