import React from 'react';
import { Flex, Box } from 'rebass';
import { useQuery } from '@apollo/client';

import IconHeading from '~common/atoms/IconHeading';
import CircleArcGraph from '~common/molecules/CircleArcGraph';
import Link from '~common/atoms/Link';
import Loading from '~common/atoms/Loading';
import { DashboardExtrasUsageQuery } from 'queries/oms/dashboard';
import { Button } from '~common/atoms/Button';

const CardExtrasUsage = () => {
  const { loading, error, data } = useQuery(DashboardExtrasUsageQuery);

  if (error) {
    console.error(error);
    return null;
  }

  const getGraphData = () => {
    if (loading) {
      return [];
    }
    const user = data.oms.user;
    const graph = data.oms.extrasUsage?.graphs?.find(
      graph => graph.id === user.personId
    );

    return (graph && graph.rows) || [];
  };

  return (
    <Flex
      flexDirection="column"
      css={{ height: '100%' }}
      justifyContent="space-between"
    >
      <Box>
        <IconHeading icon="oms-usage" text="Extras Remaining" />
      </Box>

      {loading ? (
        <Loading />
      ) : (
        <>
          <Box>
            <CircleArcGraph data={getGraphData()} />
          </Box>

          <Button
            width={1}
            variant="secondary"
            size="medium"
            as={Link}
            to="/oms/extras-usage"
          >
            View Limits &amp; usage
          </Button>
        </>
      )}
    </Flex>
  );
};

export default CardExtrasUsage;
