import React from 'react';
import PropTypes from 'prop-types';
import Section from './Section';
import ScheduleCallButton from './ScheduleCallButton';
import { noop } from '~lib/util';
import Logo from '~common/atoms/Logo';

const Header = ({ setHeaderHeight = noop, fixed = true, logo, ...props }) => {
  return (
    <Section fixed={fixed} setHeaderHeight={setHeaderHeight} {...props}>
      <Logo {...logo} />
      <ScheduleCallButton />
    </Section>
  );
};

Header.propTypes = {
  setHeaderHeight: PropTypes.func.isRequired,
};

export default Header;
