import React from 'react';
import { Footer, Header } from '../../components/PageBlocks';
import { TitleBase } from '../../components/PageBlocks/Title';
import { StandardText } from '~common/atoms/typography';
import NewsGrid from './NewsGrid';
import Block from '~common/atoms/Block';
import CmsPage from '../../components/Elements/CmsPage';
import { useLocale } from '~common/locales';

const NewsIndex = ({ pageContext }) => {
  const { getLocaleString } = useLocale();
  return (
    <CmsPage pageContext={pageContext}>
      <Header data={pageContext.header} />
      <TitleBase mb={14} textAlign="center">
        News
      </TitleBase>
      <Block mb={60}>
        <StandardText fontSize={20} textAlign="center" color="dark">
          The latest news from {getLocaleString('common.organisationName')} and
          the health insurance industry.
        </StandardText>
      </Block>
      <NewsGrid articles={pageContext.articles} />
      <Footer data={pageContext.footer} />
    </CmsPage>
  );
};

export default NewsIndex;
