import React from 'react';
import { Flex } from 'rebass';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Popover from 'components/Elements/Popover';
import Icon from '~common/atoms/Icon';
import { path } from 'lodash/fp';
import { useThemeUI } from 'theme-ui';

import Legend from './InclusionsLegend';
import { useInclusionsByTierQuery } from '~lib/hooks/sanity/useInclusionsQuery';
import { renderFeaturedTiers } from 'components/Quote/QuoteResultsNew/shared/utils';

const StyledLink = styled.a`
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes[2]}px;
  font-weight: 700;
  text-align: center;
`;

const Table = styled.table`
  width: 100%;
  border: none;
  table-layout: fixed;

  thead,
  tbody,
  tfoot,
  tr {
    width: 100%;
  }

  th,
  td {
    font-size: ${props => props.theme.fontSizes[1]}px;
    font-weight: 400;
    line-height: 1.66;
    color: #4a4a4a;
    text-align: center;
  }

  thead > tr {
    th {
      position: sticky;
      top: ${props => (props.topOffset ? `${props.topOffset}px` : '76px')};
      background-color: #fff;
      height: 64px;
      border-bottom: 1px solid #dfdede;
      line-height: 30px;
      vertical-align: middle;

      a {
        display: flex;
        height: 64px;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        strong {
          font-weight: 700;
          margin-right: 3px;
        }
      }

      &.cell-wide {
        width: 40%;
      }
    }
  }

  tbody {
    > tr {
      td {
        padding: 12px 0 12px 0;
        border-bottom: 1px solid #dfdede;
      }

      td[colspan] {
        text-align: left;
        font-size: 15px;
        line-height: 1.2;
        font-weight: 700;
        padding: 12px 10px 0 10px;

        &.tier-cell {
          font-size: 12px;
          font-weight: 700;
          padding: 0;

          div {
            padding: 8px 10px;
          }
        }

        &.cell-pad-top {
          padding-top: 22px;
        }
      }

      td[colspan] {
        border-bottom: none;
      }
    }
  }
`;

const SingleIconWithTooltip = ({ name, fillColor }) => (
  <Popover
    position="top"
    content={
      <Legend isSingleIcon={true} iconName={name} style={{ padding: '0px' }} />
    }
    theme="dark"
    isSingleIcon={true}
  >
    <Icon
      name={name}
      fill={fillColor}
      width="17px"
      height="17px"
      style={{ margin: '0 auto' }}
    />
  </Popover>
);

const Inclusions = props => {
  const { isSticky, inclusionWidth } = props;
  const { theme } = useThemeUI();

  const inclusionTiers = useInclusionsByTierQuery(props.type);

  const hasTiers = Object.keys(inclusionTiers).length > 1;

  const tierMap = {
    featured: {
      label: 'Featured tier inclusions',
      color: '#f6f6f6',
    },
    basic: {
      label: 'Basic tier inclusions',
      color: '#f6f6f6',
    },
    bronze: {
      label: 'Bronze tier inclusions',
      color: '#f0d8c1',
    },
    silver: {
      label: 'Silver tier inclusions',
      color: '#e6e6e6',
    },
    gold: {
      label: 'Gold tier inclusions',
      color: '#eee5b4',
    },
  };

  const renderInclusions = ({ tier, nodes, label, color }) => {
    return (
      <React.Fragment key={tier}>
        {hasTiers && tier && tier !== 'other' && (
          <tr>
            <td
              style={{ backgroundColor: color }}
              className="tier-cell"
              colSpan={props.products.length}
            >
              <div>{label}</div>
            </td>
          </tr>
        )}
        {nodes.map((node, i) => {
          return (
            <React.Fragment key={node.id}>
              <tr>
                <td
                  className={`product-cell ${
                    !i && !hasTiers && 'cell-pad-top'
                  }`}
                  colSpan={props.products.length}
                >
                  {node.title}
                </td>
              </tr>
              <tr>
                {props.products.map(p => {
                  const productInclusion = p.inclusions.find(
                    pi => pi.inclusion._id === node.id
                  );

                  const inclusionType =
                    productInclusion && productInclusion.type.toLowerCase();

                  if (
                    inclusionType === 'included' ||
                    inclusionType === 'included-p'
                  ) {
                    return (
                      <td key={p.id}>
                        <SingleIconWithTooltip
                          name="circle-fill-tick"
                          fillColor="#569b07"
                        />
                        {inclusionType === 'included-p' ? <sup>**</sup> : null}
                      </td>
                    );
                  }

                  if (inclusionType === 'restricted') {
                    return (
                      <td key={p.id}>
                        <SingleIconWithTooltip
                          name="restricted"
                          fillColor="#f5a623"
                        />
                      </td>
                    );
                  }

                  if (inclusionType === 'restricted-accident') {
                    return <td key={p.id}>accident only</td>;
                  }

                  if (inclusionType === 'excluded') {
                    return (
                      <td key={p.id}>
                        <SingleIconWithTooltip
                          name="remove"
                          fillColor="#d0021b"
                        />
                      </td>
                    );
                  }
                })}
              </tr>
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  return (
    <>
      <Table topOffset={props.topOffset}>
        <thead>
          <tr
            css={
              isSticky
                ? css`
                    position: fixed;
                    top: 50px;
                    z-index: 20;
                    max-width: ${inclusionWidth}px;
                  `
                : css``
            }
          >
            {props.products.map(product => (
              <th
                key={product.id}
                css={css`
                  ${!!isSticky &&
                  `width: ${
                    props.products.length === 3
                      ? inclusionWidth / 3
                      : inclusionWidth / 2
                  }px`};
                  ${!!isSticky &&
                  'box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);'};
                  z-index: ${isSticky ? 20 : 10};
                  cursor: pointer;
                `}
                style={{
                  backgroundColor:
                    product.id === props.product.id
                      ? theme.colors[props.product.type] || theme.colors.primary
                      : theme.colors.white,
                  color:
                    product.id === props.product.id
                      ? theme.colors.white
                      : theme.colors.text.dark,
                }}
                className={
                  product.id === 'smart-starter' ? 'cell-wide' : 'cell-normal'
                }
              >
                <Flex
                  onClick={() => {
                    props.onProductChange(path('variants[0].code', product));
                  }}
                  flexDirection="column"
                  css={{
                    color: 'inherit',
                  }}
                >
                  <>
                    <strong
                      css={css`
                        display: block;
                        line-height: 16px;
                      `}
                    >
                      {product.title}{' '}
                    </strong>
                    <span
                      css={css`
                        display: block;
                        line-height: 16px;
                      `}
                    >
                      {product.type === 'hospital' && product.coverage}
                    </span>
                  </>
                </Flex>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {renderFeaturedTiers(inclusionTiers)(renderInclusions)}

          {['basic', 'bronze', 'silver', 'gold', 'other'].map(tier => {
            const nodes = inclusionTiers[tier];
            if (nodes) {
              return renderInclusions({
                tier: tier,
                nodes: nodes,
                label: tierMap[tier]?.label,
                color: tierMap[tier]?.color,
              });
            }

            return null;
          })}
        </tbody>
      </Table>
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        bg="white"
        py="32px"
        pb={{ xs: '64px', lg: '32px' }}
      >
        <StyledLink href={`/${props.product.id}`} target="_blank">
          Learn more about {props.product.type} cover
        </StyledLink>
      </Flex>
    </>
  );
};

export default Inclusions;
