import React from 'react';
import { Flex } from 'rebass';

export const quoteBlockSpacing = {
  pl: {
    xs: '4.2%',
    tabletP: '6%',
    tabletL: '10%',
    lg: '16.6%',
  },
  get pr() {
    return this.pl;
  },
};

const QuoteBlock = props => {
  return <Flex {...quoteBlockSpacing} {...props} />;
};

export default QuoteBlock;
