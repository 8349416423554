import { SubmitMafMutation } from '../../../../queries/maf.graphql';
import { openModal, serializeMaf } from '../../common/appFormUtills';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { path } from 'lodash/fp';
import ErrorModal from '../../common/components/modals/ErrorModal';

export default ({
  clearApplicationForm,
  submitMutation = SubmitMafMutation,
  criteria,
  formId,
  steps,
}) => {
  const [applicationSent, setApplicationSent] = useState(false);
  const [submitMaf, { data, error: submitError }] = useMutation(submitMutation);
  const [submitting, setSubmitting] = useState(false);

  const onSubmitted = () => {
    setApplicationSent(true);
    setSubmitting(false);
    clearApplicationForm();
  };

  const submitApplicationForm = async () => {
    setSubmitting(true);
    const payload = await serializeMaf({ steps, formId, criteria });
    submitMaf({
      variables: {
        input: payload,
      },
    })
      .then(onSubmitted)
      .catch(error => {
        setSubmitting(false);
        if (
          path('graphQLErrors[0].extensions.exception.name')(error) ===
            'SugarError' &&
          path('graphQLErrors[0].message')(error) !==
            'No response from SugarCRM'
        ) {
          onSubmitted();
        } else {
          openModal(
            ({ close }) => (
              <ErrorModal
                title="Submitting application form failed!"
                onOk={close}
                isSaveForLater={false}
              />
            ),
            {
              showCloseIcon: true,
              padding: true,
            }
          );
        }
      });
  };

  return {
    submitApplicationForm,
    submitting,
    applicationSent,
    submitError,
    reference: path('submitMaf.reference')(data),
  };
};
