import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import useEventListener from '~lib/hooks/useEventListener';

const StyledBlock = styled.div`
  width: 100%;
  position: fixed;
  top: -300px; // Stops brief flash on page load
  top: ${props =>
    props.showFloatingSummary
      ? props.headerHeight
      : `-${props.headerHeight}`}px;
  padding: 12px 0;
  background-color: ${props => props.theme.colors.primary};
  z-index: 4;
  transition: 0.5s top ease-in-out;
`;

const StyledContainer = styled.div`
  padding-left: ${props => props.theme.sizes.gap};
  padding-right: ${props => props.theme.sizes.gap};
  max-width: ${props => props.theme.sizes.container};
  width: 100%;
  margin: 0 auto;
`;

const Section = ({
  children,
  headerHeight,
  setSummaryHeight,
  showFloatingSummary,
}) => {
  // get summary element height for dynamic position of
  // cover browser inclusion thead
  const ref = useRef(null);
  const onResizeChange = () => {
    setSummaryHeight(ref.current?.clientHeight);
  };
  useEffect(onResizeChange, [ref.current?.clientHeight]);
  useEventListener('resize', onResizeChange);

  return (
    <StyledBlock
      aria-hidden
      ref={ref}
      headerHeight={headerHeight}
      showFloatingSummary={showFloatingSummary}
    >
      <StyledContainer>{children}</StyledContainer>
    </StyledBlock>
  );
};

Section.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  headerHeight: PropTypes.number.isRequired,
  setSummaryHeight: PropTypes.func.isRequired,
  showFloatingSummary: PropTypes.bool.isRequired,
};

export default Section;
