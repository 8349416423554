import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import useIntersectionObserver from '~lib/hooks/useIntersectionObserver';

const StyledBlock = styled.section`
  padding: 48px 0 64px;

  ${props => props.theme.mq.sm} {
    padding: 64px 0;
  }
`;

const StyledContainer = styled.div`
  padding-left: ${props => props.theme.sizes.gap};
  padding-right: ${props => props.theme.sizes.gap};
  max-width: ${props => props.theme.sizes.container};
  width: 100%;
  margin: 0 auto;
`;

const Section = ({ children, setShowFloatingSummary }) => {
  const overviewRef = useRef();
  const isIntersecting = useIntersectionObserver(overviewRef, 0.2);

  useEffect(() => {
    setShowFloatingSummary(!isIntersecting);
  }, [isIntersecting, setShowFloatingSummary]);

  return (
    <StyledBlock ref={overviewRef}>
      <StyledContainer>{children}</StyledContainer>
    </StyledBlock>
  );
};

Section.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  setShowFloatingSummary: PropTypes.func.isRequired,
};

export default Section;
