import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import { Box, Flex } from 'rebass';
import { useLazyQuery } from '@apollo/client';
import { saveAs } from 'file-saver';
import Icon from '~common/atoms/Icon';
import { withTheme } from 'emotion-theming';
import { base64ToBlob, isRunningOnClientSide } from '~lib/util';
import { DownloadDocumentQuery } from 'queries/oms/documents';
import { StandardText } from '~common/atoms/typography';

const Row = styled(Flex)`
  position: relative;
  background: ${props =>
    props.alternate ? props.theme.colors.background.light : 'transparent'};
`;

const StyledDownloadLink = styled.span`
  text-align: right;
  color: ${props => (props.isLoading ? '#aaa' : props.theme.colors.primary)};
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  cursor: ${props =>
    props.isLoading ? 'wait' : props.isComplete ? 'auto' : 'pointer'};
  pointer-events: ${props =>
    props.isLoading || props.isComplete ? 'none' : 'auto'};
`;

const StyledDownloadLinks = styled(Flex)`
  ${props => props.theme.mq.smInverted} {
    position: absolute;
    right: 20px;
    top: 23px;
  }
`;

const MyDocumentsTable = ({ documents, theme }) => {
  const [lastId, setLastId] = useState(null);
  const [downloading, setDownloading] = useState([]);
  const onCompleted = useCallback(
    data => {
      const doc = data.oms.downloadDocument;

      if (!doc) {
        setLastId(null);
        setDownloading(ids => []);
        isRunningOnClientSide() &&
          window.alert(
            'Document could not be downloaded. Please try again later.'
          );
      } else if (lastId !== doc.id) {
        setLastId(doc.id);
        saveAs(base64ToBlob(doc.file, doc.mimeType), doc.name);
        setDownloading(ids => [
          ...ids.filter(id => id.attachmentId !== doc.id),
        ]);
      }
    },
    [lastId]
  );
  const [downloadDocument, { loading }] = useLazyQuery(DownloadDocumentQuery, {
    fetchPolicy: 'no-cache',
    onCompleted,
  });
  const isLoading = loading || downloading.length > 0;

  const downloadFile = attachment => {
    if (loading || downloading.length || lastId === attachment.id) {
      return;
    }

    setDownloading(ids => [
      ...ids,
      {
        attachmentId: attachment.id,
        documentId: attachment.documentId,
      },
    ]);
    downloadDocument({
      variables: {
        input: {
          attachmentId: attachment.id,
          documentId: attachment.documentId,
          name: attachment.name,
        },
      },
    });
  };

  return documents.map((document, index) => (
    <Row
      key={document.id}
      theme={theme}
      p={20}
      alternate={index % 2 === 0}
      flexDirection={{ xs: 'column', sm: 'row' }}
      alignItems={{ xs: 'flex-start', sm: 'center' }}
      justifyContent={{ xs: 'center', sm: 'space-between' }}
    >
      <StandardText
        width={{
          xs: 2 / 5,
          sm: 100,
        }}
      >
        {moment(document.date).format('DD/MM/YYYY')}
      </StandardText>
      <StandardText color="light" flex={1}>
        {document.name}
      </StandardText>
      <Box className="hide-mobile" color="#aaa" ml={10}>
        {downloading.some(attachment => attachment.documentId === document.id)
          ? 'Please wait'
          : ''}
      </Box>
      {document.attachments ? (
        <StyledDownloadLinks>
          {document.attachments.map(attachment => (
            <StyledDownloadLink
              key={attachment.id}
              width={120}
              isLoading={isLoading}
              isComplete={!isLoading && lastId === attachment.id}
              onClick={() => downloadFile(attachment)}
            >
              <Icon
                height="20px"
                width="20px"
                fill={
                  loading || downloading.length > 0
                    ? '#aaa'
                    : theme.colors.primary
                }
                name={lastId === attachment.id ? 'tick' : 'download'}
              ></Icon>
            </StyledDownloadLink>
          ))}
        </StyledDownloadLinks>
      ) : null}
    </Row>
  ));
};

export default withTheme(MyDocumentsTable);
