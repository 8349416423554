import styled from '@emotion/styled';
import { FancyCheckboxControl } from '~common/molecules/form-controls';

const GenderCheckboxControl = styled(FancyCheckboxControl)`
  &&&& .gender-option {
    width: 33%;
    .fancy-option {
      width: 100%;
      font-weight: normal;
      ${props => props.theme.mq.tabletPInverted} {
        min-width: unset;
      }
    }
  }
`;

export default GenderCheckboxControl;
