import {
  FormValidator,
  maxLength,
  length,
  required,
  defined,
} from '~lib/formValidation';
import { validators } from '~common/molecules/form-controls';
import { memoizeBy } from '~lib/util';

const NON_DIGITS_REGEX = /[^\d]/;
const MEDICARE_NUMBER_REGEX = /^([2-6]\d{7})(\d)/;

const validToValidator = (value, name, form) => {
  const validatingFragment = {
    [name]: Number(value),
  };
  const values = {
    validToDay: Number(form.validToDay),
    validToMonth: Number(form.validToMonth),
    validToYear: Number(form.validToYear),
    ...validatingFragment,
  };

  const date = new Date(
    values.validToYear,
    values.validToMonth - 1,
    values.validToDay || 1
  );

  if (Date.now() > date.getTime()) {
    throw 'Valid to date has to be in the future';
  }
};

const isValidMedicareCardNumber = memoizeBy(value => value)(value => {
  value = String(value);
  value = value.replace(NON_DIGITS_REGEX, '');

  // Check for 10 digits
  // Test leading digit and checksum
  const matches = value.match(MEDICARE_NUMBER_REGEX);
  if (matches && matches.length) {
    const base = matches[1];
    const checkDigit = Number(matches[2]);
    let sum = 0;
    const weights = [1, 3, 7, 9, 1, 3, 7, 9];
    for (let i = 0; i < weights.length; i++) {
      sum += Number(base[i]) * weights[i];
    }
    return sum % 10 === checkDigit;
  } else {
    return false;
  }
});

const medicareValidation = FormValidator({
  validation: {
    cardType: required('Card type is required field'),
    cardNumber: [
      required('Card number is required field'),
      length(10, 'Card number has exactly 10 digits'),
      memoizeBy(value => value)(value => {
        if (!isValidMedicareCardNumber(value)) {
          throw 'Invalid card number';
        }
      }),
    ],
    cardReferenceNumber: [
      defined('Card reference number is a required field'),
      value => {
        value = Number(value);
        if (value < 1 || value > 9) {
          throw 'Must be a number between 1 and 9';
        }
      },
    ],
    firstName: [
      required('First name is required field'),
      maxLength(100, 'First name is max 100 characters long'),
    ],
    lastName: [
      required('Last name is required field'),
      maxLength(100, 'Last name is max 100 characters long'),
    ],
    middleName: maxLength(100, 'Middle name is max 100 characters long'),
    validToDay: [
      required('Day is required'),
      value => {
        validators.day().validateSync(value);
      },
    ],
    validToMonth: [
      required('Month is required'),
      value => {
        validators.month().validateSync(value);
      },
    ],
    validToYear: [required('Year is required'), validToValidator],
    claimRebate: value => {
      if (value == null) {
        throw '';
      }
    },
    isPolicyCovered: value => {
      if (value == null) {
        throw '';
      }
    },
    tier: defined('Income rebate level is required'),
    declarationAccepted: required('Declaration has to be accepted'),
  },
  transformValidation: (validationArray, form) => {
    validationArray =
      form.claimRebate === false
        ? validationArray.filter(
            ([field]) =>
              !['isPolicyCovered', 'declarationAccepted'].includes(field)
          )
        : validationArray;

    if (form.cardType !== 'green' && form.isPolicyCovered) {
      return validationArray;
    }

    if (form.cardType === 'green') {
      validationArray = validationArray.filter(
        ([field]) => field !== 'validToDay'
      );
    }

    if (!form.isPolicyCovered) {
      validationArray = validationArray.filter(
        ([field]) => field !== 'declarationAccepted' && field !== 'tier'
      );
    }

    return validationArray;
  },
});

export default medicareValidation;
