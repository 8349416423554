import { useMemo } from 'react';
import { path, pipe, last, filter, whereEq } from 'lodash/fp';

export default (mainMenu, currentPageId = '') => {
  const current = useMemo(() => {
    if (!mainMenu) {
      return undefined;
    }
    const matchedPath =
      (function findPath(item, traversedPath = []) {
        const matched = path('link.page._id')(item) === currentPageId;

        if (item._key !== mainMenu._key) {
          traversedPath = traversedPath.concat({
            item,
            matched,
          });
        }

        if (item.items && item.items.length) {
          traversedPath = item.items
            .map(subItem => {
              return findPath(subItem, traversedPath);
            })
            .find(path => !!path);
        }

        if (
          traversedPath &&
          traversedPath.every(traversedItem => !traversedItem.matched)
        ) {
          traversedPath = undefined;
        }

        return traversedPath;
      })(mainMenu) || [];

    return {
      topLevelItem: path('0.item')(matchedPath),
      item: pipe(
        filter(
          whereEq({
            matched: true,
          })
        ),
        last,
        path('item')
      )(matchedPath),
    };
  }, [mainMenu, currentPageId]);

  return (
    current && {
      currentToplevelItem: current.topLevelItem,
      currentItem: current.item,
    }
  );
};
