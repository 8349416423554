import React from 'react';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import Eyebrow from '~common/atoms/Eyebrow';
import Loading from '~common/atoms/Loading';
import Link from '~common/atoms/Link';
import Icon from '~common/atoms/Icon';
import { DashboardRecentDocumentsQuery } from 'queries/oms/dashboard';
import { Caption, P2 } from '~common/atoms/typography';

const ICONS = {
  TAX: 'oms-download',
  POLICY: 'oms-download',
  BILL: 'oms-download',
};

const StyledText = styled.div`
  padding-right: 10px;
`;

const StyledHeading = styled.div`
  position: relative;
  display: inline-flex;
  padding-right: 8px;
  &::after {
    content: '\\203A';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    font-weight: bold;
    font-size: ${props => props.theme.fontSizes[2]}px;
    transition: transform 0.3s ease-in-out;
    color: ${props => props.colors.primary};
  }
`;

const StyledIcon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.colors.primary};
`;

const StyledItem = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  color: ${props => props.colors.text.dark};
  &:hover {
    ${StyledHeading} {
      text-decoration: underline;
      &::after {
        transform: translateX(5px);
      }
    }
    ${StyledIcon} {
      background: ${props => props.colors.hover.orange};
    }
  }
`;

const RecentMessages = props => {
  const { loading, error, data } = useQuery(DashboardRecentDocumentsQuery);

  if (error) {
    console.error(error);
    return null;
  }

  return (
    <div>
      <Eyebrow>{props.eyebrow}</Eyebrow>
      {loading ? (
        <Loading />
      ) : (
        <Flex flexWrap="wrap" mx={'-20px'}>
          {data?.oms?.documents?.map((e, i) => (
            <Box
              width={{
                xs: 1,
              }}
              px="20px"
              key={i}
            >
              <StyledItem to="/oms/my-documents" {...props.theme}>
                <StyledText>
                  <P2 mb="4px" variant="underline" color="primary">
                    {e.name}
                  </P2>
                  <Caption variant="medium">
                    Download {moment(e.date).format('DD/MM/YYYY')}
                  </Caption>
                </StyledText>
                <StyledIcon clickable {...props.theme}>
                  <Icon
                    name={ICONS[e.category]}
                    fill="#fff"
                    width="16px"
                    height="13px"
                  />
                </StyledIcon>
              </StyledItem>
            </Box>
          ))}
        </Flex>
      )}
    </div>
  );
};

export default withTheme(RecentMessages);
