import React, { useContext } from 'react';
import { Box, Button, Flex } from 'rebass';
import SimpleErrorHeader from '~common/atoms/SimpleErrorHeader';
import SSOText from '~OMS/SSO/atoms/SSOText';
import SSOFormContainer from '~OMS/styles/SSOFormContainer';
import Link from '~common/atoms/Link';
import { ContactUsContext } from '~OMS/contact-us/ContactUsContext';

const AlreadyRegisteredWrapper = () => {
  const { openRequestCallback } = useContext(ContactUsContext);

  return (
    <SSOFormContainer>
      <SimpleErrorHeader withIcon title="Already registered" />
      <Box mt={3} mb={4}>
        <SSOText mb={3}>
          This membership number has already been used to register an account.
          If you have forgotten your password,{' '}
          <Link to="/oms/sso/forgotPassword">click here</Link> to reset.
        </SSOText>
        <SSOText mb={3}>
          <strong>
            Are you a spouse trying to set-up your own login details?
          </strong>
        </SSOText>

        <SSOText>
          Great news, if you are one of two adults on a policy, you can set up
          your own individual login details. To do this, the member that
          registered your account will need to log in and invite you access.
        </SSOText>
      </Box>

      <Button
        onClick={() => {
          openRequestCallback({
            isAnomUser: true,
          });
        }}
        variant="orange"
        type="button"
      >
        Still need help? Request a Callback
      </Button>

      <Flex justifyContent="center" mt={4}>
        <Link to="/oms/sso/login/">Try logging in again</Link>
      </Flex>
    </SSOFormContainer>
  );
};

export default AlreadyRegisteredWrapper;
