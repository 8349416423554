import React from 'react';
import { CreateControl } from '~common/molecules/form-controls/Control';
import { StyledInput } from '~common/atoms/Input';
import { Flex, Box } from 'rebass';
import InfoIcon from '~common/molecules/InfoIcon';
import styled from '@emotion/styled';
import { StandardText } from '~common/atoms/typography';

const PercentageInput = styled(StyledInput)`
  width: 90px;
`;

const YouthDiscountControl = ({
  isTransferringFromAnotherFund = false,
  value,
  onChange,
  ...props
}) => {
  const formattedValue = value != null ? `${value}%` : '';
  return (
    <Box>
      <Flex alignItems="center" mb="16px">
        <PercentageInput value={formattedValue} disabled {...props} />
        <InfoIcon
          position="top"
          ml={1}
          tooltip="The youth discount is an initiative where members aged 18 to 29 are entitled to a discount of up to 10% on their hospital premium. This discount is retained until age 41, after which time it reduces at a rate of 2% per annum until age 45. This is a voluntary initiative for health funds to adopt and we offer this discount on all our hospital covers."
        />
      </Flex>
      <StandardText mt={2} fontSize={2}>
        We have calculated your youth discount based on your age at the time of
        completing this application. If you are transferring from another health
        fund and have an existing youth discount, you may qualify for a larger
        discount on your premium. This will be applied when we receive your
        transfer certificate.
      </StandardText>
      {isTransferringFromAnotherFund && (
        <StandardText mt={2} fontSize={2}>
          If transferring from another fund we will check your existing youth
          based discount and adjust to match it.
        </StandardText>
      )}
    </Box>
  );
};

export default CreateControl(YouthDiscountControl);
