import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';
import { pathOr, find, pipe, path, whereEq } from 'lodash/fp';
import { useQuery } from '@apollo/client';
import Icon from '~common/atoms/Icon';
import Link from '~common/atoms/Link';
import { AuthContext } from 'context/sso';
import { NavbarQuery } from 'queries/oms/common';
import useMenuState from './useMenuState';
import data from '~OMS/mock_data/menu';
import useFeatureToggle from '~lib/hooks/useFeatureToggle';
import useMembership from '../hooks/useMembership';
// Assets
import { useLocation } from '@reach/router';
import { P3 } from '~common/atoms/typography';

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  z-index: ${props => props.theme.zIndex.menu};
  display: flex;
  transform: translateX(${props => (props.isActive ? '0%' : '-100%')});
  transition-delay: ${props => (props.isActive ? '0s' : '0.8s')};
  ${props => props.theme.mq.tabletP} {
    transform: translateX(0);
    position: sticky;
    top: 20px;
    width: 130px;
    min-height: 0;
  }
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    content: '';
    opacity: ${props => (props.isActive ? '1' : '0')};
    transition: opacity 0.4s ease-in-out;
    transition-delay: ${props => (props.isActive ? '0s' : '0.4s')};
    ${props => props.theme.mq.sm} {
      display: none;
    }
  }
`;

const Inner = styled.div`
  background: #fff;
  width: calc(100% - 70px);
  height: 100%;
  transform: translateX(${props => (props.isActive ? '0%' : '-100%')});
  transition: transform 0.4s ease-in-out;
  transition-delay: ${props => (props.isActive ? '0.4s' : '0s')};
  overflow: hidden;
  ${props => props.mq.sm} {
    overflow: initial;
    padding-right: 4px;
    padding-left: 20px;
    transform: translateX(0);
    background: transparent;
    width: 100%;
  }
`;

const Scroll = styled.div`
  height: 100%;
  overflow-y: scroll;
  width: calc(100% + ${props => props.scrollbarWidth}px);
  ${props => props.mq.tabletP} {
    position: relative;
  }
  ${props => props.mq.sm} {
    height: auto;
    overflow-y: initial;
    width: initial;
  }
`;

const MenuIcon = styled.div`
  flex: 0 0 48px;
  padding-right: 15px;
  width: ${props => props.iconSizes.xs.width};
  height: ${props => props.iconSizes.xs.height};
  ${props => props.theme.mq.tabletP} {
    flex: initial;
    padding: 0;
    width: ${props => props.iconSizes.sm.width};
    height: ${props => props.iconSizes.sm.height};
    margin-bottom: 7px;
  }
`;

const MenuText = styled(P3)`
  transition: 0.2s background-color ease-in-out, 0.2s color ease-in-out;
`;

const MenuItem = styled(Link)`
  display: flex;
  text-decoration: none !important;
  color: ${props => props.theme.colors.menu};
  padding: 11px 12px;
  align-items: center;
  position: relative;
  transition: 0.2s background-color ease-in-out, 0.2s color ease-in-out;
  background-color: ${props => props.theme.colors.white};

  &.active,
  &:hover {
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.activeMenu};
    ${MenuText} {
      color: ${props => props.theme.colors.activeMenu};
    }

    svg,
    svg path {
      fill: ${props => props.theme.colors.activeMenu} !important;
    }
  }

  &:active {
    background-color: ${props => props.theme.colors.blue600};
  }

  ${props => props.mq.tabletP} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 90px;
    height: 78px;
    border-radius: 5px;
    margin-top: ${props => (props.index ? '12px' : 0)};
  }

  svg,
  svg path {
    fill: ${props => props.theme.colors.menu} !important;
    transition: 0.2s fill ease-in-out;
  }
`;

const MobileClose = styled.div`
  width: 48px;
  height: 48px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  z-index: 1;
  padding: 10px;
  ${props => props.mq.tabletP} {
    display: none;
  }

  > span {
    display: block;
    width: 100%;
    height: 100%;
    border: 2px solid #000;
    border-radius: 50%;
    transform: rotate(45deg);
    &::before {
      background: #000;
      display: block;
      content: '';
      width: 2px;
      height: 12px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 5px;
    }
    &::after {
      background: #000;
      display: block;
      content: '';
      width: 12px;
      height: 2px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 5px;
    }
  }
`;

const MobileUserMenu = styled.div`
  display: block;
  padding: 20px 12px;
  margin-top: 10px;
  font-size: ${props => props.theme.fontSizes[1]}px;
  line-height: 16px;
  color: ${props => props.colors.menu};
  border-top: 1px solid ${props => props.colors.borders};
  border-bottom: ${props =>
    props.borderBottom ? `1px solid ${props.colors.borders}` : ''};
  ${props => props.mq.tabletP} {
    display: none;
  }
  a {
    text-decoration: underline;
    font-size: ${props => props.theme.fontSizes[1]}px;
    color: ${props => props.colors.menu};
  }
`;

const MobileAvant = styled.a`
  display: block;
  width: 100%;
  padding: 10px 12px;
  line-height: 1;
  font-size: ${props => props.theme.fontSizes[1]}px;
  text-decoration: none;
  color: ${props => props.colors.primary};
  ${props => props.mq.tabletP} {
    display: none;
  }
`;

const Menu = ({ theme }) => {
  let ref = null;
  const location = useLocation();

  const context = useContext(AuthContext);
  const [state, toggleState] = useMenuState();
  const { isS2IMembership, membership } = useMembership();

  const {
    error: navbarError,
    loading: navbarLoading,
    data: navbarData,
  } = useQuery(NavbarQuery);
  const { sso } = useFeatureToggle();

  const currentUser = path('oms.user', navbarData);
  const currentPerson = pipe(
    pathOr([], 'oms.membership.persons'),
    find(
      whereEq({
        id: path('personId', currentUser),
      })
    )
  )(navbarData);

  if (navbarError) {
    console.error(navbarError);
    return null;
  }

  const handleClick = e => {
    if (
      e.target === ref &&
      window.innerWidth < parseInt(theme.breakpoints.sm)
    ) {
      toggleState();
    }
  };

  const handleLogout = () => {
    context.logout();
  };

  return (
    <Container
      isActive={state.isActive}
      theme={theme}
      onClick={e => handleClick(e)}
      ref={div => (ref = div)}
    >
      <Inner isActive={state.isActive} {...theme}>
        <Scroll {...theme}>
          <MobileClose {...theme} onClick={() => toggleState()}>
            <span></span>
          </MobileClose>
          {data.map((e, i) => {
            return (
              <MenuItem
                noStyles
                index={i}
                data-id={`menu-item-${e.id}`}
                onClick={async evt => {
                  evt.preventDefault();
                  await navigate(e.href);
                  toggleState(false);
                }}
                key={i}
                to={e.href}
                activeClassName="active"
                partiallyActive={e.matchPartial}
                {...theme}
              >
                <MenuIcon iconSizes={e.iconSizes}>
                  <Icon
                    name={e.icon}
                    width="100%"
                    height="100%"
                    clickable
                    fill={theme.colors.menu}
                  />
                </MenuIcon>
                <MenuText color="neutral300" variant="semiBold">
                  {e.text}
                </MenuText>
              </MenuItem>
            );
          })}
          {!navbarLoading && !navbarError && currentPerson && (
            <MobileUserMenu borderBottom={!!sso} {...theme}>
              <Flex>
                Logged in as {currentPerson.firstName} |{' '}
                <Box ml={1} onClick={handleLogout}>
                  Log out
                </Box>
              </Flex>
              <Box mt={'30px'} onClick={() => toggleState()}>
                <Link
                  to="/oms/my-details"
                  state={{ expandMember: currentPerson.id }}
                >
                  Edit my details
                </Link>
              </Box>
              <Box mt={'30px'}>
                <Link
                  to={`/oms/sso/changePassword`}
                  state={{
                    user: currentPerson,
                    prevPath: location.pathname,
                  }}
                >
                  Change password
                </Link>
              </Box>
              {!isS2IMembership && membership.type !== 'SINGLE' && (
                <Box mt={'30px'} onClick={() => toggleState()}>
                  <Link
                    to={`/oms/my-details`}
                    state={{
                      expandMember: currentPerson.id,
                    }}
                  >
                    Grant family access
                  </Link>
                </Box>
              )}
            </MobileUserMenu>
          )}

          {sso && (
            <MobileAvant onClick={context.avant.actions.goToAvant} {...theme}>
              <Flex alignItems="center">
                <Box>
                  <Icon originalColorSchema name="avant" width="23px" />
                </Box>
                <Box ml={'10px'}>
                  Switch to <strong>Avant Mutual</strong>
                </Box>
              </Flex>
            </MobileAvant>
          )}
        </Scroll>
      </Inner>
    </Container>
  );
};

export default withTheme(Menu);
