import React from 'react';
import { withTheme } from 'emotion-theming';

import Layout from 'components/Layout';
import Navbar from '~common/organisms/Navbar';
import Wrapper from '~common/molecules/Wrapper';

import CheckMembership from '~common/organisms/CheckMembership';
import OmsFooter from '~OMS/OmsFooter';
import ChangeCover from '~OMS/my-cover/change-cover/ChangeCover';
import { isUpgradeCoverEligible } from '~OMS/response-selectors';
import allow from '~lib/hocs/allow';

const UpgradeCover = props => {
  return (
    <Layout>
      <CheckMembership>
        <Navbar location={props.location} />
        <Wrapper minHeight="100vh" pb={0}>
          <ChangeCover />
        </Wrapper>
        <OmsFooter />
      </CheckMembership>
    </Layout>
  );
};

export default withTheme(
  allow({
    to: data => {
      return isUpgradeCoverEligible({
        oms: data,
      });
    },
  })(UpgradeCover)
);
