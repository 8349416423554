import { graphql, useStaticQuery } from 'gatsby';

const REFER_FRIEND_QUERY = graphql`
  query ReferFriendQuery {
    sanityReferFriend {
      giftAmount
      omsText: _rawOmsText(resolveReferences: { maxDepth: 20 })
      omsFormDisclaimer: _rawOmsFormDisclaimer(
        resolveReferences: { maxDepth: 20 }
      )
    }
  }
`;

export default () => {
  const result = useStaticQuery(REFER_FRIEND_QUERY);
  return result.sanityReferFriend;
};
