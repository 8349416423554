import React, { useState, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { MyDetailsQuery } from 'queries/oms/myDetails.graphql';
import { path } from 'lodash/fp';
import useUpdateMembershipMutation from './useUpdateMembershipMutation';
import { formatAddress } from '~lib/filters';
import StateChangeModal from '~OMS/my-details/molecules/StateChangeModal';
import { open } from '~common/services/modalService';

export const FORM_MODES = {
  MANUAL: 'manual',
  AUTO: 'auto',
};

const addressEquals = (addressLineA, addressLineB) => {
  return addressLineA === addressLineB;
};

const getAddressPayload = (formData = {}) => {
  let payload = {};

  // re-map to "addressLine"
  if (formData.postCode) {
    // manual form
    const membershipAddress = `${formData.streetAddress}, ${formData.suburb} ${formData.state} ${formData.postCode}`;
    payload = {
      membershipAddressLine: membershipAddress,
      postalAddressLine: formData.isPostalDifferentAsResidential
        ? `${formData.postalStreetAddress}, ${formData.postalSuburb} ${formData.postalState}  ${formData.postalPostCode} `
        : membershipAddress,
    };
  } else {
    // auto complete
    payload = {
      membershipAddressLine: formatAddress(formData.address),
      postalAddressLine: formData.isPostalDifferentAsResidential
        ? formatAddress(formData.postalAddress)
        : formatAddress(formData.address),
    };
  }

  return payload;
};

export const useUpdateAddress = ({ initialMode = '' } = {}) => {
  const [mode, setMode] = useState(initialMode);
  const [form, setForm] = useState({});
  const [updated, setFormUpdated] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [isChangeAddressDisplayed, displayChangeAddress] = useState(false);
  const { loading: myyDetailsLoading, data: myDetailsData } =
    useQuery(MyDetailsQuery);
  const [updateMembership] = useUpdateMembershipMutation();

  const setFormMode = specificMode => {
    setFormUpdated(false);
    setMode(specificMode);
  };

  const openManualMode = () => {
    setFormMode(FORM_MODES.MANUAL);
  };

  const openAutoMode = () => {
    setFormMode(FORM_MODES.AUTO);
  };

  const closeUpdateMode = () => {
    setFormMode(undefined);
  };

  const changeForm = formData => {
    setForm(currentForm => ({
      ...formData,
      ...currentForm,
    }));
  };

  const clearAll = () => {
    setForm({});
    closeUpdateMode();
    setFormUpdated(false);
  };

  const updateAddress = (data, form, type = '') => {
    form.setSubmitting(true);
    setUpdating(true);

    const payload = getAddressPayload(data);

    updateMembership(payload)
      .then(() => {
        // hide form
        hide();
        //TODO: tightly coupling with the context in which the hook is being used
        // instead moved this logic to order card hook
        // on card order page, go back to default state
        if (type === 'card') {
          clearAll();
        }
      })
      .catch(error => {
        error.graphQLErrors.map(({ message }, i) =>
          form.setErrors({
            type: 'ServerError',
            message,
          })
        );
      })
      .finally(() => {
        form.setSubmitting(false);
        setUpdating(false);
      });
  };

  const toggleUpdateMode = () => {
    const nextMode =
      mode === FORM_MODES.MANUAL ? FORM_MODES.AUTO : FORM_MODES.MANUAL;
    setFormMode(nextMode);
  };

  const hide = useCallback(() => {
    // make sure to set auto form to default
    openAutoMode();
    displayChangeAddress(false);
    // show message for order card form
    setFormUpdated(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const display = useCallback(() => {
    displayChangeAddress(true);
  }, []);

  const membershipAddress = path('oms.membership.membershipAddress')(
    myDetailsData
  );

  const postalAddress = myDetailsData?.oms?.membership?.postalAddress;

  const isAddressChanging = formData => {
    const { membershipAddressLine, postalAddressLine } =
      getAddressPayload(formData);

    const currentMembershipAddressLine = formatAddress(membershipAddress);
    const currentPostalAddressLine = formatAddress(postalAddress);

    return (
      membershipAddressLine !== currentMembershipAddressLine ||
      postalAddressLine !== currentPostalAddressLine
    );
  };

  const updateAddressWithStateChangeModal = (data, form, type) => {
    // fixes when a user clicks save but doesn't change anything
    if (!isAddressChanging(data)) {
      hide();
      return undefined;
    }

    const updatingState = data?.state || data?.address?.state;
    if (updatingState !== membershipAddress.state) {
      open({
        content: ({ close }) => (
          <StateChangeModal
            onConfirm={() => {
              close();
              updateAddress(data, form, type);
            }}
            onClose={close}
          />
        ),
      });

      return undefined;
    }
    return updateAddress(data, form, type);
  };

  return {
    current: {
      loading: myyDetailsLoading,
      postalAddressLine: path('oms.membership.postalAddressLine')(
        myDetailsData
      ),
      membershipAddressLine: path('oms.membership.membershipAddressLine')(
        myDetailsData
      ),
      postalAddress: path('oms.membership.postalAddress')(myDetailsData),
      membershipAddress,
    },
    updating,
    updated,
    mode,
    form,
    openAutoMode,
    closeUpdateMode,
    openManualMode,
    changeForm,
    clearAll,
    updateAddress,
    toggleUpdateMode,
    updateAddressWithStateChangeModal,
    hide,
    display,
    isChangeAddressDisplayed,
  };
};
