import React from 'react';
import styled from '@emotion/styled';
import { Box } from 'rebass';

const TableContainer = styled(Box)`
  overflow: scroll;
`;

const Table = styled.table`
  border-spacing: 0;

  tr {
    border-bottom: 1px solid ${props => props.theme.colors.borders};
  }

  td {
    padding: 10px 7px;
    font-size: ${props => props.theme.fontSizes[0]}px;

    ${props => props.theme.mq.tinyPhone} {
      padding: 10px 20px;

      font-size: ${props => props.theme.fontSizes[1]}px;
    }
    ${props => props.theme.mq.tabletP} {
      padding: 20px 40px;
      font-size: ${props => props.theme.fontSizes[2]}px;
    }
    vertical-align: middle;
    max-width: 200px;
    line-height: 1.5;
  }

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
`;

export default ({ data }) => {
  const { rows } = data;
  return (
    <TableContainer>
      <Table>
        {rows.map(row => (
          <tr>
            {row.map(cell => (
              <td style={cell.styles}>{cell.value}</td>
            ))}
          </tr>
        ))}
      </Table>
    </TableContainer>
  );
};
