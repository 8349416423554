import React from 'react';
import CheckBox from '~common/atoms/CheckBox';
import { StandardText } from '../atoms/typography';
import InfoIcon from '~common/molecules/InfoIcon';
import { Flex } from 'rebass';
import { EFC_MIN_AGE, EFC_UPPER_AGE_LIMIT } from '~lib/constants';

const CheckEfc = ({ onChange, labelProps, infoIconProps, value, ...props }) => {
  return (
    <Flex data-id="efc-check-container" alignItems="flex-start" {...props}>
      <CheckBox
        name="efcCheck"
        color="blue"
        value={value}
        onChange={evt => {
          onChange(typeof evt === 'boolean' ? evt : evt.target.checked);
        }}
      />
      <label htmlFor="efcCheck">
        <StandardText clickable fontSize={1} {...labelProps}>
          I wish to cover a dependent aged {EFC_MIN_AGE}-
          {EFC_UPPER_AGE_LIMIT - 1} who is not studying{' '}
        </StandardText>
      </label>

      <InfoIcon
        data-id="efc-help"
        icon={{
          width: '13px',
          height: '13px',
          fill: 'black',
        }}
        tooltip={
          <StandardText color="white">
            Any children aged {EFC_MIN_AGE} to {EFC_UPPER_AGE_LIMIT - 1} who are
            not currently studying can continue to be covered until they turn{' '}
            {EFC_UPPER_AGE_LIMIT}. Extended Family Cover is an optional upgrade
            available with our Family policies. The below quote has been
            calculated to reflect this. If you would like a quote that does not
            include this type of cover, please untick this box.
          </StandardText>
        }
        position="right"
        ml={2}
        {...infoIconProps}
      />
    </Flex>
  );
};

export default CheckEfc;
