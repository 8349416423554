import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { H3, P2 } from '~common/atoms/typography';
import { scrollTo } from '../shared/utils';

const CONTENT = {
  hospital: {
    title: 'Choose your Hospital Cover',
    enabled: {
      buttonText: 'Skip to Extras',
      description: 'Do not want hospital cover?',
    },
    disabled: {
      buttonText: 'Choose cover',
      description: 'Want hospital cover?',
    },
  },
  extras: {
    title: 'Choose your Extras Cover',
    enabled: {
      buttonText: 'Skip',
      description: 'Do not want extras cover?',
    },
    disabled: {
      buttonText: 'Choose cover',
      description: 'Want extras cover?',
    },
  },
};

const StyledHeading = styled(H3)`
  color: ${props => props.theme.colors.primary};
  text-align: center;
  margin-top: 33.5px;
  ${props =>
    props.isDisabled && `color: ${props.theme.colors.disabled} !important;`}
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    counter-increment: section;
    content: counter(section);
    padding: 3px 9px;
    font-size: ${props => props.theme.fontSizes[3]}px;
    color: inherit;
    border-width: 2px;
    border-style: solid;
    ${props =>
      props.isDisabled &&
      `border-color: ${props.theme.colors.disabled} !important;`}
    border-radius: 50%;
    margin-right: 16px;

    ${props => props.theme.mq.sm} {
      font-size: ${props => props.theme.fontSizes[4]}px;
    }
  }
`;

const StyledText = styled(P2)`
  font-weight: 400;
  text-align: center;
  margin-top: 17.5px;
`;

const StyledButton = styled.button`
  ${props => props.theme.buttons.primaryLink};
`;

const CoverHeading = ({
  primaryType,
  secondaryType,
  setProductType,
  isCoverVisible,
  isDescriptionVisible,
}) => {
  return (
    <>
      <StyledHeading isDisabled={!isCoverVisible}>
        {CONTENT[primaryType].title}
      </StyledHeading>
      {isDescriptionVisible ? (
        <StyledText>
          {isCoverVisible
            ? CONTENT[primaryType].enabled.description
            : CONTENT[primaryType].disabled.description}{' '}
          <StyledButton
            onClick={() => {
              setProductType(isCoverVisible ? secondaryType : 'both');

              if (primaryType === 'hospital') {
                scrollTo(
                  isCoverVisible
                    ? 'quote-products-extras'
                    : 'quote-products-hospital'
                );
              } else if (primaryType === 'extras') {
                scrollTo(
                  isCoverVisible ? 'quote-overview' : 'quote-products-extras'
                );
              }
            }}
          >
            {isCoverVisible
              ? CONTENT[primaryType].enabled.buttonText
              : CONTENT[primaryType].disabled.buttonText}
          </StyledButton>
        </StyledText>
      ) : null}
    </>
  );
};

CoverHeading.propTypes = {
  primaryType: PropTypes.string.isRequired,
  secondaryType: PropTypes.string.isRequired,
  setProductType: PropTypes.func.isRequired,
  isCoverVisible: PropTypes.bool.isRequired,
  isDescriptionVisible: PropTypes.bool.isRequired,
};

export default CoverHeading;
