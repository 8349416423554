import React from 'react';
import Layout from 'components/Layout';
import SetPasswordWrapper from '~OMS/SSO/organisms/SetPasswordWrapper';
import { withTheme } from 'emotion-theming';
import withQueryParams from '~lib/hocs/withQueryParams';

const SetPassword = ({ queryParams }) => {
  return (
    <Layout>
      <SetPasswordWrapper queryParams={queryParams} />
    </Layout>
  );
};

export default withTheme(withQueryParams(SetPassword));
