import React from 'react';
import { Flex, Box, Text, Heading } from 'rebass';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import Block from '~common/atoms/Block';
import accessGapGraph1x from 'images/access-gap-graph@1x.png';
import accessGapGraph2x from 'images/access-gap-graph@2x.png';

const ImageContainer = styled.div`
  flex: 1;
  margin-left: 10px;

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 0;
  }
`;

const TextContainer = styled(Box)`
  max-width: 314px;

  @media screen and (max-width: 992px) {
    width: 100%;
    max-width: 100%;
  }
`;

const AccessGap = props => {
  return (
    <Block snug={true} bg="background.gray">
      <Flex
        flexDirection={{ xs: 'column', lg: 'row' }}
        justifyContent="space-between"
        alignItems="flex-start"
        bg="white"
        pt={{ lg: '45px' }}
        pl={{ xs: '10px', lg: '80px' }}
        pr={{ xs: '10px', lg: '45px' }}
        pb={{ xs: '30px', lg: '40px' }}
        mb={{ xs: '10px', lg: '50px' }}
        css={css`
          max-width: 970px;
          margin-left: auto;
          margin-right: auto;
        `}
      >
        <TextContainer flex={1} mr={{ lg: '10px' }}>
          <Heading
            fontSize={{ xs: 3, lg: 5 }}
            fontWeight="bold"
            color="text.dark"
            mt="24px"
            mb="36px"
          >
            What is the gap?
          </Heading>
          <Text fontSize={2} lineHeight="1.31" color="text.light" mb="20px">
            The gap is the difference between the fee charged by the hospital
            (or the amount the doctor charges for services in hospital) and the
            amount covered by Medicare and your private health insurer. It is
            the out-of-pocket expenses you may pay for your treatment.
          </Text>
          <Text fontSize={2} lineHeight="1.31" color="text.light" mb="20px">
            Our Prime Choice Gold and Smart Starter Bronze Plus hospital covers
            include medicalgap cover that pays benefits according to the Access
            Gap Cover scheme. Our Top Cover Gold has a unique gap benefit that
            pays up to the AMA list of medical services and fees.
          </Text>
          <Text fontSize={1} lineHeight="1.31" color="text.light" mb="20px">
            The example shown compares medical services benefits for a knee
            reconstruction, item no.49542 as of April 2018.
          </Text>
        </TextContainer>
        <ImageContainer>
          <img
            src={accessGapGraph2x}
            srcSet={`${accessGapGraph2x} 2x, ${accessGapGraph1x} 1x`}
            width="100%"
            alt=""
          />
        </ImageContainer>
      </Flex>
    </Block>
  );
};

export default AccessGap;
