import React from 'react';

import { useThemeUI } from 'theme-ui';
import useFiles from '~lib/hooks/sanity/useFiles';
import Link from '~common/atoms/Link';
import { Button } from '~common/atoms/Button';

const FactsheetLink = ({ productId, url, title, ...props }) => {
  const { theme } = useThemeUI();
  const liteExtrasCoverBrochure = useFiles('liteExtrasCoverBrochure');
  const type =
    productId === 'extras-cover-lite-extras' ? 'Brochure' : 'factsheet';
  return (
    <Button
      as={Link}
      to={type === 'Brochure' ? liteExtrasCoverBrochure?.url : url}
      external
      target="_blank"
      variant={theme.factsheetLink}
      size="medium"
      icon="download"
      {...props}
    >
      Download extras {type}
    </Button>
  );
};

export default FactsheetLink;
