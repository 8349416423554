import React from 'react';
import Block from '../../common/atoms/Block';
import SanityImage from '../../common/molecules/SanityImage';
import styled from '@emotion/styled';
import Link from './Link';

const sizes = {
  small: '378px',
  medium: '688px',
  large: '100%',
};

const StyledImage = styled(SanityImage)`
  ${props => props.theme.mq.lg} {
    ${props =>
      props.inPage
        ? `
      &&& {
        object-fit: cover;
        max-height: ${sizes[props.size]};
      }
    `
        : ''}
  }
`;

const parseHeight = size => {
  const sizeValue = sizes[size];
  if (sizeValue && sizeValue.endsWith('%')) {
    return undefined;
  }

  return parseInt(sizeValue);
};

export const ImageBase = ({ data = {}, inPage, ...props }) => {
  const { caption, size = 'medium', title, alt, link } = data;

  const imageElem = (
    <StyledImage
      size={size}
      resizeHeight={parseHeight(size)}
      inPage={inPage}
      alt={alt}
      caption={caption}
      title={title}
      src={data}
      {...props}
    />
  );

  return link && (link.url || link.page) ? (
    <Link
      data={{
        ...link,
        // according to https://airteam.atlassian.net/browse/DWM-1505 all links should be opened in the new tab
        // that being said the default newTab values is set to true
        newTab: link.newTab == null ? true : link.newTab,
      }}
    >
      {imageElem}
    </Link>
  ) : (
    imageElem
  );
};

const Image = ({ data, ...props }) => {
  return (
    <Block {...props}>
      <ImageBase inPage data={data} />
    </Block>
  );
};

export default Image;
