import React from 'react';
import Layout from 'components/Layout';
import { withTheme } from 'emotion-theming';
import ApplicationForm from '../components/ApplicationForm/ApplicationForm';

const ApplicationFormPage = props => {
  return (
    <Layout>
      <ApplicationForm location={props.location} />
    </Layout>
  );
};

export default withTheme(ApplicationFormPage);
