import { useMemo } from 'react';
import useAppFormAdditionalInformation from '~lib/hooks/sanity/useAppFormAdditionalInformation';

export default () => {
  const { avantInfoOptions } = useAppFormAdditionalInformation();

  const options = useMemo(() => {
    const mappedOptions = avantInfoOptions.map(elem => ({
      name: elem.id,
      label: elem.title,
    }));

    return [
      {
        label: 'Select one or more',
        readOnly: true,
        name: 'title',
      },
    ].concat(mappedOptions);
  }, [avantInfoOptions]);

  return options;
};
