import React, { useEffect, useMemo, useState } from 'react';
import usePartner from './usePartner';
import useDependant from './useDependant';
import useExpander from './useExpander';
import { path, whereEq } from 'lodash/fp';
import { openModal } from '../common/appFormUtills';
import { AGE_CASE } from '../constants';
import EfcRemoveModal from '../common/components/modals/EfcRemoveModal';
import AddDependantsModal from '../common/components/modals/AddDependantsModal';
import { partnersDetailsValidationSchema } from '../validation/partnersDetailsValidation';
import { dependantDetailsValidationSchema } from '../validation/dependantDetailsValidation';
import { quoteStatuses } from '~lib/constants';
import { dropEmpties, dropProps } from '~lib/util';

const isEmptyForm = form => {
  return Object.values(form.values).every(
    //eslint-disable-next-line eqeqeq
    value => value == null || value === ''
  );
};

export default (
  {
    criteria,
    setCriteria,
    isFamilyPackage,
    isDependantsPackage,
    isCouplePackage,
    hasPartner,
    status,
    isEfc,
    hasLhcApplied,
    efcEligible,
  },
  { yourDetailsContext, persistKey }
) => {
  const { privateHealthDetailsForm } = yourDetailsContext;

  const partnerDetails = usePartner({
    criteria,
    setCriteria,
    privateHealthDetailsForm,
    persistKey: persistKey && `${persistKey}:partner`,
    hasLhcApplied,
  });

  const upgradeToEfc = () => {
    setCriteria({
      ...criteria,
      efc: {
        ...criteria.efc,
        value: true,
      },
    });
  };

  const switchToPackage = (value, otherCriteria) => {
    setCriteria({
      ...criteria,
      ...otherCriteria,
      status: {
        ...criteria.status,
        ...quoteStatuses.find(
          whereEq({
            value,
          })
        ),
      },
    });
  };

  const switchToCouples = () => {
    switchToPackage('COUPLE', {
      efc: {
        value: false,
      },
    });
  };

  const switchToSingle = () => {
    switchToPackage('SINGLE', {
      efc: {
        value: false,
      },
    });
  };

  const removeEfc = () => {
    setCriteria({
      ...criteria,
      efc: {
        value: false,
      },
    });
  };

  const dependantDetails = useDependant({
    onRemove: formName => {
      expander.removeExpanded(formName);
      expander.removeConfirmed(formName);
    },
    onAdd: formName => {
      expander.setExpanded(formName, true);
      expander.setConfirmed(formName, false);
      dependantDetails.forms
        .filter(({ name }) => name !== formName)
        .forEach(form => {
          if (form.isValid) {
            expander.setExpanded(form.name, false);
          }
        });
    },
    isEfc,
    efcEligible,
    upgradeToEfc,
    persistKey: persistKey && `${persistKey}:dependants`,
    initialForm: {
      dependant0: {},
    },
    isTransferringFromAnotherFund:
      privateHealthDetailsForm.values
        .partnerAndDependentsTransferringFromAnotherFund,
    privateHealthDetailsForm,
  });

  const firstDependantFormName = path('forms[0].name')(dependantDetails);

  const expander = useExpander({
    initialExpansion: {
      partnerDetails: true,
      dependant0: false,
    },
    initialConfirmation: {
      partnerDetails: false,
      dependant0: false,
    },
    scrollMap: {
      partnerDetails: firstDependantFormName,
    },
  });

  useEffect(() => {
    if (!hasPartner && isDependantsPackage) {
      expander.setExpanded(firstDependantFormName, true);
    }
  }, [isDependantsPackage, hasPartner]);

  const noNeedForEfc = () => {
    return (
      isEfc &&
      dependantDetails.forms.every(form => {
        const ageCase = dependantDetails.calculateDependantAgeCase(form);
        return (
          ageCase === AGE_CASE.UNDER_21 ||
          (ageCase === AGE_CASE.VALID_EFC && form.values.isFullTimeStudent)
        );
      })
    );
  };

  const noNeedForDependants = useMemo(() => {
    return isDependantsPackage && dependantDetails.forms.every(isEmptyForm);
  }, [dependantDetails.forms, isDependantsPackage]);

  const confirmFamilyStep = ({ confirmStep }) => {
    const proceedToNextStep = () => {
      expander.confirmAllForms();
      confirmStep();
    };
    if (noNeedForEfc()) {
      openModal(({ close }) => (
        <EfcRemoveModal
          onRemove={() => {
            close();
            removeEfc();
            proceedToNextStep();
          }}
          onReturn={() => {
            dependantDetails.addForm();
            close();
          }}
        />
      ));
    } else if (noNeedForDependants) {
      openModal(({ close }) => (
        <AddDependantsModal
          type={status}
          onAddDependants={close}
          onPackageSwitch={() => {
            close();
            if (status === 'SINGLE_PARENT') {
              switchToSingle();
            } else {
              switchToCouples();
            }
            proceedToNextStep();
          }}
        />
      ));
    } else {
      proceedToNextStep();
    }
  };

  const touched = {
    partnerDetails: () =>
      partnerDetails.form.setAllTouchedFromValidationSchema(
        partnersDetailsValidationSchema
      ),
    dependantDetails: () =>
      dependantDetails.forms.forEach(form => {
        form.setAllTouchedFromValidationSchema(
          dependantDetailsValidationSchema
        );
      }),
  };

  const handleNextForm = (name, isValid) => {
    touched.partnerDetails();

    if (isValid) {
      expander.goToNextForm(name, isValid);
    }
  };

  const setAllTouched = () => {
    touched.partnerDetails();
    if (isDependantsPackage) {
      touched.dependantDetails();
    }
  };

  const [isFormSubmitted, setFormSubmittedRaw] = useState(false);

  const setFormSubmitted = () => {
    setAllTouched();
    setFormSubmittedRaw(true);
  };

  const serialize = () => {
    return dropEmpties({
      partnerDetails: partnerDetails.serialize(),
      dependantDetails: isDependantsPackage
        ? dependantDetails.forms.map(form => {
            let values = form.validValues;

            if (
              values.useMemberPreviousHealthFund &&
              dependantDetails.canUseMembersHealthFund
            ) {
              values = {
                ...values,
                currentHealthFund:
                  privateHealthDetailsForm.values.currentHealthFund,
                membershipNumber:
                  privateHealthDetailsForm.values.membershipNumber,
              };
            }

            values = dropProps(['useMemberPreviousHealthFund'], values);

            return privateHealthDetailsForm.values
              .partnerAndDependentsTransferringFromAnotherFund
              ? values
              : dropProps(['currentHealthFund', 'membershipNumber'], values);
          })
        : null,
    });
  };

  const deserialize = (data, maf, quoteInput) => {
    partnerDetails.deserialize(data.partnerDetails, {
      entryAge: path('partner.entryAge')(quoteInput),
      lhcExempt: path('partner.lhcExempt')(quoteInput),
      dob: path('partner.dob')(quoteInput),
      hospitalProduct: quoteInput.hospitalProduct,
      isTransferringFromAnotherFund: !!(
        data.partnerDetails.currentHealthFund ||
        data.partnerDetails.membershipNumber
      ),
    });
    if (data.dependantDetails) {
      dependantDetails.setForms(data.dependantDetails, { merge: false });
    }
  };

  const form = {
    reset: () => {
      partnerDetails.form.reset();
      dependantDetails.forms.forEach(form => {
        form.reset();
      });
    },
    isValid:
      (!hasPartner || partnerDetails.form.isValid) &&
      (dependantDetails.allValid || noNeedForDependants || isCouplePackage),
  };

  return {
    expander,
    partnerDetails,
    dependantDetails,
    setFormSubmitted,
    isFormSubmitted,
    confirmFamilyStep,
    handleNextForm,
    form,
    serialize,
    deserialize,
  };
};
