import { createGlobalState } from 'react-hooks-global-state';

const initialState = { productIndex: 0, FAQIndex: -1 };
const { useGlobalState } = createGlobalState(initialState);

const useFAQState = () => {
  const [productIndex, setProductIndex] = useGlobalState('productIndex');
  const [FAQIndex, setFAQIndex] = useGlobalState('FAQIndex');

  return {
    productIndex,
    setProductIndex,
    FAQIndex,
    setFAQIndex,
  };
};

export default useFAQState;
