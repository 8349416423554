import React from 'react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';
import { CreateControl, backgroundColorStyle } from './Control';

const StyledButton = styled.button`
  min-width: 100%;
  text-align: center;
  cursor: pointer;
  height: 100%;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  color: ${props =>
    props.active ? props.theme.colors.primary : props.theme.colors.dark};
  border: 1px solid
    ${props =>
      props.active ? props.theme.colors.primary : props.theme.colors.borders};
  text-transform: none;
  line-height: 14px;
  font-size: ${props => props.theme.fontSizes[1]}px;
  padding-top: 13px;
  padding-bottom: 13px;
  ${backgroundColorStyle};
  &:focus {
    outline: none;
  }
`;

const StyledButtonSelect = styled(Flex)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(95px, 95px));
  grid-gap: 5px;
  ${props => props.theme.mq.tabletPInverted} {
    grid-gap: 0;
    grid-row-gap: 5px;
    grid-template-columns: repeat(1, 100%);
  }
`;

const ButtonSelect = ({
  buttons,
  className,
  theme,
  onChange = () => {},
  value,
}) => {
  return (
    <StyledButtonSelect className={className}>
      {buttons.map((button, index) => (
        <StyledButton
          type="button"
          last={index === buttons.length - 1}
          key={button.id}
          theme={theme}
          active={value === button.id}
          onClick={() => {
            onChange(button.id);
          }}
        >
          {button.text}
        </StyledButton>
      ))}
    </StyledButtonSelect>
  );
};

export default CreateControl(ButtonSelect);
