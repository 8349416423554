import React from 'react';
import { Field } from 'formik';
import CheckBoxControl from '~common/molecules/form-controls/CheckBoxControl';
import { Text } from 'rebass';
import Link from '~common/atoms/Link';
import useFiles from '~lib/hooks/sanity/useFiles';

const TermsAndConditionsField = ({ name = 'termsAndConditions' }) => {
  const directDebitServiceAgreement = useFiles('directDebitServiceAgreement');
  return (
    <Field
      name={name}
      label={
        <Text>
          I have read and accept the{' '}
          <Link external to={directDebitServiceAgreement?.url} target="_blank">
            direct debit service agreement
          </Link>
        </Text>
      }
      labelPosition="right"
      component={CheckBoxControl}
    />
  );
};

export default TermsAndConditionsField;
