import React from 'react';
import Layout from 'components/Layout';
import AvantLinkWrapper from '~OMS/SSO/organisms/AvantLinkWrapper';
import { withTheme } from 'emotion-theming';

const AvantLink = props => {
  return (
    <Layout>
      <AvantLinkWrapper location={props.location} />
    </Layout>
  );
};

export default withTheme(AvantLink);
