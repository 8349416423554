import CallUsNumber from '~common/molecules/CallUsNumber';
import React from 'react';
import Icon from '../../../../../common/atoms/Icon';
import { useThemeUI } from 'theme-ui';
import { StandardText } from '../../../../../common/atoms/typography';

export const describeErrorCode = (code, text) => {
  //NOTE: as per https://airteam.atlassian.net/browse/DWM-542 request we are throwing aways all the existing code
  // handlers and returning one generic message
  switch (code) {
    case '515': {
      return {
        title: 'Please be aware',
        type: 'warning',
        message: <UndetectedPaymentResultMessage />,
      };
    }
    default: {
      return {
        message: (
          <>
            It was not possible to complete the payment. Please, try again later
            or contact the Health Fund on{' '}
            <CallUsNumber inline bold color="error" />.
          </>
        ),
      };
    }
  }

  // switch (code) {
  //   case '01':
  //   case '02': {
  //     return 'There is a problem with credit card number. Please contact your bank.';
  //   }
  //   case '03': {
  //     return 'The merchant id is incorrect. Please contact our support.';
  //   }
  //   case '04':
  //   case '07': {
  //     return `Your bank has declined the transaction and requested that your credit card be retained.
  //      (card reported lost or stolen). You should use an alternate credit card.`;
  //   }
  //   case '05': {
  //     return `Your bank has declined the transaction as the credit card number has failed a security check, or the funds have been frozen or depleted. You should use an alternate credit card.`;
  //   }
  //   case '06':
  //   case '09':
  //   case '21':
  //   case '25': {
  //     return `Your bank has declined the transaction as there is a problem with the credit card number.
  //      You should contact your bank.`;
  //   }
  //   case '12': {
  //     return `Some fields are incorrectly formatted. Please contact our support.`;
  //   }
  //
  //   case '13': {
  //     return `Amount is not formatted correctly. Please contact our support.`;
  //   }
  //
  //   case '14':
  //   case '15': {
  //     return `Credit card number does not exist.`;
  //   }
  //
  //   case '19': {
  //     return 'The transaction failed. Please try again';
  //   }
  //
  //   case '22': {
  //     return 'The payment system was not able to reach your bank. Please check your credit card number and try again';
  //   }
  //
  //   case '23': {
  //     return 'Unspecified error occurred. Please contact our support.';
  //   }
  //
  //   case '51': {
  //     return `Insufficient funds on the curd detected. Please ensure you have sufficient funds on the card before you try to pay.`;
  //   }
  //   case '61': {
  //     return `The transaction will exceed your card's limit.`;
  //   }
  //   case '62': {
  //     return `The card is restricted. Please contact your bank.`;
  //   }
  //   case '82': {
  //     return `CVV is invalid. Please check it and try again.`;
  //   }
  //
  //   default: {
  //     return `${text.replace(/[+]/g, '  ')}
  //      Response code: ${code}`;
  //   }
  // }
};

export const getCallbackNotReachedMessage = ({ txnid }) => `
            Your payment was successful but our server have not been reached so your transaction have not been tracked.
             Here is your transaction id: ${txnid}.
             Please contact our support.
             We are sorry for inconvenience.`;

export const UndetectedPaymentResultMessage = () => {
  const { theme } = useThemeUI();
  return (
    <>
      We experienced difficulty confirming this transaction with our payment
      system. It is likely the payment went through, however we advise you to
      check{' '}
      <StandardText color={theme.colors.alert.warning.color} inline bold>
        'Online Activity’
      </StandardText>{' '}
      (under{' '}
      <Icon
        style={{
          display: 'inline-block',
          transform: 'translateY(5px)',
        }}
        name="oms-notification"
        width="18px"
        height="20px"
        fill={theme.colors.alert.warning.color}
      />{' '}
      alerts, top right) shortly for confirmation. Call us at{' '}
      <CallUsNumber color={theme.colors.alert.warning.color} inline bold /> if
      you need help.
    </>
  );
};
