import React from 'react';
import { StandardHeading } from '../../../../common/atoms/typography';
import FeatureToggle from '~common/atoms/FeatureToggle';

import ActionRow from '~OMS/my-details/molecules/ActionRow';
import ReferFriendContent from '~OMS/my-details/organisms/ReferFriendContent';

import WorkingModal from '~common/molecules/WorkingModal';
import { useReferFriend, VIEWS } from '~OMS/my-details/hooks/useReferFriend';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import ReferFriendForm from '~OMS/my-details/organisms/ReferFriendForm';
import { Flex } from 'rebass';
import Loading from '~common/atoms/Loading';
import Divider from '~common/atoms/Divider';

const ReferFriend = () => {
  const {
    state,
    openModal,
    closeModal,
    setView,
    submitReferFriendForm,
    loadPreviewEmail,
  } = useReferFriend();
  return (
    <FeatureToggle
      name="referrals"
      render={
        state.referFriendEligible &&
        (() =>
          state.loadingMyDetails ? (
            <>
              <Divider />
              <Flex width="100%" justifyContent="center" mt={4}>
                <Loading />
              </Flex>
            </>
          ) : (
            <>
              <Divider />
              <ActionRow
                button={{
                  label: 'Email code to friend',
                  onClick: openModal,
                }}
                title="Refer a friend"
                description={
                  <ReferFriendContent
                    referFriendCmsContent={state.referFriendCmsContent}
                    referralCode={state.referralCode}
                    referRef={state.referRef}
                    omsText={state.omsText}
                  />
                }
              />

              <WorkingModal
                visible={state.modalVisible}
                onClose={closeModal}
                height="auto"
              >
                <StandardHeading fontSize={32} bold={true} mb={4}>
                  Refer a friend
                </StandardHeading>
                {state.view === VIEWS.REFER_FRIEND && (
                  <SanityRichTextContent
                    content={state.omsText}
                    interpolate={state.referFriendCmsContent}
                  />
                )}
                <ReferFriendForm
                  emailPreviewLoading={state.loadingPreviewEmail}
                  previewedEmail={state.previewedEmail}
                  onPreviewEmail={loadPreviewEmail}
                  loading={state.loading}
                  referralCode={state.referralCode}
                  onConfirm={closeModal}
                  updateView={setView}
                  view={state.view}
                  disclaimerText={state.omsFormDisclaimer}
                  onSubmit={submitReferFriendForm}
                  onClose={closeModal}
                />
              </WorkingModal>
            </>
          ))
      }
    />
  );
};

export default ReferFriend;
