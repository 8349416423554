import React from 'react';
import Block from '~common/atoms/Block';

const AFBlock = (props, ref) => {
  return (
    <Block
      ref={ref}
      mt={0}
      mb={0}
      px={{
        xs: 16,
        tabletP: 32,
        tabletL: 64,
        lg: 200,
        xl: 239,
      }}
      {...props}
    />
  );
};

export default React.forwardRef(AFBlock);
