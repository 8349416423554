import { Box } from 'rebass';
import styled from '@emotion/styled';

const TYPES = {
  small: 1,
  'medium-small': 2,
  medium: 3,
  big: 5,
};

const Divider = styled(Box)`
  width: 100%;
  height: ${props => TYPES[props.type] || props.type || TYPES.small}px;
  background: ${props => props.theme.colors.borders};
`;

export default Divider;
