import React from 'react';
import { useMobileScreen } from '~lib/responsive';
import { withTheme } from 'emotion-theming';
import MobileScreenFilters from '~OMS/claims/molecules/ClaimsHistoryFilters/MobileScreenFilters';
import DesktopScreenFilters from '~OMS/claims/molecules/ClaimsHistoryFilters/DesktopScreenFilters';

const ClaimsHistoryFilters = props => {
  const isMobileScreen = useMobileScreen();

  return isMobileScreen ? (
    <MobileScreenFilters {...props} />
  ) : (
    <DesktopScreenFilters {...props} />
  );
};

export default withTheme(ClaimsHistoryFilters);
