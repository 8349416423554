import React from 'react';
import 'antd/es/radio/style/index.css';
import { CreateControl } from './Control';
import { noop } from '~lib/util';
import RadioGroup from '~common/atoms/RadioGroup';

const RadioGroupControl = ({
  options = [],
  onChange,
  onSelect = noop,
  content,
  ...props
}) => {
  return (
    <RadioGroup
      onChange={value => {
        onChange(value);
        onSelect(value);
      }}
      options={options}
      {...props}
    >
      {content}
    </RadioGroup>
  );
};

export default CreateControl(RadioGroupControl);
