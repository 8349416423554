import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import CurrentMemberDetailsForm from './CurrentMemberDetailsForm';
import { withTheme } from 'emotion-theming';
import { FirstCol, SecondCol, ThirdCol, Row } from '../atoms/Layout';
import { Alert } from '~common/molecules/AlertBox';
import InviteMemberForm from '~OMS/my-details/organisms/InviteMemberForm';
import MemberDetails from '~OMS/my-details/molecules/MemberDetails';
import CardHeading from '~OMS/my-details/molecules/CardHeading';
import { useMobileScreen } from '~lib/responsive';

const StyledCard = styled(Row)`
  border: 1px solid ${props => props.theme.colors.borders};
  border-radius: 6px;
  flex-direction: column !important;
`;

const renderCorrectMemberView = ({
  onChangeMemberDetails,
  member,
  showErrors,
  loading,
  memberAccessAllowed,
  isCurrentUser,
  alert,
  isMobile,
}) => {
  if (isCurrentUser) {
    return (
      <CurrentMemberDetailsForm
        isMobile={isMobile}
        alert={alert}
        onSubmit={onChangeMemberDetails}
        member={member}
        loading={loading}
      />
    );
  }

  if (memberAccessAllowed && !member.onlineAccess) {
    return (
      <InviteMemberForm
        isMobile={isMobile}
        alert={alert}
        loading={loading}
        onSubmit={onChangeMemberDetails}
        showErrors={showErrors}
        member={member}
      />
    );
  }

  return <MemberDetails member={member} />;
};

const MemberCard = ({
  theme,
  member,
  alert,
  expanded,
  onToggle,
  onInvite,
  isMainMember,
  showErrors,
  onChangeMemberDetails,
  isCurrentUser,
  memberAccessAllowed,
  loading,
  expandedOnLoad,
  isDependentMember,
  inviting,
  ...props
}) => {
  const isMobile = useMobileScreen();
  // on mount, open current user by default
  useEffect(() => {
    if (expandedOnLoad) {
      onToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canBeInvited =
    !member.onlineAccess &&
    memberAccessAllowed &&
    !isCurrentUser &&
    !isDependentMember;

  const handleOnChangeDetails = useCallback(
    details => {
      onChangeMemberDetails(details, canBeInvited);
    },
    [canBeInvited, onChangeMemberDetails]
  );

  return (
    <StyledCard theme={theme} {...props}>
      <CardHeading
        inviting={inviting}
        showInvite={canBeInvited}
        onInvite={onInvite}
        mb={expanded ? 4 : 0}
        member={member}
        expanded={expanded}
        onExpand={onToggle}
        theme={theme}
        isDependentMember={isDependentMember}
      />
      {alert && !isMobile && (
        <Alert mb={40} mt={3} type={alert.type}>
          {alert.message}
        </Alert>
      )}
      {expanded ? (
        <Row p={0} justifyContent="center">
          <FirstCol></FirstCol>
          <SecondCol>
            {renderCorrectMemberView({
              memberAccessAllowed,
              alert,
              member,
              showErrors,
              loading,
              onChangeMemberDetails: handleOnChangeDetails,
              isCurrentUser,
              isMobile,
            })}
          </SecondCol>
          <ThirdCol></ThirdCol>
        </Row>
      ) : null}
    </StyledCard>
  );
};

MemberCard.propTypes = {
  onInvite: PropTypes.func,
  onToggle: PropTypes.func.isRequired,
  expanded: PropTypes.bool,
  showErrors: PropTypes.bool,
  alert: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
  memberAccessAllowed: PropTypes.bool,
  isMainMember: PropTypes.bool,
  isDependentMember: PropTypes.bool,
  member: PropTypes.shape({
    id: PropTypes.any,
    email: PropTypes.string,
    onlineAccess: PropTypes.bool,
    dob: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    mobilePhone: PropTypes.string,
    workPhone: PropTypes.string,
    emailVerified: PropTypes.bool,
    mobilePhoneVerified: PropTypes.bool,
    isStudentDependant: PropTypes.bool,
    isStudentNonDependant: PropTypes.bool,
  }).isRequired,
  theme: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  inviting: PropTypes.bool,
};

export default withTheme(MemberCard);
