import usePaymentStep from '~maf/state/usePaymentsStep';
import usePaymentSerializer from './usePaymentSerializer';

//reuse the existing usePaymentStep but inject custom payment serializer
export default (...args) => {
  return usePaymentStep(args[0], {
    ...args[1],
    usePaymentSerializer,
  });
};
