import React from 'react';
import { useThemeUI } from 'theme-ui';
import Icon from '~common/atoms/Icon';
import { StandardText } from '~common/atoms/typography';
import { Button } from '~common/atoms/Button';
import WarningModal from './WarningModal';

const AddDependantsModal = ({ type, onPackageSwitch, onAddDependants }) => {
  const { theme } = useThemeUI();
  return (
    <WarningModal onConfirmRecalculateInfo={onPackageSwitch}>
      {({ showRecalculateInfo }) => (
        <>
          <Icon
            fill={theme.colors.error}
            name="stop-info"
            height="65px"
            width="65px"
          />
          <StandardText fontSize={31} lineHeight={1.2} my={32}>
            Add dependants
          </StandardText>

          <StandardText lineHeight={1.2} fontSize={2} mb={32}>
            You have selected {type === 'FAMILY' ? 'Family' : 'Single parent'}{' '}
            cover. Please enter the details for every dependant you wish to
            cover on this policy before you continue.
          </StandardText>

          <StandardText bold fontSize={2} mb={20} lineHeight={1.2}>
            You have not entered any dependants over 21 that are not studying.
            Do you wish to remove Extended Family Cover?
          </StandardText>

          <Button
            width={1}
            variant="orangeRoundThin"
            mb={20}
            onClick={onAddDependants}
          >
            OK, Add dependant details
          </Button>
          <Button width={1} variant="white-round" onClick={showRecalculateInfo}>
            Switch to {type === 'FAMILY' ? 'Couples' : 'Single'} cover
          </Button>
        </>
      )}
    </WarningModal>
  );
};

export default AddDependantsModal;
