import React from 'react';
import NumberFormat from 'react-number-format';
import styled from '@emotion/styled';
import { Flex, Text } from 'rebass';
import PropTypes from 'prop-types';
import useModalState from '~lib/hooks/useModalState';
import ToggleSwitch from 'components/Elements/ToggleSwitch';
import InclusionsModal from '~lib/CoverProducts/InclusionsModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 39px;
  margin-top: 12px;
  background: #fff;
  min-height: 360px;
  & > div {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    justify-content: space-between;
  }

  @media screen and (max-width: 340px) {
    padding: 39px 20px;
  }
`;

const Title = styled.span`
  font-size: 30px;
  font-weight: 700;
  color: ${props => props.theme.colors[props.type] || '#000'};
  text-align: center;
  vertical-align: middle;
  margin: 16px 0 10px 0;

  span {
    font-weight: 400;

    @media screen and (max-width: 768px) {
      display: block;
    }
  }
`;

const Price = styled.span`
  font-size: 32px;
  color: ${props => props.theme.colors.text.black};
  text-align: center;
  vertical-align: middle;
  margin: 35px 0;

  sup {
    color: #4a4a4a;
  }
`;

const Term = styled.span`
  margin-left: 4px;
  font-size: 12px;
  position: relative;
  display: inline;
  vertical-align: middle;
  text-transform: lowercase;
  color: ${props => props.theme.colors.black};
`;

const Description = styled.div`
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 1.31;
  text-align: center;
  color: #4a4a4a;
  max-width: 314px;
  margin: 11px auto 0 auto;
`;

const ProductDetail = props => {
  const { modalState, openModal, closeModal } = useModalState(false);

  if (!props.variant) {
    return null;
  }

  const hasExcess = !!props?.variant?.excess;
  const variants = props.product?.variants?.filter(v => {
    if (!v.onSale) {
      return false;
    }

    if (v.classes && v.classes.length) {
      return v.classes.includes(props.status);
    }
    return true;
  });
  const excessVariants = {
    display: variants.length > 1 && variants.some(v => v.excess),
  };

  if (excessVariants.display) {
    excessVariants.values = variants
      .sort((a, b) => {
        if (a.excess < b.excess) {
          return -1;
        }
        if (a.excess > b.excess) {
          return 1;
        }
        return 0;
      })
      .map(v => {
        return {
          label: v.excess ? `$${v.excess} excess` : 'No excess',
          value: v.code,
        };
      });
  }

  const paymentFrequencyLabel = {
    WEEKLY: 'per week',
    MONTHLY: 'per month',
    QUARTERLY: 'per quarter',
    HALF_YEARLY: 'per 6 months',
    YEARLY: 'per year',
  }[props.paymentFrequency];

  return (
    <Wrapper
      data-id={`product-card-details-${props.product.type}`}
      className="product-details"
    >
      <div>
        <Flex flexDirection="column">
          <Title type={props.product.type}>
            {props.product.title}{' '}
            {props.product.type === 'hospital' && props.product.coverage && (
              <span>{props.product.coverage}</span>
            )}
          </Title>
          <Description>{props?.product?.description}</Description>
        </Flex>
        <Flex flexDirection="column">
          {props.variant && props?.price?.net ? (
            <Price>
              <NumberFormat
                value={props.price.net}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />
              <Term>{paymentFrequencyLabel}</Term>
            </Price>
          ) : (
            <Price>
              FREE
              {!!props.price?.original && (
                <Text textAlign="center" fontSize="14px" mt="8px">
                  (usually{' '}
                  <NumberFormat
                    value={props.price.original}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />{' '}
                  {paymentFrequencyLabel})
                </Text>
              )}
            </Price>
          )}
          {excessVariants.display ? (
            <Flex justifyContent="center">
              <ToggleSwitch
                id={`${props.product.type}-excess-switch`}
                data-id={`${props.product.type}-excess-switch`}
                ariaLabel="Excess"
                defaultValue={props.variant.code}
                values={excessVariants.values}
                onChange={props.onChange}
              />
            </Flex>
          ) : hasExcess ? (
            <Flex justifyContent="center">
              <ToggleSwitch
                name={props.variant.code}
                id={props.variant.code}
                ariaLabel="Excess"
                defaultValue={props.variant.code}
                values={[
                  {
                    label: props.variant.excess
                      ? `$${props.variant.excess} excess`
                      : 'No excess',
                    value: props.variant.code,
                  },
                ]}
              />
            </Flex>
          ) : null}
        </Flex>

        <Flex
          mt="40px"
          className="show-mobile-block"
          style={{ position: 'relative' }}
        >
          <Text
            color="blue"
            fontWeight="bold"
            fontSize={2}
            textAlign="center"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={openModal}
          >
            What's included?
          </Text>
        </Flex>

        <InclusionsModal
          onProductChange={props.onChange}
          isOpen={modalState}
          onClose={closeModal}
          product={props.product}
          products={props.products}
        />
      </div>
    </Wrapper>
  );
};

ProductDetail.propTypes = {
  onChange: PropTypes.any.isRequired,
  paymentFrequency: PropTypes.any.isRequired,
  product: PropTypes.any,
  products: PropTypes.any.isRequired,
  rate: PropTypes.any,
  status: PropTypes.any.isRequired,
  variant: PropTypes.any,
};

export default ProductDetail;
