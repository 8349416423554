import React from 'react';
import { withTheme } from 'emotion-theming';
import { Flex, Box } from 'rebass';
import { Link } from 'gatsby';
import { Formik, Field } from 'formik';
import FormTitle from '~OMS/styles/FormTitle';
import FormText from '~OMS/styles/FormText';
import SSOFormContainer from '~OMS/styles/SSOFormContainer';
import {
  InputControl,
  validators,
} from '~common/molecules/form-controls/index';
import AlertBox from '~common/molecules/AlertBox';
import { Button } from '~common/atoms/Button';
import { noop } from '~lib/util';
import { Element } from 'react-scroll';
import useForgotPassword from '~OMS/SSO/hooks/useForgotPassword';
import * as Yup from 'yup';

const initialForm = {
  email: '',
};

const validation = Yup.object().shape({
  email: validators.email(),
});

function ForgotPasswordWrapper() {
  const { state, actions } = useForgotPassword();

  const { loading, error } = state;
  const { forgotPassword } = actions;

  return (
    <SSOFormContainer>
      <FormTitle>Forgot password</FormTitle>
      <Box width={1} mb="20px" mt="20px">
        <FormText>We will send you a password reset email.</FormText>
      </Box>
      <Formik
        initialValues={initialForm}
        validationSchema={validation}
        onSubmit={forgotPassword}
      >
        {({ handleSubmit, handleChange, values, handleBlur }) => (
          <Element name="form">
            <form onSubmit={handleSubmit}>
              <Field
                component={InputControl}
                label="Enter your email address"
                name="email"
                infoIcon="Once you have set an email address as your new user name you can no longer use your membership number to login."
                onFocus={noop}
                trim
              />

              <Flex flexDirection={{ xs: 'column', lg: 'row' }}>
                <Box
                  width={{ md: '100%', lg: '48%' }}
                  mb={{
                    xs: 2,
                    md: 0,
                  }}
                  mr={{ xs: '0', lg: '10px' }}
                >
                  <Button
                    variant="primaryInverted"
                    css={{ width: '100%' }}
                    as={Link}
                    to="/oms/sso/login"
                  >
                    Cancel
                  </Button>
                </Box>
                <Box
                  width={{ md: '100%', lg: '48%' }}
                  mb="30px"
                  ml={{ xs: '0', lg: '10px' }}
                >
                  <Button
                    loading={loading}
                    type="submit"
                    variant="primary"
                    css={{ width: '100%' }}
                  >
                    Reset password
                  </Button>
                </Box>
              </Flex>
            </form>
          </Element>
        )}
      </Formik>
      {error && <AlertBox error={error.message} />}
    </SSOFormContainer>
  );
}

export default withTheme(ForgotPasswordWrapper);
