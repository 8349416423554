import { useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { find, flatten, map, pathOr, pipe, whereEq, path } from 'lodash/fp';
import { ExtrasUsageBasicQuery } from 'queries/oms/extrasUsage';

const promoTextMap = {
  'extras-cover-total-extras':
    'You have Total Extras, which is our most comprehensive extras cover. This cover provides you with a range of premium benefits (like major dental) and high annual claim limits. To view a detailed breakdown of your remaining limits, visit the ‘Extras Usage’ page.',
  'extras-cover-essential-extras':
    'You have Essential Extras, this mid-level cover provides you with a comprehensive range of of benefits, including generous dental and optical limits. To view a detailed breakdown of your remaining limits, visit the ‘Extras Usage’ page.',
  'extras-cover-starter-extras':
    'You have Starter Extras, this cover provides you with services that help you stay in good health like general dental, physiotherapy and remedial massage. To view a detailed breakdown of your remaining limits, visit the ‘Extras Usage’ page.',
};

const useClaimsExtras = () => {
  const { data, loading } = useQuery(ExtrasUsageBasicQuery);
  const [selectedUsageId, setSelectedUsage] = useState(undefined);

  const usage = useMemo(() => {
    if (!data) {
      return [];
    }

    const personId = data.oms.user.personId;
    const usageTable = pipe(
      pathOr([], 'oms.extrasUsage.tables'),
      find(
        whereEq({
          id: personId,
        })
      ),
      pathOr({}, 'rows'),
      map(pathOr([], 'benefits')),
      flatten,
      map(benefit => ({
        id: benefit.title,
        label: benefit.title,
        ...benefit,
      }))
    )(data);

    return usageTable;
  }, [data]);

  const selectedUsage = useMemo(
    () =>
      (usage || []).find(
        whereEq({
          id: selectedUsageId,
        })
      ),
    [selectedUsageId, usage]
  );

  useEffect(() => {
    if (!usage || !usage.length) {
      return;
    }

    setSelectedUsage(usage[0].id);
  }, [usage]);

  const extrasPromoText = useMemo(() => {
    if (!data) {
      return '';
    }
    return promoTextMap[path('oms.membership.plan.extrasProduct.id', data)];
  }, [data]);

  return {
    state: {
      selectedUsage,
      usage,
      loading,
      extrasPromoText,
    },
    actions: {
      setSelectedUsage,
    },
  };
};

export default useClaimsExtras;
