import { Flex } from 'rebass';
import styled from '@emotion/styled';
import { Button } from '~common/atoms/Button';

//TODO: move this file to common attoms
export const FormButton = styled(Button)`
  width: 100%;
  ${props => props.theme.mq.md} {
    width: calc(
      50% - ${props => (props.noSiblings ? 0 : props.theme.space[2] / 2)}px
    );
  }

  transition: background 200ms ease-in-out;

  &[disabled] {
    background: ${props => props.theme.colors.background.light};
    pointer-events: none;
    cursor: auto;
    color: ${props => props.theme.colors.text.light};
  }
`;

export const FormOkButton = styled(FormButton)`
  margin-bottom: ${props => props.theme.space[3]}px;
  ${props => props.theme.mq.md} {
    margin: 0 ${props => props.theme.space[2]}px 0 0;
  }
`;

export const FormConfirmWrapper = styled(Flex)`
  justify-content: ${props => props.justifyContent || 'space-between'};
  flex-wrap: wrap;
`;
