import React from 'react';
import { Flex } from 'rebass';
import Column from './Column';
import Block, { defaultBlockSpacing } from '../../common/atoms/Block';

const Columns = ({ data, metaData }) => {
  const { columns } = data;
  return (
    <Block mb={0} mt={0} {...defaultBlockSpacing}>
      <Flex
        flexDirection={{
          xs: 'column',
          tabletP: 'row',
        }}
      >
        {columns.map((column, index) => (
          <Column
            key={column._key}
            metaData={{
              ...metaData,
              context: data,
            }}
            data={column}
            index={index}
          />
        ))}
      </Flex>
    </Block>
  );
};

export default Columns;
