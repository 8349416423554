import WorkingModal from '~common/molecules/WorkingModal';
import ModalConfirmation from '~common/molecules/ModalConfirmation';
import React from 'react';
import useNourishBaby from '~lib/hooks/sanity/useNourishBaby';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import styled from '@emotion/styled';

const ModalText = styled(SanityRichTextContent)`
  &&&&& .text-block {
    color: ${props => props.theme.colors.text.light2};
  }
`;

export default ({
  animation,
  visible,
  onClose,
  onConfirm,
  loading,
  ...props
}) => {
  const response = useNourishBaby();
  return (
    <WorkingModal
      animation={animation}
      height="auto"
      minHeight="200px"
      centerV
      visible={visible}
      onClose={onClose}
      data-id="nourish-baby-modal"
      {...props}
    >
      <ModalConfirmation
        title={response?.modalTitle || ''}
        message={<ModalText content={response?.modalText} />}
        confirmText="Yes"
        cancelText="No"
        onConfirm={onConfirm}
        confirmLoading={loading}
        onCancel={onClose}
      />
    </WorkingModal>
  );
};
