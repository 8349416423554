import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const getStatusText = status => {
  switch (status) {
    case 'SINGLE':
      return 'single person';
    case 'COUPLE':
      return 'couple';
    case 'FAMILY':
      return 'family';
    case 'SINGLE_PARENT':
      return 'single parent';
  }
};

const StyledText = styled.p`
  font-size: ${props => props.theme.fontSizes[4]}px;
  text-align: center;
  margin-top: 24px;
`;

const StyledSelection = styled.span`
  color: ${props => props.theme.colors.primary};
  font-weight: 600;
`;

const StyledButton = styled.button`
  ${props => props.theme.buttons.primaryLink};
`;

const Description = ({ form, setFormVisible }) => {
  const status = getStatusText(form.status.value);
  const region = form.region.label;
  const income = form.tier.label.toLowerCase();

  return (
    <StyledText>
      Based on a <StyledSelection>{status}</StyledSelection>, living in{' '}
      <StyledSelection>{region}</StyledSelection>, with an annual income of{' '}
      <StyledSelection>{income}</StyledSelection>.{' '}
      <StyledButton
        data-id="edit-details-btn"
        onClick={() => setFormVisible(true)}
      >
        Edit details
      </StyledButton>
    </StyledText>
  );
};

Description.propTypes = {
  form: PropTypes.object.isRequired,
  setFormVisible: PropTypes.func.isRequired,
};

export default Description;
