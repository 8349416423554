import { now, toDate } from '~lib/dates';

export const youthDiscountSchema = new Map();

youthDiscountSchema.set([18, 25], 10);
youthDiscountSchema.set([26], 8);
youthDiscountSchema.set([27], 6);
youthDiscountSchema.set([28], 4);
youthDiscountSchema.set([29], 2);
youthDiscountSchema.set([30], 0);

export const calculateYouthDiscountPercentage = dob => {
  const age = now().diff(toDate(dob), 'year');
  const entries = youthDiscountSchema.entries();
  for (const entry of entries) {
    const [range, percentage] = entry;
    const [from, to = from] = range;
    if (from <= age && age <= to) {
      return percentage;
    }
  }

  return null;
};
