import React from 'react';
import { Flex } from 'rebass';
import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';

const makeCol =
  (width, config) =>
  ({ children, ...props }) =>
    (
      <Flex {...config} {...props} width={width}>
        {children || ' '}
      </Flex>
    );

const CONTENT_WIDTH = {
  xs: 1,
  md: 489,
};
const SIDES_WIDTH = {
  xs: 1,
  md: 223,
};

export const FirstCol = makeCol(SIDES_WIDTH);
export const SecondCol = makeCol(CONTENT_WIDTH);
export const ThirdCol = makeCol(SIDES_WIDTH, {
  pl: {
    xs: 0,
    md: 3,
  },
});

const StyledContainer = styled(Flex)`
  ${props => props.theme.mq.mdInverted} {
    > * {
      &:not(:last-child) {
        margin-bottom: ${props => props.theme.space[3]}px;
      }
    }
  }
`;

export const Row = withTheme(props => {
  const { children, ...rest } = props;
  return (
    <StyledContainer
      p={3}
      flexDirection={{
        xs: 'column',
        md: 'row',
      }}
      {...rest}
    >
      {children}
    </StyledContainer>
  );
});
