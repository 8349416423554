import React from 'react';
import { Flex } from 'rebass';
import { P1 } from '~common/atoms/typography';
import Icon from '~common/atoms/Icon';
import styled from '@emotion/styled';
import useToggle from '~lib/hooks/useToggle';
import Block from '~common/atoms/Block';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import Heading from './Heading';
import { useThemeUI } from 'theme-ui';

const StyledAccordionItem = styled(Flex)`
  padding: 17px 20px;
  border-bottom: ${props =>
    props.lastItem ? 'none' : `1px solid ${props.theme.colors.neutral100}`};
  border-top: ${props =>
    props.addTopBorder ? `1px solid ${props.theme.colors.neutral100}` : 'none'};
`;

const ExpandIcon = ({ expanded }) => {
  const { theme } = useThemeUI();

  return (
    <Icon
      name={expanded ? 'chevron-up' : 'chevron-down'}
      clickable
      fill={theme.colors.black}
      width="21px"
    />
  );
};

const ItemTitle = styled(Flex)`
  cursor: pointer;
`;

const AccordionContent = styled(SanityRichTextContent)`
  margin-top: 17px;
`;

const AccordionItem = ({ item, index, isLast }) => {
  const [expanded, toggle] = useToggle(false);
  return (
    <StyledAccordionItem
      lastItem={isLast}
      flexDirection="column"
      addTopBorder={index === 0}
    >
      <ItemTitle
        width={1}
        onClick={() => {
          toggle();
        }}
        alignItems="center"
        justifyContent="space-between"
      >
        <P1 width="80%" variant="semiBold" color="neutral900">
          {item.title}
        </P1>

        <ExpandIcon expanded={expanded} />
      </ItemTitle>
      {expanded && <AccordionContent content={item.content} />}
    </StyledAccordionItem>
  );
};

const Accordion = ({ data: { items = [], title }, style }) => {
  return (
    <Block style={style}>
      <Flex flexDirection="column">
        {title && (
          <Heading
            variant="semiBold"
            textAlign="left"
            color="neutral900"
            mb="24px"
            data={{
              level: 'h3',
              text: title,
            }}
          />
        )}
        {items.map((item, index) => (
          <AccordionItem
            isLast={index === items.length - 1}
            key={item._key}
            item={item}
            index={index}
          />
        ))}
      </Flex>
    </Block>
  );
};

export default Accordion;
