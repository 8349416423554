import React from 'react';
import Layout from 'components/Layout';
import VerificationWrapper from '~OMS/SSO/organisms/VerificationWrapper';
import { withTheme } from 'emotion-theming';

const StepOne = props => {
  return (
    <Layout>
      <VerificationWrapper step={1} location={props.location} />
    </Layout>
  );
};

export default withTheme(StepOne);
