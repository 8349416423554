import styled from '@emotion/styled';
import { themePirmaryBorder, themePirmaryLight } from './colors';

const InputField = styled.input`
  outline: none;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  min-height: 50px;
  border: solid 2px ${themePirmaryBorder};
  background-color: ${themePirmaryLight};
  ${props => props.inputCss}
`;

export default InputField;
