import React, { useContext } from 'react';
import { AuthContext } from '../../../context/sso';
import CenterBox from '~common/atoms/CenterBox';
import Loading from '~common/atoms/Loading';

const LogoutPage = () => {
  const { logout } = useContext(AuthContext);
  logout();
  return (
    <CenterBox>
      <Loading size="large" />
    </CenterBox>
  );
};

export default LogoutPage;
