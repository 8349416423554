import React from 'react';
import ActionWithIcon from '~common/atoms/ActionWithIcon';
import Link from '~common/atoms/Link';

const GetAQuoteWithIcon = ({ iconProps, ...props }) => {
  return (
    <ActionWithIcon
      as={Link}
      to="/quote"
      noStyles
      text="Get a quote"
      iconProps={{
        name: 'get-a-quote',
        ...iconProps,
      }}
      {...props}
    />
  );
};

export default GetAQuoteWithIcon;
