import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import styled from '@emotion/styled';
import Icon from '~common/atoms/Icon';
import { useThemeUI } from 'theme-ui';

const selectedTextColor = props => props.theme.colors.text.light;

const PaginationItem = styled(Flex)`
  height: 24px;
  width: 24px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.background.light};
  transition: all 600ms ease-out;
  color: ${selectedTextColor};
  cursor: pointer;
  overflow: hidden;
  ${props =>
    props.selected
      ? `
    font-weight: bold;
    color: ${props.theme.colors.white};
    background: ${props.theme.colors.background.primary};

  `
      : ''}
  ${props =>
    props.disabled
      ? `
    pointer-events: none;
    cursor: not-allowed;

  `
      : ''}
`;

const StyledNavigateButton = styled(Icon)`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const NavigateButton = ({ type, theme, ...props }) => {
  return (
    <StyledNavigateButton
      width="24px"
      height="24px"
      fill={theme.colors.text.light}
      name={type}
      {...props}
    />
  );
};

const Pagination = ({
  pageSize = 0,
  totalItems = 0,
  onPageSelect,
  onNext,
  onPrevious,
  selected,
}) => {
  const { theme } = useThemeUI();
  const handleOnPageSelect = useCallback(
    pageNumber => handler => {
      handler({
        pageNumber,
        pageSize,
        totalItems,
      });
    },
    [pageSize, totalItems]
  );

  const numberOfPages = Math.ceil(totalItems / pageSize);

  if (!totalItems || numberOfPages === 1) {
    return null;
  }

  return (
    <Flex>
      <NavigateButton
        type="previous"
        disabled={selected === 1}
        theme={theme}
        onClick={() => {
          if (selected === 1) {
            return;
          }
          handleOnPageSelect(selected - 1)(onPrevious);
        }}
      />
      {Array.from({ length: numberOfPages }).map((_, index) => (
        <PaginationItem
          key={index}
          selected={selected === index + 1}
          onClick={() => {
            handleOnPageSelect(index + 1)(onPageSelect);
          }}
          ml={index === 0 ? '1px' : '0'}
          mr="1px"
        >
          {index + 1}
        </PaginationItem>
      ))}
      <NavigateButton
        type="next"
        disabled={selected === numberOfPages}
        theme={theme}
        onClick={() => {
          if (selected === numberOfPages) {
            return;
          }
          handleOnPageSelect(selected + 1)(onNext);
        }}
      />
    </Flex>
  );
};

Pagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  totalItems: PropTypes.number,
  selected: PropTypes.number,
  onPageSelect: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
};

export default React.memo(Pagination);
