import React from 'react';
import { Field, Form, Formik } from 'formik';
import PaymentFrequencyField from '~OMS/payments/molecules/PaymentFrequencyField';
import Confirmation from '~common/molecules/Confirmation';
import formModeSwitch from '~lib/hocs/formModeSwitch';
import BoldReadOnlyControl from '~common/molecules/form-controls/BoldReadOnlyControl';
import CenterBox from '~common/atoms/CenterBox';
import ConfirmButton from '~OMS/payments/atoms/ConfirmButton';
import { pipe } from 'lodash/fp';
import { getFrequencyLabel } from '~OMS/payments/organisms/PaymentPolicyForm/formMappers';
import { Alert } from '~common/molecules/AlertBox';
import { invoiceFrequencies, PAYMENT_METHODS } from '~OMS/payments/constants';

const EditForm = ({
  onSubmit,
  onCancel,
  initialForm,
  loading,
  alert,
  isActivePaymentMethod,
  changePaymentMethodWarningText,
  isPayroll,
}) => (
  <Formik onSubmit={onSubmit} initialValues={initialForm}>
    {() => (
      <Form className="full-width">
        <PaymentFrequencyField
          name="frequency"
          paymentMethod={PAYMENT_METHODS.INVOICE}
        />

        {changePaymentMethodWarningText && (
          <Alert mb={2} type={changePaymentMethodWarningText.type}>
            {changePaymentMethodWarningText.message}
          </Alert>
        )}
        {alert && alert.type !== 'success' && (
          <Alert mb={4} type={alert.type}>
            {alert.message}
          </Alert>
        )}
        <Confirmation
          withoutCancel={!isActivePaymentMethod || isPayroll}
          loading={loading}
          onCancel={onCancel}
        />
      </Form>
    )}
  </Formik>
);

const ViewForm = ({ onEditDetails, initialForm, alert }) => (
  <Formik initialValues={initialForm} onSubmit={onEditDetails}>
    {() => (
      <Form className="full-width">
        <Field
          name="frequency"
          label="Payment frequency"
          labelPosition="left"
          component={BoldReadOnlyControl}
        />

        {alert && alert.type === 'success' && (
          <Alert mb={4} type={alert.type}>
            {alert.message}
          </Alert>
        )}
        <CenterBox>
          <ConfirmButton onClick={onEditDetails}>Edit details</ConfirmButton>
        </CenterBox>
      </Form>
    )}
  </Formik>
);

export default formModeSwitch({
  edit: {
    Component: EditForm,
    mapInitialForm: (form = {}) => ({
      frequency: invoiceFrequencies.find(
        frequency => frequency.id === form.frequency
      )
        ? form.frequency
        : invoiceFrequencies[0].id,
    }),
  },
  view: {
    Component: ViewForm,
    mapInitialForm: pipe(getFrequencyLabel, label => ({
      frequency: label,
    })),
  },
});
