import { pathOr } from '~lib/util';
import { StandardText } from '../../../../common/atoms/typography';
import { Flex } from 'rebass';
import ProductCard from '../../../Products/ProductCard';
import React from 'react';
import HeaderLine from '~common/atoms/HeaderLine';
import styled from '@emotion/styled';

const StyledProductCard = styled(ProductCard)`
  box-shadow: none;
  border: 1px solid ${props => props.theme.colors.borders};
`;

const NoExtrasUsage = ({ coverProducts }) => {
  const products = pathOr([], 'validProducts.extras', coverProducts);

  const selectedHospitalCover = `${pathOr(
    '',
    'selections.hospital.product.title',
    coverProducts
  )} ${pathOr('', 'selections.hospital.product.coverage', coverProducts)}`;

  let message = '';

  if (products.length) {
    if (products.length === 1) {
      message = `With ${selectedHospitalCover} you can include the following option:`;
    } else {
      message = `With ${selectedHospitalCover} you can include one of the following ${products.length} options:`;
    }
  }

  return (
    <>
      <HeaderLine title="Extras cover" mb={4} />
      <StandardText fontSize={3} textAlign="center" color="light" mb={4}>
        Your current cover does not include Extras
      </StandardText>
      <StandardText fontSize={3} textAlign="center" mb={4}>
        {message}
      </StandardText>

      <Flex
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
      >
        {products.map((product, index) => (
          <StyledProductCard
            ml={{
              xs: 0,
              md: index === product.length - 1 ? 0 : 3,
            }}
            key={product.id}
            product={product}
          />
        ))}
      </Flex>
    </>
  );
};

export default NoExtrasUsage;
