import React from 'react';
import { StandardText } from '../../../../common/atoms/typography';
import { currency } from '~lib/filters';
import { CLAIM_STATUS } from '~OMS/claims/constants';
import { useThemeUI } from 'theme-ui';

const ClaimBenefit = ({ status, benefit, ...props }) => {
  const { theme } = useThemeUI();
  if (status === CLAIM_STATUS.PROCESSED && !benefit) {
    return <StandardText {...props}>Nil Benefit</StandardText>;
  }

  if (status === CLAIM_STATUS.PROCESSED) {
    return <StandardText {...props}>{currency(benefit)}</StandardText>;
  }

  return (
    <StandardText color={theme.colors.primary} {...props}>
      Pending
    </StandardText>
  );
};

export default ClaimBenefit;
