import { useQuery } from '@apollo/client';
import { isUpgradeCoverEligible } from '~OMS/response-selectors';
import { MembershipQuery } from 'queries/oms/common.graphql';
import useFeatureToggle from '~lib/hooks/useFeatureToggle';

export default () => {
  const { data, loading } = useQuery(MembershipQuery);
  const { disableSubsidisedExtrasPopup } = useFeatureToggle();

  return {
    isEligible: isUpgradeCoverEligible(data),
    disableSubsidisedExtrasPopup,
    loading,
  };
};
