import { graphql, useStaticQuery } from 'gatsby';

const NOURISH_BABY_QUERY = graphql`
  query NourishBabyQuery {
    sanityNourishBaby {
      modalTitle
      modalText: _rawModalText
    }
  }
`;

export default () => {
  const result = useStaticQuery(NOURISH_BABY_QUERY);
  return result.sanityNourishBaby;
};
