import { APPLICATION_FORM_STEPS } from '../constants';
import { STEP_STATE } from '~lib/constants';
import { path, pipe, whereEq, find } from 'lodash/fp';
import { noop } from '~lib/util';

const getConfig = step =>
  find(
    whereEq({
      value: step.value,
    })
  );

export default ({
  allStepsEnumerated,
  getStepStorageKey = step => `appForm:${step.value}`,
  ...applicationFormContext
}) => {
  const stepProvider = new Map();
  const createStep = (step, stepInitData) => {
    const config = getConfig(step)(allStepsEnumerated);
    if (!stepProvider.has(step.value) && config) {
      stepProvider.set(step.value, {
        ...getConfig(step)(allStepsEnumerated),
        ...stepInitData,
        get isValid() {
          return stepInitData.context.form.isValid;
        },
      });
    }
  };

  const getStepContext = step => {
    const stepHandler = stepProvider.get(step.value);
    return path('context')(stepHandler);
  };

  const getConfigProp = propPath => step => {
    const getProp = path(propPath);
    return pipe(getConfig(step), getProp)(allStepsEnumerated);
  };

  const getDedicatedHook = step => getConfigProp('hook')(step) || noop;
  const getDedicatedHookInput = getConfigProp('hookInput');

  createStep(APPLICATION_FORM_STEPS.ELIGIBILITY, {
    context: getDedicatedHook(APPLICATION_FORM_STEPS.ELIGIBILITY)(
      applicationFormContext,
      {
        persistKey: getStepStorageKey(APPLICATION_FORM_STEPS.ELIGIBILITY),
        ...getDedicatedHookInput(APPLICATION_FORM_STEPS.ELIGIBILITY),
      }
    ),
  });

  createStep(APPLICATION_FORM_STEPS.YOUR_DETAILS, {
    context: getDedicatedHook(APPLICATION_FORM_STEPS.YOUR_DETAILS)(
      applicationFormContext,
      {
        persistKey: getStepStorageKey(APPLICATION_FORM_STEPS.YOUR_DETAILS),
        ...getDedicatedHookInput(APPLICATION_FORM_STEPS.YOUR_DETAILS),
      }
    ),
  });

  createStep(APPLICATION_FORM_STEPS.FAMILY_DETAILS, {
    context: getDedicatedHook(APPLICATION_FORM_STEPS.FAMILY_DETAILS)(
      applicationFormContext,
      {
        yourDetailsContext: getStepContext(APPLICATION_FORM_STEPS.YOUR_DETAILS),
        persistKey: getStepStorageKey(APPLICATION_FORM_STEPS.FAMILY_DETAILS),
        ...getDedicatedHookInput(APPLICATION_FORM_STEPS.FAMILY_DETAILS),
      }
    ),
  });

  createStep(APPLICATION_FORM_STEPS.MEDICARE, {
    context: getDedicatedHook(APPLICATION_FORM_STEPS.MEDICARE)(
      applicationFormContext,
      {
        yourDetailsContext: getStepContext(APPLICATION_FORM_STEPS.YOUR_DETAILS),
        partnerDetailsContext: getStepContext(
          APPLICATION_FORM_STEPS.FAMILY_DETAILS
        ),
        persistKey: getStepStorageKey(APPLICATION_FORM_STEPS.MEDICARE),
        ...getDedicatedHookInput(APPLICATION_FORM_STEPS.MEDICARE),
      }
    ),
  });

  createStep(APPLICATION_FORM_STEPS.ADDITIONAL_DETAILS, {
    context: getDedicatedHook(APPLICATION_FORM_STEPS.ADDITIONAL_DETAILS)(
      applicationFormContext,
      {
        persistKey: getStepStorageKey(
          APPLICATION_FORM_STEPS.ADDITIONAL_DETAILS
        ),
        eligibilityContext: getStepContext(APPLICATION_FORM_STEPS.ELIGIBILITY),
        yourDetailsContext: getStepContext(APPLICATION_FORM_STEPS.YOUR_DETAILS),
        ...getDedicatedHookInput(APPLICATION_FORM_STEPS.ADDITIONAL_DETAILS),
      }
    ),
  });

  createStep(APPLICATION_FORM_STEPS.PAYMENTS, {
    context: getDedicatedHook(APPLICATION_FORM_STEPS.PAYMENTS)(
      applicationFormContext,
      {
        yourDetailsContext: getStepContext(APPLICATION_FORM_STEPS.YOUR_DETAILS),
        persistKey: getStepStorageKey(APPLICATION_FORM_STEPS.PAYMENTS),
        ...getDedicatedHookInput(APPLICATION_FORM_STEPS.PAYMENTS),
      }
    ),
  });

  const insurancePackage = path('criteria.status.value')(
    applicationFormContext
  );

  const allSteps = allStepsEnumerated.map(step => {
    const context = path('context')(stepProvider.get(step.value));
    const isValid = path('form.isValid')(context);
    return {
      ...step,
      isValid,
      label:
        step.value === APPLICATION_FORM_STEPS.FAMILY_DETAILS.value &&
        insurancePackage === 'COUPLE'
          ? 'Partner details'
          : step.label,
      context,
      state: (() => {
        if (isValid && applicationFormContext.confirmedSteps[step.value]) {
          return applicationFormContext.activeStep.value === step.value
            ? STEP_STATE.COMPLETED_ACTIVE
            : STEP_STATE.COMPLETED_INACTIVE;
        }
        return applicationFormContext.activeStep.value === step.value
          ? STEP_STATE.ACTIVE
          : STEP_STATE.INACTIVE;
      })(),
    };
  });

  const availableSteps = allSteps.filter(({ value }) =>
    insurancePackage === 'SINGLE'
      ? value !== APPLICATION_FORM_STEPS.FAMILY_DETAILS.value
      : true
  );

  return {
    stepProvider,
    steps: availableSteps,
    allSteps,
    getStepContext,
    activeStepProvider: stepProvider.get(
      applicationFormContext.activeStep.value
    ),
  };
};
