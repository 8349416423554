import { StandardText } from '../../common/atoms/typography';
import { path } from 'lodash/fp';
import Icon from '../../common/atoms/Icon';
import CallUsNumber from '~common/molecules/CallUsNumber';
import Link from '../../common/atoms/Link';
import React from 'react';
import { Flex, Box } from 'rebass';
import ContactUsBlock from './ContactUsBlock';
import { SectionTitle } from './Styles';
import { useMobileScreenOnly } from '~lib/responsive';
import { useLocale } from '~common/locales';

const ReachToUsTitle = ({ icon, title, ...props }) => {
  return (
    <Flex mb={27} alignItems="center" {...props}>
      {icon}
      <SectionTitle ml={16}>{title}</SectionTitle>
    </Flex>
  );
};

const ReachToUsText = props => (
  <StandardText fontSize={4} lineHeight={1.31} {...props} />
);

const Column = props => (
  <Flex maxWidth="183px" flexDirection="column" {...props} />
);

const splitBy = (string = '', separator = ',') =>
  string
    .split(separator)
    .map(item => item.trim())
    .filter(Boolean);

const parseAddress = (contactDetails, type) => {
  const address = path(`addresses.${type}`)(contactDetails) || '';
  const parsed = splitBy(address, ',');
  return parsed;
};

const CallUsSection = ({ theme, ...props }) => {
  const isMobile = useMobileScreenOnly();
  return (
    <Column {...props}>
      <ReachToUsTitle
        icon={
          <Icon
            name="phone"
            fill={theme.colors.background.dark}
            height="32px"
            width="23px"
          />
        }
        title="Call us"
      />
      <Flex flexWrap="wrap">
        <ReachToUsText inline mr={1}>
          Free call
        </ReachToUsText>
        <CallUsNumber
          rColor="primary"
          textDecoration="underline"
          fontSize={4}
          inline
        />
      </Flex>
    </Column>
  );
};

const MailUsSection = ({ contactDetails, theme, ...props }) => {
  const [box, building, cityAndState] = parseAddress(contactDetails, 'mailing');
  const { getLocaleString } = useLocale();

  return (
    <Column {...props}>
      <ReachToUsTitle
        icon={
          <Icon
            name="mail"
            fill={theme.colors.background.dark}
            width="31px"
            height="20px"
          />
        }
        title="Mail Us"
      />
      <Box minWidth="max-content">
        <ReachToUsText>
          {getLocaleString('common.organisationName')}
        </ReachToUsText>
        <ReachToUsText>{box}</ReachToUsText>
        <ReachToUsText>{building}</ReachToUsText>
        <ReachToUsText>{cityAndState}</ReachToUsText>
      </Box>
    </Column>
  );
};

const HeadOfficeSection = ({ contactDetails, theme, ...props }) => {
  const [level, street, cityAndState] = parseAddress(contactDetails, 'office');
  const { getLocaleString } = useLocale();
  return (
    <Column {...props}>
      <ReachToUsTitle
        icon={
          <Icon
            name="office"
            fill={theme.colors.background.dark}
            width="32px"
            height="32px"
          />
        }
        title="Head Office"
      />
      <Box mb={30}>
        <ReachToUsText>{level}</ReachToUsText>
        <ReachToUsText>{street}</ReachToUsText>
        <ReachToUsText>{cityAndState}</ReachToUsText>
      </Box>
      <ReachToUsText
        minWidth="max-content"
        clickable
        as={props => <Link target="_blank" external {...props} />}
        rColor="primary"
        to={getLocaleString('common.googleMapsLocation')}
      >
        Find us on google maps
      </ReachToUsText>
    </Column>
  );
};

const ReachToUsBlock = ({ contactDetails, theme }) => {
  return (
    <ContactUsBlock>
      <Flex
        justifyContent="center"
        flexDirection={{
          xs: 'column',
          tabletP: 'row',
        }}
      >
        <CallUsSection
          mr={{
            xs: 1,
            tabletP: 122,
          }}
          width={{
            xs: 1,
            tabletP: 183,
          }}
          mb={{
            xs: 50,
            tabletP: 0,
          }}
          contactDetails={contactDetails}
          theme={theme}
        />
        <MailUsSection
          mr={{
            xs: 1,
            tabletP: 122,
          }}
          width={{
            xs: 1,
            tabletP: 183,
          }}
          mb={{
            xs: 50,
            tabletP: 0,
          }}
          contactDetails={contactDetails}
          theme={theme}
        />
        <HeadOfficeSection
          width={{
            xs: 1,
            tabletP: 183,
          }}
          contactDetails={contactDetails}
          theme={theme}
        />
      </Flex>
    </ContactUsBlock>
  );
};

export default ReachToUsBlock;
