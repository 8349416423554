import { basedOffBrand } from '~lib/util';

const ByBrand = ({ ramsay = null, dhf = null, ...props }) => {
  const brandToUse = basedOffBrand({
    RAMSAY: ramsay,
    DHF: dhf,
  });

  return typeof brandToUse === 'function' ? brandToUse(props) : brandToUse;
};

export default ByBrand;
