import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import { groupBy, map, path, pipe, prop, sortBy, identity } from 'lodash/fp';

const INCLUSIONS_QUERY = graphql`
  query InclusionsQuery {
    hospital: allSanityHospitalInclusion {
      nodes {
        id: _id
        type
        description
        tier
        featuredTierTitle
        icon
        title
        slug {
          current
        }
        body: _rawBody(resolveReferences: { maxDepth: 1 })
      }
    }

    extras: allSanityExtrasInclusion {
      nodes {
        id: _id
        type
        title
        icon
        description
        slug {
          current
        }
        body: _rawBody(resolveReferences: { maxDepth: 1 })
      }
    }
  }
`;

export const useInclusionsQuery = type => {
  const data = useStaticQuery(INCLUSIONS_QUERY);
  return type ? data[type] : data;
};

export const useInclusionsByTierQuery = (
  type,
  {
    map: mapInclusion = () => undefined,
    filter: filterInclusion = identity,
  } = {}
) => {
  const data = useInclusionsQuery(type);
  const inclusionTiers = useMemo(() => {
    return pipe(
      path(['nodes']),
      filterInclusion,
      sortBy(prop('title')),
      map(node => {
        return {
          ...node,
          type: node.type.toLowerCase(),
          tier: node.tier || 'other',
          ...mapInclusion(node),
        };
      }),
      groupBy(prop('tier'))
    )(data);
  }, [data, filterInclusion, mapInclusion]);

  return inclusionTiers;
};
