import React from 'react';
import { Box, Button } from 'rebass';
import Block from '../../common/atoms/Block';
import Icon from '../../common/atoms/Icon';
import { useThemeUI } from 'theme-ui';
import Link from '../../common/atoms/Link';
import styled from '@emotion/styled';
import Heading from './Heading';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import HorizontalCenter from '~common/atoms/HorizontalCenter';
import { P2 } from '~common/atoms/typography';

const quoteTypes = [
  { value: 'SINGLE', label: 'Single', icon: 'status-single' },
  { value: 'COUPLE', label: 'Couple', icon: 'status-couple' },
  { value: 'FAMILY', label: 'Family', icon: 'status-family' },
  {
    value: 'SINGLE_PARENT',
    label: 'Single Parent',
    icon: 'status-single-parent',
  },
];

const Card = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.colors.borders};
  background: ${props => props.theme.colors.lightNeutral50};
  flex-direction: column;
  transition: 0.2s background-color ease-in-out;

  .card-icon svg path {
    transition: 0.2s fill ease-in-out;
  }

  .card-title {
    transition: 0.2s color ease-in-out;
  }

  &:hover,
  &:focus {
    background-color: ${props =>
      props.theme.colors[props.theme.quickQuoteLinkColor]};
    cursor: pointer;
    .card-icon svg path {
      fill: ${props => props.theme.colors.white};
    }

    .card-title {
      color: ${props => props.theme.colors.white};
    }
  }
`;

const QuoteCard = ({ type, index }) => {
  const { theme } = useThemeUI();

  return (
    <Card
      key={type.value}
      variant="primary-inverted"
      as={Link}
      to={`/quote?status=${type.value}`}
      style={{
        textDecoration: 'none',
      }}
      pt={28.5}
      pb={28.5}
      height={{
        xs: '117px',
        tabletP: '165px',
      }}
    >
      <Box
        mb={{
          xs: '12px',
          tabletP: '14px',
        }}
      >
        <Icon
          className="card-icon"
          name={type.icon}
          height={{
            xs: '48px',
            tabletP: '64px',
          }}
          width="auto"
          fill={theme.colors[theme.quickQuoteLinkColor]}
          clickable
        />
      </Box>

      <P2 className="card-title" variant="semiBold" color="black">
        {type.label}
      </P2>
    </Card>
  );
};

const CardsContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 15px;
  ${props => props.theme.mq.tabletP} {
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
  }
`;

const StyledTitle = styled(SanityRichTextContent)`
  .text-block,
  .heading-block {
    text-align: center;
    margin-bottom: 0;
    color: ${props => props.theme.colors.heading};
  }
`;

const QuickQuoteLinks = ({ data: { title }, ...props }) => {
  return (
    <Block
      py={{
        xs: 64,
        lg: 100,
      }}
      {...props}
    >
      {!Array.isArray(title) ? (
        <Heading
          mb={{
            xs: 20,
            tabletP: 40,
            tabletL: 50,
          }}
          data={title}
        />
      ) : (
        <HorizontalCenter>
          <StyledTitle
            mb={{
              xs: 20,
              tabletP: 40,
              tabletL: 50,
            }}
            content={title}
          />
        </HorizontalCenter>
      )}
      <CardsContainer>
        {quoteTypes.map((type, index) => (
          <QuoteCard key={index} type={type} index={index} />
        ))}
      </CardsContainer>
    </Block>
  );
};

export default QuickQuoteLinks;
