import React from 'react';
import { useMobileScreen } from '~lib/responsive';
import PageBlocksRenderer from '../PageBlocksRenderer';
import { Box } from 'rebass';
import { ColumnProvider } from '~lib/providers/ColumnProvider';
import { css } from '@emotion/core';

const Column = ({ data, metaData, index, ...props }) => {
  const { hideMobile, width = 12 } = data;

  const isMobile = useMobileScreen();
  if (isMobile && hideMobile) {
    return null;
  }

  return (
    <ColumnProvider
      page={metaData.page}
      columns={metaData.context.columns}
      column={data}
      index={index}
    >
      <Box
        css={css`
          overflow: hidden;
        `}
        width={{
          xs: 1,
          tabletL: width / 12,
        }}
        {...props}
      >
        <PageBlocksRenderer
          container={data}
          metaData={{
            ...metaData,
            context: data,
          }}
        />
      </Box>
    </ColumnProvider>
  );
};

export default Column;
