import React, { useState, useCallback, useEffect, useRef } from 'react';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';

import Icon from '~common/atoms/Icon';

import useEventListener from '~lib/hooks/useEventListener';

import CircleArcGraph from '../molecules/CircleArcGraph';

import { isRunningOnClientSide } from '~lib/util';
import Link from '~common/atoms/Link';
import { css } from '@emotion/core';
import { H3, P2 } from '~common/atoms/typography';

const PADDING_OFFSET = 10;

const CarouselContainer = styled.div`
  height: 320px;
  width: calc(100% + 40px);
  margin-left: -20px;
  overflow: hidden;
  position: relative;
  ${props => props.mq.md} {
    width: calc(100% + 60px);
    margin-left: -30px;
  }
`;

const CarouselScroller = styled.div`
  overflow-x: scroll;
  scroll-behavior: smooth;
  height: calc(100% + 15px);
  padding-top: 20px;
  padding-left: ${PADDING_OFFSET}px;
  padding-right: 10px;
  display: flex;
  ${props => props.mq.md} {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

const CarouselRow = styled.div`
  height: 100%;
  flex-wrap: nowrap;
  display: flex;
  margin: 0 auto;
`;

const CarouselCol = styled.div`
  padding: 0 10px;
  flex: 0 0 280px;
  width: 280px;
`;

const transition = animateProps => () =>
  animateProps.map(propName => `${propName} 400ms ease-in-out`).join(',');

const StyledBox = styled.div`
  width: 100%;
  background: #fff;
  padding: 25px;
  border-radius: 6px;
  position: relative;
  border: ${props =>
    props.isActive ? '1px solid #dfdede' : '1px solid transparent'};
  box-shadow: ${props =>
    props.isActive ? '1px 1px 8px rgba(0, 0, 0, 0.1)' : ''};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: ${transition(['box-shadow', 'border'])};
  .circle-arc-graph {
    transform: translate(20px, 0px);
  }
`;

const StyledTooltipArrow = styled.div`
  background: #fff;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -9px;
  margin: auto;
  border-top: 1px solid #dfdede;
  border-left: 1px solid #dfdede;
  transform: rotate(-135deg) skew(10deg, 10deg);
  opacity: ${props => (props.isActive ? 1 : 0)};
  transition: ${transition(['opacity'])};
`;

const StyledNextPrevArrow = styled.div`
  width: 56px;
  height: 56px;
  background: ${props => props.theme.colors.white};
  position: absolute;
  top: 0;
  bottom: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: ${props => (props.isActive ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  left: ${props => (props.isLeft ? 0 : undefined)};
  right: ${props => (props.isRight ? 0 : undefined)};
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.primary};
  transform: ${props => (props.isRight ? 'rotate(180deg)' : undefined)};
  margin: auto;
`;

const useHookRef = fn => {
  const setRef = useCallback(node => {
    if (node !== null) {
      fn(node);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [setRef];
};

const ScrollCarousel = ({
  theme,
  persons,
  graphs,
  activePerson,
  toggleActive,
  onSelectChange,
}) => {
  // Elements
  const [scrollElement, setScrollElement] = useState(null);
  const [scrollRowElement, setScrollRowElement] = useState(null);
  // only set initial position once
  const [initialized, setInitialized] = useState(false);

  // active person at position in array
  const index = persons.findIndex(p => p.id === activePerson);

  // Scroll Position (X)
  const [scrollX, setScrollX] = useState(0);

  // Width of parent element
  const [scrollerWidth, setScrollerWidth] = useState(0);

  // Width of items. We need this to determine
  // whether we show the arrows - sometimes they're hidden (1 user on this policy, for example)
  const [scrollRowWidth, setScrollRowWidth] = useState(null);

  // Refs
  const [scrollerRef] = useHookRef(setScrollElement);
  const [scrollRowRef] = useHookRef(setScrollRowElement);
  const item = useRef();

  // OnScroll Handler
  const handleOnScroll = useCallback(
    evt => {
      setScrollX(evt.target.scrollLeft);
    },
    [setScrollX]
  );

  const handleClick = direction => {
    if (scrollElement) {
      if (direction === 'right') {
        scrollElement.scrollLeft += item?.current?.offsetWidth || 0;
      } else {
        scrollElement.scrollLeft -= item?.current?.offsetWidth || 0;
      }
    }
  };

  // On Resize
  const handleResize = useCallback(() => {
    setScrollerWidth(scrollElement?.offsetWidth || 0);
  }, [setScrollerWidth, scrollElement]);

  // Onmount, set the width of scrollerWidth and scrollRowWidth.
  useEffect(() => {
    if (isRunningOnClientSide()) {
      if (scrollElement !== null) {
        setScrollerWidth(scrollElement?.offsetWidth || 0);
      }
    }
  }, [scrollElement]);

  useEffect(() => {
    if (isRunningOnClientSide()) {
      if (scrollRowElement !== null) {
        setScrollRowWidth(scrollRowElement?.offsetWidth);
      }
    }
  }, [scrollRowElement]);

  // onload setup initial position
  useEffect(() => {
    if (!initialized) {
      setTimeout(() => {
        if (scrollElement) {
          scrollElement.scrollLeft = index * (item?.current?.offsetWidth || 1);
        }

        setInitialized(true);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollElement, index]);

  // on person select change, move scroll
  useEffect(() => {
    if (initialized) {
      if (scrollElement) {
        const index = persons.findIndex(p => p.id === activePerson);
        scrollElement.scrollLeft = index * (item?.current?.offsetWidth || 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSelectChange]);

  // Event listeners
  useEventListener('resize', handleResize);
  useEventListener('scroll', handleOnScroll, scrollElement);

  return (
    <>
      <H3 variant="semiBold" color="neutral900">
        My policy snapshot
      </H3>
      <CarouselContainer {...theme}>
        <CarouselScroller {...theme} ref={scrollerRef}>
          <CarouselRow {...theme} ref={scrollRowRef}>
            {persons.map((person, i) => (
              <CarouselCol key={person.id} ref={item}>
                <StyledBox
                  isActive={person.id === activePerson}
                  {...theme}
                  onClick={() => toggleActive(person.id)}
                >
                  <CircleArcGraph
                    data={graphs.find(g => g.id === person.id).rows}
                  />
                  <Link
                    to="/oms/my-details"
                    state={{ expandMember: person.id }}
                    css={css`
                      text-decoration: none;
                    `}
                  >
                    <P2 variant="underline" color="primary">
                      {person.firstName}
                    </P2>
                  </Link>

                  <StyledTooltipArrow isActive={person.id === activePerson} />
                </StyledBox>
              </CarouselCol>
            ))}

            <StyledNextPrevArrow
              onClick={() => handleClick('left')}
              isActive={scrollRowWidth !== null && scrollX > 0}
              isLeft={true}
            >
              <Icon
                name="chevron-left"
                fill={theme.colors.primary}
                width="26px"
                height="26px"
              />
            </StyledNextPrevArrow>

            <StyledNextPrevArrow
              isActive={
                scrollRowWidth !== null &&
                scrollRowWidth > scrollerWidth &&
                scrollX - PADDING_OFFSET < scrollRowWidth - scrollerWidth
              }
              isRight={true}
              onClick={() => handleClick('right')}
            >
              <Icon
                name="chevron-left"
                fill={theme.colors.primary}
                width="26px"
                height="26px"
              />
            </StyledNextPrevArrow>
          </CarouselRow>
        </CarouselScroller>
      </CarouselContainer>
    </>
  );
};

export default withTheme(ScrollCarousel);
