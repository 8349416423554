import React from 'react';
import { H3, P1 } from '~common/atoms/typography';
import { navigate } from 'gatsby';
import { Button } from '~common/atoms/Button';
import { useLocale } from '~common/locales';

const ExpiredMessage = () => {
  const { getLocaleString } = useLocale();
  return (
    <>
      <H3 variant="semiBold" color="primary" mb={4}>
        This link has expired
      </H3>
      <P1 variant="medium" color="neutral900">
        Your application for {getLocaleString('common.organisationName')} has
        expired.
      </P1>
      <P1 variant="medium" color="neutral900" mb={4}>
        Start an application by first obtaining a quote with our latest rates.
      </P1>
      <Button
        variant="secondary"
        onClick={() => {
          navigate('/quote');
        }}
      >
        Quote
      </Button>
    </>
  );
};

export default ExpiredMessage;
