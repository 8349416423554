import { useMemo } from 'react';
import { mean, path } from 'lodash/fp';
import { calculateYouthDiscountPercentage } from '../common/youthDiscountSchema';
import { hasPartnerIncluded } from '../common/appFormUtills';

export default (criteria = {}) => {
  const youthDiscountPercentage = useMemo(() => {
    if (!criteria.hospitalProduct || !criteria.yourDob) {
      return 0;
    }

    const memberDob = path('yourDob.value')(criteria);
    const partnerDob = path('partnerDob.value')(criteria);

    if (hasPartnerIncluded(criteria)) {
      return (
        mean([
          calculateYouthDiscountPercentage(memberDob),
          calculateYouthDiscountPercentage(partnerDob),
        ]) || 0
      );
    }
    return calculateYouthDiscountPercentage(memberDob) || 0;
  }, [criteria.yourDob, criteria.partnerDob, criteria.hospitalProduct]);

  return youthDiscountPercentage;
};
