import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Button, Flex, Text } from 'rebass';
import { withTheme } from 'emotion-theming';

const BORDER_RADIUS = '5px';

const StyledButton = styled(Button)`
  border-radius: ${BORDER_RADIUS};
  display: flex;
  cursor: pointer;
  height: 40px;
  width: 60px;
  background-color: ${props =>
    props.active ? props.theme.colors.primary : 'transparent'};
  color: ${props =>
    props.active ? props.theme.colors.white : props.theme.colors.text.light};
  border-color: ${props => props.theme.colors.borders};
  border-width: 2px;
  text-transform: none;
  font-weight: bold;
  line-height: 13px;
  font-size: ${props => props.theme.fontSizes[1]}px;
  align-content: center;
  align-items: center;
  justify-content: center;

  &:last-child {
    margin-left: 10px;
  }
`;

const LabeledButtonSwitch = ({
  labelText,
  buttons,
  className,
  theme,
  onChange = () => {},
  value,
}) => {
  const [activeButtonId, setActive] = useState(null);

  useEffect(() => {
    setActive(value);
  }, [value]);

  return (
    <Flex
      className={className}
      p={1}
      justifyContent="space-between"
      alignItems="center"
    >
      <Text fontSize={2} color={theme.colors.text.light}>
        {labelText}
      </Text>
      <Flex>
        {buttons.map(button => (
          <StyledButton
            data-id={button.id}
            key={button.id}
            as="a"
            theme={theme}
            active={activeButtonId === button.id}
            onClick={() => {
              onChange(button.id);
              setActive(button.id);
            }}
            variant="whiteThinStatic"
          >
            <div>{button.text}</div>
          </StyledButton>
        ))}
      </Flex>
    </Flex>
  );
};

export default withTheme(LabeledButtonSwitch);
