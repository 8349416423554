import React from 'react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';
import { css } from '@emotion/core';
import { now } from '~lib/dates';
import { StandardText } from '~common/atoms/typography';
import { Button } from '~common/atoms/Button';
import CenterBox from '~common/atoms/CenterBox';
import { currency, paymentFrequency } from '~lib/filters';
import { useMobileScreenOnly } from '~lib/responsive';
import { round } from 'lodash/fp';
import useYouthDiscountPercentage from '../../../state/useYouthDiscountPercentage';
import ByBrand from '~common/atoms/ByBrand';
import { EFC_UPPER_AGE_LIMIT } from '~lib/constants';

const StyledContainer = styled(CenterBox)`
  background: ${props => props.theme.colors.white};
`;

const InclusionsModal = ({
  onConfirm,
  criteria,
  quoteData,
  hospital,
  extras,
  medicareRebateInactive,
}) => {
  const youthDiscountPercentage = useYouthDiscountPercentage(criteria);
  const isMobile = useMobileScreenOnly();
  return (
    <StyledContainer
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      pt={isMobile ? '30px' : 0}
    >
      {hospital && criteria.hospitalProduct && (
        <Flex width="100%" flexDirection="column" px="20px">
          <StandardText
            fontSize={1}
            lineHeight={1.62}
            mb="7px"
            color="text.light"
          >
            HOSPITAL
          </StandardText>
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            pb="21px"
            mb="11px"
            style={{
              borderBottom: criteria.extrasProduct
                ? 'solid 2px #eceded'
                : 'none',
            }}
          >
            <StandardText
              fontSize={2}
              lineHeight={1.88}
              rColor="primary"
              bold={true}
            >
              {hospital.product.title} {hospital.product.coverage}
            </StandardText>
            <StandardText
              fontSize={2}
              lineHeight={1.88}
              rColor="primary"
              textAlign="right"
            >
              {currency(quoteData.hospital.net)}
            </StandardText>
          </Flex>
        </Flex>
      )}
      {extras && criteria.extrasProduct && (
        <Flex width="100%" flexDirection="column" px="20px">
          <StandardText
            fontSize={1}
            lineHeight={1.62}
            mb="7px"
            color="text.light"
          >
            EXTRAS
          </StandardText>
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            mb="11px"
          >
            <StandardText
              fontSize={2}
              lineHeight={1.88}
              rColor="primary"
              bold={true}
            >
              {extras.product.title}
            </StandardText>
            <StandardText
              fontSize={2}
              lineHeight={1.88}
              rColor="primary"
              textAlign="right"
            >
              {currency(quoteData.extras.net)}
            </StandardText>
          </Flex>
        </Flex>
      )}
      <Flex
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        bg="primary"
        width="100%"
        px="20px"
        py="13px"
        mb="30px"
      >
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          mb="11px"
        >
          <StandardText color="white" fontSize={3} bold={true}>
            Total {paymentFrequency(criteria.paymentFrequency)} Premium
          </StandardText>
          <StandardText
            fontSize={2}
            bold={true}
            color="white"
            textAlign="right"
          >
            {currency(quoteData.totals.net)}
          </StandardText>
        </Flex>
        <StandardText mb="16px" fontSize={2} color="white">
          Price is for {criteria.status.label} cover in {criteria.region.label}{' '}
          with{' '}
          {medicareRebateInactive ? 'no' : `${quoteData.rebatePercentage}%`}{' '}
          Australian Government Rebate
          {youthDiscountPercentage
            ? ` and ${youthDiscountPercentage}% Age-based discount`
            : ''}
          {quoteData.lhcPercentage
            ? `, as well as a ${round(
                quoteData.lhcPercentage
              )}% Lifetime Health Cover (LHC) loading.`
            : '.'}
        </StandardText>
        <ByBrand
          dhf={() =>
            criteria.paymentFrequency === 'YEARLY' && (
              <StandardText mb="16px" fontSize="13px" color="white">
                Pay frequency is annual which means you enjoy a 2.5% discount.
              </StandardText>
            )
          }
        />
        {quoteData.efc && (
          <StandardText mb="16px" fontSize={2} color="white">
            This premium includes an additional cost for Extended Family Cover,
            an optional upgrade which allows children who are not studying to be
            covered until they turn {EFC_UPPER_AGE_LIMIT}.
          </StandardText>
        )}

        {!quoteData.includesAbdAndLhc && (
          <StandardText
            mb="16px"
            css={css`
              font-style: italic;
            `}
            fontSize={2}
            color="white"
          >
            This quote does not include any applicable youth discount or
            lifetime heath cover loading and may differ from your final premium.
            We will communicate your final premium in your welcome pack which
            will be emailed to you within 1 business day of joining.
          </StandardText>
        )}

        <StandardText fontSize={2} color="white">
          Price and product information is current as at{' '}
          {now().format('D/M/YYYY')}.
        </StandardText>
      </Flex>

      <Flex
        width="100%"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <Button width={176} variant="primaryInverted" onClick={onConfirm}>
          Close window
        </Button>
      </Flex>
    </StyledContainer>
  );
};

export default InclusionsModal;
