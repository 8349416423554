import React from 'react';
import { P2 } from '~common/atoms/typography';
import InfoIcon from '~common/molecules/InfoIcon';
import styled from '@emotion/styled';

const LabelTooltip = styled(InfoIcon)`
  display: inline-block;
  margin-left: 10px;
  transform: translateY(2px);
  ${
    '' /* ${props => props.theme.mq.xs} {
    transform: translateX(${props => props.translateX.xs});
  }
  ${props => props.theme.mq.sm} {
    transform: translateX(${props => props.translateX.sm});
  }
  ${props => props.theme.mq.tabletP} {
    transform: translateX(${props => props.translateX.tabletP});
  }
  ${props => props.theme.mq.tabletL} {
    transform: translateX(${props => props.translateX.tabletL});
  }
  ${props => props.theme.mq.lg} {
    transform: translateX(${props => props.translateX.lg});
  } */
  }
`;

const FormLabel = styled(P2)`
  &&& .info-icon {
    display: inline-block;
  }
`;

export default ({ children, tooltip, ...props }) => {
  return (
    <FormLabel variant="medium" color="neutral800" {...props}>
      {children}
      {tooltip && (
        <LabelTooltip
          translateX={tooltip.translateX || {}}
          icon={{
            height: '20px',
            width: '20px',
          }}
          tooltip={tooltip.text || tooltip}
          position={tooltip.position || 'top'}
        />
      )}
    </FormLabel>
  );
};
