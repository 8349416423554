import React from 'react';
import {
  StandardText,
  TextResponsive,
} from '../../../../common/atoms/typography';
import Icon from '../../../../common/atoms/Icon';
import useMembership from '~common/hooks/useMembership';
import Link from '~common/atoms/Link';
import { Flex, Box } from 'rebass';
import { withTheme } from 'emotion-theming';
import useFiles from '~lib/hooks/sanity/useFiles';

const CheckItem = ({ theme, children, id }) => (
  <Flex
    key={id}
    mb={3}
    flexDirection="row"
    alignItems="flex-start"
    justifyContent="flex-start"
  >
    <Icon
      mr={3}
      name="success-circle-tick"
      width="17px"
      height="17px"
      fill={theme.colors.green}
      style={{ minWidth: '17px', minHeight: '17px', marginTop: '2px' }}
    />
    <div>
      <StandardText color="light">{children}</StandardText>
    </div>
  </Flex>
);

const ClaimingChecklist = ({ theme, ...props }) => {
  const { isS2IMembership } = useMembership();
  const approvalForm = useFiles('healthManagementApprovalForm');
  return (
    <Box {...props}>
      <TextResponsive mb={4} fontSize={3} fontWeight={600}>
        Claiming checklist
      </TextResponsive>

      <TextResponsive mb={3} fontSize={2} fontWeight={600}>
        Extras Claims
      </TextResponsive>

      <CheckItem id={0} theme={theme}>
        The invoice is clearly itemised with patient name, provider details,
        details of the service received, date of service, and fee paid. For
        dental claims, the invoice is itemised with individual dental item
        numbers.
      </CheckItem>

      <CheckItem id={1} theme={theme}>
        The service was provided in the last 2 years.
      </CheckItem>

      <CheckItem id={2} theme={theme}>
        You haven't already received a benefit at your provider through HICAPs
        for the service.
      </CheckItem>

      <CheckItem id={3} theme={theme}>
        You have included relevant supporting documentation for any Aids and
        Appliances claims (a letter from your doctor) or Health Management
        Program claims (approval form,{' '}
        <Link to={approvalForm?.url} target="_blank" external>
          found here
        </Link>
        ).*
      </CheckItem>

      {!isS2IMembership && (
        <>
          <TextResponsive mt={4} mb={3} fontSize={2} fontWeight={600}>
            Medical claims (Hospital cover)
          </TextResponsive>

          <CheckItem id={0} theme={theme}>
            Your invoice is clearly itemised with the service provided in the
            last 2 years.
          </CheckItem>

          <CheckItem id={1} theme={theme}>
            The service was administered while you were an admitted private
            patient in hospital (we are unable to pay benefits on outpatient
            services, such as GP visits, consultations with specialists in their
            rooms, or diagnostic imaging and tests, apart from the selection of
            tests covered under Extras cover).
          </CheckItem>

          <CheckItem id={2} theme={theme}>
            You have included a Statement of Benefits if you have already
            submitted your claim to Medicare and they have returned benefits to
            you. If you haven't, just include your invoice and we will submit
            this to Medicare on your behalf.
          </CheckItem>
        </>
      )}

      <StandardText mt={2} color="light">
        *These services are not covered on all our products. Please refer to
        your Extras policy for further details
      </StandardText>
    </Box>
  );
};

export default withTheme(ClaimingChecklist);
