import { useContext, useCallback, useReducer } from 'react';

import { AuthContext } from 'context/sso';

import useEventListener from '~lib/hooks/useEventListener';
import useTimeout from '~lib/hooks/useTimeout';
import useInterval from '~lib/hooks/useInterval';
import { throttle } from 'lodash';

const SHOW_PROMPT_TIMER = Number(process.env.DHF_SHOW_PROMPT_TIMER) || 300; // In seconds (NOT ms)
const COUNTDOWN_TIMER = Number.isNaN(Number(process.env.DHF_COUNTDOWN_TIMER))
  ? 10
  : Number(process.env.DHF_COUNTDOWN_TIMER); // In seconds (NOT ms)

const initialState = {
  isRunning: true,
  isVisible: false,
  counter: COUNTDOWN_TIMER,
};

function reducer(state, action) {
  switch (action.type) {
    case 'DISABLE_PROMPT_TIMER':
      return { ...state, isRunning: false };
    case 'ENABLE_PROMPT_TIMER':
      return { ...state, isRunning: true };
    case 'DECREMENT_COUNTER':
      return { ...state, counter: state.counter - 1 };
    case 'SHOW_PROMPT':
      return { ...state, isVisible: true, isRunning: false };
    case 'RESET_STATE':
      return { ...initialState };
    default:
      throw new Error('NON_EXISTENT_ACTION');
  }
}
const useTimedLogout = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const authContext = useContext(AuthContext);

  const logoutUser = useCallback(() => {
    authContext.logout({
      reason: `You've been automatically logged out due to inactivity. Please log in again in order to access the membership portal.`,
    });
    dispatch({ type: 'RESET_STATE' });
  }, [authContext]);

  const restartTimer = useCallback(
    throttle(() => {
      if (!state.isVisible) {
        dispatch({ type: 'DISABLE_PROMPT_TIMER' });
        dispatch({ type: 'ENABLE_PROMPT_TIMER' });
      }
    }, 1000),
    [state.isVisible]
  );

  // Timeout to show prompt
  useTimeout(
    () => {
      dispatch({ type: 'SHOW_PROMPT' });
    },
    state.isRunning ? SHOW_PROMPT_TIMER * 1000 : null
  );

  // Interval to reduce counter
  useInterval(
    () => {
      if (state.counter === 1) {
        logoutUser();
      } else {
        dispatch({ type: 'DECREMENT_COUNTER' });
      }
    },
    state.isVisible ? 1000 : null
  );

  useEventListener('mousemove', restartTimer);
  useEventListener('click', restartTimer);
  useEventListener('touchstart', restartTimer);
  useEventListener('visibilitychange', AuthContext.checkForToken);

  return {
    state,
    resetTimer: () => {
      dispatch({ type: 'RESET_STATE' });
    },
  };
};

export default useTimedLogout;
