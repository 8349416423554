import React from 'react';
import styled from '@emotion/styled';
import classnames from 'classnames';
import { Box } from 'rebass';

const ProductsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 970px;
  min-height: 400px;
  margin: 20px auto 0 auto;
  padding: 40px 0 10px 0;

  > div {
    width: 49%;
  }

  > div:first-of-type {
    margin: 0 1% 0 0;
  }
  > div:last-of-type {
    margin: 0 0 0 1%;
  }

  @media screen and (max-width: 992px) {
    flex-direction: column;
    max-width: 100%;
    padding: 0 0 30px 0;
    margin: 20px auto 0 auto;

    > div {
      width: 100%;
    }

    > div:first-of-type,
    > div:last-of-type {
      margin: 0;
    }

    > div {
      display: none;

      &.active {
        display: flex;
      }
    }
  }
`;

const CoverProducts = ({
  showHospital = true,
  showExtras = true,
  activeTab = '',
  hospitalProductsBrowser,
  extrasProductsBrowser,
  className,
  ...props
}) => {
  return (
    <ProductsContainer className={className} {...props}>
      {showHospital && (
        <div
          className={classnames('hospital-container', {
            active: !showExtras || activeTab === 'hospital',
          })}
        >
          {hospitalProductsBrowser}
        </div>
      )}
      {showExtras && (
        <div
          className={classnames('extras-container', {
            active: !showHospital || activeTab === 'extras',
          })}
        >
          {extrasProductsBrowser}
        </div>
      )}
    </ProductsContainer>
  );
};

export default CoverProducts;
