import React from 'react';
import PropTypes from 'prop-types';
import EmptyCard from './EmptyCard';
import LoadingCard from './LoadingCard';
import ProductCard from './ProductCard';
import TotalCard from './TotalCard';

const Cards = ({ form, prices, quote, selections }) => {
  return (
    <>
      {quote.loading ? (
        <LoadingCard type="hospital" />
      ) : selections.hospital.product ? (
        <ProductCard
          price={prices.hospital.net}
          subtitle={selections.hospital.product.coverage}
          title={selections.hospital.product.title}
          type="hospital"
        />
      ) : (
        <EmptyCard title="Not selected" type="hospital" />
      )}
      {quote.loading ? (
        <LoadingCard type="extras" />
      ) : selections.extras.product ? (
        <ProductCard
          price={prices.extras.net}
          subtitle={selections.extras.product.coverage}
          title={selections.extras.product.title}
          type="extras"
        />
      ) : (
        <EmptyCard title="Not selected" type="extras" />
      )}
      {quote.loading ? (
        <LoadingCard />
      ) : (
        <TotalCard
          isYouth={form.dob.age < 30}
          paymentFrequency={form.paymentFrequency}
          price={prices.total}
          type="total"
        />
      )}
    </>
  );
};

Cards.propTypes = {
  form: PropTypes.object.isRequired,
  prices: PropTypes.object.isRequired,
  quote: PropTypes.object.isRequired,
  selections: PropTypes.object.isRequired,
};

export default Cards;
