import { shortWordyFormat } from '~lib/dates';
import { currency } from '~lib/filters';
import React from 'react';
import { capitalize } from '~lib/util';
import TruncatedText from '~OMS/claims/atoms/TruncatedText';
import ClaimBenefit from '~OMS/claims/atoms/ClaimBenefit';
import { CLAIM_STATUS, SERVICE_TYPES } from '~OMS/claims/constants';
import { StandardText } from '../../../common/atoms/typography';
import { formatClaimId } from './utils';

const getDescription = item => {
  return item.claimType === SERVICE_TYPES.MEDICAL || item.classCode === 'D'
    ? item.code
    : item.description;
};

const renderForStatus = ({
  item,
  value,
  renderValue,
  customRender,
  ...props
}) => {
  value = item?.status === CLAIM_STATUS.SUBMITTED ? renderValue : value;
  return customRender ? (
    customRender({
      item,
      value,
      ...props,
    })
  ) : (
    <StandardText {...props}>{value}</StandardText>
  );
};

const renderId = props =>
  renderForStatus({
    ...props,
    renderValue: `#${formatClaimId(props?.item || props?.claim)}`,
    value: props?.item ? `#${props?.item?.id}` : `#${props?.claim?.id}`,
  });

// const renderBlank = props => renderForStatus({ renderValue: '', ...props });

const renderPending = props => {
  if (props?.item?.status === CLAIM_STATUS.SUBMITTED) {
    props = {
      ...props,
      color: 'primary',
    };
  }
  return renderForStatus({ renderValue: 'Pending', ...props });
};

const defaultProps = columns => {
  return columns.map(column => ({
    pr: '10px',
    ...column,
  }));
};

export const claimColumns = defaultProps([
  {
    displayName: 'Claim ID',
    name: 'id',
    width: 11 / 100,
    customRender: renderId,
  },
  {
    displayName: 'Claim Date',
    name: 'claimDate',
    width: 12 / 100,
    format: shortWordyFormat,
  },
  {
    displayName: 'Claim Type(s)',
    name: 'claimType',
    width: 1 / 10,
    format: capitalize,
  },
]);

export const mobileClaimColumns = defaultProps([
  {
    displayName: 'Claim ID',
    name: 'id',
    width: 1 / 3,
    customRender: renderId,
  },
  {
    displayName: 'Claim Date',
    name: 'claimDate',
    width: 1 / 3,
    format: shortWordyFormat,
  },
]);

const benefitsColumn = {
  displayName: 'Benefit',
  name: 'benefitsPaid',
  customRender: ({ value, item, claim, ...props }) => {
    return <ClaimBenefit status={item.status} benefit={value} {...props} />;
  },
};

export const claimLineColumns = defaultProps([
  {
    displayName: 'Claim ID',
    name: 'id',
    width: 10 / 100,
    customRender: renderId,
  },
  {
    displayName: 'Service Date',
    name: 'serviceDate',
    width: 12 / 100,
    format: shortWordyFormat,
    customRender: renderPending,
  },
  {
    displayName: 'Patient',
    name: 'person.fullName',
    width: 18 / 100,
    customRender: renderPending,
  },
  {
    displayName: 'Provider',
    name: 'provider',
    width: 18 / 100,
    customRender: props =>
      renderPending({
        ...props,
        customRender: ({ value, ...props }) => {
          return (
            <TruncatedText
              color={props.color}
              pr={props.pr}
              width={props.width}
            >
              {value}
            </TruncatedText>
          );
        },
      }),
  },
  {
    displayName: 'Item Code / Description',
    name: 'description',
    width: 22 / 100,
    pr: '20px',
    customRender: ({ item, ...props }) => {
      return (
        <TruncatedText pr={props.pr} width={props.width}>
          {getDescription(item)}
        </TruncatedText>
      );
    },
  },
  {
    displayName: 'Fee',
    name: 'feeCharged',
    width: 10 / 100,
    format: currency,
    customRender: renderPending,
  },
  {
    ...benefitsColumn,
    width: 10 / 100,
  },
]);

export const mobileClaimLineColumns = defaultProps([
  {
    displayName: 'Service date',
    name: 'serviceDate',
    customRender: renderPending,
    format: shortWordyFormat,
  },
  {
    displayName: 'Item Code / Description',
    name: 'description',
    customRender: ({ item, ...props }) => {
      return <StandardText {...props}>{getDescription(item)}</StandardText>;
    },
  },
  {
    displayName: 'Patient',
    name: 'person.fullName',
    customRender: renderPending,
  },
  {
    displayName: 'Provider',
    name: 'provider',
    customRender: renderPending,
  },

  {
    displayName: 'Fee',
    name: 'feeCharged',
    customRender: renderPending,
    format: currency,
  },
  benefitsColumn,
]);
