import React from 'react';
import Layout from 'components/Layout';
import ResetEmailSentWrapper from '~OMS/SSO/organisms/ResetEmailSentWrapper';
import { withTheme } from 'emotion-theming';

const ResetEmailSent = props => {
  return (
    <Layout>
      <ResetEmailSentWrapper location={props.location} />
    </Layout>
  );
};

export default withTheme(ResetEmailSent);
