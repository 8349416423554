import React from 'react';
import AppFormConfirmButton from './AppFormConfirmButton';
import { Flex } from 'rebass';
import { Button } from '~common/atoms/Button';
import useFeatureToggle from '~lib/hooks/useFeatureToggle';

const FormButtons = ({
  disabled,
  children = 'Continue',
  onContinue,
  showSaveButton = true,
  onSave,
  showArrow,
  saving = false,
  submitting = false,
  ...props
}) => {
  const featureToggle = useFeatureToggle();
  const renderSaveButton = showSaveButton && featureToggle.saveMaf;

  return (
    <Flex
      justifyContent={renderSaveButton ? 'space-between' : 'flex-start'}
      {...props}
    >
      {renderSaveButton && (
        <Button
          disabled={submitting}
          type="button"
          width={{
            xs: 'calc(50% - 7.5px)',
            tabletP: 180,
          }}
          variant="primaryInverted"
          size="large"
          onClick={onSave}
          loading={saving}
        >
          Save for later
        </Button>
      )}
      <AppFormConfirmButton
        loading={submitting}
        showArrow={showArrow}
        onClick={evt => {
          if (onContinue) {
            evt.preventDefault();
            onContinue(evt);
          }
        }}
        disabled={disabled}
        width={{
          xs: 'calc(50% - 7.5px)',
          tabletP: 180,
        }}
      >
        {children}
      </AppFormConfirmButton>
    </Flex>
  );
};

export default FormButtons;
