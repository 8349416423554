import React, { useCallback } from 'react';
import { openModal } from '../common/appFormUtills';
import InclusionsModal from '../common/components/modals/InclusionsModal';

export default ({
  criteria,
  quoteData,
  hospital,
  extras,
  medicareRebateInactive,
}) => {
  const openInclusionsModal = useCallback(() => {
    openModal(
      ({ close }) => (
        <InclusionsModal
          criteria={criteria}
          quoteData={quoteData}
          hospital={hospital}
          extras={extras}
          medicareRebateInactive={medicareRebateInactive}
          onCancel={close}
          onConfirm={() => {
            close();
          }}
        />
      ),
      {
        px: {
          xs: '20px',
          tabletP: '40px',
          tabletL: '60px',
          lg: '69px',
        },
        pt: {
          xs: '20px',
          tabletP: '40px',
          tabletL: '60px',
        },
        pb: {
          xs: '20px',
          tabletP: '20px',
          tabletL: '30px',
        },
        showCloseIcon: true,
        minWidth: {
          xs: 1,
          tabletP: '638px',
        },
        width: {
          xs: 1,
          tabletP: '638px',
        },
      }
    );
  }, [criteria, quoteData, hospital, extras, medicareRebateInactive]);

  return {
    openInclusionsModal,
  };
};
