import React from 'react';
import Block from '../../common/atoms/Block';

const Iframe = ({ data: { url, height }, title, ...props }) => {
  return (
    <Block {...props}>
      <iframe
        title={title}
        width="100%"
        height={height}
        scrolling="yes"
        frameBorder="0"
        src={url}
      />
    </Block>
  );
};

export default Iframe;
