import React from 'react';
import { Flex, Box } from 'rebass';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { H4 } from '../../atoms/typography';
import { FORM_MODES } from '../../hooks/useUpdateAddress';

import isEmpty from 'lodash/isEmpty';
import ManualUpdateAddressForm from './ManualUpdateAddressForm';
import AutoCompleteUpdateAddressForm from './AutoCompleteUpdateAddressForm';
import Link from '~common/atoms/Link';

const StyledFormWrapper = styled(Box)`
  border: 1px solid ${props => props.theme.colors.borders};
  padding: ${props => props.theme.space[3]}px;
  padding-top: ${props => props.theme.space[4]}px;
`;

const StyledTitle = styled(H4)`
  font-weight: bold;
  display: block;
`;

const StyledLinkLikeText = styled(Link)`
  ${props => {
    if (props.disabled) {
      return `
        color: ${props.theme.colors.borders};
      `;
    }
    return '';
  }}
  text-align: right;
`;

const getSwitchModeText = mode => {
  return mode !== 'manual' ? 'Enter address manually' : 'Search for address';
};

const Index = ({
  mode,
  showTitle = true,
  onToggleMode,
  onSubmit,
  onCancel,
  className,
  address,
  postalAddress,
  addressLine,
  postalAddressLine,
  isPostalDifferentAsResidential,
  loading,
}) => {
  const disabled = isEmpty(postalAddress);

  return (
    <StyledFormWrapper width={1} className={className}>
      <Flex mb={4} justifyContent={showTitle ? 'space-between' : 'flex-end'}>
        {showTitle ? <StyledTitle>Send new card to:</StyledTitle> : null}

        <StyledLinkLikeText
          disabled={disabled}
          to="#"
          onClick={
            disabled
              ? undefined
              : evt => {
                  evt.preventDefault();
                  onToggleMode(evt);
                }
          }
        >
          {getSwitchModeText(mode)}
        </StyledLinkLikeText>
      </Flex>
      {mode === FORM_MODES.MANUAL && (
        <ManualUpdateAddressForm
          detailedAddress={address}
          detailedPostalAddress={postalAddress}
          postalAddress={postalAddress}
          postalAddressLine={postalAddressLine}
          address={address}
          addressLine={addressLine}
          isPostalDifferentAsResidential={isPostalDifferentAsResidential}
          onSubmit={onSubmit}
          onCancel={onCancel}
          loading={loading}
        />
      )}
      {mode === FORM_MODES.AUTO && (
        <>
          <AutoCompleteUpdateAddressForm
            loading={loading}
            address={address}
            postalAddress={postalAddress}
            isPostalDifferentAsResidential={isPostalDifferentAsResidential}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </>
      )}
    </StyledFormWrapper>
  );
};

Index.propTypes = {
  address: PropTypes.object,
  mode: PropTypes.any,
  onCancel: PropTypes.any,
  onSubmit: PropTypes.any,
  onToggleMode: PropTypes.any,
};

export default Index;
