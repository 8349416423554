import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import LegendCard from './Cards/LegendCard';
import ProductCard from './Cards/ProductCard';
import LoadingCard from './Cards/LoadingCard';
import MobileCard from './Cards/MobileCard';
import { useMediaQuery } from 'react-responsive';
import { noop } from '~lib/util';

const StyledCoverOptionsGroup = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(${props => props.cardsCount}, 1fr);
  justify-content: flex-end;

  ${props => props.theme.mq.tabletP} {
    grid-template-columns: ${({ cardsCount }) => {
      if (cardsCount >= 3) {
        return `repeat(${cardsCount + 1}, 1fr);`;
      }

      return `50% ${Array.from({ length: cardsCount })
        .map(() => `${50 / cardsCount}%`)
        .join(' ')}`;
    }}
`;

const CoverOptions = ({
  paymentFrequency,
  price,
  products,
  quote,
  selectedProduct = {},
  selectedProductCode,
  type,
  updateForm = noop,
  selectable = true,
  cardBottom = noop,
  asterisk = false,
  hat = false,
}) => {
  const isDesktop = useMediaQuery({
    query: `(min-width: 768px)`,
  });

  const productsToRender = useMemo(() => {
    return products?.length
      ? products.filter(
          product => product.allVariants.filter(v => v.onSale).length > 0
        )
      : Array.from({ length: 3 });
  }, [products]);

  if (!isDesktop && selectable) {
    return !selectedProduct.product || quote.loading ? (
      <LoadingCard />
    ) : (
      <MobileCard
        excess={selectedProduct.variant.excess}
        description={selectedProduct.product.description}
        gapCoverage={selectedProduct.product.gapCoverage}
        paymentFrequency={paymentFrequency}
        price={price}
        products={products}
        selectedProductCode={selectedProductCode}
        subtitle={selectedProduct.product.coverage}
        title={selectedProduct.product.title}
        type={type}
        updateForm={updateForm}
        asterisk={asterisk}
      />
    );
  }

  return (
    <StyledCoverOptionsGroup cardsCount={productsToRender.length}>
      {isDesktop && <LegendCard type={type} hat={hat} />}
      {(!selectedProduct.product && selectable) || quote.loading
        ? productsToRender.map((_, id) => <LoadingCard key={id} />)
        : products
            .filter(
              product => product.allVariants.filter(v => v.onSale).length > 0
            )
            .map((product, index) => (
              <ProductCard
                index={index}
                selectable={selectable}
                description={product.description}
                gapCoverage={product.gapCoverage}
                isPopular={[
                  'hospital-cover-prime-choice',
                  'extras-cover-essential-extras',
                ].includes(product.slug.current)} // Needs revision in sanity
                key={product.id}
                paymentFrequency={paymentFrequency}
                selectedProductCode={selectedProductCode}
                subtitle={
                  isDesktop
                    ? product.coverage
                    : product.type === 'hospital'
                    ? product.coverage
                    : null
                }
                title={product.title}
                type={product.type}
                updateForm={updateForm}
                variants={product.variants}
                isValid={product.isValid}
                cardBottom={cardBottom}
                slug={product.slug.current}
                asterisk={asterisk}
              />
            ))}
    </StyledCoverOptionsGroup>
  );
};

CoverOptions.propTypes = {
  paymentFrequency: PropTypes.string.isRequired,
  price: PropTypes.number,
  products: PropTypes.array.isRequired,
  quote: PropTypes.object,
  selectedProduct: PropTypes.object,
  selectedProductCode: PropTypes.string,
  type: PropTypes.string.isRequired,
  updateForm: PropTypes.func,
  asterisk: PropTypes.bool,
};

export default CoverOptions;
