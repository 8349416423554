import { memoizeBy } from '~lib/util';

const supportedCards = {
  visa: {
    code: 'VISA',
    test: (cardNumber = '') => cardNumber.startsWith('4'),
  },
  masterCard: {
    code: 'MCARD',
    test: (cardNumber = '') => {
      return (
        cardNumber[0] === '5' &&
        ['1', '2', '3', '4', '5'].includes(cardNumber[1])
      );
    },
  },
};

export const SUPPORTED_CARDS = Object.keys(supportedCards).reduce(
  (acc, cardType) => ({
    ...acc,
    [cardType]: supportedCards[cardType].code,
  }),
  {}
);

export const detectCard = memoizeBy(cardNumber => cardNumber)(
  (cardNumber = '') => {
    const cardInfo = Object.entries(supportedCards)
      .map(([cardType, detector]) => {
        return detector.test(cardNumber)
          ? {
              type: SUPPORTED_CARDS[cardType],
            }
          : undefined;
      })
      .filter(item => !!item)[0];
    return cardInfo;
  }
);
