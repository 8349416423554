import React from 'react';
import { Flex, Box } from 'rebass';
import { StandardText } from '../../../common/atoms/typography';
import Link from '../../../common/atoms/Link';
import Icon from '../../../common/atoms/Icon';
import { useThemeUI } from 'theme-ui';
import styled from '@emotion/styled';
import { useMobileScreen } from '~lib/responsive';
import { css } from '@emotion/core';
import { path } from 'lodash/fp';

const StyledLink = styled(Link)``;

const Text = styled(StandardText)`
  font-size: 14px;
  color: ${props => props.theme.colors.blueText};
`;

const NewsSideMenu = ({ relatedArticles = [], ...props }) => {
  const { theme } = useThemeUI();
  const isMobileScreen = useMobileScreen();
  return (
    <Flex {...props} flexDirection="column">
      <Text
        clickable
        mb={{
          xs: 0,
          tabletL: 40,
        }}
        bold
        to="/news"
        as={StyledLink}
      >
        <Flex alignItems="center">
          <Icon
            name="chevron-left"
            fill={theme.colors.blueText}
            width="10px"
            height="10px"
            clickable
          />
          <Box
            css={css`
              white-space: nowrap;
            `}
            ml="3px"
          >
            All news
          </Box>
        </Flex>
      </Text>
      {!isMobileScreen && !!relatedArticles.length && (
        <>
          {(relatedArticles.length > 1 ||
            !path('0.featured')(relatedArticles)) && (
            <Text
              mb={{
                xs: 0,
                tabletL: 20,
              }}
            >
              Related news:
            </Text>
          )}
          {relatedArticles.map(article => (
            <Text
              key={article._id}
              mb={{
                xs: 0,
                tabletL: 35,
              }}
              clickable
              as={StyledLink}
              to={'/news/' + (article.slug || {}).current}
            >
              {article.title}
            </Text>
          ))}
        </>
      )}
    </Flex>
  );
};

export default NewsSideMenu;
