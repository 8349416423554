import React, { useCallback } from 'react';
import HeaderLine from '~common/atoms/HeaderLine';
import SubNav from '~common/molecules/SubNav';
import { claimsMenu } from '~OMS/mock_data/menu';
import { withTheme } from 'emotion-theming';
import ClaimsHistoryFilters from '~OMS/claims/molecules/ClaimsHistoryFilters/ClaimsHistoryFilters';
import useClaimsHistory from '~OMS/claims/hooks/useClaimsHistory';
import ClaimsHistoryTable from '~OMS/claims/organisms/ClaimsHistoryTable';
import MoreDetailsOnClaimsHistory from '~OMS/claims/atoms/MoreDetailsOnClaimsHistory';
import { StandardText } from '../../../../common/atoms/typography';
import ResponsiveFlex from '~common/atoms/ResponsiveFlex';
import Pagination from '~OMS/claims/molecules/Pagination';

const ClaimsHistory = ({ theme }) => {
  const { state, actions } = useClaimsHistory();
  const {
    history,
    filters,
    loading,
    membersLoading,
    members,
    totalItems,
    pageSize,
    selectedPage,
    numberOfPages,
  } = state;
  const { filter, loadPage } = actions;

  const handlePageSelect = useCallback(
    ({ pageNumber }) => {
      loadPage(pageNumber);
    },
    [loadPage]
  );

  const heading = (
    <HeaderLine mb={4} theme={theme} title="Claims History">
      <SubNav data={claimsMenu} />
    </HeaderLine>
  );

  return (
    <>
      {heading}
      <ClaimsHistoryFilters
        mb={{
          xs: 3,
          md: 4,
        }}
        loading={loading}
        members={members}
        filters={filters}
        filter={filter}
      />

      {totalItems && !loading ? (
        <ResponsiveFlex
          alignItems="center"
          mb={4}
          justifyContent={{ xs: 'center', md: 'space-between' }}
        >
          <StandardText
            mb={{
              xs: numberOfPages > 1 ? 3 : 0,
              md: 0,
            }}
            fontSize={14}
          >
            {totalItems} claims found
          </StandardText>
          <Pagination
            selected={selectedPage}
            totalItems={totalItems}
            pageSize={pageSize}
            onPageSelect={handlePageSelect}
            onNext={handlePageSelect}
            onPrevious={handlePageSelect}
          />
        </ResponsiveFlex>
      ) : null}

      <ClaimsHistoryTable
        loading={loading || membersLoading}
        history={history}
        theme={theme}
        mb={4}
      />

      <MoreDetailsOnClaimsHistory
        mb={{
          xs: 4,
          md: 6,
        }}
      />
    </>
  );
};

export default withTheme(ClaimsHistory);
