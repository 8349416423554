import React from 'react';
import WorkingModal from '~common/molecules/WorkingModal';
import { H2, StandardText } from '~common/atoms/typography';
import styled from '@emotion/styled';
import CenterBox from '~common/atoms/CenterBox';
import { Button } from '~common/atoms/Button';
import { useLocale } from '~common/locales';

const spacing = '20px';

const StyledList = styled.ul`
  list-style-type: unset;
  padding-left: 30px;
`;

const StyledListItem = styled.li`
  line-height: 21px;
  margin-bottom: ${spacing};
  color: ${props => props.theme.colors.text.light};
`;

const SubmitClaimTermsAndCondition = ({ visible, onClose, onAgree }) => {
  const { getLocaleString } = useLocale();

  return (
    <WorkingModal visible={visible} onClose={onClose} height="auto">
      <H2 variant="semiBold" color="primary">
        Terms & Conditions
      </H2>

      <StandardText mt={4} mb={spacing} color="light">
        In respect to the services provided and by submitting this claim, I
        declare that:
      </StandardText>

      <StyledList>
        {getLocaleString('oms.claims.terms').map((term, index) => (
          <StyledListItem key={index}>
            <StandardText color="light">{term}</StandardText>
          </StyledListItem>
        ))}
      </StyledList>
      <CenterBox mt={42}>
        <Button
          data-id="agree-btn"
          onClick={onAgree}
          width={{ xs: 1, md: 180 }}
          variant="primaryInverted"
        >
          I agree
        </Button>
      </CenterBox>
    </WorkingModal>
  );
};

export default SubmitClaimTermsAndCondition;
