import React from 'react';
import { useThemeUI } from 'theme-ui';
import Icon from '~common/atoms/Icon';
import { TextResponsive } from '~common/atoms/typography';
import { Button } from '~common/atoms/Button';
import WarningModal from './WarningModal';
import { EFC_MIN_AGE, EFC_UPPER_AGE_LIMIT } from '~lib/constants';

const EfcUpgradeModal = ({ onUpgrade, onRemove }) => {
  const { theme } = useThemeUI();
  return (
    <WarningModal onConfirmRecalculateInfo={onUpgrade}>
      {({ showRecalculateInfo }) => (
        <>
          <Icon
            fill={theme.colors.error}
            name="stop-info"
            height="65px"
            width="65px"
          />
          <TextResponsive fontSize={31} lineHeight={1.2} my={32}>
            Extended Family Cover Upgrade (EFC)
          </TextResponsive>

          <TextResponsive lineHeight={1.2} fontSize={2} mb={32}>
            To cover a dependant over {EFC_MIN_AGE} that does not study full
            time, you will need to upgrade to Extended Family Cover. This will
            cover your dependant until they turn {EFC_UPPER_AGE_LIMIT}.
          </TextResponsive>

          <TextResponsive bold fontSize={2} mb={20} lineHeight={1.2}>
            How would you like to proceed?
          </TextResponsive>

          <Button
            width={1}
            variant="orangeRoundThin"
            mb={20}
            onClick={showRecalculateInfo}
          >
            Upgrade to EFC
          </Button>
          <Button width={1} variant="white-round" onClick={onRemove}>
            Remove dependant
          </Button>
        </>
      )}
    </WarningModal>
  );
};

export default EfcUpgradeModal;
