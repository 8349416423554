import React from 'react';
import Layout from 'components/Layout';
import AlreadyRegisteredWrapper from '~OMS/SSO/organisms/AlreadyRegisteredWrapper';
import { withTheme } from 'emotion-theming';

const AlreadyRegistered = props => {
  return (
    <Layout>
      <AlreadyRegisteredWrapper />
    </Layout>
  );
};

export default withTheme(AlreadyRegistered);
