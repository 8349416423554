import React from 'react';
import { Card, Flex, Box } from 'rebass';
import Icon from '~common/atoms/Icon';
import {
  H4,
  SmallStyle,
  StandardText,
} from '../../../../common/atoms/typography';
import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';

import Link from '~common/atoms/Link';

const StyledCard = styled(Card)`
  height: 100%;
  border: ${props => `2px solid ${props.theme.colors.borders}`};
  border-radius: 6px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.1);
  ${SmallStyle}
  line-height: 16px;
  padding: 20px;
`;

const StyledTitle = styled(H4)`
  font-weight: 900;
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 21px;
  color: ${props => props.theme.colors.text.light};
`;

const StyledIconBox = styled(Box)`
  transform: translateY(-40px);
  ${props => props.theme.mq.md} {
    transform: translateY(16px);
  }
`;

const InfoCardWithIcon = ({
  heading,
  children,
  to,
  external,
  icon,
  theme,
  target,
}) => {
  return (
    <StyledCard theme={theme}>
      <StyledTitle>{heading}</StyledTitle>
      <Flex mt={3} justifyContent="space-between">
        <Box
          width={{
            xs: 1,
            md: 2 / 3,
          }}
        >
          <StandardText fontSize={1}>{children}</StandardText>
        </Box>
        <Link to={to} external={external} target={target}>
          <StyledIconBox>
            <Icon
              name={icon}
              fill={theme.colors.primary}
              width="25px"
              height="25px"
              clickable={true}
            />
          </StyledIconBox>
        </Link>
      </Flex>
    </StyledCard>
  );
};

export default withTheme(InfoCardWithIcon);
