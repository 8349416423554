import React, { useMemo } from 'react';
export default ({ text, color, bold = false, wordsToHighlight = [] }) => {
  const withHighlightText = useMemo(() => {
    return text
      ?.split(' ')
      ?.map((word, index) =>
        wordsToHighlight.includes(index)
          ? `<span style="color: ${color}; font-style: italic; font-weight: ${
              bold ? '700' : '600'
            };">${word}</span>`
          : word
      )
      ?.join(' ');
  }, [text, wordsToHighlight, color, bold]);

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: withHighlightText,
      }}
    />
  );
};
