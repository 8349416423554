import React from 'react';
import { Flex } from 'rebass';

const HorizontalCenter = ({ children, ...props }) => {
  return (
    <Flex width={1} justifyContent="center" flexDirection="row" {...props}>
      {children}
    </Flex>
  );
};

export default HorizontalCenter;
