import { Box, Flex } from 'rebass';
import {
  FirstCol,
  Row,
  SecondCol,
  ThirdCol,
} from '~OMS/my-details/atoms/Layout';
import { StandardText } from '../../../../common/atoms/typography';
import { formatDate } from '~lib/dates';
import { Button } from '~common/atoms/Button';
import React from 'react';
import Icon from '../../../../common/atoms/Icon';
import Toggler from '~common/atoms/Toggler';
import { useMobileScreen } from '~lib/responsive';
import styled from '@emotion/styled';
import InfoIcon from '~common/molecules/InfoIcon';
import { EFC_UPPER_AGE_LIMIT } from '~lib/constants';

const PersonIcon = styled(Icon)`
  ${props => props.theme.mq.mdInverted} {
    transform: translateX(-5px);
  }
`;

const getThirdColJustify = (showInvite, isMobile) => {
  if (isMobile) {
    return 'center';
  }
  return !showInvite ? 'flex-end' : 'space-between';
};

const TextWithTooltip = ({ tooltip, children, ...props }) => (
  <Flex alignItems="center" {...props}>
    <StandardText mr={2} fontSize={1}>
      {children}
    </StandardText>
    <InfoIcon
      icon={{
        width: '13px',
        height: '13px',
      }}
      tooltip={tooltip}
    />
  </Flex>
);

const CardHeading = ({
  theme,
  expanded,
  onExpand,
  member,
  onInvite,
  showInvite,
  inviting,
  isDependentMember,
  ...props
}) => {
  const isMobile = useMobileScreen();
  const toggler = !isDependentMember && (
    <Toggler onClick={onExpand} expanded={expanded} />
  );

  const studentIndicator = (
    <>
      {member.isStudentDependant && (
        <TextWithTooltip tooltip="This dependant has been declared a full-time student for this calendar year.">
          Student Dependant
        </TextWithTooltip>
      )}
      {member.isNonStudentDependant && (
        <TextWithTooltip
          tooltip={`This dependant is not studying full-time and is being covered by Extended Family Cover until they turn ${EFC_UPPER_AGE_LIMIT}.`}
        >
          Non Student Dependant
        </TextWithTooltip>
      )}
    </>
  );
  return (
    <Row
      p={0}
      width={1}
      {...props}
      justifyContent="flex-start"
      alignItems="center"
    >
      <FirstCol
        alignItems="center"
        justifyContent={{
          xs: 'space-between',
          md: 'flex-start',
        }}
      >
        <Flex alignItems="center">
          <PersonIcon
            mr={2}
            name="person"
            fill={theme.colors.neutral900}
            height="44px"
            width="40px"
          />
          <StandardText fontWeight={600}>{member.fullName}</StandardText>
        </Flex>
        {isMobile && toggler}
      </FirstCol>

      <SecondCol justifyContent="space-between">
        <Box>
          <StandardText>Date of birth {formatDate(member.dob)}</StandardText>
        </Box>

        {isMobile && studentIndicator}

        {!isDependentMember && (
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <StandardText mr={2} fontSize={1}>
              Online {!isMobile ? 'access' : ''}
            </StandardText>

            <Icon
              name={member.onlineAccess ? 'success-circle-tick' : 'remove'}
              fill={
                member.onlineAccess ? theme.colors.green : theme.colors.error
              }
              height="17px"
              width="17px"
            />
          </Flex>
        )}
      </SecondCol>
      {!isDependentMember && (
        <ThirdCol
          alignItems="center"
          justifyContent={getThirdColJustify(showInvite, isMobile)}
        >
          {showInvite ? (
            <Button
              size="medium"
              loading={inviting}
              mr={{
                xs: 0,
                md: 2,
              }}
              onClick={onInvite}
              width={{
                xs: 1,
                md: inviting ? 130 : 92,
              }}
              variant={theme.usualInvertedButtonStyle}
            >
              Invite
            </Button>
          ) : null}
          {!isMobile && toggler}
        </ThirdCol>
      )}
      {!isMobile &&
        (member.isStudentDependant || member.isNonStudentDependant) && (
          <ThirdCol
            alignItems="center"
            justifyContent={{
              xs: 'flex-start',
              md: 'flex-end',
            }}
          >
            {studentIndicator}
          </ThirdCol>
        )}
    </Row>
  );
};

export default CardHeading;
