import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/core';
import Icon from '~common/atoms/Icon';
import MobileNavItem from './MobileNavItem';
import { useThemeUI } from 'theme-ui';
import { MobileLogo } from '../Styles';
import MemberLoginButton from '../MemberLoginButton';
import GetQuoteButton from '../GetQuoteButton';

const StyledBackground = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.8;
  z-index: 2;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
`;

const StyledNav = styled.nav`
  background-color: ${props => props.theme.colors.white};
  position: fixed;
  top: 0;
  left: 0;
  max-height: 100%;
  max-width: 312px;
  width: 100%;
  height: 100%;
  z-index: 100;
`;

const StyledContent = styled.div`
  background-color: ${props => props.theme.colors.white};
  max-height: 100%;
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

const StyledButtonWrapper = styled(Flex)`
  justify-content: space-between;
  margin: 20px 16px 24px;
`;

const StyledButton = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
`;

const StyledGetQuoteButton = styled(GetQuoteButton)`
  min-width: 134px !important;
  width: 134px !important;
  padding: 10px 0 !important;
`;

const StyledMemberLoginButton = styled(MemberLoginButton)`
  min-width: 134px !important;
  width: 134px !important;
  padding: 10px 0 !important;
`;

function MobileNav({ data, logo }) {
  const navRef = React.createRef();
  const [isOpen, updateIsOpen] = useState(false);
  const { theme } = useThemeUI();

  const menuItems = [...data.mainMenu.items, ...data.topMenu.items];

  return (
    <>
      <StyledButton onClick={() => updateIsOpen(true)}>
        <Icon
          name="menu"
          fill={theme.colors.primary}
          width="37px"
          height="37px"
          sx={{ pointerEvents: 'none' }}
        />
      </StyledButton>
      {isOpen && (
        <>
          <Global
            styles={css`
              body {
                overflow: hidden;
              }
            `}
          />
          <StyledNav ref={navRef} window={window}>
            <StyledContent window={window}>
              <Flex
                alignContent="center"
                py={24}
                px={16}
                alignItems="center"
                sx={{ gridGap: 20 }}
              >
                <StyledButton onClick={() => updateIsOpen(false)}>
                  <Icon
                    name="closeX"
                    fill={theme.colors.primary}
                    stroke={theme.colors.primary}
                    width="26px"
                    height="26px"
                    sx={{ pointerEvents: 'none' }}
                  />
                </StyledButton>
                <MobileLogo height={32} logo={logo} />
              </Flex>
              <Flex flexDirection="column" sx={{ margin: '0 16px' }}>
                {menuItems?.map((menuItem, i) => (
                  <MobileNavItem
                    item={menuItem}
                    key={`${menuItem.title}-${i}`}
                  />
                ))}
              </Flex>
              <StyledButtonWrapper>
                <StyledGetQuoteButton />
                <StyledMemberLoginButton />
              </StyledButtonWrapper>
            </StyledContent>
          </StyledNav>
          <StyledBackground onClick={() => updateIsOpen(false)} />
        </>
      )}
    </>
  );
}

MobileNav.defaultProps = {};

MobileNav.propTypes = {
  children: PropTypes.node,
};

export default MobileNav;
