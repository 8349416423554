import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';
import { withTheme } from 'emotion-theming';
import css from '@emotion/css';
import { WithFieldAdapter } from './FieldAdapter';
import Icon from '~common/atoms/Icon';

import classnames from 'classnames';
import { HORIZONTAL_FORM, VERTICAL_FORM } from './layoutConfig';
import { useMobileScreen } from '~lib/responsive';
import { path } from 'lodash/fp';

// TODO: move this out to the separate module
export const backgroundColorStyle = props => css`
  background-color: ${props.theme.colors.white};
`;

export const errorStateStyle = props => css`
  ${props.error ? `border-color: ${props.theme.colors.error} !important` : ''};
`;

const getMarginBottom = ({ labelPosition = 'top', bottomSpacing }) =>
  bottomSpacing
    ? {
        left: HORIZONTAL_FORM.marginBottom,
        right: HORIZONTAL_FORM.marginBottom,
        top: VERTICAL_FORM.marginBottom,
      }[labelPosition]
    : 0;

const StyledControl = styled(Box)`
  position: relative;
  margin-bottom: ${getMarginBottom};
  width: 100%;
  ${props => (props.inline ? `padding-top: 12px` : '')};
  #com-1password-op-button {
    display: none !important;
  }
`;

const smallMargin = props => props.theme.space[2];

export const StyledLabel = styled.label`
  font-size: ${props =>
    props.fontSize
      ? props.theme.fontSizes[props.fontSize]
      : props.theme.fontSizes[1]}px;
  line-height: 1.3;
  margin-bottom: ${smallMargin}px;
  display: flex;
  color: ${props => props.theme.colors.text.light};
  cursor: pointer;
  width: 100%;
`;

const StyledLabelRight = styled(StyledLabel)`
  margin-right: ${props => props.theme.space[2]}px;
  margin-bottom: 0;
`;

const InfoLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoLabel = styled(StyledLabel)`
  color: ${props => props.theme.colors.text.light3};
`;

const StyledErrorMessage = styled.div`
  color: ${props => props.theme.colors.error};
  margin-top: ${props => (props.shown ? smallMargin(props) : 0)}px;
  margin-bottom: ${props => {
    if (!props.bottomSpacing) {
      return 0;
    }
    return props.shown ? 36 : 40;
  }}px;
  height: ${props => (props.shown ? 13 : 0)}px;
  font-size: ${props => props.theme.fontSizes[1]}px;
  bottom: -18px;
`;

const StyledChildrenWrapper = styled(Box)`
  width: ${props => (props.halfWidth ? 50 : 100)}%;
`;

const getError = fieldName => path(['errors', fieldName]);
const getTouched = fieldName => path(['touched', fieldName]);
const isFieldInvalid = (form, field = {}) => {
  const fieldName = field.name;
  return (
    getError(fieldName)(form) &&
    (getTouched(fieldName)(form) || form.submitCount)
  );
};

const extractErrorMessage = (form = {}, field = {}) => {
  const error = form.errors[field.name];
  return Array.isArray(error) ? error[0] : error;
};

function Control({
  field,
  label,
  info,
  labelPosition = 'top',
  theme,
  children,
  inline,
  halfWidth,
  form = {
    errors: {},
    touched: {},
  },
  className,
  bottomSpacing = true,
  ...props
}) {
  if (!field.name) {
    throw new Error('You have to set name prop on a form control');
  }
  const isMobileScreen = useMobileScreen();
  const errorShown =
    path(`errors[${field.name}].length`)(form) &&
    (form.touched[field.name] || form.submitCount);

  const errorMessageElem = (
    <StyledErrorMessage
      className="error-message"
      mt={2}
      theme={theme}
      name={field.name}
      shown={errorShown}
      bottomSpacing={bottomSpacing}
    >
      {errorShown ? (
        <div name={field.name}>{extractErrorMessage(form, field)}</div>
      ) : null}
    </StyledErrorMessage>
  );

  // TODO: re-factor recommended, it's a bit messy with too many conditions
  // eslint-disable-next-line consistent-return
  const renderHelper = () => {
    if (label && labelPosition === 'right' && isMobileScreen) {
      return (
        <>
          <Flex className="control-wrapper" alignItems="center">
            {children({
              field,
              theme,
              invalid: isFieldInvalid(form, field),
              ...props,
            })}

            <StyledLabelRight htmlFor={field.name} theme={theme}>
              {label}
            </StyledLabelRight>
          </Flex>
          {errorMessageElem}
        </>
      );
    }

    if (label && (labelPosition === 'top' || isMobileScreen)) {
      return (
        <>
          <Flex className="control-wrapper" justifyContent="space-between">
            <StyledLabel htmlFor={field.name} theme={theme}>
              {label}
            </StyledLabel>
            {info && (
              <InfoLabelWrapper>
                <Box mr={2}>
                  <Icon
                    css={{ marginBottom: '4px' }}
                    height="15px"
                    width="15px"
                    name="restricted"
                    fill={theme.colors.text.light4}
                  />
                </Box>
                <InfoLabel>{info}</InfoLabel>
              </InfoLabelWrapper>
            )}
          </Flex>
          {children({
            field,
            theme,
            invalid: isFieldInvalid(form, field),
            ...props,
          })}
          {errorMessageElem}
        </>
      );
    }

    if (label && labelPosition === 'right' && !isMobileScreen) {
      return (
        <>
          <Flex className="control-wrapper" alignItems="center">
            {children({
              field,
              theme,
              invalid: isFieldInvalid(form, field),
              ...props,
            })}

            <StyledLabelRight htmlFor={field.name} theme={theme}>
              {label}
            </StyledLabelRight>
          </Flex>
          {errorMessageElem}
        </>
      );
    }

    if (label && labelPosition === 'left' && !isMobileScreen) {
      return (
        <>
          <Flex
            className="control-wrapper"
            alignItems="center"
            flexDirection={{
              xs: 'column',
              md: 'row',
            }}
          >
            <Flex
              className="label"
              justifyContent="flex-start"
              width={HORIZONTAL_FORM.labelWidth}
            >
              <StyledLabelRight htmlFor={field.name} fontSize={2} theme={theme}>
                {label}
              </StyledLabelRight>
            </Flex>
            <Box className="control" width={HORIZONTAL_FORM.controlWidth}>
              <StyledChildrenWrapper halfWidth={halfWidth}>
                {children({
                  field,
                  theme,
                  invalid: isFieldInvalid(form, field),
                  ...props,
                })}
              </StyledChildrenWrapper>
            </Box>
          </Flex>
          <Flex justifyContent="flex-end">
            <Box width={HORIZONTAL_FORM.controlWidth}>{errorMessageElem}</Box>
          </Flex>
        </>
      );
    }

    if (!label) {
      return (
        <StyledChildrenWrapper
          className="control-content"
          halfWidth={halfWidth}
        >
          {children({
            field,
            theme,
            invalid: isFieldInvalid(form, field),
            ...props,
          })}
          {errorMessageElem}
        </StyledChildrenWrapper>
      );
    }
  };
  return (
    <StyledControl
      bottomSpacing={bottomSpacing}
      labelPosition={labelPosition}
      className={classnames('form-control', className)}
      inline={inline}
      theme={theme}
    >
      {renderHelper()}
    </StyledControl>
  );
}

Control.defaultProps = {
  field: {},
};

const DecoratedControl = withTheme(WithFieldAdapter(Control));

export const CreateControl = Component => {
  return forwardRef(({ children, ...props }, ref) => {
    return (
      <DecoratedControl {...props}>
        {({ field, theme, invalid, ...props }) => (
          <Component
            ref={ref}
            innerRef={ref}
            error={invalid}
            theme={theme}
            {...field}
            {...props}
          >
            {children}
          </Component>
        )}
      </DecoratedControl>
    );
  });
};

export default DecoratedControl;
