import React from 'react';
import InputLabel from '~OMS/styles/InputLabel';
import InputField from '~OMS/styles/InputField';

// SSO label input field component
export default function LabelInput({
  label,
  labelCss,
  inputCss,
  type,
  placeholder,
  handleClick,
  value,
  onChange,
}) {
  return (
    <div>
      <InputLabel labelCss={labelCss}>{label}</InputLabel>
      <InputField
        inputCss={inputCss}
        type={type}
        placeholder={placeholder}
        value={value}
        onClick={handleClick}
        onChange={onChange}
      />
    </div>
  );
}
