import React, { useEffect } from 'react';
import Block from '../../common/atoms/Block';
import { P2 } from '../../common/atoms/typography';
import Icon from '../../common/atoms/Icon';
import styled from '@emotion/styled';
import { Flex } from 'rebass';
import { useThemeUI } from 'theme-ui';
import useMainMenu from './useMainMenu';
import Link from './Link';
import useToggle from '~lib/hooks/useToggle';
import { whereEq } from 'lodash/fp';

const StyledMenuItemWrapper = styled(Flex)`
  padding: 10px 0;
  border-bottom: 1px solid ${props => props.theme.colors.borders};
`;

const SideMenuItem = styled(P2)`
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
  color: ${props =>
    props.selected
      ? props.theme.colors.primary
      : props.theme.colors.neutral500};
  text-decoration: none;
`;

const SubMenuItem = styled(P2)`
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
  text-decoration: none;
`;

const MenuItem = ({ item, selected = {} }) => {
  const [expanded, toggle] = useToggle(false);

  useEffect(() => {
    if (
      (item.items || []).find(
        whereEq({
          _key: selected._key,
        })
      )
    ) {
      toggle(true);
    }
  }, [selected]);

  const { theme } = useThemeUI();
  return (
    <>
      <StyledMenuItemWrapper
        onClick={() => {
          toggle();
        }}
        py={10}
        justifyContent="space-between"
        alignItems="center"
      >
        <SideMenuItem
          selected={selected._key === item._key}
          variant="semiBold"
          data={item.link}
          as={item.items && item.items.length ? undefined : Link}
        >
          {item.title}
        </SideMenuItem>

        {item.items && item.items.length ? (
          <Icon
            name={expanded ? 'chevron-up' : 'chevron-down'}
            fill={theme.colors.text.dark}
            width="15px"
            height="15px"
          />
        ) : null}
      </StyledMenuItemWrapper>
      {expanded && item.items && (
        <StyledMenuItemWrapper flexDirection="column">
          {item.items.map((subItem, index) => (
            <SubMenuItem
              variant="medium"
              color={selected._key === subItem._key ? 'primary' : 'neutral500'}
              key={index}
              py="14px"
              as={Link}
              data={subItem.link}
            >
              {subItem.title}
            </SubMenuItem>
          ))}
        </StyledMenuItemWrapper>
      )}
    </>
  );
};

const SideMenu = ({ data: { menu }, metaData }) => {
  const mainMenu = useMainMenu(menu, metaData.page._id);

  return (
    <Block pl={0} pr={0}>
      <Flex width={1} flexDirection="column">
        {mainMenu.currentToplevelItem &&
          (mainMenu.currentToplevelItem.items || []).map(item => (
            <MenuItem selected={mainMenu.currentItem} item={item} />
          ))}
      </Flex>
    </Block>
  );
};

export default SideMenu;
