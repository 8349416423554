import React from 'react';
import { Box } from 'rebass';
import { P2, P3 } from '~common/atoms/typography';

const FormulaBox = ({ title, smallTitle, subtitle, ...props }) => {
  return (
    <Box alignSelf="flex-start" {...props}>
      {title && (
        <P3 variant="semiBold" mb="4px" color="white">
          {title}
        </P3>
      )}

      {subtitle && (
        <P3 mb="1px" variant="semiBold" color="white">
          {subtitle}
        </P3>
      )}

      {smallTitle && (
        <P2 color="white" variant="semiBold">
          {smallTitle}
        </P2>
      )}
    </Box>
  );
};

export default FormulaBox;
