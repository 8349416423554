import React from 'react';
import { H3, P1 } from '~common/atoms/typography';
import CallUsNumber from '~common/molecules/CallUsNumber';

const ConvertedMembershipMessage = () => {
  return (
    <>
      <H3 variant="medium" color="primary" mb={2}>
        This application has already been submitted.
      </H3>
      <P1 variant="semiBold" color="neutral900" mb={4}>
        Call us on <CallUsNumber as={P1} inline bold />.
      </P1>
    </>
  );
};

export default ConvertedMembershipMessage;
