import { path, pipe, prop, whereEq } from 'lodash/fp';
import { paymentFrequencies } from '~OMS/payments/constants';

export const getFrequencyLabel = pipe(
  form => form || {},
  prop('frequency'),
  frequency => {
    return paymentFrequencies.find(
      whereEq({
        id: frequency,
      })
    );
  },
  path(['text'])
);

export const getPaymentTypeLabel = form => {
  return {
    BANK: 'Bank',
    MCARD: 'Master Card',
    VISA: 'Visa',
  }[form.sourceType];
};
