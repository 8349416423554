import React from 'react';
import { css } from '@emotion/core';
import { Flex, Button, Heading, Text } from 'rebass';

import Link from '~common/atoms/Link';
import styled from '@emotion/styled';
import SanityImage from '../../common/molecules/SanityImage';

const StyledTeaserImg = styled(SanityImage)`
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-shrink: 0;
`;
const ProductCard = ({ product, ...props }) => {
  return (
    <Flex
      className="product-card"
      flexDirection="column"
      alignItems="center"
      px="10px"
      pt="10px"
      pb="20px"
      bg="white"
      mt={{ xs: '20px', lg: '0' }}
      width={1}
      css={css`
        box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.3);
      `}
      {...props}
    >
      <StyledTeaserImg src={product?.teaserImage} alt="" />

      <Heading
        width={1}
        my="20px"
        color="blue"
        fontSize={3}
        fontWeight="bold"
        textAlign="left"
      >
        {product?.title}
        {product.type === 'hospital' ? ` ${product?.coverage}` : null}
      </Heading>

      <Text
        color="text.light"
        fontSize={2}
        css={css`
          max-width: 100%;
          min-height: 60px;
        `}
      >
        {product?.description}
      </Text>

      <Button
        as={Link}
        to={`/${product?.slug?.current}`}
        mt={{ xs: '20px', lg: 4 }}
        px="40px"
        variant="orange"
        css={css`
          line-height: 30px;
          margin-top: auto;

          @media (max-width: 992px) {
            width: 100%;
          }
        `}
      >
        More details
      </Button>
    </Flex>
  );
};

export default ProductCard;
