import React, { useRef } from 'react';
import { useApplicationFormContext } from '../state/ApplicationFormProvider';
import AFBlock from '../AFBlock';
import Stepper from '~common/organisms/Stepper';
import { FirstColumn, SecondColumn } from '../common/layout';
import { StandardText } from '~common/atoms/typography';
import Confirmation from '../Confirmation';
import CenterBox from '~common/atoms/CenterBox';
import { ModalErrorBoundary } from '~common/molecules/ModalErrorBoundary';

const ActiveStep = () => {
  const appFormContext = useApplicationFormContext();
  const { state, actions, activeStepProvider } = appFormContext;
  const { context: stepContext, Component: StepComponent } =
    activeStepProvider || {
      context: {
        form: {},
      },
      Component: () => (
        <StandardText color="light2" fontSize={21}>
          Yet to be implemented!!!
        </StandardText>
      ),
    };

  const clearActiveStepErrorBoundary = useRef();

  return (
    <AFBlock
      py={42}
      minHeight="calc(100vh - 197px - 200px)"
      alignItems="flex-start"
      innerProps={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          tabletP: 'row',
        },
      }}
    >
      {!state.applicationSent ? (
        <>
          <FirstColumn
            mb={{
              xs: 55,
              tabletP: 0,
            }}
            pl={0}
            pr={0}
          >
            <ModalErrorBoundary>
              <Stepper
                onStepClick={evt => {
                  clearActiveStepErrorBoundary.current();
                  actions.selectStep(evt);
                }}
                steps={state.steps}
              />
            </ModalErrorBoundary>
          </FirstColumn>
          <SecondColumn>
            <ModalErrorBoundary
              getClearErrorHandle={clearError => {
                clearActiveStepErrorBoundary.current = clearError;
              }}
            >
              <StepComponent
                appFormContext={appFormContext}
                context={stepContext}
              />
            </ModalErrorBoundary>
          </SecondColumn>
        </>
      ) : (
        <CenterBox>
          <ModalErrorBoundary>
            <Confirmation
              promotion={appFormContext.state.savedActivePromotion}
              referenceNumber={appFormContext.state.reference}
            />
          </ModalErrorBoundary>
        </CenterBox>
      )}
    </AFBlock>
  );
};

export default ActiveStep;
