import React from 'react';
import { Button } from '~common/atoms/Button';
import styled from '@emotion/styled';

const StyledButton = styled(Button)`
  width: 175px;
  &&& button {
    transition: all 300ms ease-out;
  }
  &&&&&&& button[disabled] {
    background: ${props => props.theme.colors.borders};
    border-color: ${props => props.theme.colors.borders};
    &:hover {
      background: ${props => props.theme.colors.borders};
    }
  }
`;

const AppFormConfirmButton = ({
  showArrow = false,
  children = 'Continue',
  ...props
}) => {
  return (
    <Button size="large" variant="secondary" {...props}>
      {children}
    </Button>
  );
};

export default AppFormConfirmButton;
