import React from 'react';
import StepForm from '../../common/components/StepForm';
import DebitDetailsForm from './DebitDetailsForm';
import ClaimPaymentForm from './ClaimPaymentForm';
import FormButtons from '../../common/components/FormButtons';

const Payments = ({ appFormContext, context }) => {
  return (
    <StepForm
      appFormContext={appFormContext}
      name="payments-form"
      continueButton={
        <FormButtons
          submitting={appFormContext.state.submitting}
          saving={appFormContext.state.saving}
          onSave={appFormContext.actions.saveApplicationForm}
          showSaveButton={false}
          showArrow={false}
        >
          Submit form
        </FormButtons>
      }
      onSubmit={evt => {
        if (appFormContext.state.isValid) {
          appFormContext.actions.submitApplicationForm(evt);
        }
      }}
      title="Payment"
    >
      {({ Field, handleChange, values }) => (
        <>
          <DebitDetailsForm
            debitDayDifferentToStartCoverDate={
              context.debitDayDifferentToStartCoverDate
            }
            values={values}
            Field={Field}
            handleChange={handleChange}
          />

          <ClaimPaymentForm
            values={values}
            Field={Field}
            handleChange={handleChange}
          />
        </>
      )}
    </StepForm>
  );
};

export default Payments;
