import React from 'react';
import { P2 } from '~common/atoms/typography';
import { Flex } from 'rebass';
import { useThemeUI } from 'theme-ui';
import RatesToggle from '~common/molecules/RatesToggle';
import useRatesToggle, { useRatesToggleDate } from '~lib/hooks/useRatesToggle';
import Block from '~common/atoms/Block';
import Icon from '~common/atoms/Icon';

const RatesToggleExtended = ({ selected, onBefore, onAfter, ...props }) => {
  const { theme } = useThemeUI();
  const ratesToggle = useRatesToggle();
  const ratesChangeDate = useRatesToggleDate();

  return ratesToggle ? (
    <Block bg={theme.colors.blue50} py={12} {...props}>
      <Flex
        justifyContent="space-between"
        alignItems={{
          xs: 'stretch',
          sm: 'center',
        }}
        flexDirection={{
          xs: 'column',
          sm: 'row',
        }}
      >
        <Flex flex={1}>
          <Icon
            name="circle-info"
            originalColorSchema
            width="20px"
            height="20px"
            mr="12px"
          />
          <P2 mr={3} variant="medium" color="primary">
            Our cover premiums are increasing on{' '}
            {ratesChangeDate?.format('MMMM Do YYYY')}. Switch to ‘
            <strong>After {ratesChangeDate?.format('MMMM Do YYYY')}</strong>’ to
            view new premiums.
          </P2>
        </Flex>
        <RatesToggle
          flex={{
            xs: '1',
            sm: '0 1 auto',
          }}
          mt={{
            xs: '16px',
            sm: '0',
          }}
          selected={selected}
          onBefore={onBefore}
          onAfter={onAfter}
        />
      </Flex>
    </Block>
  ) : null;
};

export default RatesToggleExtended;
