export default size => {
  if (!size) {
    return '';
  }
  const units = ['b', 'kb', 'mb', 'gb'];
  const fractionDigits = [0, 0, 1, 2];

  let index = 0;
  let currentSize = size;
  while (currentSize > 1000) {
    currentSize /= 1000;
    index += 1;
  }

  return `${currentSize.toFixed(fractionDigits[index])} ${units[
    index
  ].toUpperCase()}`;
};
