import { FormValidator, required } from '~lib/formValidation';

const isInternOrResident = profession =>
  ['Intern', 'Resident'].includes(profession);

const eligibilityValidation = ({ showRamsayQuestions }) =>
  FormValidator({
    validation: {
      existingPolicyHolder: value => {
        if (value !== false) {
          throw '';
        }
      },
      australianCitizen: value => {
        if (value !== true) {
          throw '';
        }
      },
      ramsayEmployee: value => {
        if (value !== true) {
          throw '';
        }
      },
      consent: required(
        'Consent is required before you can proceed with the rest of the application from'
      ),
      employeeId: required('Employee ID is required'),
      profession: value => {
        if (!value || !value.value || !value.optionValue) {
          throw 'Please select your profession';
        }
        if (value.value === 'avant') {
          throw '';
        }
      },
      existingAvantMember: (value, fieldName, formValues) => {
        if (
          isInternOrResident(formValues.profession?.optionValue) &&
          value === undefined
        ) {
          throw '';
        }

        if (value === true) {
          throw '';
        }
      },
    },
    transformValidation: {
      employeeId: () => showRamsayQuestions,
      ramsayEmployee: () => showRamsayQuestions,
      consent: () => showRamsayQuestions,
      existingAvantMember: form =>
        isInternOrResident(form.profession?.optionValue),
    },
  });

export default eligibilityValidation;
