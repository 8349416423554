import React from 'react';
import StepForm from '../../common/components/StepForm';
import CallUsNumber from '~common/molecules/CallUsNumber';
import { FancyCheckboxControl } from '~common/molecules/form-controls';
import MedicareDetailsForm from './MedicareDetailsForm';
import RebateApplicationForm from './RebateApplicationForm';
import { path } from 'lodash/fp';
import { APPLICATION_FORM_STEPS } from '../../constants';
import AppFormLabel from '~common/molecules/FormLabel';

const isEntitledOptions = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
    additionalInfo: (
      <>
        To be covered by this policy, each person must have full access to
        Medicare benefits. By answering no, one or more persons may not meet
        this requirement. <br />
        <br />
        Please call us on <CallUsNumber inline bold /> to discuss in further
        detail. Alternatively, you can edit the details of your policy to only
        include those who are eligible for Medicare.
      </>
    ),
  },
];

const Medicare = ({ context, appFormContext }) => {
  const { stepProvider, state, messages, changeCoverContext } = appFormContext;

  const claimRebateOptions = [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
      additionalInfo: messages.noClaimRebate,
    },
  ];

  const { yourDetailsContext, familyDetailsContext } = (() => {
    const yourDetailsStep = stepProvider.get(
      APPLICATION_FORM_STEPS.YOUR_DETAILS.value
    );

    const familyDetailsStep = stepProvider.get(
      APPLICATION_FORM_STEPS.FAMILY_DETAILS.value
    );

    return {
      yourDetailsContext: yourDetailsStep.context,
      familyDetailsContext: familyDetailsStep && familyDetailsStep.context,
    };
  })();

  return (
    <StepForm
      showSaveButton={!changeCoverContext}
      appFormContext={appFormContext}
      continueDisabled={({ values }) => !values.isEntitled}
      title="Medicare details"
      form={context.form}
    >
      {({ Field, handleChange, values }) => (
        <>
          <AppFormLabel mb={28.6}>
            The next step of your joining application is providing your Medicare
            details. These details are required for two important reasons, to
            confirm you are eligible for and to claim the Australian Government
            Rebate on Private Health Insurance, and in the event you need to
            make a medical claim.
          </AppFormLabel>
          <Field
            onChange={handleChange}
            name="isEntitled"
            label="Are all the people on this policy eligible for Medicare?"
            component={FancyCheckboxControl}
            options={isEntitledOptions}
          />
          {values.isEntitled && (
            <>
              <MedicareDetailsForm
                values={values}
                handleChange={handleChange}
                Field={Field}
              />
              <Field
                onChange={handleChange}
                name="claimRebate"
                component={FancyCheckboxControl}
                options={claimRebateOptions}
                label={({ LabelContainer }) => (
                  <LabelContainer
                    tooltip={{
                      translateX: {
                        sm: '-200px',
                        tabletP: '-170px',
                      },
                      text: 'The Australian Government Rebate helps to cover the cost of private health insurance premiums. It is income-tested and there are thresholds used to determine a person’s eligibility for the rebate. The rebate can be claimed either as a premium reduction or as a tax offset when lodging your annual tax return.',
                    }}
                  >
                    Do you want to claim the Australian Government Rebate on
                    Private Health Insurance to reduce the price you pay for
                    your policy?
                  </LabelContainer>
                )}
              />
              {values.claimRebate && (
                <RebateApplicationForm
                  noPolicyCoveredMessage={
                    appFormContext.messages.noPolicyCovered
                  }
                  familyDetailsContext={familyDetailsContext}
                  yourDetailsContext={yourDetailsContext}
                  selectedStatus={path('criteria.status.value')(state)}
                  values={values}
                  Field={Field}
                  handleChange={handleChange}
                />
              )}
            </>
          )}
        </>
      )}
    </StepForm>
  );
};

export default Medicare;
