import React from 'react';
import { H3, P2 } from '~common/atoms/typography';
import { Flex } from 'rebass';

import Card from '~common/molecules/Card';
import PropTypes from 'prop-types';
import WorkingTime from '~common/molecules/WorkingTime';
import RequestCallbackButton from '~common/molecules/RequestCallbackButton';
import CallUsWithIcon from '~common/molecules/CallUsWithIcon';

const TalkToUsCard = ({ theme }) => (
  <Card height="auto">
    <H3
      variant="semiBold"
      mb="20px"
      color={theme.colors.primary}
      textAlign="center"
      lineHeight="1.5"
    >
      Talk to us
    </H3>
    <P2
      mb="10px"
      variant="medium"
      color={theme.colors.primary}
      textAlign="center"
    >
      Our Member Service experts are available to take you through the options
      available to you as well as explain any waiting periods that may apply.
    </P2>
    <WorkingTime
      render={workingTime => (
        <P2 mb="25px" variant="medium" color="primary" textAlign="center">
          {workingTime}
        </P2>
      )}
      mb="25px"
    />
    <Flex flexDirection="column" alignItems="center" justifyContent="center">
      <CallUsWithIcon
        mb="25px"
        textProps={{
          color: theme.colors.primary,
          fontSize: 3,
        }}
        iconProps={{
          color: theme.colors.primary,
        }}
      />
      <RequestCallbackButton size="medium" variant="secondaryInverted" />
    </Flex>
  </Card>
);

TalkToUsCard.propTypes = {
  onRequestCallClick: PropTypes.func.isRequired,
  theme: PropTypes.any,
};

export default TalkToUsCard;
