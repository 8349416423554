import React from 'react';
import { Box, Flex } from 'rebass';
import { currency } from '~lib/filters';
import { Caption, H2 } from '~common/atoms/typography';
import { paymentFrequency } from '~lib/filters/paymentFrequency';
const ProductCardPrice = ({
  amount,
  originalAmount,
  paymentFrequency: frequency,
  starred = true,
  isFree,
  ...props
}) => {
  return (
    <Flex alignItems="flex-end" justifyContent="center" {...props}>
      <Box>
        <H2
          variant="semiBold"
          color="neutral900"
          textAlign={isFree ? 'center' : 'left'}
        >
          {isFree ? (
            'FREE'
          ) : (
            <Flex>
              <H2 variant="semiBold" color="neutral900">
                {currency(amount)}
              </H2>
              <Caption color="neutral900" variant="medium" pl="4px">
                {paymentFrequency(frequency)}
              </Caption>
            </Flex>
          )}
          {starred && <sup>*</sup>}
        </H2>
        {isFree && (
          <H2
            variant="semiBold"
            color="neutral900"
            mt="20px"
            fontSize="18px"
            textAlign={isFree ? 'center' : 'left'}
          >
            (usually {currency(originalAmount)} {paymentFrequency(frequency)})
          </H2>
        )}
      </Box>
    </Flex>
  );
};

export default ProductCardPrice;
