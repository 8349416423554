import React from 'react';
import { Button } from '~common/atoms/Button';
import { Box } from 'rebass';
import styled from '@emotion/styled';
import { Element } from 'react-scroll';

const NavButtonsContainer = styled(Box)`
  display: grid;
  justify-content: center;
  grid-gap: 10px;
  position: absolute;
  grid-template-columns: ${props => (props.showBack ? 'auto auto' : 'auto')};
  width: 100%;
  bottom: 18px;
  ${props => props.theme.mq.tabletP} {
    position: relative;
    bottom: 0;
  }
`;

const StyledButton = styled(Button)`
  button {
    line-height: 19px;
    height: 50px;
  }
`;

const BackButton = styled(StyledButton)``;

const NextButton = styled(StyledButton)``;

const NavButtons = ({
  onNext,
  nextDisabled,
  onBack,
  showBack = true,
  lastStep = false,
  ...props
}) => {
  return (
    <Element name="nav-buttons">
      <NavButtonsContainer showBack={showBack} mt={48} {...props}>
        {showBack && (
          <BackButton
            data-id="back-btn"
            order={{
              xs: 2,
              tabletP: 1,
            }}
            variant="secondaryInverted"
            type="button"
            onClick={evt => {
              onBack(evt);
            }}
          >
            Back
          </BackButton>
        )}
        <NextButton
          data-id="next-btn"
          order={{
            xs: 1,
            tabletP: 2,
          }}
          disabled={nextDisabled}
          variant="secondary"
          onClick={onNext}
          type="submit"
        >
          {lastStep ? 'Calculate cover' : 'Next'}
        </NextButton>
      </NavButtonsContainer>
    </Element>
  );
};

export default NavButtons;
