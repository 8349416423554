import { FormValidator, required } from '~lib/formValidation';

const acceptTerms = required('Please accept this before we submit the form');

export default () =>
  FormValidator({
    validation: {
      acceptTermsAndConditions: acceptTerms,
      agreeUsingPersonalInformation: acceptTerms,
      acceptAHSA: acceptTerms,
      confirmApplicationIsTrue: acceptTerms,
      agreeFreeExtrasTerms: acceptTerms,
    },
  });
