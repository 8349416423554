import React from 'react';
import { Flex } from 'rebass';

const flexDirection = {
  xs: 'column',
  md: 'row',
};

const ResponsiveFlex = ({ children, ...props }) => {
  return (
    <Flex {...props} flexDirection={flexDirection}>
      {children}
    </Flex>
  );
};

export default ResponsiveFlex;
