import React from 'react';
import {
  FancyCheckboxControl,
  InputControl,
  CheckboxControl,
} from '~common/molecules/form-controls';
import CallUsNumber from '~common/molecules/CallUsNumber';

const ramsayEmployeeQuestions = [
  {
    value: true,
    label: 'YES',
  },
  {
    value: false,
    label: 'NO',
    additionalInfo: (
      <>
        Unfortunately our online application form is only available for current
        employees of Ramsay healthcare. Please call us on{' '}
        <CallUsNumber inline bold /> to discuss the other cover options you may
        be eligible for.
      </>
    ),
  },
];

const RamsaySpecificFields = ({ handleChange, values, Field }) => {
  return (
    <>
      <Field
        onChange={handleChange}
        name="ramsayEmployee"
        label="Are you a current employee of Ramsay healthcare?"
        options={ramsayEmployeeQuestions}
        component={FancyCheckboxControl}
      />

      {values.ramsayEmployee && (
        <Field
          onChange={handleChange}
          name="employeeId"
          label="Employee ID"
          component={InputControl}
        />
      )}
      {values.ramsayEmployee && (
        <Field
          onChange={handleChange}
          name="consent"
          component={CheckboxControl}
          align="start"
          labelPosition="right"
          label=" You authorise us to share your name, RHCA employee payroll
                number and date of birth with Ramsay Health Care for the
                purposes of determining your eligibility to become a member
                under the plan and to periodically verify your continued
                eligibility under the plan."
        />
      )}
    </>
  );
};

export default RamsaySpecificFields;
