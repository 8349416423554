import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { PersonsQuery } from 'queries/oms/common.graphql';
import {
  path,
  last,
  pipe,
  filter as lodashFilter,
  whereEq,
  map,
  reduce,
  sortBy,
} from 'lodash/fp';
import {
  CLAIM_STATUS,
  DATE_FILTER_PERIODS,
  HISTORY_PAGE_SIZE,
  SERVICE_TYPES,
} from '~OMS/claims/constants';
import { equals } from 'lodash/fp';
import { dropEmpties, pathOr } from '~lib/util';
import usePaginatedHistory from '~OMS/claims/hooks/usePaginatedHistory';
import { shouldKeepPrivate } from '~OMS/response-selectors';

const DEFAULT_FILTERS = {
  serviceType: SERVICE_TYPES.ALL,
  member: 'all',
  status: CLAIM_STATUS.ALL,
  period: last(DATE_FILTER_PERIODS),
};

const byLineId = line => +line.id.split('-')[1];

const getHistoryPayload = filters => {
  const { serviceType, member, status, period } = filters;

  return {
    input: dropEmpties({
      type: serviceType === SERVICE_TYPES.ALL ? undefined : serviceType,
      personId: member === 'all' ? undefined : member,
      status: status === CLAIM_STATUS.ALL ? undefined : status,
      period: Number(period),
      enableSubmittedClaims: true,
    }),
  };
};
const useClaimsHistory = () => {
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const { data: membersData, loading: membersLoading } = useQuery(PersonsQuery);

  const keepPrivate = shouldKeepPrivate(membersData);

  const [
    getHistory,
    {
      data,
      loading,
      loadPage,
      selectedPage,
      totalItems,
      numberOfPages,
      error,
      refetch,
      called,
    },
  ] = usePaginatedHistory({ pageSize: HISTORY_PAGE_SIZE });

  const currentLoggedInPerson = path('oms.user.personId')(membersData);

  const filter = useCallback(
    newFilters => {
      const resolvedFilters = {
        ...filters,
        ...newFilters,
      };

      setFilters(resolvedFilters);

      const payload = getHistoryPayload(resolvedFilters);
      return !equals(resolvedFilters, DEFAULT_FILTERS) || !called
        ? getHistory({
            variables: payload,
          })
        : refetch(payload);
    },
    [called, filters, getHistory, refetch]
  );

  useEffect(() => {
    if (currentLoggedInPerson) {
      filter({
        member: currentLoggedInPerson,
      });
    }

    //eslint-disable-next-line
  }, [currentLoggedInPerson]);

  const personsMap = useMemo(() => {
    if (!membersData) {
      return undefined;
    }

    return pipe(
      pathOr([], 'oms.membership.persons'),
      reduce(
        (acc, person) => ({
          ...acc,
          [person.id]: person,
        }),
        {}
      )
    )(membersData);
  }, [membersData]);

  const prepareLinesForView = pipe(
    map(line => ({
      ...line,
      person: personsMap[line.personId],
    })),
    sortBy(byLineId)
  );

  const history = useMemo(() => {
    if (!data || !personsMap) {
      return [];
    }

    const claims = data || [];

    if (filters.member === 'all') {
      return claims.map(claim => ({
        ...claim,
        lines: prepareLinesForView(claim.lines || []),
      }));
    }

    const filteredClaims = [];
    // using the imperative way with the simple for loop due to performance concerns with big claims lists
    for (let i = 0; i < claims.length; i++) {
      const lines = pipe(
        pathOr([], 'lines'),
        lodashFilter(
          whereEq({
            personId: filters.member,
          })
        ),
        prepareLinesForView,
        lines => (lines.length ? lines : null)
      )(claims[i]);

      if (lines) {
        filteredClaims.push({
          ...claims[i],
          lines,
        });
      }
    }

    return filteredClaims;

    // eslint-disable-next-line
  }, [data, membersData]);

  return {
    state: {
      error,
      loading,
      membersLoading,
      filters,
      members: path('oms.membership.persons')(membersData),
      history,
      totalItems,
      pageSize: HISTORY_PAGE_SIZE,
      selectedPage,
      numberOfPages,
      keepPrivate,
    },
    actions: {
      loadPage,
      filter,
    },
  };
};

export default useClaimsHistory;
