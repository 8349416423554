import React from '~common/molecules/FormLabel';
import SSOFormContainer from '~OMS/styles/SSOFormContainer';

import SuccessContent from '~OMS/SSO/molecules/SuccessContent';

function VerificationSuccessWrapper({ renderState, location, navigate }) {
  const details = location.state && location.state.details;

  return (
    <SSOFormContainer>
      <SuccessContent details={details} />
    </SSOFormContainer>
  );
}

export default VerificationSuccessWrapper;
