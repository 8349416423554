import React from 'react';
import {
  DropDownControl,
  FancyCheckboxControl,
  PersonNameControl,
} from '~common/molecules/form-controls';
import { genderOptions } from '../../state/options';
import useAppFormTitleOptions from '../../state/options/useAppFormTitleOptions';
import DobField from '../../common/components/DobField';
import { StandardText } from '~common/atoms/typography';

const PersonalDetailsForm = ({
  Field,
  handleChange,
  values,
  isTransferringFromAnotherFund,
  youthDiscountPercentage,
  criteria,
  hasLhcApplied,
  lhcAppliedMessage,
}) => {
  const titlesOptions = useAppFormTitleOptions('memberPartner');
  return (
    <>
      <Field
        halfWidth
        name="title"
        label="Title"
        onChange={handleChange}
        placeholder="Select"
        component={DropDownControl}
        options={titlesOptions}
      />
      <Field
        name="firstName"
        label="First name"
        onChange={handleChange}
        component={PersonNameControl}
      />
      <Field
        name="middleName"
        label="Middle name (optional)"
        onChange={handleChange}
        component={PersonNameControl}
      />
      <Field
        name="lastName"
        label="Last name"
        onChange={handleChange}
        component={PersonNameControl}
      />

      <DobField
        Field={Field}
        label="Have you held hospital cover continuously since your 31st birthday OR  continuously for the last 10 years?"
        handleChange={handleChange}
        lhcMessage={
          criteria?.lhcExempt === false &&
          hasLhcApplied && (
            <>
              <StandardText mb={2}>{lhcAppliedMessage}</StandardText>
              {isTransferringFromAnotherFund && (
                <StandardText>
                  Your loading percentage will be confirmed once we receive your
                  transfer certificate from your current health fund.
                </StandardText>
              )}
            </>
          )
        }
        showLhcFlow={values.showLhcFlow}
        isTransferringFromAnotherFund={isTransferringFromAnotherFund}
        values={values}
        youthDiscountPercentage={youthDiscountPercentage}
      />

      <Field
        name="gender"
        label="Gender"
        onChange={handleChange}
        justifyContent="space-between"
        optionClassName="gender-option"
        component={FancyCheckboxControl}
        options={genderOptions}
      />
    </>
  );
};

export default React.memo(PersonalDetailsForm);
