import React from 'react';
import { Flex } from 'rebass';

import Block from '~common/atoms/Block';
import Heading from './Heading';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import { ButtonsBase } from './Buttons';
import Icon from './Icon';
import styled from '@emotion/styled';
import { P1 } from '~common/atoms/typography';
import { usePageContext } from '~lib/providers/PageProvider';
import HorizontalCenter from '~common/atoms/HorizontalCenter';
import CenterBox from '~common/atoms/CenterBox';
import { useThemeUI } from 'theme-ui';

const IconCard = ({ data: { icon, title }, ...props }) => {
  const { theme } = useThemeUI();
  const iconElement = (
    <Icon
      width="80px"
      height="80px"
      data={{
        ...icon,
        stroke: {
          hex: theme.colors.secondary,
        },
        //ignore colors from CMS
        color: null,
      }}
    />
  );
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      {...props}
      width={{
        xs: 1,
        tabletP: 'auto',
      }}
    >
      {iconElement}

      <P1
        variant="medium"
        textAlign="center"
        maxWidth={{
          xs: 257,
          tabletP: 226,
        }}
        mt={{
          xs: 30,
        }}
        color="neutral900"
      >
        {title}
      </P1>
    </Flex>
  );
};

const Reasons = ({ reasons, ...props }) => (
  <Flex
    flexDirection={{
      xs: 'column',
      tabletP: 'row',
    }}
    justifyContent={{
      tabletP: 'space-around',
      xs: 'flex-start',
    }}
    alignItems="flex-start"
    {...props}
  >
    {reasons.map((reason, index) => (
      <IconCard
        key={reason._key}
        mb={
          index !== reasons.length - 1
            ? {
                xs: 53,
                tabletP: 0,
              }
            : 0
        }
        data={reason}
      />
    ))}
  </Flex>
);

const Content = styled(SanityRichTextContent)`
  &&& .text-block {
    font-size: ${props => props.theme.fontSizes[4]}px;
    text-align: center;
    font-weight: 600;
    color: ${props => props.theme.colors.neutral600};
    line-height: 1.4;
    ${props => props.theme.mq.tabletL} {
      width: 794px;
      font-size: 20px;
    }
  }
`;

const ReasonsToJoin = ({
  data: { title, actions, content, items },
  ...props
}) => {
  const { isCampaign } = usePageContext();
  return (
    <Block
      mb={0}
      py={{
        xs: 50,
        lg: 100,
      }}
      type={isCampaign ? 'light' : 'dark'}
      {...props}
    >
      <Heading
        variant="semiBold"
        color="neutral900"
        mb={{
          xs: 34,
          tabletL: 36,
          tabletP: 40,
        }}
        data={title}
      />
      <HorizontalCenter>
        <Content
          textAlign="center"
          content={content}
          mb={{
            md: 41,
            xs: 48,
          }}
          lineHeight={1.5}
        />
      </HorizontalCenter>

      <Reasons
        mb={{
          lg: 70,
          xs: 42,
        }}
        reasons={items}
      />

      <CenterBox>
        <ButtonsBase
          flexDirection="row"
          buttonProps={{
            minWidth: {
              xs: 'calc(50% - 8px)',
              tabletP: 175,
            },
            mb: 0,
          }}
          data={actions}
        />
      </CenterBox>
    </Block>
  );
};

export default ReasonsToJoin;
