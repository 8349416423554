import React from 'react';
import { Box, Flex } from 'rebass';
import UploadIcon from '~OMS/claims/atoms/UploadIcon';
import { Button } from '~common/atoms/Button';
import { StandardText } from '~common/atoms/typography';
import { MAX_BATCH_SIZE } from '~OMS/claims/config';

const VerticalUploader = ({
  theme,
  onClick,
  accept,
  disableAddMore,
  ...props
}) => (
  <Flex {...props} alignItems="center" flexDirection="column">
    <UploadIcon mb={4} height="100px" width="100px" theme={theme} />
    <Box width={1} mb={4}>
      <Button
        disabled={disableAddMore}
        onClick={onClick}
        width={1}
        variant="secondaryInverted"
      >
        Upload receipt
      </Button>
    </Box>
    <StandardText textAlign="center" fontSize={1} color="light2">
      Drag and drop files or click here to upload. Maximum size of files:{' '}
      {MAX_BATCH_SIZE}MB. File types accepted: {accept.join(' ')}
    </StandardText>
  </Flex>
);

export default VerticalUploader;
