import React from 'react';
import Button from './Button';
import Block from '../../common/atoms/Block';
import styled from '@emotion/styled';
import { Box } from 'rebass';

const ButtonsWrapper = styled(Box)`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(
    ${props => props.buttonsCount},
    ${props => (props.buttonsCount === 1 ? 'auto' : '1fr')}
  );
  grid-column-gap: 16px;
  ${props => props.theme.mq.tabletP} {
    width: auto;
    grid-template-columns: repeat(${props => props.buttonsCount}, auto);
  }
`;

export const ButtonsBase = ({
  data: { buttons = [], align = 'center' } = {},
  spacing,
  buttonProps,
  size,
  ...props
}) => {
  const justifyContent = {
    left: 'flex-start',
    center: 'center',
  }[align];

  return (
    <ButtonsWrapper
      buttonsCount={buttons.length}
      flexDirection={{
        xs: 'column',
        tabletP: 'row',
      }}
      justifyContent={justifyContent}
      {...props}
    >
      {buttons.map((button, index) => {
        const isLast = index === buttons.length - 1;
        const appliedSpacing = spacing && !isLast ? spacing : undefined;
        return (
          <Button
            size={size}
            index={index}
            mb={0}
            {...buttonProps}
            {...appliedSpacing}
            key={button._key}
            data={button}
          />
        );
      })}
    </ButtonsWrapper>
  );
};

export default ({ data, ...props }) => {
  return (
    <Block {...props}>
      <ButtonsBase data={data} />
    </Block>
  );
};
