import React from 'react';
import styled from '@emotion/styled';
import { Form } from '~common/molecules/Form';
import FormField from '~common/molecules/FormField';
import StepTitle from './StepTitle';
import FormButtons from './FormButtons';
import { formLayout } from '../layout';
import useSmoothScrollTop from '~lib/hooks/useSmoothScrollTop';
import InvalidFormError from './InvalidFormError';

const StepFormContainer = styled.form`
  ${formLayout};
`;

const StepForm = ({
  showSaveButton = true,
  continueButton,
  hideContinueButton = () => false,
  continueDisabled = () => false,
  children,
  title,
  onSubmit,
  appFormContext,
  ...props
}) => {
  const { actions, activeStepProvider, state } = appFormContext;
  useSmoothScrollTop();

  return (
    <Form
      onSubmit={
        onSubmit ||
        (() => {
          actions.confirmStep();
        })
      }
      form={activeStepProvider.context.form}
      {...props}
    >
      {formProps => {
        formProps.Field = FormField;
        const showInvalidFormError =
          formProps.submitCount && !formProps.isValid;
        return (
          <StepFormContainer onSubmit={formProps.handleSubmit}>
            {title && <StepTitle>{title}</StepTitle>}
            {children(formProps)}
            {showInvalidFormError ? <InvalidFormError /> : null}
            {!hideContinueButton(formProps) &&
              (continueButton || (
                <FormButtons
                  saving={state.saving}
                  onSave={actions.saveApplicationForm}
                  showSaveButton={showSaveButton}
                  disabled={continueDisabled(formProps)}
                />
              ))}
          </StepFormContainer>
        );
      }}
    </Form>
  );
};

export default StepForm;
