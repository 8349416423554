import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Block from '~common/atoms/Block';
import useEventListener from '~lib/hooks/useEventListener';

const StyledBlock = styled(Block)`
  position: ${props => (props.fixed ? 'sticky' : 'relative')};
  width: 100%;
  top: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: ${props => props.theme.colors.white};
  z-index: 5;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Section = ({ children, fixed, setHeaderHeight }) => {
  // get header element height for dynamic position of
  // floating summary
  const ref = useRef(null);
  const onResizeChange = () => {
    setHeaderHeight(ref.current?.clientHeight);
  };
  useEffect(onResizeChange, [ref.current?.clientHeight]);
  useEventListener('resize', onResizeChange);

  return (
    <StyledBlock as="header" fixed={fixed} ref={ref} mb={0}>
      <StyledRow>{children}</StyledRow>
    </StyledBlock>
  );
};

Section.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  setHeaderHeight: PropTypes.func.isRequired,
};

export default Section;
