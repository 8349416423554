import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import NumberFormat from 'react-number-format';
import { toWordCase } from '../../shared/utils';

const StyledCard = styled.div``;

const StyledType = styled.p`
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSizes[0]}px;
  line-height: 13px;
  color: ${props => props.theme.colors.white};
`;

const StyledPrice = styled.div`
  padding-top: 4px;
  font-weight: 500;
  font-size: ${props => props.theme.fontSizes[1]}px;
  line-height: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  color: ${props => props.theme.colors.white};

  span {
    margin-right: 4px;
    font-weight: 600;
    font-size: ${props => props.theme.fontSizes[5]}px;
    line-height: 26px;
    color: ${props => props.theme.colors.white};
  }
`;

const TotalPrice = ({ paymentFrequency, price }) => {
  return (
    <StyledPrice>
      <NumberFormat
        decimalScale={2}
        displayType={'text'}
        fixedDecimalScale={true}
        prefix={'$'}
        thousandSeparator={true}
        value={price}
      />
      {toWordCase(paymentFrequency)}
    </StyledPrice>
  );
};

const TotalCard = ({ paymentFrequency, price, type }) => {
  return (
    <StyledCard>
      <StyledType>{type}</StyledType>
      <TotalPrice paymentFrequency={paymentFrequency} price={price} />
    </StyledCard>
  );
};

TotalCard.propTypes = {
  paymentFrequency: PropTypes.string.isRequired,
  price: PropTypes.number,
  type: PropTypes.string.isRequired,
};

export default TotalCard;
