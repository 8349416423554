import React, { useEffect } from 'react';
import { StandardText } from '../../../../common/atoms/typography';
import { useThemeUI } from 'theme-ui';
import Icon from '~common/atoms/Icon';
import CenterBox from '~common/atoms/CenterBox';
import OneOffPaymentTitle from '~OMS/payments/atoms/OneOffPaymentTitle';
import { animateScroll as scroll } from 'react-scroll';

const PaymentSuccess = ({ amount }) => {
  const { theme } = useThemeUI();
  useEffect(() => {
    scroll.scrollToTop({
      duration: 500,
      timeout: 500,
      smooth: 'easeInOutQuart',
    });
  }, []);
  return (
    <CenterBox flexDirection="column">
      <OneOffPaymentTitle mb={4} />
      <Icon
        mb={4}
        height="50px"
        width="50px"
        name="success-circle-tick"
        fill={theme.colors.green}
      />
      <StandardText>
        Your payment of {amount} was processed successfully.
      </StandardText>
    </CenterBox>
  );
};

export default PaymentSuccess;
