import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import styled from '@emotion/styled';

import CoverOptions from './CoverOptions';
import CoverInclusions from './CoverInclusions';

const StyledCoverBrowser = styled.div`
  margin-top: 24px;

  @media (min-width: 768px) {
    margin-top: 66px;
  }
`;

const CoverBrowser = ({
  onInclusionsToggle,
  open,
  paymentFrequency,
  price,
  products,
  quote,
  selectedProduct,
  selectedProductCode,
  totalStickyHeight,
  type,
  updateForm,
}) => {
  return (
    <StyledCoverBrowser>
      <Element name={`quote-products-${type}`}>
        <CoverOptions
          paymentFrequency={paymentFrequency}
          price={price}
          products={products}
          quote={quote}
          selectedProduct={selectedProduct}
          selectedProductCode={selectedProductCode}
          type={type}
          updateForm={updateForm}
        />
        {selectedProduct && !quote.loading ? (
          <CoverInclusions
            onInclusionsToggle={onInclusionsToggle}
            open={open}
            products={products}
            selectedProductCode={selectedProductCode}
            totalStickyHeight={totalStickyHeight}
            type={type}
            updateForm={updateForm}
          />
        ) : null}
      </Element>
    </StyledCoverBrowser>
  );
};

CoverBrowser.propTypes = {
  onInclusionsToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  paymentFrequency: PropTypes.string.isRequired,
  price: PropTypes.number,
  products: PropTypes.object.isRequired,
  quote: PropTypes.object.isRequired,
  selectedProduct: PropTypes.object.isRequired,
  selectedProductCode: PropTypes.string.isRequired,
  totalStickyHeight: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default CoverBrowser;
