import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import Icon from './Icon';
import { withTheme } from 'emotion-theming';

const StyledIcon = styled(Icon)`
  transition: transform 200ms ease-out;
  transform: ${props => (props.expanded ? 'rotate(0)' : 'rotate(180deg)')};
  cursor: pointer;
  &&&&&&&& path {
    stroke: ${props => props.theme.colors.primary};
  }
`;

const Toggler = ({
  expanded,
  theme,
  width = '17px',
  height = '17px',
  onClick = () => {},
  ...props
}) => {
  const handleClick = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <StyledIcon
      name="chevron-up"
      fill={theme.colors.primary}
      height={height}
      width={width}
      expanded={expanded}
      clickable={true}
      onClick={handleClick}
      {...props}
    />
  );
};

export default withTheme(Toggler);
