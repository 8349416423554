export default {
  page: {
    size: 5,
  },
  sort: [{ _score: 'desc' }, { created_at: 'desc' }],
  search_fields: {
    title: {
      weight: 8,
    },
    content: {
      weight: 4,
    },
  },
  boosts: {
    type: [
      {
        type: 'value',
        factor: 10,
        value: ['hospitalProduct', 'extrasProduct'],
        operation: 'multiply',
      },
      {
        type: 'value',
        factor: 4,
        value: ['page', 'staticPage'],
        operation: 'multiply',
      },
      {
        type: 'value',
        factor: 3,
        value: ['newsArticle'],
        operation: 'multiply',
      },
    ],
    slug: [
      {
        type: 'value',
        factor: 10,
        value: ['compare-extras-products', 'compare-hospital-products'],
        operation: 'multiply',
      },
    ],
  },
};
