import {
  CHANGE_CREDIT_CARD_STATUS,
  PAYMENT_TYPE,
} from '~OMS/payments/constants';
import { path } from 'lodash/fp';

const removeNonDigits = stringNumber => stringNumber.replace(/[^0-9.]/g, '');

export const amountTextToNumber = amountText => {
  if (typeof amountText === 'number') {
    return amountText;
  }
  const sanitized = removeNonDigits(amountText);
  return Number(sanitized);
};

export const sanitizeCardNumber = (cardNumber = '') => {
  return cardNumber.replace(/\s*/g, '');
};

export const mask = (length = 16, char = 'X') => ''.padStart(length, char);

export const maskCardNumber = (cardNumber = '') =>
  cardNumber?.length >= 15
    ? `${cardNumber.substring(0, 6)}${mask(
        cardNumber.length - 9
      )}${cardNumber.substring(cardNumber.length - 3)}`
    : mask();

export const mapAccountType = type => {
  if (Object.values(PAYMENT_TYPE).includes(type)) {
    return type;
  }

  if (type === 'BANK') {
    return PAYMENT_TYPE.BANK_ACCOUNT;
  }

  return PAYMENT_TYPE.CREDIT_CARD;
};

const HAMBS_VALIDATION_CODE_FOR_NON_EXISTENT_BSB = 's:E1043';

export const isNonExistingBsb = errorResponse =>
  path('graphQLErrors[0].extensions.response.body.Fault.Code.Value')(
    errorResponse
  ) === HAMBS_VALIDATION_CODE_FOR_NON_EXISTENT_BSB;

export const isCreditCardChangeApproved = (response = {}) =>
  path('status')(response) === CHANGE_CREDIT_CARD_STATUS.SUCCESS;
