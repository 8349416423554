import React, { useEffect } from 'react';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import { Box } from 'rebass';
import { useQuery } from '@apollo/client';
import Link from '~common/atoms/Link';
import Row from '~common/molecules/Row';
import Col from '~common/molecules/Col';
import CoverSummary from '~OMS/dashboard/atoms/CoverSummary';
import { DashboardCoverQuery } from 'queries/oms/dashboard';
import useChangeCover from '~OMS/my-cover/hooks/useChangeCover';
import { path } from 'lodash/fp';
import Loading from '~common/atoms/Loading';
import { isExtrasOnly } from '~OMS/response-selectors';
import useUpgradeCoverEligible from '~OMS/payments/hooks/useUpgradeCoverEligible';
import UpgradeCoverButton from '~OMS/dashboard/molecules/UpgradeCoverButton';
import UpgradeCoverModal from '~OMS/dashboard/molecules/UpgradeCoverModal';
import useToggle from '~lib/hooks/useToggle';
import { getSessionItem, setSessionItem } from '~common/lib/util';
import { Button } from '~common/atoms/Button';
import ByBrand from '~common/atoms/ByBrand';
import Logo from '~common/atoms/Logo';
import { useMobileScreenOnly } from '~lib/responsive';

const CoverInfo = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  grid-gap: 20px;

  ${props => props.mq.sm} {
    justify-content: space-between;
    text-align: initial;
  }

  ${props => props.mq.tabletL} {
    grid-template-columns: auto 1fr;
  }
`;

const Container = styled(Box)`
  position: relative;
`;

const XSInline = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direciton: column;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;

  ${props => props.mq.tabletL} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const DashboardCover = ({ theme }) => {
  const isMobile = useMobileScreenOnly();
  const { loading, error, data } = useQuery(DashboardCoverQuery);
  const changeCover = useChangeCover({
    disableProductValidation: true,
    queryOnlyMembershipRates: true,
  });
  const excess = path('coverProducts.excess')(changeCover);
  const loadingExcess = path('coverProducts.quote.loading')(changeCover);
  const [modalVisible, toggle, , hideModal] = useToggle(false);
  const {
    isEligible: isUpgradeCoverEligible,
    disableSubsidisedExtrasPopup,
    loading: loadingUpgradeEligible,
  } = useUpgradeCoverEligible();

  useEffect(() => {
    const hasPopupShown = getSessionItem('hasPopupShown') || false;
    toggle(!hasPopupShown);
    setSessionItem('hasPopupShown', true);
  }, [toggle]);

  if (error) {
    console.error(error);
    return null;
  }

  if (loading || loadingUpgradeEligible || loadingExcess) {
    return <Loading size="medium" />;
  }

  const { plan, rate } = data.oms.membership;
  const extrasOnly = isExtrasOnly(data);

  const renderQuickButtons = () => {
    if (plan && plan.type === 'BOTH') {
      return null;
    }

    return isUpgradeCoverEligible ? (
      <Col width="auto" my={{ xs: '10px', sm: 0 }}>
        <UpgradeCoverButton />
      </Col>
    ) : (
      <Col width="auto" my={{ xs: '10px', sm: 0 }}>
        <Button
          variant={theme.upgradeCoverButtonStyle}
          as={Link}
          to={`/oms/my-cover/change`}
          size="small"
        >
          {plan.type === 'HOSPITAL_ONLY'
            ? 'Check Out Extras Cover'
            : 'Check Out Hospital Cover'}
        </Button>
      </Col>
    );
  };

  return (
    <>
      <UpgradeCoverModal
        visible={
          isUpgradeCoverEligible &&
          modalVisible &&
          !disableSubsidisedExtrasPopup
        }
        onClose={() => {
          hideModal();
        }}
      />
      {/* Disable this for now as per DWM-2299 */}
      {/*<GiveBackModal*/}
      {/*  visible={!isUpgradeCoverEligible && modalVisible}*/}
      {/*  onClose={() => {*/}
      {/*    hideModal();*/}
      {/*  }}*/}
      {/*/>*/}
      <Container>
        <CoverInfo {...theme}>
          <CoverSummary
            plan={plan}
            rate={rate}
            extrasOnly={extrasOnly}
            excess={excess}
          />
          <XSInline {...theme}>
            <Box>
              <Row
                justifyContent={{
                  xs: 'center',
                  sm: 'flex-start',
                }}
              >
                <Col>
                  <Button
                    variant="secondary"
                    size="medium"
                    as={Link}
                    to="/oms/my-cover"
                  >
                    View my cover
                  </Button>
                </Col>
                {renderQuickButtons()}
              </Row>
            </Box>

            <ByBrand
              dhf={() =>
                !isMobile && (
                  <Box
                    sx={{
                      fontSize: 0,
                      lineHeight: 1,
                      a: {
                        display: 'inline-block',
                      },
                    }}
                  >
                    <Logo height={40} applyOffset={false} />
                  </Box>
                )
              }
            />
          </XSInline>
        </CoverInfo>
      </Container>
    </>
  );
};

export default withTheme(DashboardCover);
