import React, { useState } from 'react';
import { Box } from 'rebass';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';

import useQueryParams from '~lib/hooks/useQueryParams';
import { formatToISO8601DateOnly } from '~lib/dates';

import { Formik, Form } from 'formik';
import { Title } from 'components/PageBlocks';
import Link from '~common/atoms/Link';
import FormField from '~common/molecules/FormField';
import { InputControl } from '~common/molecules/form-controls';
import DateOfBirth from '~common/molecules/DateOfBirth';
import { Button } from '~common/atoms/Button';
import { P, P2, Subheading } from '~common/atoms/typography';

import ConfirmModal from './ConfirmModal';
import Submitted from './Submitted';

import { RequestAccountDeletionMutation } from 'queries/requestDelete.graphql';

const RequestAccountDeleteForm = () => {
  const queryParams = useQueryParams();
  const [warning, setWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitRequest] = useMutation(RequestAccountDeletionMutation);

  const handleSubmit = async values => {
    setLoading(true);
    setWarning(true);
    const source = queryParams?.source;
    const input = {
      firstName: values.firstName,
      lastName: values.lastName,
      dob: formatToISO8601DateOnly(
        `${values.year}-${values.month}-${values.day}`
      ),
      membershipId: values.membershipId,
      email: values.email,
      mobile: values.mobile,
      source,
    };
    try {
      await submitRequest({
        variables: {
          input,
        },
      });
      setLoading(false);
      setSubmitted(true);
    } catch (e) {
      console.error(e);
      setError(e);
      setWarning(false);
      setLoading(false);
    }
  };

  const showWarning = () => {
    setWarning(true);
  };

  return (
    <>
      {submitted ? (
        <Submitted />
      ) : (
        <>
          <Title
            data={{
              text: 'Request to delete OMS & Mobile App account',
            }}
            mb={{
              xs: 14,
              tabletP: 44,
            }}
            titleProps={{
              style: {
                textAlign: 'left',
              },
            }}
            pl={0}
            pr={0}
          />
          <P mb={44}>
            To delete your mobile app and Online Member Services accounts and
            login credentials, please complete the below form. We will process
            your request within 3 business days.
          </P>
          <Formik
            onSubmit={handleSubmit}
            validationSchema={RequestAccountDeleteForm.validation}
          >
            {({ isValid, setFieldValue }) => (
              <Form>
                <Box>
                  <FormField
                    name="firstName"
                    label="First name"
                    component={InputControl}
                    onChange={e => setFieldValue('firstName', e.target.value)}
                  />
                  <FormField
                    name="lastName"
                    label="Last name"
                    component={InputControl}
                    onChange={e => setFieldValue('lastName', e.target.value)}
                  />
                  <DateOfBirth />
                  <FormField
                    name="membershipId"
                    label="Membership number"
                    onChange={e =>
                      setFieldValue('membershipId', e.target.value)
                    }
                    component={InputControl}
                  />
                  <FormField
                    name="email"
                    label="Email address"
                    onChange={e => setFieldValue('email', e.target.value)}
                    component={InputControl}
                  />
                  <FormField
                    name="mobile"
                    label="Mobile number"
                    onChange={e => setFieldValue('mobile', e.target.value)}
                    component={InputControl}
                  />
                </Box>
                <Box>
                  <Button
                    disabled={!isValid}
                    loading={loading}
                    type="button"
                    onClick={showWarning}
                    variant="primary"
                  >
                    Submit
                  </Button>
                </Box>
                <ConfirmModal open={warning} toggleOpen={setWarning} />
              </Form>
            )}
          </Formik>
          {error && (
            <>
              <P2 color="red" mt={44} mb={12}>
                Sorry, something went wrong there. Please try again.
              </P2>
              <P2 color="red">{error?.message}</P2>
            </>
          )}
        </>
      )}
      <Subheading mt={44}>Additional information</Subheading>
      <P2>
        <br />•{' '}
        <Link style={{ fontWeight: 600 }} to="/privacy-policy">
          Privacy policy
        </Link>
      </P2>
      <P2>
        <br />•{' '}
        <Link style={{ fontWeight: 600 }} to="/terms-of-use">
          Online Member Services terms of use
        </Link>
      </P2>
      <P2>
        <br />•{' '}
        <Link style={{ fontWeight: 600 }} to="/app-conditions-of-use">
          Mobile app terms of use
        </Link>
      </P2>
    </>
  );
};

export default RequestAccountDeleteForm;

RequestAccountDeleteForm.validation = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  membershipId: Yup.string()
    .required('Membership number is required')
    .length(6, 'Membership number must be exactly 6 characters'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  day: Yup.number().required('Day is required'),
  month: Yup.number().required('Month is required'),
  year: Yup.number().required('Year is required'),
  mobile: Yup.string().required('Mobile number is required'),
});
