import useForm from '~lib/hooks/useForm';
import { useEffect, useMemo } from 'react';
import { path } from 'lodash/fp';
import { toDate } from '~lib/dates';
import usePaymentSerializerDefault from './usePaymentSerializer';
import paymentsValidation from '../../validation/paymentsValidation';
import { DEBIT_TYPE } from '../../constants';
import { dropProps } from '~lib/util';
import { PAYMENT_TYPE } from '~lib/constants';

const LAST_DAY_OF_MONTH = 'last_day_of_month';

export default (
  { criteria, setCriteria },
  {
    yourDetailsContext,
    persistKey,
    usePaymentSerializer = usePaymentSerializerDefault,
  }
) => {
  const { privateHealthDetailsForm, personalDetailsForm } = yourDetailsContext;
  const form = useForm(
    {
      debitType: DEBIT_TYPE.BANK_ACCOUNT,
      useBankAccountDetails: true,
    },
    {
      persistKey,
      mapFormBeforePersist: value => {
        const dontPersistFields = ['cardNumber', 'cvv', 'expiry'];
        return {
          ...value,
          errors: dropProps(dontPersistFields, value.errors),
          values: dropProps(dontPersistFields, value.values),
        };
      },
      validate: paymentsValidation(),
      onChange: {
        debitType: value => {
          if (value === DEBIT_TYPE.CREDIT_CARD) {
            return {
              useBankAccountDetails: false,
            };
          }

          return undefined;
        },
        paymentFrequency: value => {
          setCriteria(
            {
              ...criteria,
              paymentFrequency: value,
            },
            {
              notifyPriceChange: true,
            }
          );
        },
      },
    }
  );

  const { serialize } = usePaymentSerializer({
    form,
    personalDetailsForm,
    privateHealthDetailsForm,
  });

  const deserialize = data => {
    return {
      ...data,
      debitType: {
        creditCard: PAYMENT_TYPE.CREDIT_CARD,
        bank: PAYMENT_TYPE.BANK_ACCOUNT,
      }[data.debitType],
    };
  };

  useEffect(() => {
    if (!path('values.startCoverDate')(privateHealthDetailsForm)) {
      return;
    }
    const startCoverDay = toDate(
      privateHealthDetailsForm.values.startCoverDate
    ).get('date');
    form.setForm({
      preferredDebitDay:
        startCoverDay > 28 ? LAST_DAY_OF_MONTH : String(startCoverDay),
    });
  }, [privateHealthDetailsForm.values]);

  useEffect(() => {
    if (form.values.paymentFrequency !== criteria.paymentFrequency) {
      form.setForm({
        paymentFrequency: criteria.paymentFrequency,
      });
    }
  }, [criteria.paymentFrequency]);

  const debitDayDifferentToStartCoverDate = useMemo(() => {
    const startCoverDate = toDate(
      privateHealthDetailsForm.values.startCoverDate
    ).get('date');
    const preferredDebitDay = form.values.preferredDebitDay;

    if (startCoverDate > 28) {
      return preferredDebitDay !== LAST_DAY_OF_MONTH;
    }

    return Number(form.values.preferredDebitDay) !== startCoverDate;
  }, [
    form.values.preferredDebitDay,
    privateHealthDetailsForm.values.startCoverDate,
  ]);

  return {
    debitDayDifferentToStartCoverDate,
    form,
    serialize,
    deserialize,
  };
};
