import React, { useEffect } from 'react';
import Icon from '~common/atoms/Icon';
import { useThemeUI } from 'theme-ui';
import CenterBox from '~common/atoms/CenterBox';
import { P3 } from '~common/atoms/typography';
import { Box } from 'rebass';
import styled from '@emotion/styled';
import CallUsNumber from '~common/molecules/CallUsNumber';
import EmailUs from '~common/molecules/EmailUs';
import Link from '~common/atoms/Link';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import useScrollTop from '~lib/hooks/useScrollTop';
import { useLocale } from '~common/locales';

const StyledList = styled.ul`
  list-style-type: disc;
  padding-left: 35px;
  margin-bottom: 20px;
`;

const StyledSanityRichTextContent = styled(SanityRichTextContent)`
  * {
    font-size: ${props => props.theme.fontSizes[2]}px;
    line-height: 1.3;
    font-weight: 400;
  }
`;

const Confirmation = ({ promotion }) => {
  const { theme } = useThemeUI();
  const { getLocaleString } = useLocale();
  const [, scrollTop] = useScrollTop();

  useEffect(() => {
    scrollTop();
  }, []);
  return (
    <Box
      id="maf-confirmation"
      width={{
        xs: 1,
        tabletL: 567,
        lg: '80%',
      }}
    >
      <CenterBox height="auto" flexDirection="column">
        <Icon
          fill={theme.colors.green}
          stroke={theme.colors.green}
          name="circle-fill-tick"
          height="50px"
          width="50px"
        />

        <P3 my={20} variant="semiBold" fontSize={2} as="h2" color="dark">
          Thank you for joining
        </P3>
      </CenterBox>

      <P3 fontSize={2} mb={16}>
        Thank you for choosing to join{' '}
        {getLocaleString('common.organisationName')}.
      </P3>

      <P3 mb={16} fontSize={2}>
        You will receive your Welcome Pack by email the next business day. This
        pack includes important information about your policy such as your
        membership number and our product brochures.
      </P3>

      <P3 mb={16} fontSize={2}>
        Your membership card/s will arrive in the mail within 5 business days.
      </P3>

      <P3 variant="semiBold" mb={16} fontSize={2}>
        Cooling off period
      </P3>

      <P3 mb={16} fontSize={2}>
        If for any reason you change your mind within the first 30 days of
        joining us and have not made a claim, simply call or email us and we
        will cancel your policy and refund any premiums you have paid
      </P3>

      <P3 variant="semiBold" mb={16} fontSize={2}>
        Transfer from another health fund
      </P3>

      <P3 mb={16} fontSize={2}>
        It can take up to 2 weeks for your old health fund to issue us with your
        Clearance Certificate. We will notify you when it is received and
        explain any effect it has (if any) on -
      </P3>
      <StyledList>
        <li>
          <P3 fontSize={2}>Any waiting periods you have yet to serve</P3>
        </li>
        <li>
          <P3 fontSize={2}>
            The price of your cover due to Lifetime Health Cover Loading or the
            Age-based discount
          </P3>
        </li>
      </StyledList>

      {promotion?.isEligible && (
        <>
          <P3 variant="semiBold" mb={16} fontSize={2}>
            Promotion eligibility
          </P3>
          {Array.isArray(promotion.confirmationPageMessage) ? (
            <StyledSanityRichTextContent
              mb={16}
              content={promotion.confirmationPageMessage}
            />
          ) : (
            <P3 mb={16}>{promotion.confirmationPageMessage}</P3>
          )}
        </>
      )}

      <P3 variant="semiBold" mb={16} fontSize={2}>
        Waiting periods
      </P3>

      <P3 mb={16} fontSize={2}>
        Please note that if you are transferring from a lower level of cover or
        have not held private health insurance before, you may be required to
        serve waiting periods for some services. Please ensure you understand
        whether waiting periods, pre-existing conditions, exclusions,
        restrictions, limits or an excess apply to your chosen level of cover.
        Information regarding this can be found on our{' '}
        <Link to="/">website</Link> or in our product brochures, which will be
        provided to you in your Welcome Pack.
      </P3>

      <P3 mb={16} fontSize={2}>
        If you have any questions about your cover, please don’t hesitate to
        contact our expert Member Service Team on{' '}
        <CallUsNumber bold fontSize={2} inline /> or email us at{' '}
        <EmailUs bold fontSize={2} inline />
      </P3>
    </Box>
  );
};

export default Confirmation;
