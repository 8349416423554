import React from 'react';
import PropTypes from 'prop-types';

import Section from './Section';
import Heading from './Heading';
import CoverProduct from './CoverProduct';
import CoverHeading from './CoverHeading';
import CoverBrowser from './CoverBrowser';
import { scrollTo } from '../shared/utils';

const CompareCovers = ({
  extrasInclusionsOpen,
  form,
  hospitalInclusionsOpen,
  isExtrasVisible,
  isHospitalVisible,
  prices,
  products,
  quote,
  selectedProduct,
  selections,
  setProductType,
  toggleExtrasInclusions,
  toggleHospitalInclusions,
  totalStickyHeight,
  updateForm,
}) => {
  return (
    <Section>
      <Heading />
      <CoverProduct
        heading={
          <CoverHeading
            primaryType="hospital"
            secondaryType="extras"
            setProductType={setProductType}
            isCoverVisible={isHospitalVisible}
            isDescriptionVisible={
              (!isHospitalVisible && isExtrasVisible) ||
              (isHospitalVisible && isExtrasVisible)
            }
          />
        }
        browser={
          <CoverBrowser
            onInclusionsToggle={() => {
              toggleHospitalInclusions();
              scrollTo('quote-products-hospital');
            }}
            open={hospitalInclusionsOpen}
            paymentFrequency={form.paymentFrequency}
            price={prices.hospital.net}
            products={products.hospital}
            quote={quote}
            selectedProduct={selections.hospital}
            selectedProductCode={selectedProduct.hospital}
            totalStickyHeight={totalStickyHeight}
            type="hospital"
            updateForm={updateForm}
          />
        }
        isVisible={isHospitalVisible}
      />
      <CoverProduct
        heading={
          <CoverHeading
            primaryType="extras"
            secondaryType="hospital"
            setProductType={setProductType}
            isCoverVisible={isExtrasVisible}
            isDescriptionVisible={
              (!isExtrasVisible && isHospitalVisible) ||
              (isExtrasVisible && isHospitalVisible)
            }
          />
        }
        browser={
          <CoverBrowser
            onInclusionsToggle={() => {
              toggleExtrasInclusions();
              scrollTo('quote-products-extras');
            }}
            open={extrasInclusionsOpen}
            paymentFrequency={form.paymentFrequency}
            price={prices.extras.net}
            products={products.extras}
            quote={quote}
            selectedProduct={selections.extras}
            selectedProductCode={selectedProduct.extras}
            totalStickyHeight={totalStickyHeight}
            type="extras"
            updateForm={updateForm}
          />
        }
        isVisible={isExtrasVisible}
      />
    </Section>
  );
};

CompareCovers.propTypes = {
  extrasInclusionsOpen: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  hospitalInclusionsOpen: PropTypes.bool.isRequired,
  isExtrasVisible: PropTypes.bool.isRequired,
  isHospitalVisible: PropTypes.bool.isRequired,
  prices: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  quote: PropTypes.object.isRequired,
  selectedProduct: PropTypes.object.isRequired,
  selections: PropTypes.object.isRequired,
  setProductType: PropTypes.func.isRequired,
  toggleExtrasInclusions: PropTypes.func.isRequired,
  toggleHospitalInclusions: PropTypes.func.isRequired,
  totalStickyHeight: PropTypes.number.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default CompareCovers;
