import React from 'react';
import { Button } from '~common/atoms/Button';

const ConfirmButton = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      variant="secondary"
      width={{
        xs: 1,
        md: 233,
      }}
    >
      {children}
    </Button>
  );
};

export default ConfirmButton;
