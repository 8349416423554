import React from 'react';
import Expandable from '~common/molecules/Expandable';
import FormTitle from '~OMS/payments/atoms/FormTitle';
import { StandardText } from '../../../../../common/atoms/typography';
import CenterBox from '~common/atoms/CenterBox';
import formModeSwitch from '~lib/hocs/formModeSwitch';
import PaymentFormWrapper from '~OMS/payments/atoms/PaymentFormWrapper';
import usePaymentClaim from '~OMS/payments/hooks/usePaymentClaim';
import EditForm from '~OMS/payments/organisms/ClaimPaymentForm/EditForm';
import ViewForm from '~OMS/payments/organisms/ClaimPaymentForm/ViewForm';
import Loading from '~common/atoms/Loading';

const ClaimPaymentFormBody = formModeSwitch({
  view: {
    Component: ViewForm,
  },
  edit: {
    Component: EditForm,
  },
});

const ClaimPaymentForm = () => {
  const { state, actions } = usePaymentClaim();
  const { formMode, data, loading, changingDetails, alert, notExistingBsb } =
    state;
  const { startEditing, viewDetails, changeDetails } = actions;
  return (
    <Expandable
      centerBody={false}
      expand={true}
      header={<FormTitle>How my claim is paid</FormTitle>}
      body={
        <>
          <StandardText color="light2">
            {data
              ? `We pay your benefits directly into your nominated bank account. The
            details of your nominated account are shown below.`
              : `We currently pay your benefits by cheque. To have your claims paid directly into your nominated account please update your bank details.`}
          </StandardText>
          <CenterBox mt={4}>
            <PaymentFormWrapper>
              {loading ? (
                <Loading />
              ) : (
                <ClaimPaymentFormBody
                  notExistingBsb={notExistingBsb}
                  alert={alert}
                  loading={changingDetails}
                  mode={formMode}
                  initialForm={data}
                  onSubmit={changeDetails}
                  onCancel={viewDetails}
                  onEditDetails={startEditing}
                />
              )}
            </PaymentFormWrapper>
          </CenterBox>
        </>
      }
    />
  );
};

export default ClaimPaymentForm;
