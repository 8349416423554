import React from 'react';

import { scrollTo } from '~lib/util';
import Question from '../Question';
import RadioButtonGroup from '../RadioButtonGroup';
import NavButtons from '../NavButtons';
import { useMobileScreenOnly } from '~lib/responsive';

const TaxRebate = ({
  form,
  onChange,
  activeStep,
  tierChoices,
  onNext,
  onBack,
}) => {
  const isMobile = useMobileScreenOnly();

  return (
    <Question
      title="What is your annual income?"
      subtitle="You may be eligible to lower your health insurance costs with the Australian Government's rebate on private health insurance. Your government rebate tier is determined by your income and age."
    >
      <RadioButtonGroup
        data-id="tier"
        name="tier"
        availableStates={tierChoices}
        defaultValue={form.tier ? form.tier.value : undefined}
        onChange={change => {
          onChange('tier', change);
          if (isMobile) {
            scrollTo('nav-buttons', { offset: -200 });
          }
        }}
      />
      <NavButtons
        nextDisabled={!activeStep.isValid}
        onNext={onNext}
        onBack={onBack}
        lastStep={true}
      />
    </Question>
  );
};

export default TaxRebate;
