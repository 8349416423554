import React, { useMemo } from 'react';
import { Flex } from 'rebass';
import { dissoc, sortBy } from 'lodash/fp';
import { P1, P3, StandardText } from '~common/atoms/typography';
import styled from '@emotion/styled';
import Icon from '~common/atoms/Icon';
import { useThemeUI } from 'theme-ui';
import Link from '~common/atoms/Link';
import InfoIcon from '~common/molecules/InfoIcon';
import { useMobileScreenOnly } from '~lib/responsive';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';

const Table = styled.table`
  border-collapse: collapse;
`;

const Row = styled.tr``;

const Column = styled.td`
  padding: 15px;
  border: 1px solid
    ${props => (props.borders ? props.theme.colors.neutral50 : 'transparent')};

  ${props => props.theme.mq.sm} {
    vertical-align: top;
  }
`;

const TitleColumn = styled(Column)`
  width: 25%;
`;

const ProductColumn = styled(Column)`
  width: 1%; //hack!!!
  ${props => props.theme.mq.tabletP} {
    width: calc(75% / 3);
  }
  text-align: center;
`;

const CellText = styled(StandardText)`
  font-size: 13px;
  color: ${props => props.theme.colors.text.light7};
  text-align: center;
`;

const StyledIcon = styled(Icon)`
  width: 100%;

  ${props => props.theme.mq.sm} {
    margin-top: 2.5px;
  }
`;

const getGroupKey = (inclusionId, product) => {
  const inclusion = product.content.inclusions?.find(
    elem => elem?.inclusion?.id === inclusionId
  );
  if (!inclusion?.groupKey) {
    return undefined;
  }
  return `${product.content.ID.current}_${inclusion.groupKey}`;
};

const GroupInfo = ({
  groupInclusions = [],
  annualLimitPerPerson,
  annualLimitTooltip,
}) => {
  return (
    !!annualLimitTooltip && (
      <InfoIcon
        ml="4px"
        icon={{
          width: '15px',
          height: '15px',
          name: 'circle-info',
        }}
        tooltip={
          <SanityRichTextContent
            content={annualLimitTooltip}
            forceColor="white"
            forceFontSize={2}
          />
        }
      />
    )
  );
};

const MobileTable = ({
  products,
  inclusions,
  getInclusionsFromGroup,
  theme,
  onMoreDetails,
  onMoreDetailsClosed,
}) => {
  return (
    <Table>
      {inclusions.map(inclusion => (
        <Row>
          <TitleColumn borders>
            <StandardText bold color="light" fontSize={3} mb={13}>
              {inclusion.title}
            </StandardText>

            {products.map(product => (
              <ProductColumn borders={false}>
                <StyledIcon
                  height="16px"
                  width="16px"
                  mb={15}
                  fill={
                    inclusion.products[product.content.ID.current]
                      ? theme.colors.green
                      : theme.colors.error
                  }
                  name={
                    inclusion.products[product.content.ID.current]
                      ? 'circle-fill-tick'
                      : 'remove'
                  }
                />

                <CellText mb="8px">
                  {inclusion.products[product.content.ID.current]?.benefitsPaid}
                </CellText>
                <Flex alignItems="center" justifyContent="center">
                  <CellText>
                    {inclusion.products[product.content.ID.current]
                      ?.annualLimitPerPerson
                      ? `${
                          inclusion.products[product.content.ID.current]
                            ?.annualLimitType || 'Annual limit'
                        }: ${
                          inclusion.products[product.content.ID.current]
                            .annualLimitPerPerson
                        }`
                      : null}
                  </CellText>
                  <GroupInfo
                    groupInclusions={getInclusionsFromGroup(
                      inclusion.id,
                      product
                    )}
                    annualLimitPerPerson={
                      inclusion.products[product.content.ID.current]
                        ?.annualLimitPerPerson
                    }
                    annualLimitTooltip={
                      inclusion.products[product.content.ID.current]
                        ?.annualLimitTooltip
                    }
                  />
                </Flex>
              </ProductColumn>
            ))}
          </TitleColumn>
        </Row>
      ))}
    </Table>
  );
};

const DesktopTable = ({
  inclusions,
  products,
  theme,
  getInclusionsFromGroup,
}) => {
  return (
    <Table>
      {inclusions.map(inclusion => (
        <Row>
          <TitleColumn borders>
            <P1 variant="semiBold" color="neutral900" mb={13}>
              {inclusion.title}
            </P1>
            <P3 variant="medium" color="neutral500">
              {inclusion.description}
            </P3>
          </TitleColumn>
          {products.map(product => (
            <ProductColumn borders>
              <StyledIcon
                height="16px"
                width="16px"
                mb={15}
                fill={
                  inclusion.products[product.content.ID.current]
                    ? theme.colors.green
                    : theme.colors.error
                }
                name={
                  inclusion.products[product.content.ID.current]
                    ? 'circle-fill-tick'
                    : 'remove'
                }
              />

              <CellText mb="8px">
                {inclusion.products[product.content.ID.current]?.benefitsPaid}
              </CellText>
              <Flex alignItems="center" justifyContent="center">
                <CellText>
                  {inclusion.products[product.content.ID.current]
                    ?.annualLimitPerPerson
                    ? `${
                        inclusion.products[product.content.ID.current]
                          ?.annualLimitType || 'Annual limit'
                      }: ${
                        inclusion.products[product.content.ID.current]
                          .annualLimitPerPerson
                      }`
                    : null}
                </CellText>
                <GroupInfo
                  groupInclusions={getInclusionsFromGroup(
                    inclusion.id,
                    product
                  )}
                  annualLimitPerPerson={
                    inclusion.products[product.content.ID.current]
                      ?.annualLimitPerPerson
                  }
                  annualLimitTooltip={
                    inclusion.products[product.content.ID.current]
                      ?.annualLimitTooltip
                  }
                />
              </Flex>
            </ProductColumn>
          ))}
        </Row>
      ))}
      <Row>
        <TitleColumn borders></TitleColumn>
        {products.map(product => (
          <ProductColumn borders>
            <Flex justifyContent="center" alignItems="center">
              <P1
                variant="semiBold"
                as={Link}
                to={'/' + product.content.ID.current}
                color="primary"
              >
                More details
              </P1>
            </Flex>
          </ProductColumn>
        ))}
      </Row>
    </Table>
  );
};

const CompareInclusionsTable = ({ products = [] }) => {
  const { theme } = useThemeUI();
  const isMobile = useMobileScreenOnly();
  const sortedProducts = useMemo(() => {
    return sortBy(product => product.content.sortOrder)(products);
  }, [products]);

  const { inclusions, getInclusionsFromGroup } = useMemo(() => {
    const groupKeyMap = {};
    const inclusionsMap = products.reduce((map, product) => {
      product.content.inclusions
        .filter(inclusion => inclusion.type === 'included')
        .forEach(inclusion => {
          const groupKey = getGroupKey(inclusion.inclusion.id, product);
          if (groupKey) {
            groupKeyMap[groupKey] = Array.isArray(groupKeyMap[groupKey])
              ? groupKeyMap[groupKey].concat(inclusion)
              : [inclusion];
          }

          map[inclusion.inclusion.id] = {
            ...inclusion.inclusion,
            products: {
              ...map[inclusion.inclusion.id]?.products,
              [product.content.ID.current]: dissoc('inclusion')(inclusion),
            },
          };
        });

      return map;
    }, {});

    return {
      inclusions: Object.values(inclusionsMap).sort((a, b) =>
        a.title.localeCompare(b.title)
      ),
      getInclusionsFromGroup: (inclusionId, product) => {
        const key = getGroupKey(inclusionId, product);
        return groupKeyMap[key]?.filter(
          item => item.inclusion.id !== inclusionId
        );
      },
    };
  }, [products]);

  return (
    <>
      {isMobile ? (
        <MobileTable
          inclusions={inclusions}
          products={sortedProducts}
          theme={theme}
          getInclusionsFromGroup={getInclusionsFromGroup}
        />
      ) : (
        <DesktopTable
          inclusions={inclusions}
          products={sortedProducts}
          theme={theme}
          getInclusionsFromGroup={getInclusionsFromGroup}
        />
      )}
    </>
  );
};

export default CompareInclusionsTable;
