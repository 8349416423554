import React, { useContext } from 'react';
import useApplicationForm from './useApplicationForm';
import { APPLICATION_FORM_STEPS } from '../constants';

export const ApplicationFormContext = React.createContext({
  state: {
    activeStep: APPLICATION_FORM_STEPS.ELIGIBILITY,
  },
  actions: {},
});

const { Provider } = ApplicationFormContext;

export const ApplicationFormProvider = ({ children, location }) => {
  const applicationForm = useApplicationForm({ location });
  return <Provider value={applicationForm}>{children}</Provider>;
};

export const useApplicationFormContext = () =>
  useContext(ApplicationFormContext);
