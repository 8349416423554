import React from 'react';
import Layout from 'components/Layout';
import LoginUnsuccessfulWrapper from '~OMS/SSO/organisms/LoginUnsuccessfulWrapper';
import { withTheme } from 'emotion-theming';

const InviteRequired = props => {
  return (
    <Layout>
      <LoginUnsuccessfulWrapper />
    </Layout>
  );
};

export default withTheme(InviteRequired);
