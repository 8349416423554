import { useMemo } from 'react';
import { myCoverMenu } from '~OMS/mock_data/menu';
import useUpgradeCoverEligible from '~OMS/payments/hooks/useUpgradeCoverEligible';
import { isChangeCoverAllowed } from '~OMS/response-selectors';
import { useQuery } from '@apollo/client';
import { MyCoverMembershipDetails } from 'queries/oms/mycover.graphql';
const filterOutCoverChange = menuItem => menuItem.id !== 'change-cover';

const filterOutCoverChangeConditionally = shouldApplyFilter =>
  shouldApplyFilter ? filterOutCoverChange : () => true;

export default () => {
  const { isEligible, loading } = useUpgradeCoverEligible();
  const myCoverDetails = useQuery(MyCoverMembershipDetails);
  const changeCoverAllowed = isChangeCoverAllowed(myCoverDetails.data);

  const menu = useMemo(() => {
    if (loading || myCoverDetails.loading) {
      return [];
    }

    const myCoverMenuFinal = myCoverMenu.filter(
      filterOutCoverChangeConditionally(!changeCoverAllowed)
    );

    return isEligible
      ? myCoverMenuFinal
          .concat({
            href: '/oms/my-cover/upgrade',
            text: 'Hospital + Subsidised Extras',
            id: 'upgrade-cover',
          })
          .filter(filterOutCoverChange)
      : myCoverMenuFinal;
  }, [myCoverMenu, isEligible, loading, myCoverDetails]);

  return menu;
};
