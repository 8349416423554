import React from 'react';
import { P2 } from '~common/atoms/typography';

const Eyebrow = ({ children, ...props }) => {
  return (
    <P2 mb="16px" variant="semiBold" color="primary" {...props}>
      {children}
    </P2>
  );
};

export default Eyebrow;
