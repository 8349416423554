import React from 'react';
import MembershipAddress from '../organisms/MembershipAddress';
import Address from '../organisms/Address';
import CommunicationPreference from '../organisms/CommunicationPreference';
import Divider from '~common/atoms/Divider';
import ExtraDetails from '../organisms/ExtraDetails';
import RebateSection from '~OMS/my-details/organisms/RebateSection';
import MyDetailsHeader from '~OMS/my-details/molecules/MyDetailsHeader';
import Members from '~OMS/my-details/organisms/Members';
import ReferFriend from '~OMS/my-details/organisms/ReferFriend';
import ByBrand from '~common/atoms/ByBrand';
import DeleteAccount from '~OMS/my-details/organisms/DeleteAccount';

const MyDetails = ({ expandMember }) => {
  return (
    <>
      <MyDetailsHeader mb={4} />
      <MembershipAddress mb={4} address={<Address />} />
      <Divider mb={4} />
      <ByBrand dhf={() => <CommunicationPreference mb={4} />} />
      <Members mb={4} expandMember={expandMember} />
      <RebateSection mb={4} />
      <ExtraDetails pb={4} />
      <ReferFriend pb={4} />
      <DeleteAccount pb={4} />
    </>
  );
};

export default MyDetails;
