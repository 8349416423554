import React, { useContext, createContext } from 'react';
import { path } from 'lodash/fp';

const PageContext = createContext({
  page: undefined,
});

const PageProvider = ({ children, page }) => {
  return (
    <PageContext.Provider
      value={{
        page,
        isCampaign: page && page.layout === 'campaign',
        // the home page does not have slug set
        isHomePage: path('slug.current')(page) === 'home',
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);

export { PageContext, PageProvider };
