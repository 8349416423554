import React, { useState } from 'react';
import Block from '~common/atoms/Block';
import { css } from '@emotion/core';
import { useThemeUI } from 'theme-ui';
import Heading from './Heading';
import { P1, P2 } from '~common/atoms/typography';
import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';
import Carousel from '~common/molecules/Carousel';

const DASH_CHAR = '–';
const ALTERNATE_DASH_CHAR = '-';

const prefixWithDash = (text = '') => {
  const trimmed = text.trim();
  return trimmed.startsWith(DASH_CHAR) ||
    trimmed.startsWith(ALTERNATE_DASH_CHAR)
    ? text
    : `${DASH_CHAR} ${text}`;
};

const Testimonial = React.forwardRef(
  ({ theme, testimonial, ...props }, ref) => (
    <Flex ref={ref} flexDirection="column" alignItems="center" {...props}>
      <P1
        variant="italic"
        color="primary"
        textAlign="center"
        mb={{
          xs: 16,
          tabletP: 30,
        }}
        width={{
          xs: 1,
          tabletL: 636,
        }}
        sx={{
          fontWeight: '500 !important',
        }}
      >
        {testimonial.quote}
      </P1>
      <P2
        variant="medium"
        textAlign="center"
        width={{
          xs: 1,
          tabletL: 445,
        }}
        color="primary"
      >
        {prefixWithDash(testimonial.attribution)}
      </P2>
    </Flex>
  )
);

const NavigatorBullet = styled(Box)`
  height: 15px;
  width: 15px;
  cursor: pointer;
  border-radius: 50%;
  transition: all 300ms ease-out;
  background: ${props =>
    props.active ? props.theme.colors.primary : props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.primary};
  ${props => props.theme.mq.tabletPInverted} {
    height: 20px;
    width: 20px;
  }
`;

const StyledCarousel = styled(Carousel)`
  overflow-x: clip;
`;

const CarouselNavigator = ({ count = 0, currentSlide, onChange, ...props }) => {
  const items = Array.from({ length: count });
  return (
    <Flex {...props}>
      {items.map((_, index) => (
        <NavigatorBullet
          active={currentSlide === index}
          key={`${index}-navigation`}
          mr={
            index < items.length - 1
              ? {
                  xs: 20,
                  tabletP: 16,
                }
              : 0
          }
          onClick={() => {
            onChange(index);
          }}
        />
      ))}
    </Flex>
  );
};

const Testimonials = ({ data: { items = [], title }, next }) => {
  const { theme } = useThemeUI();
  const [currentSlide, setCurrentSlide] = useState(0);
  return (
    <Block
      type="dark"
      py={80}
      mb={{
        xs: 0,
        tabletL: ['footerComponent', 'bannerComponent'].includes(next?._type)
          ? 0
          : undefined,
      }}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      innerProps={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Heading
        data={{
          level: 'h2',
          text: title,
        }}
        textAlign="center"
        mb={{
          xs: 40,
          tabletP: 36,
        }}
        color={theme.colors.primary}
      />
      <StyledCarousel
        enableNavigationButtons={false}
        currentSlide={currentSlide}
        containerCss={css`
          display: grid;
          grid-auto-columns: auto;
          column-gap: 0px;
          grid-template-rows: minmax(max-content, auto);
          grid-auto-flow: column;
          grid-auto-columns: minmax(100%, 100%);
        `}
      >
        {items.map((testimonial, index) => (
          <Testimonial
            theme={theme}
            title={title}
            testimonial={testimonial}
            index={index}
            key={testimonial._key}
          />
        ))}
      </StyledCarousel>
      <CarouselNavigator
        onChange={setCurrentSlide}
        currentSlide={currentSlide}
        mt={{
          xs: 40,
          tabletP: 50,
        }}
        count={items.length}
      />
    </Block>
  );
};

export default Testimonials;
