import React from 'react';
import useBrowser from '~common/hooks/useBrowser';

const CmsPage = ({ children }) => {
  const { message } = useBrowser();

  return (
    <>
      {message()}
      {children}
    </>
  );
};

export default CmsPage;
