import React from 'react';
import Layout from 'components/Layout';
import { withTheme } from 'emotion-theming';
import Navbar from '~common/organisms/Navbar';
import Wrapper from '~common/molecules/Wrapper';
import useChangeCover from '~OMS/my-cover/hooks/useChangeCover';
import ExtrasUsage from '~OMS/extras-usage/templates/ExtrasUsage';
import { path } from 'lodash/fp';
import CenterBox from '~common/atoms/CenterBox';
import Loading from '~common/atoms/Loading';
import NoExtrasUsage from '~OMS/extras-usage/templates/NoExtrasUsage';
import CheckMembership from '~common/organisms/CheckMembership';
import OmsFooter from '~OMS/OmsFooter';

const PageSwitcher = () => {
  const changeCover =
    useChangeCover({
      queryOnlyMembershipRates: true,
    }) || {};
  const hasExtras = !!path('membershipProducts.extrasProduct', changeCover);

  const render = () => {
    if (changeCover.loading) {
      return (
        <CenterBox height="100vh">
          <Loading />
        </CenterBox>
      );
    }

    return hasExtras ? (
      <ExtrasUsage />
    ) : (
      <NoExtrasUsage coverProducts={changeCover.coverProducts} />
    );
  };
  return (
    <Wrapper minHeight="100vh" pb={hasExtras ? 0 : 30}>
      {render()}
    </Wrapper>
  );
};

const MyUsageLimits = props => {
  return (
    <Layout>
      <CheckMembership>
        <Navbar location={props.location} />
        <PageSwitcher />
        <OmsFooter />
      </CheckMembership>
    </Layout>
  );
};

export default withTheme(MyUsageLimits);
