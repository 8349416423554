import { useEffect, useRef } from 'react';

function useInterval(callback, delay) {
  const savedCallback = useRef();
  const intervalId = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    clearInterval(intervalId.current);
    if (delay !== null) {
      intervalId.current = setInterval(() => {
        savedCallback.current();
      }, delay);
    }

    return () => {
      clearInterval(intervalId.current);
    };
  }, [delay]);
}

export default useInterval;
