import React from 'react';
import ActionRow from '../molecules/ActionRow';
import Divider from '~common/atoms/Divider';
import Loading from '~common/atoms/Loading';
import WorkingModal from '~common/molecules/WorkingModal';
import ModalConfirmation from '~common/molecules/ModalConfirmation';
import useMyExtraDetails from '~OMS/my-details/hooks/useMyExtraDetails';
import PaymentDescription from '~common/molecules/PaymentDescription';
import { useLocale } from '~common/locales';

const ExtraDetails = () => {
  const { state, actions } = useMyExtraDetails();

  const {
    myDetailsLoading,
    unlinking,
    checkingLinkage,
    modalOpened,
    isSsoTurnedOn,
    goingToAvant,
  } = state;
  const { hideModal, removeAvantLink, toggleLinkage } = actions;
  const { getLocaleString } = useLocale();
  const organisationName = getLocaleString('common.organisationName');

  return (
    <>
      <WorkingModal
        height="auto"
        minHeight="200px"
        centerV
        visible={modalOpened}
        onClose={hideModal}
      >
        <ModalConfirmation
          title="Remove link to Avant"
          message={`The link to your Avant account will be removed from your ${organisationName} account. The next time you wish to access your Avant account you will need enter your Avant username and password.`}
          confirmText="Remove"
          cancelText="Cancel"
          confirmLoading={unlinking}
          onConfirm={removeAvantLink}
          onCancel={hideModal}
        />
      </WorkingModal>
      {isSsoTurnedOn && (
        <>
          <Divider />
          {!myDetailsLoading && !checkingLinkage ? (
            <ActionRow
              py={4}
              title="Linked account"
              description={
                state.isLinked
                  ? 'Avant'
                  : `If you are an Avant member you can link your ${organisationName} account.`
              }
              button={{
                loading: goingToAvant,
                label: state.isLinked ? 'Remove Link' : 'Link Avant Account',
                onClick: toggleLinkage,
              }}
            />
          ) : (
            <Loading />
          )}
        </>
      )}
      <Divider />
      {myDetailsLoading ? (
        <Loading />
      ) : (
        <ActionRow
          py={4}
          button={{
            label: 'Manage Payments',
            link: '/oms/payments',
          }}
          title="Payment Details"
          description={<PaymentDescription />}
        />
      )}
    </>
  );
};

export default ExtraDetails;
