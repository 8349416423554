import React from 'react';
import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';

const StyledEmphasize = styled.span`
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
`;

const Emphasize = ({ children, theme }) => (
  <StyledEmphasize theme={theme}>{children}</StyledEmphasize>
);

export default withTheme(Emphasize);
