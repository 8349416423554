import React from 'react';
import useMembership from '../../hooks/useMembership';
import Loading from '../../atoms/Loading';
import CenterBox from '../../atoms/CenterBox';
import { StandardText } from '../../atoms/typography';

const defaultForbiddenTemplate = () => (
  <CenterBox height="100vh">
    <StandardText fontSize="32px">
      You are not allowed to access this.
    </StandardText>
  </CenterBox>
);

const allow =
  ({ to, whenForbidden = defaultForbiddenTemplate }) =>
  Component =>
  props => {
    const membership = useMembership();

    if (membership.loading || !membership.membership) {
      return (
        <CenterBox height="100vh">
          <Loading size="50px" />
        </CenterBox>
      );
    }

    if (to(membership)) {
      return <Component {...props} />;
    }

    return whenForbidden(membership);
  };

export default allow;
