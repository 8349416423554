import { useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { path } from 'lodash/fp';
import { ClaimsHistoryQuery } from 'queries/oms/claims.graphql';

const usePaginatedHistory = ({ pageSize }) => {
  const [selectedPage, setSelectedPage] = useState(1);

  const [getHistory, { data, ...rest }] = useLazyQuery(ClaimsHistoryQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const totalItems = path('oms.claims.length')(data);

  const paginatedData = useMemo(() => {
    if (!data) {
      return data;
    }

    const from = pageSize * (selectedPage - 1);
    const to = from + pageSize;

    return data.oms.claims.slice(from, to);
    //eslint-disable-next-line
  }, [data, selectedPage]);

  return [
    getHistory,
    {
      numberOfPages: data ? Math.ceil(totalItems / pageSize) : undefined,
      data: paginatedData,
      totalItems,
      loadPage: setSelectedPage,
      selectedPage,
      ...rest,
    },
  ];
};

export default usePaginatedHistory;
