import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import EmptyCard from './EmptyCard';
import LoadingCard from './LoadingCard';
import ProductCard from './ProductCard';
import TotalCard from './TotalCard';

// Bit of a mess, will clean it up at end of QQ work
const StyledCardGroup = styled.div`
  display: flex;
  flex-flow: column nowrap;

  ${props => props.theme.mq.sm} {
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: -30px 0;
    padding-top: 26px;
  }

  > article {
    position: relative;

    &:not(:first-of-type):not(:last-of-type) {
      ${props => props.theme.mq.smInverted} {
        border-top: 0;
      }
    }

    /* plus sign */
    &:first-of-type {
      &:before,
      &:after {
        content: '';
        position: absolute;
        background-color: ${props => props.theme.colors.primary};
        border-radius: 4px;
        z-index: 1;
      }

      &:before {
        width: 4px;
        height: 24px;
        top: calc(100% - 11px);
        left: 50%;

        ${props => props.theme.mq.sm} {
          top: 96px;
          right: -34px;
          left: inherit;
        }
      }

      &:after {
        width: 24px;
        height: 4px;
        top: calc(100% - 1px);
        left: calc(50% - 10px);

        ${props => props.theme.mq.sm} {
          top: 106px;
          right: -44px;
          left: inherit;
        }
      }
    }

    /* equal sign */
    &:nth-of-type(2) {
      &:before,
      &:after {
        content: none;
        position: absolute;
        background-color: ${props => props.theme.colors.primary};
        border-radius: 4px;

        ${props => props.theme.mq.sm} {
          content: '';
        }
      }

      &:before {
        width: 24px;
        height: 4px;
        top: 100px;
        right: -44px;
      }

      &:after {
        width: 24px;
        height: 4px;
        top: 110px;
        right: -44px;
      }
    }

    ${props => props.theme.mq.sm} {
      flex: 0 1 calc(33.33% - 40px);
      margin: 30px 0;
    }
  }
`;

const Cards = ({
  form,
  onApply,
  onEmailQuote,
  prices,
  quote,
  selections,
  setProductType,
}) => {
  return (
    <StyledCardGroup>
      {quote.loading ? (
        <LoadingCard type="hospital" />
      ) : selections.hospital.product ? (
        <ProductCard
          excess={selections.hospital.variant.excess}
          price={prices.hospital.net}
          paymentFrequency={form.paymentFrequency}
          subtitle={selections.hospital.product.coverage}
          summaryPoints={selections.hospital.product.summaryPoints}
          title={selections.hospital.product.title}
          type="hospital"
        />
      ) : (
        <EmptyCard
          setProductType={setProductType}
          title="No Hospital Selected"
          type="hospital"
        />
      )}
      {quote.loading ? (
        <LoadingCard type="extras" />
      ) : selections.extras.product ? (
        <ProductCard
          price={prices.extras.net}
          paymentFrequency={form.paymentFrequency}
          subtitle={selections.extras.product.coverage}
          summaryPoints={selections.extras.product.summaryPoints}
          title={selections.extras.product.title}
          type="extras"
        />
      ) : (
        <EmptyCard
          setProductType={setProductType}
          title="No Extras Selected"
          type="extras"
        />
      )}
      {quote.loading ? (
        <LoadingCard />
      ) : (
        <TotalCard
          isYouth={form.dob.age < 30 || form.partnerDob?.age < 30}
          onApply={onApply}
          onEmailQuote={onEmailQuote}
          paymentFrequency={form.paymentFrequency}
          price={prices.total}
          rebatePercentage={quote?.data?.rebatePercentage}
          subtitle="Premium"
          tier={form.tier.value}
          title="Total"
        />
      )}
    </StyledCardGroup>
  );
};

Cards.propTypes = {
  form: PropTypes.object.isRequired,
  onApply: PropTypes.func.isRequired,
  onEmailQuote: PropTypes.func.isRequired,
  prices: PropTypes.object.isRequired,
  quote: PropTypes.object.isRequired,
  selections: PropTypes.object.isRequired,
  setProductType: PropTypes.func.isRequired,
};

export default Cards;
