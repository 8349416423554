import React, { useMemo, useState } from 'react';
import { CreateControl } from '~common/molecules/form-controls/Control';
import {
  DropDownControl,
  InputControl,
  NumberInputControl,
} from '~common/molecules/form-controls/index';
import states from '~OMS/mock_data/states';
import { Option } from '~common/atoms/DropDown';
import FormLabel from '~common/molecules/FormLabel';

const ManualAddressControl = ({
  value = {},
  layout = 'vertical',
  onChange,
  onBlur,
  name,
}) => {
  const labelPosition = layout === 'vertical' ? 'top' : 'left';
  const [touched, setTouched] = useState({});

  const isTouched = fieldName => {
    return touched[fieldName];
  };

  const handleBlur = fieldName => evt => {
    if (!touched[fieldName]) {
      setTouched({
        ...touched,
        [fieldName]: true,
      });
    }

    onBlur({
      target: {
        name,
        value,
      },
    });
  };

  const form = useMemo(() => {
    return {
      errors: {
        line1: value.line1 ? undefined : ['Street Address is required field'],
        suburb: value.suburb ? undefined : ['Suburb is required field'],
        state: value.state ? undefined : ['State is required field'],
        postcode: value.postcode ? undefined : ['Postcode is  required field'],
      },
      touched: {
        line1: isTouched('line1'),
        suburb: isTouched('suburb'),
        state: isTouched('state'),
        postcode: isTouched('postcode'),
      },
    };
  }, [value, touched]);

  console.log('Manual address form', form);

  const handleChange = evt => {
    onChange({
      line1: value.line1,
      suburb: value.suburb,
      state: value.state,
      postcode: value.postcode,
      [evt.target.name]: evt.target.value,
    });
  };

  return (
    <>
      <InputControl
        field={{
          name: 'line1',
          value: value.line1,
        }}
        form={form}
        onChange={handleChange}
        label={<FormLabel>Street Address</FormLabel>}
        labelPosition={labelPosition}
        component={InputControl}
        onBlur={handleBlur('line1')}
      />

      <InputControl
        form={form}
        field={{
          name: 'suburb',
          value: value.suburb,
        }}
        label={<FormLabel>Suburb</FormLabel>}
        onChange={handleChange}
        labelPosition={labelPosition}
        onBlur={handleBlur('suburb')}
      />
      <DropDownControl
        form={form}
        halfWidth
        placeholder="Select"
        labelPosition={labelPosition}
        label={<FormLabel>State</FormLabel>}
        onBlur={handleBlur('state')}
        field={{
          name: 'state',
          value: value.state,
        }}
        onChange={value => {
          handleChange({
            target: {
              value,
              name: 'state',
            },
          });
        }}
      >
        {states.map(state => (
          <Option value={state.id} key={state.id}>
            {state.short}
          </Option>
        ))}
      </DropDownControl>
      <NumberInputControl
        form={form}
        halfWidth
        label={<FormLabel>Postcode</FormLabel>}
        onBlur={handleBlur('postcode')}
        labelPosition={labelPosition}
        onChange={handleChange}
        field={{
          name: 'postcode',
          value: value.postcode,
        }}
      />
    </>
  );
};

export default CreateControl(ManualAddressControl);
