import React, { useState, useCallback, useEffect } from 'react';
import useLocalStorage from '~lib/hooks/useLocalStorage';
import Layout from 'components/Layout';
import QuoteForm from 'components/Quote/QuoteFormNew';
import QuoteResultsNew from 'components/Quote/QuoteResultsNew';
import CmsPage from '../components/Elements/CmsPage';
import valueConversions from '~lib/valueConversions';
import useQueryParams from '~lib/hooks/useQueryParams';

const keyConversions = {
  membershipClass: 'status',
  location: 'region',
  dateOfBirth: 'dob',
  partnerDateOfBirth: 'partnerDob',
  rebateTier: 'tier',
  paymentFrequency: 'paymentFrequency',
  resultType: 'resultType',
  hospitalProduct: 'hospitalProduct',
  extrasProduct: 'extrasProduct',
};

const getValuesFromQueryParams = queryParams => {
  if (typeof window === 'undefined') {
    return {};
  }

  const results = {};

  Object.keys(queryParams).forEach(field => {
    const lookupField = keyConversions[field] || field;

    if (valueConversions[lookupField]) {
      results[lookupField] = valueConversions[lookupField](
        queryParams[field],
        queryParams
      );
    }
  });

  return results;
};

const QuotePage = props => {
  const queryParams = useQueryParams();
  const [form, setForm] = useLocalStorage('form', {
    paymentFrequency: 'MONTHLY',
    resultType: 'CURRENT',
  });
  const [formVisible, setFormVisible] = useState(
    () => !form.status || !form.region || !form.dob || !form.tier
  );

  useEffect(() => {
    const valuesFromQueryParams = getValuesFromQueryParams(queryParams);

    const paramsForm = {
      ...form,
      ...valuesFromQueryParams,
    };

    if (
      paramsForm.status === undefined ||
      !['COUPLE', 'FAMILY'].includes(paramsForm.status.value)
    ) {
      delete paramsForm.partnerDob;
    }

    if (paramsForm.dob !== undefined) {
      paramsForm.yourDob = paramsForm.dob;
    } else {
      delete paramsForm.yourDob;
    }

    setForm(paramsForm);

    setFormVisible(
      () =>
        !paramsForm.status ||
        !paramsForm.region ||
        !paramsForm.dob ||
        !paramsForm.tier
    );
  }, [queryParams]);

  useEffect(() => {
    window.history.replaceState(
      {},
      window.document.title,
      window.location.pathname
    );
  }, []);

  const updateForm = useCallback(
    (key, value) => {
      setForm(current => ({
        ...current,
        [key]: value,
      }));
    },
    [setForm]
  );

  const submitForm = useCallback(
    formToSubmit => {
      if (formToSubmit) {
        setForm(formToSubmit);
      }
      setFormVisible(false);
    },
    [setForm]
  );

  return (
    <CmsPage pageContext={props.pageContext}>
      <Layout>
        {formVisible ? (
          <QuoteForm
            footer={props.pageContext.footer}
            form={form}
            onChange={updateForm}
            submit={submitForm}
          />
        ) : (
          <QuoteResultsNew
            footer={props.pageContext.footer}
            form={form}
            setForm={setForm}
            updateForm={updateForm}
            setFormVisible={setFormVisible}
            location={props.location}
          />
        )}
      </Layout>
    </CmsPage>
  );
};

export default QuotePage;
