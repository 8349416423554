import React from 'react';
import Layout from 'components/Layout';
import VerificationSuccessWrapper from '~OMS/SSO/organisms/VerificationSuccessWrapper';
import { withTheme } from 'emotion-theming';

const VerificationSuccess = props => {
  return (
    <Layout>
      <VerificationSuccessWrapper
        location={props.location}
        renderState="success"
      />
    </Layout>
  );
};

export default withTheme(VerificationSuccess);
