import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import styled from '@emotion/styled';

import Icon from '~common/atoms/Icon';
import { useThemeUI } from 'theme-ui';

const Container = styled.div`
  width: 100%;
  padding: 20px 0 0 0;
`;

const ChoiceContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ChoiceButton = styled.button`
  display: block;
  width: calc(25% - 0.5em);
  padding: 12px;
  margin-bottom: 1em;
  font-size: 20px;
  line-height: 1.6;
  color: ${props => props.theme.colors.dark};
  font-weight: bold;
  text-align: center;
  border: 1px solid ${props => props.theme.colors.borders};
  border-radius: 5px;
  background-color: ${props => props.theme.colors.background.light2};
  cursor: pointer;

  @media screen and (max-width: 992px) {
    width: calc(50% - 0.25em);
    margin-bottom: 0.5em;
  }

  &.hasIcon {
    padding: 26px 12px;
  }

  &.selected {
    color: #fff;
    border: 1px solid ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.primary};
    > span {
      color: ${props => props.theme.colors.white};
    }
  }

  > span {
    font-size: 17.5px;
    line-height: 1.57143;
    display: block;
    font-weight: 400;
    color: ${props => props.theme.colors.light};
  }
`;

const RadioButtonGroup = ({
  className,
  onChange,
  availableStates,
  defaultValue,
  dataId,
  ...props
}) => {
  const [selectedValue, setSelected] = useState(defaultValue);
  const { theme } = useThemeUI();

  const handleSelection = state => {
    setSelected(state.value);
    onChange({
      ...state,
      availableStates,
    });
  };

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  return (
    <Container
      data-id={dataId}
      className={className}
      role="radiogroup"
      aria-activedescendant={`choice-${selectedValue}`}
      tabIndex="0"
      {...props}
    >
      <ChoiceContainer className="choice-container">
        {availableStates
          .filter(state => state.value != null)
          .map((state, idx) => (
            <ChoiceButton
              key={`choice-${idx}`}
              id={`choice-${state.value}`}
              data-id={`choice-${state.value}`}
              tabIndex="0"
              className={cx('choice', state.inputClassName, {
                selected: state.value === selectedValue,
                hasIcon: !!state.icon,
                hasDescription: !!state.description,
              })}
              onClick={() => {
                handleSelection(state);
              }}
              role="radio"
              aria-checked={state.value === selectedValue ? 'true' : 'false'}
            >
              {state.icon ? (
                <Icon
                  name={state.icon}
                  height="62px"
                  width="auto"
                  fill={
                    state.value === selectedValue
                      ? theme.colors.white
                      : theme.colors.primary
                  }
                  style={{ marginBottom: '20px' }}
                />
              ) : null}
              {state.description && <span>{state.description}</span>}
              {state.label}
            </ChoiceButton>
          ))}
      </ChoiceContainer>
    </Container>
  );
};

export default RadioButtonGroup;
