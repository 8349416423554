import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import NumberFormat from 'react-number-format';
import { checkSelectedProduct, toWordCase } from '../../../shared/utils';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import Icon from '~common/atoms/Icon';
import Popover from 'components/Elements/Popover';
import { Flex } from 'rebass';
import { useMobileScreenOnly } from '~lib/responsive';
import { Caption, P3 } from '~common/atoms/typography';
import { Button } from '~common/atoms/Button';

const StyledGapCoverage = styled.div`
  padding-top: 16px;
  margin-top: auto;

  * {
    font-size: ${props => props.theme.fontSizes[2]}px;
    line-height: 18px;
  }

  strong {
    font-weight: 600;
  }
`;

// Hopefully just temporary styles while rebrand global styles are present
const StyledSanityRichTextContent = styled(SanityRichTextContent)`
  &&&&& .text-block {
    p,
    a {
      font-size: ${props => props.theme.fontSizes[2]}px;
      line-height: 18px;
      color: ${props => props.theme.colors.white};
    }
  }
`;

const GapCoverage = ({ gapCoverage }) => {
  const isMobile = useMobileScreenOnly();
  return (
    <StyledGapCoverage>
      {isMobile ? (
        <Caption color="neutral500">Gap coverage</Caption>
      ) : (
        <P3 color="neutral500" variant="semiBold">
          Gap coverage
        </P3>
      )}
      <Flex justifyContent="space-between" alignItems="center" mt={8}>
        <Flex justifyContent="space-between" alignItems="center">
          <Icon
            name={gapCoverage.icon}
            originalColorSchema
            style={{ marginRight: 10 }}
          />
          {gapCoverage.title}
        </Flex>
        {gapCoverage.tooltip ? (
          <Popover
            position="top"
            content={
              <StyledSanityRichTextContent content={gapCoverage.tooltip} />
            }
          >
            <Icon name="circle-info" originalColorSchema />
          </Popover>
        ) : null}
      </Flex>
    </StyledGapCoverage>
  );
};

const StyledContainer = styled.div`
  padding: 16px 6px;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  ${props => props.theme.mq.md} {
    padding: 16px;
  }
`;

const StyledTitle = styled.h4`
  font-size: ${props => props.theme.fontSizes[5]}px;
  line-height: 24px;
  font-weight: 700;
  color: ${props => props.theme.colors.primary};
`;

const StyledSubtitle = styled.h4`
  font-size: ${props => props.theme.fontSizes[5]}px;
  line-height: 26px;
  color: ${props => props.theme.colors.primary};
`;

const StyledPrice = styled.div`
  color: ${props => props.theme.colors.text};
  margin-top: 16px;
  font-size: ${props => props.theme.fontSizes[1]}px;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  span {
    font-size: ${props => props.theme.fontSizes[5]}px;
    font-weight: 600;
    margin-right: 4px;
  }
`;

const StyledExcess = styled.p`
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 18px;
  margin-top: 8px;
`;

const StyledDescription = styled.p`
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 18px;
  margin-top: 16px;
`;

const StyledDisclaimer = styled.p`
  font-size: ${props => props.theme.fontSizes[1]}px;
  line-height: 15.6px;
  margin-top: 24px;
`;

const StyledButtonGroup = styled.div`
  padding-top: 16px;
  margin-top: ${props => (props.gapCoverage ? '0' : 'auto')};

  > button {
    width: 100%;
  }
`;

const StyledCard = styled('div', {
  shouldForwardProp: propName =>
    !['isSelected', 'isPopular'].includes(propName),
})`
  border-right: 1px solid
    ${props =>
      props.isSelected
        ? props.theme.colors.primary
        : props.theme.colors.neutral50};
  border-top: 1px solid
    ${props =>
      props.isSelected
        ? props.theme.colors.primary
        : props.theme.colors.neutral50};
  ${props =>
    true
      ? `border-left: 1px solid
      ${
        props.isSelected
          ? props.theme.colors.primary
          : props.theme.colors.neutral50
      };`
      : ''}
  border-bottom: none;
  background-color: ${props =>
    props.isSelected ? 'rgba(233, 236, 245, 0.4)' : props.theme.colors.white};
  position: relative;

  ${StyledTitle},
  ${StyledSubtitle},
  ${StyledDescription},
  ${StyledDisclaimer},
  ${StyledPrice} {
    ${props =>
      !props.isValid && props.selectable
        ? `color: ${props.theme.colors.neutral300};`
        : null}
  }

  &:after {
    content: 'Most popular';
    ${props => !props.isPopular && 'display: none'};
    position: absolute;
    top: -35px;
    left: -1px;
    right: -1px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 8px 0;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.secondary};
    line-height: 18px;
    font-weight: 600;
    text-align: center;
  }
`;

const TitleContainer = styled.div`
  min-height: ${props => (props.type === 'hospital' ? '100px' : '50px')};

  @media (min-width: 397px) {
    min-height: ${props => (props.type === 'hospital' ? '74px' : '50px')};
  }

  @media (min-width: 442px) {
    min-height: ${props => (props.type === 'hospital' ? '50px' : '24px')};
  }
`;

const ProductCard = ({
  description,
  gapCoverage,
  isPopular,
  paymentFrequency,
  selectedProductCode,
  subtitle,
  title,
  type,
  updateForm,
  variants,
  isValid,
  selectable,
  cardBottom,
  slug,
  index,
  asterisk,
}) => {
  const isMobile = useMobileScreenOnly();
  const { isSelected, variant } = checkSelectedProduct(
    variants,
    selectedProductCode
  );

  const handleClick = () => {
    if (isSelected) {
      return;
    }
    updateForm(`${type}Product`, variant.code);
  };

  return (
    <StyledCard
      index={index}
      selectable={selectable}
      isValid={isValid}
      isSelected={isSelected}
      isPopular={isPopular}
    >
      <StyledContainer>
        <TitleContainer type={type}>
          <StyledTitle>{title}</StyledTitle>
          <StyledSubtitle>{subtitle}</StyledSubtitle>
        </TitleContainer>
        <StyledPrice>
          {isValid || !selectable ? (
            <>
              <NumberFormat
                value={variant?.price?.net}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                suffix={asterisk ? '*' : null}
                decimalScale={2}
                fixedDecimalScale={true}
              />
              {variant?.price?.net ? toWordCase(paymentFrequency) : null}
            </>
          ) : null}
        </StyledPrice>
        {type === 'hospital' && !isMobile ? (
          <StyledExcess>
            with {variant.excess ? `$${variant.excess}` : 'no'} excess
          </StyledExcess>
        ) : null}
        <StyledDescription>{description}</StyledDescription>
        {title === 'Starter Extras' ? (
          <StyledDisclaimer>
            *Only available with Smart Starter Bronze Plus
          </StyledDisclaimer>
        ) : null}
        {gapCoverage ? <GapCoverage gapCoverage={gapCoverage} /> : null}
        <StyledButtonGroup gapCoverage={gapCoverage}>
          {selectable && (
            <Button
              data-id={`${type}-product-${index}-btn`}
              onClick={isValid ? handleClick : null}
              width="100%"
              size="small"
              variant={isSelected ? 'primary' : 'primaryInverted'}
              disabled={!isValid}
            >
              {isValid
                ? isSelected
                  ? 'Selected'
                  : 'Select cover'
                : 'Not available'}
            </Button>
          )}
        </StyledButtonGroup>
        {cardBottom({ slug })}
      </StyledContainer>
    </StyledCard>
  );
};

ProductCard.propTypes = {
  description: PropTypes.string.isRequired,
  gapCoverage: PropTypes.object,
  isPopular: PropTypes.bool.isRequired,
  paymentFrequency: PropTypes.string.isRequired,
  selectedProductCode: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  updateForm: PropTypes.func.isRequired,
  variants: PropTypes.array.isRequired,
  isValid: PropTypes.bool.isRequired,
  asterisk: PropTypes.bool,
};

export default ProductCard;
