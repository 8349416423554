import Icon from '../../../../common/atoms/Icon';
import React from 'react';

const UploadIcon = ({ theme, ...props }) => (
  <Icon
    fill={theme.colors.borders}
    name="attach-receipt"
    width="75px"
    {...props}
  />
);

export default UploadIcon;
