import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Email from '../atoms/Email';

const FOOTER_QUERY = graphql`
  {
    sanityContactDetails {
      _id
      email
    }
  }
`;

const EmailUs = props => {
  const contact = useStaticQuery(FOOTER_QUERY);
  return <Email {...props} email={contact.sanityContactDetails.email} />;
};

export default EmailUs;
