import React, { useState } from 'react';
import { Box, Button, Flex, Heading } from 'rebass';
import { css } from '@emotion/core';
import { navigate } from 'gatsby';
import SSOText from '~OMS/SSO/atoms/SSOText';
import CheckBox from '~common/atoms/CheckBox';
import styled from '@emotion/styled';
import { AVANT_DASHBOARD_URL } from '../constants';

import { useLocale } from '~common/locales';
import Logo from '~common/atoms/Logo';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-width: 100vw;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.4);

  @media screen and (max-width: 992px) {
    width: 100%;
    height: 100%;
  }
`;

const DHFLinkWrapper = props => {
  const [termsAccepted, setTermsAccept] = useState(false);
  const toggleTermsAccept = () => {
    setTermsAccept(!termsAccepted);
  };
  const goToLogin = () => {
    navigate('/oms/sso/login/?link=true');
  };

  const { getLocaleString } = useLocale();

  const organisationName = getLocaleString('common.organisationName');

  return (
    <Container>
      <Flex
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          width={{ xs: '100%', md: '480px' }}
          css={css`
            height: 500px;
            background-color: #fff;
            box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.5);

            @media screen and (max-width: 992px) {
              height: 260px;
            }
          `}
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            css={css`
              width: 100%;
              height: 100%;
            `}
          >
            <Logo />
            <SSOText color="blue" mt="40px" mb="5px">
              <strong>Not a {organisationName} member?</strong>
            </SSOText>
            <SSOText color="blue">
              Learn about{' '}
              <a
                href="https://www.doctorshealthfund.com.au"
                target="_blank"
                rel="noopener noreferrer"
                css={css`
                  &,
                  &:visited,
                  &:active {
                    color: #1d4b8d;
                    text-decoration: underline;
                  }
                `}
              >
                our private health insurance
              </a>
            </SSOText>
          </Flex>
        </Box>
        <Box
          width={{ xs: '100%', md: '480px' }}
          css={css`
            height: 840px;
            max-height: 100vh;
            background-color: #f6f6f6;
            box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.5);
            padding: 60px;
            overflow-y: scroll;

            @media screen and (max-width: 992px) {
              height: 100%;
              max-height: none;
              padding: 30px 10px;
              overflow-y: visible;
            }
          `}
        >
          <Heading mb={20}>Link your {organisationName} account</Heading>
          <SSOText>
            You can now link your Avant and {organisationName} accounts and
            easily switch between the two.
          </SSOText>
          <SSOText>
            Please ensure that you have completed your new {organisationName}
            account set up, before linking your accounts to get started.{' '}
          </SSOText>

          <Flex justifyContent={{ xs: 'flex-start', md: 'center' }} mt={20}>
            <CheckBox
              id="agree"
              name="agree"
              value={termsAccepted}
              onChange={toggleTermsAccept}
            />
            <label htmlFor="agree" style={{ cursor: 'pointer' }}>
              I authorise {organisationName} to link my Avant member login
            </label>
          </Flex>
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mt={16}
          >
            <Button
              variant="orange"
              css={{ width: '100%' }}
              disabled={!termsAccepted}
              onClick={goToLogin}
              mb={20}
            >
              Login to {organisationName}
            </Button>
            <SSOText pb={2}>
              <a
                href={AVANT_DASHBOARD_URL}
                css={css`
                  color: #4a4a4a;
                `}
              >
                Cancel
              </a>
            </SSOText>
          </Flex>
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <SSOText>
              To protect your private and confidential information, we recommend
              you do not share your username or password details, as once they
              are linked, the information in both accounts will be accessible*.
            </SSOText>
            <SSOText>
              You can unlink at any time via the 'My Details' section of your{' '}
              {organisationName} account.
            </SSOText>
          </Flex>

          <SSOText style={{ fontSize: '12px' }} mb={0}>
            <p>
              <strong>*Disclaimer</strong>
            </p>
            <p>
              By linking your {organisationName} and Avant Mutual member login
              areas, you accept and acknowledge that anyone with your login ID
              and password will be able to access your personal health
              information and/or your medical indemnity insurance information.
            </p>
            <p style={{ marginTop: '10px' }}>
              Avant Mutual Group Limited ABN 58 123 154 898, and each of its
              related entities including; Avant Insurance Limited ABN 82 003 707
              471 AFSL 238765, The {organisationName} Pty Limited ABN 68 001 417
              527 and Doctors Financial Services Pty Ltd ABN 56 610 510 328,
              will not be held liable for any resulting unauthorised, access,
              release or loss of your personal health information and/or your
              medical indemnity insurance information.
            </p>
          </SSOText>
        </Box>
      </Flex>
    </Container>
  );
};

export default DHFLinkWrapper;
