import React from 'react';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import { H3, P3, StandardText } from '~common/atoms/typography';
import { Flex, Box } from 'rebass';
import Eyebrow from '~common/atoms/Eyebrow';
import Row from '~common/molecules/Row';
import InclusionsList from '~OMS/my-cover/molecules/InclusionsList';
import ProductCardPrice from '~OMS/my-cover/molecules/ProductCardPrice';
import ProductPromoCard from '~OMS/my-cover/molecules/ProductPromoCard';
import { chainedPath } from '~lib/util';
import WithExcess from '~OMS/my-cover/molecules/WithExcess';
import CenterBox from '~common/atoms/CenterBox';
import Loading from '~common/atoms/Loading';
import Icon from '~common/atoms/Icon';
import InfoIcon from '~common/molecules/InfoIcon';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import css from '@emotion/css';

const InclusionTab = styled(Box)`
  padding: 0;
  display: ${props => (props.isActive ? 'block' : 'none')};
  ${props => props.mq.sm} {
    display: block;
  }
`;

const StyledInclusionToggle = styled.div`
  border-bottom: 1px solid ${props => props.colors.borders};
  padding-bottom: 15px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: ${props => props.colors.text.dark};
  font-size: ${props => props.fontSizes[1]}px;
  position: relative;
  cursor: pointer;
  &::before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: ${props => props.colors.primary};
    display: ${props => (props.isActive ? 'block' : 'none')};
  }
  ${props => props.mq.sm} {
    display: none;
  }
`;

const MyInclusions = ({
  theme,
  activeIndex,
  coverStatus,
  hospital,
  hospitalProduct,
  extras,
  extrasProduct,
  promoExtrasProduct,
  promoHospitalProduct,
  paymentFrequencyLabel,
  myCoverDetails,
  onInclusionToggle,
  excess,
  excessLoading,
  showExtrasPromoCard,
  showHospitalPromoCard,
  s2iMembership,
  isSubsidisedExtras,
  isChangeCoverAllowed,
  isPartiallySubsidised,
  isFullySubsidised,
}) => {
  const plan = chainedPath('oms.membership.plan')(myCoverDetails);
  const rate = chainedPath('oms.membership.rate')(myCoverDetails);

  // TODO: get this from the api once added
  const hasActiveWaitingPeriods = false;

  const noExtras =
    myCoverDetails &&
    myCoverDetails.oms.membership.plan.type === 'HOSPITAL_ONLY';

  const hospitalAmount = isSubsidisedExtras
    ? rate('hospital.net') + rate('hospital.subsidy') - rate('extras.rebate')
    : rate('hospital.net');
  const extrasAmount = isSubsidisedExtras
    ? rate('extras.net') - rate('hospital.subsidy') + rate('extras.rebate')
    : rate('extras.net');

  const originalExtrasAmount = extrasAmount + rate('totals.subsidy');

  return (
    <>
      <Flex
        ml="-20px"
        css={{
          width: 'calc(100% + 40px)',
        }}
      >
        <Box width={1 / 2}>
          <StyledInclusionToggle
            isActive={activeIndex === 0}
            onClick={() => onInclusionToggle(0)}
            {...theme}
          >
            Hospital
          </StyledInclusionToggle>
        </Box>
        <Box width={1 / 2}>
          <StyledInclusionToggle
            isActive={activeIndex === 1}
            onClick={() => onInclusionToggle(1)}
            {...theme}
          >
            Extras
          </StyledInclusionToggle>
        </Box>
      </Flex>
      <Row
        justifyContent={s2iMembership ? 'center' : 'space-between'}
        mx="-5px"
      >
        {!s2iMembership && (
          <Box width={{ xs: 1, sm: 'calc(50% - 10px)' }}>
            <InclusionTab {...theme} isActive={activeIndex === 0}>
              {!showHospitalPromoCard ? (
                <>
                  <InclusionsList
                    type="hospital"
                    product={hospitalProduct}
                    inclusions={hospital}
                    coverage={plan('hospitalProduct.coverage')}
                    isPartiallySubsidised={isPartiallySubsidised}
                  >
                    <Eyebrow
                      mb="16px"
                      textAlign="center"
                      className="hide-mobile"
                    >
                      Hospital
                    </Eyebrow>
                    <H3
                      mb="8px"
                      variant="medium"
                      color="hospital"
                      textAlign="center"
                    >
                      <strong>{plan('hospitalProduct.title')}</strong>{' '}
                      {plan('hospitalProduct.coverage')}
                    </H3>
                    {!isPartiallySubsidised && (
                      <>
                        <ProductCardPrice
                          starred={false}
                          amount={hospitalAmount}
                          paymentFrequency={paymentFrequencyLabel}
                        />

                        {excessLoading ? (
                          <Loading size="small" minHeight={0} />
                        ) : (
                          <WithExcess
                            mb="16px"
                            disableChangeCoverLink={!isChangeCoverAllowed}
                            excess={excess}
                          />
                        )}
                      </>
                    )}
                    <P3
                      mt="4px"
                      mb="16px"
                      textAlign="center"
                      variant="medium"
                      color="neutral500"
                    >
                      {plan('hospitalProduct.description')}
                    </P3>

                    <P3
                      textAlign="center"
                      mb="8px"
                      variant="semiBold"
                      color="neutral500"
                    >
                      Gap coverage
                    </P3>
                    {excessLoading ? (
                      <Loading size="small" minHeight={0} />
                    ) : (
                      <Flex alignItems="center" justifyContent="center">
                        <Icon
                          mr="10px"
                          fill={theme.colors.tick}
                          name="green-tick"
                          width="10x"
                          height="10px"
                        />
                        <P3 mr="8px" variant="medium" color="neutral500">
                          {hospitalProduct?.gapCoverage?.title}
                        </P3>
                        <InfoIcon
                          name="info-new"
                          tooltip={
                            <SanityRichTextContent
                              css={css`
                                p {
                                  color: ${theme.colors.white};
                                }
                              `}
                              content={hospitalProduct?.gapCoverage?.tooltip}
                            />
                          }
                        />
                      </Flex>
                    )}
                  </InclusionsList>
                  {/*<FactsheetLink*/}
                  {/*  color={theme.colors.hospital}*/}
                  {/*  productId={plan('hospitalProduct.id')}*/}
                  {/*  title={`${plan('hospitalProduct.title')} ${plan(*/}
                  {/*    'hospitalProduct.coverage'*/}
                  {/*  )}`}*/}
                  {/*  url={plan('hospitalProduct.factsheet')}*/}
                  {/*/>*/}
                </>
              ) : (
                <ProductPromoCard
                  type="hospital"
                  eyebrow="hospital"
                  title="No Hospital"
                  description="Currently you are not covered for Hospital"
                  instruction="Add Hospital Cover"
                  loading={excessLoading}
                  coverStatus={coverStatus}
                  paymentFrequency={paymentFrequencyLabel}
                  product={promoHospitalProduct}
                />
              )}
            </InclusionTab>
          </Box>
        )}
        <Box width={{ xs: 1, sm: 'calc(50% - 10px)' }}>
          <InclusionTab {...theme} isActive={activeIndex === 1}>
            {!showExtrasPromoCard ? (
              <>
                <InclusionsList
                  type="extras"
                  product={extrasProduct}
                  inclusions={extras}
                  coverage={plan('extrasProduct.coverage')}
                  isPartiallySubsidised={isPartiallySubsidised}
                >
                  <Eyebrow className="hide-mobile" textAlign="center">
                    Extras
                  </Eyebrow>
                  <H3
                    mb="8px"
                    variant="medium"
                    color="hospital"
                    textAlign="center"
                  >
                    <strong>{plan('extrasProduct.title')}</strong>{' '}
                    {plan('extrasProduct.coverage')}
                  </H3>

                  {!isPartiallySubsidised ? (
                    <>
                      <ProductCardPrice
                        amount={extrasAmount}
                        originalAmount={originalExtrasAmount}
                        paymentFrequency={paymentFrequencyLabel}
                        starred={hasActiveWaitingPeriods}
                        isFree={isFullySubsidised}
                      />
                    </>
                  ) : (
                    <CenterBox mt={4}>
                      <StandardText color={theme.colors.primary}>
                        Partially subsidised
                      </StandardText>
                    </CenterBox>
                  )}
                  <P3
                    mt="37px"
                    textAlign="center"
                    variant="medium"
                    color="neutral500"
                  >
                    {plan('extrasProduct.description')}
                  </P3>
                </InclusionsList>
                {/*<FactsheetLink*/}
                {/*  color={theme.colors.extras}*/}
                {/*  productId={plan('extrasProduct.id')}*/}
                {/*  title={plan('extrasProduct.title')}*/}
                {/*/>*/}
              </>
            ) : (
              <ProductPromoCard
                type="extras"
                eyebrow="extras"
                instruction={
                  noExtras ? 'Add Extras Cover' : 'View extras cover'
                }
                title="No Extras"
                description="Currently you are not covered for Extras"
                loading={excessLoading}
                coverStatus={coverStatus}
                paymentFrequency={paymentFrequencyLabel}
                product={promoExtrasProduct}
              />
            )}
          </InclusionTab>
        </Box>
      </Row>
    </>
  );
};

export default withTheme(MyInclusions);
