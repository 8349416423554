import React from 'react';
import { SubFormPanel } from '../Panel';
import YouthDiscountControl from './YouthDiscountControl';
import { StandardText } from '~common/atoms/typography';
import {
  FancyCheckboxControl,
  NumberInputControl,
} from '~common/molecules/form-controls';
import DateOfBirth from '~common/molecules/DateOfBirth';
import defaultYesNoOptions from '../defaultYesNoOptions';
import AdditionalInfo from '~common/atoms/AdditionalInfo';
import Link from '~common/atoms/Link';

const DobField = ({
  Field,
  youthDiscountPercentage,
  isTransferringFromAnotherFund,
  showLhcFlow,
  handleChange,
  values,
  lhcMessage,
  label,
}) => {
  return (
    <>
      <DateOfBirth
        Field={Field}
        dayField={{
          name: 'dobDay',
          onChange: handleChange,
        }}
        monthField={{
          name: 'dobMonth',
          onChange: handleChange,
        }}
        yearField={{
          name: 'dobYear',
          onChange: handleChange,
        }}
      />
      {youthDiscountPercentage ? (
        <SubFormPanel>
          <Field
            bottomSpacing={false}
            value={youthDiscountPercentage}
            isTransferringFromAnotherFund={isTransferringFromAnotherFund}
            name="youthDiscountPercentage"
            label="You qualify for a youth discount of:"
            component={YouthDiscountControl}
          />
        </SubFormPanel>
      ) : null}

      {showLhcFlow && (
        <SubFormPanel>
          <StandardText mb={10} fontSize={2} bold>
            Lifetime Health Cover (LHC)
          </StandardText>
          <StandardText fontSize={2} mb={27}>
            A loading applies to hospital cover if you did not have a policy
            before 1 July following your 31st birthday or if you have not held
            hospital cover continuously for the past 10 years. More information
            about this loading and how it might apply to you can be{' '}
            <Link external to="/tax-and-rebates">
              found on our website
            </Link>
          </StandardText>
          <Field
            label={label}
            onChange={handleChange}
            name="lhcStatement"
            type="secondary"
            component={FancyCheckboxControl}
            options={defaultYesNoOptions}
          />

          {values.lhcStatement === false && (
            <Field
              focus={!values.firstHospitalCoverAge}
              name="firstHospitalCoverAge"
              label="Approximately how old were you when you first took out hospital cover?"
              onChange={handleChange}
              component={NumberInputControl}
              maxDigits={3}
              realNumber
            />
          )}

          {lhcMessage ? (
            <AdditionalInfo mb={2}>{lhcMessage}</AdditionalInfo>
          ) : null}
        </SubFormPanel>
      )}
      {!showLhcFlow && lhcMessage && (
        <AdditionalInfo mb={2}>{lhcMessage}</AdditionalInfo>
      )}
    </>
  );
};

export default DobField;
