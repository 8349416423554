import React from 'react';
import Layout from 'components/Layout';
import RequestSentWrapper from '~OMS/SSO/organisms/RequestSentWrapper';
import { withTheme } from 'emotion-theming';

const RequestSent = props => {
  return (
    <Layout>
      <RequestSentWrapper />
    </Layout>
  );
};

export default withTheme(RequestSent);
