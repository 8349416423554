import React from 'react';
import Control from './Control';
import ButtonSwitch from '../ButtonSwitch';
import LabeledButtonSwitch from '../LabeledButtonSwitch';
import styled from '@emotion/styled';

const StyledButtonSwitch = styled(ButtonSwitch)`
  border-width: 1px;
  border-color: ${props => props.theme.colors.borders};
`;

function ButtonSwitchControl(props) {
  return (
    <Control {...props}>
      {({ field, ...controlProps }) =>
        props.labelText ? (
          <LabeledButtonSwitch {...field} {...controlProps} />
        ) : (
          <StyledButtonSwitch {...field} {...controlProps} />
        )
      }
    </Control>
  );
}

export default ButtonSwitchControl;
