import { Box } from 'rebass';
import { StandardText } from '../../../../common/atoms/typography';
import { FormConfirmWrapper } from '~OMS/dashboard/atoms/FormConfirmation';
import { Link } from 'gatsby';
import React from 'react';
import { Button } from '~common/atoms/Button';

const ExtrasInfo = ({ text, ...props }) => (
  <Box {...props}>
    <StandardText
      fontWeight={600}
      fontSize={3}
      mb={{
        xs: 3,
        md: 5,
      }}
    >
      Your Extras Usage
    </StandardText>
    <StandardText mb={4} color="light">
      {text}
    </StandardText>
    <FormConfirmWrapper>
      <Button
        size="medium"
        variant="secondary"
        width={{
          xs: '100%',
          tabletP: 'max-content',
        }}
        as={Link}
        to="/oms/extras-usage"
      >
        View usage & limits
      </Button>
    </FormConfirmWrapper>
  </Box>
);

export default ExtrasInfo;
