import React from 'react';
import { Footer, Header, Title } from '../../components/PageBlocks';
import useContactDetails from '~lib/hooks/sanity/useContactDetails';
import { useThemeUI } from 'theme-ui';
import WorkingTimeBlock from './WorkingTimeBlock';
import ReachToUsBlock from './ReachToUsBlock';
import EmailUsFormBlock from './EmailUsBlock';
import CmsPage from '../../components/Elements/CmsPage';

const Index = ({ pageContext }) => {
  const contactDetails = useContactDetails();
  const { theme } = useThemeUI();
  return (
    <CmsPage pageContext={pageContext}>
      <Header
        metaData={{
          page: {
            _id: 'contact-us',
          },
        }}
        data={pageContext.header}
      />
      <Title
        data={{
          text: 'Contact Us',
        }}
        mb={14}
      />
      <WorkingTimeBlock theme={theme} />
      <ReachToUsBlock contactDetails={contactDetails} theme={theme} />
      <EmailUsFormBlock theme={theme} />
      <Footer data={pageContext.footer} />
    </CmsPage>
  );
};

export default Index;
