import React from 'react';
import { withTheme } from 'emotion-theming';
import isEmpty from 'lodash/isEmpty';

import Link from '~common/atoms/Link';
import FormTitle from '~OMS/styles/FormTitle';
import SSOFormContainer from '~OMS/styles/SSOFormContainer';
import SSOTwoColContainer from '~OMS/styles/SSOTwoColContainer';
import ProgressStepper from '~OMS/SSO/atoms/ProgressStepper';
import SSOText from '~OMS/SSO/atoms/SSOText';
import VerificationStep1Form from '~OMS/SSO/molecules/VerificationStep1Form';
import VerificationStep2Form from '~OMS/SSO/molecules/VerificationStep2Form';

const VerificationWrapper = ({ theme, step, location }) => {
  // if there is no user data (username, auth) passed via state (likley through direct entry), show message
  const hasUserData = isEmpty(location.state);

  const firstStepContent = (
    <>
      <ProgressStepper current={step} total={2} />
      <FormTitle>Verify Yourself</FormTitle>
      <SSOText>
        We have updated the way our members access their account. You can no
        longer use your membership number to log in and must create a new
        individual ID.
      </SSOText>
      <SSOText>
        Please start by verifying your details as they appear on your policy.
      </SSOText>
    </>
  );

  const secondStepContent = (
    <>
      <ProgressStepper current={step} total={2} />
      <FormTitle>Create new login</FormTitle>
      <SSOText>
        Thanks. Now, create a password and check the details you will use to log
        in.
      </SSOText>
    </>
  );

  const renderContainer = step => {
    return step === 1 ? (
      <SSOTwoColContainer
        leftContent={firstStepContent}
        rightContent={
          <VerificationStep1Form
            user={location.state.auth || location.state.user}
          />
        }
      />
    ) : (
      <SSOTwoColContainer
        leftContent={secondStepContent}
        rightContent={
          <VerificationStep2Form
            auth={location.state.auth || location.state.user}
          />
        }
      />
    );
  };

  return !hasUserData ? (
    renderContainer(step)
  ) : (
    <SSOFormContainer>
      <SSOText>You don't have permission to view this page.</SSOText>
      <Link to={'/'}>Home</Link>
    </SSOFormContainer>
  );
};

export default withTheme(VerificationWrapper);
