import React, { forwardRef, useEffect } from 'react';
import { Button } from '~common/atoms/Button';
import { Flex } from 'rebass';
import { CreateControl } from '~common/molecules/form-controls/Control';
import styled from '@emotion/styled';
import usePaymentChecksContext from '~OMS/payments/hooks/usePaymentChecksContext';

const checkPaymentFactory = ({
  InputComponent,
  fromPaymentCheck,
  checkButtonText,
  renderInfoAlert,
  buttonDataId,
}) => {
  const StyledInput = styled(InputComponent)`
    width: 100%;
    margin-right: 0;

    ${props => props.theme.mq.md} {
      margin-right: 8px;
      width: 55%;
    }
  `;

  const StyledButton = styled(Button)`
    width: 100%;
    ${props => props.theme.mq.md} {
      min-width: 45%;
    }
  `;

  const CheckerControl = CreateControl(
    forwardRef(({ value, ...props }, ref) => {
      const paymentChecks = usePaymentChecksContext();
      const { state, actions } = fromPaymentCheck(paymentChecks, value);
      const { data, loading } = state;

      const check = () => {
        actions.check(value);
      };

      useEffect(() => {
        actions.clear();

        //eslint-disable-next-line
    }, [value]);

      return (
        <>
          <Flex
            alignItem="center"
            flexDirection={{
              xs: 'column',
              md: 'row',
            }}
          >
            <StyledInput ref={ref} value={value} {...props} />
            <StyledButton
              data-id={buttonDataId}
              mt={{
                xs: 2,
                md: 0,
              }}
              uppercase={false}
              disabled={!value}
              type="button"
              loading={loading}
              showOnlyLoaderWhenActive
              onClick={check}
              variant="primaryInverted"
            >
              {checkButtonText}
            </StyledButton>
          </Flex>
          <div data-id="check-payment-alert">
            {data &&
              !loading &&
              data &&
              value &&
              renderInfoAlert({
                checkData: data,
                inputValue: value,
                isMaximumPeriodExceeded: paymentChecks.isMaximumPeriodExceeded,
                isBellowMinimumAmount: paymentChecks.isBellowMinimumAmount,
              })}
          </div>
        </>
      );
    })
  );

  return styled(CheckerControl)`
    .control-wrapper {
      align-items: baseline;
    }
  `;
};

export default checkPaymentFactory;
