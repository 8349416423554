import React from 'react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';
import ButtonSwitch from '~common/molecules/ButtonSwitch';

import useRatesToggle, { useRatesToggleDate } from '~lib/hooks/useRatesToggle';

const StyledButtonSwitch = styled(ButtonSwitch)`
  button {
    padding: 12px 20px;
    width: max-content;

    ${props => props.theme.mq.sm} {
      min-width: 150px;
    }
  }

  &&& {
    border-color: ${props => props.theme.colors.primary};

    .option.inactive {
      color: ${props => props.theme.colors.primary};
    }
  }
`;

const RatesToggle = ({
  selected = 'CURRENT',
  onBefore,
  onAfter,
  quoteForm,
  ...props
}) => {
  const ratesToggle = useRatesToggle(quoteForm);
  const ratesChangeDate = useRatesToggleDate();

  const buttons = [
    {
      id: 'CURRENT',
      text: `Before ${ratesChangeDate?.format('MMM Do YYYY')}`,
    },
    {
      id: 'FUTURE',
      text: `After ${ratesChangeDate?.format('MMM Do YYYY')}`,
    },
  ];
  return ratesToggle ? (
    <Flex flexDirection="row" justifyContent="flex-start" alignItems="center">
      <StyledButtonSwitch
        {...props}
        variant="blue"
        value={selected}
        buttons={buttons}
        onChange={option => {
          if (option === 'CURRENT') {
            onBefore();
          } else {
            onAfter();
          }
        }}
      />
      {/* <InfoIcon
        tooltip={
          <Text>
            Premium increases were deferred for our Victorian members from 1
            April 2020 to 1 January 2021, as part of several support measures we
            implemented during COVID-19.
          </Text>
        }
        position="bottom"
      /> */}
    </Flex>
  ) : null;
};

export default RatesToggle;
