import moment from 'moment';

export const GetFieldName =
  inputName =>
  (section = 'Day') =>
    `${inputName}${section}`;

export const GetField =
  inputName =>
  (object, type = 'Day') =>
    object?.[GetFieldName(inputName)(type)];

export const getDob = (values, inputName) => {
  const getField = GetField(inputName);
  const day = getField(values, 'Day');
  const month = getField(values, 'Month');
  const year = getField(values, 'Year');
  return moment(new Date(year, month - 1, day));
};
