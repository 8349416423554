import { Flex } from 'rebass';
import css from '@emotion/css';
import { P2 } from '~common/atoms/typography';
import { useThemeUI } from 'theme-ui';
import React from 'react';

const ProductTabs = ({
  onSelect,
  Typography = P2,
  selected,
  tabs = [
    {
      id: 'hospital',
      title: 'Hospital',
    },
    {
      id: 'extras',
      title: 'Extras',
    },
  ],
  ...props
}) => {
  const { theme } = useThemeUI();
  return (
    <Flex
      css={css`
        border-bottom: 1px solid ${theme.colors.neutral200};
      `}
      {...props}
    >
      {tabs.map(tab => (
        <Flex
          width={1 / 2}
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            onSelect(tab.id);
          }}
          css={css`
            padding: 16px 10px;
            cursor: pointer;
            border-bottom: 5px solid
              ${selected === tab.id ? theme.colors.primary : 'transparent'};
          `}
        >
          <Typography
            color={tab.id === selected ? 'primary' : 'neutral500'}
            variant="semiBold"
          >
            {tab.title}
          </Typography>
        </Flex>
      ))}
    </Flex>
  );
};

export default ProductTabs;
