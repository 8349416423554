import React from 'react';
import { FancyCheckboxControl } from '~common/molecules/form-controls';
import QuestControl from '../../common/components/QuestControl';
import StepForm from '../../common/components/StepForm';
import CallUsNumber from '~common/molecules/CallUsNumber';
import FormField from '~common/molecules/FormField';
import RamsaySpecificFields from './RamsaySpecificFields';
import { useLocale } from '~common/locales';
import { Button } from '~common/atoms/Button';
import Link from '~common/atoms/Link';

const Eligibility = ({ context, appFormContext }) => {
  const { getLocaleString } = useLocale();

  const organisationName = getLocaleString('common.organisationName');
  const requestCallbackUrl = getLocaleString('common.requestCallbackUrl');

  const avantProfession = [
    {
      value: 'avant',
      label: 'Avant employee',
      subLabel: (
        <>
          To ensure that you receive the full benefits of being an Avant
          Employee please contact the Avant Health Division, {organisationName}{' '}
          on <CallUsNumber color="primary" inline />.
        </>
      ),
    },
  ];

  const existingPolicyHolderQuestions = [
    {
      value: true,
      label: 'Yes',
      additionalInfo: (
        <>
          This form is for new members joining Doctors’ Health Fund. If you
          would like to make a change to your policy, please{' '}
          <Link to="/contact-us" style={{ fontWeight: 700 }}>
            contact our Member Services team
          </Link>
          .
        </>
      ),
    },
    {
      value: false,
      label: 'No',
    },
  ];

  const citizenshipQuestions = [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
      additionalInfo: (
        <>
          We are sorry, but you are not eligible at this time to become a
          member. Doctor’s Health Fund requires that you are an Australian
          citizen or permanent resident to join.
        </>
      ),
    },
  ];

  const existingAvantMemberQuestions = [
    {
      value: true,
      label: 'Yes',
      additionalInfo: (
        <>
          As an Avant member, you may already have or be eligible to receive our
          Subsidised Lite Extras Cover. Please exit this application form and
          call <CallUsNumber color="primary" inline /> to speak to one of our
          sales agents for more information.
        </>
      ),
    },
    {
      value: false,
      label: 'No',
    },
  ];
  return (
    <StepForm
      appFormContext={appFormContext}
      continueDisabled={formProps => !formProps.isValid}
      showSaveButton={false}
      title="First, let’s check you're eligible to join"
      form={context.form}
      hideContinueButton={({ values }) =>
        !values.australianCitizen ||
        values.existingAvantMember ||
        values.existingPolicyHolder
      }
    >
      {({ values, Field, handleChange }) => {
        return (
          <>
            <Field
              onChange={handleChange}
              name="existingPolicyHolder"
              label="Are you a current member of Doctors' Health Fund?"
              options={existingPolicyHolderQuestions}
              component={FancyCheckboxControl}
            />
            {values.existingPolicyHolder === false && (
              <Field
                onChange={handleChange}
                name="australianCitizen"
                label="Are you an Australian citizen or permanent resident?"
                options={citizenshipQuestions}
                component={FancyCheckboxControl}
              />
            )}
            {context.showRamsayQuestions &&
              values.australianCitizen &&
              values.existingPolicyHolder === false && (
                <RamsaySpecificFields
                  values={values}
                  Field={Field}
                  handleChange={handleChange}
                />
              )}
            {values.australianCitizen === true &&
              values.existingPolicyHolder === false &&
              (context.showRamsayQuestions
                ? values.ramsayEmployee && values.consent
                : true) && (
                <FormField
                  onChange={handleChange}
                  label="To be eligible to join Doctors’ Health Fund, you must be associated with one of the following groups. Please select the option that best describes you."
                  name="profession"
                  orOptions={context.showRamsayQuestions ? [] : avantProfession}
                  options={context.membershipOptions}
                  component={QuestControl}
                />
              )}

            {(values.profession?.optionValue === 'Intern' ||
              values.profession?.optionValue === 'Resident') &&
              values.australianCitizen &&
              values.existingPolicyHolder === false && (
                <Field
                  onChange={handleChange}
                  name="existingAvantMember"
                  label="Are you an existing Avant member?"
                  options={existingAvantMemberQuestions}
                  component={FancyCheckboxControl}
                />
              )}
            {(values.australianCitizen === false ||
              values.existingAvantMember) &&
              values.existingPolicyHolder !== true && (
                <Button
                  size="large"
                  variant="secondary"
                  mt="8px"
                  as={Link}
                  external
                  to={requestCallbackUrl}
                >
                  Request a callback
                </Button>
              )}
          </>
        );
      }}
    </StepForm>
  );
};

export default Eligibility;
