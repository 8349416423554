import React from 'react';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';

import { Box, Flex } from 'rebass';
import { ModalErrorBoundary } from './ModalErrorBoundary';
import Menu from '~common/organisms/Menu';

const StyledWrapper = styled(Flex)`
  padding-top: 30px;
  max-width: ${props => props.theme.layout.max}px;
  width: 100%;
  margin: 0 auto;
`;

const StyledOuter = styled.div`
  width: 100%;
  padding-right: ${props => props.theme.padding.xs.paddingRight}px;
  padding-left: ${props => props.theme.padding.xs.paddingLeft}px;
  ${props => props.theme.mq.md} {
    width: calc(100% - 130px);
    padding-right: ${props => props.theme.padding.paddingRight}px;
    padding-left: ${props => props.theme.padding.paddingLeft}px;
  }
`;

const Wrapper = ({ theme, minHeight, children, loading, ...props }) => {
  return (
    <ModalErrorBoundary>
      <StyledWrapper theme={theme}>
        <Menu />

        <StyledOuter theme={theme}>
          <Box
            data-id="page-wrapper"
            bg="white"
            css={{
              boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.1)',
              minHeight,
            }}
            pt={{ xs: 30 }}
            pb={30}
            pl={{ xs: 20, md: 30, lg: 60 }}
            pr={{ xs: 20, md: 30, lg: 60 }}
            {...props}
          >
            {children}
          </Box>
        </StyledOuter>
      </StyledWrapper>
    </ModalErrorBoundary>
  );
};

export default withTheme(Wrapper);
