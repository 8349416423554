import {
  landlineMobile,
  mobile,
  membershipNumber,
  bsb as BsbValidator,
} from '~common/molecules/form-controls/validators';
import * as Yup from 'yup';
import { waterfall } from '~lib/util';

//TODO: it is not used currently nowhere, it has potential but has to be tested more
export const cacheValidator = (validator, getCacheKey = x => x) => {
  const cacheResult = {};
  const cacheError = {};
  return (...args) => {
    const cacheKey = getCacheKey(...args);
    if (cacheResult[cacheKey]) {
      return cacheResult[cacheKey];
    }

    if (cacheError[cacheKey]) {
      throw cacheError[cacheKey];
    }
    try {
      const validationResult = validator(...args);
      cacheResult[cacheKey] = validationResult;
      return validationResult;
    } catch (error) {
      cacheError[cacheKey] = error;
      throw error;
    }
  };
};

export const required = message => value => {
  if (typeof value === 'string') {
    value = value.trim();
  }
  if (!value) {
    throw [message];
  }
};

export const asciiOnly = message => value => {
  if (typeof value === 'string') {
    value = value.trim();

    const regex = /([^\x00-\x7F]+)/g;

    if (value.match(regex)) {
      throw [message];
    }
  }
};

export const defined =
  (message = 'Should be defined') =>
  value => {
    if (typeof value === 'string') {
      value = value.trim();
    }
    if (value == null || value === '') {
      throw [message];
    }
  };

export const maxLength =
  (length, message) =>
  (value = '') => {
    if (String(value).length > length) {
      throw [message];
    }
  };

export const length =
  (l, message) =>
  (value = '') => {
    if (String(value).length !== l) {
      throw [message];
    }
  };

const landlineMobileValidator = landlineMobile('Invalid phone number');
const mobileValidator = mobile('Invalid phone number');

export const mobileNumber =
  (message = '') =>
  value => {
    try {
      mobileValidator.validateSync(value);
    } catch (err) {
      throw [message];
    }
  };

export const phoneNumber =
  (message = 'Invalid phone number') =>
  value => {
    try {
      landlineMobileValidator.validateSync(value);
    } catch (err) {
      throw [message];
    }
  };

const emailValidator = Yup.string().email('Please enter a valid Email Address');

export const email =
  (message = 'Invalid email') =>
  value => {
    try {
      emailValidator.validateSync(value);
    } catch (error) {
      throw [message];
    }
  };

const membershipNumberValidator = membershipNumber('membershipNumber');

export const membership = () => value => {
  membershipNumberValidator.validateSync(value);
};

const bsbValidator = BsbValidator();

export const bsb = () => value => {
  return bsbValidator.validateSync(value);
};

export const address = fieldName => [
  required(`${fieldName} is required`),
  value => {
    if (!value.errorCode) {
      return;
    }
    switch (value.errorCode) {
      case '9': {
        throw 'Address is not specific enough. The unit number is probably missing.';
      }
      default: {
        throw value.errorDescription || `${fieldName} is invalid`;
      }
    }
  },
  value => {
    if (
      !value ||
      !value.line1 ||
      !value.state ||
      !value.suburb ||
      !value.postcode
    ) {
      throw `${fieldName} is invalid`;
    }
  },
];

const handleTransformValidation = (
  transformValidation,
  validationArray,
  form
) => {
  if (typeof transformValidation === 'function') {
    return transformValidation(validationArray, form);
  }

  if (typeof transformValidation === 'object') {
    return validationArray.filter(([field]) => {
      return transformValidation[field]
        ? transformValidation[field](form)
        : true;
    });
  }

  return validationArray;
};

export const FormValidator =
  ({ validation, transformValidation }) =>
  form => {
    const validationArray = Object.entries(
      typeof validation === 'function' ? validation(form) : validation
    );

    const transformedValidation = handleTransformValidation(
      transformValidation,
      validationArray,
      form
    );

    return transformedValidation.reduce((errors, [field, validators]) => {
      try {
        validators = Array.isArray(validators) ? validators : [validators];
        validators.forEach(validator => {
          validator(form[field], field, form);
        });
      } catch (error) {
        errors[field] =
          error.errors || (Array.isArray(error) ? error : [error]);
      }
      return errors;
    }, {});
  };

const asyncValidateField = (form, field, validators) => {
  validators = Array.isArray(validators) ? validators : [validators];
  return waterfall(
    validators.map(validator => errors => {
      // if previous validator threw errors, skip the rest of validators and propagate errors
      if (errors) {
        return errors;
      }

      errors = {};
      try {
        const validateResult = validator(form[field], field, form);
        if (validateResult instanceof Promise) {
          return validateResult.catch(error => {
            errors[field] =
              error.errors || (Array.isArray(error) ? error : [error]);
            return errors;
          });
        }
      } catch (error) {
        errors[field] =
          error.errors || (Array.isArray(error) ? error : [error]);
        return errors;
      }

      return undefined;
    }),
    {
      agregateResult: true,
    }
  );
};

export const AsyncFormValidator =
  ({ validation, transformValidation }) =>
  form => {
    const validationArray = Object.entries(
      typeof validation === 'function' ? validation(form) : validation
    );

    const transformedValidation = handleTransformValidation(
      transformValidation,
      validationArray,
      form
    );

    let allErrors;

    return Promise.all(
      transformedValidation.map(([field, validators]) => {
        return asyncValidateField(form, field, validators).then(errors => {
          allErrors = {
            ...allErrors,
            ...errors,
          };
        });
      })
    ).then(() => {
      throw allErrors;
    });
  };
