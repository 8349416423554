import React from 'react';
import { Flex, Box } from 'rebass';

import Navbar from './Navbar';
import { Footer } from '../../PageBlocks';
import { useThemeUI } from 'theme-ui';
import { quoteBlockSpacing } from './QuoteBlock';
import Stepper from '~common/organisms/Stepper';
import useQuoteForm from './useQuoteForm';
import { StandardText } from '~common/atoms/typography';
import { useMobileScreenOnly } from '~lib/responsive';
import PromoBanner from './PromoBanner';
import { css } from '@emotion/core';

const QuoteForm = ({ form, onChange, submit, footer }) => {
  const { theme } = useThemeUI();

  const { actions, state } = useQuoteForm({
    form,
    onChange,
    onSubmit: submit,
  });

  const isMobile = useMobileScreenOnly();
  const ActiveStep = state.activeStep?.component;

  return (
    !!ActiveStep && (
      <>
        <Navbar mb="4px" />
        <Flex
          pt={{
            xs: 67,
            tabletP: 83,
          }}
        >
          {!isMobile && (
            <Box
              pt={168}
              pl={quoteBlockSpacing.pl}
              pr={{
                xs: quoteBlockSpacing.pr,
                tabletP: 44,
              }}
              mb={285}
              width="40%"
              bg={theme.colors.background.white}
            >
              <StandardText
                maxWidth={281}
                mb={23}
                color="neutral600"
                fontSize={20}
              >
                Find the right cover for you in{' '}
                <strong>less than 30 seconds</strong>
              </StandardText>
              <Stepper
                activeStepIndex={state.activeStepIndex}
                onStepClick={actions.selectStep}
                steps={state.steps}
              />
              <PromoBanner mt={42} promotions={state.promotions} />
            </Box>
          )}
          <Box
            pl={{
              xs: quoteBlockSpacing.pl.xs,
              tabletP: 80,
              tabletL: 100,
              lg: 162,
            }}
            pr={quoteBlockSpacing.pr}
            width={{
              xs: 1,
              tabletP: '60%',
            }}
            bg={theme.colors.background.lightNeutral50}
            height="auto"
            minHeight={{
              xs: 'calc(100vh - 64px)',
              tabletP: 'calc(100vh - 80px)',
            }}
            css={css`
              position: relative;
            `}
          >
            {isMobile && (
              <>
                <Stepper
                  mt={24}
                  activeStepIndex={state.activeStepIndex}
                  onStepClick={actions.selectStep}
                  steps={state.steps}
                />
                <PromoBanner mt={24} promotions={state.promotions} />
              </>
            )}
            <ActiveStep
              {...state}
              onNext={actions.confirmStep}
              onBack={actions.goToPreviousStep}
              onChange={actions.handleChange}
              form={form}
            />
          </Box>
        </Flex>
        <Footer data={footer} />
      </>
    )
  );
};

export default QuoteForm;
