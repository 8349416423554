import React from 'react';
import { Flex } from 'rebass';
import styled from '@emotion/styled';
import { Row } from '~OMS/claims/atoms/ClaimsLayout';
import { Button } from '~common/atoms/Button';
import { StandardText } from '../../../../common/atoms/typography';
import ProgressBar from '~OMS/claims/atoms/ProgressBar';
import { states } from '~OMS/claims/hooks/useClaimUploader';
import { withTheme } from 'emotion-theming';
import CheckBox from '~common/atoms/CheckBox';

const SubmitClaimConfirmationContainer = styled(Flex)`
  min-height: 88px;
  background: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.white};
`;

const StyledCheckbox = styled(CheckBox)`
  display: flex;
  &&& {
    svg:first-of-type {
      color: ${props => props.theme.colors.white};
    }
  }
`;

const TermsConditions = styled.a`
  color: ${props => props.theme.colors.white};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.white};
  }
`;

const initialView = ({
  onSubmit,
  theme,
  disableSubmit = false,
  progress,
  onTermsAndConditions,
  termsAccepted,
  onTermsToggle,
}) => (
  <Row
    flexDirection={{
      xs: 'column',
      md: 'row',
    }}
    alignItems="center"
    justifyContent="space-between"
  >
    <Flex
      alignItems="center"
      mb={{
        xs: 2,
        md: 0,
      }}
    >
      <StyledCheckbox value={termsAccepted} onChange={() => onTermsToggle()} />
      <StandardText color="white">
        I agree to the{' '}
        <strong>
          <TermsConditions onClick={onTermsAndConditions} theme={theme}>
            terms & conditions
          </TermsConditions>
        </strong>
      </StandardText>
    </Flex>
    <Button
      type="button"
      onClick={onSubmit}
      disabled={disableSubmit || !termsAccepted}
      width={{
        xs: 1,
        md: 208,
      }}
      variant="primaryInverted"
      loading={progress > 0 && progress < 100}
    >
      Submit claim
    </Button>
  </Row>
);

const successView = ({ onStartNewClaim }) => (
  <Row justifyContent="center">
    <Button
      width={{
        xs: 1,
        md: 208,
      }}
      variant="primaryInverted"
      onClick={onStartNewClaim}
    >
      Start new claim
    </Button>
  </Row>
);

const inProgressView = ({ progress }) => (
  <Row>
    <ProgressBar progress={progress} />
  </Row>
);

const uploadComplete = () => (
  <Row justifyContent="center">
    <StandardText textAlign="center" color="white">
      The batch is uploaded. Please wait while it is being fully processed.
    </StandardText>
  </Row>
);

const SubmitClaimConfirmation = ({ state, css, className, ...props }) => (
  <SubmitClaimConfirmationContainer
    p={3}
    justifyContent="center"
    alignItems="center"
    css={css}
    className={className}
  >
    {state === states.INITIAL ? initialView(props) : null}
    {state === states.IN_PROGRESS ? inProgressView(props) : null}
    {state === states.SUCCESS ? successView(props) : null}
    {state === states.UPLOAD_COMPLETE ? uploadComplete(props) : null}
  </SubmitClaimConfirmationContainer>
);

export default React.memo(withTheme(SubmitClaimConfirmation));
