import React from 'react';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';
import { path } from 'lodash/fp';

import Eyebrow from '~common/atoms/Eyebrow';
import Loading from '~common/atoms/Loading';
import Icon from '~common/atoms/Icon';
import useCardQuickLinks from '~OMS/dashboard/hooks/useCardQuickLinks';
import NourishBabyModal from '~OMS/dashboard/molecules/NourishBabyModal';
import { P2 } from '~common/atoms/typography';
import Link from '~common/atoms/Link';

const StyledQuickLink = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const StyledQuickEntry = styled(P2)`
  text-decoration: unset;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const LinksContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  ${props => props.theme.mq.tabletP} {
    grid-template-columns: 1fr 1fr;
  }
`;

const Quicklink = ({
  theme,
  title,
  url,
  page,
  link_key,
  new_window,
  onClick,
  icon,
}) => {
  const linkContent = (
    <Flex justifyContent="space-between" alignItems="center">
      {title}
      <Icon
        ml="10px"
        clickable
        fill={theme.colors.primary}
        name="chevron-right"
        width="14px"
        height="14px"
      />
    </Flex>
  );
  return (
    <StyledQuickLink data-id={`${link_key}_quick_link`} {...theme}>
      <Flex
        alignItems="flex-start"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex justifyContent="center">
          <Icon
            fill={theme.colors.primary}
            name={icon ?? 'quick-link'}
            height="20px"
            mr="12px"
          />
          {onClick ? (
            <StyledQuickEntry
              noStyles
              variant="medium"
              color={theme.colors.primary}
              onClick={evt => {
                evt.preventDefault();
                onClick();
              }}
            >
              {linkContent}
            </StyledQuickEntry>
          ) : (
            <StyledQuickEntry
              color={theme.colors.primary}
              as={Link}
              noStyles
              variant="medium"
              to={url ? url : `/${path('slug.current')(page)}`}
              external={new_window || url.indexOf('/oms') === -1}
              target={new_window ? '_blank' : null}
            >
              {linkContent}
            </StyledQuickEntry>
          )}
        </Flex>
      </Flex>
    </StyledQuickLink>
  );
};

const CardQuicklinks = ({ theme, location, plan }) => {
  const { state, actions } = useCardQuickLinks(location, plan);
  const {
    nourishBabyLoading,
    links,
    loading,
    nourishBabyModalVisible,
    popupAnimation,
  } = state;
  const { goToNourishBabyPortal, closeNourishBabyModal } = actions;
  return (
    <>
      <NourishBabyModal
        animation={popupAnimation}
        visible={nourishBabyModalVisible}
        onClose={closeNourishBabyModal}
        onConfirm={goToNourishBabyPortal}
        loading={nourishBabyLoading}
      />
      <Eyebrow>Quick Links</Eyebrow>
      {loading ? (
        <Loading />
      ) : (
        <LinksContainer mt={1}>
          {links.map((link, i) => (
            <Quicklink key={link._key} {...link} theme={theme} />
          ))}
        </LinksContainer>
      )}
    </>
  );
};

export default withTheme(CardQuicklinks);
