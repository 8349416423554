import React from 'react';
import { Alert } from '~common/molecules/AlertBox';
import { StandardText } from '../../../../common/atoms/typography';
import { formatDate } from '~lib/dates';
import { currency } from '~lib/filters';

const PaymentAmountInfo = ({ amount, date, children }) => {
  return (
    <Alert mt={2} alignItems="flex-start" flexDirection="column" type="info">
      <StandardText fontSize={2} mb={2}>
        Payment: <strong>{currency(amount)}</strong>
      </StandardText>
      <StandardText fontSize={2}>
        Covers you until: <strong>{formatDate(date)}</strong>
      </StandardText>
      {children && (
        <StandardText fontSize={2} mt={2}>
          {children}
        </StandardText>
      )}
    </Alert>
  );
};

export default PaymentAmountInfo;
