import { graphql, useStaticQuery } from 'gatsby';

const ELIGIBILITY_QUERY = graphql`
  query ApplicationFormAdditionalInformationQuery {
    sanityApplicationFormAdditionalInformation {
      howDidYouHearForUs {
        _key
        id
        title
      }
      nominatePersonRelationship {
        _key
        id
        title
      }
      showAvantBasedQuestions: toggleAvantBasedQuestions
      avantInfoOptions {
        id
        title
        _key
      }
      partnerOrganisations {
        id
        title
        _key
      }
    }
  }
`;

export default () => {
  const result = useStaticQuery(ELIGIBILITY_QUERY);
  return result.sanityApplicationFormAdditionalInformation;
};
