import React from 'react';
import Layout from 'components/Layout';
import VerifyEmailWrapper from '~OMS/SSO/organisms/VerifyEmailWrapper';
import { withTheme } from 'emotion-theming';
import withQueryParams from '~lib/hocs/withQueryParams';

const VerifyEmail = props => {
  return (
    <Layout>
      <VerifyEmailWrapper queryParams={props.queryParams} />
    </Layout>
  );
};

export default withTheme(withQueryParams(VerifyEmail));
