import React from 'react';
import Block from '../../common/atoms/Block';
import { Card } from './index';
import Heading from './Heading';
import { Flex } from 'rebass';
import { usePageContext } from '~lib/providers/PageProvider';
import styled from '@emotion/styled';

const StyledCards = styled(Block)`
  transform: translateY(
    ${props => (props.prevBlock._type === 'newHeroComponent' ? 47 : 0)}px
  );
`;

const Cards = ({ data: { cards, title, style = 'normal' }, prev, next }) => {
  const horizontalCards = ['imageLeft', 'imageRight'].includes(style);
  const { isHomePage } = usePageContext();

  const normalCards = style === 'normal';

  return (
    <StyledCards
      prevBlock={prev}
      redefineMb={
        prev._type === 'newHeroComponent'
          ? { xs: 0, md: 0, tabletP: 0, tabletL: 0, lg: 0, xl: 0 }
          : undefined
      }
      py={
        normalCards && isHomePage
          ? {
              xs: 64,
              lg: 100,
            }
          : undefined
      }
      redefinePr={
        horizontalCards
          ? {
              xs: 0,
            }
          : undefined
      }
      redefinePl={
        horizontalCards
          ? {
              xs: 0,
            }
          : undefined
      }
    >
      {title && (
        <Heading
          fontSize="34px"
          data={{
            text: title,
            level: 'h2',
          }}
        />
      )}
      <Flex
        flexDirection={{
          xs: 'column',
          tabletP: horizontalCards ? 'column' : 'row',
        }}
        justifyContent="space-between"
        pr="4px"
      >
        {cards?.map?.((card, index) => (
          <Card
            index={index}
            cardsCount={cards.length}
            style={style}
            key={card._key}
            data={card}
            mb={
              index !== cards.length - 1
                ? {
                    xs: horizontalCards ? 0 : 30,
                    tabletP: horizontalCards ? 100 : 0,
                  }
                : 0
            }
          />
        ))}
      </Flex>
    </StyledCards>
  );
};

export default Cards;
