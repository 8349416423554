import { PAYMENT_MODES } from '~OMS/payments/constants';
import { AsyncFormValidator } from '~lib/formValidation';
import { required } from '~lib/formValidation';
import { creditCardValidation } from '~OMS/payments/validators';
import { amountTextToNumber } from '~OMS/payments/paymentUtils';
import {
  MINIMUM_ALLOWED_PAYMENT_AMOUNT,
  MAXIMUM_PAYMENT_PERIOD,
} from '~OMS/payments/constants';
import { now } from '~lib/dates';

const getPaymentPeriod = () => {
  const maximumPaymentPeriod = now().add(MAXIMUM_PAYMENT_PERIOD, 'months');
  return maximumPaymentPeriod.diff(now(), 'year');
};

const validateForm = paymentChecks =>
  AsyncFormValidator({
    validation: {
      amount: [
        required('Amount is required'),
        amount => {
          if (amountTextToNumber(amount) < MINIMUM_ALLOWED_PAYMENT_AMOUNT) {
            //eslint-disable-next-line no-throw-literal
            throw `Minimum allowed payment amount is $${MINIMUM_ALLOWED_PAYMENT_AMOUNT}`;
          }
        },
        async amount => {
          const { isMaximumPeriodExceeded } = await paymentChecks.date.actions
            .check(amount)
            .catch(error => {
              console.error(error);
              //eslint-disable-next-line no-throw-literal
              throw (
                'Unexpected error happened when validating amount. ' +
                'Please check the console and report it to our support.'
              );
            });

          if (isMaximumPeriodExceeded) {
            const paymentPeriod = getPaymentPeriod();
            //eslint-disable-next-line no-throw-literal
            throw `Your policy can only be paid up to ${paymentPeriod} year${
              paymentPeriod !== 1 ? 's' : ''
            } in advance. The value you have entered exceeds this. Please enter a new dollar amount.`;
          }
        },
      ],
      date: [
        required('Date is required'),
        date => {
          if (paymentChecks.checkIfMaximumPeriodExceeded(date)) {
            const paymentPeriod = getPaymentPeriod();
            //eslint-disable-next-line no-throw-literal
            throw `Your policy can only be paid up to ${paymentPeriod} year${
              paymentPeriod !== 1 ? 's' : ''
            } in advance. The value you have entered exceeds this. Please enter a new date.`;
          }
        },
      ],
      ...creditCardValidation,
    },
    transformValidation: (validationArray, form) => {
      return validationArray.filter(
        ([field]) =>
          field !==
          (form.paymentMode === PAYMENT_MODES.AMOUNT ? 'date' : 'amount')
      );
    },
  });

export default validateForm;
