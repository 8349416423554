import React from 'react';
import Layout from 'components/Layout';
import VerificationSuccessWrapper from '~OMS/SSO/organisms/VerificationSuccessWrapper';
import { withTheme } from 'emotion-theming';

const VerificationSuccess = props => {
  return (
    <Layout>
      <VerificationSuccessWrapper
        location={props.location}
        navigate={props.navigate}
        renderState="oldEmail"
      />
    </Layout>
  );
};

export default withTheme(VerificationSuccess);
