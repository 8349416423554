import React from 'react';
import { DropDownControl } from '~common/molecules/form-controls';
import { PAYMENT_FREQUENCIES_PER_METHOD } from '~OMS/payments/constants';
import { Option } from '~common/atoms/DropDown';
import FormField from '~common/molecules/FormField';

const PaymentFrequencyField = ({ onChange }) => {
  return (
    <FormField
      name="paymentFrequency"
      label="How often would you like to make payments?"
      render={renderProps => (
        <DropDownControl placeholder="Select" {...renderProps}>
          {PAYMENT_FREQUENCIES_PER_METHOD.directDebit.map(frequency => (
            <Option key={frequency.id} value={frequency.id}>
              {frequency.text}
            </Option>
          ))}
        </DropDownControl>
      )}
      onChange={onChange}
    />
  );
};

export default PaymentFrequencyField;
