import React from 'react';

import CompareInclusionsTable from './CompareInclusionsTable';
import CompareProductsTable from './CompareProductsTable';
import CompareProducts from './CompareProducts';
import useCompareProducts from './useCompareProducts';
import { P3 } from '~common/atoms/typography';

const CompareExtrasCover = props => {
  const { products, loading, rebatePercentage, resultType, actions } =
    useCompareProducts('extras', props.pageContext.products);

  return (
    <CompareProducts
      onResultType={actions.setResultType}
      rebatePercentage={rebatePercentage}
      loading={loading}
      products={products}
      type="extras"
      {...props}
    >
      <CompareProductsTable
        quote={{
          loading,
        }}
        type="extras"
        resultType={resultType}
        products={products}
      />
      <CompareInclusionsTable resultType={resultType} products={products} />
      <P3 variant="medium" color="neutral500" textAlign="center" mt={4}>
        Annual limits are per person per calendar year except where otherwise
        stated.
      </P3>
      <P3 variant="medium" color="neutral500" textAlign="center">
        ~ Checkups limited to an examination, fluoride, scale and clean where
        the fees are within the range of usual, customary and reasonable
        charges.
      </P3>
    </CompareProducts>
  );
};

export default CompareExtrasCover;
