import React, { useState, useEffect, useMemo } from 'react';
import { withTheme } from 'emotion-theming';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Flex } from 'rebass';
import { chainedPath } from '~lib/util';
import { Option, DropDown } from '~common/atoms/DropDown';
import HeaderLine from '~common/atoms/HeaderLine';
import Loading from '~common/atoms/Loading';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import ScrollCarousel from '~common/organisms/ScrollCarousel';
import UsageAccordion from '~OMS/extras-usage/molecules/UsageAccordion';
import FactsheetLink from '~OMS/extras-usage/molecules/FactsheetLink';
import Link from '~common/atoms/Link';
import { ExtrasUsageQuery } from 'queries/oms/extrasUsage';
import { getExtrasProductId, getSortedPersons } from '~OMS/response-selectors';
import styled from '@emotion/styled';
import { Caption, H3 } from '~common/atoms/typography';
import { Button } from '~common/atoms/Button';

const Disclaimer = styled(SanityRichTextContent)`
  p {
    font-size: ${props => props.theme.fontSizes[3]}px;
    color: ${props => props.theme.colors.neutral900};
    font-weight: 500;
  }
`;

const ExtrasUsage = ({ theme }) => {
  //TODO: move the logic to a hook
  const { loading, error, data } = useQuery(ExtrasUsageQuery);
  const [activePerson, setActivePerson] = useState(null);
  const [activeTable, setActiveTable] = useState(null);
  const [changed, setChanged] = useState(null);

  const persons = getSortedPersons(data);
  const extrasProductId = getExtrasProductId(data);
  const product = chainedPath('oms.membership.plan.extrasProduct')(data);

  const handleSelectChange = p => {
    setActivePerson(p);
    setChanged(!changed);
  };

  useEffect(() => {
    if (activePerson || loading || error || !data || !data.oms) {
      return;
    }

    setActivePerson(data.oms.user.personId);
  }, [loading, error, data, activePerson, activeTable]);

  useEffect(() => {
    if (loading || error || !data || !data.oms) {
      return;
    }

    const table = data.oms.extrasUsage?.tables?.find(
      t => t.id === activePerson
    );

    if (table && (!activeTable || activeTable.id !== table.id)) {
      setActiveTable(table);
    }
  }, [activePerson, activeTable, data, error, loading]);

  const hasActiveWaitingPeriod = useMemo(() => {
    return data?.oms?.extrasUsage?.graphs.some(
      item => item?.waitingPeriod?.active
    );
  }, [data]);

  if (error) {
    console.error(error);
    return null;
  }

  return (
    <>
      <HeaderLine title="Extras Usage" />
      <Flex
        flexDirection={{ xs: 'column', lg: 'row' }}
        alignItems={{
          xs: 'center',
          tabletP: 'flex-start',
        }}
        justifyContent="space-between"
      >
        {!loading && data?.oms?.extrasUsage?.disclaimer && (
          <Disclaimer
            maxWidth={{
              xs: '100%',
              tabletP: '671px',
            }}
            mr={{
              xs: 0,
              tabletP: 20,
            }}
            mb="36px"
            content={data?.oms?.extrasUsage?.disclaimer}
          ></Disclaimer>
        )}
        {!loading && (
          <FactsheetLink
            productId={product('id')}
            title={product('title')}
            url={product('factsheet')}
            mb="35px"
          />
        )}
      </Flex>

      {loading || !activePerson || !activeTable ? (
        <Loading pt={30} pb={60} />
      ) : (
        <>
          <ScrollCarousel
            activePerson={activePerson}
            persons={persons}
            graphs={data.oms.extrasUsage.graphs}
            toggleActive={setActivePerson}
            onSelectChange={changed}
          />
          <Flex
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent="space-between"
            pb={35}
            pt={10}
          >
            <Flex alignItems="center" mb={{ xs: 20, sm: 0 }}>
              <H3 variant="semiBold" mr="30px">
                Usage & limits for
              </H3>
              <DropDown
                theme={theme}
                name="person-selector"
                id="person-selector"
                value={activePerson}
                onChange={val => handleSelectChange(val)}
                css={css`
                  width: 200px;
                `}
              >
                {persons.map(person => (
                  <Option key={person.id} value={person.id}>
                    {person.fullName}
                  </Option>
                ))}
              </DropDown>
            </Flex>

            <Button
              size="medium"
              variant="secondary"
              as={Link}
              to="/oms/claims"
              width={{
                xs: 1,
                tabletP: 'auto',
              }}
            >
              Make a claim
            </Button>
          </Flex>
          <div>
            {(activeTable &&
              activeTable.rows.map((row, i) => (
                <UsageAccordion
                  extrasProductId={extrasProductId}
                  key={i}
                  {...row}
                />
              ))) ||
              null}
          </div>
          {hasActiveWaitingPeriod && (
            <Caption mt="24px">*Active waiting period</Caption>
          )}
        </>
      )}
    </>
  );
};

export default withTheme(ExtrasUsage);
