import { scroller } from 'react-scroll';
import { tierInfo } from '~lib/inclusionConstants';

/**
 * @param {string} str - e.g. EXAMPLE_TEXT
 * @returns {string} - e.g. Example Text
 */
export const toTitleCase = str => {
  return str
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/\b(\w)/g, s => s.toUpperCase());
};

/**
 * @param {string} str - e.g. EXAMPLE_TEXT
 * @returns {string} - e.g. example-text
 */
export const toHyphenCase = str => {
  return str.replace(/_/g, ' ').replace(/\s+/g, '-').toLowerCase();
};

/**
 * @param {string} str - e.g. EXAMPLE_TEXT
 * @returns {string} - e.g. example text
 */
export const toWordCase = (str = '') => {
  return str.replace(/_/g, ' ').toLowerCase();
};

/**
 *
 * @param {array} variants - covers can have multiple variants e.g. different excess
 * @param {string} selectedProductCode - currently selected products variant code
 * @returns {{ isSelected: boolean, variant: object }}
 *
 */
export const checkSelectedProduct = (variants, selectedProductCode) => {
  const isSelected = variants.some(
    v => v.onSale && v.code === selectedProductCode
  );
  const variant = isSelected
    ? variants.find(v => v.onSale && v.code === selectedProductCode)
    : variants.filter(v => v.onSale)[0];

  return {
    isSelected,
    variant,
  };
};

/**
 *
 * @param {string} name - element name to scroll to
 * @param {object} options - additional scroller options to override
 *
 */
export const scrollTo = (name, options = {}) => {
  setTimeout(
    () =>
      scroller.scrollTo(name, {
        duration: 1000,
        delay: 0,
        isDynamic: true,
        offset: -250,
        smooth: 'easeInOutQuint',
        ...options,
      }),
    1000
  );
};

/**
 *
 * @param {array} tiers - inclusion tiers
 * @param {function} render - render function
 * @returns inclusion DOM
 *
 */
export const renderFeaturedTiers = tiers => render => {
  const featuredTiers = tiers?.featured;
  if (featuredTiers) {
    const tierNames = [...new Set(featuredTiers.map(t => t.featuredTierTitle))];

    return tierNames.map(tier =>
      render({
        tier,
        nodes: tiers.featured.filter(t => t.featuredTierTitle === tier),
        label: tier,
        color: tierInfo.featured.color,
        textColor: tierInfo.featured.textColor,
      })
    );
  }
  return null;
};
