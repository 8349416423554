import { whereEq } from 'lodash/fp';
import { STEP_STATE } from '~lib/constants';

export default ({
  activeStepProvider,
  activeStep,
  setActiveStep,
  confirmedSteps,
  setConfirmedSteps,
  allSteps,
  steps,
}) => {
  const clearAllSteps = () => {
    allSteps.forEach(step => {
      step.context.form.reset();
    });
  };

  const selectStep = step => {
    if (process.env.ALLOW_STEPPER_JUMP === 'true') {
      setActiveStep(step);
      return;
    }
    const targetIndex = steps.findIndex(
      whereEq({
        value: step.value,
      })
    );

    if (
      !targetIndex ||
      steps.every((step, index) =>
        index < targetIndex
          ? [
              STEP_STATE.COMPLETED_INACTIVE,
              STEP_STATE.COMPLETED_ACTIVE,
            ].includes(step.state)
          : true
      )
    ) {
      setActiveStep(step);
    }
  };

  const findCurrentStepIndex = () =>
    steps.findIndex(step => step.value === activeStep.value);

  const goToNextStep = () => {
    if (!activeStepProvider || activeStepProvider.context.form.isValid) {
      const currentStepIndex = findCurrentStepIndex();
      if (currentStepIndex !== steps.length - 1) {
        setActiveStep(steps[currentStepIndex + 1]);
      }
    }
  };

  const goToPreviousStep = () => {
    const currentStepIndex = findCurrentStepIndex() - 1;
    const nextStepIndex = currentStepIndex >= 0 ? currentStepIndex : 0;
    setActiveStep(steps[nextStepIndex]);
  };

  const confirmStep = () => {
    setConfirmedSteps({
      ...confirmedSteps,
      [activeStep.value]: true,
    });
    goToNextStep();
  };

  return {
    selectStep,
    clearAllSteps,
    goToNextStep,
    confirmStep,
    goToPreviousStep,
  };
};
