import React from 'react';
import Button from '../Button';
import { useThemeUI } from 'theme-ui';

const GetQuoteButton = props => {
  const { theme } = useThemeUI();
  return (
    <Button
      data-id="get-quote-btn"
      {...props}
      data={{
        style: theme.quoteButtonStyle,
        link: {
          url: '/quote',
        },
        text: 'Get a quote',
      }}
    />
  );
};

export default GetQuoteButton;
