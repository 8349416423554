import React from 'react';
import { InputControl } from '~common/molecules/form-controls';
import AddressField from './AddressField';
import CheckBoxControl from '~common/molecules/form-controls/CheckBoxControl';

const ContactDetailsForm = ({ Field, handleChange, values }) => {
  return (
    <>
      <Field
        name="email"
        label="Email"
        onChange={handleChange}
        component={InputControl}
        trim
        focus
      />
      <Field
        name="phoneNumber"
        label="Mobile number"
        onChange={handleChange}
        component={InputControl}
        trim
        type="tel"
      />

      <AddressField
        name="residentialAddress"
        label="Residential address"
        onChange={handleChange}
      />

      <Field
        name="isPostalAddressDifferent"
        label="My postal address is different from the residential address"
        labelPosition="right"
        component={CheckBoxControl}
        onChange={handleChange}
      />

      {values.isPostalAddressDifferent && (
        <AddressField
          name="postalAddress"
          label="Postal address"
          onChange={handleChange}
        />
      )}
    </>
  );
};

export default React.memo(ContactDetailsForm);
