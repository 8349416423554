import React, { useState } from 'react';
import Block from '../../common/atoms/Block';
import ArticleCard from './ArticleCard';
import styled from '@emotion/styled';
import { Flex } from 'rebass';
import { Button } from '~common/atoms/Button';
import { GRID_ROW_HEIGHT } from './constants';

const ARTICLES_PER_PAGE_LIMIT = 12;

const Grid = styled.div`
  display: grid;
  row-gap: 30px;
  grid-template-columns: 100%;
  grid-auto-rows: minmax(${GRID_ROW_HEIGHT}px, auto);

  ${props => props.theme.mq.tabletP} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
  }
  ${props => props.theme.mq.tabletL} {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
  }
`;

const LoadMoreButton = styled(Button)`
  width: auto;
`;

const NewsGrid = ({ articles }) => {
  const [shownArticles, setShownArticles] = useState(
    articles.slice(0, ARTICLES_PER_PAGE_LIMIT - 1)
  );

  const loadMore = () => {
    setShownArticles(
      articles.slice(0, shownArticles.length + ARTICLES_PER_PAGE_LIMIT)
    );
  };

  return (
    <Block>
      <Grid>
        {shownArticles.map((article, index) => (
          <ArticleCard index={index} key={article._id} article={article} />
        ))}
      </Grid>
      {shownArticles.length !== articles.length && (
        <Flex mt={60} justifyContent="center">
          <LoadMoreButton onClick={loadMore} variant="primary">
            Load more
          </LoadMoreButton>
        </Flex>
      )}
    </Block>
  );
};

export default NewsGrid;
