import React from 'react';
import { css } from '@emotion/core';

import { Caption, P3, StandardText } from '~common/atoms/typography';
import { currency } from '~lib/filters';
import FormulaBox from './FormulaBox';
import FormulaSymbol from './FormulaSymbol';
import { SecondColumn } from '../../common/layout';
import { Flex } from 'rebass';
import Icon from '~common/atoms/Icon';
import styled from '@emotion/styled';

const TotalBox = styled(FormulaBox)`
  ${props => props.theme.mq.tinyPhoneInverted} {
    text-align: end;
  }
`;

const Subtitle = ({ name, coverage }) => {
  return (
    <Flex>
      <P3 variant="bold" color="white">
        {name}
      </P3>
      {coverage && (
        <P3 ml="5px" variant="medium" color="white">
          {coverage}
        </P3>
      )}
    </Flex>
  );
};

const Formula = ({
  isMobile,
  theme,
  hospital,
  extras,
  total,
  medicareRebateInactive,
  efc,
  openInclusionsModal,
  tier,
  ...props
}) => {
  return (
    <SecondColumn
      css={css`
        display: grid;
        grid-template-columns: repeat(5, auto);
        grid-column-gap: 20px;
        ${theme.mq.tabletP} {
          grid-column-gap: 0;
          border-left: 1px solid ${theme.colors.borders};
        }
      `}
      justifyContent="space-between"
      alignItems="center"
      {...props}
    >
      {hospital && (
        <FormulaBox
          title="HOSPITAL"
          subtitle={
            !isMobile && (
              <Subtitle name={hospital.name} coverage={hospital.coverage} />
            )
          }
          smallTitle={
            <>
              {currency(hospital.price)}{' '}
              {!!hospital.excess && (
                <Caption color="white" inline={!isMobile} variant="medium">
                  With {currency(hospital.excess)} excess
                </Caption>
              )}
            </>
          }
        />
      )}
      {hospital && extras && <FormulaSymbol theme={theme} name="plus" />}
      {extras && (
        <FormulaBox
          title="EXTRAS"
          subtitle={
            !isMobile && (
              <Subtitle name={extras.name} coverage={extras.coverage} />
            )
          }
          smallTitle={currency(extras.price)}
        />
      )}

      <FormulaSymbol theme={theme} name="equals" />
      <TotalBox
        title="TOTAL"
        subtitle={
          total && (
            <>
              <StandardText inline fontSize={5} rColor="white">
                {currency(total.price)}
              </StandardText>{' '}
              <Caption variant="medium" color="white" inline={!isMobile}>
                {
                  {
                    WEEKLY: 'per week',
                    MONTHLY: 'per month',
                    QUARTERLY: 'per quarter',
                    HALF_YEARLY: 'per 6 months',
                    YEARLY: 'per year',
                  }[total.paymentFrequency]
                }
              </Caption>
            </>
          )
        }
        smallTitle={
          <>
            {!isMobile &&
              (medicareRebateInactive ? (
                <Caption variant="medium" color="white">
                  No Government Rebate applied
                </Caption>
              ) : (
                <Flex alignItems="center">
                  <Icon
                    name="discount"
                    fill="white"
                    width="12px"
                    height="12px"
                    mr="8px"
                  />
                  <Caption variant="medium" color="white">
                    Includes {Number(tier) ? `tier ${tier}` : 'base tier'}{' '}
                    rebate of {total?.rebatePercentage?.toFixed(2) || 0}%
                  </Caption>
                </Flex>
              ))}
            <P3
              mt="8px"
              variant="underline"
              onClick={() => openInclusionsModal()}
              color="white"
            >
              See price inclusions
            </P3>
          </>
        }
      />
    </SecondColumn>
  );
};

export default Formula;
