const FAMILY = [
  {
    value: '0',
    label: 'Below $194k',
    description: 'Base tier',
  },
  {
    value: '1',
    label: '$194k - $226k',
    description: 'Tier 1',
  },
  {
    value: '2',
    label: '$226k - $302k',
    description: 'Tier 2',
  },
  {
    value: '3',
    label: 'Over $302k',
    description: 'Tier 3',
  },
];

const tiers = {
  SINGLE: [
    {
      value: '0',
      label: 'Below $97k',
      description: 'Base tier',
    },
    {
      value: '1',
      label: '$97k - $113k',
      description: 'Tier 1',
    },
    {
      value: '2',
      label: '$113k - $151k',
      description: 'Tier 2',
    },
    {
      value: '3',
      label: 'Over $151k',
      description: 'Tier 3',
    },
  ],
  FAMILY,
  SINGLE_PARENT: FAMILY,
  COUPLE: FAMILY,
};

export default tiers;
