import React from 'react';
import Block from '../../common/atoms/Block';
import { useColumnContext } from '~lib/providers/ColumnProvider';
import { usePageContext } from '~lib/providers/PageProvider';
import { H1 } from '~common/atoms/typography';

export const TitleBase = ({ columnContext = {}, children, ...props }) => (
  <H1
    variant="semiBold"
    textAlign={{
      xs: 'left',
      tabletP: 'left',
    }}
    {...props}
  >
    {children}
  </H1>
);

const Title = ({
  data: { text, color },
  next = {},
  metaData,
  titleProps,
  ...props
}) => {
  const columnContext = useColumnContext() || {};
  const pageContext = usePageContext() || {};
  return (
    <Block
      color={color || 'primary'}
      s
      redefineMb={{
        xs: 30,
        tabletP: 40,
        lg: 40,
      }}
      {...props}
    >
      <TitleBase
        color={color || 'primary'}
        textAlign={{
          xs: 'left',
          tabletP:
            columnContext.sideMenuLayout || pageContext.isCampaign
              ? 'left'
              : 'center',
        }}
        {...titleProps}
      >
        {text}
      </TitleBase>
    </Block>
  );
};

export default Title;
