import React, { useContext } from 'react';
import { Box, Button, Flex } from 'rebass';
import SimpleErrorHeader from '~common/atoms/SimpleErrorHeader';
import SSOText from '~OMS/SSO/atoms/SSOText';
import SSOFormContainer from '~OMS/styles/SSOFormContainer';
import Link from '~common/atoms/Link';
import { ContactUsContext } from '~OMS/contact-us/ContactUsContext';
import { AuthContext } from '../../../../context/sso';

const LoginUnsuccessfulWrapper = () => {
  const { openRequestCallback } = useContext(ContactUsContext);

  const { getErrorMessageByCode } = useContext(AuthContext);

  const copy = getErrorMessageByCode(412);

  return (
    <SSOFormContainer>
      <SimpleErrorHeader withIcon title="Unsuccessful login attempt" />
      <Box mt={3} mb={4}>
        <SSOText>{copy}</SSOText>
      </Box>

      <Button
        onClick={() => {
          openRequestCallback({
            isAnomUser: true,
          });
        }}
        variant="orange"
        type="button"
      >
        Request Callback
      </Button>

      <Flex justifyContent="center" mt={4}>
        <Link to="/oms/sso/login/">Try logging in again</Link>
      </Flex>
    </SSOFormContainer>
  );
};

export default LoginUnsuccessfulWrapper;
