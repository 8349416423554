import React from 'react';

import { withTheme } from 'emotion-theming';
import { Flex, Box, Button } from 'rebass';

import FormTitle from '~OMS/styles/FormTitle';
import SSOFormContainer from '~OMS/styles/SSOFormContainer';
import IconHeading from '~common/atoms/IconHeading';

// SSO Legacy ID Retired page wrapper component
function LegacyIDRetiredWrapper() {
  return (
    <SSOFormContainer>
      <FormTitle>Upgrade ID</FormTitle>
      <Flex>
        <Box width={1} mt="30px" mb="40px">
          <IconHeading
            icon="remove"
            fill="#d0021b"
            text="You can no longer log in to this account using your membership number. Please request access from the primary account holder below, or call us for help on XXXX XXXX."
            mb="0px"
          />
        </Box>
      </Flex>
      <Flex>
        <Box width={1}>
          <Button variant="orange" css={{ width: '100%' }}>
            Request Access
          </Button>
        </Box>
      </Flex>
    </SSOFormContainer>
  );
}

export default withTheme(LegacyIDRetiredWrapper);
