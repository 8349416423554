import { useCallback, useContext, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { validators } from '~common/molecules/form-controls';
import { scroller } from 'react-scroll';
import { debounce } from 'lodash';
import { FormValidator, required } from '~lib/formValidation';
import { AuthContext } from '../../../../context/sso';

const validation = {
  email: [
    value => {
      validators.email().validateSync(value);
    },
  ],
  membershipId: [
    required('Membership number is required'),
    value => {
      validators.membershipNumber().validateSync(value);
    },
  ],
  lastName: required('Last Name is required'),
  day: [
    required('Day is required'),
    value => {
      validators.day().validateSync(value);
    },
  ],
  year: [
    required('Year is required'),
    value => {
      validators.year().validateSync(value);
    },
  ],
  month: [
    required('Month is required'),
    value => {
      validators.month().validateSync(value);
    },
  ],
};

const scrollToIdentityValidation = to => {
  scroller.scrollTo(to, {
    duration: 1000,
    delay: 0,
    isDynamic: true,
    offset: 0,
    smooth: 'easeInOutQuint',
  });
};

export default () => {
  const { forgotPassword, error, loading } = useContext(AuthContext);
  const [validationDisabled, setValidationDisabled] = useState(true);

  const checkIfValidationDisabled = useCallback(
    (username, scrollTo = 'form') => {
      const shouldBeDisabled = Yup.string().email().isValidSync(username);

      // only switch states if username is valid
      if (validators.username().isValidSync(username) && username) {
        setValidationDisabled(shouldBeDisabled);
        scrollToIdentityValidation(scrollTo);
      }
    },
    []
  );

  const checkIfValidationDisabledWithDebounce = useCallback(
    debounce(checkIfValidationDisabled, 500),
    [checkIfValidationDisabled]
  );

  const handleUsernameBlur = handleOnBlur => evt => {
    checkIfValidationDisabled(evt.target.value, 'identityValidation');
    handleOnBlur(evt);
  };

  const validateForm = useMemo(() => {
    return FormValidator({
      validation,
    });
  }, []);

  const handleUsernameChanged = handleChange => evt => {
    handleChange(evt);
    checkIfValidationDisabledWithDebounce(evt.target.value, 'form');
  };

  return {
    state: {
      error,
      loading,
      validationDisabled,
    },
    actions: {
      forgotPassword,
      validateForm,
      handleUsernameChanged,
      handleUsernameBlur,
      checkIfValidationDisabled,
    },
  };
};
