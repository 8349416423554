import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledHorizontalRule = styled.hr`
  background-color: ${props => props.color};
  margin-top: ${props => props.mt}px;
  margin-bottom: ${props => props.mb}px;
`;

const HorizontalRule = ({ color, mb, mt }) => {
  return <StyledHorizontalRule color={color} mb={mb} mt={mt} />;
};

HorizontalRule.defaultProps = {
  color: '#C9C9C9',
  mb: '0',
  mt: '0',
};

HorizontalRule.propTypes = {
  color: PropTypes.string.isRequired,
  mb: PropTypes.number.isRequired,
  mt: PropTypes.number.isRequired,
};

export default HorizontalRule;
