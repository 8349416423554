import { APPLICATION_FORM_STEPS } from '../../../../../../ApplicationForm/constants';
import { chainedPath } from '~lib/util';
import { useEffect } from 'react';
import { toDate } from '~lib/dates';
import { PaymentPolicyQuery } from 'queries/oms/payments.graphql';
import { useQuery } from '@apollo/client';
import { isBankPayment, isCreditCardPayment } from '~OMS/response-selectors';
import { path } from 'lodash/fp';
import { PAYMENT_TYPE } from '~lib/constants';

const applyDob = dobString => {
  const date = toDate(dobString);
  return {
    dobYear: date.get('year'),
    dobMonth: date.get('month') + 1,
    dobDay: date.get('date'),
  };
};

export default ({
  stepProvider,
  hasPartner,
  isDependantsPackage,
  changeCoverContext,
}) => {
  const { data, loading } = useQuery(PaymentPolicyQuery);
  useEffect(() => {
    const yourDetails = stepProvider.get(
      APPLICATION_FORM_STEPS.YOUR_DETAILS.value
    ).context;
    const familyDetails = stepProvider.get(
      APPLICATION_FORM_STEPS.FAMILY_DETAILS.value
    ).context;
    const coverDetails = chainedPath('state.coverDetails')(changeCoverContext);

    yourDetails.personalDetailsForm.setForm({
      firstName: coverDetails('member.firstName'),
      lastName: coverDetails('member.lastName'),
      title: coverDetails('member.title'),
      gender: coverDetails('member.gender'),
      ...applyDob(coverDetails('dob.value')),
    });

    yourDetails.contactDetailsForm.setForm({
      email: coverDetails('email.value'),
      phoneNumber: coverDetails('mobileNumber.value'),
      residentialAddress: coverDetails('address.value'),
    });

    if (hasPartner) {
      familyDetails.partnerDetails.form.setForm({
        firstName: coverDetails('partner.firstName'),
        lastName: coverDetails('partner.lastName'),
        email: coverDetails('partner.email'),
        mobileNumber: coverDetails('partner.mobilePhone'),
        ...applyDob(coverDetails('partner.dob')),
      });
    }

    if (isDependantsPackage && coverDetails('dependants.length')) {
      coverDetails('dependants').forEach(dependant => {
        familyDetails.dependantDetails.addDependant(false, {
          firstName: dependant.firstName,
          lastName: dependant.lastName,
          ...applyDob(dependant.dob),
        });
      });
    }
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }

    const paymentDetails = stepProvider.get(
      APPLICATION_FORM_STEPS.PAYMENTS.value
    ).context;
    const creditCardPayment = isCreditCardPayment(data);
    const bankPayment = isBankPayment(data);
    const debitDetails = path('oms.payments.debitAccount.details')(data);
    const claimDetails = path('oms.payments.claimAccount.details')(data);

    if (creditCardPayment) {
      paymentDetails.form.setForm({
        debitType: PAYMENT_TYPE.CREDIT_CARD,
        cardHolder: debitDetails.holder,
        cardNumber: debitDetails.number,
        expiry: debitDetails.expiry,
      });
    } else if (bankPayment) {
      paymentDetails.form.setForm({
        debitType: PAYMENT_TYPE.BANK_ACCOUNT,
        bsb: debitDetails.accountBSB,
        accountNumber: debitDetails.accountNumber,
        accountName: debitDetails.accountName,
      });
    }

    if (claimDetails) {
      paymentDetails.form.setForm({
        useBankAccountDetails: false,
        claimBsb: claimDetails.accountBSB,
        claimAccountNumber: claimDetails.accountNumber,
        claimAccountName: claimDetails.accountName,
      });
    }
  }, [data]);

  return {
    loading,
  };
};
