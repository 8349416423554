import React from 'react';
import { useThemeUI } from 'theme-ui';
import { H2, P2 } from '~common/atoms/typography';
import PhoneNumber from '~common/atoms/PhoneNumber';
import { Button } from '~common/atoms/Button';
import Icon from '~common/atoms/Icon';
import { formatDays } from '~common/molecules/WorkingTime';
import { Flex } from 'rebass';

const SaveForLaterSuccessModal = ({
  email,
  callUsNumber,
  workingTime,
  onConfirm,
}) => {
  const { theme } = useThemeUI();
  const { days, hours, timezone } = workingTime;

  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        width={1}
      >
        <H2 mb={4}>Saved successfully</H2>
        <Icon
          name="circle-fill-tick"
          fill={theme.colors.green}
          width="50px"
          height="50px"
        />
        <P2 textAlign="center" mt={4} mb={4}>
          We have sent an email to {email} with the link to resume your
          application from any device. This link will be valid for 28 days.
        </P2>
        <P2 textAlign="center" mb={4}>
          If you need help, call us on{' '}
          <PhoneNumber phone={callUsNumber} inline bold /> {formatDays(days)}{' '}
          (excluding NSW public holidays) from {hours.from} to {hours.to} (
          {timezone}).
        </P2>

        <Button onClick={onConfirm} variant="primary">
          OK
        </Button>
      </Flex>
    </>
  );
};

export default SaveForLaterSuccessModal;
