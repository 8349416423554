import React from 'react';
import { MaskedInput } from '~common/atoms/Input';
import { CreateControl } from '~common/molecules/form-controls/Control';
import { mixed } from 'yup';
import { NON_DIGIT_REGEX } from '~lib/regexes';
import { memoizeBy } from '~lib/util';

const creditCardMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const CardNumberControl = props => {
  return <MaskedInput keepCharPositions mask={creditCardMask} {...props} />;
};

export default CreateControl(CardNumberControl);

const luhnCheck = (function (array) {
  return function luhn(number) {
    let length = number.length;
    let bit = 1;
    let sum = 0;
    let value;

    while (length) {
      value = parseInt(number.charAt(--length), 10);
      bit ^= 1;
      sum += bit ? array[value] : value;
    }

    return sum % 10 === 0;
  };
})([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]);

const VISA_MASTER_CARD_LENGTH = 16;

export const cardNumberValidator = (name = 'cardNumber') =>
  mixed().test({
    name,
    test: memoizeBy(number => number)(number => {
      number = number.replace(NON_DIGIT_REGEX, '');
      if (!number) {
        // leave this to required validator so the more appropriate message can be shown to the user
        return true;
      }

      if (number.length !== VISA_MASTER_CARD_LENGTH) {
        return false;
      }

      return luhnCheck(number);
    }),
    message: `You have entered invalid credit card number`,
  });
