import React from 'react';
import { Box } from 'rebass';
import { Form } from '~common/molecules/Form';
import { Field } from 'formik';
import DateOfBirth from '~common/molecules/DateOfBirth';
import Confirmation from '~common/molecules/Confirmation';
import { InputControl, validators } from '~common/molecules/form-controls';
import {
  email,
  FormValidator,
  phoneNumber,
  required,
  address,
} from '~lib/formValidation';
import { formatToISO8601DateOnly, fromNativeDate } from '~lib/dates';
import AddressField from '~OMS/my-cover/change-cover/MyDetailsSection/AddressField';
import { FORM_MODE } from '~lib/constants';

const validation = FormValidator({
  validation: {
    address: address('Address'),
    email: [required('Email is required field'), email('Invalid email')],
    mobileNumber: [
      required('Mobile number is required field'),
      phoneNumber('Invalid mobile number'),
    ],
    day: [
      required('Day is required'),
      value => {
        validators.day().validateSync(value);
      },
    ],
    month: [
      required('Month is required'),
      value => {
        validators.month().validateSync(value);
      },
    ],
    year: [
      required('Year is required'),
      value => {
        validators.year().validateSync(value);
      },
      validators.dobValidator,
    ],
  },
});

const EditMyDetails = ({ setMode, onSave, initialForm, ...props }) => {
  return (
    <Form
      validate={validation}
      initialValues={initialForm}
      onSubmit={form => {
        onSave({
          ...form,
          dob: formatToISO8601DateOnly(
            fromNativeDate(new Date(form.year, form.month - 1, form.day))
          ),
        });
        setMode(FORM_MODE.VIEW);
      }}
    >
      {({ handleSubmit, handleChange }) => (
        <Box {...props}>
          <AddressField
            onChange={handleChange}
            labelPosition="left"
            name="address"
            label="Address"
          />

          <DateOfBirth
            labelStyle={{
              fontWeight: 'normal',
              fontSize: '16px',
            }}
            labelPosition="left"
          />

          <Field
            name="email"
            label="Email address"
            labelPosition="left"
            component={InputControl}
          />
          <Field
            name="mobileNumber"
            label="Mobile number"
            labelPosition="left"
            component={InputControl}
            type="tel"
          />

          <Confirmation
            onConfirm={handleSubmit}
            onCancel={() => {
              setMode('view');
            }}
          />
        </Box>
      )}
    </Form>
  );
};

export default EditMyDetails;
