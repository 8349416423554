import React from 'react';
import Block from '../../../common/atoms/Block';
import Logo from '~common/atoms/Logo';
import { Flex, Box } from 'rebass';
import CallUsBox from './CallUsBox';
import { useMobileScreen } from '~lib/responsive';
import CallUsButton from './CallUsButton';

const isHeroComponent = component =>
  ['heroComponent', 'newHeroComponent'].includes(component._type);

const CampaignHeader = ({ data: { topMenu, logo }, next = {}, ...props }) => {
  const spacing = isHeroComponent(next)
    ? {
        mb: 0,
      }
    : undefined;
  return (
    <Block pb={20} mt={27} {...spacing} ignoreCampaignLayout {...props}>
      <Flex>
        <Box width={1 / 5}>
          <Logo />
        </Box>
        <Flex width={4 / 5} justifyContent="flex-end" alignItems="center">
          <CallUsBox mr={50} />
        </Flex>
      </Flex>
    </Block>
  );
};

const CampaignHeaderMobile = ({ data: { logo, topMenu }, next = {} }) => {
  const spacing = isHeroComponent(next)
    ? {
        mb: 30,
      }
    : undefined;
  return (
    <Block mt={20} {...spacing} ignoreCampaignLayout>
      <Flex mb={30} justifyContent="space-between" alignItems="center">
        <Logo height={60} />
        <CallUsButton />
      </Flex>
      {/*<Flex justifyContent="space-between" alignItems="center">*/}
      {/*  {topMenu.items.map(item => (*/}
      {/*    <MainMenuItem data={item.link}>{item.title}</MainMenuItem>*/}
      {/*  ))}*/}
      {/*</Flex>*/}
    </Block>
  );
};

export default props => {
  const isMobile = useMobileScreen();
  const Component = isMobile ? CampaignHeaderMobile : CampaignHeader;
  return <Component {...props} />;
};
