import React from 'react';
import Layout from 'components/Layout';
import ResetSuccessWrapper from '~OMS/SSO/organisms/ResetSuccessWrapper';
import { withTheme } from 'emotion-theming';

const ResetSuccess = props => {
  return (
    <Layout>
      <ResetSuccessWrapper />
    </Layout>
  );
};

export default withTheme(ResetSuccess);
