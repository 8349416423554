import styled from '@emotion/styled';
import { Flex } from 'rebass';
import { StandardText } from '../../../../common/atoms/typography';
import {
  claimLineColumns,
  mobileClaimLineColumns,
} from '~OMS/claims/claimHistoryColumns';
import React from 'react';
import ClaimsHistoryRow from '~OMS/claims/molecules/atoms/ClaimsHistoryRow';
import ClaimHeader from '~OMS/claims/molecules/ClaimHeader';
import { columnValue } from '~OMS/claims/utils';
import { path } from 'lodash/fp';
import { CLAIM_STATUS, SUBMITTED_CLAIM_RENDERED_ID } from '../constants';

const ColumnValue = ({ column, item, claim, ...props }) => {
  return column.customRender ? (
    column.customRender({
      value: path(column.name)(item),
      item,
      claim,
      ...column,
      ...props,
    })
  ) : (
    <StandardText {...props}>{columnValue(item, column)}</StandardText>
  );
};

const Record = ({ columns, item, claim, ...props }) => {
  return (
    <Flex {...props}>
      {columns.map((column, index) =>
        ColumnValue({
          key: index,
          column,
          item,
          claim,
          width: column.width,
          pr: column.pr,
          fontSize: 2,
        })
      )}
    </Flex>
  );
};

const DesktopClaimLines = ({ lines, claim, columns }) => {
  return (
    <ClaimsHistoryRow flexDirection="column" py={3}>
      <ClaimHeader mb={2} columns={columns} />
      {lines.map((line, index) => (
        <Record
          key={line.id}
          claim={claim}
          mb={index === lines.length - 1 ? 0 : 12}
          item={line}
          columns={columns}
        />
      ))}
    </ClaimsHistoryRow>
  );
};

const MobileClaimLine = styled(ClaimsHistoryRow)`
  border-bottom: 2px solid ${props => props.theme.colors.iceBlue};
`;

const MobileClaimLines = ({ lines, columns }) => {
  const spaceBetweenRows = 3;
  return lines.map(line => (
    <MobileClaimLine py={3} flexDirection="column" key={line.id}>
      <Flex mb={spaceBetweenRows}>
        <StandardText bold fontSize={1} mr={1}>
          Claim ID
        </StandardText>
        <StandardText fontSize={1}>
          {line.status !== CLAIM_STATUS.SUBMITTED
            ? line.id
            : SUBMITTED_CLAIM_RENDERED_ID}
        </StandardText>
      </Flex>
      {columns.map((column, index) => (
        <Flex
          mb={index === columns.length - 1 ? 0 : spaceBetweenRows}
          key={index}
        >
          <StandardText
            width={1 / 3}
            fontSize={1}
            bold={column.isDisplayNameBold}
          >
            {column.displayName}
          </StandardText>
          <ColumnValue width={2 / 3} fontSize={2} item={line} column={column} />
        </Flex>
      ))}
    </MobileClaimLine>
  ));
};

const ClaimLines = ({ isMobile, ...props }) =>
  isMobile ? (
    <MobileClaimLines columns={mobileClaimLineColumns} {...props} />
  ) : (
    <DesktopClaimLines columns={claimLineColumns} {...props} />
  );

export default ClaimLines;
