import React from 'react';
import { Flex } from 'rebass';

import BackgroundImage from '../../common/molecules/BackgroundImage';
import { useMobileScreenOnly } from '~lib/responsive';
import SanityImage from '../../common/molecules/SanityImage';
import CenterBox from '~common/atoms/CenterBox';
import Block from '../../common/atoms/Block';
import { usePageContext } from '~lib/providers/PageProvider';
import { useColumnContext } from '~lib/providers/ColumnProvider';
import { ButtonsBase } from './Buttons';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import QuickQuoteLinks from './QuickQuoteLinks';
import styled from '@emotion/styled';

import { useThemeUI } from 'theme-ui';
import useHighlightedText from '~common/hooks/useHighlightedText';
import { H1 } from '~common/atoms/typography';
import css from '@emotion/css';

const StyledQuickQuoteLinks = styled(QuickQuoteLinks)`
  ${props => props.theme.mq.tabletPInverted} {
    left: 0;
    right: 0;
  }
  &&& {
    position: absolute;
    transform: translateY(20px);
    width: auto;
    padding-top: 60px;
    padding-bottom: 60px;
    left: 80px;
    right: 80px;
    ${props => props.theme.mq.xl} {
      left: calc(50% - 640px);
      right: calc(50% - 640px);
    }

    box-shadow: 0px -1px 12px rgba(0, 0, 0, 0.03),
      0px 4px 8px rgba(0, 0, 0, 0.08);
  }
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  position: relative;
`;

const LegacyHeroBlockTitle = ({ title, textIndent = false }) => {
  const { theme } = useThemeUI();
  const highlightedTitle = useHighlightedText({
    text: title,
    color: theme.colors.secondary,
    wordsToHighlight: [2, 3],
  });

  return (
    <H1
      variant="medium"
      maxWidth={textIndent ? '60%' : { tabletP: '489px', lg: '590px' }}
      width={1}
      mb={2}
      color="primary"
    >
      {highlightedTitle}
    </H1>
  );
};

const StyledRichTextTitle = styled(SanityRichTextContent)`
  em {
    font-style: normal;
    color: ${props => props.theme.colors.secondary};
  }
`;

const RichTextHeroBlockTitle = ({ title, textIndent = false }) => {
  return (
    <StyledRichTextTitle
      content={title}
      customSerializers={{
        block: ({ children }) => (
          <H1
            variant="medium"
            maxWidth={textIndent ? '60%' : { tabletP: '489px', lg: '590px' }}
            width={1}
            mb={2}
            color="primary"
          >
            {children}
          </H1>
        ),
      }}
    />
  );
};

const StyledRichTextSubtitle = styled(SanityRichTextContent)`
  width: 100%;
  ${props => props.theme.mq.sm} {
    max-width: 45%;
  }

  ${props => props.theme.mq.md} {
    width: 470px;
  }

  a {
    color: ${props => props.color};
  }

  .text-block,
  .heading-block,
  a {
    font-size: 20px;
    ${props => props.theme.mq.tabletPInverted} {
      color: ${props => props.theme.colors.dark};
    }
  }
`;

const HeroBlockSubtitle = ({ subtitle, color }) => {
  return subtitle ? (
    <StyledRichTextSubtitle color={color} content={subtitle} />
  ) : null;
};

const HeroBlockActions = ({ actions = {} }) => (
  <Flex
    mt={4}
    flexDirection="row"
    alignItems="center"
    justifyContent="flex-start"
  >
    <ButtonsBase
      flexDirection="row"
      size="large"
      data={{
        ...actions,
        buttons: (actions.buttons || []).slice(0, 2),
      }}
      buttonProps={{
        maxHeight: 54,
        mb: 0,
        css: css`
          white-space: nowrap;
        `,
      }}
    />
  </Flex>
);

const TextIndentWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const HeroDesktop = ({
  data: {
    title,
    actions,
    titleColor,
    subtitleColor,
    backgroundImage,
    subtitle,
    textIndent,
    quickQuoteLinks,
  },
  next,
  ...props
}) => {
  const columnContext = useColumnContext() || {};
  const margins = columnContext.sideMenuLayout
    ? {
        ml: {
          xs: 25,
          tabletP: 25,
          tabletL: 50,
          lg: 102,
        },
      }
    : undefined;

  let mb;
  if (next._type === 'richTextComponent') {
    mb = 50;
  }

  if (quickQuoteLinks) {
    mb = {
      tabletP: (mb || 0) + 390,
      tabletL: (mb || 0) + 340,
      lg: (mb || 0) + 260,
    };
  }

  return (
    <StyledBackgroundImage
      {...margins}
      mb={mb}
      ignoreCampaignLayout
      pt={{ xs: 64, lg: 120 }}
      pb={{ xs: 40, lg: 92 }}
      bg="background.gray"
      data={backgroundImage}
      transform={builder =>
        window && window.screen ? builder.width(window.screen.width) : builder
      }
      {...props}
    >
      <TextIndentWrapper
        condition={textIndent}
        wrapper={children => (
          <CenterBox
            flexDirection="column"
            alignItems="start"
            px={{ xl: 200, lg: 140 }}
          >
            {children}
          </CenterBox>
        )}
      >
        {/* Handles legacy hero blocks that use text instead of portable text */}
        {typeof title === 'string' ? (
          <LegacyHeroBlockTitle
            title={title}
            color={titleColor}
            textIndex={textIndent}
          />
        ) : (
          <RichTextHeroBlockTitle
            title={title}
            color={titleColor}
            textIndex={textIndent}
          />
        )}
        <HeroBlockSubtitle
          color={titleColor}
          textIndent={textIndent}
          subtitle={subtitle}
        />
        <HeroBlockActions actions={actions} />
      </TextIndentWrapper>
      {quickQuoteLinks && (
        <StyledQuickQuoteLinks
          redefineMb={0}
          pl={40}
          pr={40}
          pt={60}
          pb={60}
          mb={0}
          mt={0}
          data={quickQuoteLinks}
          innerProps={{
            width: 1,
          }}
        />
      )}
    </StyledBackgroundImage>
  );
};

const HeroMobile = ({
  data: {
    title,
    actions,
    titleColor,
    subtitleColor,
    backgroundImage,
    subtitle,
    quickQuoteLinks,
  },
  next,
  ...props
}) => {
  const { theme } = useThemeUI();
  const { isCampaign } = usePageContext();
  const { sideMenuLayout } = useColumnContext() || {};
  //TODO: move all this spacing configs to single accessible place
  const margins =
    (!isCampaign && sideMenuLayout) || next._type === 'richTextComponent'
      ? {
          text: {
            mb: 0,
            pt: 0,
          },
          image: {
            mb: 50,
            ml: 0,
          },
        }
      : {};

  return (
    <Flex flexDirection="column">
      <Block
        ignoreCampaignLayout
        flexDirection="column"
        pt={{ xs: isCampaign ? 22 : 64, lg: 120 }}
        pb={3}
        bg="background.white"
        {...margins.text}
        {...props}
      >
        {/*ignore title color coming from CMS for mobile resolutions as mobile title does not sit inside the background image*/}
        {/* Handles legacy hero blocks that use text instead of portable text */}
        {typeof title === 'string' ? (
          <LegacyHeroBlockTitle title={title} color={theme.colors.secondary} />
        ) : (
          <RichTextHeroBlockTitle
            title={title}
            color={theme.colors.secondary}
          />
        )}
        <HeroBlockSubtitle color={theme.colors.black} subtitle={subtitle} />
        <HeroBlockActions actions={actions} />
      </Block>
      <CenterBox {...margins.image}>
        <SanityImage
          style={{
            width: '100%',
            height: '375px',
          }}
          src={backgroundImage}
          transform={transformer =>
            transformer.width(375).height(375).fit('crop')
          }
        />
      </CenterBox>
      {quickQuoteLinks && <QuickQuoteLinks data={quickQuoteLinks} />}
    </Flex>
  );
};

export default props => {
  const isMobile = useMobileScreenOnly();
  return isMobile ? <HeroMobile {...props} /> : <HeroDesktop {...props} />;
};
