import React, { useState } from 'react';
import { Flex } from 'rebass';
import styled from '@emotion/styled';
import Icon from '../../../../common/atoms/Icon';
import { useThemeUI } from 'theme-ui';
import { StandardText } from '~common/atoms/typography';
import MapList from '../../../Elements/MapList';
import { css } from '@emotion/core';
import HeaderBlock from './HeaderBlock';
import { StyledLink } from '../Styles';
import { slideIn, slideOut } from '../HeaderAnimations';

const SubMenuBlock = styled(HeaderBlock)`
  position: relative;
  ${props =>
    !props.visible
      ? `
    padding-top: 0;
    padding-bottom: 0;
  `
      : ''}
  ${props => {
    return props.visible
      ? slideOut(props)
      : props.visible === false
      ? slideIn(props)
      : '';
  }}
`;

const SubMenuRow = styled(Flex)`
  height: 100%;
  grid-gap: 32px;
  flex-wrap: wrap;

  ${props => props.theme.mq.tabletL} {
    grid-gap: 64px;
  }
`;

const SubMenuColumn = styled(Flex)`
  grid-gap: 20px;
`;

const CloseSubMenuIcon = styled(Icon)`
  position: absolute;
  right: 25px;
  top: 21px;
  display: none;

  ${props => props.theme.mq.tabletLInverted} {
    display: flex;
    opacity: 0;
    ${props => props.visible && 'opacity: 1'};
  }
`;

const SubMenu = ({
  menu = {},
  onClose,
  onMouseEnter,
  onMouseLeave,
  visible,
  mb,
}) => {
  const { theme } = useThemeUI();
  const [hoveredItem, setHoveredItem] = useState();

  return (
    <SubMenuBlock
      visible={visible}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      type="dark"
      mt={0}
      mb={mb}
    >
      <SubMenuRow>
        {(menu.items || []).map((firstLevelItem, index) => (
          <SubMenuColumn flexDirection="column" key={firstLevelItem._key}>
            <MapList
              list={[firstLevelItem].concat(
                (firstLevelItem.showSubmenuOnDesktop && firstLevelItem.items) ||
                  []
              )}
              render={({ item, isFirst, isLast }) => {
                return (
                  <Flex
                    onMouseLeave={() => {
                      setHoveredItem();
                    }}
                    onMouseEnter={() => {
                      setHoveredItem(item);
                    }}
                    onTouchStart={() => {
                      setHoveredItem(item);
                    }}
                    alignItems="center"
                    width={1}
                    key={item._key}
                  >
                    <StandardText
                      clickable
                      fontWeight={isFirst ? 600 : 500}
                      rColor="primary"
                      as={StyledLink}
                      data={item.link}
                    >
                      {item.title}
                    </StandardText>

                    <Icon
                      css={css`
                        transform: translate(
                          ${hoveredItem === item ? '5px' : 0},
                          2px
                        );
                        opacity: ${hoveredItem === item ? 1 : 0};
                        transition: all 200ms ease-in;
                      `}
                      name="arrow-right"
                      fill={theme.colors.primary}
                      height="13px"
                      width="11px"
                    />
                  </Flex>
                );
              }}
            />
          </SubMenuColumn>
        ))}
      </SubMenuRow>
      <CloseSubMenuIcon
        visible={visible}
        onClick={onClose}
        clickable
        stroke={theme.colors.primary}
        name="close"
        height={'15px'}
        width={'15px'}
      />
    </SubMenuBlock>
  );
};

export default SubMenu;
