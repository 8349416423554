import { PAYMENT_TYPE } from '~lib/constants';

export const APPLICATION_FORM_STEPS = {
  ELIGIBILITY: {
    value: 'eligibility',
    label: 'Eligibility',
  },
  YOUR_DETAILS: {
    value: 'yourDetails',
    label: 'Your details',
  },
  FAMILY_DETAILS: {
    value: 'familyDetails',
    label: 'Family',
  },
  MEDICARE: {
    value: 'medicare',
    label: 'Medicare',
  },
  ADDITIONAL_DETAILS: {
    value: 'additionalDetails',
    label: 'Additional information',
  },
  PAYMENTS: {
    value: 'payments',
    label: 'Payment',
  },
};

export const AGE_CASE = {
  UNDER_21: 0,
  VALID_EFC: 1,
  VALID_NO_EFC: 2,
  OVER_32: 3,
  INVALID_AGE: 4,
};

export const MAX_ALLOWED_DEPENDANTS = 10;

export const DEBIT_TYPE = PAYMENT_TYPE;

export const LHC_STATEMENT = {
  continuouslySince31: 'continuouslySince31',
  continuouslyPast10Years: 'continuouslyPast10Years',
  notContinuouslySince31: 'notContinuouslySince31',
};

export const LHC_START_AGE = 30;
