import React from 'react';
import { wordyFormat } from '~lib/dates';
import { path } from 'lodash/fp';
import styled from '@emotion/styled';
import { StandardText } from '../atoms/typography';

const InlineBox = styled(StandardText)`
  display: inline;
`;

const PolicyPaidTo = ({ data, payments, dateProps, ...props }) => {
  return (
    <InlineBox {...props}>
      Policy paid to{' '}
      <InlineBox {...dateProps}>
        {wordyFormat(
          path('oms.payments.paidToDate')(data) || path('paidToDate')(payments)
        )}
      </InlineBox>
    </InlineBox>
  );
};

export default PolicyPaidTo;
