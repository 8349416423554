import React, { useContext } from 'react';
import useChangeCover from '~OMS/my-cover/change-cover/state/useChangeCover';

export const ChangeCoverContext = React.createContext({
  state: {},
  actions: {},
});

const { Provider } = ChangeCoverContext;

export const ChangeCoverProvider = ({ children, location }) => {
  const changeCover = useChangeCover({ location });
  return <Provider value={changeCover}>{children}</Provider>;
};

export const useChangeCoverContext = () => useContext(ChangeCoverContext);
