import React from 'react';
import styled from '@emotion/styled';
import { themePirmaryLight } from '~OMS/styles/colors';
import Icon from '~common/atoms/Icon';
import { useThemeUI } from 'theme-ui';

const ModalBox = styled.div`
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  background-color: ${themePirmaryLight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 12px 12px 32px;
  .close-button {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

// SSO Modal component
function Modal({ setToggleModal, children, width, height, closeButton }) {
  const { theme } = useThemeUI();
  return (
    <ModalBox width={width} height={height}>
      {closeButton || (
        <Icon
          className="close-button"
          name="circle-cross"
          fill={theme.colors.light}
          width="20px"
          height="20px"
          onClick={() => {
            setToggleModal(false);
          }}
        />
      )}
      {children}
    </ModalBox>
  );
}

export default Modal;
