import React from 'react';
import {
  DropDownControl,
  InputControl,
  PersonNameControl,
} from '~common/molecules/form-controls';
import { genderOptions } from '../../state/options';
import GenderCheckboxControl from '../../common/components/GenderCheckboxControl';
import CurrentHealthFundFields from '../../common/components/CurrentHealthFundFields';
import DobField from '../../common/components/DobField';
import useAppFormTitleOptions from '../../state/options/useAppFormTitleOptions';
import useAppFormRelationshipOptions from '../../state/options/useAppFormRelationshipOptions';
import { StandardText } from '~common/atoms/typography';
import { path } from 'lodash/fp';

const PartnersDetailsForm = ({
  values,
  handleChange,
  Field,
  youthDiscountPercentage,
  criteria,
  hasLhcApplied,
  lhcAppliedMessage,
}) => {
  const partnerTitlesOptions = useAppFormTitleOptions('memberPartner');
  const relationshipOptions = useAppFormRelationshipOptions('partner');
  return (
    <>
      <Field
        halfWidth
        name="title"
        label="Title"
        onChange={handleChange}
        placeholder="Select"
        component={DropDownControl}
        options={partnerTitlesOptions}
      />

      <Field
        name="firstName"
        label="First name"
        onChange={handleChange}
        component={PersonNameControl}
      />
      <Field
        name="middleName"
        label="Middle name (optional)"
        onChange={handleChange}
        component={PersonNameControl}
      />
      <Field
        name="lastName"
        label="Last name"
        onChange={handleChange}
        component={InputControl}
      />
      <Field
        name="email"
        label="Email address"
        onChange={handleChange}
        component={InputControl}
      />

      <Field
        name="mobileNumber"
        label="Mobile number"
        onChange={handleChange}
        component={InputControl}
      />

      <Field
        name="gender"
        label="Gender"
        onChange={handleChange}
        justifyContent="space-between"
        optionClassName="gender-option"
        component={GenderCheckboxControl}
        options={genderOptions}
      />

      <Field
        halfWidth
        name="relationship"
        label="Relationship"
        onChange={handleChange}
        placeholder="Select"
        component={DropDownControl}
        options={relationshipOptions}
      />

      {values.isTransferringFromAnotherFund && (
        <CurrentHealthFundFields
          Field={Field}
          onChange={handleChange}
          names={{
            fund: 'currentHealthFund',
            membershipNumber: 'membershipNumber',
          }}
        />
      )}

      <DobField
        label="Has your partner held hospital cover continuously since their 31st birthday OR have they held
hospital cover continuously for the last 10 years?"
        lhcMessage={
          hasLhcApplied &&
          path('partner.lhcExempt')(criteria) === false && (
            <>
              <StandardText mb={2}>{lhcAppliedMessage}</StandardText>
              {values.isTransferringFromAnotherFund && (
                <StandardText>
                  Your partner’s loading percentage will be confirmed once we
                  receive their transfer certificate from their current health
                  fund.
                </StandardText>
              )}
            </>
          )
        }
        values={values}
        isTransferringFromAnotherFund={values.isTransferringFromAnotherFund}
        showLhcFlow={values.showLhcFlow}
        youthDiscountPercentage={youthDiscountPercentage}
        Field={Field}
        handleChange={handleChange}
      />
    </>
  );
};

export default React.memo(PartnersDetailsForm);
