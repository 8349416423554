import React from 'react';
import FormTitle from '~OMS/styles/FormTitle';
import AlertBox, { Alert } from '~common/molecules/AlertBox';
import { Field, Formik } from 'formik';
import {
  CheckboxControl,
  InputControl,
  PasswordInputControl,
  validators,
} from '~common/molecules/form-controls';
import { get } from 'lodash';
import { Flex } from 'rebass';
import { Button } from '~common/atoms/Button';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import Link from '~common/atoms/Link';
import { useThemeUI } from 'theme-ui';
import { noop } from '~lib/util';
import { useMobileScreen } from '~lib/responsive';
import useBrowser from '~common/hooks/useBrowser';

const validation = Yup.object().shape({
  username: validators.email(),
  password: Yup.string().required('Password is required'),
});

const StyledPasswordWrapper = styled(Flex)`
  position: relative;
`;

const StyledForgotPasswordLink = styled(Link)`
  position: absolute;
  right: 0;
  font-size: ${props => props.theme.fontSizes[1]}px;
`;

const initialForm = {
  username: '',
  password: '',
  rememberUsername: true,
};

const ReturningMemberForm = ({
  alert,
  onSubmit,
  usernameRef,
  passwordRef,
  error,
  loading,
  getFieldValueSetter = noop,
  getErrorSetter = noop,
}) => {
  const { theme } = useThemeUI();
  const isMobile = useMobileScreen();
  const { getName } = useBrowser();
  const browserName = getName();
  const isSafari = browserName === 'Safari';

  return (
    <>
      <FormTitle>Returning Member</FormTitle>
      {alert && (
        <Alert mb={3} type={alert.type}>
          {alert.message}
        </Alert>
      )}
      <Formik
        validationSchema={validation}
        initialValues={initialForm}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, setFieldValue, setErrors }) => {
          getFieldValueSetter(setFieldValue);
          getErrorSetter(setErrors);
          return (
            <form data-id="returning-member-form" onSubmit={handleSubmit}>
              <Field
                component={InputControl}
                label="Enter your email address"
                name="username"
                id="username"
                innerRef={usernameRef}
                onBlur={() => {}}
                onFocus={
                  () => {} /* required in case field focus() gets set manually - avoid form validation on init */
                }
              />

              {browserName !== 'Safari' && (
                <Field
                  name="rememberUsername"
                  id="rememberUsername"
                  component={props => (
                    <CheckboxControl
                      label="Remember me"
                      labelPosition="right"
                      {...props}
                    />
                  )}
                />
              )}
              <StyledPasswordWrapper mt="30px" position="relative">
                <Field
                  component={PasswordInputControl}
                  name="password"
                  id="password"
                  label="Enter your password"
                  innerRef={passwordRef}
                  onBlur={() => {}}
                  onFocus={
                    () => {} /* required in case field focus() gets set manually - avoid form validation on init */
                  }
                />
                <StyledForgotPasswordLink
                  theme={theme}
                  to="/oms/sso/forgotPassword"
                >
                  Forgot Password?
                </StyledForgotPasswordLink>
              </StyledPasswordWrapper>

              {error && <AlertBox error={get(error, 'message')} />}

              <Button
                mt={{
                  tabletL: isSafari ? 54 : 0,
                }}
                showOnlyLoaderWhenActive={!isMobile}
                uppercase={false}
                naturalWidth={!isMobile}
                loading={loading}
                variant="primary"
                type="submit"
              >
                Login to my account
              </Button>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default ReturningMemberForm;
