import usePersonalDetails from './usePersonalDetails';
import { useEffect } from 'react';
import partnersDetailsValidation from '../validation/partnersDetailsValidation';
import { path } from 'lodash/fp';
import { isPartnerTransferringFromAnotherFund } from '../common/appFormUtills';
import { dropProp } from '~lib/util';

export default ({
  criteria,
  setCriteria,
  privateHealthDetailsForm,
  persistKey,
  hasLhcApplied,
}) => {
  const isTransferringFromAnotherFund = isPartnerTransferringFromAnotherFund(
    privateHealthDetailsForm
  );

  const partnerDetails = usePersonalDetails({
    persistKey,
    validate: partnersDetailsValidation,
    criteria,
    setCriteria,
    dob: criteria.partnerDob,
    dobName: 'partnerDob',
    isTransferringFromAnotherFund,
    hasLhcApplied,
    entryAge: path('partner.entryAge')(criteria),
  });

  useEffect(() => {
    partnerDetails.form.setForm({
      isTransferringFromAnotherFund,
      partnerAndDependentsCovered:
        privateHealthDetailsForm.values.partnerAndDependentsCovered,
    });
  }, [isTransferringFromAnotherFund]);

  useEffect(() => {
    const showLhcFlow =
      partnerDetails.showLhcFlow ||
      (privateHealthDetailsForm.values.partnerAndDependentsCovered &&
        partnerDetails.lhcEligible);
    if (showLhcFlow !== partnerDetails.form.values.showLhcFlow) {
      partnerDetails.form.setForm({
        showLhcFlow,
      });
    }
  }, [
    partnerDetails.lhcEligible,
    partnerDetails.showLhcFlow,
    privateHealthDetailsForm.values.partnerAndDependentsCovered,
  ]);

  return {
    ...partnerDetails,
    serialize: () => {
      const serialized = partnerDetails.serialize();
      return dropProp('partnerAndDependentsCovered', serialized);
    },
  };
};
