import React from 'react';
import { DropDownControl, InputControl } from '~common/molecules/form-controls';
import { StandardText } from '~common/atoms/typography';
import { Box } from 'rebass';
import useAppFormHealthFundOptions from '../../state/options/useAppFormHealthFundOptions';
import { useLocale } from '~common/locales';

const CurrentHealthFundFields = ({
  Field,
  onChange,
  names: { fund, membershipNumber } = {},
  ...props
}) => {
  const healthFundOptions = useAppFormHealthFundOptions();
  const { getLocaleString } = useLocale();
  const organisationName = getLocaleString('common.organisationName');

  return (
    <Box {...props}>
      <Field
        onChange={onChange}
        name={fund}
        label="Current health fund"
        component={DropDownControl}
        placeholder="Select"
        options={healthFundOptions}
      />

      <Field
        onChange={onChange}
        name={membershipNumber}
        label="Membership number"
        component={InputControl}
        trim
        maxChars={12}
      />

      <StandardText mb={30} fontSize={2}>
        By providing this information you authorise {organisationName} to cancel
        this current health fund policy on your behalf for yourself, partner and
        dependants where applicable. Any amounts paid in advance to your current
        health fund will be credited directly back to you.
      </StandardText>

      <StandardText fontSize={2} mb={40}>
        Please note, this only applies where you are transferring to the same
        cover type with {organisationName}. For example, if you join us on
        hospital cover, we can only cancel your hospital cover with your current
        health fund.
      </StandardText>
    </Box>
  );
};

export default CurrentHealthFundFields;
