import React from 'react';

import { FirstCol, SecondCol, ThirdCol, Row } from '../atoms/Layout';
import { StandardText } from '~common/atoms/typography';
import Link from '~common/atoms/Link';
import { Box, Flex } from 'rebass';
import { useMobileScreen } from '~lib/responsive';
import Divider from '~common/atoms/Divider';

const DeleteAccount = ({ props }) => {
  const isMobile = useMobileScreen();
  return (
    <Box {...props}>
      <Divider />
      <Row flexDirection="column" pb={0}>
        <StandardText fontSize={18} fontWeight={600}>
          Request OMS/App account deletion
        </StandardText>
      </Row>
      <Row>
        {!isMobile && <FirstCol>&nbsp;</FirstCol>}
        <SecondCol>
          <Flex className="full-width" flexDirection="column">
            <StandardText fontSize={14}>
              To delete your mobile app and Online Member Services accounts and
              login credentials, please complete the below form. We will process
              your request within 3 business days.
            </StandardText>
            <Link mt={3} to="/request-account-deletion?source=OMS" external>
              Request form
            </Link>
          </Flex>
        </SecondCol>
        <ThirdCol />
      </Row>
    </Box>
  );
};

export default DeleteAccount;
