import React from 'react';
import Layout from 'components/Layout';
import { withTheme } from 'emotion-theming';
import { path } from 'lodash/fp';

import Navbar from '~common/organisms/Navbar';
import Wrapper from '~common/molecules/Wrapper';
import MyDetails from '~OMS/my-details/templates/MyDetails';
import CheckMembership from '~common/organisms/CheckMembership';
import OmsFooter from '~OMS/OmsFooter';

const MyDetailsPage = props => {
  const expandMember = path('location.state.expandMember')(props);

  return (
    <Layout>
      <CheckMembership>
        <Navbar location={props.location} />
        <Wrapper pb={0}>
          <MyDetails expandMember={expandMember} />
        </Wrapper>
        <OmsFooter />
      </CheckMembership>
    </Layout>
  );
};

export default withTheme(MyDetailsPage);
