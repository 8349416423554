import { useCallback, useState } from 'react';

function useToggle(initialState = false) {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(
    forceState =>
      setState(state => {
        if (forceState !== false && forceState !== true && forceState != null) {
          console.warn(
            `You are passing ${forceState} to useToggle.toggle function. It is not boolean nor null nor undefined. Is this what you want ?`
          );
        }
        return typeof forceState !== 'undefined' ? forceState : !state;
      }),
    []
  );

  const show = useCallback(() => {
    setState(true);
  }, []);

  const hide = useCallback(() => {
    setState(false);
  });

  return [state, toggle, show, hide];
}

export default useToggle;
