import React from 'react';
import { Flex, Box } from 'rebass';
import styled from '@emotion/styled';
import Icon from '~common/atoms/Icon';
import { inclusionColors, tierInfo } from '~lib/inclusionConstants';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import { P1, P2, P3 } from '~common/atoms/typography';
import { FirstColumn, SecondColumn, ThirdColumn } from './Columns';
import useInclusionsTable from './useInclusionsTable';
import css from '@emotion/css';
import { useMobileScreenOnly } from '~lib/responsive';
import { useThemeUI } from 'theme-ui';

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid ${props => props.theme.colors.neutral50};
  background: ${props => props.theme.colors.light};
`;

const bodyTextCss = css`
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 1.3;
  &&& {
    padding: 0;
  }
`;

const Body = styled(SanityRichTextContent)`
  ${() => bodyTextCss};
  font-weight: 500;
  @media (max-width: 992px) {
    padding: 0 16px 15px ${props => (props.showIcon ? '0' : '16px')};
  }
  &&&& * {
    font-size: ${props => props.theme.fontSizes[2]}px;
    color: ${props => props.theme.colors.neutral500};
  }
  a {
    color: ${props => props.theme.colors.primary};
  }

  p {
    margin-bottom: 6px;
  }

  ul,
  ol {
    margin-top: 6px;
    padding-left: 24px;

    > li {
      margin-bottom: 2px;
      padding-left: 0;
    }
  }
`;

const InclusionIcon = styled(Icon)`
  align-self: flex-start;
`;

const BodyInlineColumn = ({
  label,
  value,
  border = false,
  width = '50%',
  ...props
}) => {
  const { theme } = useThemeUI();
  return (
    <Box
      css={css`
        width: ${width};
        ${border ? `border-right: 1px solid ${theme.colors.neutral50}` : ''};
      `}
      {...props}
    >
      <P3 variant="medium" mb="4px">
        {label}
      </P3>
      <P3 variant="semiBold">{value || '--'}</P3>
    </Box>
  );
};

const Row = styled.tr`
  background: ${props => (props.expanded ? props.theme.colors.light : '')};
`;

const InclusionsTable = ({ inclusions = [], type, productType }) => {
  const { theme } = useThemeUI();
  const { state, actions } = useInclusionsTable({ inclusions, type });
  const isMobile = useMobileScreenOnly();
  return (
    <StyledTable
      css={css`
        ${type ? `border-top: 2px solid ${inclusionColors[type]}` : null}
      `}
    >
      <tbody>
        {state.groupedInclusions.map((group, groupIndex) =>
          group.map((inclusion, index) => (
            <Row key={inclusion.id}>
              <FirstColumn
                onClick={() => {
                  actions.select(inclusion);
                }}
                layout={state.columnLayout}
              >
                <Flex alignItems="center">
                  <InclusionIcon
                    mr={{
                      xs: 12,
                      tabletP: 26,
                    }}
                    name={inclusion.inclusion.icon}
                    width="29px"
                    height="29px"
                    stroke={type ? inclusionColors[type] : theme.colors.primary}
                  />

                  <Box width={1}>
                    <Flex alignItems="center" justifyContent="space-between">
                      <Box width={9 / 10}>
                        {isMobile ? (
                          <P2 variant="medium" color="neutral900">
                            {inclusion.inclusion.title}
                          </P2>
                        ) : (
                          <P1 variant="medium" color="neutral900">
                            {inclusion.inclusion.title}
                          </P1>
                        )}

                        {isMobile &&
                          productType === 'extras' &&
                          (inclusion?.waitingPeriod ||
                            inclusion?.benefitsPaid) && (
                            <Flex mt="12px" flexDirection="column">
                              <BodyInlineColumn
                                width="100%"
                                label="Waiting period:"
                                value={inclusion?.waitingPeriod}
                              />
                              <BodyInlineColumn
                                width="100%"
                                mt={3}
                                label="Benefits paid:"
                                value={inclusion?.benefitsPaid}
                              />
                            </Flex>
                          )}
                      </Box>

                      <Flex alignItems="center">
                        {tierInfo[inclusion?.tier]?.image &&
                          type === 'included' && (
                            <Icon
                              name={tierInfo[inclusion?.tier]?.image}
                              originalColorSchema
                              height="24px"
                              alt="badge"
                              style={{ marginRight: '24px' }}
                            />
                          )}
                        {inclusion.body && (
                          <InclusionIcon
                            clickable
                            name={
                              state.expandId === inclusion.id
                                ? 'chevron-up'
                                : 'chevron-down'
                            }
                            stroke="#4a4a4a"
                            width="10px"
                            height="15px"
                          />
                        )}
                      </Flex>
                    </Flex>

                    {state.expandId === inclusion.id && (
                      <Box mt="27px">
                        {!isMobile && inclusion?.waitingPeriod && (
                          <Flex>
                            <P3
                              mb="8px"
                              mr="4px"
                              color="neutral500"
                              variant="medium"
                              css={bodyTextCss}
                            >
                              Waiting period:{' '}
                            </P3>
                            <P3 color="neutral500" variant="semiBold">
                              {inclusion?.waitingPeriod}
                            </P3>
                          </Flex>
                        )}
                        <Body content={inclusion.body} />
                      </Box>
                    )}
                  </Box>
                </Flex>
              </FirstColumn>
              {state.columnLayout === 'multiple' && (
                <>
                  {!isMobile && (
                    <SecondColumn>
                      <P3 color="neutral900" variant="semiBold">
                        Benefits paid
                      </P3>
                      <P3 color="neutral900" variant="semiBold">
                        {inclusion?.benefitsPaid}
                      </P3>
                    </SecondColumn>
                  )}
                  {(group.length > 1 ? index === 0 : true) && (
                    <ThirdColumn
                      isLast={
                        index === group.length - 1 &&
                        groupIndex === state.groupedInclusions.length - 1
                      }
                      bg={theme.colors.neutral50}
                      rowSpan={group.length}
                    >
                      <P3 variant="medium" color="neutral900">
                        Total limit
                      </P3>
                      <P3 variant="semiBold" color="neutral900">
                        {group.find(inclusion => !!inclusion?.limit)?.limit}
                      </P3>
                    </ThirdColumn>
                  )}
                </>
              )}
            </Row>
          ))
        )}
      </tbody>
    </StyledTable>
  );
};

export default InclusionsTable;
