import styled from '@emotion/styled';
import Icon from '../../../common/atoms/Icon';
import React from 'react';
import { useThemeUI } from 'theme-ui';

const StyledArrowUp = styled(Icon)`
  top: 26px;
`;

export default ({ className }) => {
  const { theme } = useThemeUI();
  return (
    <StyledArrowUp
      name="triangle"
      fill={theme.colors.background.light2}
      width="22px"
      height="22px"
      className={className}
    />
  );
};
