import React from 'react';
import { StandardText } from '../../../../common/atoms/typography';
import styled from '@emotion/styled';
import Popover from 'components/Elements/Popover';
import { useThemeUI } from 'theme-ui';

const dimensionStyle = propName => props => {
  if (typeof props[propName] === 'string') {
    return props[propName];
  }

  if (props[propName] < 1 && props[propName]) {
    return `${props[propName] * 100}%`;
  }

  return `${props.uiTheme.space[props[propName]] || props[propName]}px`;
};

const StyledPopover = styled(Popover)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 21px;
  width: ${dimensionStyle('width')};
  padding-right: ${dimensionStyle('pr')};
`;

const StyledTruncatedText = styled(StandardText)`
  display: inline;
`;
const TruncatedText = ({ children, width, pr, ...props }) => {
  const { theme } = useThemeUI();

  return (
    <StyledPopover
      position="top"
      uiTheme={theme}
      theme="dark"
      width={width}
      pr={pr}
      content={children}
    >
      <StyledTruncatedText {...props}>{children}</StyledTruncatedText>
    </StyledPopover>
  );
};

export default TruncatedText;
