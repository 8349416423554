import React from 'react';
import { P3 } from '../atoms/typography';
import { currency } from '~lib/filters';
import { PAYMENT_FREQUENCY_LABELS } from '~OMS/payments/constants';
import { chainedPath } from '~lib/util';

const PremiumInfo = ({ data, ...props }) => {
  const payments = chainedPath('oms.payments')(data);
  const paymentFrequencyLabel = (
    PAYMENT_FREQUENCY_LABELS[payments('frequency')] || ''
  ).toLowerCase();
  return (
    <P3 {...props}>
      Your {paymentFrequencyLabel} payment is{' '}
      <P3 inline variant="semiBold" color="primary">
        {currency(payments('amount'))}
      </P3>
    </P3>
  );
};

export default PremiumInfo;
