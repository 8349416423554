import React from 'react';
import { Field } from 'formik';
import AppFormLabel from '~common/molecules/FormLabel';

const FormField = ({ label, onChange, name, render, ...props }) => {
  const onChangeProxy = evt => {
    if (onChange) {
      onChange(
        evt.target
          ? evt
          : {
              target: {
                name,
                value: evt,
              },
            }
      );
    }
  };

  let wrappedLabel;

  if (label) {
    wrappedLabel =
      typeof label === 'function' ? (
        label({ LabelContainer: AppFormLabel })
      ) : (
        <AppFormLabel>{label}</AppFormLabel>
      );
  }

  let overridenRender;
  if (render) {
    overridenRender = renderProps => {
      return render({
        name,
        onChange: onChangeProxy,
        ...renderProps,
        label: wrappedLabel || <AppFormLabel>{renderProps.label}</AppFormLabel>,
      });
    };
  }

  return (
    <Field
      onChange={onChangeProxy}
      name={name}
      label={wrappedLabel}
      render={overridenRender}
      {...props}
    />
  );
};

export default FormField;
