import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';
import { Box } from 'rebass';
import Eyebrow from '~common/atoms/Eyebrow';
import { ContactUsContext } from '~OMS/contact-us/ContactUsContext';
import CallUsNumber from '~common/molecules/CallUsNumber';
import { Button } from '~common/atoms/Button';

const Container = styled.div`
  color: ${props => props.colors.secondary};
  font-weight: bold;
`;

const EyebrowMDHidden = styled.div`
  ${props => props.mq.md} {
    display: none;
  }
  ${props => props.mq.lg} {
    display: block;
  }
`;

const DashboardContact = props => {
  const { openEmailUs, openRequestCallback } = useContext(ContactUsContext);

  return (
    <>
      <EyebrowMDHidden {...props.theme}>
        <Eyebrow>Contact Us</Eyebrow>
      </EyebrowMDHidden>
      <Container {...props.theme}>
        <Box>
          <Button
            icon="oms-phone-outline"
            variant="primaryInverted"
            size="small"
            mt={{ xs: 10, md: 0, lg: 10 }}
            width={1}
          >
            Call <CallUsNumber rColor="primary" ml={1} inline />
          </Button>
          <Button
            icon="request-callback"
            mt={{ xs: 10, md: 0, lg: 10 }}
            width={1}
            size="small"
            variant="primaryInverted"
            data-id="contact-card-request-callback"
            onClick={openRequestCallback}
          >
            Request a Callback
          </Button>

          <Button
            icon="oms-letter-outline"
            mt={{ xs: 10, md: 0, lg: 10 }}
            width={1}
            variant="primaryInverted"
            size="small"
            data-id="contact-card-email-us"
            onClick={openEmailUs}
          >
            Email us
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default withTheme(DashboardContact);
