import { useEffect, useMemo } from 'react';
import {
  toDate,
  formatToISO8601DateOnly,
  fromNativeDate,
  getAge,
} from '~lib/dates';
import { calculateYouthDiscountPercentage } from '../common/youthDiscountSchema';
import useForm from '~lib/hooks/useForm';
import { getDobDate, is31OrUnder } from '../common/appFormUtills';
import { dropProps } from '~lib/util';
import { LHC_START_AGE } from '../constants';

const isLhcEligible = ({ hospitalProduct, dob }) => {
  if (!hospitalProduct || !dob) {
    return false;
  }

  return !is31OrUnder(dob);
};

const deserializeLhcDetails = data => {
  const { firstHospitalCoverAge, lhcStatement, isTransferringFromAnotherFund } =
    data;

  let { entryAge, lhcExempt } = data;
  entryAge =
    entryAge ||
    (isTransferringFromAnotherFund ? firstHospitalCoverAge : undefined);
  lhcExempt = lhcExempt ?? lhcStatement;
  if (entryAge) {
    return {
      firstHospitalCoverAge: entryAge,
      lhcStatement: !!lhcStatement,
    };
  }

  if (lhcExempt) {
    return {
      lhcStatement: lhcExempt,
    };
  }

  if (isLhcEligible(data)) {
    return {
      lhcEligible: true,
      lhcStatement: false,
    };
  }

  return undefined;
};

export default ({
  isTransferringFromAnotherFund,
  criteria,
  setCriteria,
  dob,
  dobName = 'yourDob',
  validate,
  persistKey,
  hasLhcApplied,
  entryAge,
}) => {
  const onDobChange = (value, prevValue, formikForm) => {
    if (
      !formikForm.errors.dobDay &&
      !formikForm.errors.dobMonth &&
      !formikForm.errors.dobYear
    ) {
      // qq results seems to need both 'yourDob' and 'dob' to function properly
      // This ensures if yourDob is changed, dob is updated as well, while leaving
      // partnerDob unaffected
      const newDobValue = formatToISO8601DateOnly(getDobDate(formikForm));
      const newDobAge = getAge(fromNativeDate(newDobValue));
      setCriteria(
        {
          ...criteria,
          ...(dobName !== 'partnerDob' && {
            dob: {
              ...dob,
              value: newDobValue,
              age: newDobAge,
            },
          }),
          [dobName]: {
            ...dob,
            value: newDobValue,
            age: newDobAge,
          },
        },
        {
          notifyPriceChange: true,
        }
      );
    }
  };

  const youthDiscountPercentage = useMemo(() => {
    if (!criteria.hospitalProduct || !dob) {
      return 0;
    }

    return calculateYouthDiscountPercentage(dob.value);
  }, [dob, criteria.hospitalProduct]);

  const lhcEligible = useMemo(() => {
    return isLhcEligible({
      hospitalProduct: criteria.hospitalProduct,
      dob: dob && dob.value,
    });
  }, [dob, criteria.hospitalProduct]);

  const showLhcFlow = lhcEligible && isTransferringFromAnotherFund;

  const form = useForm(
    {},
    {
      onChange: {
        dobDay: onDobChange,
        dobMonth: onDobChange,
        dobYear: onDobChange,
      },
      validate: validate(),
      persistKey,
    }
  );

  useEffect(() => {
    if (form.values.showLhcFlow !== showLhcFlow) {
      form.setForm({
        showLhcFlow,
      });
    }
  }, [showLhcFlow]);

  useEffect(() => {
    if (!dob) {
      return;
    }
    const criteriaDob = toDate(dob.value);
    if (!criteriaDob.isSame(getDobDate(form))) {
      form.setForm({
        dobYear: Number(criteriaDob.year()),
        dobMonth: criteriaDob.month() + 1,
        dobDay: criteriaDob.date(),
      });
    }
  }, [dob]);

  useEffect(() => {
    form.setForm({
      lhcEligible,
    });
  }, [lhcEligible]);

  const serialize = () => {
    const validValues = form.validValues;
    const propsToDrop = [];
    if (!validValues.lhcEligible || !validValues.showLhcFlow) {
      propsToDrop.push(...['lhcStatement', 'firstHospitalCoverAge']);
    } else if (validValues.lhcStatement) {
      propsToDrop.push('firstHospitalCoverAge');
    }

    if (!isTransferringFromAnotherFund) {
      propsToDrop.push(...['currentHealthFund', 'membershipNumber']);
    }

    return dropProps(propsToDrop, validValues);
  };

  const deserialize = (data, options) => {
    const deserializedForm = {
      ...data,
      lhcEligible: isLhcEligible({
        hospitalProduct: options.hospitalProduct,
        dob: options.dob,
      }),
      ...deserializeLhcDetails({
        ...data,
        ...options,
      }),
    };

    form.setForm(deserializedForm, { merge: false });
  };

  return {
    youthDiscountPercentage,
    lhcEligible,
    showLhcFlow,
    form,
    serialize,
    deserialize,
    hasLhcApplied:
      hasLhcApplied && (entryAge != null ? entryAge > LHC_START_AGE : true),
  };
};
