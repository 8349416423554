import {
  FormValidator,
  required,
  defined,
  length,
  maxLength,
} from '~lib/formValidation';

const acceptTerms = required('Please accept this before we submit the form');

export default ({
  showAvantQuestions,
  partnerOrganisationsOptions,
  howDidYouHearForUsOptions,
}) =>
  FormValidator({
    validation: {
      isNominatePerson: defined('Please answer this question'),
      nominatePersonFullName: required(
        'Nominate person full name is a required field'
      ),
      nominatePersonRelationship: required(
        'Nominate person relationship is a required field'
      ),
      isAvantGroupMember: defined('Please answer this question'),
      avantMemberNumber: [
        required('Member number is a required field'),
        maxLength(9, 'Member number should have a maximum of 9 characters'),
      ],
      isPartnerAvantGroupMember: defined('Please answer this question'),
      partnerAvantMemberNumber: [
        required('Member number is a required field'),
        maxLength(9, 'Member number should have a maximum of 9 characters'),
      ],
      howDidYouHearAboutUs: required('Please select one or more items'),
      getInTouch: defined('Please answer this question'),
      acceptTermsAndConditions: acceptTerms,
      agreeUsingPersonalInformation: acceptTerms,
      acceptAHSA: acceptTerms,
      confirmApplicationIsTrue: acceptTerms,
      memberReferralCode: [
        value => {
          if (!value) {
            return;
          }

          if (!value.startsWith('MGM') && value.length >= 3) {
            throw ['Invalid referral code'];
          }

          length(9, 'Referral code has exactly 9 characters')(value);
        },
      ],
    },
    transformValidation: {
      nominatePersonFullName: form => form.isNominatePerson,
      nominatePersonRelationship: form => form.isNominatePerson,
      isAvantGroupMember: () => showAvantQuestions,
      avantMemberNumber: form => showAvantQuestions && form.isAvantGroupMember,
      isPartnerAvantGroupMember: form => {
        return showAvantQuestions && form.hasPartner;
      },
      partnerAvantMemberNumber: form =>
        showAvantQuestions && form.isPartnerAvantGroupMember,
      howDidYouHearAboutUs: () => !!howDidYouHearForUsOptions?.length,
      partnerOrganisationMember: () => !!partnerOrganisationsOptions?.length,
    },
  });
