import React from 'react';
import styled from '@emotion/styled';
import SanityImage from '../../common/molecules/SanityImage';
import Block from '../../common/atoms/Block';

const StyledHeaderImage = styled(SanityImage)`
  &&&& {
    height: 350px;
    width: 100%;
    object-fit: cover;
  }
`;

const HeaderImage = ({ data: { image }, index, ...props }) => {
  return (
    <Block
      redefineMb={
        index === 0
          ? {
              lg: 50,
            }
          : undefined
      }
      ignoreCampaignLayout
      index={index}
      {...props}
    >
      <StyledHeaderImage
        transform={transformer => transformer.height(350)}
        useHotspot={false}
        src={image}
      />
    </Block>
  );
};

export default HeaderImage;
