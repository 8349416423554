import { useMemo } from 'react';

import { sortBy } from 'lodash';

const useProductsQuery = (type, productSlug, rawProducts) => {
  const products = useMemo(() => {
    return (
      rawProducts && {
        hospital: sortBy(
          rawProducts?.filter(h => h.content.type === 'hospital'),
          'content.sortOrder'
        ),
        extras: sortBy(
          rawProducts?.filter(e => e.content.type === 'extras'),
          'content.sortOrder'
        ),
      }
    );
  }, [rawProducts]);

  if (!productSlug && !type) {
    return products;
  }

  if (type && !productSlug) {
    return products?.[type] || [];
  }

  const typeRelatedProducts = products?.[type];

  return {
    all: products?.hospital?.concat(products?.extras),
    products: typeRelatedProducts,
    product: typeRelatedProducts?.find(
      p => p.content.slug.current === productSlug
    ),
  };
};

export default useProductsQuery;
