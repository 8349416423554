import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '~common/atoms/Button';
import { StandardText } from '../../../../common/atoms/typography';
import { Row, FirstCol, SecondCol, ThirdCol } from '../atoms/Layout';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

const buttonConfig = {
  variant: 'secondaryInverted',
  width: {
    xs: 1,
    md: 200,
  },
};

const StyledDescription = styled(StandardText)`
  &&&&& .text-block {
    p,
    a {
      font-size: ${props => props.theme.fontSizes[2]}px;
      font-weight: 400;
      line-height: 18px;
      color: ${props => props.theme.colors.neutral800};
    }
  }
`;

const ActionRow = ({ title, description, button, ...props }) => (
  <Row alignItems="center" justifyContent="space-between" {...props}>
    {title && (
      <FirstCol>
        <StandardText fontSize={18} fontWeight={600}>
          {title}
        </StandardText>
      </FirstCol>
    )}
    <SecondCol>
      <StyledDescription>{description}</StyledDescription>
    </SecondCol>
    <ThirdCol>
      {button.onClick && (
        <Button
          {...buttonConfig}
          loading={button.loading}
          onClick={button.onClick}
        >
          {button.label}
        </Button>
      )}
      {button.link && (
        /* Link triggers react warning: https://github.com/gatsbyjs/gatsby/issues/11362 */
        <Button
          loading={button.loading}
          to={button.link}
          as={Link}
          {...buttonConfig}
        >
          {button.label}
        </Button>
      )}
    </ThirdCol>
  </Row>
);

ActionRow.propTypes = {
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    link: PropTypes.string,
  }).isRequired,
  description: PropTypes.any,
  title: PropTypes.any,
};

export default ActionRow;
