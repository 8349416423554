import { Row } from '~OMS/claims/atoms/ClaimsLayout';
import UploadIcon from '~OMS/claims/atoms/UploadIcon';
import { Box } from 'rebass';
import { StandardText } from '~common/atoms/typography';
import { Button } from '~common/atoms/Button';
import React from 'react';
import { MAX_BATCH_SIZE } from '~OMS/claims/config';

const HorizontalUploader = ({
  theme,
  onClick,
  accept,
  disableAddMore,
  ...props
}) => (
  <Row flexDirection="row" alignItems="flex-start" {...props}>
    <UploadIcon theme={theme} mr={3} />
    <Box width={310} mr={3}>
      <StandardText textAlign="left" color="light2" mb={2}>
        Drag and drop files or click here to upload.
      </StandardText>
      <StandardText textAlign="left" fontSize={1} color="light2">
        Maximum size of files: {MAX_BATCH_SIZE}MB. File types accepted:{' '}
        {accept.join(' ')}
      </StandardText>
    </Box>
    <Button
      disabled={disableAddMore}
      onClick={onClick}
      width={208}
      variant="secondaryInverted"
    >
      Attach receipt
    </Button>
  </Row>
);

export default HorizontalUploader;
