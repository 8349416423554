import React, { useEffect } from 'react';
import { withTheme } from 'emotion-theming';
import LightBox from '~common/atoms/LightBox';
import { days } from '~OMS/mock_data/order-card';
import ModalConfirmation from '~common/molecules/ModalConfirmation';
import OrderCardForm from '../molecules/OrderCardForm';
import { useOrderCard } from '../hooks/useOrderCard';
import UpdateAddressForm from '~common/organisms/UpdateAddressForm';
import { path } from 'lodash/fp';
import { parseQueryParams } from '~lib/util';
import { Button } from '~common/atoms/Button';

const OrderCard = ({ theme, location }) => {
  const {
    address,
    orderCard,
    selectedPersons,
    selectedPersonsIds,
    selectPerson,
    showConfirmationPage,
    isOrderCardModalShown,
    closeModal,
    openModal,
    personButtons,
    loading,
    toggleOrderCardModal,
    alert,
  } = useOrderCard(location);

  const params = parseQueryParams(location.search.slice(1));

  useEffect(() => {
    if (params) {
      if (params.show === 'card') {
        toggleOrderCardModal(true);
        location.search = '';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <>
      <Button
        size="medium"
        variant="secondary"
        onClick={openModal}
        width={{
          xs: 1,
          tabletP: 'auto',
        }}
      >
        Order New Card
      </Button>

      <LightBox
        centerV={showConfirmationPage}
        centerH
        visible={isOrderCardModalShown}
        onClose={closeModal}
      >
        {!showConfirmationPage && (
          <OrderCardForm
            loading={loading}
            alert={alert}
            onUpdatedAddressClick={address.openAutoMode}
            addressUpdated={address.updated}
            address={address.current.membershipAddress}
            addressLine={address.current.membershipAddressLine}
            buttons={personButtons}
            confirmationDisabled={!path('length')(selectedPersons)}
            selectedPersons={selectedPersonsIds}
            onSubmit={orderCard}
            onPersonChange={selectPerson}
            theme={theme}
            addressMode={address.mode}
            updateAddressForm={
              <UpdateAddressForm
                showTitle={false}
                mode={address.mode}
                loading={address.updating}
                onSubmit={(data, form) =>
                  address.updateAddressWithStateChangeModal(data, form, 'card')
                }
                onCancel={address.closeUpdateMode}
                postalAddress={address.current.postalAddress}
                address={address.current.membershipAddress}
                postalAddressLine={address.current.postalAddressLine}
                addressLine={address.current.membershipAddressLine}
                onToggleMode={address.toggleUpdateMode}
                isPostalDifferentAsResidential={
                  address.current.postalAddressLine !==
                  address.current.membershipAddressLine
                }
              />
            }
          />
        )}

        {showConfirmationPage && (
          <ModalConfirmation
            onConfirm={closeModal}
            title="It's on its way!"
            message={`You will receive a new card in approximately ${days} business days.`}
          />
        )}
      </LightBox>
    </>
  );
};

export default withTheme(OrderCard);
