import { searchAddress, repairAddress } from '~common/services/kleberService';
import { getAltAddress as addressify } from '~lib/util';

const debounce = (fn, delay) => {
  let pid;
  return (...args) =>
    new Promise((resolve, reject) => {
      clearTimeout(pid);
      pid = setTimeout(
        () =>
          fn(...args)
            .then(resolve)
            .catch(reject),
        delay
      );
    });
};

const UP_ARROW = 38;
const DOWN_ARROW = 40;
const RETURN = 13;
export const keyUpHandler = (nextAction, prevAction, select) => e => {
  const keyCode = e.charCode || e.keyCode;
  if (keyCode === UP_ARROW) {
    prevAction();
  } else if (keyCode === DOWN_ARROW) {
    nextAction();
  } else if (keyCode === RETURN) {
    e.preventDefault();
    select();
  }
};
export const keyDownHandler = () => e => {
  const keyCode = e.charCode || e.keyCode;
  if (keyCode === RETURN) {
    e.preventDefault();
  }
};

export const getAddressOptions = () =>
  debounce(query => searchAddress(query), 300);

export const validateAddress = a =>
  repairAddress(a) //
    .then(result => {
      const firstItem = result[0];
      const { MatchType } = firstItem;
      if (MatchType === '0') {
        return addressify(firstItem);
      } else {
        throw firstItem;
      }
    });

export const invalidAddressHandler = handler => e => {
  const { MatchTypeDescription, MatchType } = e;
  if (MatchType && MatchTypeDescription) {
    handler({
      ...addressify(e),
      errorCode: MatchType,
      errorDescription: MatchTypeDescription,
    });
  }
};
