import React from 'react';
import { InputControl } from '~common/molecules/form-controls';
import { Box } from 'rebass';
import AdditionalInfo, {
  AdditionalInfoArrow,
} from '~common/atoms/AdditionalInfo';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import styled from '@emotion/styled';
import Loading from '~common/atoms/Loading';

const MessageBox = styled(Box)`
  transform: translateY(-40px);
  .info-arrow {
    left: 50%;
    transform: translateX(-50%);
  }

  .info-box {
    transform: translateY(0px);
  }
`;

export default ({ handleChange, formValues, Field, promoCodeValidation }) => {
  return (
    <Box>
      <Field
        name="exclusivePromotionCode"
        label="Promotion code (if applicable)"
        onChange={handleChange}
        component={InputControl}
      />

      {formValues?.exclusivePromotionCode && (
        <MessageBox>
          <AdditionalInfoArrow className="info-arrow" />
          <AdditionalInfo className="info-box">
            {promoCodeValidation ? (
              <>
                {promoCodeValidation?.isValid ? (
                  <SanityRichTextContent
                    content={promoCodeValidation?.validationMessage}
                  />
                ) : (
                  'The entered promo code is not valid'
                )}
              </>
            ) : (
              <Loading />
            )}
          </AdditionalInfo>
        </MessageBox>
      )}
    </Box>
  );
};
