import React from 'react';
import Footer from '~common/organisms/Footer';
import {
  ApplicationFormProvider,
  useApplicationFormContext,
} from './state/ApplicationFormProvider';
import EligibilityHeroBanner from './steps/Eligibility/EligibilityHeroBanner';
import { APPLICATION_FORM_STEPS } from './constants';
import QuoteSummary from './QuoteSummary/QuoteSummary';
import ActiveStep from './steps/ActiveStep';
import { path } from 'lodash/fp';
import { ModalErrorBoundary } from '~common/molecules/ModalErrorBoundary';
import { css, Global } from '@emotion/core';
import AFBlock from './AFBlock';
import { RatesToggleExtended } from '~lib/CoverProducts';
import { compareDateOnly } from '~lib/dates';
import PageBanner from './PageBanner';

const EligibilityHeroBannerWrapper = ({ appFormContext }) => {
  const { state, stepProvider } = appFormContext;
  const eligibilityContext = stepProvider.get(
    APPLICATION_FORM_STEPS.ELIGIBILITY.value
  ).context;
  return (
    state.activeStep.value === APPLICATION_FORM_STEPS.ELIGIBILITY.value &&
    path('form.values.australianCitizen', eligibilityContext) == null &&
    !state.applicationSent && <EligibilityHeroBanner />
  );
};

const ApplicationFormContent = () => {
  const appFormContext = useApplicationFormContext();
  const { state, actions } = appFormContext;

  return (
    <>
      <ModalErrorBoundary>
        <PageBanner />
      </ModalErrorBoundary>
      <ModalErrorBoundary>
        <EligibilityHeroBannerWrapper appFormContext={appFormContext} />
      </ModalErrorBoundary>
      {!state.applicationSent && (
        <ModalErrorBoundary>
          <QuoteSummary mt="2px" />
          {compareDateOnly(
            state.criteria?.startCoverDate || new Date(),
            process.env.DHF_RATES_SWITCH_DATE
          ) === -1 ? (
            <RatesToggleExtended
              selected={state.criteria?.resultType}
              onBefore={() => actions.toggleRates('CURRENT')}
              onAfter={() => actions.toggleRates('FUTURE')}
              px={{
                xs: 16,
                tabletP: 32,
                tabletL: 64,
                lg: 200,
                xl: 239,
              }}
              mb={0}
            />
          ) : null}
        </ModalErrorBoundary>
      )}
      <ActiveStep />
      <ModalErrorBoundary>
        <Footer as={AFBlock} />
      </ModalErrorBoundary>
    </>
  );
};

const appFormGlobalStyles = css``;

const ApplicationForm = ({ location }) => {
  return (
    <ApplicationFormProvider location={location}>
      <Global styles={appFormGlobalStyles} />
      <ApplicationFormContent />
    </ApplicationFormProvider>
  );
};

export default ApplicationForm;
