import React from 'react';
import styled from '@emotion/styled';
import { StandardText } from '~common/atoms/typography';
import Block from '~common/atoms/Block';

const StyledQuote = styled(StandardText)`
  padding: 0px 40px;
  display: block;
  border-left: 5px solid ${props => props.theme.colors.primary};
  font-stretch: normal;
  letter-spacing: normal;
`;

export const PullQuoteBase = ({ children, ...props }) => {
  return (
    <StyledQuote as="q" fontSize={12} rColor="primary" {...props}>
      {children}
    </StyledQuote>
  );
};

const PullQuote = ({ data: { text } }) => {
  return (
    <Block
      redefineMb={{
        xs: 50,
        tabletP: 60,
        lg: 60,
      }}
    >
      <PullQuoteBase>{text}</PullQuoteBase>
    </Block>
  );
};

export default PullQuote;
