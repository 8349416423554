import React from 'react';
import min from 'lodash/min';
import max from 'lodash/max';
import styled from '@emotion/styled';

import { Flex, Heading, Text } from 'rebass';
import { css } from '@emotion/core';
import { useThemeUI } from 'theme-ui';
import Icon from '~common/atoms/Icon';
import { youthDiscountSchema } from '~maf/common/youthDiscountSchema';

import AlertQuestion from '../../AlertQuestion';
import DateOfBirth from '~common/molecules/DateOfBirth';

const StyledDateOfBirth = styled(DateOfBirth)`
  .dob-fields-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 15px;
  }

  .form-control {
    width: 100%;
  }

  .dob-field-wrapper {
    margin: 0;
  }
`;

const StyledAlertQuestion = styled(AlertQuestion)`
  .quote-alert {
    top: -33px;
  }
`;

const DobQuestion = ({
  dateRef,
  title,
  age,
  handleChange,
  hide,
  subtitle,
  discountMessage,
  inputName = 'dob',
  confirmButtonId,
  ...props
}) => {
  const ageRanges = [...youthDiscountSchema].map(row => row[0]).flat();
  const ageMin = min(ageRanges);
  const ageMax = max(ageRanges);
  const { theme } = useThemeUI();
  return (
    <StyledAlertQuestion
      alertPosition="calc(100% - 60px)"
      title={title}
      subtitle={subtitle}
      alert={
        age >= ageMin &&
        age < ageMax && (
          <>
            <Icon
              name="tada"
              width="60px"
              height="60px"
              stroke={theme.colors.primary}
            />
            <Flex
              px={{ xs: 2, md: 3 }}
              ml={1}
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Heading
                color="text.dark"
                fontSize="18px"
                fontWeight="500"
                lineHeight="0.87"
                mb="14px"
              >
                Congratulations!
              </Heading>

              <Text
                color="text.dark"
                fontSize="12px"
                lineHeight={1.2}
                mb="14px"
              >
                {discountMessage}
              </Text>
              <a
                href="/youth-discount"
                target="_blank"
                css={css`
                  color: ${theme.colors.primary};
                  font-size: 12px;
                  line-height: 1.2;
                `}
              >
                What's the youth discount?
              </a>
            </Flex>
          </>
        )
      }
      hide={hide}
      {...props}
    >
      <StyledDateOfBirth
        label={null}
        dayField={{
          name: `${inputName}Day`,
          label: <strong>Day</strong>,
        }}
        monthField={{
          name: `${inputName}Month`,
          label: <strong>Month</strong>,
        }}
        yearField={{
          name: `${inputName}Year`,
          label: <strong>Year</strong>,
        }}
      />
    </StyledAlertQuestion>
  );
};

export default DobQuestion;
