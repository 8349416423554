import React from 'react';
import Layout from 'components/Layout';
import ResumeApplicationForm from '../components/ApplicationForm/ResumeApplicationForm';
import withQueryParams from '~lib/hocs/withQueryParams';

const ResumeApplicationFormPage = ({ queryParams }) => {
  return (
    <Layout>
      <ResumeApplicationForm queryParams={queryParams} />
    </Layout>
  );
};

export default withQueryParams(ResumeApplicationFormPage);
