import React from 'react';
import Block from '~common/atoms/Block';
import { P3 } from '~common/atoms/typography';
import { Flex } from 'rebass';
import Icon from '~common/atoms/Icon';
import styled from '@emotion/styled';
import { useThemeUI } from 'theme-ui';
import Heading from './Heading';
import { rewriteSanityUrl } from '~lib/sanity/sanityUtils';

const DownloadLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  margin-bottom: ${props => (props.last ? 0 : 10)}px;
`;

const Downloads = ({ data: { files, title }, next = {}, ...props }) => {
  const { theme } = useThemeUI();
  return (
    <Block
      redefineMb={
        next._type === 'downloadsComponent'
          ? {
              lg: 50,
            }
          : undefined
      }
      {...props}
    >
      <Heading data={{ text: title, level: 'h3' }} textAlign="left" mb={20} />
      <Flex flexDirection="column">
        {(files || []).map((file, index) => {
          const downloadFile = file.file || {};
          const asset = downloadFile?.file?.asset;
          const title = downloadFile.title || asset?.originalFilename;
          return (
            <DownloadLink
              key={file._key}
              download={title}
              last={index === files.length - 1}
              href={rewriteSanityUrl(asset?.url)}
              target="_blank"
            >
              <Icon
                name="download"
                fill={theme.colors.primary}
                clickable
                width="16px"
              />
              <P3 variant="semiBold" ml={2} color="primary">
                {title}
              </P3>
            </DownloadLink>
          );
        })}
      </Flex>
    </Block>
  );
};

export default Downloads;
