import React from 'react';
import { Flex, Box } from 'rebass';
import CenterBox from '~common/atoms/CenterBox';
import Icon from '~common/atoms/Icon';
import { useThemeUI } from 'theme-ui';
import { StandardText } from '~common/atoms/typography';
import styled from '@emotion/styled';

const AddIcon = ({ disabled }) => {
  const { theme } = useThemeUI();
  return (
    <CenterBox
      css={{
        height: '28px',
        width: '28px',
        borderRadius: '50%',
        transition: 'all 300ms ease-in-out',
        background: disabled ? theme.colors.borders : theme.colors.secondary,
      }}
    >
      <Icon
        clickable={!disabled}
        name="oms-plus"
        fill={theme.colors.white}
        width="15px"
        height="15px"
      />
    </CenterBox>
  );
};

const AddDependantContainer = styled(Box)`
  border: 2px solid ${props => props.theme.colors.borders};
  padding: 10px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const AddDependant = ({ onClick, limit, limitReached }) => {
  return (
    <AddDependantContainer
      disabled={limitReached}
      onClick={() => {
        if (!limitReached) {
          onClick();
        }
      }}
    >
      <Flex alignItems="center">
        <AddIcon disabled={limitReached} />
        <StandardText
          color={limitReached ? 'light3' : 'dark'}
          ml={10}
          fontSize={2}
        >
          {limitReached
            ? `Max ${limit} dependants is allowed`
            : 'Add a dependant'}
        </StandardText>
      </Flex>
    </AddDependantContainer>
  );
};

export default React.memo(AddDependant);
