import React from 'react';
import { Box } from 'rebass';

const MyDocumentsRow = ({ children, paddedOnMobile = true, ...props }) => {
  const mobilePadding = paddedOnMobile ? 20 : 0;
  return (
    <Box
      {...props}
      pl={{ xs: mobilePadding, md: 30, lg: 60 }}
      pr={{ xs: mobilePadding, md: 30, lg: 60 }}
    >
      {children}
    </Box>
  );
};

export default MyDocumentsRow;
