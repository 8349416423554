import React, { useCallback, useMemo, useRef } from 'react';
import { Box } from 'rebass';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import { StandardText } from '../../../../../common/atoms/typography';
import FileItem from '~OMS/claims/molecules/FileItem';
import { Row } from '../../atoms/ClaimsLayout';
import { fileSize } from '~lib/filters';
import VerticalUploader from '~OMS/claims/organisms/AttachReceipt/VerticalUploader';
import HorizontalUploader from '~OMS/claims/organisms/AttachReceipt/HorizontalUploader';
import { css } from '@emotion/core';
import ClaimSubmitSuccess from '~OMS/claims/atoms/ClaimSubmitSuccess';
import { useMobileScreen } from '~lib/responsive';
import Loading from '~common/atoms/Loading';
import Dragger from 'antd/es/upload/Dragger';
import 'antd/es/upload/style/index.css';

const orangeColor = props => props.theme.colors.primary;
const borderStyle = props => css`
  border: 2px dashed ${props.theme.colors.borders};
`;
const sidePadding = props => props.theme.space[3] + 'px';
const minHeight = 388;

const isAlternateView = props => props.showSuccess || props.showLoader;
const StyledDragger = styled(Box)`
  ${props =>
    isAlternateView(props)
      ? `
      display: flex;
    flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: ${minHeight}px;

  `
      : ''};
  ${props => (isAlternateView(props) ? borderStyle(props) : '')};
  .ant-upload.ant-upload-drag {
    padding-left: ${sidePadding};
    padding-right: ${sidePadding};
    min-height: ${minHeight}px;
    padding-top: ${props => props.theme.space[4]}px;
    background: transparent;
    ${borderStyle};
    cursor: default;
    &:hover {
      border-color: ${orangeColor};
    }
    &.ant-upload-drag-hover {
      border-color: ${orangeColor};
    }

    .ant-upload.ant-upload-btn {
      //width: 50%;
    }

    .ant-upload-drag-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

const render = ({
  files = [],
  onChange,
  onRemove,
  theme,
  accept = [],
  alert,
  loading,
  showSuccess,
  totalFileSizeFormatted,
  showLoader,
  isMobile,
  openFileSelector,
}) => {
  if (showSuccess) {
    return <ClaimSubmitSuccess width={1 / 2} theme={theme} />;
  }

  if (showLoader) {
    return <Loading />;
  }

  return (
    <Dragger
      openFileDialogOnClick={false}
      multiple={true}
      fileList={files}
      accept={accept.join(',')}
      beforeUpload={(file, fileList) => {
        onChange(file, fileList);
        return false;
      }}
      showUploadList={false}
    >
      {!files.length || isMobile ? (
        <VerticalUploader
          disableAddMore={loading}
          onClick={openFileSelector}
          accept={accept}
          theme={theme}
          mb={4}
          width={{
            xs: 1,
            md: 1 / 4,
          }}
        />
      ) : (
        <HorizontalUploader
          disableAddMore={loading}
          accept={accept}
          mb={4}
          onClick={openFileSelector}
          theme={theme}
        />
      )}
      {files.map(file => (
        <Row key={file.uid}>
          <FileItem
            disableRemoval={loading}
            onRemove={() => {
              onRemove(file.uid);
            }}
            width={1}
            theme={theme}
            file={file}
          />
        </Row>
      ))}
      {files.length ? (
        <Row
          pr="20px"
          width={1}
          mt={2}
          justifyContent={alert ? 'flex-start' : 'flex-end'}
        >
          {alert || (
            <StandardText bold fontSize={1}>
              Total file size: {totalFileSizeFormatted}
            </StandardText>
          )}
        </Row>
      ) : null}
    </Dragger>
  );
};

const Index = props => {
  const ref = useRef(null);
  const isMobile = useMobileScreen();

  const openFileSelector = useCallback(() => {
    ref.current
      .querySelector('input[type=file]')
      .dispatchEvent(new MouseEvent('click'));
  }, [ref]);

  const totalFileSizeFormatted = useMemo(() => {
    return fileSize(props.totalFileSize);
  }, [props.totalFileSize]);

  return (
    <StyledDragger
      ref={ref}
      theme={props.theme}
      showSuccess={props.showSuccess}
      showLoader={props.showLoader}
    >
      {render({
        totalFileSizeFormatted,
        isMobile,
        openFileSelector,
        ...props,
      })}
    </StyledDragger>
  );
};

export default withTheme(Index);
