import {
  defined,
  FormValidator,
  maxLength,
  required,
} from '~lib/formValidation';
import { validators } from '~common/molecules/form-controls';
import { AGE_CASE } from '../constants';
import { privateHealthDetailsValidationSchema } from './yourDetailsValidation';

export const dependantDetailsValidationSchema = {
  title: required('Title is required field'),
  firstName: [
    required('First name is required field'),
    maxLength(100, 'First name is max 100 characters long'),
  ],
  lastName: [
    required('Last name is required field'),
    maxLength(100, 'Last name is max 100 characters long'),
  ],
  dobDay: [
    required('Day is required'),
    value => {
      validators.day().validateSync(value);
    },
  ],
  dobMonth: [
    required('Month is required'),
    value => {
      validators.month().validateSync(value);
    },
  ],
  dobYear: [
    required('Year is required'),
    value => {
      validators.year().validateSync(value);
    },
    validators.dobValidator,
  ],
  relationship: required('Relationship is required'),
  isFullTimeStudent: defined('Please answer this question'),
  institution: required('Please enter the name of institution'),
  currentHealthFund: required('Current health fund is required field'),
  membershipNumber: privateHealthDetailsValidationSchema.membershipNumber,
};

export default ({ calculateAgeCase }) => {
  return FormValidator({
    validation: dependantDetailsValidationSchema,
    transformValidation: (validationArray, form) => {
      const ageCase = calculateAgeCase({
        values: form,
      });

      const studentEligible = [
        AGE_CASE.VALID_EFC,
        AGE_CASE.VALID_NO_EFC,
      ].includes(ageCase);

      return validationArray.filter(validation => {
        const [field] = validation;
        if (
          (['currentHealthFund', 'membershipNumber'].includes(field) &&
            !form.isTransferringFromAnotherFund) ||
          (form.useMemberPreviousHealthFund && form.canUseMembersHealthFund)
        ) {
          return false;
        }

        if (
          field === 'institution' &&
          (!studentEligible || !form.isFullTimeStudent)
        ) {
          return false;
        }

        if (!studentEligible && field === 'isFullTimeStudent') {
          return false;
        }

        return true;
      });
    },
  });
};
