import React, { useCallback, useEffect } from 'react';
import { useMemo, useRef } from 'react';
import { useDynamicForms } from '~lib/hooks/useForm';
import { confirm } from '~common/services/modalService';
import dependantDetailsValidation from '../validation/dependantDetailsValidation';
import {
  canDependantUseMembersHealthFund,
  getDobDate,
  openModal,
} from '../common/appFormUtills';
import { now } from '~lib/dates';
import { AGE_CASE } from '../constants';
import EfcUpgradeModal from '../common/components/modals/EfcUpgradeModal';
import Over25Modal from '../common/components/modals/Over25Modal';
import { scrollTo } from '~lib/util';
import { EFC_MIN_AGE, EFC_UPPER_AGE_LIMIT } from '~lib/constants';

export default ({
  initialForm,
  onRemove,
  onAdd,
  isEfc,
  upgradeToEfc,
  persistKey,
  isTransferringFromAnotherFund,
  privateHealthDetailsForm,
  efcEligible = true,
} = {}) => {
  const dependantCounterRef = useRef(0);

  const calculateDependantAgeCase = useCallback(
    form => {
      if (
        !form.values.dobDay ||
        !form.values.dobMonth ||
        !form.values.dobYear
      ) {
        return AGE_CASE.INVALID_AGE;
      }

      try {
        const dob = getDobDate(form);
        const age = now().diff(dob, 'year');
        if (age < EFC_MIN_AGE) {
          return AGE_CASE.UNDER_21;
        }

        if (isEfc && age >= EFC_MIN_AGE && age < EFC_UPPER_AGE_LIMIT) {
          return AGE_CASE.VALID_EFC;
        }

        if (!isEfc && age >= EFC_MIN_AGE && age < EFC_UPPER_AGE_LIMIT) {
          return AGE_CASE.VALID_NO_EFC;
        }

        return AGE_CASE.OVER_32;
      } catch (error) {
        return AGE_CASE.INVALID_AGE;
      }
    },
    [isEfc]
  );

  const onDobChange = (value, prevValue, form, name) => {
    if (form.errors.dobDay || form.errors.dobMonth || form.errors.dobYear) {
      return;
    }

    const ageCase = calculateDependantAgeCase(form);

    if (ageCase === AGE_CASE.OVER_32) {
      openModal(({ close }) => (
        <Over25Modal
          onRemove={() => {
            close();
            dependantsForms.getForm(name).setForm({
              dobDay: '',
              dobMonth: '',
              dobYear: '',
            });
          }}
        />
      ));
    }
  };

  const canUseMembersHealthFund = canDependantUseMembersHealthFund(
    privateHealthDetailsForm
  );

  const dependantsForms = useDynamicForms(initialForm, {
    persistKey,
    validate: dependantDetailsValidation({
      calculateAgeCase: calculateDependantAgeCase,
    }),
    onChange: {
      dobDay: onDobChange,
      dobMonth: onDobChange,
      dobYear: onDobChange,
      isFullTimeStudent: (value, prevValue, formikForm, formName) => {
        if (!value && !isEfc && efcEligible) {
          openModal(({ close }) => (
            <EfcUpgradeModal
              onUpgrade={() => {
                close();
                upgradeToEfc();
              }}
              onRemove={() => {
                close();
                dependantsForms.getForm(formName).setForm({
                  dobDay: '',
                  dobMonth: '',
                  dobYear: '',
                  isFullTimeStudent: '',
                });
              }}
            />
          ));
        }
      },
    },
  });

  const getFormName = () => {
    return `dependant${++dependantCounterRef.current}`;
  };

  const addDependant = (autoScroll = false, initialForm) => {
    const formName = getFormName();
    dependantsForms.addForm(formName, initialForm);
    onAdd(formName);
    if (autoScroll) {
      scrollTo(formName, {
        offset: -150,
        timeout: 0,
      });
    }
  };

  const removeDependant = form => {
    confirm({
      title: 'Remove this dependant',
      message:
        'Are you sure you want to remove this dependant from this cover?',
      confirmText: 'Yes, Remove',
      cancelText: 'Cancel',
      alignItems: 'flex-start',
      onConfirm: () => {
        dependantsForms.removeForm(form.name);
        onRemove(form.name);
      },
    });
  };

  useEffect(() => {
    dependantsForms.forms.forEach(form => {
      let formSlice;
      if (
        form.values.isTransferringFromAnotherFund !==
        isTransferringFromAnotherFund
      ) {
        formSlice = {
          isTransferringFromAnotherFund,
        };
      }

      if (form.values.canUseMembersHealthFund !== canUseMembersHealthFund) {
        formSlice = {
          ...formSlice,
          canUseMembersHealthFund,
        };
      }

      if (formSlice) {
        form.setForm(formSlice);
      }
    });
  }, [isTransferringFromAnotherFund, canUseMembersHealthFund]);

  const allValid = useMemo(() => {
    return dependantsForms.forms.every(form => form.isValid);
  }, [dependantsForms.forms]);

  return {
    ...dependantsForms,
    addDependant,
    removeDependant,
    calculateDependantAgeCase,
    allValid,
    isTransferringFromAnotherFund,
    canUseMembersHealthFund,
  };
};
