import React from 'react';
import { StandardText } from '../../../../common/atoms/typography';
import { Flex, Box } from 'rebass';
import { HORIZONTAL_FORM } from '~common/molecules/form-controls';

const Detail = ({ label, value }) => (
  <Flex mb={HORIZONTAL_FORM.marginBottom}>
    <StandardText width={HORIZONTAL_FORM.labelWidth}>{label}</StandardText>
    <StandardText width={HORIZONTAL_FORM.controlWidth}>{value}</StandardText>
  </Flex>
);

const keyToLabelMap = {
  email: 'Email address',
  mobilePhone: 'Mobile phone',
  workPhone: 'Work phone',
  homePhone: 'Home phone',
};

const MemberDetails = ({ member }) => {
  return (
    <Box className="full-width">
      {Object.keys(keyToLabelMap).map(key => (
        <Detail key={key} label={keyToLabelMap[key]} value={member[key]} />
      ))}
    </Box>
  );
};

export default React.memo(MemberDetails);
