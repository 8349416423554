import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { AvantExclusivePromotionQuery } from 'queries/maf.graphql';
import { formatToISO8601DateOnly } from '~lib/dates';

export default ({
  startCoverDate,
  isAvantGroupMember,
  isPartnerAvantGroupMember,
  productType,
  isProfessionEligible,
}) => {
  const { data } = useQuery(AvantExclusivePromotionQuery, {
    variables: {
      input: {
        joinDate: formatToISO8601DateOnly(startCoverDate),
        productType,
        isAvantGroupMember,
        isPartnerAvantGroupMember,
        isProfessionEligible,
      },
    },
  });

  return useMemo(() => {
    return {
      isEligible: data?.avantExclusivePromotion?.isEligible,
      message: data?.avantExclusivePromotion?.alertMessage,
      confirmationPageMessage:
        data?.avantExclusivePromotion?.confirmationMessage,
    };
  }, [data]);
};
