import { useEffect } from 'react';
import { useState } from 'react';
import { path, whereEq } from 'lodash/fp';
import useLocalStorage from '~lib/hooks/useLocalStorage';
import {
  criteriaToQuery,
  getPromotionProductType,
  hasPartnerIncluded,
} from '~maf/common/appFormUtills';
import { useAdvancedQuoteQuery } from '~lib/CoverProducts/useQuoteQuery';
import useCriteria from '~OMS/my-cover/change-cover/ApplicationForm/state/useApplicationForm/useCriteria';
import useSubmitApplicationForm from '~maf/state/useApplicationForm/useSubmitApplicationForm';
import useLhcLoading from '~maf/state/useApplicationForm/useLhcLoading';
import usePromotions from '~lib/hooks/sanity/usePromotions';
import { APPLICATION_FORM_STEPS } from '~maf/constants';
import { STEP_STATE } from '~lib/constants';
import useStepProvider from '~maf/state/useStepProvider';
import useStepFiller from '~OMS/my-cover/change-cover/ApplicationForm/state/useApplicationForm/useStepFiller';
import { RequestCoverChangeMutation } from 'queries/oms/mycover.graphql';
import enumeratedSteps from '~OMS/my-cover/change-cover/ApplicationForm/state/useApplicationForm/enumeratedSteps';
import useStepper from '~maf/state/useApplicationForm/useStepper';
import messages from '~OMS/my-cover/change-cover/ApplicationForm/state/useApplicationForm/messages';
import { isEfcEligible } from '~lib/util';

export default ({ changeCoverContext }) => {
  const [activeStep, setActiveStep] = useState(
    APPLICATION_FORM_STEPS.ELIGIBILITY
  );

  const [confirmedSteps, setConfirmedSteps, removeConfirmedSteps] =
    useLocalStorage('appForm:confirmedSteps', {});

  const { setCriteria, criteria } = useCriteria(changeCoverContext);

  const { data: quoteData, loading: quoteLoading } = useAdvancedQuoteQuery(
    criteriaToQuery(criteria),
    {
      skip: !criteria,
    }
  );

  const status = path('status.value')(criteria);
  const isFamilyPackage = status !== 'SINGLE';
  const isCouplePackage = status === 'COUPLE';
  const isDependantsPackage = ['FAMILY', 'SINGLE_PARENT'].includes(status);
  const hasPartner = hasPartnerIncluded(criteria);
  const isEfc = path('efc.value')(criteria);
  const hasLhcApplied = !!path('maf.advancedQuote.lhcPercentage')(quoteData);

  const { activeStepProvider, steps, allSteps, stepProvider } = useStepProvider(
    {
      criteria: criteria || {},
      setCriteria,
      confirmedSteps,
      activeStep,
      isFamilyPackage,
      isCouplePackage,
      isDependantsPackage,
      status,
      hasPartner,
      hasLhcApplied,
      efcEligible: isEfcEligible(status),
      isUpgradeCover: true,
      getStepStorageKey: () => null,
      allStepsEnumerated: enumeratedSteps,
    }
  );

  const { selectStep, clearAllSteps, goToNextStep, confirmStep } = useStepper({
    activeStep,
    activeStepProvider,
    setActiveStep,
    confirmedSteps,
    setConfirmedSteps,
    steps,
    allSteps,
  });

  const { loading } = useStepFiller({
    stepProvider,
    changeCoverContext,
    hasPartner,
    isDependantsPackage,
  });

  const [savedJoinDate, setSavedJoinDate] = useState(null);

  const isValid = steps.every(step => step.isValid);

  useEffect(() => {
    if (
      !steps.find(
        whereEq({
          value: activeStep.value,
        })
      )
    ) {
      const firstUncompletedStep = steps.find(
        whereEq({
          state: STEP_STATE.INACTIVE,
        })
      );
      setActiveStep(firstUncompletedStep);
    }
  }, [steps.length]);

  const promotions = usePromotions({
    variables: {
      input: {
        productType: getPromotionProductType(criteria),
        joinDate:
          savedJoinDate ||
          stepProvider.get(APPLICATION_FORM_STEPS.YOUR_DETAILS.value).context
            .privateHealthDetailsForm.values.startCoverDate,
        lens: 'applicationForm',
      },
    },
  });

  const clearApplicationForm = () => {
    setSavedJoinDate(
      stepProvider.get(APPLICATION_FORM_STEPS.YOUR_DETAILS.value).context
        .privateHealthDetailsForm.values.startCoverDate
    );
    clearAllSteps();
    removeConfirmedSteps();
  };

  const { submitApplicationForm, reference, applicationSent, submitting } =
    useSubmitApplicationForm({
      criteria,
      steps,
      submitMutation: RequestCoverChangeMutation,
      clearApplicationForm,
    });

  useLhcLoading({
    setCriteria,
    criteria,
    steps: allSteps,
    hasPartner,
    applicationSent,
  });

  return {
    activeStepProvider,
    stepProvider,
    changeCoverContext,
    state: {
      loading,
      hasLhcApplied,
      steps,
      criteria,
      activeStep,
      status,
      hasPartner,
      isFamilyPackage,
      isCouplePackage,
      isDependantsPackage,
      applicationSent,
      submitting,
      isValid,
      isEfc,
      promotions,
      reference,
      quoteLoading,
      isUpgradeCover: true,
    },
    actions: {
      submitApplicationForm,
      selectStep,
      goToNextStep,
      confirmStep,
      clearAllSteps,
      clearApplicationForm,
    },
    messages: messages({ hasLhcApplied }),
  };
};
