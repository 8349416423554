import React from 'react';
import {
  FormButton,
  FormConfirmWrapper,
  FormOkButton,
} from '~OMS/dashboard/atoms/FormConfirmation';

const Confirmation = ({
  onCancel,
  onConfirm,
  disableConfirm,
  loading,
  withoutCancel = false,
  disableCancel,
}) => {
  return (
    <FormConfirmWrapper
      justifyContent={withoutCancel ? 'center' : 'space-between'}
      mt={4}
    >
      <FormOkButton
        loading={loading}
        disabled={disableConfirm}
        type="submit"
        variant="secondary"
        onClick={onConfirm}
      >
        Save Changes
      </FormOkButton>

      {!withoutCancel && (
        <FormButton
          disabled={loading || disableCancel}
          onClick={onCancel}
          type="button"
          variant="secondaryInverted"
        >
          Cancel changes
        </FormButton>
      )}
    </FormConfirmWrapper>
  );
};

export default Confirmation;
