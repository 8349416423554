import React from 'react';
import Toggler from '~common/atoms/Toggler';
import useToggle from '~lib/hooks/useToggle';
import { Box, Flex } from 'rebass';
import styled from '@emotion/styled';

const StyledExpandable = styled(Box)`
  border-top: ${props =>
    props.divider ? `2px solid ${props.theme.colors.borders}` : ''};

  .expandable-header {
    cursor: pointer;
  }
`;

export const ControlledExpandable = ({
  header,
  body,
  divider = true,
  centerBody = true,
  expanded = false,
  toggle,
  ...props
}) => {
  return (
    <StyledExpandable divider={divider} py={4} {...props}>
      <Flex
        alignItems="center"
        className="expandable-header"
        justifyContent="space-between"
        onClick={() => toggle()}
      >
        {header}
        <Toggler expanded={expanded} />
      </Flex>
      {expanded && (
        <Flex
          flexDirection="column"
          alignItems={centerBody ? 'center' : ''}
          mt={3}
          className="expandable-body"
        >
          {body}
        </Flex>
      )}
    </StyledExpandable>
  );
};

export const Expandable = ({ expand, ...props }) => {
  const [expanded, toggle] = useToggle(expand);
  return (
    <ControlledExpandable expanded={expanded} toggle={toggle} {...props} />
  );
};

export default Expandable;
