import { Field, Form, Formik } from 'formik';
import BoldReadOnlyControl from '~common/molecules/form-controls/BoldReadOnlyControl';
import CenterBox from '~common/atoms/CenterBox';
import ConfirmButton from '~OMS/payments/atoms/ConfirmButton';
import React from 'react';
import { Alert } from '~common/molecules/AlertBox';

const ViewForm = ({ onEditDetails, initialForm, alert }) => (
  <Formik onSubmit={onEditDetails} initialValues={initialForm}>
    {() => (
      <Form className="full-width">
        {initialForm && (
          <>
            <Field
              name="accountName"
              label="Account name"
              labelPosition="left"
              component={BoldReadOnlyControl}
            />
            <Field
              name="accountBSB"
              label="BSB"
              labelPosition="left"
              component={BoldReadOnlyControl}
            />
            {initialForm && initialForm.institution && (
              <Field
                name="institution"
                label="Institution"
                labelPosition="left"
                component={BoldReadOnlyControl}
              />
            )}
            <Field
              name="accountNumber"
              label="Account number"
              labelPosition="left"
              component={BoldReadOnlyControl}
            />

            {alert && alert.type === 'success' && (
              <Alert mb={4} type={alert.type}>
                {alert.message}
              </Alert>
            )}
          </>
        )}
        <CenterBox>
          <ConfirmButton>
            {initialForm ? 'Edit details' : 'Add Bank Details'}
          </ConfirmButton>
        </CenterBox>
      </Form>
    )}
  </Formik>
);

export default ViewForm;
