import React from 'react';
import ButtonCheckSelector from '../molecules/ButtonCheckSelector';
import {
  FormConfirmWrapper,
  FormButton,
} from '~OMS/dashboard/atoms/FormConfirmation';
import SimpleSuccessMessage from '~common/atoms/SimpleSuccessMessage';
import styled from '@emotion/styled';
import { Box } from 'rebass';
import Link from '~common/atoms/Link';
import { Alert } from '~common/molecules/AlertBox';

const StyledFormWrapper = styled(Box)`
  width: 413px;
  font-size: ${props => props.theme.fontSizes[2]}px;
`;

const StyledTitle = styled.h1`
  font-size: ${props => props.theme.fontSizes[5]}px;
  margin-bottom: ${props => props.theme.space[3]}px;
`;

const StyledSubTitle = styled.h2`
  font-size: ${props => props.theme.fontSizes[2]}px;
  color: ${props => props.theme.colors.text.light};
  margin-bottom: ${props => props.theme.space[4]}px;
`;

const StyledAddress = styled(Box)`
  font-weight: bold;
`;

const StyledLinkLikeText = styled(Link)`
  text-align: right;
`;

const OrderCardForm = ({
  onSubmit,
  confirmationDisabled,
  theme,
  className,
  onPersonChange,
  selectedPersons,
  address,
  addressLine,
  buttons,
  addressMode,
  updateAddressForm,
  onUpdatedAddressClick,
  addressUpdated,
  loading,
  alert,
}) => {
  return (
    <StyledFormWrapper pb={3} className={className}>
      <StyledTitle marginBottom={theme.space[3]}>Order a new card</StyledTitle>
      <StyledSubTitle marginBottom={theme.space[3]}>
        (Every member on this policy is listed on each card)
      </StyledSubTitle>

      <Box mb={3}>Select who needs a new card:</Box>
      <ButtonCheckSelector
        value={selectedPersons}
        onChange={onPersonChange}
        mb={3}
        buttons={buttons}
      />

      {addressUpdated && (
        <SimpleSuccessMessage
          emphasize
          mt={4}
          message="Your address has been updated"
        />
      )}

      {!addressMode && (
        <>
          <Box mt={4} mb={3}>
            Send new card to:
          </Box>
          <StyledAddress mb={3}>{addressLine}</StyledAddress>
          <StyledLinkLikeText
            to="#"
            onClick={evt => {
              evt.preventDefault();
              onUpdatedAddressClick(evt);
            }}
          >
            Update address
          </StyledLinkLikeText>
          {alert && (
            <Alert title="Unexpected error" mt={20} type={alert.type}>
              {alert.message}
            </Alert>
          )}
          <FormConfirmWrapper mt={4}>
            <FormButton
              loading={loading}
              noSiblings
              disabled={confirmationDisabled}
              onClick={onSubmit}
              variant="primary"
            >
              Order now
            </FormButton>
          </FormConfirmWrapper>
        </>
      )}

      {addressMode && <Box mt={4}>{updateAddressForm}</Box>}
    </StyledFormWrapper>
  );
};

export default OrderCardForm;
