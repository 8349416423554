import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import { withTheme } from 'emotion-theming';
import { graphql, useStaticQuery } from 'gatsby';
import { Element } from 'react-scroll';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import compose from 'lodash/fp/compose';
import filter from 'lodash/fp/filter';
import groupBy from 'lodash/fp/groupBy';
import anyPass from 'lodash/fp/anyPass';

import { isRunningOnClientSide } from '~lib/util';
import Icon from '~common/atoms/Icon';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import { H3, P2 } from '~common/atoms/typography';
import styled from '@emotion/styled';
import useFAQState from '~OMS/my-cover/hooks/useFAQState';
import ProductTabs from '~common/organisms/ProductTabs';

const StyledItem = styled.div`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.borders};
  position: relative;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  right: 0;
  top: 15px;
`;

const StyledContent = styled(Box)`
  display: ${props => (props.isActive ? 'block' : 'none')};
`;

const QUERY = graphql`
  query ThingsYouShouldKnow {
    hospital: allSanityHospitalProduct {
      nodes {
        id
        content {
          title
          coverage
          type
          faq {
            title
            link
            body: _rawBody(resolveReferences: { maxDepth: 1 })
          }
        }
      }
    }
    extras: allSanityExtrasProduct {
      nodes {
        id
        content {
          title
          type
          faq {
            title
            link
            body: _rawBody(resolveReferences: { maxDepth: 1 })
          }
        }
      }
    }
  }
`;

const openLink = url => {
  isRunningOnClientSide() && window.open(url, '_blank');
};

const TabbedAccordion = ({ theme, heading, plan, scrollName }) => {
  const { productIndex, setProductIndex } = useFAQState();
  const data = useStaticQuery(QUERY);

  const hospitalCover = x => x.title === get('hospitalProduct.title')(plan);
  const extrasCover = x => x.title === get('extrasProduct.title')(plan);

  const faqs = compose(
    groupBy(get('type')),
    filter(anyPass([hospitalCover, extrasCover])),
    map(get('content'))
  )(data.hospital.nodes.concat(data.extras.nodes));

  const tabs = useMemo(() => {
    return [
      plan?.hospitalProduct && {
        id: 0,
        title: 'Hospital',
      },
      plan?.extrasProduct && {
        id: 1,
        title: 'Extras',
      },
    ].filter(Boolean);
  }, [plan]);

  return (
    <Element name={scrollName}>
      {Object.keys(faqs).length ? (
        <>
          <H3 pb="20px" color="neutral900" variant="semiBold">
            {heading}
          </H3>
          <ProductTabs
            mb="20px"
            theme={theme}
            tabs={tabs}
            onSelect={setProductIndex}
            selected={productIndex}
          />

          <Flex flexWrap="wrap">
            {Object.keys(faqs).map((x, i) => {
              const [items] = faqs[x];
              return (
                <Accordion
                  key={i}
                  isActive={productIndex === i}
                  items={items.faq}
                  theme={theme}
                />
              );
            })}
          </Flex>
        </>
      ) : null}
    </Element>
  );
};

const itemType = PropTypes.shape({
  title: PropTypes.string,
  body: PropTypes.array,
  link: PropTypes.string,
});

TabbedAccordion.propTypes = {
  heading: PropTypes.string,
};

const Accordion = ({ theme, isActive, items }) => {
  const { FAQIndex, setFAQIndex } = useFAQState();
  return (
    <>
      {items.map((x, i) => (
        <StyledItem
          key={`${x.title}`}
          css={{ display: isActive ? 'block' : 'none' }}
        >
          <Element index={i} name={x.title}>
            <Flex
              py="17px"
              pr="30px"
              onClick={() =>
                x.link ? openLink(x.link) : setFAQIndex(FAQIndex !== i ? i : -1)
              }
            >
              <P2 variant="semiBold" color="neutral800">
                {x.title}
              </P2>
              {!x.link && (
                <StyledIcon
                  name={FAQIndex === i ? 'chevron-up' : 'chevron-down'}
                  clickable
                  fill={theme.colors.primary}
                  width="21px"
                />
              )}
            </Flex>
            {x.body && !x.link && (
              <StyledContent isActive={FAQIndex === i}>
                <Box pb={20} css={{ lineHeight: '22px' }}>
                  <SanityRichTextContent content={x.body} />
                </Box>
              </StyledContent>
            )}
          </Element>
        </StyledItem>
      ))}
    </>
  );
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(itemType),
  isActive: PropTypes.bool,
};

export default withTheme(TabbedAccordion);
