import React from 'react';
import { StandardText } from './typography';

const Email = ({ email, ...props }) => {
  return (
    <StandardText
      css={{
        textDecoration: 'none',
      }}
      clickable
      as="a"
      href={`mailto:${email}`}
      {...props}
    >
      {email}
    </StandardText>
  );
};

export default Email;
