import styled from '@emotion/styled';
import { Flex } from 'rebass';
import { useThemeUI } from 'theme-ui';
import { H3, P2 } from '~common/atoms/typography';
import Button from './Button';
import React from 'react';
import { getCardWidth } from './pageBlocksUtils';

const StyledProductCard = styled(Flex)`
  border-radius: 5px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.lightNeutral200};
`;

const ProductCard = ({ data, cardsCount, index, type }) => {
  const { theme } = useThemeUI();

  const buttonStyle = theme.productCardButtonStyle[type];

  return (
    <StyledProductCard
      mb={{
        xs: index !== cardsCount - 1 ? 40 : 0,
        tabletP: 0,
      }}
      alignItems="center"
      flexDirection="column"
      width={{
        xs: 1,
        tabletP: cardsCount === 2 ? 380 : getCardWidth(cardsCount),
      }}
      mr={{
        xs: 0,
        tabletP: index !== cardsCount - 1 ? 30 : 0,
      }}
    >
      <Flex
        width={1}
        justifyContent="center"
        alignItems="center"
        height={130}
        px={20}
        bg={type}
      >
        <H3 textAlign="center" color={theme.colors.white}>
          {data?.title} {data?.type === 'hospital' && data?.coverage}
        </H3>
      </Flex>

      <Flex
        flexDirection="column"
        height="calc(100% - 130px)"
        justifyContent="space-between"
        width={1}
        pt={30}
        pb={20}
        px={20}
      >
        <P2 color="neutral500" mb={24} fontWeight={500}>
          {data?.description}
        </P2>
        <Flex justifyContent="center">
          <Button
            data={{
              link: {
                url: `/${data?.slug?.current}`,
                newTab: false,
              },
              text: 'More details',
              style: buttonStyle,
              inverted: true,
            }}
          />
        </Flex>
      </Flex>
    </StyledProductCard>
  );
};

export default ProductCard;
