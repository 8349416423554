import Icon from '~common/atoms/Icon';
import { css } from '@emotion/core';
import React from 'react';
import { useThemeUI } from 'theme-ui';
import { Flex } from 'rebass';
import { P3 } from '~common/atoms/typography';

const AdditionalInfo = ({ children, ...props }) => {
  const { theme } = useThemeUI();
  return (
    <Flex
      px="16px"
      py="12px"
      alignItems="flex-start"
      bg={theme.colors.blue50}
      {...props}
    >
      <Icon mr="16px" name="info" originalColorSchema />
      <P3 variant="medium" color={theme.colors.primary}>
        {children}
      </P3>
    </Flex>
  );
};

export const AdditionalInfoArrow = ({
  show = true,
  x = '0px',
  y = '-2px',
  ...props
}) => {
  const { theme } = useThemeUI();
  return (
    <Icon
      css={css`
        opacity: ${show ? 1 : 0};
        transition: all 100ms ease-out;
        transform: translate(${x}, ${y});
      `}
      name="triangle"
      width="25px"
      height="15px"
      fill={theme.colors.primary}
      {...props}
    />
  );
};

export default AdditionalInfo;
