import React from 'react';
import styled from '@emotion/styled';
import Icon from '~common/atoms/Icon';
import { Box } from 'rebass';
import { withTheme } from 'emotion-theming';
import PropTypes from 'prop-types';
import Popover from '../../../Elements/Popover';

const StyledIcon = styled(Icon)`
  &&&&&& svg text {
    fill: ${props => props.theme.colors.primary};
  }
`;

const StyledButtonSelect = styled(Box)`
  background: ${props => props.theme.colors.background.light};
  color: ${props => props.theme.colors.text.light};
  cursor: ${props => (props.clickable ? 'pointer' : 'none')};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => props.theme.fontSizes[2]}px;
  padding: 12px;
  margin-bottom: ${props => (props.last ? 0 : props.theme.space[2])}px;
  transition: all 300ms ease-out;
  ${props =>
    props.active
      ? `
    border: 1px solid ${props.theme.colors.primary};
    color: ${props.theme.colors.primary};`
      : `
       border: 1px solid transparent;
      `}
`;

const isActive = (button = {}, value = []) =>
  value && value.includes(button.id);

function ButtonCheckSelector({ buttons, onChange, value, theme, ...props }) {
  return buttons.map((button, index) => {
    const buttonElement = (
      <StyledButtonSelect
        {...props}
        clickable={!button.disabled}
        last={index === buttons.length - 1}
        active={isActive(button, value)}
        onClick={() => {
          if (!button.disabled) {
            onChange(button);
          }
        }}
        key={button.id}
      >
        <Box mr={2}>{button.label}</Box>
        {isActive(button, value) && (
          <StyledIcon name="tick" height="13px" width="13px" />
        )}
      </StyledButtonSelect>
    );
    return button.tooltip ? (
      <Popover position="top" content={button.tooltip || ''}>
        {buttonElement}
      </Popover>
    ) : (
      buttonElement
    );
  });
}

ButtonCheckSelector.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default withTheme(ButtonCheckSelector);
