import { MaskedInput } from '~common/atoms/Input';
import React from 'react';
import { CreateControl } from '~common/molecules/form-controls/Control';

const bsbMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

const BsbInput = props => {
  return <MaskedInput keepCharPositions mask={bsbMask} {...props} />;
};

export default CreateControl(BsbInput);
