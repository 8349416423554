import React from 'react';
import Columns from './Columns';

const ContentWithSideMenu = ({
  data: { mainMenu, topMenu, content },
  ...props
}) => {
  const columnsData = {
    columns: [
      {
        _key: 'sideMenuColumn',
        _type: 'columnComponent',
        width: 2,
        hideMobile: true,
        content: [
          {
            _type: 'sideMenuComponent',
            menu: {
              ...mainMenu,
              items: (mainMenu.items || []).concat(topMenu.items),
            },
          },
        ],
      },
      {
        _key: 'contentColumn',
        _type: 'columnComponent',
        width: 10,
        content,
      },
    ],
  };

  return <Columns data={columnsData} {...props} />;
};

export default ContentWithSideMenu;
