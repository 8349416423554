import React from 'react';
import { Flex, Box } from 'rebass';
import Block from '~common/atoms/Block';
import Heading from './Heading';
import { ButtonsBase } from './Buttons';
import styled from '@emotion/styled';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import { css } from '@emotion/core';

const circleSize = {
  xs: 50,
  tabletL: 60,
};

const StepNumber = styled(Flex)`
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.white};
  font-weight: bold;
`;

const ConnectLine = styled(Box)`
  position: absolute;
  height: 1px;
  width: 24px;
  transform: rotate(90deg);
  left: 14px;
  top: 63px;
  ${props => props.theme.mq.tabletL} {
    transform: rotate(0deg);
    width: calc(100% - ${circleSize.tabletL}px);
    height: 1px;
    left: unset;
    right: unset;
    margin-left: ${circleSize.tabletL}px;
    top: ${circleSize.tabletL / 2}px;
  }
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%2387929FFF' stroke-width='2' stroke-dasharray='10%2c10' stroke-dashoffset='8' stroke-linecap='butt'/%3e%3c/svg%3e");
`;

const StepDescription = styled(SanityRichTextContent)`
  .text-block,
  .heading-block {
    font-size: ${props => props.theme.fontSizes[2]}px;
    ${props => props.theme.mq.tabletL} {
      font-size: 20px;
      text-align: center;
      width: 230px;
    }
  }
`;

const Step = ({ connectLine, step, index, width }) => {
  return (
    <Flex
      mb={{
        xs: connectLine ? 24 : 0,
        tabletL: 0,
      }}
      css={css`
        position: relative;
      `}
      flexDirection={{
        xs: 'row',
        tabletL: 'column',
      }}
      alignItems="center"
      justifyContent="flex-start"
      width={width}
    >
      <Box
        height={circleSize}
        width={circleSize}
        minHeight={circleSize}
        minWidth={circleSize}
        mb={{
          xs: 0,
          tabletL: 32,
        }}
      >
        <StepNumber
          fontSize={{
            xs: 24,
            tabletL: 32,
          }}
          height={circleSize}
          width={circleSize}
          minHeight={circleSize}
          minWidth={circleSize}
        >
          {index + 1}
        </StepNumber>
        {connectLine && <ConnectLine />}
      </Box>

      <StepDescription
        ml={{
          xs: 20,
          tabletL: 0,
        }}
        content={step.description}
      />
    </Flex>
  );
};

const Steps = ({ steps, ...props }) => {
  return (
    <Flex
      flexDirection={{
        xs: 'column',
        tabletL: 'row',
      }}
      {...props}
    >
      {steps.map((step, index) => (
        <Step
          width={{
            xs: 1,
            tabletL: 1 / steps.length,
          }}
          key={step._key}
          index={index}
          step={step}
          connectLine={index < steps.length - 1}
        />
      ))}
    </Flex>
  );
};

const Stepper = ({ data: { actions, steps, title }, ...props }) => {
  return (
    <Block {...props}>
      <Heading data={title} />
      <Steps
        steps={steps}
        mb={{
          xs: 40,
          tabletL: 60,
        }}
      />
      <ButtonsBase flexDirection="row" data={actions} />
    </Block>
  );
};

export default Stepper;
