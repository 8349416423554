import React from 'react';
import useContactDetails from '~lib/hooks/sanity/useContactDetails';
import ActionWithIcon from '~common/atoms/ActionWithIcon';
import Link from '~common/atoms/Link';

const CallUsWithIcon = ({ iconBoxProps, iconProps, ...props }) => {
  const sanityContactDetails = useContactDetails();

  return (
    <ActionWithIcon
      as={props => (
        <Link
          {...props}
          target="_self"
          external
          to={`tel:${sanityContactDetails?.phone}`}
        />
      )}
      text={sanityContactDetails?.phone}
      iconProps={{
        name: 'oms-phone-outline',
        ...iconProps,
      }}
      {...props}
    />
  );
};

export default CallUsWithIcon;
