import React from 'react';
import CmsPage from '../../components/Elements/CmsPage';
import { Footer, Header, ReasonsToJoin } from '../../components/PageBlocks';
import Layout from '../../components/Layout';
import Block from '~common/atoms/Block';

import { H1, H3, P1, P3 } from '~common/atoms/typography';
import ProductTabs from '~common/organisms/ProductTabs';
import { navigate } from 'gatsby';
import Loading from '~common/atoms/Loading';
import { RatesToggleExtended } from '~lib/CoverProducts';

const CompareProducts = ({
  children,
  type = 'hospital',
  products,
  rebatePercentage,
  loading,
  resultType,
  onResultType,
  ...props
}) => {
  return (
    <CmsPage pageContext={props.pageContext}>
      <Layout>
        <Header removeBottomMargin data={props.pageContext.header} />
        <Block
          pt="80px"
          pb="80px"
          mb="0"
          bg="primary"
          innerProps={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <H1 textAlign="center" mb="32px" variant="semiBold" color="white">
            Compare Covers
          </H1>
          <H3
            width={{
              xs: 1,
              tabletP: 572,
            }}
            textAlign="center"
            variant="medium"
            color="white"
          >
            Choose from options designed to suit the needs of people at
            different stages in their lives.
          </H3>
        </Block>
        <RatesToggleExtended
          selected={resultType}
          onAfter={() => {
            onResultType('FUTURE');
          }}
          onBefore={() => {
            onResultType('CURRENT');
          }}
        />
        <Block mt={70} mb={40}>
          <ProductTabs
            mb="48px"
            Typography={H3}
            onSelect={type => {
              navigate(`/compare-${type}-products`);
            }}
            selected={type}
          />
          {type === 'hospital' && (
            <P1
              mb="24px"
              variant="medium"
              color="neutral900"
              textAlign="center"
            >
              The Australian Government requires all private health insurance
              policies to be classified as basic, bronze, silver or gold. This
              categorisation helps you to better understand your level of cover.
            </P1>
          )}
          {!loading && (
            <P3 variant="medium" color="neutral500" textAlign="center">
              *Premium for a single in NSW including the base tier Australian
              Government rebate for under 65s
            </P3>
          )}
          {type === 'hospital' && (
            <P3 textAlign="center" variant="medium" color="neutral500">
              {' '}
              ^Services limited to delivery as a private patient in a public
              hospital in a shared room
            </P3>
          )}
        </Block>
        <Block>{loading ? <Loading size="large" /> : children}</Block>
        <ReasonsToJoin
          type="dark"
          mb={0}
          data={props.pageContext.reasonsToJoin}
        />
        <Footer data={props.pageContext.footer} />
      </Layout>
    </CmsPage>
  );
};

export default CompareProducts;
