import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { PersonsQuery } from 'queries/oms/common.graphql';
import { getMembership, getPerson } from '~OMS/response-selectors';
import { path, whereEq } from 'lodash/fp';
import { PAYMENT_FREQUENCY } from '~OMS/payments/constants';
import { PERSON_ROLE } from '~OMS/my-details/contants';

export default () => {
  const [coverDetails, setCoverDetails] = useState({
    resultType: 'CURRENT',
    paymentFrequency: PAYMENT_FREQUENCY.MONTHLY,
    subsidisedExtras: true,
    // freeExtras: true,
  });

  const setMyDetails = form => {
    setCoverDetails(value => ({
      ...value,
      ...form,
      address: {
        value: form.address,
      },
      dob: {
        value: form.dob,
      },
      yourDob: {
        value: form.dob,
      },
      region: {
        value: form.address.state,
      },
      email: {
        value: form.email,
      },
      mobileNumber: {
        value: form.mobileNumber,
      },
    }));
  };

  const { loading } = useQuery(PersonsQuery, {
    onCompleted: data => {
      const person = getPerson(data);
      const membership = getMembership(data);
      const partner = membership('persons').find(
        whereEq({
          role: PERSON_ROLE.PARTNER,
        })
      );
      const myDetailsInitialForm = {
        addressLine: membership('membershipAddressLine'),
        address: membership('membershipAddress'),
        dob: person('dob'),
        email: person('email'),
        mobileNumber: person('mobilePhone'),
        partner,
        partnerDob: {
          value: path('dob')(partner),
        },
        dependants: membership('persons').filter(
          whereEq({
            role: PERSON_ROLE.DEPENDANT,
          })
        ),
        member: membership('persons').find(
          whereEq({
            role: PERSON_ROLE.MEMBER,
          })
        ),
      };

      setMyDetails(myDetailsInitialForm);
    },
  });
  const [activeView, setActiveView] = useState('step1');

  const calculateCover = () => {
    setActiveView('step2');
  };

  const joinNow = () => {
    setActiveView('step3');
  };

  const goToInitialStep = () => {
    setActiveView('step1');
  };

  const myDetails = {
    address: path('address.value')(coverDetails),
    dob: path('dob.value')(coverDetails),
    email: path('email.value')(coverDetails),
    mobileNumber: path('mobileNumber.value')(coverDetails),
  };

  return {
    state: {
      activeView,
      coverDetails,
      myDetailsLoading: loading,
      myDetails,
    },
    actions: {
      setCoverDetails,
      calculateCover,
      setMyDetails,
      joinNow,
      goToInitialStep,
    },
  };
};
