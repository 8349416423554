import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';
import useToggle from '~lib/hooks/useToggle';
import React, { useCallback, useEffect, useState } from 'react';
import Icon from '../../../../../common/atoms/Icon';
import { StandardText } from '../../../../../common/atoms/typography';
import ServiceTypeFilter from '~OMS/claims/molecules/ClaimsHistoryFilters/ServiceTypeFilter';
import OtherFilters from '~OMS/claims/molecules/ClaimsHistoryFilters/OtherFilters';
import { Button } from '~common/atoms/Button';

const backgroundStyles = props => css`
  background: ${props.theme.colors.background.base};
`;

const StyledMobileScreenFiltersHeader = styled(Box)`
  ${backgroundStyles};
  padding: 12px;
  font-weight: 600;
`;

const StyledMobileScreenFiltersBody = styled(Box)`
  ${backgroundStyles};
  padding: 20px;
`;

const MobileScreenFilters = ({
  filter,
  filters: globalFilters,
  members,
  theme,
  loading,
  ...props
}) => {
  const [expanded, toggle] = useToggle();
  const [localFilters, setFilters] = useState({});

  useEffect(() => {
    setFilters(globalFilters);
  }, [globalFilters]);

  const setLocalFilters = useCallback(value => {
    setFilters(currentLocalFilters => ({
      ...currentLocalFilters,
      ...value,
    }));
  }, []);

  const submitFilters = useCallback(() => {
    filter(localFilters);
    toggle(false);
  }, [filter, localFilters, toggle]);

  const handleIconClick = useCallback(() => {
    if (expanded) {
      setFilters(globalFilters);
    }

    toggle();
  }, [expanded, globalFilters, toggle]);

  return (
    <Box {...props}>
      <StyledMobileScreenFiltersHeader mb="1px">
        <Flex onClick={handleIconClick}>
          <Icon
            name="refine"
            width="19px"
            height="15px"
            fill={theme.colors.black}
            mr={3}
          />
          <StandardText fontSize={2} lineHeight="13px">
            Refine results
          </StandardText>
        </Flex>
      </StyledMobileScreenFiltersHeader>
      {expanded && (
        <StyledMobileScreenFiltersBody>
          <ServiceTypeFilter
            mb={3}
            selectedType={localFilters.serviceType}
            onChange={value => {
              setLocalFilters({
                serviceType: value,
              });
            }}
          />
          <OtherFilters
            members={members}
            filters={localFilters}
            onChange={setLocalFilters}
          />
          <Button loading={loading} variant="secondary" onClick={submitFilters}>
            Filter results
          </Button>
        </StyledMobileScreenFiltersBody>
      )}
    </Box>
  );
};

export default MobileScreenFilters;
