import React from 'react';
import { Flex, Box } from 'rebass';
import { useThemeUI } from 'theme-ui';
import { Button } from '~common/atoms/Button';
import Icon from '~common/atoms/Icon';
import { css } from '@emotion/core';

const JoinNowButton = props => {
  const { theme } = useThemeUI();
  return (
    <Button
      data-id="join-now-bottom"
      variant={theme.joinNowButtonStyle}
      ml={{ md: '15px' }}
      width={{ xs: '100%', md: 'auto' }}
      minWidth={200}
      {...props}
    >
      <Flex alignItems="center">
        <Box>{props.text || 'Join Now'}</Box>
        <Icon
          name="arrow-right"
          fill={theme.colors.white}
          width="19px"
          height="21px"
          css={css`
            transform: translateX(30px);
          `}
        />
      </Flex>
    </Button>
  );
};

export default JoinNowButton;
