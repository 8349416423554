import React from 'react';
import { Heading } from '../../../components/PageBlocks';
import Carousel from '../../../common/molecules/Carousel';
import { useThemeUI } from 'theme-ui';
import ArticleCard from '../ArticleCard';
import { css } from '@emotion/core';
import { Flex } from 'rebass';

const ArticlesCarousel = ({ articles = [], ...props }) => {
  const { theme } = useThemeUI();
  return (
    <Flex flexDirection="column" justifyContent="flex-end" {...props}>
      <Heading
        textAlign="left"
        data={{
          level: 'h2',
          text: 'Read next',
        }}
      />
      <Carousel
        containerCss={css`
          display: grid;
          grid-auto-columns: 100%;
          column-gap: 30px;
          grid-template-rows: minmax(382px, auto);
          grid-auto-flow: column;
          padding: 10px 0;
          ${theme.mq.tabletL} {
            grid-auto-columns: 371px;
          }
        `}
      >
        {articles.map((article, index) => (
          <ArticleCard
            article={article}
            index={index}
            cardsCount={articles.length}
            key={article.slug.current}
          />
        ))}
      </Carousel>
    </Flex>
  );
};

export default ArticlesCarousel;
