import React, { useState } from 'react';
import { KleberAutoCompleteControl } from '~common/molecules/form-controls';
import { StandardText } from '~common/atoms/typography';
import { Box, Flex } from 'rebass';
import ManualAddressControl from '~common/molecules/form-controls/ManualAddressControl';
import FormField from '~common/molecules/FormField';
import { useMobileScreenOnly } from '~lib/responsive';
import Icon from '~common/atoms/Icon';
import { useThemeUI } from 'theme-ui';
import Popover from '../../../Elements/Popover';

const ModeSwitcher = ({ tooltip, onClick }) => {
  const { theme } = useThemeUI();

  return (
    <Popover position="top" content={tooltip}>
      <Icon
        name="refine"
        width="18px"
        height="18px"
        fill={theme.colors.primary}
        clickable
        onClick={onClick}
      />
    </Popover>
  );
};

const AddressField = ({ onChange, name, label }) => {
  const [mode, setMode] = useState('auto');
  const isMobile = useMobileScreenOnly();
  const enterManuallyElem = (
    <StandardText
      clickable
      onClick={() => {
        setMode('manual');
      }}
      textDecoration="underline"
      color="blue2"
      fontSize={2}
    >
      Enter it manually
    </StandardText>
  );

  return mode === 'auto' ? (
    <>
      <FormField
        name={name}
        component={KleberAutoCompleteControl}
        onChange={onChange}
        label={({ LabelContainer }) => {
          return (
            <Box width={1}>
              <LabelContainer mb={1} bold>
                {label}
              </LabelContainer>
              <Flex width={1} justifyContent="space-between">
                <LabelContainer>Search for your address</LabelContainer>
                {!isMobile && enterManuallyElem}
                {isMobile && (
                  <ModeSwitcher
                    onClick={() => {
                      setMode('manual');
                    }}
                    tooltip="Enter manually"
                  />
                )}
              </Flex>
            </Box>
          );
        }}
      />
    </>
  ) : (
    <FormField
      onChange={onChange}
      name={name}
      component={ManualAddressControl}
      label={({ LabelContainer }) => {
        return (
          <Flex width={1} justifyContent="space-between">
            <LabelContainer bold>{label}</LabelContainer>
            {!isMobile && (
              <StandardText
                clickable
                onClick={() => {
                  setMode('auto');
                }}
                textDecoration="underline"
                color="blue2"
                fontSize={2}
              >
                Search for address
              </StandardText>
            )}
            {isMobile && (
              <ModeSwitcher
                onClick={() => {
                  setMode('auto');
                }}
                tooltip="Search for address"
              />
            )}
          </Flex>
        );
      }}
    />
  );
};

export default AddressField;
