import React from 'react';
import { withTheme } from 'emotion-theming';
import { Text } from 'rebass';
import { themePirmaryText } from '../../styles/colors';

const ProgressStepper = ({ theme, current, total }) => {
  return (
    <Text mb={3} css={{ color: themePirmaryText }}>
      Step {current} of {total}
    </Text>
  );
};

export default withTheme(ProgressStepper);
