import React from 'react';
import { Flex } from 'rebass';
import { useThemeUI } from 'theme-ui';
import Icon from '~common/atoms/Icon';
import { StandardText } from '~common/atoms/typography';

const InvalidFormError = props => {
  const { theme } = useThemeUI();
  return (
    <Flex alignItems="center" my={40} {...props}>
      <Icon
        name="alert"
        fill={theme.colors.error}
        width="16px"
        height="16px"
      ></Icon>
      <StandardText ml={2} color={theme.colors.error} fontSize={2}>
        Please fill the required fields marked in red before you continue
      </StandardText>
    </Flex>
  );
};

export default InvalidFormError;
