import { pipe, find, last, prop, sortBy, path } from 'lodash/fp';

import {
  COUPLE,
  FAMILY,
  SINGLE,
  SINGLE_PARENT,
} from '~lib/constants/quoteStatuses';

const productsMap = {
  extras: {
    EE: 'extras-cover-essential-extras',
    TE: 'extras-cover-total-extras',
  },
  hospital: {
    SS: 'hospital-cover-smart-starter-bronze-plus',
    PC: 'hospital-cover-prime-choice-gold',
  },
};

const getProductMatcher = (acronym, type) => {
  return product => {
    return product.content.ID.current === productsMap[type][acronym];
  };
};

export default ({ extras, hospital }) => {
  const findExtras = acronym =>
    extras.find(getProductMatcher(acronym, 'extras'))?.content?.variants?.[0]
      ?.code;

  const findHospital = acronym =>
    pipe(
      find(getProductMatcher(acronym, 'hospital')),
      path('content.variants'),
      sortBy('excess'),
      last,
      prop('code')
    )(hospital);

  const generateSelection = (hospitalAcronym, extrasAcronym) => ({
    hospital: findHospital(hospitalAcronym),
    extras: findExtras(extrasAcronym),
  });

  return status => {
    const noSelection = () => ({
      hospital: undefined,
      extras: undefined,
    });

    return (
      {
        [SINGLE]: dob => {
          if (dob.age < 35) {
            return generateSelection('SS', 'EE');
          }

          if (dob.age >= 35 && dob.age <= 60) {
            return generateSelection('PC', 'EE');
          }

          return generateSelection('PC', 'TE');
        },
        [COUPLE]: dob => {
          if (dob.age <= 60) {
            return generateSelection('PC', 'EE');
          }

          return generateSelection('PC', 'TE');
        },
        [FAMILY]: dob => {
          if (dob.age <= 60) {
            return generateSelection('PC', 'EE');
          }

          return generateSelection('PC', 'TE');
        },
        [SINGLE_PARENT]: () => generateSelection('PC', 'EE'),
      }[status] || noSelection
    );
  };
};
