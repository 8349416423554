import React, { useContext } from 'react';
import { ContactUsContext } from '~OMS/contact-us/ContactUsContext';
import { Button } from '~common/atoms/Button';

const RequestCallbackButton = ({
  isAnomUser,
  children = 'Request a Callback',
  ...props
}) => {
  const { openRequestCallback } = useContext(ContactUsContext);
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <Button
      type="button"
      size="small"
      variant="secondary"
      onClick={() => {
        openRequestCallback({ isAnomUser });
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default RequestCallbackButton;
