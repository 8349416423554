import { Flex } from 'rebass';
import ServiceTypeFilter from '~OMS/claims/molecules/ClaimsHistoryFilters/ServiceTypeFilter';
import OtherFilters from '~OMS/claims/molecules/ClaimsHistoryFilters/OtherFilters';
import React from 'react';

const DesktopScreenFilters = ({
  filters,
  filter,
  members,
  loading,
  ...props
}) => (
  <Flex {...props} flexDirection="row" justifyContent="space-between">
    <ServiceTypeFilter
      selectedType={filters.serviceType}
      onChange={value => {
        filter({
          serviceType: value,
        });
      }}
    />
    <OtherFilters
      width={1 / 2}
      onChange={filter}
      filters={filters}
      members={members}
    />
  </Flex>
);

export default DesktopScreenFilters;
