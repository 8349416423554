/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';
import isEmpty from 'lodash/isEmpty';
import { StyledInput } from '~common/atoms/Input';
import Loading from '~common/atoms/Loading';
import { formatAddress } from '~lib/filters';
import { useStateReducer } from './reducer';
import {
  getAddressOptions,
  validateAddress,
  invalidAddressHandler,
  keyUpHandler,
  keyDownHandler,
} from './utils';

const canSearch = str => str && str.length >= 6;

const Dropdown = styled(Flex)`
  left: 0;
  top: 35px;
  width: 100%;
  z-index: 250;
  overflow-y: auto;
  max-height: 150px;
  position: absolute;
  background-color: ${props => props.theme.colors.white};
  border: solid 1px ${props => props.theme.colors.background.gray};

  @media screen and (max-width: 992px) {
    top: 60px;
    width: 100%;
    margin-left: 0;
  }
`;

const Address = styled.div`
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.colors.borders};
  background-color: ${props =>
    props.highlight ? props.theme.colors.background.light : undefined};
  &:hover {
    background-color: ${props => props.theme.colors.background.light};
  }
`;

const AddressInput = styled(StyledInput)`
  padding-right: 30px;
`;

const AddressOptions = ({ highlight, options, onMouseDown, onMouseUp }) => {
  const highlightRef = useRef();
  useEffect(() => {
    highlightRef.current &&
      highlightRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
  }, [highlight]);

  return !isEmpty(options) ? (
    options.map((a, i) => {
      const formatted = formatAddress(a);
      return (
        <Address
          key={i}
          className="address-item"
          // don't use onClick it will not fire after onBlur for AddressInput
          onMouseDown={onMouseDown}
          highlight={highlight === i}
          onMouseUp={() => onMouseUp(a)}
          ref={highlight === i ? highlightRef : null}
        >
          {formatted}
        </Address>
      );
    })
  ) : (
    <Address>Sorry, no search results.</Address>
  );
};

const noop = () => {};
export default ({
  value,
  onError,
  onChange,
  disabled,
  onBlur = noop,
  onKeyDown = noop,
  onKeyUp = noop,
  onRepairing = noop,
  ...props
}) => {
  const itemClick = useRef(false); // could use state but this avoids re-render
  const [state, actions] = useStateReducer({
    value, // aka query
    show: false,
    highlight: 0,
    options: null,
    searching: false,
  });

  const {
    close,
    setQuery,
    setValue,
    pickValue,
    setOptions,
    nextOption,
    prevOption,
  } = actions;

  const getOptions = useMemo(getAddressOptions, []);
  const onInputChange = ({ currentTarget }) => setQuery(currentTarget.value);
  const onItemMouseDown = () => (itemClick.current = true);
  const onSelectAddress = address => {
    itemClick.current = false;
    pickValue(formatAddress(address));
    onChange(address);
  };
  const selectOption = () => onSelectAddress(state.options[state.highlight]);
  const blurHandler = e => {
    const { value } = state;
    onBlur(e);
    if (!itemClick.current) {
      onChange(value);
      if (canSearch(value)) {
        close();
        validateAddress(value)
          .then(onSelectAddress)
          .catch(invalidAddressHandler(onChange));
      }
    }
  };

  useEffect(() => {
    let mounted = true;
    const { value: query, show } = state;
    if (canSearch(query) && show) {
      getOptions(query) //
        .then(options => mounted && setOptions(options));
    }
    return () => (mounted = false);
  }, [state.value]);

  // Hack: the parent is sometimes rendering before the value is ready.
  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <Flex css={{ position: 'relative' }}>
      <AddressInput
        onBlur={blurHandler}
        autoComplete="off"
        value={state.value}
        disabled={disabled}
        onChange={onInputChange}
        onKeyUp={keyUpHandler(nextOption, prevOption, selectOption)}
        onKeyDown={keyDownHandler()}
        {...props}
      />

      {canSearch(state.value) && state.show && (
        <Dropdown justifyContent="flex-start" flexDirection="column">
          {state.searching ? (
            <Loading minHeight="auto" size="small" />
          ) : (
            <AddressOptions
              highlight={state.highlight}
              options={state.options}
              onMouseDown={onItemMouseDown}
              onMouseUp={onSelectAddress}
            />
          )}
        </Dropdown>
      )}
    </Flex>
  );
};
