import React from 'react';
import { Box, Flex } from 'rebass';
import { H2, P2 } from '~common/atoms/typography';
import { Form } from '~common/molecules/Form';
import useForm from '~lib/hooks/useForm';
import {
  CheckboxControl,
  InputControl,
  validators,
} from '~common/molecules/form-controls';
import DateOfBirth from '~common/molecules/DateOfBirth';
import { Button } from '~common/atoms/Button';
import {
  email,
  FormValidator,
  phoneNumber,
  required,
} from '~lib/formValidation';
import FormField from '~common/molecules/FormField';
import Link from '~common/atoms/Link';
import css from '@emotion/css';

const validationSchema = {
  firstName: required('First name is required'),
  phoneNumber: [
    required('Phone number is required'),
    phoneNumber('Invalid phone number'),
  ],
  lastName: required('Last name is required'),
  email: [required('Email is required'), email('Invalid email')],
  dobDay: [
    required('Day is required'),
    value => {
      validators.day().validateSync(value);
    },
  ],
  dobMonth: [
    required('Month is required'),
    value => {
      validators.month().validateSync(value);
    },
  ],
  dobYear: [
    required('Year is required'),
    value => {
      validators.year().validateSync(value);
    },
  ],
  agreeTC: [required('You must agree to the terms and conditions')],
};

const validation = initialForm =>
  FormValidator({
    validation: validationSchema,
  });

const SaveForLaterMissingDetailsModal = ({
  onSubmit,
  onCancel,
  initialForm,
  saving,
}) => {
  const form = useForm(initialForm, {
    validate: validation(initialForm),
  });

  return (
    <Box>
      <H2 mb={3}>Save for later</H2>
      <P2 mb={3}>You can save your form to complete later</P2>
      <P2 variant="semiBold" mb={3}>
        Please enter the following details:
      </P2>

      <Form form={form} onSubmit={onSubmit}>
        {({ handleChange, handleSubmit, isValid }) => (
          <>
            {
              <FormField
                component={InputControl}
                onChange={handleChange}
                name="firstName"
                label="First name"
                focus
              />
            }
            {
              <FormField
                component={InputControl}
                onChange={handleChange}
                name="lastName"
                label="Last name"
                focus={initialForm.firstName}
              />
            }

            {
              <FormField
                name="email"
                label="Email"
                onChange={handleChange}
                component={InputControl}
                focus={initialForm.firstName && initialForm.lastName}
              />
            }

            {
              <FormField
                name="phoneNumber"
                label="Phone"
                type="tel"
                onChange={handleChange}
                component={InputControl}
                focus={
                  initialForm.firstName &&
                  initialForm.lastName &&
                  initialForm.email
                }
              />
            }

            {(!initialForm.dobDay ||
              !initialForm.dobMonth ||
              !initialForm.dobYear) && (
              <DateOfBirth
                Field={FormField}
                dayField={{
                  onChange: handleChange,
                  name: 'dobDay',
                }}
                monthField={{
                  onChange: handleChange,
                  name: 'dobMonth',
                }}
                yearField={{
                  onChange: handleChange,
                  name: 'dobYear',
                }}
              />
            )}

            <FormField
              onChange={handleChange}
              name="agreeTC"
              component={CheckboxControl}
              align="start"
              labelPosition="right"
              label={({ LabelContainer }) => (
                <LabelContainer>
                  I understand that Doctors’ Health Fund may contact me by
                  phone, email or SMS regarding my application.
                  <br />
                  <br />
                  We collect your information in accordance with our{' '}
                  <Link external to="/privacy-policy">
                    privacy policy.
                  </Link>
                </LabelContainer>
              )}
            />

            <Flex
              css={css`
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 10px;
                &&& button {
                  min-width: unset;
                }
              `}
            >
              <Button
                width="auto"
                loading={saving}
                disabled={!isValid}
                variant="primary"
                onClick={handleSubmit}
              >
                OK
              </Button>
              <Button
                width="auto"
                disabled={saving}
                variant="primaryInverted"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </Flex>
          </>
        )}
      </Form>
    </Box>
  );
};

export default SaveForLaterMissingDetailsModal;
