import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import styled from '@emotion/styled';
import Icon from '~common/atoms/Icon';
import { useThemeUI } from 'theme-ui';

const ChoiceContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(50px, ${props => (props.hasIcons ? '180px' : 'auto')});
  grid-gap: 10px;
  width: 100%;
  max-width: 476px;
`;

const ChoiceButton = styled.button`
  display: block;
  padding: 24px 12px;
  font-size: 16px;
  line-height: 19px;
  color: ${props => props.theme.colors.dark};
  font-weight: bold;
  text-align: center;
  border: 1px solid ${props => props.theme.colors.borders};
  border-radius: 5px;
  background: ${props => props.theme.colors.white};
  cursor: pointer;

  &.hasIcon {
    padding: 26px 12px;
  }

  &.selected {
    color: #fff;
    border: 1px solid ${props => props.theme.colors.secondary};
    background-color: ${props => props.theme.colors.secondary};
    > span {
      color: ${props => props.theme.colors.white};
    }
  }

  > span {
    font-size: 17.5px;
    line-height: 1.57143;
    display: block;
    font-weight: 400;
    color: ${props => props.theme.colors.light};
  }
`;

const RadioButtonGroup = ({
  className,
  onChange,
  availableStates,
  defaultValue,
  dataId,
  hasIcons,
  ...props
}) => {
  const [selectedValue, setSelected] = useState(defaultValue);
  const { theme } = useThemeUI();

  const handleSelection = state => {
    setSelected(state.value);
    onChange({
      ...state,
      availableStates,
    });
  };

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  return (
    <ChoiceContainer
      data-id={dataId}
      className={cx([className, 'choice-container'])}
      role="radiogroup"
      aria-activedescendant={`choice-${selectedValue}`}
      tabIndex="0"
      hasIcons={hasIcons}
      {...props}
    >
      {availableStates
        .filter(state => state.value != null)
        .map((state, idx) => (
          <ChoiceButton
            key={`choice-${idx}`}
            id={`choice-${state.value}`}
            data-id={`choice-${state.value}`}
            tabIndex="0"
            className={cx('choice', state.inputClassName, {
              selected: state.value === selectedValue,
              hasIcon: !!state.icon,
              hasDescription: !!state.description,
            })}
            onClick={() => {
              handleSelection(state);
            }}
            role="radio"
            aria-checked={state.value === selectedValue ? 'true' : 'false'}
          >
            {state.icon ? (
              <Icon
                clickable
                name={state.icon}
                height="62px"
                width="auto"
                fill={
                  state.value === selectedValue
                    ? theme.colors.white
                    : theme.colors.secondary
                }
                style={{ marginBottom: '20px' }}
              />
            ) : null}
            {state.description && <span>{state.description}</span>}
            {state.label}
          </ChoiceButton>
        ))}
    </ChoiceContainer>
  );
};

export default RadioButtonGroup;
