import React from 'react';
import Layout from 'components/Layout';
import ChangePasswordSuccessWrapper from '~OMS/SSO/organisms/ChangePasswordSuccessWrapper';
import { withTheme } from 'emotion-theming';

const ChangeSuccess = props => {
  return (
    <Layout>
      <ChangePasswordSuccessWrapper />
    </Layout>
  );
};

export default withTheme(ChangeSuccess);
