import React from 'react';
import { Flex, Heading } from 'rebass';
import { css } from '@emotion/core';
import { ScrollElement } from 'react-scroll';
import Popover from 'components/Elements/Popover';
import Icon from '~common/atoms/Icon';
import { useThemeUI } from 'theme-ui';

export default ScrollElement(
  ({ hide, title, help, contentWidth = '100%', children, name, ...props }) => {
    const { theme } = useThemeUI();
    const inner = (
      <Flex
        flexDirection="column"
        alignItems="center"
        pt={{ xs: '20px', md: '60px' }}
        pb={{ xs: '100px', md: '150px' }}
        w="100%"
        {...props}
      >
        <Flex
          flexDirection="row"
          justifyItems="flex-start"
          alignContent="flex-start"
        >
          <Heading
            color="text.dark"
            fontSize={3}
            mb="40px"
            textAlign="center"
            lineHeight="1.4"
          >
            {title}
          </Heading>
          {help ? (
            <Popover position="top" content={help}>
              <Icon
                name="help-circled"
                width="20px"
                height="20px"
                fill={theme.colors.secondary}
                style={{ marginLeft: '10px' }}
              />
            </Popover>
          ) : null}
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="flex-start"
          width={contentWidth}
          css={css`
            .EventDetection-container {
              width: 100%;
            }
          `}
        >
          {children}
        </Flex>
      </Flex>
    );

    return <div name={name}>{hide ? null : inner}</div>;
  }
);
