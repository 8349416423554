import { useQuery } from '@apollo/client';
import {
  ProductQuoteQueryFull,
  ProductQuoteQuery,
} from '../../../queries/quote.graphql';
import { AdvancedQuoteQuery } from '../../../queries/maf.graphql';
import { QuickQuoteQuery } from '../../../queries/quote.graphql';

export const useAdvancedQuoteQuery = (input, options) => {
  return useQuery(AdvancedQuoteQuery, {
    variables: {
      input,
    },
    ...options,
  });
};

export const useQuickQuoteQuery = (input, options) => {
  return useQuery(QuickQuoteQuery, {
    variables: {
      input,
    },
    ...options,
  });
};

export default (input, fullResponse, options) => {
  const result = useQuery(
    fullResponse ? ProductQuoteQueryFull : ProductQuoteQuery,
    {
      variables: {
        input: input,
      },
      ...options,
    }
  );

  return result;
};
