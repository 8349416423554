import React from 'react';
import { Box, Flex } from 'rebass';
import styled from '@emotion/styled';
import { findLastIndex } from 'lodash';
import { P2 } from '~common/atoms/typography';
import { useThemeUI } from 'theme-ui';
import { css } from '@emotion/core';
import { STEP_STATE } from '~lib/constants';
import { useMobileScreenOnly } from '~lib/responsive';
import MobileStepper from '~common/organisms/Stepper/MobileStepper';

const StepperContainer = styled(Flex)`
  position: relative;
`;

const CircleBase = styled(Box)`
  cursor: pointer;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.colors.color};
  background: ${props => props.colors.background};
  border: 1.6px solid ${props => props.colors.border};
  height: 24px;
  width: 24px;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
`;

const Circle = ({ state, index, colors }) => {
  return (
    <CircleBase colors={colors} state={state}>
      {index + 1}
    </CircleBase>
  );
};

const ConnectLine = styled(Box)`
  height: 24px;
  width: 2px;
  background: ${props =>
    props.lastActiveStepIndex < props.index + 1
      ? props.theme.colors.borders3
      : props.theme.colors.primary};
  transform: translateX(11px);
  ${props => props.theme.mq.tabletPInverted} {
    height: 2px;
    width: 40px;
    transform: translate(0, 8px);
  }
`;

const getColors = (state, theme) => {
  if (
    state === STEP_STATE.COMPLETED_INACTIVE ||
    state === STEP_STATE.COMPLETED_ACTIVE
  ) {
    return {
      color: theme.colors.white,
      background: theme.colors.primary,
      border: theme.colors.primary,
      label: theme.colors.primary,
    };
  }

  if (state === STEP_STATE.ACTIVE) {
    return {
      color: theme.colors.primary,
      background: theme.colors.white,
      border: theme.colors.primary,
      label: theme.colors.primary,
    };
  }

  return {
    color: theme.colors.neutral100,
    border: theme.colors.neutral100,
    background: theme.colors.white,
    label: theme.colors.neutral100,
  };
};

const Step = ({ step, index, onClick, theme }) => {
  const colors = getColors(step.state, theme);
  return (
    <Flex
      alignItems="center"
      css={css`
        z-index: 1;
        cursor: pointer;
        position: relative;
      `}
      onClick={() => onClick(step)}
      flexDirection={{
        xs: 'column',
        tabletP: 'row',
      }}
    >
      <Box
        width={{
          xs: 'auto',
          tabletP: '25px',
        }}
      >
        <Circle
          index={index}
          colors={colors}
          theme={theme}
          state={step.state}
        />
      </Box>
      <P2
        ml={12}
        textAlign="center"
        css={css`
          transition: all 200ms ease-in-out;
          ${theme.mq.tabletPInverted} {
            width: 60px;
            position: absolute;
            top: 27px;
          }
        `}
        variant={step.state.boldLabel ? 'bold' : 'semiBold'}
        color={colors.label}
      >
        {step.label}
      </P2>
    </Flex>
  );
};

const Stepper = ({ steps, onStepClick, ...props }) => {
  const { theme } = useThemeUI();
  const isMobileOnly = useMobileScreenOnly();
  const lastActiveStepIndex = findLastIndex(steps, step =>
    [
      STEP_STATE.ACTIVE,
      STEP_STATE.COMPLETED_ACTIVE,
      STEP_STATE.COMPLETED_INACTIVE,
    ].includes(step.state)
  );
  return isMobileOnly ? (
    <MobileStepper steps={steps} onStepClick={onStepClick} {...props} />
  ) : (
    <StepperContainer
      height={{
        xs: 30,
        tabletP: 'auto',
      }}
      flexDirection={{
        xs: 'row',
        tabletP: 'column',
      }}
      justifyContent={{
        xs: 'space-between',
        tabletP: 'flex-start',
      }}
      alignItems={{
        xs: 'center',
        tabletP: 'flex-start',
      }}
      {...props}
    >
      {steps.map((step, index) => (
        <Flex
          flexDirection={{
            xs: 'row',
            tabletP: 'column',
          }}
          key={step.value}
        >
          <Step index={index} onClick={onStepClick} theme={theme} step={step} />
          {index !== steps.length - 1 && !isMobileOnly && (
            <ConnectLine
              lastActiveStepIndex={lastActiveStepIndex}
              index={index}
            />
          )}
        </Flex>
      ))}
    </StepperContainer>
  );
};

export default Stepper;
