import { useMemo, useState } from 'react';
import { path } from 'lodash/fp';

export default changeCoverContext => {
  const [criteriaRaw, setCriteriaRaw, removeCriteria] = useState(
    changeCoverContext.state.coverDetails
  );

  const criteria = useMemo(() => {
    if (!criteriaRaw) {
      return undefined;
    }

    return {
      ...criteriaRaw,
      paymentFrequency: path('paymentFrequency', criteriaRaw) || 'MONTHLY',
    };
  }, [criteriaRaw]);

  const setCriteria = (data, { notifyPriceChange = false } = {}) => {
    setCriteriaRaw(data);
  };

  return {
    criteria,
    setCriteria,
    removeCriteria,
  };
};
