import { graphql, useStaticQuery } from 'gatsby';
import { useApolloClient, useQuery } from '@apollo/client';
import useModalState from '~lib/hooks/useModalState';
import { useEffect, useMemo, useState } from 'react';
import { PlanTypeQuery, NourishBabyLinkQuery } from 'queries/oms/common';
import { path } from 'lodash/fp';
import { isReferFriendEligible } from '~OMS/response-selectors';
import useFeatureToggle from '~lib/hooks/useFeatureToggle';

const LINKS_QUERY = graphql`
  {
    sanityOmsQuickLinks {
      links {
        _key
        link_key
        custom_logic
        title
        icon
        url
        limit_visibility
        new_window
        page {
          ... on SanityPage {
            slug {
              current
            }
          }
          ... on SanityCampaignPage {
            slug {
              current
            }
          }
          ... on SanityNewsArticle {
            slug {
              current
            }
          }
          ... on SanityExtrasProduct {
            content {
              slug {
                current
              }
            }
          }
          ... on SanityStaticPage {
            slug {
              current
            }
          }
          ... on SanityHospitalProduct {
            content {
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
`;

const ANIMATION_NAME = 'moveInFromTop';

export default (location, plan) => {
  const {
    sanityOmsQuickLinks: { links },
  } = useStaticQuery(LINKS_QUERY);

  const { loading, error, data } = useQuery(PlanTypeQuery);
  const client = useApolloClient();

  if (error) {
    console.error(error);
  }

  const {
    modalState,
    closeModal: closeNourishBabyModal,
    openModal: openNourishBabyModal,
  } = useModalState(false);
  const [nourishBabyLoading, setNourishBabyLoading] = useState(false);
  const [popupAnimation, setPopupAnimation] = useState(ANIMATION_NAME);

  const goToNourishBabyPortal = () => {
    setNourishBabyLoading(true);
    // fetch token from network every time as token expires in 30 seconds

    const aotWindow = window.open('', '_blank');

    client
      .query({
        query: NourishBabyLinkQuery,
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        aotWindow.location = data.oms.learnUponUrl;

        setNourishBabyLoading(false);
        closeNourishBabyModal();
      })
      .catch(error => {
        console.error(error);
        setNourishBabyLoading(false);
      });
  };

  const getTypes = type => {
    return (
      {
        BOTH: ['HOSPITAL', 'EXTRAS'],
        HOSPITAL_ONLY: ['HOSPITAL'],
        EXTRAS_ONLY: ['EXTRAS'],
      }[type] || []
    );
  };

  const membershipPlanType = path('oms.membership.plan.type')(data);
  const allLinks = [
    ...(links || []),
    ...((plan.hospitalProduct && plan.hospitalProduct.links) || []),
    ...((plan.extrasProduct && plan.extrasProduct.links) || []),
  ];

  const featureToggle = useFeatureToggle();
  const showReferFriend =
    isReferFriendEligible(data) && featureToggle.referrals;
  const preparedLinks = useMemo(() => {
    return allLinks
      .filter(link => {
        return (
          (!link.limit_visibility ||
            !link.limit_visibility.length ||
            getTypes(membershipPlanType).some(type =>
              link.limit_visibility.includes(type)
            )) &&
          (link.link_key !== 'refer-a-friend' || showReferFriend)
        );
      })
      .map(link =>
        link.link_key === 'nourish_baby'
          ? {
              ...link,
              onClick: () => {
                setPopupAnimation(ANIMATION_NAME);
                openNourishBabyModal();
              },
            }
          : link
      );
    //eslint-disable-next-line
  }, [links, membershipPlanType, showReferFriend]);

  useEffect(() => {
    if (location.hash === '#nourish-baby') {
      openNourishBabyModal();
      setPopupAnimation(false);
    }
  }, [location, openNourishBabyModal]);

  return {
    state: {
      loading,
      data,
      links: preparedLinks,
      nourishBabyModalVisible: modalState,
      nourishBabyLoading,
      membershipPlanType,
      popupAnimation,
    },
    actions: {
      closeNourishBabyModal,
      goToNourishBabyPortal,
    },
  };
};
