import { APPLICATION_FORM_STEPS } from '../../constants';
import Eligibility from '../../steps/Eligibility';
import useEligibilityStep from '../useEligibilityStep';
import YourDetails from '../../steps/YourDetails';
import useYourDetailsStep from '../useYourDetailsStep';
import FamilyDetails from '../../steps/FamilyDetails';
import useFamilyDetailsStep from '../useFamilyDetailsStep';
import Medicare from '../../steps/Medicare';
import useMedicareStep from '../useMedicareStep';
import AdditionalDetails from '../../steps/AdditionalDetails';
import useAdditionalDetailsStep from '../useAdditionalDetailsStep';
import Payments from '../../steps/Payments';
import usePaymentsStep from '../usePaymentsStep';

export default [
  {
    ...APPLICATION_FORM_STEPS.ELIGIBILITY,
    Component: Eligibility,
    hook: useEligibilityStep,
  },
  {
    ...APPLICATION_FORM_STEPS.YOUR_DETAILS,
    Component: YourDetails,
    hook: useYourDetailsStep,
  },
  {
    ...APPLICATION_FORM_STEPS.FAMILY_DETAILS,
    Component: FamilyDetails,
    hook: useFamilyDetailsStep,
  },
  {
    ...APPLICATION_FORM_STEPS.MEDICARE,
    Component: Medicare,
    hook: useMedicareStep,
  },
  {
    ...APPLICATION_FORM_STEPS.ADDITIONAL_DETAILS,
    Component: AdditionalDetails,
    hook: useAdditionalDetailsStep,
  },
  {
    ...APPLICATION_FORM_STEPS.PAYMENTS,
    Component: Payments,
    hook: usePaymentsStep,
  },
];
