import React from 'react';
import { Box } from 'rebass';
import { P3, StandardText } from '~common/atoms/typography';
import Divider from '~common/atoms/Divider';

const spacing = '22px';

const Field = ({ children, ...props }) => (
  <StandardText mb={spacing} fontSize={2} {...props}>
    {children}
  </StandardText>
);

const RebateApplicationSection = ({
  divider = true,
  title,
  children,
  ...props
}) => {
  return (
    <Box {...props}>
      {divider && <Divider mb={16} />}
      <StandardText mb={spacing} bold fontSize={2}>
        {title}
      </StandardText>
      <Box>{children({ Label: P3, Field, spacing })}</Box>
    </Box>
  );
};

export default RebateApplicationSection;
