import React from 'react';
import Link from '~common/atoms/Link';
import styled from '@emotion/styled';
import { Button } from '~common/atoms/Button';

const StyledButton = styled(Button)`
  a {
    border-radius: 0;
  }
  position: ${props => props.type === 'fixed' && 'fixed'};
  bottom: ${props => props.type === 'fixed' && 0};
  left: ${props => props.type === 'fixed' && 0};
  width: ${props => props.type === 'fixed' && '100%'};
`;

const AppButton = ({ text, variant, to, type = 'default', style = {} }) => (
  <StyledButton
    size="large"
    as={Link}
    variant={variant}
    to={to}
    type={type}
    style={style}
  >
    {text}
  </StyledButton>
);

export default AppButton;
