import { Flex, Box } from 'rebass';
import React from 'react';
import { HORIZONTAL_FORM } from './layoutConfig';
import { useMobileScreen } from '~lib/responsive';

export const HorizontalFormConfirmWrapper = ({ children, ...props }) => {
  const isMobileScreen = useMobileScreen();
  return isMobileScreen ? (
    <Box width={1} {...props}>
      {children}
    </Box>
  ) : (
    <Flex>
      <Box width={HORIZONTAL_FORM.labelWidth}>&nbsp;</Box>
      <Box {...props} width={HORIZONTAL_FORM.controlWidth}>
        {children}
      </Box>
    </Flex>
  );
};
