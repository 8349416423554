import React from 'react';
import { withTheme } from 'emotion-theming';
import { Flex, Box, Button } from 'rebass';

import LabelInput from '~common/molecules/LabelInput';
import FormTitle from '~OMS/styles/FormTitle';
import FormText from '~OMS/styles/FormText';
import SSOFormContainer from '~OMS/styles/SSOFormContainer';
import DateOfBirth from '~common/molecules/DateOfBirth';

const inputCss = `
  ::placeholder {
    padding-left: 13px;
    font-weight: normal;
    color: #dfdede;
  }
  ::-webkit-input-placeholder {
    padding-left: 13px;
    font-weight: normal;
    color: #dfdede;
  }
  ::-ms-input-placeholder {
    padding-left: 13px;
    font-weight: normal;
    color: #dfdede;
  }
`;

// SSO Request Access page wrapper component
function RequestAccessWrapper() {
  const [date, setDate] = React.useState({
    day: '',
    month: '',
    year: '',
  });

  const handleSubmit = e => {
    e.preventDefault();
    // @Todo: logic for API request
  };

  return (
    <SSOFormContainer>
      <FormTitle>Request access</FormTitle>
      <Flex>
        <Box width={1} mb="20px" mt="20px">
          <FormText>
            Please enter your details. They must match our records to verify
            your identity and protect your privacy.
          </FormText>
        </Box>
      </Flex>
      <form onSubmit={handleSubmit}>
        <Flex>
          <Box width={1} mb="30px">
            <LabelInput
              label="Username"
              type="text"
              inputCss={inputCss}
              placeholder="email, mobile or membership number"
            />
          </Box>
        </Flex>
        <Flex>
          <Box width={1} mb="40px">
            <LabelInput label="Firstname" type="text" />
          </Box>
        </Flex>
        <Flex>
          <Box width={1} mb="40px">
            <DateOfBirth setDate={setDate} date={date} />
          </Box>
        </Flex>
        <Flex flexDirection={{ xs: 'column', lg: 'row' }}>
          <Box
            width={{ md: '100%', lg: '48%' }}
            mb="30px"
            mr={{ xs: '0', lg: '10px' }}
          >
            <Button variant="inverted" css={{ width: '100%' }}>
              Cancel
            </Button>
          </Box>
          <Box
            width={{ md: '100%', lg: '48%' }}
            mb="30px"
            ml={{ xs: '0', lg: '10px' }}
          >
            <Button type="submit" variant="orange" css={{ width: '100%' }}>
              Request access
            </Button>
          </Box>
        </Flex>
      </form>
    </SSOFormContainer>
  );
}

export default withTheme(RequestAccessWrapper);
