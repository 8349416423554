import React from 'react';
import { Field } from 'formik';
import isEqual from 'lodash/isEqual';

import CheckBoxControl from '../../molecules/form-controls/CheckBoxControl';
import UpdateAddressFormFactory from './UpdateAddressFormFactory';
import { KleberAutoCompleteControl } from '~common/molecules/form-controls';
import { address as validAddress } from '~lib/formValidation';

// remove falsy object values
// { p: false, q: null } becomes {}
const clean = obj =>
  Object.entries(obj).reduce((a, [k, v]) => (v ? { ...a, [k]: v } : a), {});

const validate = (data, validators) => {
  try {
    validators.forEach(validator => validator(data));
  } catch (error) {
    // return Array.isArray(error) ? error : [error];
    return error;
  }
  return null;
};

const validation = form => {
  const { address, postalAddress, isPostalDifferentAsResidential: diff } = form;
  return clean({
    address: validate(address, validAddress('Residential address')),
    postalAddress:
      diff && validate(postalAddress, validAddress('Postal address')),
  });
};

export const AddressAutoCompleteFields = ({
  isPostalDifferentAsResidential,
}) => {
  return (
    <>
      <Field component={KleberAutoCompleteControl} name="address" />

      <Field
        name="isPostalDifferentAsResidential"
        label="My postal address is different from my residential address"
        labelPosition="right"
        component={CheckBoxControl}
      />

      {isPostalDifferentAsResidential && (
        <Field component={KleberAutoCompleteControl} name="postalAddress" />
      )}
    </>
  );
};

const AutoCompleteUpdateAddressForm = UpdateAddressFormFactory(
  AddressAutoCompleteFields,
  {
    address: '',
    postalAddress: '',
    isPostalDifferentAsResidential: '',
  },
  validation,
  (address, postalAddress) => ({
    address,
    postalAddress,
    isPostalDifferentAsResidential: !isEqual(address, postalAddress),
  })
);

export default AutoCompleteUpdateAddressForm;
