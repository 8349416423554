import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Global, css } from '@emotion/core';
import { navigate } from 'gatsby';
import { useThemeUI } from 'theme-ui';
import { Helmet } from 'react-helmet';
import { useCoverProducts } from '~lib/CoverProducts/useCoverProducts';
import useModalState from '~lib/hooks/useModalState';
import { isRunningOnClientSide, isProduction } from '~lib/util';
import { Footer } from '../../PageBlocks';
import Header from '~common/molecules/SimpleHeader';
import EmailQuoteModal from './EmailQuoteModal';
import QuoteOverview from './QuoteOverview';
import CompareCovers from './CompareCovers';
import JoinUs from './JoinUs';
import FloatingSummary from './FloatingSummary';
import useEditDetailsModal from './EditDetailsModal/useEditDetailsModal';
import useShowSaveModal from '~common/hooks/useShowSaveModal';
import { RatesToggleExtended } from '~lib/CoverProducts';

/* Due to font-family being set in global css, this is a temporary
 * workaround to allow us to scope the rebrand work specifically to
 * quote results. Eventually most of these global styles and the styles
 * in Root.js will get removed and the theme-ui styles object will be used
 * instead.
 *
 * https://theme-ui.com/theming/#styles
 */
const temporaryStyleOverrides = props => css`
  h1,
  h2,
  h3,
  h4,
  a {
    color: ${props.theme.colors.primary};
  }

  p {
    color: ${props.theme.colors.text};
  }

  h1 {
    font-size: 34px;
  }

  @media (min-width: 1024px) {
    h1 {
      font-size: 48px;
    }
  }

  h2 {
    font-size: 28px;
  }

  @media (min-width: 1024px) {
    h2 {
      font-size: 34px;
    }
  }

  h3 {
    font-size: 20px;
  }

  @media (min-width: 1024px) {
    h3 {
      font-size: 24px;
    }
  }

  h4 {
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    h4 {
      font-size: 20px;
    }
  }

  .sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }

  .sr-only-md {
    @media (min-width: 768px) {
      border: 0 !important;
      clip: rect(1px, 1px, 1px, 1px) !important;
      -webkit-clip-path: inset(50%) !important;
      clip-path: inset(50%) !important;
      height: 1px !important;
      margin: -1px !important;
      overflow: hidden !important;
      padding: 0 !important;
      position: absolute !important;
      width: 1px !important;
      white-space: nowrap !important;
    }
  }
`;

const QuoteResultsNew = ({ footer, form, setForm, updateForm, location }) => {
  const { theme } = useThemeUI();
  const confirmExit = location?.hash === '#confirmExit';
  const {
    quote,
    selections,
    productType,
    setProductType,
    prices,
    selectedProduct,
    products,
    isHospitalVisible,
    isExtrasVisible,
    hospitalInclisionsOpen,
    toggleHospitalInclusions,
    extrasInclusionsOpen,
    toggleExtrasInclusions,
  } = useCoverProducts(
    {
      ...form,
      subsidisedExtras: false,
      freeExtras: false,
    },
    {
      onProductChange: (type, code) => {
        updateForm(`${type}Product`, code);
      },
      onProductTypeChange: (type, rateType) => {
        updateForm('rateType', rateType);
        if (type === 'extras') {
          updateForm(`hospitalProduct`, undefined);
        } else if (type === 'hospital') {
          updateForm('extrasProduct', undefined);
        }
      },
    }
  );

  const [headerHeight, setHeaderHeight] = useState();
  const [summaryHeight, setSummaryHeight] = useState();
  const [modalViewed, setModalViewed] = useState(false);
  const [showFloatingSummary, setShowFloatingSummary] = useState(false);

  /** the first time a user leaves the quote page they are redirected back and shown a save quote modal
   *  unless the user has already emailed the quote to themselves. the hook function returns a
   *  cleanup function that is called after the redirect */
  const cleanup = useShowSaveModal({
    location,
    ignore: ['/quote', '/application-form'],
  });

  const {
    toggleModal: toggleEmailQuoteModal,
    openModal: openEmailQuoteModal,
    modalState: emailQuoteModalVisible,
  } = useModalState(false);

  useEffect(() => {
    if (!modalViewed && confirmExit && !emailQuoteModalVisible) {
      openEmailQuoteModal();
      setModalViewed(true);
    }
  }, [confirmExit, emailQuoteModalVisible, modalViewed, openEmailQuoteModal]);

  const handleApply = () => {
    setForm({
      ...form,
      hospitalProduct: selectedProduct.hospital || null,
      extrasProduct: selectedProduct.extras || null,
    });

    try {
      cleanup && cleanup();
    } catch (e) {
      console.log(e);
    }

    if (isRunningOnClientSide()) {
      navigate('/application-form');
    }
  };

  const handleEmailQuoteModalToggle = () => {
    toggleEmailQuoteModal(emailQuoteModalVisible);
    setModalViewed(true);
    // don't redirect the user if they have already viewed the modal
    try {
      cleanup && cleanup();
    } catch (e) {
      console.log(e);
    }
  };

  const editDetailsModal = useEditDetailsModal({ form });

  const openEditDetails = () => {
    editDetailsModal.open({
      onConfirm: updatedForm => {
        setForm({
          ...form,
          ...updatedForm,
        });
      },
    });
  };

  useEffect(() => {
    if (isRunningOnClientSide()) {
      window.scrollTo({ top: 0, left: 0 });
    }
  }, []);

  return (
    <>
      <Global
        styles={temporaryStyleOverrides({
          theme,
        })}
      />
      <Helmet>
        {/* crazyegg tracking */}
        {isProduction && (
          <script>var CE_SNAPSHOT_NAME = "Quick Quote Results";</script>
        )}
      </Helmet>
      <EmailQuoteModal
        isYouth={form.dob.age < 30 || form.partnerDob?.age < 30}
        form={form}
        prices={prices}
        productType={productType}
        quote={quote}
        selections={selections}
        setToggleModal={handleEmailQuoteModalToggle}
        toggleModal={emailQuoteModalVisible}
        confirmExit={confirmExit}
      />
      <Header setHeaderHeight={setHeaderHeight} />
      <RatesToggleExtended
        selected={form?.resultType}
        onBefore={() => updateForm('resultType', 'CURRENT')}
        onAfter={() => updateForm('resultType', 'FUTURE')}
        mb={0}
      />
      <FloatingSummary
        form={form}
        headerHeight={headerHeight}
        onApply={handleApply}
        prices={prices}
        productType={productType}
        quote={quote}
        selections={selections}
        setSummaryHeight={setSummaryHeight}
        showFloatingSummary={showFloatingSummary}
      />
      <QuoteOverview
        form={form}
        onApply={handleApply}
        onEmailQuote={toggleEmailQuoteModal}
        prices={prices}
        productType={productType}
        quote={quote}
        selections={selections}
        setProductType={setProductType}
        setShowFloatingSummary={setShowFloatingSummary}
        updateForm={updateForm}
        setFormVisible={openEditDetails}
      />
      <CompareCovers
        extrasInclusionsOpen={extrasInclusionsOpen}
        form={form}
        hospitalInclusionsOpen={hospitalInclisionsOpen}
        isExtrasVisible={isExtrasVisible}
        isHospitalVisible={isHospitalVisible}
        prices={prices}
        products={products}
        quote={quote}
        selectedProduct={selectedProduct}
        selections={selections}
        setProductType={setProductType}
        toggleExtrasInclusions={toggleExtrasInclusions}
        toggleHospitalInclusions={toggleHospitalInclusions}
        totalStickyHeight={headerHeight + summaryHeight}
        updateForm={updateForm}
      />
      <JoinUs
        onApply={handleApply}
        onEmailQuote={handleEmailQuoteModalToggle}
      />
      <Footer data={footer} />
    </>
  );
};

QuoteResultsNew.propTypes = {
  footer: PropTypes.any,
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  setFormVisible: PropTypes.func.isRequired,
};

export default QuoteResultsNew;
