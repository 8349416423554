import React from 'react';
import styled from '@emotion/styled';
import Loading from '~common/atoms/Loading';

const StyledCard = styled.div`
  background-color: ${props => props.theme.colors.white};
  position: relative;
  height: 300px;

  ${props => props.theme.mq.sm} {
    border: 1px solid ${props => props.theme.colors.neutral50};
  }
`;

const LoadingCard = () => {
  return (
    <StyledCard>
      <Loading size="large" />
    </StyledCard>
  );
};

export default LoadingCard;
