import React from 'react';
import Layout from 'components/Layout';
import VerifyInvitation from '~OMS/SSO/organisms/VerifyInvitation';
import { withTheme } from 'emotion-theming';
import withQueryParams from '~lib/hocs/withQueryParams';

const VerifyInvitationPage = props => {
  return (
    <Layout>
      <VerifyInvitation queryParams={props.queryParams} />
    </Layout>
  );
};

export default withTheme(withQueryParams(VerifyInvitationPage));
