import Hero from './Hero';
import NewHero from './NewHero';
import Button from './Button';
import WarningBanner from './WarningBanner';
import PromoBanner from './PromoBanner';
import QuickQuoteLinks from './QuickQuoteLinks';
import Heading from './Heading';
import PopularProducts from './PopularProducts';
import Card from './Card';
import Cards from './Cards';
import Icon from './Icon';
import ReasonsToJoin from './ReasonsToJoin';
import Link from './Link';
import Header from './Header';
import RichTextContent from './RichTextContent';
import Accordion from './Accordion';
import Divider from './Divider';
import Title from './Title';
import PullQuote from './PullQuote';
import Columns from './Columns';
import Column from './Column';
import SideMenu from './SideMenu';
import Banner from './Banner';
import Image from './Image';
import HeadingBlock from './HeadingBlock';
import Footer from './Footer';
import ContentWithSideMenu from './ContentWithSideMenu';
import Downloads from './Downloads';
import Iframe from './Iframe';
import HeaderImage from './HeaderImage';
import SharedBlock from './SharedBlock';
import Video from './Video';
import Buttons from './Buttons';
import Table from './Table';
import Tabs from './Tabs';
import Testimonials from './Testimonials';
import Stepper from './Stepper';
import ProductCards from './ProductCards';

export { Hero };
export { Button };
export { WarningBanner };
export { PromoBanner };
export { QuickQuoteLinks };
export { Heading };
export { PopularProducts };
export { Card };
export { Cards };
export { Icon };
export { ReasonsToJoin };
export { Link };
export { Header };
export { Footer };
export { RichTextContent };
export { Accordion };
export { Title };
export { PullQuote };
export { Columns };
export { Column };
export { SideMenu };
export { Banner };
export { Image };
export { ContentWithSideMenu };
export { Downloads };
export { HeaderImage };
export { SharedBlock };
export { NewHero };
export { Tabs };
export { Testimonials };
export { Stepper };
export { ProductCards };

export const componentRegistry = {
  heroComponent: Hero,
  newHeroComponent: NewHero,
  buttonComponent: Button,
  buttonsComponent: Buttons,
  warningBannerComponent: WarningBanner,
  promoBannerComponent: PromoBanner,
  quickQuoteLinksComponent: QuickQuoteLinks,
  headingComponent: HeadingBlock,
  popularProductsComponent: PopularProducts,
  cardComponent: Card,
  newCardComponent: Card,
  cardsComponent: Cards,
  newCardsComponent: Cards,
  iconComponent: Icon,
  reasonsToJoinComponent: ReasonsToJoin,
  link: Link,
  headerComponent: Header,
  footerComponent: Footer,
  accordionComponent: Accordion,
  dividerComponent: Divider,
  titleComponent: Title,
  pullQuoteComponent: PullQuote,
  columnsComponent: Columns,
  columnComponent: Column,
  sideMenuComponent: SideMenu,
  richTextComponent: RichTextContent,
  bannerComponent: Banner,
  imageComponent: Image,
  contentWithSideMenuComponent: ContentWithSideMenu,
  downloadsComponent: Downloads,
  iFrameComponent: Iframe,
  headerImageComponent: HeaderImage,
  sharedBlockComponent: SharedBlock,
  videoComponent: Video,
  customTable: Table,
  tabsComponent: Tabs,
  testimonialsComponent: Testimonials,
  stepperComponent: Stepper,
  productCardsComponent: ProductCards,
};
