import React from 'react';
import StepTitle from '../../common/components/StepTitle';
import { Form } from '~common/molecules/Form';
import { Box } from 'rebass';
import FormField from '~common/molecules/FormField';
import FormButtons from '../../common/components/FormButtons';
import { formLayout } from '../../common/layout';
import styled from '@emotion/styled';

import useSmoothScrollTop from '~lib/hooks/useSmoothScrollTop';
import PartnersDetailsForm from './PartnersDetailsForm';
import EditableFormPanel from '../../common/components/EditableFormPanel';
import DependantForm from './DependantForm';
import AddDependant from './AddDependant';
import { MAX_ALLOWED_DEPENDANTS } from '../../constants';
import InvalidFormError from '../../common/components/InvalidFormError';

const FormContainer = styled(Box)`
  ${formLayout};
`;

const FamilyDetails = ({ context, appFormContext }) => {
  useSmoothScrollTop();

  const {
    dependantDetails,
    partnerDetails,
    expander,
    confirmFamilyStep,
    handleNextForm,
  } = context;

  const { youthDiscountPercentage } = partnerDetails;

  return (
    <FormContainer>
      <StepTitle>
        {appFormContext.state.isCouplePackage ? 'Partner' : 'Family'}
      </StepTitle>
      {appFormContext.state.hasPartner && (
        <Form form={partnerDetails.form}>
          {({ handleChange, isValid, touched, values }) => (
            <EditableFormPanel
              touched={touched}
              confirmed={expander.confirmed.partnerDetails}
              isValid={isValid}
              mb={1}
              expanded={expander.expanded.partnerDetails}
              onHeaderClick={expander.toggleExpanded}
              title="Partner’s details"
              name="partnerDetails"
              onNext={
                !appFormContext.state.isCouplePackage
                  ? handleNextForm
                  : undefined
              }
            >
              <PartnersDetailsForm
                lhcAppliedMessage={appFormContext.messages.partnerLhcApplied}
                hasLhcApplied={context.partnerDetails.hasLhcApplied}
                criteria={appFormContext.state.criteria}
                youthDiscountPercentage={youthDiscountPercentage}
                values={values}
                handleChange={handleChange}
                Field={FormField}
              />
            </EditableFormPanel>
          )}
        </Form>
      )}

      {appFormContext.state.isDependantsPackage &&
        dependantDetails.forms.map((form, index) => (
          <Form key={form.name} form={form}>
            {({ handleChange, touched, isValid, values }) => (
              <EditableFormPanel
                onRemove={() => {
                  dependantDetails.removeDependant(form);
                }}
                mb={1}
                touched={touched}
                isValid={isValid}
                expanded={expander.expanded[form.name]}
                enableRemove={dependantDetails.forms.length > 1}
                confirmed={true}
                onHeaderClick={expander.toggleExpanded}
                title={`Dependant ${index + 1}`}
                name={form.name}
              >
                <DependantForm
                  calculateDependantAgeCase={
                    dependantDetails.calculateDependantAgeCase
                  }
                  Field={FormField}
                  values={values}
                  handleChange={handleChange}
                />
              </EditableFormPanel>
            )}
          </Form>
        ))}
      {!appFormContext.state.isCouplePackage && (
        <AddDependant
          limit={MAX_ALLOWED_DEPENDANTS}
          limitReached={
            dependantDetails.forms.length === MAX_ALLOWED_DEPENDANTS
          }
          onClick={() => {
            dependantDetails.addDependant(true);
          }}
        />
      )}

      {context.isFormSubmitted && !context.form.isValid && <InvalidFormError />}

      <FormButtons
        mt={30}
        saving={appFormContext.state.saving}
        onSave={() => {
          appFormContext.actions.saveApplicationForm();
        }}
        onContinue={() => {
          context.setFormSubmitted();
          if (context.form.isValid) {
            confirmFamilyStep({
              confirmStep: appFormContext.actions.confirmStep,
            });
          }
        }}
      />
    </FormContainer>
  );
};

export default FamilyDetails;
