import React, { useMemo } from 'react';
import { Formik, Field, Form } from 'formik';
import { Box, Flex } from 'rebass';
import { StandardText } from '../../../../common/atoms/typography';
import { BoldReadOnlyControl } from '~common/molecules/form-controls/index';
import { FirstCol, Row, SecondCol, ThirdCol } from '../atoms/Layout';
import * as Yup from 'yup';
import { useQuery } from '@apollo/client';
import { MyDetailsQuery } from 'queries/oms/myDetails.graphql';
import { path, pipe } from 'lodash/fp';
import { shortFormat } from '~lib/dates';
import Loading from '~common/atoms/Loading';
import { pathOr } from '~lib/util';
import { rebateTierFilter } from '~lib/filters';
import { useMobileScreen } from '~lib/responsive';
import { expiryDateValidator } from '~validators';

const initialForm = {
  number: '',
  reference: '',
  expiry: '',
  tier: '',
};

const validation = {
  medicareNumber: Yup.number()
    .positive()
    .integer()
    .required('Medicare number is required field'),
  expiryDate: expiryDateValidator().required('Expiry date is required field'),
};

const RebateForm = ({
  onSubmit,
  className,
  theme,
  tier,
  healthCoverLoading,
  medicareCard,
  ...props
}) => {
  return (
    <Box className="full-width" {...props}>
      <Formik
        initialValues={{
          ...initialForm,
          ...medicareCard,
          tier,
        }}
        validationSchema={validation}
        onSubmit={onSubmit}
      >
        {({ isValid, values, resetForm }) => (
          <Form className="full-width">
            <Field
              name="tier"
              label="Government Rebate Tier:"
              labelPosition="left"
              component={BoldReadOnlyControl}
            />
            <Field
              name="number"
              label="Medicare number"
              labelPosition="left"
              component={BoldReadOnlyControl}
            />
            <Field
              name="reference"
              label="Reference number"
              labelPosition="left"
              component={BoldReadOnlyControl}
            />
            <Field
              name="expiry"
              label="Expiry date"
              labelPosition="left"
              component={BoldReadOnlyControl}
            />
            <StandardText fontSize={2}>
              Please call us on{' '}
              <a href="tel:1800226126" style={{ color: '#222' }}>
                1800 226 126
              </a>{' '}
              to change these details.
            </StandardText>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

RebateForm.propTypes = {};

const withAvailabilityCheck = (card, propName) =>
  card[propName] ? card[propName] : 'not available';

const RebateSection = ({ rebateDetails, ...props }) => {
  const { data, loading } = useQuery(MyDetailsQuery);
  const isMobile = useMobileScreen();
  const medicareCard = useMemo(() => {
    return data
      ? pipe(pathOr({}, 'oms.membership.medicareCard'), card => ({
          number: withAvailabilityCheck(card, 'number'),
          reference: withAvailabilityCheck(card, 'reference'),
          tier: withAvailabilityCheck(card, 'tier'),
          expiry: card.expiry ? shortFormat(card.expiry) : 'not available',
        }))(data)
      : undefined;
  }, [data]);

  const tier = loading
    ? null
    : pipe(path('oms.membership'), rebateTierFilter)(data);

  return (
    <Box {...props}>
      <Row flexDirection="column">
        <StandardText fontSize={18} mb={3} fontWeight={600}>
          Government Rebate
        </StandardText>
      </Row>
      <Row>
        {!isMobile && <FirstCol>&nbsp;</FirstCol>}
        <SecondCol>
          {!loading ? (
            <RebateForm
              tier={tier}
              medicareCard={medicareCard}
              rebateDetails={rebateDetails}
            />
          ) : (
            <Flex width="100%" justifyContent="center">
              <Loading />
            </Flex>
          )}
        </SecondCol>
        <ThirdCol />
      </Row>
    </Box>
  );
};

export default RebateSection;
