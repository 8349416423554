import { mapWithLast } from '~lib/util';

const MapList = ({ list, render }) => {
  return mapWithLast(list, (item, index, isLast) => {
    return render({
      item,
      index,
      isLast,
      isFirst: index === 0,
    });
  });
};

export default MapList;
