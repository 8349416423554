import React, { useEffect, useMemo, useState } from 'react';
import { Box } from 'rebass';
import { whereEq } from 'lodash/fp';
import AdditionalInfo from './AdditionalInfo';
import RadioGroup from '~common/atoms/RadioGroup';
import styled from '@emotion/styled';

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
`;

const FancyCheckbox = ({
  value,
  options = [],
  onChange,
  optionClassName,
  type = 'primary',
  ...props
}) => {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const selectedOptionObject = useMemo(() => {
    if (!options) {
      return undefined;
    }
    return options.find(
      whereEq({
        value: selected,
      })
    );
  }, [selected, options]);

  return (
    <Box {...props}>
      <StyledRadioGroup
        value={selected}
        onChange={evt => {
          onChange(evt.target.value);
        }}
        className={optionClassName}
        options={options}
      />
      {selectedOptionObject && selectedOptionObject.additionalInfo && (
        <AdditionalInfo mt="25px" show>
          {selectedOptionObject.additionalInfo}
        </AdditionalInfo>
      )}
    </Box>
  );
};

export default FancyCheckbox;
