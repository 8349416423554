import { useLayoutEffect, useRef, useState } from 'react';
import useElementWidth from '~lib/hooks/useElementWidth';

export default ({ subContainerClassName = 'inner-container' } = {}) => {
  const containerRef = useRef();
  const [innerContainer, setInnerContainer] = useState();
  const { width: containerWidth } = useElementWidth(innerContainer);

  useLayoutEffect(() => {
    setInnerContainer(
      containerRef.current?.querySelector(`.${subContainerClassName}`)
    );
  }, []);

  return {
    containerRef,
    containerWidth,
  };
};
