import { noop } from '~lib/util';
import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Box } from 'rebass';
import isEmpty from 'lodash/isEmpty';
import AlertBox from '../../molecules/AlertBox';

import {
  FormButton,
  FormConfirmWrapper,
  FormOkButton,
} from '~OMS/dashboard/atoms/FormConfirmation';

const UpdateAddressFormFactory =
  (FormFieldsComponent, initialForm, validation, mapAddressToForm) =>
  ({
    onSubmit,
    onCancel,
    address,
    postalAddress,
    isPostalDifferentAsResidential,
    kleberKey,
    detailedAddress,
    detailedPostalAddress,
    addressLine,
    postalAddressLine,
    loading,
  }) => {
    let setFormValues = noop;
    let formValues;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      address &&
        setFormValues({
          ...formValues,
          ...mapAddressToForm(
            address,
            postalAddress,
            detailedAddress,
            detailedPostalAddress,
            isPostalDifferentAsResidential,
            addressLine,
            postalAddressLine
          ),
        });
      // eslint-disable-next-line
    }, [address, postalAddress, isPostalDifferentAsResidential]);

    return (
      <Formik
        onSubmit={(data, form) => {
          onSubmit(data, form);
        }}
        initialValues={initialForm}
        validate={validation}
      >
        {({
          handleSubmit,
          setValues,
          values,
          setFieldValue,
          isSubmitting,
          submitCount,
          isValid,
          errors,
          setFieldError,
        }) => {
          setFormValues = setValues;
          formValues = values;
          // toggle postal field
          isPostalDifferentAsResidential =
            values.isPostalDifferentAsResidential;

          return (
            <form
              onSubmit={e => {
                e.preventDefault();
                // only submit if there are no errors
                if (isEmpty(errors)) {
                  handleSubmit(e);
                }
              }}
            >
              <FormFieldsComponent
                kleberKey={kleberKey}
                isPostalDifferentAsResidential={isPostalDifferentAsResidential}
              />
              {/* show server error */}
              {submitCount > 0 && !loading && !isValid && (
                <Box mb={4}>
                  <AlertBox error={errors.message} />
                </Box>
              )}
              <FormConfirmWrapper>
                <FormOkButton loading={loading} variant="primary" type="submit">
                  Save address
                </FormOkButton>
                <FormButton
                  disabled={loading}
                  variant="primaryInverted"
                  onClick={onCancel}
                >
                  Cancel
                </FormButton>
              </FormConfirmWrapper>
            </form>
          );
        }}
      </Formik>
    );
  };

export default UpdateAddressFormFactory;
