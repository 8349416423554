import React, { createRef, useRef } from 'react';
import css from '@emotion/css';
import styled from '@emotion/styled';
import { Label, Checkbox } from '@rebass/forms';
import { Box, Flex } from 'rebass';
import { StandardText } from '~common/atoms/typography';

const CheckBoxContainer = styled.div`
  transform: translateX(-3px);
  label {
    align-items: center;
  }
  input[type='checkbox'] {
    display: none;
  }
  label:focus,
  input:focus,
  svg {
    outline: none;
  }
  input:checked ~ svg {
    color: ${props =>
      props.theme.colors[props.color] ||
      props.color ||
      props.theme.colors.primary};
  }
  svg:first-of-type {
    color: ${props =>
      props.theme.colors[props.color] ||
      props.color ||
      props.theme.colors.primary};
  }
  svg:last-of-type {
    color: ${props => props.theme.colors.borders};
  }
`;

const CheckBox = React.forwardRef(
  ({ id, name, value, onChange, color, className }, ref) => {
    return (
      <CheckBoxContainer
        className={`checkbox-container ${className}`}
        color={color}
      >
        <Label
          css={css`
            cursor: pointer;
          `}
        >
          <Checkbox
            ref={ref}
            data-id={name}
            id={name}
            name={name}
            checked={value}
            onChange={evt => {
              onChange(evt.target.checked);
            }}
          />
        </Label>
      </CheckBoxContainer>
    );
  }
);

export const MultipleCheckBox = ({
  id,
  name,
  options = [],
  value = {},
  onChange,
  color,
  className,
}) => {
  const refs = useRef(options.map(() => createRef()));
  return (
    <Box className={className} id={id} name={name}>
      {options.map((option, index) => (
        <Flex key={index} mb={index !== options.length - 1 ? '20px' : 0}>
          {!option.readOnly && (
            <CheckBox
              ref={refs.current[index]}
              color={color}
              name={option.name}
              value={value[option.name]}
              onChange={evt => {
                onChange({
                  ...value,
                  [option.name]: evt,
                });
              }}
            />
          )}
          <Label
            onClick={() => {
              refs.current[index].current.click();
            }}
            ml={1}
          >
            <StandardText className="multi-checkbox-option" fontSize={2}>
              {option.label}
            </StandardText>
          </Label>
        </Flex>
      ))}
    </Box>
  );
};

export default CheckBox;
