import { CreateControl } from './Control';
import CheckBox, { MultipleCheckBox } from '../../atoms/CheckBox';
import styled from '@emotion/styled';

export const MultiCheckBoxControl = CreateControl(MultipleCheckBox);

const CheckBoxControl = CreateControl(CheckBox);

export default styled(CheckBoxControl)`
  &&& .control-wrapper {
    align-items: ${props => props.align || 'center'};
  }
`;
