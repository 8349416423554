import React from 'react';
import { css } from '@emotion/core';
import { Flex, Heading } from 'rebass';
import AriaModal from 'react-aria-modal';
import Inclusions from './Inclusions';
import Icon from '~common/atoms/Icon';
import Legend from './InclusionsLegend';
import classnames from 'classnames';
import { useThemeUI } from 'theme-ui';

const getApplicationNode = () => {
  return document.getElementById('___gatsby');
};

const navbar = theme => css`
  position: sticky;
  top: 0;
  left: 0;
  background-color: ${theme.colors.white};
  width: 100%;
  height: 76px;
  padding: 15px 0 15px 0;
  border-bottom: 1px solid #eee;
  z-index: 9;

  &.navbar-open {
    height: 136px;
  }
`;

const InclusionsModal = props => {
  const { isOpen, onClose, product, products, onProductChange } = props;
  const { theme } = useThemeUI();
  // const [inclusions, setInclusions] = useState(true);
  const inclusions = true;

  return isOpen ? (
    <AriaModal
      titleText="Product Inclusions"
      onExit={onClose}
      getApplicationNode={getApplicationNode}
      focusDialog={true}
      verticallyCenter={true}
      underlayColor="rgba(0, 0, 0, 0.25)"
      underlayStyle={{
        paddingTop: '2em',
        overflow: 'hidden',
      }}
      dialogStyle={{
        backgroundColor: theme.colors.white,
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        overflow: 'scroll',
        '-webkit-overflow-scrolling': 'touch',
        padding: 0,
        paddingBottom: '50px',
      }}
    >
      <Flex
        flexDirection="column"
        alignItems="stretch"
        justifyContent="flex-start"
        bg="white"
        className={classnames({
          'navbar-open': inclusions,
        })}
        css={navbar(theme)}
      >
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Icon
            name="arrow-left"
            width="30"
            height="32"
            style={{
              cursor: 'pointer',
              marginLeft: '20px',
            }}
            fill={theme.colors.secondary}
            onClick={() => onClose()}
          />
          <Heading
            fontSize={3}
            fontWeight="bold"
            lineHeight="2.19"
            color="text.dark"
            style={{ textTransform: 'capitalize' }}
          >
            {props.product.type} Inclusions
          </Heading>
          <div style={{ width: '32px' }} />
        </Flex>
        {inclusions ? (
          <>
            <Flex
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
              width={1}
              css={css`
                position: sticky;
                top: 76px;
                left: 0;
                width: 100%;
              `}
            >
              <Legend inline={true} />
              <div
                css={css`
                  position: relative;
                `}
              >
                <p
                  css={css`
                    font-size: 13px;
                    padding: 0 5.5vw 1vw;
                    color: ${theme.colors.light};
                  `}
                >
                  *These services are limited to delivery as a private patient
                  in a public hospital in a shared room.
                </p>
              </div>
            </Flex>
          </>
        ) : null}
      </Flex>

      <Inclusions
        onProductChange={onProductChange}
        type={product.type}
        product={product}
        products={products}
        topOffset={inclusions ? 136 : 0}
      />
    </AriaModal>
  ) : null;
};

export default InclusionsModal;
