import React from 'react';
import { StandardText } from '../../../../common/atoms/typography';

const OneOffPaymentTitle = props => {
  return (
    <StandardText bold fontSize={3} lineHeight="32px" mb={3} {...props}>
      Make a one-off payment
    </StandardText>
  );
};

export default OneOffPaymentTitle;
