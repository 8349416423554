import useYourDetailsStep from '~maf/state/useYourDetailsStep';
import { APPLICATION_FORM_STEPS } from '~maf/constants';
import YourDetails from '~maf/steps/YourDetails';
import FamilyDetails from '~OMS/my-cover/change-cover/ApplicationForm/steps/FamilyDetails';
import useFamilyDetailsStep from '~maf/state/useFamilyDetailsStep';
import usePaymentsStep from '~OMS/my-cover/change-cover/ApplicationForm/state/usePaymentsStep';
import AdditionalDetails from '~OMS/my-cover/change-cover/ApplicationForm/steps/AdditionalDetails';
import MedicareDetails from '~maf/steps/Medicare';
import useMedicareDetails from '~maf/state/useMedicareStep';
import useAdditionalDetails from '~OMS/my-cover/change-cover/ApplicationForm/state/useAdditionalDetails';
import Payments from '~OMS/my-cover/change-cover/ApplicationForm/steps/Payments';

export default [
  {
    ...APPLICATION_FORM_STEPS.YOUR_DETAILS,
    Component: YourDetails,
    hook: useYourDetailsStep,
  },
  {
    ...APPLICATION_FORM_STEPS.FAMILY_DETAILS,
    Component: FamilyDetails,
    hook: useFamilyDetailsStep,
  },
  {
    ...APPLICATION_FORM_STEPS.MEDICARE,
    Component: MedicareDetails,
    hook: useMedicareDetails,
  },
  {
    ...APPLICATION_FORM_STEPS.PAYMENTS,
    Component: Payments,
    hook: usePaymentsStep,
  },
  {
    ...APPLICATION_FORM_STEPS.ADDITIONAL_DETAILS,
    label: 'Terms & conditions',
    Component: AdditionalDetails,
    hook: useAdditionalDetails,
  },
];
