import React, { useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import { InputControl } from '~common/molecules/form-controls';
import PaymentFrequencyField from '~OMS/payments/molecules/PaymentFrequencyField';
import Confirmation from '~common/molecules/Confirmation';
import TermsAndConditionsField from '~OMS/payments/molecules/TermsAndConditionsField';
import ConfirmButton from '~OMS/payments/atoms/ConfirmButton';
import CenterBox from '~common/atoms/CenterBox';
import { formatDate } from '~lib/dates';
import formModeSwitch from '~lib/hocs/formModeSwitch';
import BoldReadOnlyControl from '~common/molecules/form-controls/BoldReadOnlyControl';
import {
  getFrequencyLabel,
  getPaymentTypeLabel,
} from '~OMS/payments/organisms/PaymentPolicyForm/formMappers';
import RadioGroupControl from '~common/molecules/form-controls/RadioGroupControl';
import {
  PAYMENT_METHODS,
  PAYMENT_TYPE,
  paymentTypes,
} from '~OMS/payments/constants';
import { Alert } from '~common/molecules/AlertBox';
import CardNumberControl from '~OMS/payments/molecules/CardNumberControl';
import ExpiryDateControl from '~common/molecules/form-controls/ExpiryDateControl';
import directDebitFormValidator from '~OMS/payments/molecules/PaymentDirectDebitForm/validation';
import BsbControl from '~OMS/payments/molecules/BsbControl';
import { noop } from '~lib/util';

const CreditCardViewFields = ({ values }) => (
  <>
    <Field
      name="cardHolder"
      label="Name on card"
      labelPosition="left"
      component={BoldReadOnlyControl}
    />

    <Field
      name="cardNumber"
      label="Card number"
      labelPosition="left"
      component={BoldReadOnlyControl}
    />

    {values && values.expiry && (
      <Field
        name="expiry"
        label="Expiry date"
        labelPosition="left"
        component={BoldReadOnlyControl}
      />
    )}
  </>
);

const BankAccountViewFields = ({ values }) => (
  <>
    <Field
      name="accountName"
      label="Account name"
      labelPosition="left"
      component={BoldReadOnlyControl}
    />

    <Field
      name="bsb"
      label="BSB"
      labelPosition="left"
      halfWidth
      component={BoldReadOnlyControl}
    />

    {values && values.institution && (
      <Field
        name="institution"
        label="Institution"
        labelPosition="left"
        halfWidth
        component={BoldReadOnlyControl}
      />
    )}

    <Field
      name="accountNumber"
      label="Account number"
      labelPosition="left"
      halfWidth
      component={BoldReadOnlyControl}
    />
  </>
);

const ViewForm = ({ initialForm, onEditDetails, alert }) => (
  <Formik onSubmit={onEditDetails} initialValues={initialForm}>
    {({ values }) => (
      <Form className="full-width">
        <Field
          name="type"
          label="Payment type"
          labelPosition="left"
          component={BoldReadOnlyControl}
        />
        <Field
          name="frequency"
          label="Payment frequency"
          labelPosition="left"
          component={BoldReadOnlyControl}
        />

        {values.type === PAYMENT_TYPE.BANK_ACCOUNT ? (
          <BankAccountViewFields values={values} />
        ) : (
          <CreditCardViewFields values={values} />
        )}

        {alert && alert.type === 'success' && (
          <Alert mb={4} type="success">
            {alert.message}
          </Alert>
        )}

        <CenterBox>
          <ConfirmButton>Edit details</ConfirmButton>
        </CenterBox>
      </Form>
    )}
  </Formik>
);

const BankAccountEditFields = ({ values }) => (
  <>
    <Field
      name="accountName"
      label="Account name"
      labelPosition="left"
      bold
      component={InputControl}
    />

    <Field
      name="bsb"
      label="BSB"
      labelPosition="left"
      halfWidth
      bold
      component={BsbControl}
    />

    <Field
      name="accountNumber"
      label="Account number"
      labelPosition="left"
      halfWidth
      bold
      component={InputControl}
    />
  </>
);

const CreditCardEditFields = ({ values }) => (
  <>
    <Field
      name="cardHolder"
      label="Name on card"
      labelPosition="left"
      component={InputControl}
    />

    <Field
      name="cardNumber"
      label="Card number"
      labelPosition="left"
      component={CardNumberControl}
    />

    <Field
      name="expiry"
      label="Expiry date"
      labelPosition="left"
      component={ExpiryDateControl}
    />
  </>
);

const EditForm = ({
  onSubmit,
  onCancel,
  initialForm,
  alert,
  loading,
  isActivePaymentMethod,
  nonExistingBsb,
  onPaymentTypeChange,
  changePaymentMethodWarningText,
}) => {
  let setFormErrors = noop;

  useEffect(() => {
    if (nonExistingBsb) {
      setFormErrors({
        bsb: 'Please check this number',
      });
    }
  }, [nonExistingBsb, setFormErrors]);
  return (
    <Formik
      validate={directDebitFormValidator}
      onSubmit={onSubmit}
      initialValues={initialForm}
    >
      {({ values, isValid, setErrors }) => {
        setFormErrors = setErrors;
        return (
          <Form className="full-width">
            <Field
              name="type"
              render={props => (
                <RadioGroupControl
                  onSelect={onPaymentTypeChange}
                  label="Payment type"
                  labelPosition="left"
                  {...props}
                  options={paymentTypes}
                />
              )}
            />
            <PaymentFrequencyField
              paymentMethod={PAYMENT_METHODS.DIRECT_DEBIT}
            />

            {values.type === PAYMENT_TYPE.BANK_ACCOUNT ? (
              <BankAccountEditFields values={values} />
            ) : (
              <CreditCardEditFields values={values} />
            )}

            <TermsAndConditionsField name="termsAndConditions" />

            {changePaymentMethodWarningText && (
              <Alert mb={2} type={changePaymentMethodWarningText.type}>
                {changePaymentMethodWarningText.message}
              </Alert>
            )}

            {alert && alert.type !== 'success' && (
              <Alert type={alert.type}>{alert.message}</Alert>
            )}

            <Confirmation
              withoutCancel={!isActivePaymentMethod}
              loading={loading}
              disableConfirm={!isValid}
              onCancel={onCancel}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

const Index = formModeSwitch({
  edit: {
    Component: EditForm,
    mapInitialForm: (initialForm = {}) => {
      return {
        ...initialForm,
        bsb: initialForm.accountBSB,
        cardNumber: (initialForm.cardNumber || '').replace(/x/gi, '_'),
      };
    },
  },
  view: {
    Component: ViewForm,
    mapInitialForm: (initialForm = {}) => {
      return {
        ...initialForm,
        bsb: initialForm.accountBSB,
        date: formatDate(initialForm.date),
        frequency: getFrequencyLabel(initialForm),
        type: getPaymentTypeLabel(initialForm),
      };
    },
  },
});

export default Index;
