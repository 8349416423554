import React from 'react';
import { withTheme } from 'emotion-theming';
import { Flex, Box } from 'rebass';
import styled from '@emotion/styled';
import moment from 'moment';

import Icon from '~common/atoms/Icon';
import { H3, P2 } from '~common/atoms/typography';
import HeaderLine from '~common/atoms/HeaderLine';
import Loading from '~common/atoms/Loading';
import Row from '~common/molecules/Row';
import Col from '~common/molecules/Col';
import SubNav from '~common/molecules/SubNav';
import TabbedAccordion from '~OMS/my-cover/molecules/TabbedAccordion';
import MyInclusions from '~OMS/my-cover/organisms/MyInclusions';
import Link from '~common/atoms/Link';

import OrderCard from '../organisms/OrderCard';
import useWhatsCovered from '~OMS/my-cover/hooks/useWhatsCovered';
import { excessFilter, rebateTierFilter } from '~lib/filters';
import { path, pipe } from 'lodash/fp';
import { formatDate, wordyFormat } from '~lib/dates';
import PaymentDescription from '~common/molecules/PaymentDescription';
import PaymentDescriptionPartialSubsidy from '~common/molecules/PaymentDescriptionPartialSubsidy';
import { Button } from '~common/atoms/Button';

const MembershipRow = ({ label, value, loading }) => (
  <Flex mb="16px">
    <P2 color="neutral900" variant="medium" maxWidth="50%" minWidth="50%">
      {label}
    </P2>
    {loading ? (
      <Loading minHeight={0} size="small" />
    ) : (
      <P2 variant="medium" color="neutral500" ml="20px" width="auto">
        {value}
      </P2>
    )}
  </Flex>
);
const RIGHT_COL1_WIDTH = '50%';
const RIGHT_COL2_WIDTH = '50%';

const CoveredRow = ({ fullName, id, dob, theme }) => (
  <Flex py={10} justifyContent="space-between">
    <Box width={RIGHT_COL1_WIDTH}>
      <Flex alignItems="center">
        <Box pr={10} className="hide-mobile">
          <Icon name="avatar" originalColorSchema width="24px" height="24px" />
        </Box>
        <P2
          color="primary"
          variant="underline"
          as={Link}
          noStyles
          to="/oms/my-details"
          state={{ expandMember: id }}
        >
          {fullName}
        </P2>
      </Flex>
    </Box>
    <P2 variant="medium" color="neutral500" width={RIGHT_COL2_WIDTH}>
      {formatDate(dob)}
    </P2>
  </Flex>
);

const StyledHR = styled.div`
  margin: 40px 0;
  border-top: 1px solid ${props => props.theme.colors.neutral100};
  width: 100%;
`;

const WhatsCovered = ({ theme, products, location }) => {
  const { state, actions } = useWhatsCovered(products);

  if (state.error) {
    console.error(state.error);
    return null;
  }

  const {
    myCoverDetails,
    persons,
    extrasOnly,
    hospitalOnly,
    subNavItems,
    s2iMembership,
    isChangeCoverAllowed,
    isPartiallySubsidised,
    getPartiallySubsidisedSavingAmount,
  } = state;

  const rebateTierText = pipe(
    path('oms.membership'),
    rebateTierFilter
  )(myCoverDetails);

  const noExtras = hospitalOnly;

  return (
    <>
      <HeaderLine title="My Cover">
        <SubNav data={subNavItems} />
      </HeaderLine>
      {state.loading ? (
        <Loading pt={60} pb={30} />
      ) : (
        <>
          <Row pt={25}>
            <Col width={{ xs: 1, md: 1 / 2 }}>
              <H3 color="text2" variant="semiBold" mb="20px">
                Membership Details
              </H3>
              <MembershipRow
                label="Membership #"
                value={myCoverDetails.oms.membership.id}
              />
              <MembershipRow
                label="Membership type"
                value={
                  {
                    SINGLE: 'Single',
                    COUPLE: 'Couple',
                    FAMILY: 'Family',
                    SINGLE_PARENT: 'Single Parent',
                    SINGLE_PARENT_EXTENDED: 'Single Parent (Extended)',
                    FAMILY_EXTENDED: 'Family (Extended)',
                    SINGLE_PARENT_DISABILITY: 'Single Parent - Disability',
                    FAMILY_DISABILITY: 'Family - Disability',
                  }[myCoverDetails.oms.membership.type]
                }
              />
              {!extrasOnly && (
                <MembershipRow
                  loading={state.excessLoading}
                  label="Hospital Excess"
                  value={excessFilter(state.excess)}
                />
              )}
              <MembershipRow
                label="Government Rebate Tier"
                value={rebateTierText}
              />
              <MembershipRow
                label="Member since"
                value={wordyFormat(myCoverDetails.oms.membership.joinDate)}
              />
              <MembershipRow
                label="Cover last updated"
                value={moment(
                  myCoverDetails.oms.membership.lastUpdatedDate
                ).format('D MMMM YYYY')}
              />

              {myCoverDetails.oms.membership.nextCoverChangeDate && (
                <MembershipRow
                  label="Future Cover Change"
                  value={wordyFormat(
                    myCoverDetails.oms.membership.nextCoverChangeDate
                  )}
                />
              )}
            </Col>
            <Col width={{ xs: 1, md: 1 / 2 }} pt={{ xs: 30, md: 0 }}>
              <H3 color="text2" variant="semiBold" mb="16px">
                Who is covered
              </H3>
              <Flex justifyContent="space-between" pb={20}>
                <Box width={RIGHT_COL1_WIDTH}>
                  <P2 variant="semiBold" color="neutral500">
                    Member
                  </P2>
                </Box>
                <Box width={RIGHT_COL2_WIDTH}>
                  <P2 variant="semiBold" color="neutral500">
                    Date of Birth
                  </P2>
                </Box>
              </Flex>
              {persons.map((e, i) => (
                <CoveredRow
                  key={e.id}
                  fullName={`${e.fullName}`}
                  id={e.id}
                  dob={e.dob}
                  theme={theme}
                />
              ))}
            </Col>
          </Row>
          <Row
            mt="20px"
            flexDirection={{
              xs: 'column',
              tabletP: 'row',
            }}
            justifyContent="center"
          >
            <Col
              mb={{
                xs: '20px',
                tabletP: 0,
              }}
            >
              <OrderCard location={location} />
            </Col>
            <Col>
              <Button
                size="medium"
                variant="secondaryInverted"
                as={Link}
                to="/oms/my-details"
                width={{
                  xs: 1,
                  tabletP: 'auto',
                }}
              >
                My Details
              </Button>
            </Col>
          </Row>
          <Row>
            <Col width={1}>
              <StyledHR />
              <Flex
                mb="20px"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
                sx={{ gridGap: '20px' }}
              >
                <H3
                  variant="semiBold"
                  color="neutral900"
                  textAlign={{
                    xs: 'center',
                    md: 'left',
                  }}
                >
                  Payment Details
                </H3>
                <Button
                  size="medium"
                  variant="secondaryInverted"
                  as={Link}
                  to="/oms/my-details"
                  width={{
                    xs: 1,
                    tabletP: 'auto',
                  }}
                >
                  Manage payments
                </Button>
              </Flex>

              <Row justifyContent="space-between">
                <Col pt={10} width={{ xs: '100%', sm: 'auto' }}>
                  <PaymentDescription />
                </Col>
              </Row>

              {isPartiallySubsidised && (
                <Row justifyContent="flex-start">
                  <Col pt={10} width={{ xs: '100%', sm: 'auto' }}>
                    <Flex
                      flexWrap="wrap"
                      alignItems="center"
                      mt={{ xs: 2, sm: 0 }}
                      justifyContent={{ xs: 'center', sm: 'auto' }}
                      css={{
                        height: '40px',
                        color: theme.colors.text.light,
                      }}
                    >
                      <PaymentDescriptionPartialSubsidy
                        savingAmount={getPartiallySubsidisedSavingAmount}
                      />
                    </Flex>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>

          <Row>
            <Col width={1}>
              <StyledHR />
            </Col>
          </Row>

          <Row
            pb={35}
            flexDirection="row"
            alignItems="center"
            justifyContent={{
              xs: 'center',
              md: 'space-between',
            }}
          >
            <Col>
              <H3 variant="semiBold" color="neutral900">
                What am I covered for?
              </H3>
            </Col>
            {/*{!s2iMembership && (*/}
            {/*  <Col*/}
            {/*    mt={{*/}
            {/*      xs: 3,*/}
            {/*      md: 0,*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <Button*/}
            {/*      onClick={() => {*/}
            {/*        scrollTo('thingsYouShouldKnow');*/}
            {/*      }}*/}
            {/*      variant="primaryInverted"*/}
            {/*      size="medium"*/}
            {/*    >*/}
            {/*      Things you should know*/}
            {/*    </Button>*/}
            {/*  </Col>*/}
            {/*)}*/}
          </Row>
          <MyInclusions {...state} onInclusionToggle={actions.setActiveIndex} />
          <Row mt="32px" justifyContent="center">
            {!noExtras && (
              <Button
                mr={{
                  xs: 0,
                  tabletP: '20px',
                }}
                variant="secondary"
                as={Link}
                size="medium"
                to="/oms/extras-usage"
                width={{
                  xs: 1,
                  tabletP: 'auto',
                }}
                mb={{
                  xs: '20px',
                  tabletP: 0,
                }}
              >
                View Usage &amp; Limits
              </Button>
            )}
            {isChangeCoverAllowed && (
              <Button
                variant="secondaryInverted"
                as={Link}
                to="/oms/my-cover/change"
                size="medium"
                width={{
                  xs: 1,
                  tabletP: 'auto',
                }}
              >
                Compare Cover
              </Button>
            )}
          </Row>
          {!s2iMembership && (
            <>
              <Row>
                <Col width={1}>
                  <StyledHR />
                </Col>
              </Row>
              {/*<Flex>*/}
              {/*  <Box width={1}>*/}
              {/*    <TheGap*/}
              {/*      isChangeCoverAllowed={isChangeCoverAllowed}*/}
              {/*      plan={myCoverDetails.oms.membership.plan}*/}
              {/*    />*/}
              {/*  </Box>*/}
              {/*</Flex>*/}
              {/*<Row>*/}
              {/*  <Col width={1} py={40}>*/}
              {/*    <StyledHR {...theme} />*/}
              {/*  </Col>*/}
              {/*</Row>*/}
              <TabbedAccordion
                scrollName="thingsYouShouldKnow"
                theme={theme}
                plan={myCoverDetails.oms.membership.plan}
                heading="Things you should know"
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default withTheme(WhatsCovered);
