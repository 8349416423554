import { useMemo } from 'react';
import useAppFormAdditionalInformation from '~lib/hooks/sanity/useAppFormAdditionalInformation';

export default () => {
  const { nominatePersonRelationship } = useAppFormAdditionalInformation();

  const options = useMemo(() => {
    return nominatePersonRelationship.map(elem => ({
      value: elem.id,
      label: elem.title,
    }));
  }, [nominatePersonRelationship]);

  return options;
};
