import { useQuery } from '@apollo/client';
import { MembershipQuery } from 'queries/oms/common';
import {
  isBoth,
  isExtrasOnly,
  isHospitalOnly,
  isS2IMembership,
} from '~OMS/response-selectors';

const useMembership = options => {
  const { loading, error, data } = useQuery(MembershipQuery);
  let membership = null;
  let person = null;
  let isActive = true;
  let personIsActive = true;
  const isMaintenance = false; //TODO: move this to CMS

  if (!loading && !error && data && data.oms.membership) {
    membership = data.oms.membership;
    person = data.oms.person;

    if (membership.coverStatus !== 'ACTIVE') {
      isActive = false;
    }

    if (!person) {
      personIsActive = false;
    }
  }

  return {
    loading,
    error,
    membership,
    person,
    isActive,
    personIsActive,
    isMaintenance,
    isExtrasOnly: isExtrasOnly(data),
    isHospitalOnly: isHospitalOnly(data),
    isBoth: isBoth(data),
    isS2IMembership: isS2IMembership(data),
  };
};

export default useMembership;
