import React from 'react';
import { Box } from 'rebass';
import styled from '@emotion/styled';
import { StandardText } from '~common/atoms/typography';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';

const PromoBannerContainer = styled(Box)`
  background: ${props => props.theme.colors.primary};
  border-radius: 4px;
  padding: 16px;
`;

const Description = styled(SanityRichTextContent)`
  && * {
    font-size: 14px;
    color: ${props => props.theme.colors.white};
    ${props => props.theme.mq.tabletP} {
      font-size: 16px;
    }
  }
`;

const PromoBanner = ({ promotions, ...props }) => {
  return (
    !!promotions?.eligible && (
      <PromoBannerContainer {...props}>
        <StandardText
          bold
          mb={2}
          color="white"
          fontSize={{
            xs: 18,
            tabletP: 20,
          }}
        >
          {promotions.content?.banner?.title}
        </StandardText>
        <Description content={promotions.content?.banner?.description} />
      </PromoBannerContainer>
    )
  );
};

export default PromoBanner;
