import styled from '@emotion/styled';

const BULLET_SPACING = 8;

export const List = styled.ul`
  list-style-type: ${props => (props.useNumbers ? 'decimal' : 'none')};
  text-align: left;
  padding-left: ${props => (props.useNumbers ? 16 : 0)}px;
  color: ${props => props.color || props.theme.colors.neutral500};
  &.list-block {
    margin-bottom: 16px;
    .list-block {
      margin-bottom: 0px;
    }
  }

  li .text-block {
    margin-bottom: 0;
  }
  li {
    ::marker {
      font-weight: 700;
    }
    padding-left: ${props => (props.useNumbers ? BULLET_SPACING : 0)}px;

    margin-bottom: 16px;
    ${props =>
      !props.useNumbers
        ? `
        display: flex;
        align-items: flex-start;
        ::before {
          content: '';
          background: ${props.color || props.theme.colors.neutral500};
          height: 3px;
          width: 3px;
          border-radius: 50%;
          min-height: 3px;
          min-width: 3px;
          margin-right: ${BULLET_SPACING + 4}px;
          transform: translateY(10px);
        }`
        : ''}
  }

  li:last-child {
    margin-bottom: 0;
  }

  li:last-child {
    margin-bottom: 0;
  }

  li:first-child {
    margin-top: 20px;
  }
`;

export const ListItem = styled.li`
  > ul,
  > ol {
    margin-top: 16px;
  }
  line-height: 1.5;
`;
