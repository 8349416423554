import React from 'react';
import styled from '@emotion/styled';
import Block from '~common/atoms/Block';
import { Box, Flex } from 'rebass';
import { useThemeUI } from 'theme-ui';
import CallUsWithIcon from '~common/molecules/CallUsWithIcon';
import EmailUsWithIcon from '~common/molecules/EmailUsWithIcon';
import SanityRichTextContent from '~common/molecules/SanityRichTextContent';
import useContactDetails from '~lib/hooks/sanity/useContactDetails';
import { css } from '@emotion/core';
import { useLaptopScreenAndBigger } from '~lib/responsive';
import { P2, StandardText } from '~common/atoms/typography';
import Link from '../Link';
import Logo from '~common/atoms/Logo';
import MembersHealthFundLogo from '~common/molecules/MembersHealthFundLogo';
import GetAQuoteWithIcon from '~common/molecules/GetAQuoteWithIcon';

const FooterColumn = props => (
  <Flex
    flexDirection="column"
    width={{
      xs: '100%',
      sm: 222,
      md: 286,
    }}
    {...props}
  />
);

const OtherInformation = props => {
  const { abn, organisationName } = useContactDetails();

  return (
    <Flex flexDirection="column" {...props}>
      <ContactDetailsText mb={12}>
        © {new Date().getFullYear()} {organisationName}
      </ContactDetailsText>
      <ContactDetailsText>ABN {abn}</ContactDetailsText>
    </Flex>
  );
};

const ContactDetailsText = styled(StandardText)`
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 1.4;
  font-weight: 500;
  color: ${props => props.theme.colors.white};
`;

const AlignWithMobileLogo = styled(Box)`
  margin-left: 42px;
  ${props => props.theme.mq.tabletP} {
    margin-left: unset;
  }
`;

const StyledLogo = styled(Logo)`
  margin-right: 16px;

  svg {
    max-width: 100%;
  }
`;

const ContentFooter = ({ data: { menu, logo, disclaimer }, className }) => {
  const { theme } = useThemeUI();
  const isLaptopScreenAndBigger = useLaptopScreenAndBigger();

  return (
    <Block
      className={className}
      ignoreCampaignLayout
      pt={{
        xs: 40,
        md: 64,
      }}
      pb={64}
      mb={0}
      bg={theme.colors.primary}
    >
      <Flex width="100%" flexDirection="column">
        <Flex
          width="100%"
          flexWrap={{
            xs: 'nowrap',
            sm: 'wrap',
            md: 'nowrap',
          }}
          flexDirection={{
            xs: 'column',
            sm: 'row',
          }}
          justifyContent={{
            xs: 'flex-start',
            sm: 'space-between',
          }}
        >
          <FooterColumn
            width={{
              xs: '100%',
              sm: '100%',
              md: 286,
            }}
          >
            <StyledLogo alternate height={50} mobileHeight={40} />
            <AlignWithMobileLogo>
              <MembersHealthFundLogo mt={8} />
              {isLaptopScreenAndBigger && <OtherInformation mt={20} />}
            </AlignWithMobileLogo>
          </FooterColumn>

          <FooterColumn
            pt={{
              xs: 32,
              md: 'inherit',
            }}
          >
            <AlignWithMobileLogo>
              <CallUsWithIcon
                mb="16px"
                textProps={{
                  fontWeight: '600',
                  fontSize: '16px',
                }}
                iconProps={{
                  fill: theme.colors.white,
                  stroke: 'none',
                }}
              />
              <EmailUsWithIcon
                mb="16px"
                isAnomUser
                textProps={{
                  fontWeight: '600',
                  fontSize: '16px',
                }}
                iconProps={{
                  fill: theme.colors.white,
                  stroke: 'none',
                }}
              />
              <GetAQuoteWithIcon
                textProps={{
                  fontWeight: '600',
                  fontSize: '16px',
                }}
                iconProps={{
                  fill: theme.colors.white,
                  width: '22px',
                  height: '16px',
                  stroke: 'none',
                }}
              />
            </AlignWithMobileLogo>
          </FooterColumn>

          {menu?.items?.map(menu => (
            <AlignWithMobileLogo>
              <FooterColumn
                key={menu._key}
                mt={{
                  xs: 24,
                  sm: 'inherit',
                }}
                pt={{
                  xs: 24,
                  sm: 32,
                  md: 'inherit',
                }}
                css={css`
                  ${theme.mq.smInverted} {
                    border-top: solid 1px rgba(255, 255, 255, 0.25);
                  }
                `}
              >
                {menu.items?.map((menu, i, { length }) => (
                  <P2
                    color="white"
                    variant="semiBold"
                    key={menu._key}
                    as={Link}
                    data={menu.link}
                    mb={length - 1 === i ? '0' : '16px'}
                  >
                    {menu.title}
                  </P2>
                ))}
              </FooterColumn>
            </AlignWithMobileLogo>
          ))}
        </Flex>
        {!isLaptopScreenAndBigger && (
          <AlignWithMobileLogo>
            <OtherInformation
              mt={24}
              pt={24}
              sx={{
                borderTop: '1px solid rgba(255, 255, 255, 0.25)',
              }}
            />
          </AlignWithMobileLogo>
        )}
      </Flex>
      {disclaimer && (
        <Flex mt={40} color="white">
          <SanityRichTextContent
            content={disclaimer?.blockContent}
            forceColor="white"
            css={css`
              &&& {
                p {
                  font-size: ${theme.fontSizes[2]}px;
                  color: ${theme.colors.white} !important;
                }

                a {
                  color: ${theme.colors.white} !important;
                  text-decoration: underline;
                }
              }
            `}
          />
        </Flex>
      )}
    </Block>
  );
};

export default ContentFooter;
