import React from 'react';
import Icon from '../../../common/atoms/Icon';
import styled from '@emotion/styled';
import { useThemeUI } from 'theme-ui';

const StyledSearchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;

  ${props => props.theme.mq.md} {
    margin-left: 24px;
  }
`;

const SearchButton = ({ onClick }) => {
  const { theme } = useThemeUI();
  return (
    <StyledSearchButton onClick={onClick}>
      <Icon
        name="search"
        fill={theme.colors.primary}
        width="24px"
        height="24px"
        clickable
      />
    </StyledSearchButton>
  );
};

export default SearchButton;
