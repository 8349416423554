import React from 'react';
import Layout from 'components/Layout';
import { withTheme } from 'emotion-theming';
import Navbar from '~common/organisms/Navbar';
import MyDocuments from '~OMS/my-documents/templates/MyDocuments';
import CheckMembership from '~common/organisms/CheckMembership';
import OmsFooter from '~OMS/OmsFooter';

const MyDocumentsPage = props => {
  return (
    <Layout>
      <CheckMembership>
        <Navbar location={props.location} />
        <MyDocuments />
        <OmsFooter mt={155} />
      </CheckMembership>
    </Layout>
  );
};

export default withTheme(MyDocumentsPage);
