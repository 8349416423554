import React from 'react';
import Icon from '~common/atoms/Icon';
import styled from '@emotion/styled';
import ByBrand from '~common/atoms/ByBrand';
import { useThemeUI } from 'theme-ui';

const DiscountBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  line-height: 1.3;
  font-size: ${props => props.theme.fontSizes[1]}px;
  color: ${props => props.color};
  font-weight: 500;
  text-align: left;
  margin-top: 1em;
  cursor: ${props => (props.clickable ? 'pointer' : 'default')};
`;

const DiscountBar = ({
  onClick,
  paymentFrequency,
  color,
  iconProps,
  ...props
}) => {
  const { theme } = useThemeUI();
  color = color || theme.colors.white;
  return (
    <ByBrand
      dhf={() => (
        <DiscountBarContainer
          onClick={onClick}
          clickable={paymentFrequency !== 'YEARLY'}
          color={color}
          {...props}
        >
          <Icon
            name="discount"
            width="24px"
            height="24px"
            fill={color}
            style={{ marginRight: '16px' }}
            {...iconProps}
          />

          {paymentFrequency === 'YEARLY'
            ? 'Your quote includes a discount of 2.5% as you have selected to pay annually'
            : 'Pay annually and receive a 2.5% discount'}
        </DiscountBarContainer>
      )}
    />
  );
};

export default DiscountBar;
