import React from 'react';
import { P3, StandardText } from '~common/atoms/typography';
import Link from '~common/atoms/Link';
import { Flex } from 'rebass';
import { FormPanel } from '../../common/Panel';
import {
  DropDownControl,
  FancyCheckboxControl,
} from '~common/molecules/form-controls';
import defaultYesNoOptions from '../../common/defaultYesNoOptions';
import tiers from '~lib/constants/tiers';
import ClaimantDetails from './ClaimantDetails';
import PolicyIndividuals from './PolicyIndividuals';
import Declaration from './Declaration';
import { Alert } from '~common/molecules/AlertBox';
import AppFormLabel from '~common/molecules/FormLabel';

const RebateApplicationForm = ({
  handleChange,
  Field,
  values,
  selectedStatus,
  yourDetailsContext,
  familyDetailsContext,
  noPolicyCoveredMessage,
}) => {
  return (
    <FormPanel title="Rebate application">
      <StandardText mb={25} fontSize={2} lineHeight="21px">
        Application to receive the Australia Government Rebate on Private Health
        Insurance as reduced premium.
      </StandardText>
      <AppFormLabel mb={25}>
        For more information about the Australian Government Rebate on Private
        Health Insurance, go to{' '}
        <Link external to="https://www.privatehealth.gov.au">
          www.privatehealth.gov.au
        </Link>
      </AppFormLabel>

      <Field
        onChange={handleChange}
        name="isPolicyCovered"
        component={FancyCheckboxControl}
        options={defaultYesNoOptions}
        label={({ LabelContainer }) => (
          <Flex>
            <LabelContainer>Are you covered by the policy?</LabelContainer>
          </Flex>
        )}
      />

      {values.isPolicyCovered && (
        <>
          <Field
            name="tier"
            label="Income rebate level"
            onChange={handleChange}
            render={fieldOptions => (
              <DropDownControl
                placeholder={
                  values.tier === '' ? 'Select an income level' : undefined
                }
                {...fieldOptions}
              >
                {(tiers[selectedStatus] || []).map(tier => (
                  <option key={tier.value} value={tier.value}>
                    {tier.description} → {tier.label}
                  </option>
                ))}
              </DropDownControl>
            )}
          />
          <P3 mt={2}>
            <strong>Note:</strong> If at any stage you wish to nominate a new
            income tier or stop receiving the Australian Government Rebate as a
            reduced premium, you must notify your health fund as soon as
            possible.
          </P3>
          <ClaimantDetails
            privateHealthDetails={yourDetailsContext.privateHealthDetailsForm}
            contactDetails={yourDetailsContext.contactDetailsForm}
            personalDetails={yourDetailsContext.personalDetailsForm}
            mt="22px"
          />
          <PolicyIndividuals
            partner={familyDetailsContext.partnerDetails.form}
            dependants={familyDetailsContext.dependantDetails.forms}
          />
          <Declaration FormField={Field} handleChange={handleChange} />
        </>
      )}

      {values.isPolicyCovered === false && (
        <Alert type="error">{noPolicyCoveredMessage}</Alert>
      )}
    </FormPanel>
  );
};

export default RebateApplicationForm;
