import { useState, useEffect } from 'react';

let listeners = [];
let state = {
  isActive: false,
  isAnimating: true,
};

const toggleState = newState => {
  state = { ...state, isActive: newState };
  listeners.forEach(listener => {
    listener(state);
  });
};

export default function useMenuState() {
  const newListener = useState()[1];
  useEffect(() => {
    listeners.push(newListener);

    return () => {
      listeners = listeners.filter(listener => listener !== newListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, toggleState];
}
