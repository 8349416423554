import React from 'react';
import { CreateControl } from './Control';
import { StandardText } from '../../atoms/typography';
import { Flex } from 'rebass';
import { MaskedInput } from '../../atoms/Input';
import styled from '@emotion/styled';
const DIVIDER = '/';

const mask = [/\d/, /\d/, DIVIDER, /\d/, /\d/];

const ExpiryDate = CreateControl(props => {
  return (
    <Flex width={1 / 3} flexDirection="column">
      <StandardText mb={2} fontSize={1}>
        MM/YY
      </StandardText>
      <MaskedInput mask={mask} {...props} />
    </Flex>
  );
});

const ExpiryDateControl = styled(ExpiryDate)`
  .label {
    transform: translateY(12px);
  }
`;

export default ExpiryDateControl;
