import React, { useMemo } from 'react';
import { Flex, Box } from 'rebass';
import styled from '@emotion/styled';
import { STEP_STATE } from '~lib/constants';
import { P3 } from '~common/atoms/typography';

const StepGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(${props => props.stepCount}, 1fr);
  grid-column-gap: 4px;
`;

const StepLine = styled(Box)`
  width: 100%;
  height: 5px;
  transition: opacity 300ms ease-in-out;
  background: ${props => props.theme.colors.primary};
  opacity: ${props =>
    [
      STEP_STATE.ACTIVE,
      STEP_STATE.COMPLETED_ACTIVE,
      STEP_STATE.COMPLETED_INACTIVE,
    ].includes(props.state)
      ? 1
      : 0.15};
`;

const StepLabel = styled(P3)`
  color: ${props => props.theme.colors.primary};
  opacity: ${props => (props.active ? 1 : 0.75)};
  transition: opacity 300ms ease-in-out;
`;

const Step = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CurrentStepIndicator = styled(Box)`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 5px 0 5px;
  border-color: ${props => props.theme.colors.primary} transparent transparent
    transparent;
  position: absolute;
  top: -15px;
`;

const MobileStepper = ({ steps = [], onStepClick, ...props }) => {
  const activeStepIndex = useMemo(() => {
    return steps.findIndex(step =>
      [STEP_STATE.COMPLETED_ACTIVE, STEP_STATE.ACTIVE].includes(step.state)
    );
  }, [steps]);
  return (
    <Box {...props}>
      <StepGrid stepCount={steps.length}>
        {steps.map((step, index) => (
          <Step>
            {index === activeStepIndex && <CurrentStepIndicator />}
            <StepLine
              onClick={() => {
                onStepClick(step);
              }}
              state={step.state}
            />
          </Step>
        ))}
      </StepGrid>
      <Flex mt="8px" justifyContent="space-between">
        <StepLabel active variant="semiBold">
          {steps?.[activeStepIndex]?.label}
        </StepLabel>
        {activeStepIndex < steps.length - 1 && (
          <StepLabel>Next: {steps?.[activeStepIndex + 1]?.label}</StepLabel>
        )}
      </Flex>
    </Box>
  );
};

export default MobileStepper;
