import React from 'react';
import {
  ExpiryDateControl,
  InputControl,
} from '~common/molecules/form-controls';
import CardNumberControl from '~OMS/payments/molecules/CardNumberControl';
import PaymentFrequencyField from './PaymentFrequencyField';
import PreferredDebitDayField from './PreferredDebitDayField';
import CvvControl from '~OMS/payments/molecules/CvvControl';
import InfoIcon from '~common/molecules/InfoIcon';
import { detectCard } from '~OMS/payments/cardDetector';
import { Flex } from 'rebass';
import styled from '@emotion/styled';

const StyledCvvControl = styled(CvvControl)`
  &&& .detected-card-icons {
    ${props => props.theme.mq.tabletP} {
      right: 35%;
    }
  }
`;

const CreditCardForm = ({
  Field,
  handleChange,
  values,
  debitDayDifferentToStartCoverDate,
}) => {
  return (
    <>
      <Field
        name="cardHolder"
        label="Name on card"
        component={InputControl}
        onChange={handleChange}
        focus
        trim
      />
      <Field
        name="cardNumber"
        onChange={handleChange}
        label="Card number"
        component={CardNumberControl}
      />

      <Field
        name="cvv"
        onChange={handleChange}
        labelPosition="top"
        label={({ LabelContainer }) => (
          <Flex alignItems="center">
            <LabelContainer>CVV</LabelContainer>{' '}
            <InfoIcon
              tooltip="Your CVV is the 3 digit number on the back of your card"
              position="right"
              ml={2}
            />
          </Flex>
        )}
        card={detectCard(values.cardNumber)}
        cardsClassName="detected-card-icons"
        component={StyledCvvControl}
      />

      <Field
        name="expiry"
        label="Expiry date"
        component={ExpiryDateControl}
        onChange={handleChange}
      />

      <PaymentFrequencyField onChange={handleChange} />
      <PreferredDebitDayField
        debitDayDifferentToStartCoverDate={debitDayDifferentToStartCoverDate}
        onChange={handleChange}
      />
    </>
  );
};

export default CreditCardForm;
