import React, { useRef, useState } from 'react';
import { Flex } from 'rebass';
import CenterBox from '~common/atoms/CenterBox';
import { mapWithLast } from '~lib/util';
import SubMenu from './SubMenu';
import HeaderBlock from './HeaderBlock';
import MemberLoginButton from '../MemberLoginButton';
import { StyledLink, MenuItem } from '../Styles';
import { path } from 'lodash/fp';
import Logo from '~common/atoms/Logo';
import CallUsBox from '../CallUsBox';
import GetQuoteButton from '../GetQuoteButton';
import { css } from '@emotion/core';
import ArrowPointer from '../ArrowPointer';
import SearchPanel from '../SearchPanel';
import useToggle from '~lib/hooks/useToggle';
import SearchButton from '../SearchButton';
import useStoredSearch from '~lib/hooks/useStoredSearch';

const firstLevelMainMenu = mainMenu => mainMenu.items;

const mapFirstLevelMainMenu = (mainMenu, cb) =>
  mapWithLast(firstLevelMainMenu(mainMenu), cb);

const DesktopHeader = ({
  data: { topMenu = {}, mainMenu = {}, logo },
  next = {},
  metaData = {
    page: {},
  },
  removeBottomMargin,
  ...props
}) => {
  const [selectedMenuItem, selectMenuItem] = useState();
  const [
    isSearchPanelShown,
    toggleSearchPanel,
    showSearchPanel,
    hideSearchPanel,
  ] = useToggle(false);

  useStoredSearch({
    onInitialStoredSearch: showSearchPanel,
  });

  const closingSubMenu = useRef(false);

  const setClosingSubMenu = () => {
    closingSubMenu.current = true;
  };

  const unsetClosingSubMenu = () => {
    closingSubMenu.current = false;
  };

  const closeSubMenu = (waitBeforeClose = true) => {
    if (!waitBeforeClose) {
      selectMenuItem(false);
      return;
    }
    setClosingSubMenu();
    setTimeout(() => {
      if (closingSubMenu.current) {
        selectMenuItem(false);
      }
    }, 500);
  };

  const openSubMenu = item => {
    unsetClosingSubMenu();
    selectMenuItem(item);
  };

  return (
    <>
      <HeaderBlock ignoreCampaignLayout {...props} mb={0}>
        <Flex justifyContent="space-between" alignItems="center" mb={20}>
          <Flex>
            <Logo logo={logo} />
          </Flex>
          <Flex sx={{ gridGap: 32 }} justifyContent="space-between">
            <Flex sx={{ gridGap: 32 }}>
              {(topMenu.items || []).map(item => (
                <MenuItem
                  header
                  whenActiveCss={css`
                    font-weight: 700 !important;
                  `}
                  key={item._key}
                  data={item.link}
                >
                  {item.title}
                </MenuItem>
              ))}
            </Flex>
            <CallUsBox />
          </Flex>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex
            onMouseLeave={closeSubMenu}
            flex="1 0 59%"
            justifyContent="space-between"
          >
            {mapFirstLevelMainMenu(mainMenu, item => (
              <Flex
                css={{
                  position: 'relative',
                }}
                flexDirection="column"
                justifyContent="center"
                key={item._key}
              >
                <MenuItem
                  onMouseEnter={() => openSubMenu(item)}
                  onTouchStart={() => openSubMenu(item)}
                  as={!item.items ? StyledLink : undefined}
                  data={item.link || path('items[0].link')(item)}
                >
                  {item.title}
                </MenuItem>
                {selectedMenuItem === item && (
                  <CenterBox height={0}>
                    <ArrowPointer />
                  </CenterBox>
                )}
              </Flex>
            ))}
          </Flex>
          <Flex flex="0 1 41%" justifyContent="flex-end">
            <GetQuoteButton minWidth={137} mr={12} />
            <MemberLoginButton minWidth={137} />
            <SearchButton
              onClick={() => {
                toggleSearchPanel();
              }}
            />
          </Flex>
        </Flex>
      </HeaderBlock>
      <SubMenu
        mb={
          metaData.page?.slug?.current === 'home' || removeBottomMargin ? 0 : 50
        }
        visible={selectedMenuItem}
        onMouseLeave={() => {
          closeSubMenu();
        }}
        onMouseEnter={() => {
          unsetClosingSubMenu();
        }}
        onClose={() => closeSubMenu()}
        menu={selectedMenuItem}
      />
      {isSearchPanelShown && (
        <SearchPanel
          onClose={() => {
            hideSearchPanel();
          }}
          name="search-box"
        />
      )}
    </>
  );
};

export default DesktopHeader;
