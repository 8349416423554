import { useEffect } from 'react';
import { APPLICATION_FORM_STEPS } from '../../constants';
import { STEP_STATE } from '~lib/constants';
import { useMemo, useState } from 'react';
import { useCoverProducts } from '~lib/CoverProducts';
import { path, whereEq } from 'lodash/fp';
import useStepProvider from '../useStepProvider';
import useContactDetails from '~lib/hooks/sanity/useContactDetails';
import useLocalStorage from '~lib/hooks/useLocalStorage';
import useEditCoverModal from './useEditCoverModal';
import usePromotions from '~lib/hooks/sanity/usePromotions';
import useSaveApplicationForm from './useSaveApplicationForm';
import useRetrivedApplicationForm from './useRetrivedApplicationForm';
import {
  criteriaToQuery,
  getPromotionProductType,
  hasPartnerIncluded,
  isProfessionEligible,
} from '../../common/appFormUtills';
import useLhcLoading from './useLhcLoading';
import { useAdvancedQuoteQuery } from '~lib/CoverProducts/useQuoteQuery';
import useSubmitApplicationForm from './useSubmitApplicationForm';
import useCriteria from './useCriteria';
import useYouthDiscountNotification from './useYouthDiscountNotification';
import enumeratedSteps from './enumeratedSteps';
import useStepper from './useStepper';
import messages from './messages';
import useExclusivePromotions from '~lib/hooks/sanity/useExclusivePromotions';
import useAvantExclusivePromotions from '~lib/hooks/sanity/useAvantExclusivePromotions';

export default ({ location }) => {
  const contactDetails = useContactDetails();

  const [confirmedSteps, setConfirmedSteps, removeConfirmedSteps] =
    useLocalStorage('appForm:confirmedSteps', {});

  const { setCriteria, criteria, removeCriteria } = useCriteria();

  const { data: quoteData, loading: quoteLoading } = useAdvancedQuoteQuery(
    criteriaToQuery(criteria),
    {
      skip: !criteria,
    }
  );

  const coverProducts = useCoverProducts(criteria, {
    onProductChange: (type, code) => {
      setCriteria(current => ({
        ...current,
        [`${type}Product`]: code,
      }));
    },
    onProductTypeChange: (type, rateType) => {
      const newCriteria = {
        ...criteria,
        rateType,
      };
      if (type === 'extras') {
        newCriteria.hospitalProduct = undefined;
      } else if (type === 'hospital') {
        newCriteria.extrasProduct = undefined;
      }

      setCriteria(newCriteria);
    },
  });

  const status = path('status.value')(criteria);
  const isFamilyPackage = status !== 'SINGLE';
  const isCouplePackage = status === 'COUPLE';
  const isDependantsPackage = ['FAMILY', 'SINGLE_PARENT'].includes(status);
  const hasPartner = hasPartnerIncluded(criteria);
  const isEfc = path('efc.value')(criteria);
  const hasLhcApplied = !!path('maf.advancedQuote.lhcPercentage')(quoteData);
  const eligibilityValueRegex = new RegExp(/^\d+\.\s*|\s*/g);

  const [activeStep, setActiveStep] = useState(
    APPLICATION_FORM_STEPS.ELIGIBILITY
  );

  const { activeStepProvider, steps, allSteps, stepProvider } = useStepProvider(
    {
      criteria: criteria || {},
      setCriteria,
      confirmedSteps,
      activeStep,
      isFamilyPackage,
      isCouplePackage,
      isDependantsPackage,
      status,
      isEfc,
      hasPartner,
      hasLhcApplied,
      allStepsEnumerated: enumeratedSteps,
    }
  );

  const { clearAllSteps, selectStep, confirmStep, goToNextStep } = useStepper({
    defaultSelectedStep: APPLICATION_FORM_STEPS.ELIGIBILITY,
    confirmedSteps,
    activeStepProvider,
    activeStep,
    setActiveStep,
    setConfirmedSteps,
    steps,
    allSteps,
  });

  useYouthDiscountNotification(criteria);

  useRetrivedApplicationForm({
    retrievedData: path('state.maf')(location),
    setCriteria,
    allSteps,
  });

  const [savedJoinDate, setSavedJoinDate] = useState(null);
  const [savedActivePromotion, setSavedActivePromotion] = useState();

  const total = useMemo(() => {
    if (!criteria || !quoteData) {
      return undefined;
    }

    const { rebatePercentage, totals } = quoteData.maf.advancedQuote;
    return {
      rebatePercentage,
      tier: path('tier.value')(criteria),
      price: totals.net,
      paymentFrequency: path('paymentFrequency')(criteria),
    };
  }, [quoteData, criteria]);

  const hospitalTotal = useMemo(() => {
    if (!criteria || !criteria.hospitalProduct || !quoteData) {
      return undefined;
    }
    const { hospital } = quoteData.maf.advancedQuote;
    return hospital.net;
  }, [quoteData, criteria]);

  const extrasTotal = useMemo(() => {
    if (!criteria || !criteria.extrasProduct || !quoteData) {
      return undefined;
    }
    const { extras } = quoteData.maf.advancedQuote;
    return extras.net;
  }, [quoteData, criteria]);

  const { saveApplicationForm, saving, formId, clearSaveApplicationCache } =
    useSaveApplicationForm({
      stepProvider,
      criteria,
      steps,
      contactDetails,
    });

  const isValid = steps.every(step => step.isValid);

  const { openEditCoverModal } = useEditCoverModal({
    stepProvider,
    criteria,
    setCriteria,
    setActiveStep,
  });

  useEffect(() => {
    if (
      !steps.find(
        whereEq({
          value: activeStep.value,
        })
      )
    ) {
      const firstUncompletedStep = steps.find(
        whereEq({
          state: STEP_STATE.INACTIVE,
        })
      );
      setActiveStep(firstUncompletedStep);
    }
  }, [steps.length]);

  const eligibilityContext = stepProvider.get(
    APPLICATION_FORM_STEPS.ELIGIBILITY.value
  ).context;

  const promotions = usePromotions({
    variables: {
      input: {
        productType: getPromotionProductType(criteria),
        joinDate:
          savedJoinDate ||
          stepProvider.get(APPLICATION_FORM_STEPS.YOUR_DETAILS.value).context
            .privateHealthDetailsForm.values.startCoverDate,
        lens: 'applicationForm',
      },
    },
  });

  const exclusivePromotions = useExclusivePromotions({
    variables: {
      input: {
        productType: getPromotionProductType(criteria),
        joinDate:
          savedJoinDate ||
          stepProvider.get(APPLICATION_FORM_STEPS.YOUR_DETAILS.value).context
            .privateHealthDetailsForm.values.startCoverDate,
        promotionCode: stepProvider.get(
          APPLICATION_FORM_STEPS.ADDITIONAL_DETAILS.value
        ).context.form.values.exclusivePromotionCode,
      },
    },
  });

  const avantExclusivePromotion = useAvantExclusivePromotions({
    startCoverDate:
      savedJoinDate ||
      stepProvider.get(APPLICATION_FORM_STEPS.YOUR_DETAILS.value).context
        .privateHealthDetailsForm.values.startCoverDate,
    isAvantGroupMember: stepProvider.get(
      APPLICATION_FORM_STEPS.ADDITIONAL_DETAILS.value
    ).context?.form?.values?.isAvantGroupMember,
    isPartnerAvantGroupMember: stepProvider.get(
      APPLICATION_FORM_STEPS.ADDITIONAL_DETAILS.value
    ).context?.form?.values?.isPartnerAvantGroupMember,
    productType: getPromotionProductType(criteria),
    isProfessionEligible: isProfessionEligible(
      path('form.values.profession.value')(eligibilityContext)?.replace(
        eligibilityValueRegex,
        ''
      ),
      path('form.values.profession.optionValue')(eligibilityContext)?.replace(
        eligibilityValueRegex,
        ''
      )
    ),
  });

  const clearApplicationForm = () => {
    setSavedActivePromotion(activePromotion);
    setSavedJoinDate(
      stepProvider.get(APPLICATION_FORM_STEPS.YOUR_DETAILS.value).context
        .privateHealthDetailsForm.values.startCoverDate
    );
    clearAllSteps();
    removeCriteria();
    removeConfirmedSteps();
    clearSaveApplicationCache();
  };

  const medicareRebateInactive =
    stepProvider.get(APPLICATION_FORM_STEPS.MEDICARE.value).context.form.values
      .claimRebate === false;

  const { submitApplicationForm, reference, applicationSent, submitting } =
    useSubmitApplicationForm({
      criteria,
      formId,
      steps,
      clearApplicationForm,
    });

  useLhcLoading({
    setCriteria,
    criteria,
    steps: allSteps,
    hasPartner,
    applicationSent,
  });

  const toggleRates = rateType => {
    setCriteria(currentState => ({
      ...currentState,
      resultType: rateType,
    }));
  };

  const activePromotion = (() => {
    if (avantExclusivePromotion?.isEligible) {
      return avantExclusivePromotion;
    }

    if (promotions?.data?.eligible) {
      return {
        isEligible: true,
        confirmationPageMessage:
          promotions.data.content.confirmationPageMessage,
      };
    }
  })();

  console.log('ACTIVE PROMOTION', activePromotion);

  return {
    activeStepProvider,
    stepProvider,
    state: {
      hasLhcApplied,
      total,
      hospitalTotal,
      extrasTotal,
      steps,
      criteria,
      activeStep,
      quoteLoading,
      quoteData: quoteData?.maf?.advancedQuote,
      status,
      hasPartner,
      saving,
      productSelections: coverProducts.selections,
      isFamilyPackage,
      isCouplePackage,
      isDependantsPackage,
      applicationSent,
      submitting,
      isValid,
      isEfc,
      medicareRebateInactive,
      promotions,
      reference,
      exclusivePromotions,
      avantExclusivePromotion,
      activePromotion,
      savedActivePromotion,
    },
    actions: {
      submitApplicationForm,
      selectStep,
      goToNextStep,
      confirmStep,
      saveApplicationForm,
      clearAllSteps,
      clearApplicationForm,
      openEditCoverModal,
      toggleRates,
    },
    messages: messages({ hasLhcApplied }),
  };
};
