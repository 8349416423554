import { FormValidator, required } from '~lib/formValidation';
import {
  accountNameValidator,
  creditCardValidation,
} from '~OMS/payments/validators';
import { PAYMENT_TYPE, termsAndConditionsError } from '~OMS/payments/constants';
import { dissoc } from 'lodash/fp';

const commonValidation = {
  // date: required('Payment date is required'),
  termsAndConditions: required(termsAndConditionsError),
};

const bankAccountFormValidation = {
  ...commonValidation,
  accountName: value => {
    return accountNameValidator().validateSync(value);
  },
  bsb: required('BSB is required'),
  accountNumber: required('Account number is required'),
};

const creditCardFormValidation = dissoc('cvv', {
  ...commonValidation,
  ...creditCardValidation,
});

export default FormValidator({
  validation: form =>
    ({
      [PAYMENT_TYPE.BANK_ACCOUNT]: bankAccountFormValidation,
      [PAYMENT_TYPE.CREDIT_CARD]: creditCardFormValidation,
    }[form.type]),
});
