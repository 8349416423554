import React from 'react';
import Layout from 'components/Layout';
import RequestAccessWrapper from '~OMS/SSO/organisms/RequestAccessWrapper';
import { withTheme } from 'emotion-theming';

const RequestAccess = props => {
  return (
    <Layout>
      <RequestAccessWrapper />
    </Layout>
  );
};

export default withTheme(RequestAccess);
