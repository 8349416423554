import { APPLICATION_FORM_STEPS } from '../../constants';
import { whereEq, path } from 'lodash/fp';
import {
  isPartnerTransferringFromAnotherFund,
  whenNull,
} from '../../common/appFormUtills';
import { useEffect } from 'react';
import { isEmptyObject } from '~lib/util';

const toLhcCriteria = ({
  lhcEligible,
  lhcStatement,
  firstHospitalCoverAge,
  isTransferringFromAnotherFund,
}) => {
  if (isTransferringFromAnotherFund === false && lhcEligible) {
    return {
      entryAge: firstHospitalCoverAge || null,
      lhcExempt: false,
    };
  }

  if (
    isTransferringFromAnotherFund &&
    lhcEligible &&
    lhcStatement === false &&
    firstHospitalCoverAge === undefined
  ) {
    return undefined;
  }

  if (
    (!lhcEligible || isTransferringFromAnotherFund) &&
    !lhcStatement &&
    !firstHospitalCoverAge
  ) {
    return {
      entryAge: null,
      lhcExempt: null,
    };
  }

  if (
    !lhcEligible ||
    lhcStatement ||
    (lhcEligible && lhcStatement == null) ||
    isTransferringFromAnotherFund === null
  ) {
    return {
      entryAge: firstHospitalCoverAge || null,
      lhcExempt: true,
    };
  }

  if (firstHospitalCoverAge && lhcStatement === false) {
    return {
      entryAge: firstHospitalCoverAge,
      lhcExempt: false,
    };
  }

  return undefined;
};

export default ({
  setCriteria,
  criteria,
  steps,
  hasPartner,
  applicationSent,
}) => {
  const yourDetailsStep = steps.find(
    whereEq({
      value: APPLICATION_FORM_STEPS.YOUR_DETAILS.value,
    })
  );

  const familyDetailsStep = steps.find(
    whereEq({
      value: APPLICATION_FORM_STEPS.FAMILY_DETAILS.value,
    })
  );

  const shouldNotifyTheChange = (prevLhc, currentLhc) => {
    prevLhc = prevLhc || {};
    currentLhc = currentLhc || {};
    const prevEntryAge = prevLhc.entryAge || null;
    const prevLhcExempt = prevLhc.lhcExempt == null ? true : prevLhc.lhcExempt;

    const currentEntryAge = currentLhc.entryAge || null;
    const currentLhcExempt =
      currentLhc?.lhcExempt == null ? true : currentLhc.lhcExempt;

    return (
      prevEntryAge !== currentEntryAge || prevLhcExempt !== currentLhcExempt
    );
  };

  const calculatePartnerState = prevValues => {
    const validValues =
      familyDetailsStep.context.partnerDetails.form.validValues;
    if (!hasPartner) {
      return {
        lhcCriteria: null,
        notifyPriceChange: shouldNotifyTheChange(criteria.partner),
      };
    }

    const lhcCriteria = toLhcCriteria({
      ...validValues,
      isTransferringFromAnotherFund: whenNull(
        isPartnerTransferringFromAnotherFund(
          yourDetailsStep.context.privateHealthDetailsForm
        ),
        !!yourDetailsStep.context.privateHealthDetailsForm.values
          .partnerAndDependentsCovered || null
      ),
    });

    return (
      lhcCriteria && {
        lhcCriteria,
        notifyPriceChange: shouldNotifyTheChange(criteria.partner, lhcCriteria),
      }
    );
  };

  const calculateMemberState = prevValues => {
    const validValues = yourDetailsStep.context.personalDetailsForm.validValues;
    const lhcCriteria = toLhcCriteria({
      ...validValues,
      isTransferringFromAnotherFund:
        yourDetailsStep.context.privateHealthDetailsForm.values
          .isTransferringFromAnotherFund,
    });

    return (
      lhcCriteria && {
        lhcCriteria,
        notifyPriceChange: shouldNotifyTheChange(criteria, lhcCriteria),
      }
    );
  };

  useEffect(
    prevValues => {
      if (!criteria || isEmptyObject(criteria) || applicationSent) {
        return;
      }

      const partnerState = calculatePartnerState(prevValues);
      const memberState = calculateMemberState(prevValues);
      const notifyPriceChange =
        path('notifyPriceChange')(partnerState) ||
        path('notifyPriceChange')(memberState);

      if (notifyPriceChange) {
        const criteriaUpdate = {
          ...criteria,
        };

        if (path('lhcCriteria')(memberState)) {
          Object.assign(criteriaUpdate, path('lhcCriteria')(memberState));
        }

        Object.assign(criteriaUpdate, {
          partner: path('lhcCriteria')(partnerState),
        });

        setCriteria(criteriaUpdate, {
          notifyPriceChange,
        });
      }
    },
    [
      yourDetailsStep.context.personalDetailsForm.values.lhcEligible,
      yourDetailsStep.context.personalDetailsForm.values.lhcStatement,
      yourDetailsStep.context.personalDetailsForm.values.firstHospitalCoverAge,
      yourDetailsStep.context.privateHealthDetailsForm.values
        .isTransferringFromAnotherFund,
      familyDetailsStep.context.partnerDetails.form.values.lhcEligible,
      familyDetailsStep.context.partnerDetails.form.values.lhcStatement,
      familyDetailsStep.context.partnerDetails.form.values
        .firstHospitalCoverAge,
      isPartnerTransferringFromAnotherFund(
        yourDetailsStep.context.privateHealthDetailsForm
      ),
      yourDetailsStep.context.privateHealthDetailsForm.values
        .partnerAndDependentsCovered,
      hasPartner,
    ]
  );
};
