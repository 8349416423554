import React from 'react';
import { withTheme } from 'emotion-theming';
import { Box } from 'rebass';
import styled from '@emotion/styled';

import Icon from '~common/atoms/Icon';
import Drawer from '../molecules/Drawer';
import useNotifications from '../hooks/useNotifications';

const ActiveAlert = styled.span`
  position: absolute;
  top: -2px;
  right: -2px;
  width: 10px;
  height: 10px;
  background: ${props => props.theme.colors.background.red};
  border-radius: 50%;
`;

const UserNotification = ({ theme }) => {
  const { state, actions } = useNotifications();
  const {
    isVisible,
    loading,
    error,
    data,
    activeTab,
    alertLoaders: notificationLoaders,
    hasUnseenAlerts,
  } = state;
  const { setIsVisible, markAlertAsSeen, setActiveTab } = actions;

  return (
    <Box css={{ position: 'relative', cursor: 'pointer' }}>
      <div style={{ position: 'relative' }}>
        <Icon
          name="oms-notification"
          fill={theme.colors.black}
          width="21px"
          height="24px"
          clickable={true}
          onClick={evt => {
            evt.stopPropagation();
            setIsVisible(!isVisible);
          }}
        />
        {!loading && !error && hasUnseenAlerts ? <ActiveAlert /> : null}
      </div>

      <Drawer
        error={error}
        loading={loading}
        data={data}
        activeTab={activeTab}
        onTabChange={setActiveTab}
        notificationLoaders={notificationLoaders}
        onNotificationSeen={markAlertAsSeen}
        onClick={setIsVisible}
        isVisible={isVisible}
      />
    </Box>
  );
};

export default withTheme(UserNotification);
