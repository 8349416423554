import { useMemo, useState, useCallback } from 'react';
import { path } from 'lodash/fp';
import { debounce } from 'lodash';
import SwifttypeService from '../../../../services/swifttypeService';
import { trackCustomEvent } from '~lib/util';
import useStoredSearch from '~lib/hooks/useStoredSearch';
import searchConfig from './searchConfig';

const getRaw = result => propName => path(`${propName}.raw`)(result);
const getSnippet = result => propName => path(`${propName}.snippet`)(result);

const swiftypeService = SwifttypeService();

export default () => {
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchWord, setSearchWord] = useState('');

  const search = useCallback(
    debounce(searchText => {
      setLoading(true);

      swiftypeService
        .search({
          query: searchText,
          ...searchConfig,
        })
        .then(({ results }) => {
          trackCustomEvent({
            category: 'Full Text Search',
            action: 'searching',
            label: searchText,
          });
          setStoredSearch(searchText);
          setSearchResults(results || []);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.error(error);
        });
    }, 200),
    []
  );

  const searchResultsMapped = useMemo(() => {
    return searchResults.map(result => {
      const raw = getRaw(result);
      const snippet = getSnippet(result);
      return {
        id: raw('id'),
        title: snippet('title') || raw('title'),
        description: snippet('content') || raw('content'),
        slug:
          result.type.raw === 'newsArticle'
            ? `news/${result.slug.raw}`
            : result.slug.raw,
        rank: result._meta.score,
      };
    });
  }, [searchResults]);

  const { setStoredSearch } = useStoredSearch({
    onInitialStoredSearch: storedSearch => {
      search(storedSearch);
      setSearchWord(storedSearch);
    },
  });

  return {
    state: {
      searchWord,
      searchResults: searchResultsMapped,
      loading,
    },
    actions: {
      search,
      setSearchWord,
    },
  };
};
