import React from 'react';
import { Box, Flex } from 'rebass';
import { StandardText } from '../atoms/typography';
import Icon from '../atoms/Icon';
import styled from '@emotion/styled';

const Text = styled(StandardText)`
  &&& {
    font-size: ${props => props.theme.fontSizes[1]}px;
    font-weight: 600;
    color: ${props => props.theme.colors.secondary};
    text-transform: lowercase;
  }
`;

const TickIcon = styled(Icon)`
  &&& text {
    fill: ${props => props.theme.colors.secondary};
  }
`;

const MarkSeen = ({ alert, loading, onClick }) => {
  return (
    <Box>
      {alert.isRead ? (
        <Flex alignItems="center">
          <Text mr={2}>seen</Text>
          <TickIcon name="tick" height="11px" width="11px" />
        </Flex>
      ) : (
        <Text
          onClick={() => {
            onClick(alert);
          }}
          clickable
        >
          {loading ? '...........' : 'mark as seen'}
        </Text>
      )}
    </Box>
  );
};

export default MarkSeen;
