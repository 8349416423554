import { P, P3 } from '~common/atoms/typography';
import Link from '~common/atoms/Link';
import React from 'react';

export default ({ hasLhcApplied }) => ({
  noClaimRebate: (
    <>
      By selecting ‘No’ you are choosing to not claim the Australian Government
      Rebate as a reduced premium.{' '}
      <strong>
        The rebate has been removed from your premium quoted above.
      </strong>
      <br />
      <br />
      You can always apply for the rebate another time by contacting us.
    </>
  ),
  applicantLhcApplied: (
    <>
      Based on the information provided, a Lifetime Health Cover (LHC) loading
      has been calculated and applied to your premium quoted above.
    </>
  ),
  partnerLhcApplied: (
    <>
      Based on the information provided, a Lifetime Health Cover (LHC) loading
      has been calculated and applied to your premium quoted above. An average
      loading has been taken across both adults on your policy.
    </>
  ),
  noOtherFundLhc: (
    <>
      <P3 color="primary" variant="medium" mb={2}>
        A loading applies to hospital cover if you did not have a policy before
        1 July following your 31st birthday or if you have not held hospital
        cover continuously for the past 10 years. More information about this
        loading and how it might apply to you can be{' '}
        <Link external target="_blank" to="/tax-and-rebates">
          found on our website.
        </Link>
      </P3>

      {hasLhcApplied && (
        <P3 color="primary" variant="medium">
          By selecting ‘No’, a loading percentage has been calculated, based on
          your date of birth, and applied to your premium total quoted above.
        </P3>
      )}
    </>
  ),
  partnerLhcMessage: (
    <>
      <P3 color="primary" variant="medium" mb={2}>
        A loading applies to hospital cover if you did not have a policy before
        1 July following your 31st birthday or if you have not held hospital
        cover continuously for the past 10 years. More information about this
        loading and how it might apply to you can be{' '}
        <Link external target="_blank" to="/tax-and-rebates">
          found on our website.
        </Link>
      </P3>

      <P3 color="primary" variant="medium">
        By selecting ‘No’, a loading percentage has been calculated, based on
        your partner’s date of birth, and applied to your premium total quoted
        above. An average loading has been taken across both adults on your
        policy.
      </P3>
    </>
  ),
  noPolicyCovered: (
    <>
      <P mb={2}>
        Applicants not covered by the policy cannot claim the Australian
        Government Rebate on Private Health Insurance (excluding child only
        policies) and employers and trustees of organisations cannot claim the
        Australian Government Rebate on Private Health Insurance on policies
        paid on behalf of employees.
      </P>
      <P>
        Once this policy is activated, it is the responsibility of the
        policyholder to contact us to confirm they are eligible and to claim the
        Australian Government Rebate as a reduced premium. The rebate has been
        removed from the premium quoted above.
      </P>
    </>
  ),
});
