import React from 'react';
import { css } from '@emotion/core';
import { Flex } from 'rebass';
import Icon from '~common/atoms/Icon';

const InclusionsIcon = ({ inline, isSingleIcon }) => (
  <Flex
    flexDirection="row"
    justifyContent={inline ? 'center' : 'flex-start'}
    alignItems="center"
    css={css`
      height: 40px;
      line-height: 40px;
      font-size: 15px;
      color: ${isSingleIcon ? '#fff' : '#4a4a4a'};
      width: 100%;
    `}
  >
    <Icon
      name="circle-fill-tick"
      fill="#569b07"
      width="17px"
      height="17px"
      style={{ marginRight: inline ? '5px' : '10px' }}
    />
    Inclusions
  </Flex>
);

const RestrictionsIcon = ({ inline, isSingleIcon }) => (
  <Flex
    flexDirection="row"
    justifyContent={inline ? 'center' : 'flex-start'}
    alignItems="center"
    css={css`
      height: 40px;
      line-height: 40px;
      font-size: 15px;
      color: ${isSingleIcon ? '#fff' : '#4a4a4a'};
      width: 100%;
    `}
  >
    <Icon
      name="restricted"
      fill="#f5a623"
      width="17px"
      height="17px"
      style={{ marginRight: inline ? '5px' : '10px' }}
    />
    Restrictions^
  </Flex>
);

const ExclusionsIcon = ({ inline, isSingleIcon }) => (
  <Flex
    flexDirection="row"
    justifyContent={inline ? 'center' : 'flex-start'}
    alignItems="center"
    css={css`
      height: 40px;
      line-height: 40px;
      font-size: 15px;
      color: ${isSingleIcon ? '#fff' : '#4a4a4a'};
      width: 100%;
    `}
  >
    <Icon
      name="remove"
      fill="#d0021b"
      width="17px"
      height="17px"
      style={{ marginRight: inline ? '5px' : '10px' }}
    />
    Exclusions
  </Flex>
);

const SingleIconText = ({ inline, isSingleIcon, text }) => (
  <Flex
    flexDirection="row"
    justifyContent={inline ? 'center' : 'flex-start'}
    alignItems="center"
    css={css`
      font-size: 13px;
      color: ${isSingleIcon ? '#fff' : '#4a4a4a'};
      width: 100%;
      text-align: center;
      display: block;
      margin: -8px 0px;
    `}
  >
    <p>{text}</p>
  </Flex>
);

const CombinedIcons = ({ inline }) => (
  <>
    <InclusionsIcon inline={inline} />
    <RestrictionsIcon inline={inline} />
    <ExclusionsIcon inline={inline} />
  </>
);

const SingleIcon = ({ isSingleIcon, iconName, inline }) => {
  switch (iconName) {
    case 'circle-fill-tick':
      return (
        <SingleIconText
          inline={inline}
          isSingleIcon={isSingleIcon}
          text="Included"
        />
      );
    case 'restricted':
      return (
        <SingleIconText
          inline={inline}
          isSingleIcon={isSingleIcon}
          text="Restricted - These services are limited to delivery as a private patient in a public hospital in a shared room."
        />
      );
    case 'remove':
      return (
        <SingleIconText
          inline={inline}
          isSingleIcon={isSingleIcon}
          text="Excluded"
        />
      );
    default:
      return <CombinedIcons inline={inline} />;
  }
};

const InclusionsLegend = props => {
  const { isSingleIcon, iconName, inline, style } = props;
  return (
    <Flex
      flexDirection={inline ? 'row' : 'column'}
      justifyContent={inline ? 'space-between' : 'flex-start'}
      alignItems={inline ? 'center' : 'flex-start'}
      width={1}
      style={{ minWidth: '120px', ...style }}
      {...props}
    >
      {isSingleIcon ? (
        <SingleIcon
          iconName={iconName}
          inline={inline}
          isSingleIcon={isSingleIcon}
        />
      ) : (
        <CombinedIcons inline={inline} />
      )}
    </Flex>
  );
};

export default InclusionsLegend;
