import React from 'react';
import { css } from '@emotion/core';
import { Box } from 'rebass';

const wrapper = css`
  width: 100%;
  height: 100%;
`;

const Layout = ({ children, ...props }) => {
  return (
    <Box css={wrapper} {...props}>
      {children}
    </Box>
  );
};

export default Layout;
