import { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { RetrieveMafQuery } from '../../../queries/maf.graphql';
import { toDateOnly } from '~lib/dates';
import { navigate } from 'gatsby';
import { dropTypename, getDobDate } from '../common/appFormUtills';
import { path } from 'lodash/fp';

export default queryParams => {
  const client = useApolloClient();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const retrieve = ({ dobDay, dobMonth, dobYear, lastName }) => {
    setLoading(true);
    const dobDate = getDobDate({
      values: {
        dobDay,
        dobMonth,
        dobYear,
      },
    });

    const dob = toDateOnly(dobDate);
    return client
      .query({
        query: RetrieveMafQuery,
        variables: {
          input: {
            lastName,
            reference: queryParams.reference,
            dob,
          },
        },
      })
      .then(path('data.maf.retrieve'))
      .then(result => {
        // purge all local storage items so the empty state awaits on the application-form page
        localStorage.clear();
        navigate('/application-form', {
          state: {
            maf: dropTypename(result),
          },
        });
      })
      .catch(error => {
        console.error(error);
        setError(error);
        setLoading(false);
      });
  };

  return {
    retrieve,
    error,
    loading,
    forbiddenError:
      path('graphQLErrors[0].extensions.code')(error) === 'FORBIDDEN',
    expired:
      path('graphQLErrors[0].extensions.code')(error) === 'EXPIRED_ERROR',
    convertedMembership:
      path('graphQLErrors[0].extensions.code')(error) ===
      'CONVERTED_MEMBERSHIP_ERROR',
    needsAssistance:
      path('graphQLErrors[0].extensions.code')(error) ===
      'ASSISTANCE_NEEDED_ERROR',
  };
};
