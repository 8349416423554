import { useQuery } from '@apollo/client';
import useToggle from '~lib/hooks/useToggle';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { AuthContext } from '../../../../context/sso';
import { get } from 'lodash';
import { currency } from '~lib/filters';
import PolicyPaidTo from '~common/molecules/PolicyPaidTo';
import useFeatureToggle from '~lib/hooks/useFeatureToggle';
import { MyDetailsQuery } from 'queries/oms/myDetails.graphql';

export default () => {
  const { loading, data } = useQuery(MyDetailsQuery);
  const [modalOpened, , openModal, hideModal] = useToggle(false);
  const { avant } = useContext(AuthContext);
  const { state, actions } = avant;
  const { sso } = useFeatureToggle();

  useEffect(() => {
    if (sso) {
      actions.checkLinkage();
    }
    //eslint-disable-next-line
  }, []);

  const paymentDescription = useMemo(() => {
    const payments = get(data, 'oms.payments');
    if (!payments) {
      return null;
    }
    const { amount, frequency } = payments;

    return (
      <>
        Your premium{' '}
        <strong>
          {currency(amount)} {frequency.toLowerCase()}
        </strong>{' '}
        | <PolicyPaidTo data={data} />
      </>
    );
  }, [data]);

  const removeAvantLink = useCallback(() => {
    actions.unlink().then(hideModal);
  }, [actions, hideModal]);

  const toggleLinkage = useCallback(() => {
    if (state.isLinked) {
      openModal();
    } else {
      actions.goToAvant();
    }
  }, [actions, openModal, state.isLinked]);

  return {
    state: {
      myDetailsLoading: loading,
      paymentDescription,
      modalOpened,
      ...avant.state,
      isSsoTurnedOn: sso,
    },
    actions: {
      removeAvantLink,
      toggleLinkage,
      openModal,
      hideModal,
    },
  };
};
