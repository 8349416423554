import React from 'react';
// import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { withTheme } from 'emotion-theming';
import { Box, Button } from 'rebass';

import FormTitle from '~OMS/styles/FormTitle';
import SSOFormContainer from '~OMS/styles/SSOFormContainer';
import SimpleSuccessMessage from '~common/atoms/SimpleSuccessMessage';

function ChangeSuccessWrapper() {
  return (
    <SSOFormContainer>
      <FormTitle>Your password was changed</FormTitle>
      <Box mt={3} mb={4}>
        <SimpleSuccessMessage message="Log in next time with your new password." />
      </Box>
      <Box width={1} mb="30px">
        <Button
          as={Link}
          to="/oms/sso/login"
          variant="primary"
          css={{ width: '100%' }}
        >
          Continue to dashboard
        </Button>
      </Box>
    </SSOFormContainer>
  );
}

export default withTheme(ChangeSuccessWrapper);
