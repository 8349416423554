import React from 'react';
import PropTypes from 'prop-types';
import { StandardText } from '../../../../common/atoms/typography';
import { FirstCol, SecondCol, Row } from '../atoms/Layout';

const MembershipAddress = ({ address, ...props }) => (
  <Row alignItems="flex-start" {...props}>
    <FirstCol>
      <StandardText fontWeight={600} fontSize={18} color="dark">
        Membership address
      </StandardText>
    </FirstCol>
    <SecondCol>{address}</SecondCol>
  </Row>
);

MembershipAddress.propTypes = {
  address: PropTypes.node.isRequired,
};

export default MembershipAddress;
