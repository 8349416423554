import { useMemo } from 'react';
import useRelationship from '~lib/hooks/sanity/useRelationship';

export default type => {
  const titles = useRelationship(type);

  const options = useMemo(() => {
    return titles.map(title => ({
      value: title.id,
      label: title.title,
    }));
  }, [titles]);

  return options;
};
