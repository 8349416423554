import { open } from '~common/services/modalService';
import { getDob } from '../../QuoteFormNew/steps/DateOfBirth/DateOfBirthUtils';
import { formatToISO8601DateOnly, fromNativeDate, getAge } from '~lib/dates';
import EditDetailsModal from './index';
import quoteStatuses, { COUPLE, FAMILY } from '~lib/constants/quoteStatuses';
import { auStates, tiers } from '~lib/constants';
import React from 'react';

export default ({ form }) => {
  return {
    open: ({ onConfirm }) => {
      const hasPartner = [COUPLE, FAMILY].includes(form.status?.value);
      open({
        px: {
          xs: 24,
          tabletP: 64,
        },
        py: {
          xs: 32,
          tabletP: 40,
        },
        showHeader: false,
        showCloseIcon: false,
        minWidth: {
          xs: '92%',
          tabletP: '454px',
        },
        mobileWidth: '92%',
        width: '454px',
        content: ({ close }) => {
          //TODO: there is a similar logic in useDateOfBirth hook, unify these
          const dobToForm = dob => {
            const date = new Date(dob.value);
            return {
              day: date.getDate(),
              month: date.getMonth() + 1,
              year: date.getFullYear(),
            };
          };

          const formToDob = (values, name) => {
            const dob = getDob(values, name);
            return {
              value: formatToISO8601DateOnly(dob),
              age: getAge(fromNativeDate(dob)),
            };
          };

          const {
            day: dobDay,
            month: dobMonth,
            year: dobYear,
          } = dobToForm(form.dob);
          const {
            day: partnerDay,
            month: partnerMonth,
            year: partnerYear,
          } = form.partnerDob ? dobToForm(form.partnerDob) : {};

          return (
            <EditDetailsModal
              hasPartner={hasPartner}
              onConfirm={values => {
                const { efc, status, region, tier } = values;

                const hasPartner = [COUPLE, FAMILY].includes(values.status);

                const updatedForm = {
                  status: quoteStatuses.find(item => item.value === status),
                  tier: tiers[status].find(item => item.value === tier),
                  region: auStates.find(item => item.value === region),
                  efc: {
                    ...form.efc,
                    value: !!efc,
                    label: efc ? 'Yes' : 'No',
                  },
                  dob: formToDob(values, 'dob'),
                  yourDob: formToDob(values, 'dob'),
                  partnerDob: hasPartner
                    ? formToDob(values, 'partner')
                    : undefined,
                };

                onConfirm(updatedForm);
                close();
              }}
              initialForm={{
                status: form.status?.value,
                region: form.region?.value,
                tier: form.tier?.value,
                efc: form.efc?.value,
                dobDay,
                dobMonth,
                dobYear,
                partnerDay,
                partnerMonth,
                partnerYear,
              }}
              onCancel={close}
            />
          );
        },
      });
    },
  };
};
