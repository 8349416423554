import WorkingModal from '~common/molecules/WorkingModal';
import { StandardHeading, StandardText } from '~common/atoms/typography';
import React from 'react';
import { useThemeUI } from 'theme-ui';
import { Button } from '~common/atoms/Button';
import Link from '~common/atoms/Link';
import useFiles from '~lib/hooks/sanity/useFiles';

export default ({ visible, onClose }) => {
  const { theme } = useThemeUI();
  const termsAndConditionsPdf = useFiles('hospitalFreeExtrasTermsConditions');
  return (
    <WorkingModal visible={visible} onClose={onClose} height="auto">
      <StandardHeading
        fontSize={32}
        lineHeight="35px"
        bold={false}
        color={theme.colors.text.light}
      >
        You're eligible for another great offer!
      </StandardHeading>

      <StandardText mt={4} mb={'20px'} color={theme.colors.text.light}>
        We hope you're enjoying the benefits of your Lite Extras Cover.
        We're passionate about supporting doctors along their career journey,
        which is why we've introduced an exciting new offer as part of your
        ongoing membership with Avant.
      </StandardText>
      <StandardText mt={4} mb={'20px'} bold={true} color={theme.colors.text.light}>
        Upgrade to a hospital + extras policy and Avant will cover the cost of
        your extras policy for two years.
      </StandardText>
      <StandardText mt={4} mb={'20px'} color={theme.colors.text.light}>
        Access higher limits and additional benefits with our more comprehensive
        extras products and save hundreds on your premium.
      </StandardText>
      <Link newTab to={termsAndConditionsPdf?.url} external>
        Terms & Conditions apply
      </Link>
      <Button
        data-id="agree-btn"
        width={{ xs: 1, md: 180 }}
        variant="primaryInverted"
        as={Link}
        to={`/oms/my-cover/upgrade`}
        mt={4}
      >
        APPLY NOW
      </Button>
    </WorkingModal>
  );
};
