import React, { useContext } from 'react';
import { Button as RebassButton } from 'rebass';
import Link from './Link';
import styled from '@emotion/styled';
import { ContactUsContext } from '~OMS/contact-us/ContactUsContext';

const getConfig = ({ style, inverted, index }) => {
  style = style || 'primary';
  return {
    variant: `${style}${
      inverted || (inverted == null && index > 0) ? 'Inverted' : ''
    }`,
    style: {
      textTransform: 'none',
    },
  };
};

const StyledLink = styled(Link)`
  &&&& {
    text-decoration: none;
  }
`;

const Button = ({
  data: { style, text, inverted, link } = {},
  width,
  index,
  size = 'medium',
  ...props
}) => {
  const { openRequestCallback } = useContext(ContactUsContext);
  const isRequestCallbackButton = link?.url === '#requestCallback';

  const linkProps =
    link && !isRequestCallbackButton
      ? {
          as: props => <StyledLink data-size={size} data={link} {...props} />,
        }
      : undefined;
  return (
    <RebassButton
      data-size={size}
      {...getConfig({ style, inverted, index })}
      {...linkProps}
      width={width}
      onClick={() => {
        if (isRequestCallbackButton) {
          openRequestCallback();
        }
      }}
      {...props}
    >
      {text}
    </RebassButton>
  );
};

export default Button;
