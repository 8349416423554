import React, { useCallback } from 'react';
import AntTimePicker from 'antd/es/time-picker/index';
import 'antd/es/time-picker/style/index.css';
import styled from '@emotion/styled';
import { inputStyles } from './Input';
import { css, Global } from '@emotion/core';
import { noop } from '~lib/util';

const StyledTimePicker = styled(AntTimePicker)`
  input {
    ${inputStyles};
  }
`;

const popupStyles = ({ colors: { orange, hover }, fontSizes }) => css`
  .dhf-time-picker-popup .ant-time-picker-panel-select {
    li:hover {
      background: ${hover.light};
    }

    li:focus {
      color: ${orange};
    }

    .ant-time-picker-panel-select-option-selected {
      color: orange;
    }
  }

  .ant-time-picker {
    width: 100%;
  }

  .ant-time-picker-panel-input {
    font-size: ${fontSizes[1]}px;
  }
`;

const TimePicker = ({
  onChange,
  disabledMinutes,
  minuteStep = 1,
  onInvalidSelection = noop,
  ...props
}) => {
  const checkForInvalidSelection = useCallback(
    (selectedTime, selectedTimeString = '') => {
      if (!selectedTimeString) {
        onChange(selectedTime);
        return;
      }

      const [selectedHour, selectedMinute] = selectedTimeString
        .split(':')
        .map(Number);
      const disabledMinutesList =
        disabledMinutes && disabledMinutes(+selectedHour);
      if (
        disabledMinutesList &&
        disabledMinutesList.includes(+selectedMinute)
      ) {
        selectedTime.minutes(
          onInvalidSelection({
            selectedHour,
            selectedMinute,
            disabledMinutesList,
            minuteStep,
          }) || 0
        );
      }
      onChange(selectedTime);
    },
    [disabledMinutes, minuteStep, onChange, onInvalidSelection]
  );
  return (
    <>
      <Global styles={popupStyles(props.theme)} />
      <StyledTimePicker
        minuteStep={minuteStep}
        onChange={checkForInvalidSelection}
        popupClassName="dhf-time-picker-popup"
        disabledMinutes={disabledMinutes}
        minu
        {...props}
      />
    </>
  );
};

export default TimePicker;
