import React from 'react';
import CompareHospitalInclusionsTable from 'components/Products/CompareHospitalInclusionsTable';
import CompareProductsTable from './CompareProductsTable';
import CompareProducts from './CompareProducts';
import useCompareProducts from './useCompareProducts';

const CompareHospitalCover = props => {
  const { products, loading, rebatePercentage, resultType, actions } =
    useCompareProducts('hospital', props.pageContext.products);

  return (
    <CompareProducts
      resultType={resultType}
      onResultType={actions.setResultType}
      rebatePercentage={rebatePercentage}
      loading={loading}
      products={products}
      type="hospital"
      {...props}
    >
      <CompareProductsTable
        quote={{
          loading,
        }}
        type="hospital"
        resultType={resultType}
        products={products}
      ></CompareProductsTable>
      <CompareHospitalInclusionsTable
        resultType={resultType}
        showIcon={false}
        showTiers={true}
        products={products}
      />
    </CompareProducts>
  );
};

export default CompareHospitalCover;
