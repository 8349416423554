import React from 'react';
import BaseLink from '~common/atoms/Link';
import { path } from 'lodash/fp';
import { getFileUrl } from '~lib/sanity/sanityUtils';
import { isRunningOnClientSide } from '~lib/util';
import styled from '@emotion/styled';

const getPageLink = page => {
  let link = path('slug.current')(page) || path('content.slug.current')(page);

  if (!link) {
    return null;
  }

  if (page._type === 'newsArticle') {
    link = `/news/${link}`;
  }

  return link.startsWith('/') ? link : `/${link}`;
};

const StyledBaseLink = styled(BaseLink, {
  shouldForwardProp: propName =>
    !['isActive', 'whenActiveCss'].includes(propName),
})`
  ${props =>
    props.whenActiveCss && props.isActive() ? props.whenActiveCss : ''};
  * {
    color: ${props => props?.style?.color || 'inherit'} !important;
  }
`;

const Link = ({
  data: { url = '', page, file, newTab },
  whenActiveCss,
  ...props
}) => {
  const pageLink = getPageLink(page);
  let fileLink = path('file.asset.url')(file);
  fileLink = fileLink && getFileUrl(fileLink.split('/').pop());
  const to = pageLink || fileLink || url;
  if (!to) {
    console.warn(`Link component is missing link to navigate`);
  }

  let openInNewTab;

  if (newTab === false) {
    openInNewTab = false;
  } else {
    openInNewTab =
      newTab || (!pageLink && (fileLink || (url && !url.startsWith('/'))));
  }

  const isActive = () => {
    if (isRunningOnClientSide()) {
      return window.location.pathname === (to.startsWith('/') ? to : `/${to}`);
    }
    return false;
  };

  return (
    <StyledBaseLink
      isActive={isActive}
      whenActiveCss={whenActiveCss}
      to={to}
      external={openInNewTab}
      {...props}
    />
  );
};

export default Link;
