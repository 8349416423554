import { useCallback, useState } from 'react';
import { PAYMENT_FORM_MODE } from '~OMS/payments/constants';

export default () => {
  const [formMode, setFormMode] = useState(PAYMENT_FORM_MODE.VIEW);
  const startEditing = useCallback(() => {
    setFormMode(PAYMENT_FORM_MODE.EDIT);
  }, []);

  const viewDetails = useCallback(() => {
    setFormMode(PAYMENT_FORM_MODE.VIEW);
  }, []);

  return {
    state: {
      formMode,
    },
    actions: {
      startEditing,
      viewDetails,
    },
  };
};
