import { graphql, useStaticQuery } from 'gatsby';

const QUICK_QUOTE_SETTINGS_QUERY = graphql`
  query QuickQuoteSettingsQuery {
    sanityQuickQuoteSettings {
      hospital: hospitalProducts {
        content: _rawContent(resolveReferences: { maxDepth: 1 })
      }
      extras: extrasProducts {
        content: _rawContent(resolveReferences: { maxDepth: 1 })
      }

      defaults {
        hospital: hospitalProduct {
          content: _rawContent(resolveReferences: { maxDepth: 1 })
        }
        extras: extrasProduct {
          content: _rawContent(resolveReferences: { maxDepth: 1 })
        }
      }
    }
  }
`;

export default () => {
  const data = useStaticQuery(QUICK_QUOTE_SETTINGS_QUERY);
  return data.sanityQuickQuoteSettings;
};
