import React from 'react';
import PersonalDetailsForm from './PersonalDetailsForm';
import ContactDetailsForm from './ContactDetailsForm';
import PrivateHealthDetailsForm from './PrivateHealthDetailsForm';
import StepTitle from '../../common/components/StepTitle';
import { Form } from '~common/molecules/Form';
import { Box } from 'rebass';
import FormField from '~common/molecules/FormField';
import FormButtons from '../../common/components/FormButtons';
import { formLayout } from '../../common/layout';
import styled from '@emotion/styled';
import useSmoothScrollTop from '~lib/hooks/useSmoothScrollTop';
import EditableFormPanel from '../../common/components/EditableFormPanel';
import InvalidFormError from '../../common/components/InvalidFormError';

const FormContainer = styled(Box)`
  ${formLayout};
`;

const YourDetails = ({ context, appFormContext }) => {
  const { kleberKey, criteria } = appFormContext.state;
  useSmoothScrollTop();
  const {
    expanded,
    toggleExpanded,
    goToNextForm,
    privateHealthDetailsForm,
    confirmed,
    confirmAllForms,
    handleNextForm,
  } = context;
  return (
    <FormContainer>
      <StepTitle>Your details</StepTitle>

      <Form form={context.privateHealthDetailsForm}>
        {({ handleChange, isValid, touched, values }) => (
          <EditableFormPanel
            touched={touched}
            confirmed={confirmed.privateHealthDetails}
            isValid={isValid}
            mb={1}
            expanded={expanded.privateHealthDetails}
            onHeaderClick={toggleExpanded}
            title="Membership details"
            name="privateHealthDetails"
            onNext={handleNextForm}
          >
            <PrivateHealthDetailsForm
              isLEC={!!appFormContext.changeCoverContext}
              appFormContext={appFormContext}
              hasLhcApplied={context.hasLhcApplied}
              criteria={appFormContext.state.criteria}
              lhcEligible={privateHealthDetailsForm.lhcEligible}
              hasPartner={appFormContext.state.hasPartner}
              isFamilyPackage={appFormContext.state.isFamilyPackage}
              promotions={appFormContext.state.promotions}
              values={values}
              handleChange={handleChange}
              Field={FormField}
            />
          </EditableFormPanel>
        )}
      </Form>

      <Form form={context.personalDetailsForm}>
        {({ handleChange, values, isValid, touched }) => (
          <EditableFormPanel
            isValid={isValid}
            touched={touched}
            confirmed={confirmed.personalDetails}
            expanded={expanded.personalDetails}
            onHeaderClick={toggleExpanded}
            title="Personal details"
            mb={1}
            name="personalDetails"
            onNext={handleNextForm}
          >
            <PersonalDetailsForm
              lhcAppliedMessage={appFormContext.messages.applicantLhcApplied}
              hasLhcApplied={context.hasLhcApplied}
              criteria={criteria}
              youthDiscountPercentage={context.youthDiscountPercentage}
              isTransferringFromAnotherFund={
                privateHealthDetailsForm.values.isTransferringFromAnotherFund
              }
              values={values}
              Field={FormField}
              handleChange={handleChange}
              onNext={goToNextForm}
            />
          </EditableFormPanel>
        )}
      </Form>
      <Form form={context.contactDetailsForm}>
        {({ handleChange, values, isValid, touched }) => (
          <EditableFormPanel
            expanded={expanded.contactDetails}
            onHeaderClick={toggleExpanded}
            isValid={isValid}
            touched={touched}
            confirmed={confirmed.contactDetails}
            mb={45}
            title="Contact details"
            name="contactDetails"
          >
            <ContactDetailsForm
              values={values}
              kleberKey={kleberKey}
              handleChange={handleChange}
              Field={FormField}
            />
          </EditableFormPanel>
        )}
      </Form>

      {context.isFormSubmitted && !context.form.isValid && <InvalidFormError />}

      <FormButtons
        showSaveButton={!appFormContext.changeCoverContext}
        saving={appFormContext.state.saving}
        onSave={appFormContext.actions.saveApplicationForm}
        onContinue={() => {
          context.setSubmitted();
          if (context.form.isValid) {
            confirmAllForms();
            appFormContext.actions.confirmStep();
          }
        }}
      />
    </FormContainer>
  );
};

export default YourDetails;
