import React from 'react';
import { HeadingBase } from '~common/atoms/typography';

const Heading = ({ data: { level, text }, variant = 'semiBold', ...props }) => {
  return (
    <HeadingBase
      textAlign="center"
      type={level}
      variant={variant}
      mb={{
        xs: 30,
        md: 50,
      }}
      {...props}
    >
      {text}
    </HeadingBase>
  );
};

export default Heading;
