import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag.macro';
import { get } from 'lodash';
import Loading from '~common/atoms/Loading';
import Link from '~common/atoms/Link';
import { Button } from '~common/atoms/Button';

import { Flex, Box, Heading } from 'rebass';
import { Formik } from 'formik';
import { useThemeUI } from 'theme-ui';
import { useLocale } from '~common/locales';

const phoneExpression =
  /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$/;

const Input = styled.input`
  width: 100%;
  border: 1px solid ${props => props.theme.colors.borders};
  line-height: 24px;
  background-color: ${props => props.theme.colors.background.lightest};
  padding: 2px 13px;
  height: 40px;
  font-size: ${props => props.theme.fontSizes[3]}px;

  &.error {
    border-color: ${props => props.theme.colors.error};
  }
`;

const Checkbox = styled.input`
  margin: 2px 10px 0 0;
  cursor: pointer;
`;

const CheckLabel = styled.label`
  font-size: ${props => props.theme.fontSizes[0]}px;
  line-height: 1.2;
  color: ${props => props.theme.colors.neutral800};
  flex: 1;
  cursor: pointer;
`;

const Label = styled.label`
  font-size: ${props => props.theme.fontSizes[3]}px;
  line-height: 21px;
  margin-bottom: 8px;
  font-weight: 500;
  color: ${props => props.theme.colors.neutral800};
`;

const Feedback = styled.div`
  color: ${props => props.theme.colors.error};
  font-size: ${props => props.theme.fontSizes[1]}px;
  margin-top: 4px;
`;

const Form = styled.form`
  display: flex;
  flex-flow: column nowrap;
  grid-gap: 24px;
`;

const StyledStatus = styled.p`
  width: 100%;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-size: ${props => props.theme.fontSizes[3]}px;
  line-height: 21px;
  font-weight: 600;
  margin-bottom: 20px;

  ${props => props.theme.mq.sm} {
    text-align: left;
  }
`;

const EMAIL_QUOTE_MUTATION = gql`
  mutation EmailQuote($input: EmailQuoteInput!) {
    emailQuote(input: $input) {
      success
    }
  }
`;

const validate = values => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = 'Please enter your first name.';
  } else if (values.firstName.length > 16) {
    errors.firstName = 'First name can only be a max of 16 characters long.';
  }

  if (!values.lastName) {
    errors.lastName = 'Please enter your last name.';
  } else if (values.lastName.length > 25) {
    errors.lastName = 'Last name can only be a max of 25 characters long.';
  }

  if (!values.phone) {
    errors.phone = 'Please enter your phone number.';
  } else if (!values.phone.match(phoneExpression)) {
    errors.phone = 'Please enter a valid australian phone number.';
  }

  if (!values.email) {
    errors.email = 'Please enter your email address.';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(values.email)) {
    errors.email = 'Invalid Email Address';
  }

  if (!values.agree) {
    errors.agree = 'You must agree to the terms and conditions.';
  }

  return errors;
};

const EmailQuoteForm = ({ form, selections }) => {
  const { getLocaleString } = useLocale();
  const { theme } = useThemeUI();
  const [status, setStatus] = useState('');
  const [sendEmailQuote, { loading: formLoading }] =
    useMutation(EMAIL_QUOTE_MUTATION);

  const handleSubmit = async values => {
    const quoteInput = {
      state: form.region.value,
      status: form.status.value,
      dob: form.yourDob.value,
      partnerDob: form.partnerDob?.value,
      age: form.yourDob.age,
      qualifiesForDiscount: form.dob.age < 30 || form.partnerDob?.age < 30,
      paymentFrequency: form.paymentFrequency,
      resultType: form.resultType,
      tier: parseInt(form.tier.value || '0', 10),
      efc: get(form, 'efc.value'),
    };

    const productsInput = {};

    if (
      selections?.hospital?.variant?.code &&
      selections?.hospital?.product?.id &&
      selections?.hospital?.product?.title
    ) {
      quoteInput.hospitalProduct = selections.hospital.variant.code;
      productsInput.hospital = {
        title: selections.hospital.product.title,
        coverage: selections.hospital.product.coverage || '',
        code: selections.hospital.variant.code,
        excess: selections.hospital.variant.excess || 0,
        link: `/${selections.hospital.product.id}`,
      };
    }

    if (
      selections?.extras?.variant?.code &&
      selections?.extras?.product?.id &&
      selections?.extras?.product?.title
    ) {
      quoteInput.extrasProduct = selections.extras.variant.code;
      productsInput.extras = {
        title: selections.extras.product.title,
        coverage: selections.extras.product.coverage || '',
        code: selections.extras.variant.code,
        excess: selections.extras.variant.excess || 0,
        link: `/${selections.extras.product.id}`,
      };
    }
    const input = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      agree: values.agree,
    };
    const response = await sendEmailQuote({
      variables: {
        input: {
          form: input,
          quote: quoteInput,
          products: productsInput,
        },
      },
    });
    setStatus('success');
  };

  if (status) {
    return (
      <StyledStatus
        style={{
          color: status !== 'success' ? theme.colors.error : 'inherit',
        }}
      >
        {status !== 'success'
          ? 'There was a problem sending an email with the quote. Please try again later.'
          : 'An email with the quote has been sent to the email address provided.'}
      </StyledStatus>
    );
  }

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        agree: false,
      }}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return formLoading ? (
          <Flex
            width="100%"
            flexDirection="column"
            alignItems="center"
            justifyContent="start"
          >
            <Heading
              fontSize={3}
              fontWeight="bold"
              color={theme.colors.text}
              textAlign="center"
              mb={1}
            >
              Sending quote
            </Heading>
            <Loading size="large" />
          </Flex>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Box>
              <Label htmlFor="firstName" style={{ display: 'block' }}>
                First name
                <sup style={{ color: theme.colors.error }}>*</sup>
              </Label>
              <Input
                id="firstName"
                placeholder=""
                type="text"
                defaultValue={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                className={
                  errors.firstName && touched.firstName
                    ? 'text-input error'
                    : 'text-input'
                }
              />
              {errors.firstName && touched.firstName ? (
                <Feedback>{errors.firstName}</Feedback>
              ) : null}
            </Box>
            <Box>
              <Label htmlFor="lastName" style={{ display: 'block' }}>
                Last name
                <sup style={{ color: theme.colors.error }}>*</sup>
              </Label>
              <Input
                id="lastName"
                placeholder=""
                type="text"
                defaultValue={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                className={
                  errors.lastName && touched.lastName
                    ? 'text-input error'
                    : 'text-input'
                }
              />
              {errors.lastName && touched.lastName ? (
                <Feedback>{errors.lastName}</Feedback>
              ) : null}
            </Box>
            <Box>
              <Label htmlFor="email" style={{ display: 'block' }}>
                Email<sup style={{ color: theme.colors.error }}>*</sup>
              </Label>
              <Input
                id="email"
                placeholder=""
                type="text"
                defaultValue={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                className={
                  errors.email && touched.email
                    ? 'text-input error'
                    : 'text-input'
                }
              />
              {errors.email && touched.email ? (
                <Feedback>{errors.email}</Feedback>
              ) : null}
            </Box>
            <Box>
              <Label htmlFor="phone" style={{ display: 'block' }}>
                Phone<sup style={{ color: theme.colors.error }}>*</sup>
              </Label>
              <Input
                id="phone"
                placeholder=""
                type="text"
                defaultValue={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                className={
                  errors.phone && touched.phone
                    ? 'text-input error'
                    : 'text-input'
                }
              />
              {errors.phone && touched.phone ? (
                <Feedback>{errors.phone}</Feedback>
              ) : null}
            </Box>
            <Box>
              <Flex alignItems="flex-start">
                <Checkbox
                  id="agree"
                  type="checkbox"
                  defaultValue={values.agree}
                  onChange={handleChange}
                  onBlur={props.handleBlur}
                  required={true}
                  className={
                    errors.agree && touched.agree
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                <CheckLabel htmlFor="agree">
                  I understand that {getLocaleString('common.organisationName')}{' '}
                  will contact me by phone, email or SMS with information
                  regarding their health insurance products. We collect your
                  information in accordance with our{' '}
                  <Link external to="/privacy-policy">
                    privacy policy
                  </Link>
                  .<sup style={{ color: theme.colors.error }}>*</sup>
                </CheckLabel>
              </Flex>
              {errors.agree && touched.agree ? (
                <Feedback>{errors.agree}</Feedback>
              ) : null}
            </Box>
            <Button
              data-id="send-quote-email"
              variant="secondary"
              type="submit"
              disabled={!isValid}
              width="100%"
            >
              Email quote
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EmailQuoteForm;
