import React, { useContext, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { Flex, Box, Text } from 'rebass';
import Link from '~common/atoms/Link';
import SSOFormContainer from '~OMS/styles/SSOFormContainer';
import { AuthContext } from 'context/sso';
import { noop } from '~lib/util';
import { get } from 'lodash';
import ReturningMemberForm from '~OMS/SSO/organisms/ReturningMemberForm';
import RegisterForAccessForm from '~OMS/SSO/organisms/RegisterForAccessForm';
import CallUsNumber from '~common/molecules/CallUsNumber';
import { StandardText } from '~common/atoms/typography';
import { useMobileScreen } from '~lib/responsive';
import Logo from '~common/atoms/Logo';
import { useLocale } from '~common/locales';
import Mfa from '../../../../templates/oms/sso/mfa';

const LogoWrapper = styled(Flex)`
  grid-gap: 16px;
  padding: 0;
`;

const LogoText = styled(StandardText)``;

const MemberHelpText = styled(Text)`
  color: ${props => props.theme.colors.text.light};
`;

const Container = styled(SSOFormContainer)`
  max-width: 950px;
  width: 100%;
  padding: 20px;
  height: unset;

  @media screen and (min-height: 676px) and (min-width: 992px) {
    height: 100vh;
  }

  @media screen and (min-width: 970px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const FormContainer = styled(Box)`
  border: 2px solid ${props => props.theme.colors.borders};
  padding: 20px;
  background: ${props => props.theme.colors.white};
  width: 100%;
  ${props => props.theme.mq.md} {
    width: 440px;
  }
`;

const LoginWrapper = props => {
  let setFormErrors = noop;
  let setFormFieldValue = noop;

  const authContext = useContext(AuthContext);
  const passwordRef = useRef();
  const usernameRef = useRef();

  const {
    login,
    loginToLink,
    error,
    rememberedUsername,
    userData,
    loading,
    resetFormState,
    register,
    shouldLink,
    mfa,
  } = authContext;

  const isMobile = useMobileScreen();
  const alert = get(props, 'location.state.alert');
  const { getLocaleString } = useLocale();

  useEffect(() => {
    // on mount reset state to default
    resetFormState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rememberedUsername) {
      setFormFieldValue('username', rememberedUsername);
      setFormFieldValue('rememberUsername', true);
      setTimeout(() => {
        if (passwordRef.current) {
          passwordRef.current.focus();
        }
      }, 500);
    } else {
      setTimeout(() => {
        if (usernameRef.current) {
          usernameRef.current.focus();
        }
      }, 500);
    }
  }, [rememberedUsername, setFormFieldValue]);

  useEffect(() => {
    if (get(error, 'code') === 'NotAuthorizedException') {
      setFormErrors({
        username: 'Email Address might be incorrect',
        password: 'Password might be incorrect',
      });
    }
  }, [error, setFormErrors]);

  if (mfa) {
    return <Mfa />;
  }

  return (
    !userData && (
      <Container>
        <LogoWrapper mb={4} flexDirection="column">
          <Link to="/">
            <Logo height="53px" />
          </Link>

          <LogoText fontSize={4} bold color="primary">
            {getLocaleString('oms.loginPage.heading')}
          </LogoText>
        </LogoWrapper>

        <Flex
          justifyContent="space-between"
          flexDirection={{
            xs: 'column',
            md: 'row',
          }}
        >
          <FormContainer
            mb={{
              xs: 5,
              md: 0,
            }}
          >
            <ReturningMemberForm
              loading={loading}
              alert={alert}
              error={error}
              getErrorSetter={setter => {
                setFormErrors = setter;
              }}
              getFieldValueSetter={setter => {
                setFormFieldValue = setter;
              }}
              onSubmit={data => {
                return shouldLink ? loginToLink(data) : login(data);
              }}
              usernameRef={usernameRef}
              passwordRef={passwordRef}
            />
          </FormContainer>

          <FormContainer>
            <RegisterForAccessForm
              error={register.state.error}
              loading={register.state.loading}
              onSubmit={register.actions.submit}
            />
          </FormContainer>
        </Flex>

        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mt={{
            xs: 3,
            md: 5,
          }}
        >
          <MemberHelpText textAlign="center" fontSize="14px">
            {isMobile ? (
              <>
                <Box>Having trouble logging in?</Box>
                <Link
                  to="/setting-your-OMS-details"
                  external={true}
                  target="_blank"
                >
                  Click here for guidance,
                </Link>{' '}
                or call us on <CallUsNumber inline />
              </>
            ) : (
              <>
                Having trouble logging in?{' '}
                <Link
                  to="/setting-your-OMS-details"
                  external={true}
                  target="_blank"
                >
                  Click here for guidance,
                </Link>{' '}
                or call us on <CallUsNumber inline />
              </>
            )}
          </MemberHelpText>
        </Flex>
      </Container>
    )
  );
};

export default LoginWrapper;
