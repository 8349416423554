import React from 'react';
import { Formik, Field, Form } from 'formik';
import {
  InputControl,
  ChangePasswordControl,
} from '~common/molecules/form-controls/InputControl';

import { withTheme } from 'emotion-theming';
import { Button } from '~common/atoms/Button';
import validation from './validation';
import { HorizontalFormConfirmWrapper } from '~common/molecules/form-controls';
import classNames from 'classnames';
import { Alert } from '~common/molecules/AlertBox';

const initialForm = {
  email: '',
  mobilePhone: '',
  homePhone: '',
  workPhone: '',
  password: 'unknown',
};

const CurrentMemberDetailsForm = ({
  onSubmit,
  className,
  member,
  loading,
  alert,
  isMobile,
}) => {
  // data issue, form does not accept null as values, replace
  if (member.homePhone === null) {
    member.homePhone = '';
  }

  if (member.mobilePhone === null) {
    member.mobilePhone = '';
  }

  if (member.workPhone === null) {
    member.workPhone = '';
  }

  return (
    <Formik
      validationSchema={validation}
      initialValues={{
        ...initialForm,
        ...member,
      }}
      onSubmit={onSubmit}
    >
      {({ isValid, validateForm }) => {
        return (
          <Form className={classNames('full-width', className)}>
            <Field
              name="email"
              label="Email address"
              labelPosition="left"
              component={InputControl}
            />
            <Field
              name="mobilePhone"
              label="Mobile Phone"
              labelPosition="left"
              component={InputControl}
            />
            <Field
              name="homePhone"
              label="Home Phone"
              labelPosition="left"
              component={InputControl}
            />
            <Field
              name="workPhone"
              label="Work Phone"
              labelPosition="left"
              component={InputControl}
            />
            <Field
              name="password"
              label="Password"
              labelPosition="left"
              component={ChangePasswordControl}
            />

            {alert && isMobile && (
              <Alert mb={2} mt={2} type={alert.type}>
                {alert.message}
              </Alert>
            )}

            <HorizontalFormConfirmWrapper
              alignItems="center"
              justifyContent="center"
            >
              <Button
                loading={loading}
                width={{
                  xs: 1,
                  md: 188,
                }}
                type="submit"
                variant="secondary"
                disabled={!isValid}
              >
                Save Changes
              </Button>
            </HorizontalFormConfirmWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

CurrentMemberDetailsForm.propTypes = {};

export default withTheme(CurrentMemberDetailsForm);
