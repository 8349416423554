import CoverType from './CoverType';
import DateOfBirth from './DateOfBirth';
import TaxRebate from './TaxRebate';
import YourLocation from './YourLocation';

const steps = {
  COVER_TYPE: {
    value: 'cover_type',
    label: 'Cover Type',
    gaTrackingKey: 'Cover Type',
    component: CoverType,
    validate: ({ form, isEfcEligible }) => {
      return !!(isEfcEligible
        ? form?.status?.value && form?.efc?.value != null
        : form?.status?.value);
    },
  },
  YOUR_LOCATION: {
    value: 'state_of_residency',
    label: 'State of residency',
    gaTrackingKey: 'State of residency',
    component: YourLocation,
    validate: ({ form }) => {
      return !!form?.region?.value;
    },
  },
  DATE_OF_BIRTH: {
    value: 'date_of_birth',
    label: 'Date Of Birth',
    gaTrackingKey: 'Date Of Birth',
    component: DateOfBirth,
    validate: ({ form, hasPartner }) => {
      return !!(hasPartner
        ? form?.yourDob?.value && form?.partnerDob?.value
        : form?.yourDob?.value);
    },
  },
  TAX_REBATE: {
    value: 'annual_income',
    label: 'Annual income',
    gaTrackingKey: 'Annual income',
    component: TaxRebate,
    validate: ({ form }) => {
      return !!form?.tier?.value;
    },
  },
};

export default steps;
