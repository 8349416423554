export { default as tiers } from './tiers';
export { default as quoteStatuses } from './quoteStatuses';

export const auStates = [
  { value: 'NSW', label: 'NSW' },
  { value: 'VIC', label: 'VIC' },
  { value: 'QLD', label: 'QLD' },
  { value: 'ACT', label: 'ACT' },
  { value: 'WA', label: 'WA' },
  { value: 'SA', label: 'SA' },
  { value: 'NT', label: 'NT' },
  { value: 'TAS', label: 'TAS' },
];

export const statuses = [
  { value: 'SINGLE', label: 'Single' },
  { value: 'COUPLE', label: 'Couple' },
  { value: 'FAMILY', label: 'Family' },
  { value: 'SINGLE_PARENT', label: 'Single Parent' },
];

export const FORM_MODE = {
  EDIT: 'edit',
  VIEW: 'view',
};

export const PRODUCT_TYPE = {
  HOSPITAL: 'hospital',
  EXTRAS: 'extras',
};

export const PAYMENT_TYPE = {
  INVOICE: 'Invoice',
  BANK_ACCOUNT: 'Bank',
  CREDIT_CARD: 'Card',
};

export const STEP_STATE = {
  INACTIVE: {
    value: 'INACTIVE',
    color: 'light',
    boldLabel: false,
  },
  ACTIVE: {
    value: 'ACTIVE',
    color: 'orange',
    boldLabel: true,
  },
  COMPLETED_ACTIVE: {
    value: 'COMPLETED_ACTIVE',
    boldLabel: true,
  },
  COMPLETED_INACTIVE: {
    value: 'COMPLETED_INACTIVE',
    boldLabel: false,
  },
};

export const EFC_MIN_AGE = 21;

export const EFC_UPPER_AGE_LIMIT = 32;
