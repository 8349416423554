import React from 'react';
import { useThemeUI } from 'theme-ui';
import { Link } from 'gatsby';

import { Box, Flex } from 'rebass';
import styled from '@emotion/styled';

import Block from '~common/atoms/Block';
import { StyledInput } from '~common/atoms/Input';
import { StandardText } from '~common/atoms/typography';
import Icon from '~common/atoms/Icon';
import Loading from '~common/atoms/Loading';

import ArrowPointer from '../ArrowPointer';
import useSearchPanel from './useSearchPanel';
import css from '@emotion/css';

const CloseIcon = styled(Icon)`
  position: absolute;
  right: 10px;
  top: 75px;
  display: none;
  ${props => props.theme.mq.tabletP} {
    display: block;
  }
`;

const SearchResult = styled(Link)`
  display: block;
  height: auto;
  cursor: pointer;
  text-decoration: none;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.colors.borders};
  margin-left: 10px;
`;

const SearchInputBase = styled(StyledInput)`
  border: 1px solid ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primary};
  padding: 12px 10px;
  &:hover {
    border: 1px solid ${props => props.theme.colors.primary};
  }
`;

const SearchInput = ({ loading, ...props }) => {
  return (
    <Flex
      css={css`
        position: relative;
      `}
    >
      <SearchInputBase {...props} />
      {loading && (
        <Loading
          size="18px"
          css={css`
            position: absolute;
            right: 10px;
            top: -24px;
          `}
        />
      )}
    </Flex>
  );
};

const SearchPanelArrowPointer = styled(ArrowPointer)`
  position: absolute;
  top: -17px;
  right: 0px;
`;

const HighlightedSearchText = styled(StandardText)`
  &&&& em {
    font-style: normal;
    background: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.white};
  }
`;

const SearchBlock = styled(Block)`
  position: absolute;
  width: 100%;
  z-index: 999999;

  ${props => props.theme.mq.mdInverted} {
    transform: translateY(-32px);
  }
`;

const SearchResults = React.memo(({ searchResults }) =>
  searchResults.map(result => (
    <SearchResult
      to={`/${result.slug === 'home' ? '' : result.slug}`}
      key={result.id}
    >
      <HighlightedSearchText
        dangerouslySetInnerHTML={{ __html: result.title }}
        color="dark"
        mb={10}
        fontSize={20}
      />
      <HighlightedSearchText
        dangerouslySetInnerHTML={{ __html: result.description }}
        fontSize={2}
        color="dark"
        lineHeight={1.31}
      />
    </SearchResult>
  ))
);

const SearchPanel = ({ onClose, ...props }) => {
  const { theme } = useThemeUI();
  const { state, actions } = useSearchPanel();

  return (
    <SearchBlock
      type="dark"
      innerProps={{
        display: 'flex',
        justifyContent: 'center',
        pt: 36,
        pb: 20,
        css: {
          position: 'relative',
          width: '100%',
        },
      }}
      {...props}
    >
      <SearchPanelArrowPointer />
      <Box
        width={{
          xs: 1,
          tabletP: 589,
        }}
      >
        <Box mb={32}>
          <StandardText
            pb={10}
            color="light"
            fontSize={2}
            lineHeight={1.2}
            as="label"
            htmlFor="search"
          >
            Search
          </StandardText>
          <SearchInput
            loading={state.loading}
            value={state.searchWord}
            onChange={evt => {
              actions.search(evt.target.value);
              actions.setSearchWord(evt.target.value || '');
            }}
            focusColor={theme.colors.secondary}
            name="search"
          />
        </Box>

        <SearchResults searchResults={state.searchResults} />
      </Box>
      <CloseIcon
        onClick={onClose}
        clickable
        fill={theme.colors.dark}
        stroke={theme.colors.dark}
        name="thick-close"
        width="17px"
        height="17px"
      />
    </SearchBlock>
  );
};

export default SearchPanel;
