export default [
  ['New South Wales', 'NSW'],
  ['Queensland', 'QLD'],
  ['South Australia', 'SA'],
  ['Tasmania', 'TAS'],
  ['Victoria', 'VIC'],
  ['Western Australia', 'WA'],
  ['Australian Capital Territory', 'ACT'],
  ['Northern Territory', 'NT'],
].map((state, index) => ({
  id: state[1],
  name: state[0],
  short: state[1],
}));
