import { scrollTo } from '~lib/util';
import { useState } from 'react';
import { dissoc } from 'lodash/fp';

export default ({ initialExpansion, initialConfirmation, scrollMap }) => {
  const [expanded, setExpandedRaw] = useState(initialExpansion || {});
  const [confirmed, setConfirmedRaw] = useState(initialConfirmation || {});

  const confirmAllForms = () => {
    setConfirmedRaw(
      Object.keys(confirmed).reduce((acc, name) => {
        acc[name] = true;
        return acc;
      }, {})
    );
    setExpandedRaw(
      Object.keys(expanded).reduce((acc, name) => {
        acc[name] = false;
        return acc;
      }, {})
    );
  };

  const setConfirmed = name => {
    setConfirmedRaw({
      ...confirmed,
      [name]: true,
    });
  };

  const setExpanded = (name, isExpanded) => {
    setExpandedRaw(currentState => ({
      ...currentState,
      [name]: isExpanded,
    }));
  };

  const removeExpanded = name => {
    setExpandedRaw(current => dissoc(name)(current));
  };

  const removeConfirmed = name => {
    setConfirmedRaw(current => dissoc(name)(current));
  };

  const toggleExpanded = name => {
    setExpanded(name, !expanded[name]);
  };

  const goToForm = formName => {
    scrollTo(formName, {
      timeout: 0,
      offset: -300,
      duration: 500,
    });
  };

  const goToNextForm = currentFormName => {
    const nextForm = scrollMap[currentFormName];
    if (nextForm) {
      if (!confirmed[currentFormName]) {
        setConfirmedRaw({
          ...confirmed,
          [currentFormName]: true,
        });
      }
      setExpandedRaw({
        ...expanded,
        [nextForm]: true,
        [currentFormName]: false,
      });

      goToForm(nextForm);
    }
  };

  return {
    confirmed,
    expanded,
    setExpanded,
    toggleExpanded,
    goToNextForm,
    goToForm,
    setConfirmed,
    confirmAllForms,
    removeExpanded,
    removeConfirmed,
  };
};
