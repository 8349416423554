import { P, StandardText } from '~common/atoms/typography';
import Link from '~common/atoms/Link';
import React from 'react';

export default ({ hasLhcApplied }) => ({
  noClaimRebate: (
    <>
      By selecting ‘No’ you are choosing to not claim the Australian Government
      Rebate as a reduced premium.{' '}
      <strong>
        Therefore, the rebate will be removed from your quoted premium. You will
        receive an email once your application is complete, which will confirm
        your updated premium amount.
      </strong>
      <br />
      <br />
      You can always apply for the rebate another time by contacting us.
    </>
  ),
  applicantLhcApplied: (
    <>
      Based on the information provided, a Lifetime Health Cover (LHC) loading
      may apply to your policy. You will receive an email once your application
      is complete, which will confirm any applicable loading and your updated
      premium amount.
    </>
  ),
  partnerLhcApplied: (
    <>
      Based on the information provided, a Lifetime Health Cover (LHC) loading
      may apply to your policy. You will receive an email once your application
      is complete, which will confirm any applicable loading and your updated
      premium amount.Based on the information provided, a Lifetime Health Cover
      (LHC) loading may apply to your policy. You will receive an email once
      your application is complete, which will confirm any applicable loading
      and your updated premium amount.
    </>
  ),
  noOtherFundLhc: (
    <>
      <StandardText mb={2}>
        A loading applies to hospital cover if you did not have a policy before
        1 July following your 31st birthday or if you have not held hospital
        cover continuously for the past 10 years. More information about this
        loading and how it might apply to you can be{' '}
        <Link external target="_blank" to="/tax-and-rebates">
          found on our website.
        </Link>
      </StandardText>

      {hasLhcApplied && (
        <StandardText>
          By selecting ‘No’, a loading percentage will be calculated and applied
          to your premium. You will receive an email once your application is
          complete, which will confirm your loading and updated premium amount.
        </StandardText>
      )}
    </>
  ),
  partnerLhcMessage: (
    <>
      <StandardText mb={2}>
        A loading applies to hospital cover if you did not have a policy before
        1 July following your 31st birthday or if you have not held hospital
        cover continuously for the past 10 years. More information about this
        loading and how it might apply to you can be{' '}
        <Link external target="_blank" to="/tax-and-rebates">
          found on our website.
        </Link>
      </StandardText>

      <StandardText>
        By selecting ‘No’, a loading percentage will be calculated and applied
        to your premium. You will receive an email once your application is
        complete, which will confirm your loading and updated premium amount.
      </StandardText>
    </>
  ),
  noPolicyCovered: (
    <>
      <P mb={2}>
        Applicants not covered by the policy cannot claim the Australian
        Government Rebate on Private Health Insurance (excluding child only
        policies) and employers and trustees of organisations cannot claim the
        Australian Government Rebate on Private Health Insurance on policies
        paid on behalf of employees.
      </P>
      <P>
        Once this policy is activated, it is the responsibility of the
        policyholder to contact us to confirm they are eligible and to claim the
        Australian Government Rebate as a reduced premium.
      </P>
    </>
  ),
});
