import React from 'react';
import Layout from 'components/Layout';
import SSOAuthWrapper from '~OMS/SSO/organisms/SSOAuthWrapper';
import { withTheme } from 'emotion-theming';
import withQueryParams from '~lib/hocs/withQueryParams';

const SsoAuth = props => {
  return (
    <Layout>
      <SSOAuthWrapper
        queryParams={props.queryParams}
        location={props.location}
      />
    </Layout>
  );
};

export default withQueryParams(withTheme(SsoAuth));
