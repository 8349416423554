import styled from '@emotion/styled';

const SSOFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 400px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 20px;

    input,
    input::placeholder {
      font-size: 14px;
    }
  }
`;

export default SSOFormContainer;
