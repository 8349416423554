import React, { useContext } from 'react';
import { Box, Button, Flex } from 'rebass';
import SimpleErrorHeader from '~common/atoms/SimpleErrorHeader';
import SSOText from '~OMS/SSO/atoms/SSOText';
import SSOFormContainer from '~OMS/styles/SSOFormContainer';
import Link from '~common/atoms/Link';
import { ContactUsContext } from '~OMS/contact-us/ContactUsContext';
import CallUsNumber from '~common/molecules/CallUsNumber';
import { StandardText } from '../../../../common/atoms/typography';

const MembershipExistsWrapper = () => {
  const { openRequestCallback } = useContext(ContactUsContext);

  const copy = (
    <>
      <StandardText mt={2}>
        To reset your password, please enter the <strong>email address</strong>{' '}
        used for registration. A reset password link will be sent to this email
        address.
      </StandardText>
      <StandardText mt={2}>
        As someone on this policy has already registered your account for Online
        Member Services, your member number is no longer required to update your
        online access or login.
      </StandardText>
      <StandardText mt={2}>
        If you are trying to access Online Member Services for the first time,
        please request an invite from the registered user or call us for
        assistance on <CallUsNumber bold />.
      </StandardText>
    </>
  );

  return (
    <SSOFormContainer>
      <SimpleErrorHeader
        withIcon
        title="Enter your email address to reset your password"
      />
      <Box mt={3} mb={4}>
        <SSOText>{copy}</SSOText>
      </Box>

      <Button onClick={openRequestCallback} variant="orange" type="button">
        Request Callback
      </Button>

      <Flex justifyContent="center" mt={4}>
        <Link to="/oms/sso/login/">Try logging in again</Link>
      </Flex>
    </SSOFormContainer>
  );
};

export default MembershipExistsWrapper;
