import React from 'react';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import Icon from '~common/atoms/Icon';
import { useThemeUI } from 'theme-ui';

const heartBeat = keyframes`
  to { transform: rotate(360deg); }
`;

const AnimatedIcon = props => {
  const { theme } = useThemeUI();

  return (
    <Icon
      fill={props.color || theme.colors.primary}
      name="ramsay-loader"
      {...props}
    />
  );
};

export default styled(AnimatedIcon)`
  animation: ${heartBeat} 3s infinite;
`;
