import React from 'react';
import { Box } from 'rebass';
import NoClaimsFound from '~OMS/claims/organisms/ClaimsHistoryTable/NoClaimsFound';
import HistoryLoading from '~OMS/claims/organisms/ClaimsHistoryTable/HistoryLoading';
import ClaimRecord from '~OMS/claims/molecules/ClaimRecord';

const HistoryTable = ({ theme, history = [], loading, ...props }) => {
  if (!history.length && !loading) {
    return <NoClaimsFound />;
  }

  return loading ? (
    <HistoryLoading />
  ) : (
    <Box {...props}>
      {history.map((item, index) => (
        <ClaimRecord
          mb={index === history.length - 1 ? 0 : '25px'}
          key={item.id}
          theme={theme}
          claim={item}
        />
      ))}
    </Box>
  );
};

export default React.memo(HistoryTable);
