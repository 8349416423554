import styled from '@emotion/styled';
import { StandardText } from '~common/atoms/typography';

const EditDetails = styled(StandardText)`
  padding-bottom: 1px;
  border-bottom: 1px solid ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.white};
  width: fit-content;
  font-weight: 600;
`;

export default EditDetails;
