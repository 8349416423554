import { useMemo } from 'react';
import sanityImgBuilder from '~lib/sanity/sanityImgBuilder';
import { path } from 'lodash/fp';
import { rewriteSanityUrl } from '~lib/sanity/sanityUtils';
import { pathOr } from '~lib/util';

export default ({ src, resizeHeight, transform }) => {
  const { imageSrc, builder } = useMemo(() => {
    let builder = sanityImgBuilder.image(src);

    builder = builder.fit('max').auto('format');

    // compress png images due to their usually large size
    if (
      path('asset.extension')(src) === 'png' ||
      pathOr('', 'asset._id')(src).endsWith('png')
    ) {
      builder = builder.quality(65);
    }

    if (transform) {
      builder = transform(builder);
    }

    if (resizeHeight) {
      builder = builder.height(resizeHeight);
    }

    return {
      imageSrc: rewriteSanityUrl(
        builder.url(),
        process.env.SANITY_IMAGES_CDN_URL
      ),
      builder,
    };
  }, [src, resizeHeight]);

  return {
    imageSrc,
    builder,
  };
};
