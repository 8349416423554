import React, { useState } from 'react';
import { Flex, Box, Button } from 'rebass';
import Layout from 'components/Layout';
import styled from '@emotion/styled';

import { H2 } from '../../../common/atoms/typography';
import ModalWrapper from '~common/molecules/ModalWrapper';
import ImageRadioButton from '~common/atoms/ImageRadioButton';

const sampleData = ['Tara Hickman', 'Mark Hickman', 'Adult Dependent']; // @Todo just demo only, will be removed

const ModalInnerContainer = styled.div`
  .user-options {
    margin: 16px;
    flex: 0 0 25%;
  }

  button {
    width: 367px;
  }

  @media screen and (max-width: 768px) {
    .user-options {
      margin: 16px auto;
    }
    button {
      width: 100%;
    }
  }
`;

export default function LegacyUpgrade() {
  const [toggleModal, setToggleModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [activeName, setActiveName] = useState(sampleData[0]);

  const handleClick = () => {
    setToggleModal(true);
  };

  const handleSelect = index => {
    return setActiveIndex(activeIndex === index ? -1 : index);
  };

  return (
    <Layout>
      <button onClick={handleClick}>Open Modal</button>
      <ModalWrapper
        toggleModal={toggleModal}
        setToggleModal={setToggleModal}
        width="800px"
      >
        <ModalInnerContainer>
          <Flex justifyContent="center">
            <Box width={1} css={{ textAlign: 'center' }} mt="50px" mb="23px">
              <H2>Who are you?</H2>
            </Box>
          </Flex>
          <Flex>
            <Box width={1} css={{ textAlign: 'center' }} mb="20px">
              <p>
                We have upgraded the way our members access their account. This
                means that you can no longer use your membership number to log
                in and must create a new individual ID.
              </p>
            </Box>
          </Flex>
          <Flex>
            <Box width={1} css={{ textAlign: 'center' }} mb="10px">
              <p>Please begin by selecting who you are from the list below:</p>
            </Box>
          </Flex>
          <Flex
            justifyContent="center"
            flexWrap="wrap"
            mt="35px"
            mb="45px"
            flexDirection={{ xs: 'column', lg: 'row' }}
          >
            {sampleData.map((sample, index) => (
              <Box
                className="user-options"
                width="184px"
                height="119px"
                key={sample}
              >
                <ImageRadioButton
                  icon="status-single"
                  fill="#4a4a4a"
                  text={sample}
                  isSelected={activeIndex === index}
                  handleSelect={handleSelect}
                  index={index}
                  setActiveName={setActiveName}
                />
              </Box>
            ))}
          </Flex>
          <Flex width={1} justifyContent="center">
            <Box width={1} css={{ textAlign: 'center' }}>
              <Button
                variant="orange"
                onClick={() => {
                  console.log('selected name: ', activeName);
                }}
              >
                Next
              </Button>
            </Box>
          </Flex>
        </ModalInnerContainer>
      </ModalWrapper>
    </Layout>
  );
}
