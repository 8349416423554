import React from 'react';
import { Flex } from 'rebass';
import { useThemeUI } from 'theme-ui';
import { Alert } from '~common/molecules/AlertBox';
import styled from '@emotion/styled';
import { StandardText } from '~common/atoms/typography';
import { Button } from '~common/atoms/Button';
import useContactDetails from '~lib/hooks/sanity/useContactDetails';

const StyledAlert = styled(Alert)`
  max-height: 500px;
  overflow-y: scroll;
`;

const ErrorModal = ({
  title = 'Saving application form failed!',
  onOk,
  isSaveForLater = true,
}) => {
  let message = `We are unable to ${
    isSaveForLater ? 'save' : 'submit'
  } your application at this time. Please contact us during our normal business hours to discuss.`;

  try {
    const contactDetails = useContactDetails();
    message = `We are unable to ${
      isSaveForLater ? 'save' : 'submit'
    } your application at this time. Please contact us ${
      contactDetails && `on ${contactDetails.phone}`
    } during our normal business hours to discuss`;
  } catch (err) {
    //Silently catch the error to prevent a graphql error from blocking the modal being shown.
  }

  const { theme } = useThemeUI();

  return (
    <>
      <StandardText color={theme.colors.error} bold mb={4} as="h3" fontSize={4}>
        {title}
      </StandardText>
      <StyledAlert mb={4} p={2} type="error">
        {message}
      </StyledAlert>
      <Flex justifyContent="center">
        <Button width={175} variant="orangeRoundThin" onClick={onOk}>
          OK
        </Button>
      </Flex>
    </>
  );
};

export default ErrorModal;
