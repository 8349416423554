import { useCallback, useMemo, useState } from 'react';
import { PaymentEstimateQuery } from 'queries/oms/payments.graphql';
import { useApolloClient } from '@apollo/client';
import { amountTextToNumber } from '~OMS/payments/paymentUtils';
import { path } from 'lodash/fp';
import { compare, formatToISO8601DateOnly, now } from '~lib/dates';
import {
  MINIMUM_ALLOWED_PAYMENT_AMOUNT,
  MAXIMUM_PAYMENT_PERIOD,
} from '~OMS/payments/constants';

const getAmount = path('oms.paymentEstimate.amount');
const getDate = path('oms.paymentEstimate.paidTo');

const checkIfMaximumPeriodExceeded = date => {
  const comparison = compare(date, now().add(MAXIMUM_PAYMENT_PERIOD, 'months'));
  return comparison > 0;
};

const checkIfBellowMinimumAmount = amount => {
  return amount < MINIMUM_ALLOWED_PAYMENT_AMOUNT;
};

const usePaymentChecks = () => {
  const client = useApolloClient();
  const [amount, setAmount] = useState();
  const [date, setDate] = useState();
  const [dateCheckLoading, setDateCheckLoading] = useState(false);
  const [amountCheckLoading, setAmountCheckLoading] = useState(false);

  const checkAmount = dateValue => {
    setAmountCheckLoading(true);
    setDate(dateValue);
    return client
      .query({
        query: PaymentEstimateQuery,
        variables: {
          input: {
            paidTo: formatToISO8601DateOnly(dateValue),
          },
        },
        fetchPolicy: 'cache-first',
      })
      .then(result => {
        const value = getAmount(result.data);
        setAmount(value);
        setAmountCheckLoading(false);
        return {
          isBellowMinimumAmount: checkIfBellowMinimumAmount(value),
          isMaximumPeriodExceeded: checkIfMaximumPeriodExceeded(dateValue),
          amount: value,
        };
      })
      .catch(error => {
        console.error(error);
        setAmountCheckLoading(false);
        throw error;
      });
  };

  const checkDate = amountValue => {
    setDateCheckLoading(true);
    setAmount(amountValue);
    return client
      .query({
        query: PaymentEstimateQuery,
        variables: {
          input: {
            amount: amountTextToNumber(amountValue),
          },
        },
        fetchPolicy: 'cache-first',
      })
      .then(result => {
        setDateCheckLoading(false);
        const value = getDate(result.data);
        setDate(value);
        return {
          isMaximumPeriodExceeded: checkIfMaximumPeriodExceeded(value),
          isBellowMinimumAmount: checkIfBellowMinimumAmount(amountValue),
          date: value,
        };
      })
      .catch(error => {
        console.error(error);
        setDateCheckLoading(false);
        throw error;
      });
  };

  const isMaximumPeriodExceeded = useMemo(() => {
    return checkIfMaximumPeriodExceeded(date);
  }, [date]);

  const isBellowMinimumAmount = useMemo(() => {
    return checkIfBellowMinimumAmount(amount);
  }, [amount]);

  return {
    isMaximumPeriodExceeded,
    isBellowMinimumAmount,
    checkIfMaximumPeriodExceeded,
    amount: {
      state: {
        data: amount,
        loading: amountCheckLoading,
      },
      actions: {
        clear: useCallback(() => {
          setAmount(undefined);
        }, []),
        check: checkAmount,
      },
    },
    date: {
      state: {
        data: date,
        loading: dateCheckLoading,
      },
      actions: {
        setDate,
        clear: useCallback(() => {
          setDate(undefined);
        }, []),
        check: checkDate,
      },
    },
  };
};

export default usePaymentChecks;
