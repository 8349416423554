import React, { useEffect, useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import {
  HorizontalFormConfirmWrapper,
  InputControl,
  validators,
} from '~common/molecules/form-controls';
import { object } from 'yup';
import { Button } from '~common/atoms/Button';
import { formSafeValues, noop } from '~lib/util';
import { equals } from 'lodash/fp';
import { Alert } from '~common/molecules/AlertBox';

const initialForm = {
  email: '',
  mobilePhone: '',
};

const validation = object().shape({
  email: validators.email(),
  mobilePhone: validators.mobile().required('Mobile phone is required field'),
});

const InviteMemberForm = ({
  onSubmit,
  loading,
  showErrors,
  member,
  isMobile,
  alert,
}) => {
  let triggerFormValidation = noop;
  useEffect(() => {
    if (showErrors) {
      triggerFormValidation();
    }
  }, [showErrors, triggerFormValidation]);

  const initialValues = useMemo(() => {
    return {
      ...initialForm,
      ...formSafeValues(member),
    };
  }, [member]);

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validation}
      initialValues={initialValues}
    >
      {({ isValid, validateForm, values }) => {
        triggerFormValidation = validateForm;
        return (
          <Form className="full-width">
            <Field
              name="email"
              label="Email address"
              labelPosition="left"
              component={InputControl}
            />
            <Field
              name="mobilePhone"
              label="Mobile phone"
              labelPosition="left"
              component={InputControl}
            />

            {alert && isMobile && (
              <Alert mb={2} mt={2} type={alert.type}>
                {alert.message}
              </Alert>
            )}

            <HorizontalFormConfirmWrapper>
              <Button
                type="submit"
                variant="secondary"
                width={{
                  xs: 1,
                  md: 188,
                }}
                loading={loading}
                disabled={!isValid || equals(values, member)}
              >
                Save changes
              </Button>
            </HorizontalFormConfirmWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InviteMemberForm;
