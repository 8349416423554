import React from 'react';
import Layout from 'components/Layout';
import { withTheme } from 'emotion-theming';

import Navbar from '~common/organisms/Navbar';
import Dashboard from '~OMS/dashboard/templates/Dashboard';
import CheckMembership from '~common/organisms/CheckMembership';
import Wrapper from '~common/molecules/Wrapper';
import OmsFooter from '~OMS/OmsFooter';
import { css } from '@emotion/core';

const index = props => {
  return (
    <Layout>
      <CheckMembership>
        <Navbar location={props.location} />

        <Wrapper
          css={css`
            background: transparent;
            box-shadow: none;
          `}
          pl={0}
          pr={0}
        >
          <Dashboard location={props.location} />
        </Wrapper>
        <OmsFooter />
      </CheckMembership>
    </Layout>
  );
};

export default withTheme(index);
