import React from 'react';
import PropTypes from 'prop-types';

import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import Link from '~common/atoms/Link';

const StyledSubNav = styled.div`
  position: relative;
  display: flex;
  text-align: center;
  padding: 30px 0 0;

  ${props => props.theme.mq.sm} {
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-flex;
    padding: 0;
  }
`;

const StyledItem = styled(Link)`
  cursor: pointer;
  position: relative;
  padding: 10px 0;
  color: ${props => props.theme.primary};
  font-size: ${props => props.theme.fontSizes[2]}px;
  text-decoration: none;
  border-top: 2px solid ${props => props.theme.colors.primary};
  border-right: 2px solid ${props => props.theme.colors.primary};
  border-bottom: 2px solid ${props => props.theme.colors.primary};
  font-weight: 500;
  flex: 1 1 auto;
  background: transparent;

  :hover {
    text-decoration: none;
  }

  &:first-of-type {
    border-left: 2px solid ${props => props.theme.colors.primary};
    ${props => props.theme.mq.sm} {
      border-left: none;
    }
    ${props => props.theme.mq.md} {
      padding: 0 0 15px;
    }
  }
  ${props => props.theme.mq.sm} {
    background: transparent;
    margin-left: 30px;
    border: none;
    color: ${props => props.theme.colors.primary};
    border-bottom: 2px solid transparent;
  }
  ${props => props.theme.mq.md} {
    font-size: 21px;
    border-bottom-width: 3px;
    padding: 0 25px 15px;
  }

  &.active {
    text-decoration: none !important;
    border-bottom-color: ${props => props.theme.colors.primary};
    font-weight: 600;
    ${props => props.theme.mq.smInverted} {
      background: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.white};
    }
  }
`;

const SubNav = ({ theme, data = [] }) => {
  return (
    <StyledSubNav theme={theme}>
      {data.map((e, i) => {
        return (
          <StyledItem
            data-id={e.id}
            to={e.href}
            key={i}
            activeClassName="active"
            partiallyActive={e.matchPartial}
            theme={theme}
          >
            {e.text}
          </StyledItem>
        );
      })}
    </StyledSubNav>
  );
};

SubNav.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
      id: PropTypes.string,
    })
  ),
};

export default withTheme(SubNav);
