import React from 'react';
import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';

import HeaderLine from '~common/atoms/HeaderLine';
import Row from '~common/molecules/Row';
import Col from '~common/molecules/Col';
import SubNav from '~common/molecules/SubNav';
import { ProductBrowser, MobileTabSwitcher } from '~lib/CoverProducts/index';
import CompareCoverSection from '../molecules/CompareCoverSection';
import Divider from '~common/atoms/Divider';
import TalkToUsFooter from '../molecules/TalkToUsFooter';
import TalkToUsCard from '../molecules/TalkToUsCard';
import ChangeCoverIntro from '../molecules/ChangeCoverIntro';
import ChangeCoverIntroS2I from '../molecules/ChangeCoverIntroS2I';
import useChangeCover from '~OMS/my-cover/hooks/useChangeCover';
import Loading from '~common/atoms/Loading';
import CenterBox from '~common/atoms/CenterBox';
import useMembership from '~common/hooks/useMembership';
import useCoverMenu from '~OMS/my-cover/hooks/useCoverMenu';
import StyledCoverProducts from '~OMS/my-cover/organisms/StyledCoverProducts';

const LightColoredCol = styled(Col)`
  color: ${props => props.theme.colors.text.light};
`;

const RespRow = styled(Row)`
  flex-direction: row;
  ${props => props.theme.mq.mdInverted} {
    flex-direction: column;
  }
`;

const productsContainerStyles = {
  minHeight: '700px',
};

const CompareCover = ({ theme }) => {
  const {
    coverProducts = {},
    cover = {},
    membershipProducts,
    requestCallback,
  } = useChangeCover({
    disableProductValidation: true,
  });
  const { isS2IMembership } = useMembership();
  const {
    quote = {},
    selections,
    productType,
    setProductType,
    activeTab,
    setActiveTab,
    hospitalInclisionsOpen,
    toggleHospitalInclusions,
    extrasInclusionsOpen,
    toggleExtrasInclusions,
    selectedProduct,
    setSelected,
    isExtrasVisible,
    isHospitalVisible,
    products,
    prices,
  } = coverProducts;

  const menu = useCoverMenu();

  return (
    <>
      <HeaderLine title="My Cover">
        <SubNav data={menu} />
      </HeaderLine>
      <RespRow flexWrap="no-wrap">
        <LightColoredCol py={30} width={{ xs: 1, md: 7 / 12 }}>
          {isS2IMembership ? <ChangeCoverIntroS2I /> : <ChangeCoverIntro />}
        </LightColoredCol>
        <Col
          py={{
            sm: 0,
            md: 30,
          }}
        ></Col>
        <Col
          py={{
            xs: 0,
            md: 4,
          }}
          width={{ xs: 1, md: 5 / 12 }}
        >
          <TalkToUsCard
            onRequestCallClick={requestCallback.open}
            theme={theme}
          />
        </Col>
      </RespRow>
      {!isS2IMembership && (
        <>
          <Row mb={3} mt={{ xs: 3, md: 0 }}>
            <Col width={1}>
              <Divider className="hide-mobile" />
            </Col>
          </Row>
          <RespRow css={productsContainerStyles}>
            {(cover.loading || quote.loading || !quote.data) && (
              <CenterBox>
                <Loading size="large" />
              </CenterBox>
            )}
            {!quote.loading && quote.data && !cover.loading && (
              <>
                <LightColoredCol className="full-width">
                  <CompareCoverSection
                    cover={cover}
                    onChange={setProductType}
                    selectedType={productType}
                    theme={theme}
                  />
                </LightColoredCol>
                <Col className="full-width">
                  {isHospitalVisible && isExtrasVisible && (
                    <MobileTabSwitcher
                      activeTab={activeTab}
                      onSelect={setActiveTab}
                    />
                  )}
                  <StyledCoverProducts
                    theme={theme}
                    activeTab={activeTab}
                    showExtras={isExtrasVisible}
                    showHospital={isHospitalVisible}
                    hospitalProductsBrowser={
                      <ProductBrowser
                        price={prices?.hospital}
                        products={products.hospital}
                        selectedProduct={selectedProduct.hospital}
                        currentProduct={membershipProducts.hospitalProduct}
                        paymentFrequency={cover.paymentFrequency}
                        status={cover.status.value}
                        type="hospital"
                        selected={selections.hospital.product}
                        selectedVariant={selections.hospital.variant}
                        open={hospitalInclisionsOpen}
                        onInclusionsToggle={() => {
                          toggleHospitalInclusions();
                        }}
                        onChange={setSelected.bind(null, 'hospital')}
                      />
                    }
                    extrasProductsBrowser={
                      <ProductBrowser
                        price={prices?.extras}
                        products={products.extras.filter(
                          product => product.isValid
                        )}
                        selectedProduct={selectedProduct.extras}
                        currentProduct={membershipProducts.extrasProduct}
                        paymentFrequency={cover.paymentFrequency}
                        status={cover.status.value}
                        type="extras"
                        selected={selections.extras.product}
                        selectedVariant={selections.extras.variant}
                        open={extrasInclusionsOpen}
                        onInclusionsToggle={() => {
                          toggleExtrasInclusions();
                        }}
                        onChange={setSelected.bind(null, 'extras')}
                      />
                    }
                  ></StyledCoverProducts>
                </Col>
              </>
            )}
          </RespRow>
        </>
      )}

      <Row justifyContent="center">
        <Col>
          <TalkToUsFooter
            onRequestCallClick={requestCallback.open}
            isS2IMembership={isS2IMembership}
          />
        </Col>
      </Row>
    </>
  );
};

export default withTheme(CompareCover);
