import React from 'react';
import { CreateControl } from './Control';
import styled from '@emotion/styled';
import { StandardText } from '../../atoms/typography';

const StyledReadOnly = styled(StandardText)`
  padding: 8px 13px;
  line-height: 24px;
`;

const ReadOnly = ({ value, bold }) => {
  return <StyledReadOnly bold={bold}>{value}</StyledReadOnly>;
};

export default CreateControl(ReadOnly);
