import React, { useMemo, useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import Icon from '~common/atoms/Icon';
import { withTheme } from 'emotion-theming';
import AntDatePicker from 'antd/es/date-picker/index';
import 'antd/es/date-picker/style/index.css';
import 'antd/es/input/style/index.css';
import { css, Global } from '@emotion/core';
import { toDate } from '~lib/dates';
import { Box } from 'rebass';
import { focusStylesInline } from '~common/styles/formControls';
const borderColor = props => props.theme.colors.borders;

const StyledDatePicker = styled(AntDatePicker)`
  width: 100%;
  border: 1px solid ${borderColor};
  line-height: 24px;
  height: auto;
  padding: 7px 13px;
  border-radius: 0;
  ${props =>
    props.error ? `border-color: ${props.theme.colors.error} !important` : ''};

  &&&&&&&&:hover {
    border: 1px solid ${borderColor};
  }
  &&&&&&&&&.ant-picker-focused {
    ${focusStylesInline};
    box-shadow: none;
  }

  &&&& input {
    font-size: ${props => props.theme.fontSizes[2]}px;
  }
`;

const dropDownStyles = ({
  colors: { orange, white, hover, dark, text },
  fontSizes,
}) => {
  return css`
    .dhf-data-picker-dropdown {
      pointer-events: all !important;

      .ant-picker-today-btn {
        color: ${dark};
      }

      &&&& .ant-picker-header-view button:hover {
        color: ${orange};
      }

      .ant-picker-cell .ant-picker-cell-inner {
        :hover {
          background: ${hover.light};
        }
      }

      .ant-picker-cell.ant-picker-cell-selected .ant-picker-cell-inner {
        background: ${orange};
      }

      .ant-picker-cell.ant-picker-cell-selected.ant-picker-cell-today
        .ant-picker-cell-inner {
        color: ${white};
      }

      .ant-picker-cell.ant-picker-cell-today .ant-picker-cell-inner {
        color: ${orange};
        &::before {
          border: 1px solid ${orange};
        }
      }
    }
  `;
};

const StyledDatePickerIcon = styled(Icon)``;

function DatePicker({ theme, value, name, onBlur, onChange, ...props }) {
  const adaptedValue = useMemo(() => {
    return value && toDate(value);
  }, [value]);
  const [open, setOpen] = useState(false);

  const dropDownRef = useRef();

  //workaround for open prop not working on AntDatePicker
  useEffect(() => {
    const dropdown =
      dropDownRef.current ||
      document.querySelector('.dhf-data-picker-dropdown');
    if (dropdown) {
      dropDownRef.current = dropdown;
      if (open) {
        dropdown.classList.remove('ant-picker-dropdown-hidden');
      } else {
        dropdown.classList.add('ant-picker-dropdown-hidden');
      }
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      //eslint-disable-next-line no-unused-expressions
      dropDownRef.current?.classList?.add('ant-picker-dropdown-hidden');
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownRef.current?.className]);

  return (
    <Box
      name={name}
      onBlur={evt => {
        evt.target.value = value ? value : '';
        evt.target.name = name;
        onBlur(evt);
      }}
    >
      <Global styles={dropDownStyles(theme)} />
      <StyledDatePicker
        open={open}
        suffixIcon={
          <StyledDatePickerIcon
            clickable
            fill={theme.colors.primary}
            name="calendar2"
            height="28px"
            width="28px"
          />
        }
        onOpenChange={evt => {
          setOpen(evt);
        }}
        format="DD/MM/YYYY"
        onChange={value => {
          onChange(value);
        }}
        dropdownClassName="dhf-data-picker-dropdown"
        value={adaptedValue}
        allowClear={false}
        {...props}
      />
    </Box>
  );
}

export default withTheme(DatePicker);
