import React, { useState } from 'react';
import { Flex, Text } from 'rebass';
import { css } from '@emotion/core';
import Block from '~common/atoms/Block';
import Icon from '~common/atoms/Icon';
import QuoteFilterForm from './QuoteFilterForm';
import { Summary } from '~lib/CoverProducts/index';
import { useMobileScreen } from '~lib/responsive';
import styled from '@emotion/styled';
import { useThemeUI } from 'theme-ui';

const summaryStyle = css`
  margin-left: 42px;
  margin-top: 12px;

  @media screen and (max-width: 992px) {
    margin-left: 0;
    margin-top: 5px;
  }
`;

const Container = styled(Block)`
  height: 100px;
  width: 100%;
  position: fixed;
  z-index: 19;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.3);
  min-height: ${props => (props.withEfc ? '145px' : '120px')};
  height: auto;
  ${props => props.theme.mq.md} {
    height: 120px;
  }
`;

const editDetailsButton = css`
  text-decoration: underline;
  cursor: pointer;
`;

const Navbar = ({ form, setFormVisible, updateForm, setForm }) => {
  const { theme } = useThemeUI();
  const isMobile = useMobileScreen();
  const [isFilterOpen, updateFilterOpenStatus] = useState(false);
  const toggleFilterPanel = currentStatus => {
    if (currentStatus === true) {
      return updateFilterOpenStatus(false);
    }
    return updateFilterOpenStatus(true);
  };

  const chevronDownIcon = (
    <Icon
      ml={1}
      name="chevron-down"
      fill={theme.colors.primary}
      width="16px"
      height="16px"
    />
  );

  return (
    <Container
      data-id="quote-navbar"
      withEfc={form.efc && form.efc.value}
      snug={true}
      bg={theme.colors.background.light}
      innerProps={{
        maxWidth: '970px',
      }}
    >
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          flex={1}
        >
          <Icon
            name="arrow-left"
            data-id="go-to-previous-page"
            onClick={() => setFormVisible(true)}
            width="24px"
            height="27px"
            fill={theme.colors.secondary}
            style={{
              cursor: 'pointer',
            }}
          />
          <strong
            className="hide-mobile"
            style={{ fontSize: '21px', marginLeft: '16px' }}
          >
            Quick Quote
          </strong>
          <strong
            style={{ marginLeft: '10px', fontSize: '21px', lineHeight: '2.19' }}
            className="show-mobile-inline"
          >
            Quick Quote
          </strong>
        </Flex>
        <Flex className="hide-desktop" alignItems="center">
          <Text
            data-id="edit-details-mobile"
            onClick={() => updateFilterOpenStatus(true)}
            fontWeight="bold"
            fontSize={2}
            color="primary"
            css={editDetailsButton}
            style={{ marginTop: '0px' }}
          >
            Edit Details
          </Text>
          {chevronDownIcon}
        </Flex>

        <QuoteFilterForm
          form={form}
          updateForm={updateForm}
          isFilterOpen={isFilterOpen}
          toggleFilterPanel={toggleFilterPanel}
          setForm={setForm}
        />
      </Flex>
      {!isMobile && (
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Summary
            efc={form.efc && form.efc.value}
            css={summaryStyle}
            status={form.status.label}
            age={form.dob.age}
            region={form.region.label}
            tier={form.tier.value}
          />

          <Flex alignItems="center">
            <Text
              data-id="edit-details"
              onClick={() => updateFilterOpenStatus(true)}
              fontWeight="bold"
              fontSize={2}
              color="primary"
              css={editDetailsButton}
            >
              Edit Details
            </Text>
            {chevronDownIcon}
          </Flex>
        </Flex>
      )}
      {isMobile && (
        <Summary
          efc={form.efc && form.efc.value}
          css={summaryStyle}
          status={form.status.label}
          age={form.dob.age}
          region={form.region.label}
          tier={form.tier.value}
        />
      )}
    </Container>
  );
};

export default Navbar;
