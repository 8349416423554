import React from 'react';
import Block from '../../common/atoms/Block';
import Heading from './Heading';
import { Flex } from 'rebass';
import ProductCard from './ProductCard';

const ProductCards = ({ data: { cards, title, type }, ...props }) => {
  return (
    <Block className="test" {...props}>
      {title && (
        <Heading
          color="neutral800"
          variant="semiBold"
          data={{
            text: title,
            level: 'h2',
          }}
        />
      )}
      <Flex
        flexDirection={{
          xs: 'column',
          tabletP: 'row',
        }}
        justifyContent="space-between"
      >
        {cards?.map?.((card, index) => (
          <ProductCard
            type={type}
            index={index}
            cardsCount={cards.length}
            key={card._key}
            data={card.product.content}
          />
        ))}
      </Flex>
    </Block>
  );
};

export default ProductCards;
