import React from 'react';
import { useQuery } from '@apollo/client';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import { Box } from 'rebass';
import Row from '~common/molecules/Row';
import Col from '~common/molecules/Col';
import Loading from '~common/atoms/Loading';
import DashboardCover from '~OMS/dashboard/molecules/DashboardCover';
import { H2, P2 } from '~common/atoms/typography';
import { DashboardProfileQuery } from 'queries/oms/dashboard';

const Container = styled.div`
  width: 100%;
  text-align: center;
  ${props => props.mq.sm} {
    text-align: initial;
  }
`;

const Profile = ({ theme }) => {
  const { loading, error, data } = useQuery(DashboardProfileQuery);

  if (error) {
    console.error(error);
    return null;
  }

  const person = loading
    ? null
    : data.oms.membership.persons.find(p => p.id === data.oms.user.personId);

  return (
    <Container {...theme}>
      <Box mb="16px">
        <Row justifyContent="space-between" alignItems="flex-start">
          <Col
            display="flex"
            justifyContent={{
              xs: 'center',
              tabletP: 'space-between',
            }}
            alignItems="center"
            width={1}
            flexDirection={{
              xs: 'column',
              tabletP: 'row',
            }}
          >
            {loading ? (
              <Loading minHeight="126px" justifyContent="flex-start" />
            ) : (
              <>
                <Box>
                  <H2 inline color="primary" variant="medium">
                    Hi{' '}
                    <span style={{ fontWeight: 600 }}>{person.firstName}</span>
                  </H2>
                </Box>
                <P2 color="neutral500" variant="medium">
                  Membership # {data.oms.membership.id}
                </P2>
              </>
            )}
          </Col>
        </Row>
      </Box>
      <DashboardCover mb="24px" />
    </Container>
  );
};

export default withTheme(Profile);
