import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button } from '~common/atoms/Button';

const StyledButtonGroup = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    padding-top: 40px;
  }

  > * {
    max-width: 180px;
    width: 100%;

    &:not(:last-of-type) {
      margin-right: 20px;
    }
  }
`;

const DesktopActions = ({ onApply, onEmailQuote }) => {
  return (
    <StyledButtonGroup>
      <Button onClick={onEmailQuote} variant="primaryInverted">
        Email quote
      </Button>
      <Button
        icon="chevron-right-thin"
        iconPosition="right"
        onClick={onApply}
        variant="secondary"
      >
        Apply now
      </Button>
    </StyledButtonGroup>
  );
};

DesktopActions.propTypes = {
  onApply: PropTypes.func.isRequired,
  onEmailQuote: PropTypes.func.isRequired,
};

export default DesktopActions;
