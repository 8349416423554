import React from 'react';
import FormField from '~common/molecules/FormField';
import DropDownControl from '~common/molecules/form-controls/DropDownControl';
import { Option } from '~common/atoms/DropDown';
import AdditionalInfo, {
  AdditionalInfoArrow,
} from '~common/atoms/AdditionalInfo';
import { Box } from 'rebass';
import { css } from '@emotion/core';

const baseOptions = Array.from({
  length: 28,
}).map((_, index) => {
  index = index + 1;

  if (index >= 11 && index <= 13) {
    return {
      label: index + 'th',
      value: index,
    };
  }

  switch (index % 10) {
    case 1: {
      return {
        label: index + 'st',
        value: index,
      };
    }

    case 2: {
      return {
        label: index + 'nd',
        value: index,
      };
    }

    case 3: {
      return {
        label: index + 'rd',
        value: index,
      };
    }

    default: {
      return {
        label: `${index}th`,
        value: index,
      };
    }
  }
});

const options = [
  {
    label: 'First day of the month',
    value: 1,
  },
  {
    label: 'Last day of the month',
    value: 'last_day_of_month',
  },
].concat(baseOptions);

const PreferredDebitDayField = ({
  onChange,
  debitDayDifferentToStartCoverDate,
}) => {
  return (
    <>
      <FormField
        onChange={onChange}
        name="preferredDebitDay"
        label="Preferred debit day of the month"
        render={renderProps => (
          <DropDownControl placeholder="Select" {...renderProps}>
            {options.map((option, index) => (
              <Option key={index} value={option.value}>
                {option.label}
              </Option>
            ))}
          </DropDownControl>
        )}
      />
      {debitDayDifferentToStartCoverDate && (
        <Box
          css={css`
            transform: translateY(-30px);
          `}
        >
          <AdditionalInfoArrow
            css={css`
              left: 50%;
              transform: translate(-50%, -3px);
            `}
          />
          <AdditionalInfo>
            By selecting a different payment date to your policy start date,
            your first payment due will be greater than your standard premium
            quoted above. We will inform you of this amount by email one
            business day before it is due. All subsequent payments will be your
            standard premium as quoted.
          </AdditionalInfo>
        </Box>
      )}
    </>
  );
};

export default PreferredDebitDayField;
