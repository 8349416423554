import useForm from '~lib/hooks/useForm';
import { useEffect, useMemo } from 'react';
import { path, whereEq, find, pipe } from 'lodash/fp';
import additionalDetailsValidation from '../validation/additionalDetailsValidation';
import useAppFormAdditionalInformation from '~lib/hooks/sanity/useAppFormAdditionalInformation';
import useSanityEligibility from '~lib/hooks/sanity/useSanityEligibility';
import { dropProps, pathOr } from '~lib/util';
import useAppFormHowDidYouHearForUsOptions from './options/useAppFormHowDidYouHearForUsOptions';
import useAppFormPartnerOrganisationsOptions from './options/useAppFormPartnerOrganisationsOptions';
export default (
  { criteria, hasPartner },
  { persistKey, eligibilityContext, yourDetailsContext }
) => {
  const { showAvantBasedQuestions: enableAvantBasedQuestions } =
    useAppFormAdditionalInformation();
  const { membershipOptions } = useSanityEligibility();
  const selectedProfession = path('form.values.profession')(eligibilityContext);
  const howDidYouHearForUsOptions = useAppFormHowDidYouHearForUsOptions();
  const partnerOrganisationsOptions = useAppFormPartnerOrganisationsOptions();

  const showAvantQuestions = useMemo(() => {
    if (!enableAvantBasedQuestions || !selectedProfession) {
      return false;
    }

    const membershipOption = membershipOptions.find(
      whereEq({
        id: path('value', selectedProfession),
      })
    );

    const membershipSubOption = pipe(
      pathOr([], 'options'),
      find(
        whereEq({
          id: path('optionValue', selectedProfession),
        })
      )
    )(membershipOption);

    return (
      path('triggerAvantQuestions', membershipOption) ||
      path('triggerAvantQuestions', membershipSubOption)
    );
  }, [selectedProfession]);

  useEffect(() => {
    if (!showAvantQuestions) {
      form.setForm({
        values: {
          isAvantGroupMember: undefined,
          isPartnerAvantGroupMember: undefined,
        },
        touched: {
          isAvantGroupMember: false,
          isPartnerAvantGroupMember: false,
        },
      });
    }
  }, [showAvantQuestions]);

  const form = useForm(
    {},
    {
      validate: additionalDetailsValidation({
        showAvantQuestions,
        howDidYouHearForUsOptions,
        partnerOrganisationsOptions,
      }),
      persistKey,
    }
  );

  const serialize = () => {
    const values = form.validValues;
    let serialized = { ...values };
    const propsToRemove = [
      'acceptTermsAndConditions',
      'agreeUsingPersonalInformation',
      'acceptAHSA',
      'confirmApplicationIsTrue',
    ];

    if (!values.isNominatePerson) {
      propsToRemove.push([
        'nominatePersonFullName',
        'nominatePersonRelationship',
      ]);
    }

    if (values.isAvantGroupMember) {
      propsToRemove.push('avantMemberInformation');
    } else {
      propsToRemove.push('avantMemberNumber');
    }

    if (values.isPartnerAvantGroupMember) {
      propsToRemove.push('partnerAvantMemberInformation');
    } else {
      propsToRemove.push('partnerAvantMemberNumber');
    }

    serialized = [
      'avantMemberInformation',
      'partnerAvantMemberInformation',
    ].reduce((acc, avantInformationField) => {
      if (acc[avantInformationField]) {
        const value = acc[avantInformationField];
        acc[avantInformationField] = Object.entries(value)
          .filter(([name, value]) => !!value)
          .map(([name]) => name);
      }
      return acc;
    }, serialized);

    return dropProps(propsToRemove, serialized);
  };

  const deserialize = data => {
    return ['avantMemberInformation', 'partnerAvantMemberInformation'].reduce(
      (acc, avantInformationField) => {
        if (data[avantInformationField]) {
          acc[avantInformationField] = acc[avantInformationField].reduce(
            (avantInformationObject, optionName) => ({
              ...avantInformationObject,
              [optionName]: true,
            }),
            {}
          );
        }
        return acc;
      },
      data
    );
  };

  useEffect(() => {
    form.setForm({
      hasPartner,
    });
  }, [hasPartner]);

  return {
    form,
    showAvantQuestions,
    serialize,
    deserialize,
  };
};
