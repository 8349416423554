import React, { useState } from 'react';
import { Flex } from 'rebass';
import { useMobileScreenOnly } from '~lib/responsive';
import useStickyRef from '~lib/hooks/useStickyRef';
import useModalState from '~lib/hooks/useModalState';
import CompareProductsModal from './CompareProductsModal';
import CoverOptions from '../../components/Quote/QuoteResultsNew/CompareCovers/CoverBrowser/CoverOptions';
import Link from '~common/atoms/Link';
import styled from '@emotion/styled';

const MoreDetailsLink = styled(Link)`
  color: ${props => props.theme.colors.primary};
  font-weight: 600;
  text-decoration: underline;
`;

const CompareProductsTable = ({ products = [], resultType, type, quote }) => {
  const [minimised, setMinimised] = useState(false);
  const isMobile = useMobileScreenOnly();
  const stickyRef = useStickyRef({
    fromTop: '-10px',
    ignore: !isMobile,
    distanceFromTop: 0,
    onSticky: () => {
      setMinimised(true);
    },
    onBackToNormal: () => {
      setMinimised(false);
    },
  });

  const [selectedProduct, selectProductRaw] = useState();
  const { modalState, openModal, closeModal } = useModalState(false);

  const selectProduct = product => {
    selectProductRaw(product);
    openModal();
  };

  return (
    <>
      <CompareProductsModal
        visible={modalState}
        onClose={closeModal}
        product={selectedProduct}
      />
      <Flex
        flexDirection={{
          xs: 'column',
          tabletL: 'row',
        }}
      >
        <Flex>
          <CoverOptions
            selectable={false}
            quote={quote}
            products={products.map(product => product.content)}
            paymentFrequency="MONTHLY"
            asterisk={true}
            hat={true}
            type={type}
            cardBottom={({ slug }) =>
              isMobile && (
                <MoreDetailsLink to={`/${slug}`} external target="_self">
                  More details
                </MoreDetailsLink>
              )
            }
          />
        </Flex>
      </Flex>
    </>
  );
};

export default CompareProductsTable;
