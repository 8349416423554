import React, { useContext, useState, useEffect } from 'react';
import { withTheme } from 'emotion-theming';
import { Flex, Box } from 'rebass';
import styled from '@emotion/styled';
import { navigate } from 'gatsby';
import { useQuery } from '@apollo/client';

import Row from './Row';
import Col from './Col';
import UserNotification from '../atoms/UserNotification';
import UserAccountDropdown from '../atoms/UserAccountDropdown';
import { AuthContext } from 'context/sso';

import { NavbarQuery } from 'queries/oms/common';
import { path } from 'lodash/fp';
import { getLoggedInUser } from '~OMS/response-selectors';
import { useMobileScreenOnly } from '~lib/responsive';
import ByBrand from '~common/atoms/ByBrand';
import Logo from '~common/atoms/Logo';
import { basedOffBrand } from '~lib/util';
import { useThemeUI } from 'theme-ui';
import useEventListener from '~lib/hooks/useEventListener';

const Wrapper = styled.div`
  width: 100%;
  color: #000;

  ${props => props.mq.sm} {
    display: block;
    margin-right: 0;
  }

  ${props => props.mq.lg} {
    margin-right: calc((100vw - ${props => props.layout.max}px) / 2);
    display: block;
  }
`;

const NotificationsBox = styled(Box)`
  position: relative;
  left: -38px;
  top: 4px;
  ${props => props.theme.mq.tabletP} {
    left: 0;
    top: 0;
  }
`;

const NavbarLogo = () => {
  const [paddingLeft, setPaddingLeft] = useState(0);
  const isMobile = useMobileScreenOnly();
  const handleResize = () => {
    const innerLogoSvgPadding = 7;
    const pageWrapper = document.querySelector('[data-id="page-wrapper"]');
    setPaddingLeft(
      pageWrapper?.getBoundingClientRect()?.x - innerLogoSvgPadding
    );
  };
  useEventListener('resize', handleResize);
  useEffect(() => {
    handleResize();
  }, []);
  return (
    !isMobile && (
      <Box pl={paddingLeft}>
        <Logo />
      </Box>
    )
  );
};

const UserNavbar = ({ location }) => {
  const { theme } = useThemeUI();
  const context = useContext(AuthContext);
  const { error, data } = useQuery(NavbarQuery);

  const currentUser = path('oms.user', data);

  const currentPerson = getLoggedInUser(data);
  const isMobile = useMobileScreenOnly();

  if (error) {
    console.error(error);
    return null;
  }

  const handleLogout = () => {
    context.logout();
    navigate('/oms/sso/login');
  };

  return (
    <Wrapper {...theme}>
      <Row
        justifyContent={basedOffBrand({
          RAMSAY: 'space-between',
          DHF: 'flex-end',
        })}
      >
        <ByBrand ramsay={() => <NavbarLogo />} />
        <Col>
          <Flex>
            {!isMobile && (
              <Box>
                <UserAccountDropdown
                  location={location}
                  user={currentUser}
                  person={currentPerson}
                  handleLogout={handleLogout}
                />
              </Box>
            )}
            <NotificationsBox
              ml={{
                sm: '30px',
                md: '30px',
              }}
            >
              <Flex alignItems="center" css={{ height: '100%' }}>
                <UserNotification />
              </Flex>
            </NotificationsBox>
          </Flex>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default withTheme(UserNavbar);
