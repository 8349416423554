import React, { useState, useEffect, useCallback } from 'react';
import useLocalStorage from '~lib/hooks/useLocalStorage';
import Layout from 'components/Layout';
import QuoteForm from 'components/Quote/QuoteFormNew';
import QuoteResults from 'components/Quote/QuoteResults';
import valueConversions from '~lib/valueConversions';
import CmsPage from '../components/Elements/CmsPage';
import useQueryParams from '~lib/hooks/useQueryParams';

const keyConversions = {
  membershipClass: 'status',
  location: 'region',
  dateOfBirth: 'dob',
  rebateTier: 'tier',
  paymentFrequency: 'paymentFrequency',
  resultType: 'resultType',
  hospitalProduct: 'hospitalProduct',
  extrasProduct: 'extrasProduct',
};

const getValuesFromQueryParams = queryParams => {
  if (typeof window === 'undefined') {
    return {};
  }

  const results = {};

  Object.keys(queryParams).forEach(field => {
    const lookupField = keyConversions[field] || field;

    if (valueConversions[lookupField]) {
      results[lookupField] = valueConversions[lookupField](
        queryParams[field],
        queryParams
      );
    }
  });

  return results;
};

const QuoteOldPage = props => {
  const queryParams = useQueryParams();
  const [form, setForm] = useLocalStorage('form', {
    paymentFrequency: 'MONTHLY',
    resultType: 'CURRENT',
  });

  const [showForm, setShowForm] = useState(false);

  const [formVisible, setFormVisible] = useState(
    () => !form.status || !form.region || !form.dob || !form.tier || showForm
  );

  useEffect(
    () => {
      const valuesFromQueryParams = getValuesFromQueryParams(queryParams);

      setShowForm(
        current =>
          valuesFromQueryParams.showForm ||
          (form.status && form.region && form.dob && form.tier)
      );

      if (
        valuesFromQueryParams.status &&
        valuesFromQueryParams.region &&
        valuesFromQueryParams.dob &&
        valuesFromQueryParams.tier
      ) {
        setShowForm(current => false);
      }

      if (valuesFromQueryParams.hasOwnProperty('showForm')) {
        delete valuesFromQueryParams.showForm;
      }

      setForm(current => ({
        ...current,
        ...valuesFromQueryParams,
        yourDob: valuesFromQueryParams.dob || form.yourDob,
        partnerDob: valuesFromQueryParams.dob || form.partnerDob,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryParams]
  );

  useEffect(
    () => {
      setFormVisible(
        () =>
          !form.status || !form.region || !form.dob || !form.tier || showForm
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    window.history.replaceState(
      {},
      window.document.title,
      window.location.pathname
    );
  }, []);

  const updateForm = useCallback(
    (key, value) => {
      setForm(current => ({
        ...current,
        [key]: value,
      }));
    },
    [setForm]
  );

  const submitForm = useCallback(
    formToSubmit => {
      if (formToSubmit) {
        setForm(formToSubmit);
      }
      setFormVisible(false);
    },
    [setForm]
  );

  return (
    <CmsPage pageContext={props.pageContext}>
      <Layout>
        {formVisible ? (
          <QuoteForm
            footer={props.pageContext.footer}
            form={form}
            showForm={showForm}
            onChange={updateForm}
            submit={submitForm}
          />
        ) : (
          <QuoteResults
            footer={props.pageContext.footer}
            setFormVisible={setFormVisible}
            setStoredForm={setForm}
            form={form}
            onChange={updateForm}
            setForm={setForm}
          />
        )}
      </Layout>
    </CmsPage>
  );
};

export default QuoteOldPage;
