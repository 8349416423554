import React from 'react';
import HeaderLine from '~common/atoms/HeaderLine';
import { withTheme } from 'emotion-theming';
import ClaimUploader from '~OMS/claims/organisms/ClaimUploader';
import Cards from '~OMS/claims/molecules/Cards';
import ClaimingChecklist from '~OMS/claims/molecules/ClaimingChecklist';
import { Flex } from 'rebass';
import ClaimPaymentInfo from '~OMS/claims/organisms/ClaimPaymentInfo';
import ClaimBenefitModal from '~OMS/claims/organisms/ClaimBenefitModal';
import styled from '@emotion/styled';
import ClaimsExtras from '~OMS/claims/organisms/ClaimsExtras';
import SubNav from '~common/molecules/SubNav';
import { claimsMenu } from '~OMS/mock_data/menu';
import { useQuery } from '@apollo/client';
import { ClaimPaymentQuery } from 'queries/oms/claims.graphql';
import { path } from 'lodash/fp';
import Loading from '~common/atoms/Loading';
import { open } from '~common/services/modalService';
import { navigate } from 'gatsby';
import { isEmptyObject } from '~lib/util';

const MARGIN = 5;

const StyledClaimPaymentInfo = styled(ClaimPaymentInfo)``;
const SubmitClaims = ({ theme }) => {
  const { data, loading } = useQuery(ClaimPaymentQuery);
  const { details = {} } = path('oms.payments.claimAccount', data) || {};

  if (!loading && isEmptyObject(details)) {
    open({
      content: ({ close }) => (
        <ClaimBenefitModal
          onConfirm={() => {
            close();
            navigate('/oms/payments?scroll=claim');
          }}
          onClose={close}
        />
      ),
    });
  }

  return loading ? (
    <Loading />
  ) : (
    <>
      <HeaderLine mb={4} theme={theme} title="My Claims">
        <SubNav data={claimsMenu} />
      </HeaderLine>
      <ClaimUploader mb={MARGIN} />
      <Cards mb={MARGIN} />
      <Flex
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
        mb={MARGIN}
      >
        <ClaimingChecklist
          width={{
            xs: 1,
            md: '45%',
          }}
          mr={{
            xs: 0,
            md: 4,
          }}
          mb={{
            xs: 4,
            md: 0,
          }}
        />

        <StyledClaimPaymentInfo
          ml={{
            xs: 0,
            md: 6,
          }}
          width={{
            xs: 1,
            md: 'auto',
          }}
          maxWidth={{
            xs: '100%',
            md: 300,
          }}
          theme={theme}
          loading={loading}
          details={details}
        />
      </Flex>

      <ClaimsExtras pb={MARGIN} />
    </>
  );
};

export default withTheme(SubmitClaims);
