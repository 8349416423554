import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { scrollTo } from '../../shared/utils';

const StyledArticle = styled.article`
  border: 2px solid ${props => props.theme.colors.neutral100};
  background-color: ${props => props.theme.colors.white};
  position: relative;
`;

const StyledContainer = styled.div`
  padding: 24px;
`;

const StyledType = styled.p`
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 18px;
  color: ${props => props.theme.colors.neutral500};
  padding-bottom: 8px;

  ${props => props.theme.mq.sm} {
    padding-bottom: 0;
    position: absolute;
    top: -26px;
    left: 0;
  }
`;

const StyledTitle = styled.h3``;

const StyledLink = styled.button`
  ${props => props.theme.buttons.primaryLink};
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 18px;
  margin-top: 16px;
`;

const EmptyCard = ({ setProductType, title, type }) => {
  return (
    <StyledArticle data-id={`empty-${type}-card`}>
      <StyledContainer>
        {type ? (
          <StyledType data-id={`empty-tile-${type}-title`}>{type}</StyledType>
        ) : null}
        <StyledTitle data-id={`empty-${type}-title`}>{title}</StyledTitle>
        <StyledLink
          onClick={() => {
            setProductType('both');
            scrollTo(`quote-products-${type}`, { offset: -400 });
          }}
        >
          Add {type} cover
        </StyledLink>
      </StyledContainer>
    </StyledArticle>
  );
};

EmptyCard.propTypes = {
  setProductType: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default EmptyCard;
