import { PAYMENT_TYPE } from '~lib/constants';

export const PAYMENT_MODES = {
  AMOUNT: 'AMOUNT',
  DATE: 'DATE',
};

export const PAYMENT_METHODS = {
  INVOICE: 'invoice',
  DIRECT_DEBIT: 'directDebit',
  PAYROLL: 'payroll',
};

export const PAYMENT_FREQUENCY = {
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  HALF_YEARLY: 'HALF_YEARLY',
  YEARLY: 'YEARLY',
};

export const PAYMENT_FREQUENCY_LABELS = {
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  HALF_YEARLY: '6 Monthly',
  YEARLY: 'Yearly',
};

export const invoiceFrequencies = [
  {
    id: PAYMENT_FREQUENCY.QUARTERLY,
    text: 'Quarterly',
  },
  {
    id: PAYMENT_FREQUENCY.HALF_YEARLY,
    text: '6 Months',
  },
  {
    id: PAYMENT_FREQUENCY.YEARLY,
    text: 'Yearly',
  },
];

export const directDebitFrequencies = [
  {
    id: PAYMENT_FREQUENCY.MONTHLY,
    text: 'Monthly',
  },
  {
    id: PAYMENT_FREQUENCY.QUARTERLY,
    text: 'Quarterly',
  },
  {
    id: PAYMENT_FREQUENCY.HALF_YEARLY,
    text: '6 Months',
  },
  {
    id: PAYMENT_FREQUENCY.YEARLY,
    text: 'Yearly',
  },
];

export const PAYMENT_FREQUENCIES_PER_METHOD = {
  [PAYMENT_METHODS.INVOICE]: invoiceFrequencies,
  [PAYMENT_METHODS.DIRECT_DEBIT]: directDebitFrequencies,
};

export const PAYMENT_FORM_MODE = {
  EDIT: 'edit',
  VIEW: 'view',
};

export const paymentFrequencies = [
  {
    id: PAYMENT_FREQUENCY.WEEKLY,
    text: 'Weekly',
  },
  {
    id: PAYMENT_FREQUENCY.MONTHLY,
    text: 'Monthly',
  },
  {
    id: PAYMENT_FREQUENCY.QUARTERLY,
    text: 'Quarterly',
  },
  {
    id: PAYMENT_FREQUENCY.HALF_YEARLY,
    text: '6 Months',
  },
  {
    id: PAYMENT_FREQUENCY.YEARLY,
    text: 'Yearly',
  },
];

export const paymentTypes = [
  {
    label: 'Bank account',
    value: PAYMENT_TYPE.BANK_ACCOUNT,
  },
  {
    label: 'Credit card',
    value: PAYMENT_TYPE.CREDIT_CARD,
  },
];

export const termsAndConditionsError =
  'You have to accept terms and conditions first before you can submit your details';

export const PAYMENT_RESULT_CODES = {
  UKNOWN: '-1',
  APPROVED: '1',
  DECLINED_BY_BANK: '2',
  OTHER: '3',
};

export const CALLBACK_STATUS_SUCCESS = '200';

export const CHANGE_DETAILS_ACTION = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};

export const CHANGE_CREDIT_CARD_STATUS = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  NOT_FOUND: 'NOT_FOUND',
  NOT_UNIQUE: 'NOT_UNIQUE',
  FAILED_HASH: 'FAILED_HASH',
};

export const MAX_NUMBER_OF_CREDIT_CARD_RETRIES = 2;

export const NON_EXISTING_BSB_MESSAGE = 'The entered BSB does not exist.';

export const MINIMUM_ALLOWED_PAYMENT_AMOUNT = 5;

export const MAXIMUM_PAYMENT_PERIOD =
  parseInt(process.env.DHF_MAXIMUM_PAYMENT_PERIOD, 10) || 13;

export { PAYMENT_TYPE };
