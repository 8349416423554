import useForm from '~lib/hooks/useForm';
import { useEffect } from 'react';
import tiers from '~lib/constants/tiers';
import { whereEq, path, last, pipe } from 'lodash/fp';
import medicareValidation from '../validation/medicareValidation';
import { dropProp, dropProps } from '~lib/util';

const getMaxTier = pipe(path('status.value'), status => last(tiers[status]));

export default (
  { criteria = {}, setCriteria, activeStep, confirmedSteps },
  { yourDetailsContext, partnerDetailsContext, persistKey }
) => {
  const { personalDetailsForm } = yourDetailsContext;
  const isOverride =
    activeStep?.value !== 'medicare' && confirmedSteps?.medicare !== true;
  const form = useForm(
    {
      cardType: 'green',
    },
    {
      persistKey,
      validate: medicareValidation,
      mapFormBeforePersist: value => {
        return {
          ...value,
          errors: dropProp('declarationAccepted', value.errors),
          values: dropProp('declarationAccepted', value.values),
        };
      },
      onChange: {
        tier: value => {
          setCriteria(
            {
              ...criteria,
              tier: tiers[criteria.status.value].find(
                whereEq({
                  value,
                })
              ),
            },
            {
              notifyPriceChange: true,
            }
          );
        },
        isPolicyCovered: (value, prevValue, values) => {
          if (value === false) {
            const maxTier = getMaxTier(criteria);
            setCriteria(
              {
                ...criteria,
                tier: maxTier,
              },
              {
                notifyPriceChange: true,
              }
            );
            return {
              tier: maxTier,
            };
          } else if (value === true && prevValue === false) {
            return {
              tier: '',
            };
          }

          return undefined;
        },
        claimRebate: value => {
          if (value === false) {
            const maxTier = getMaxTier(criteria);
            setCriteria(
              {
                ...criteria,
                tier: maxTier,
              },
              {
                notifyPriceChange: true,
              }
            );
            return {
              tier: maxTier,
            };
          }
          return undefined;
        },
      },
    }
  );

  useEffect(() => {
    const getValue = key =>
      isOverride
        ? personalDetailsForm.values[key] || ''
        : form.values?.[key] || '';
    form.setForm({
      firstName: getValue('firstName'),
      middleName: getValue('middleName'),
      lastName: getValue('lastName'),
    });
  }, [
    personalDetailsForm.values.firstName,
    personalDetailsForm.values.middleName,
    personalDetailsForm.values.lastName,
    isOverride,
  ]);

  const topLevelCriteriaTierValue = path('tier.value')(criteria);

  useEffect(() => {
    let formUpdate;
    if (topLevelCriteriaTierValue !== form.values.tier) {
      formUpdate = {
        tier: criteria.tier ? criteria.tier.value : undefined,
      };
    }

    const maxTier = getMaxTier(criteria) || {};

    if (
      form.values.claimRebate === false &&
      topLevelCriteriaTierValue !== maxTier.value
    ) {
      formUpdate = {
        ...formUpdate,
        claimRebate: null,
      };
    }

    if (formUpdate) {
      form.setForm(formUpdate);
    }
  }, [topLevelCriteriaTierValue]);

  const serialize = () => {
    const values = form.validValues;
    const serialized = {
      ...values,
      tier: Number(values.tier),
    };
    const propsToDrop = ['declarationAccepted'];
    if (!values.isPolicyCovered) {
      propsToDrop.push('tier');
    }

    if (values.cardType === 'green') {
      propsToDrop.push('validToDay');
    }

    return dropProps(propsToDrop, serialized);
  };

  return {
    form,
    serialize,
  };
};
