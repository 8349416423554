import React from 'react';
import { Box } from 'rebass';
import { useThemeUI } from 'theme-ui';
import styled from '@emotion/styled';
import { CreateControl } from '~common/molecules/form-controls/Control';
import Icon from '~common/atoms/Icon';

const DropdownMenu = styled(Box)`
  border-bottom: 1px solid ${props => props.theme.colors.primary};
`;

const SelectMenu = styled.select`
  cursor: pointer;
  border: none;
  outline: none;
  background-color: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  font-size: 18px;
  padding: 10px 5px 12px 5px;
  background-size: 16px;
  color: #1d4b8d;
  // for IE 10+
  &::-ms-expand {
    display: none;
  }
`;

const ChevronDownIcon = styled(Icon)`
  position: absolute;
  right: 5px;
  top: 14px;
`;

const EditCoverDropDown = ({ options = [], value, onChange }) => {
  const { theme } = useThemeUI();
  return (
    <DropdownMenu>
      <ChevronDownIcon
        name="chevron-down"
        width="18px"
        height="18px"
        fill={theme.colors.primary}
      />
      <SelectMenu
        value={value}
        onChange={evt => {
          onChange(evt.target.value);
        }}
      >
        {options.map(option => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </SelectMenu>
    </DropdownMenu>
  );
};

export default CreateControl(EditCoverDropDown);
