import React from 'react';
import { css } from '@emotion/core';
import { Flex, Box } from 'rebass';

const SSOTwoColContainer = ({ theme, leftContent, rightContent }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100vh;
        width: 800px;
        margin: 0 auto;
        @media screen and (max-width: 768px) {
          width: 100%;
          height: 100%;
          padding: 20px;

          input,
          input::placeholder {
            font-size: 14px;
          }
        }
      `}
    >
      <Flex flexDirection={['column', 'column', 'row']}>
        <Box p={3} width={[1, 1, 1 / 2]}>
          {leftContent}
        </Box>
        <Box p={3} width={[1, 1, 1 / 2]}>
          {rightContent}
        </Box>
      </Flex>
    </div>
  );
};

export default SSOTwoColContainer;

// import styled from '@emotion/styled';

// const SSOTwoColContainer = styled.div`
//  = ({ theme, current, total }) => {
//   return (
//     <Text mb={3} css={{ color: themePirmaryText }}>
//       Step {current} of {total}
//     </Text>
//   );
// };

// const SSOTwoColContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   height: 100vh;
//   width: 600px;
//   margin: 0 auto;

//   @media screen and (max-width: 768px) {
//     width: 100%;
//     height: 100%;
//     padding: 20px;

//     input,
//     input::placeholder {
//       font-size: 14px;
//     }
//   }
// `;

// export default SSOTwoColContainer;
