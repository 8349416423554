import React, { useMemo } from 'react';
import DateOfBirth from '~common/molecules/DateOfBirth';
import {
  CheckboxControl,
  DropDownControl,
  FancyCheckboxControl,
  InputControl,
  PersonNameControl,
} from '~common/molecules/form-controls';
import { genderOptions } from '../../state/options';
import GenderCheckboxControl from '../../common/components/GenderCheckboxControl';
import { AGE_CASE } from '../../constants';
import { StandardText } from '~common/atoms/typography';
import { css } from '@emotion/core';
import useAppFormTitleOptions from '../../state/options/useAppFormTitleOptions';
import useAppFormRelationshipOptions from '../../state/options/useAppFormRelationshipOptions';
import CurrentHealthFundFields from '../../common/components/CurrentHealthFundFields';
import { SubFormPanel } from '../../common/Panel';
import { EFC_UPPER_AGE_LIMIT } from '~lib/constants';

const DependantForm = ({
  Field,
  handleChange,
  values,
  calculateDependantAgeCase,
  noFullTimeStudentEfcMessage,
}) => {
  const ageCase = useMemo(() => {
    return calculateDependantAgeCase({ values });
  }, [
    values.dobDay,
    values.dobMonth,
    values.dobYear,
    calculateDependantAgeCase,
  ]);

  const titleOptions = useAppFormTitleOptions('dependant');
  const relationshipOptions = useAppFormRelationshipOptions('dependant');

  const isFullTimeStudentOptions = [
    {
      value: true,
      label: 'YES',
    },
    {
      value: false,
      label: 'NO',
      additionalInfo:
        AGE_CASE.VALID_NO_EFC === ageCase && noFullTimeStudentEfcMessage,
    },
  ];

  return (
    <>
      <DateOfBirth
        Field={Field}
        dayField={{
          focus: !values.dobDay,
          name: 'dobDay',
          onChange: handleChange,
        }}
        monthField={{
          name: 'dobMonth',
          onChange: handleChange,
        }}
        yearField={{
          name: 'dobYear',
          onChange: handleChange,
        }}
      />

      {ageCase === AGE_CASE.VALID_EFC && values.isFullTimeStudent === false && (
        <StandardText
          css={css`
            margin-top: -16px;
            margin-bottom: 40px;
          `}
          fontSize={2}
        >
          <strong>Note:</strong> You have selected Extended Family cover which
          covers this dependant until they are {EFC_UPPER_AGE_LIMIT}.
        </StandardText>
      )}

      {(ageCase === AGE_CASE.VALID_NO_EFC ||
        ageCase === AGE_CASE.VALID_EFC) && (
        <>
          <Field
            onChange={handleChange}
            name="isFullTimeStudent"
            component={FancyCheckboxControl}
            label="Is this dependant currently studying full-time?"
            options={isFullTimeStudentOptions}
          />
          {values.isFullTimeStudent === true && (
            <Field
              label="Enter name of Institution"
              name="institution"
              onChange={handleChange}
              component={InputControl}
            />
          )}
        </>
      )}

      <Field
        halfWidth
        name="title"
        label="Title"
        onChange={handleChange}
        placeholder="Select"
        component={DropDownControl}
        options={titleOptions}
      />
      <Field
        name="firstName"
        label="First name"
        onChange={handleChange}
        component={PersonNameControl}
      />
      <Field
        name="middleName"
        label="Middle name (optional)"
        onChange={handleChange}
        component={PersonNameControl}
      />
      <Field
        name="lastName"
        label="Last name"
        onChange={handleChange}
        component={PersonNameControl}
      />

      {values.isTransferringFromAnotherFund && (
        <>
          <SubFormPanel>
            <StandardText bold mb={22}>
              Previous Health Fund details
            </StandardText>
            {values.canUseMembersHealthFund && (
              <Field
                name="useMemberPreviousHealthFund"
                label="Same as primary applicant's details"
                labelPosition="right"
                component={CheckboxControl}
                onChange={handleChange}
              />
            )}
            {(!values.useMemberPreviousHealthFund ||
              !values.canUseMembersHealthFund) && (
              <CurrentHealthFundFields
                mb={2}
                Field={Field}
                onChange={handleChange}
                names={{
                  fund: 'currentHealthFund',
                  membershipNumber: 'membershipNumber',
                }}
              />
            )}
          </SubFormPanel>
        </>
      )}

      <Field
        name="gender"
        label="Gender"
        onChange={handleChange}
        justifyContent="space-between"
        optionClassName="gender-option"
        component={GenderCheckboxControl}
        options={genderOptions}
      />
      <Field
        halfWidth
        name="relationship"
        label="Relationship"
        onChange={handleChange}
        placeholder="Select"
        component={DropDownControl}
        options={relationshipOptions}
      />
    </>
  );
};

export default React.memo(DependantForm);
