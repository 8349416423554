import React from 'react';
import Footer from '~common/organisms/Footer';
import CenterBox from '~common/atoms/CenterBox';
import AFBlock from '../AFBlock';
import useResumeApplicationForm from '../state/useResumeApplicationForm';
import ExpiredMessage from './ExpiredMessage';
import ResumeForm from './ResumeForm';
import ConvertedMembershipMessage from './ConvertedMembershipMessage';
import NeedsAssistanceMessage from './NeedsAssistanceMessage';
import Header from '~common/molecules/SimpleHeader';

const ResumeApplicationForm = ({ queryParams = {} } = {}) => {
  const {
    retrieve,
    expired,
    convertedMembership,
    needsAssistance,
    loading,
    forbiddenError,
    error,
  } = useResumeApplicationForm(queryParams);

  const renderContent = () => {
    if (expired) {
      return <ExpiredMessage />;
    }

    if (convertedMembership) {
      return <ConvertedMembershipMessage />;
    }

    if (needsAssistance) {
      return <NeedsAssistanceMessage />;
    }

    return (
      <ResumeForm
        error={error}
        loading={loading}
        retrieve={retrieve}
        forbiddenError={forbiddenError}
      />
    );
  };
  return (
    <>
      <Header
        fixed={false}
        logo={{
          height: 48,
        }}
      />
      <CenterBox
        minHeight="calc(100vh - 258px)"
        mt={
          !expired
            ? {
                xs: 50,
                tabletP: 140,
              }
            : {
                xs: -100,
                tabletP: 0,
              }
        }
        mb={
          !expired
            ? {
                xs: 50,
                tabletP: 210,
              }
            : 0
        }
        flexDirection="column"
      >
        <AFBlock
          innerProps={{
            width: {
              xs: 1,
              tabletP: 400,
              tabletL: 400,
              lg: 400,
              xl: 400,
            },
          }}
          flexDirection="column"
          justifyContent="flex-start"
        >
          {renderContent()}
        </AFBlock>
      </CenterBox>
      <Footer as={AFBlock} />
    </>
  );
};

export default ResumeApplicationForm;
