import React from 'react';
import BaseIcon from '~common/atoms/Icon';

const Icon = ({ data: { name, color, stroke }, ...props }) => {
  return (
    <BaseIcon
      name={name}
      fill={color && color.hex}
      stroke={stroke && stroke.hex}
      originalColorSchema={!color && !stroke}
      {...props}
    />
  );
};

export default Icon;
