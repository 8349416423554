import React from 'react';
import { Field } from 'formik';
import {
  DropDownControl,
  InputControl,
  NumberInputControl,
} from '../../molecules/form-controls';
import states from '~OMS/mock_data/states';
import { Option } from '../../atoms/DropDown';
import CheckBoxControl from '../../molecules/form-controls/CheckBoxControl';
import { StandardText } from '../../atoms/typography';

const AddressManualFields = ({ type, isPostalDifferentAsResidential }) =>
  type === 'address' ? (
    <>
      <StandardText mb={3} bold>
        Residential address:
      </StandardText>
      <Field
        name="streetAddress"
        label="Street address"
        labelPosition="left"
        component={InputControl}
      />

      <Field
        name="suburb"
        label="Suburb"
        labelPosition="left"
        component={InputControl}
      />
      <Field
        name="state"
        render={props => (
          <DropDownControl
            halfWidth
            labelPosition="left"
            label="State"
            {...props}
          >
            {states.map(state => (
              <Option value={state.id} key={state.id}>
                {state.short}
              </Option>
            ))}
          </DropDownControl>
        )}
      />
      <Field
        halfWidth
        name="postCode"
        label="Postcode"
        labelPosition="left"
        component={NumberInputControl}
      />

      {!isPostalDifferentAsResidential && (
        <Field
          name="isPostalDifferentAsResidential"
          label="My postal address is different from my residential address"
          labelPosition="right"
          component={CheckBoxControl}
        />
      )}
    </>
  ) : (
    <>
      <StandardText mb={3} bold>
        Postal:
      </StandardText>
      <Field
        name="postalStreetAddress"
        label="Street address"
        labelPosition="left"
        component={InputControl}
      />

      <Field
        name="postalSuburb"
        label="Suburb"
        labelPosition="left"
        component={InputControl}
      />
      <Field
        name="postalState"
        render={props => (
          <DropDownControl
            halfWidth
            labelPosition="left"
            label="State"
            {...props}
          >
            {states.map(state => (
              <Option value={state.id} key={state.id}>
                {state.short}
              </Option>
            ))}
          </DropDownControl>
        )}
      />
      <Field
        halfWidth
        name="postalPostCode"
        label="Postcode"
        labelPosition="left"
        component={NumberInputControl}
      />

      {!isPostalDifferentAsResidential && (
        <Field
          name="isPostalDifferentAsResidential"
          label="My postal address is different from my residential address"
          labelPosition="right"
          component={CheckBoxControl}
        />
      )}
    </>
  );

export default AddressManualFields;
