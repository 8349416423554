import React, { useEffect, useState } from 'react';

// the component reads images from static/images folder
const ResponsiveImage = React.forwardRef(
  ({ src, alt = '', upTo = 3, className, style, ...props }, ref) => {
    const [srcSet, setSrcSet] = useState('');

    useEffect(() => {
      if (!src) {
        return;
      }

      const [fileName, extension = 'png'] = src.split('.');

      const images = Array.from({ length: upTo })
        .map((_, index) => `${index + 1}x`)
        .map(screenSize => `images/${fileName}@${screenSize}.${extension}`);

      const srcSet = images
        .map((image, index) => `${image} ${index + 1}x`)
        .join(',');
      setSrcSet(srcSet);
    }, [src, upTo]);

    return (
      <img
        ref={ref}
        alt={alt}
        srcSet={srcSet}
        className={className}
        style={style}
        {...props}
      />
    );
  }
);

export default ResponsiveImage;
