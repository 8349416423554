import { Flex } from 'rebass';
import { DropDown, Option } from '~common/atoms/DropDown';
import React from 'react';
import { CLAIM_STATUS, DATE_FILTER_PERIODS } from '~OMS/claims/constants';
import { capitalize } from '~lib/util';

const statuses = Object.keys(CLAIM_STATUS).map(status => ({
  id: status,
  label: capitalize(status).replace('_', ' '),
}));

const datePeriods = DATE_FILTER_PERIODS.map(period => ({
  id: period,
  label: `${period} Month` + (period === 1 ? '' : 's'),
}));

const marginRight = {
  xs: 0,
  md: 2,
};

const marginBottom = {
  xs: 3,
  md: 0,
};

const OtherFilters = ({ members = [], filters = {}, onChange, ...props }) => {
  const getOnChangeHandler = filterName => value =>
    onChange({
      [filterName]: value,
    });

  return (
    <Flex
      flexDirection={{
        xs: 'column',
        md: 'row',
      }}
      {...props}
    >
      <DropDown
        onChange={getOnChangeHandler('member')}
        value={filters.member}
        mr={marginRight}
        mb={marginBottom}
      >
        <Option value="all">All members</Option>
        {members.map(member => (
          <Option key={member.id} value={member.id}>
            {member.fullName}
          </Option>
        ))}
      </DropDown>
      <DropDown
        onChange={getOnChangeHandler('status')}
        value={filters.status}
        mb={marginBottom}
        mr={marginRight}
      >
        {statuses.map(status => (
          <Option key={status.id} value={status.id}>
            {status.label}
          </Option>
        ))}
      </DropDown>
      <DropDown
        mb={marginBottom}
        onChange={getOnChangeHandler('period')}
        value={filters.period}
      >
        {datePeriods.map(period => (
          <Option key={period.id} value={period.id}>
            {period.label}
          </Option>
        ))}
      </DropDown>
    </Flex>
  );
};

export default React.memo(OtherFilters);
