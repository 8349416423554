import { graphql, useStaticQuery } from 'gatsby';
import { useFilesBase } from '~lib/hooks/sanity/useFiles';
import { useBrandingBase } from '~lib/hooks/sanity/useBranding';

const QUERY = graphql`
  query Query {
    allSanityFileDownload {
      nodes {
        title
        fileKey
        file {
          asset {
            url
          }
        }
      }
    }
    sanityBranding {
      _id
      fontUrls
      appleStoreUrl
      googleStoreUrl
      logo {
        asset {
          url
          id
        }
      }
    }
  }
`;

export default (fileKeys = []) => {
  const { sanityBranding, allSanityFileDownload } = useStaticQuery(QUERY);
  return {
    files: useFilesBase(allSanityFileDownload?.nodes, fileKeys),
    branding: useBrandingBase(sanityBranding),
  };
};
