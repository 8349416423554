import { FormValidator, required } from '~lib/formValidation';
import { InputControl, validators } from '~common/molecules/form-controls';
import { H3, P1 } from '~common/atoms/typography';
import { Formik } from 'formik';
import FormField from '~common/molecules/FormField';
import DateOfBirth from '~common/molecules/DateOfBirth';
import { Alert } from '~common/molecules/AlertBox';
import { Button } from '~common/atoms/Button';
import React from 'react';

const validate = FormValidator({
  validation: {
    lastName: required('Last name is required'),
    dobDay: [
      required('Day is required'),
      value => {
        validators.day().validateSync(value);
      },
    ],
    dobMonth: [
      required('Month is required'),
      value => {
        validators.month().validateSync(value);
      },
    ],
    dobYear: [
      required('Year is required'),
      value => {
        validators.year().validateSync(value);
      },
    ],
  },
});

const ResumeForm = ({ retrieve, forbiddenError, loading, error }) => (
  <>
    <H3 variant="semiBold" color="primary" mb={4}>
      Welcome back
    </H3>
    <P1 variant="medium" color="neutral900" mb={4}>
      Your previous answers have been saved. Please review them to make sure
      they are still correct and then complete the rest of your application.
    </P1>

    <Formik onSubmit={retrieve} validate={validate}>
      {({ handleSubmit, handleChange }) => (
        <form onSubmit={handleSubmit}>
          <FormField
            onChange={handleChange}
            name="lastName"
            label="Last name"
            focus
            component={InputControl}
          />

          <DateOfBirth
            Field={FormField}
            dayField={{
              name: 'dobDay',
              onChange: handleChange,
            }}
            monthField={{
              name: 'dobMonth',
              onChange: handleChange,
            }}
            yearField={{
              name: 'dobYear',
              onChange: handleChange,
            }}
          />

          {forbiddenError && (
            <Alert type="error" mb={4}>
              Invalid details entered.
            </Alert>
          )}

          {!forbiddenError && error && (
            <Alert type="error" mb={4}>
              {error.message}
            </Alert>
          )}

          <Button mt={4} type="submit" variant="secondary" loading={loading}>
            Continue
          </Button>
        </form>
      )}
    </Formik>
  </>
);

export default ResumeForm;
