import React from 'react';
import { Flex } from 'rebass';
import styled from '@emotion/styled';
import { useThemeUI } from 'theme-ui';
import Icon from '~common/atoms/Icon';
import { StandardText } from '~common/atoms/typography';
import css from '@emotion/css';

const StyledText = styled(StandardText)`
  &:hover {
    text-decoration: underline;
  }
`;

const ActionWithIcon = ({
  isAnomUser = false,
  iconProps,
  iconBoxProps,
  textProps,
  text,
  ...props
}) => {
  const { theme } = useThemeUI();

  return (
    <Flex
      css={css`
        cursor: pointer;
      `}
      alignItems="center"
      justifyContent="flex-start"
      {...props}
    >
      <Icon
        mr={15}
        clickable
        fill={theme.colors.primary}
        stroke={theme.colors.primary}
        width="22px"
        height="16px"
        {...iconProps}
      />

      <StyledText color="white" fontWeight="600" {...textProps}>
        {text}
      </StyledText>
    </Flex>
  );
};

export default ActionWithIcon;
