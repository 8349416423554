import React from 'react';
import styled from '@emotion/styled';
import classnames from 'classnames';
import { noop } from '~lib/util';
import PropTypes from 'prop-types';

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: transparent;
  margin: 20px 0 0 0;
  border-bottom: 1px solid #c0c0c0;

  > button {
    border: none;
    outline: none;
    box-shadow: none;
    padding: 0 0 10px 0;
    margin: 0;
    width: 50%;
    background-color: transparent;
    color: #4a4a4a;
    border-bottom: 5px solid transparent;
    font-size: ${props => props.theme.fontSizes[1]}px;
    line-height: 1.62;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;

    &.active {
      color: #1d4b8d;
      border-bottom: 5px solid #1d4b8d;
    }
  }
`;

const MobileTabSwitcher = ({ onSelect = noop, activeTab = '' }) => (
  <Tabs className="show-mobile-block">
    <button
      className={classnames({
        active: activeTab === 'hospital',
      })}
      onClick={() => onSelect('hospital')}
    >
      Hospital
    </button>
    <button
      className={classnames({
        active: activeTab === 'extras',
      })}
      onClick={() => onSelect('extras')}
    >
      Extras
    </button>
  </Tabs>
);

MobileTabSwitcher.propTypes = {
  activeTab: PropTypes.string,
  onSelect: PropTypes.any.isRequired,
};

export default MobileTabSwitcher;
