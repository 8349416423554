import { APPLICATION_FORM_STEPS } from '../../constants';
import { openModal, serializeMaf } from '../../common/appFormUtills';
import SaveForLaterModal from '../../common/components/modals/SaveForLaterModal';
import React from 'react';
import { useMutation } from '@apollo/client';
import { SaveMafMutation } from '../../../../queries/maf.graphql';
import useLocalStorage from '~lib/hooks/useLocalStorage';
import ErrorModal from '../../common/components/modals/ErrorModal';
import { path, assocPath, pipe } from 'lodash/fp';

const getValue = (form, propName) => {
  const value = form.values[propName];
  const error = form.errors[propName];
  return error ? '' : value;
};

const YOUR_DETAILS = APPLICATION_FORM_STEPS.YOUR_DETAILS.value;

export default ({ stepProvider, contactDetails, steps, criteria = {} }) => {
  const [saveMaf, { data = {}, loading: saving, error }] =
    useMutation(SaveMafMutation);
  const [formId, setFormId, removeFormId] = useLocalStorage('mafId');

  const { contactDetailsForm, personalDetailsForm } =
    stepProvider.get(YOUR_DETAILS).context;

  const save = async missingDetails => {
    const missingContactDetails = {
      email: missingDetails.email,
      phoneNumber: missingDetails.phoneNumber,
    };

    const missingPersonalDetails = {
      firstName: missingDetails.firstName,
      lastName: missingDetails.lastName,
      dobYear: Number(missingDetails.dobYear),
      dobMonth: Number(missingDetails.dobMonth),
      dobDay: Number(missingDetails.dobDay),
    };

    let payload = await serializeMaf({ formId, steps, criteria });

    payload = pipe(
      assocPath(['form', YOUR_DETAILS, 'contactDetails'], {
        ...path(['form', YOUR_DETAILS, 'contactDetails'])(payload),
        ...missingContactDetails,
      }),
      assocPath(['form', YOUR_DETAILS, 'personalDetails'], {
        ...path(['form', YOUR_DETAILS, 'personalDetails'])(payload),
        ...missingPersonalDetails,
      })
    )(payload);

    console.log('SAVE PAYLOAD', payload);

    return saveMaf({
      variables: {
        input: payload,
      },
    })
      .then(path('data.saveMaf'))
      .then(result => {
        console.log('SAVE MAF RESULT:', result);
        setFormId(result.id);
        contactDetailsForm.setForm(missingContactDetails);
        personalDetailsForm.setForm(missingPersonalDetails);
        return result;
      })
      .catch(error => {
        contactDetailsForm.setForm(missingContactDetails);
        personalDetailsForm.setForm(missingPersonalDetails);
        throw error;
      });
  };

  const saveApplicationForm = async ({ leaving, onCancel } = {}) => {
    const initialForm = {
      firstName: getValue(personalDetailsForm, 'firstName'),
      lastName: getValue(personalDetailsForm, 'lastName'),
      email: getValue(contactDetailsForm, 'email'),
      dobYear: getValue(personalDetailsForm, 'dobYear'),
      dobMonth: getValue(personalDetailsForm, 'dobMonth'),
      dobDay: getValue(personalDetailsForm, 'dobDay'),
      phoneNumber: getValue(contactDetailsForm, 'phoneNumber'),
    };

    const allDataProvided = Object.values(initialForm).every(value => !!value);

    try {
      openModal(
        ({ close }) => (
          <SaveForLaterModal
            saveCompleted={false}
            allDataProvided={allDataProvided}
            leaving={leaving}
            callUsNumber={contactDetails.phone}
            workingTime={contactDetails.workingTime}
            initialForm={initialForm}
            onCancel={() => {
              if (saving) {
                return;
              }

              close();
              if (onCancel) {
                onCancel();
              }
            }}
            onConfirm={close}
            onSave={save}
          />
        ),
        {
          padding: true,
          showCloseIcon: true,
          flexDirection: 'column',
        }
      );
    } catch (err) {
      openModal(
        ({ close }) => <ErrorModal onOk={close} isSaveForLater={true} />,
        {
          showCloseIcon: true,
          padding: true,
        }
      );
    }
  };

  return {
    saveApplicationForm,
    saving,
    reference: data.reference,
    formId,
    error,
    clearSaveApplicationCache: removeFormId,
  };
};
