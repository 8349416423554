import { useQuery } from '@apollo/client';
import moment from 'moment';
import { path } from 'lodash/fp';
import { ExclusivePromotionsQuery } from 'queries/maf.graphql';

const prepare = params => {
  const joinDate = path('variables.input.joinDate')(params);
  if (!joinDate) {
    return params;
  }

  return {
    ...params,
    skip:
      !params?.variables?.input?.joinDate ||
      !params?.variables?.input?.productType ||
      !params?.variables?.input?.promotionCode,
    variables: {
      input: {
        ...params.variables.input,
        joinDate: moment(joinDate).format('YYYY-MM-DD'),
      },
    },
  };
};

export default params => {
  const { data, ...rest } = useQuery(ExclusivePromotionsQuery, prepare(params));

  return {
    data: data?.exclusivePromotions?.validatePromotionCode,
    ...rest,
  };
};
