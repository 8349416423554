export { default as fileSize } from './fileSizeFilter';
export { default as currency } from './currencyFilter';
export { default as excessFilter } from './excessFilter';
export { paymentFrequency } from './paymentFrequency';

const REBATE_STATUS = {
  REGISTERED: 'REGISTERED',
  UNREGISTERED: 'UNREGISTERED',
};

export const rebateTierFilterBasic = rebateTier => {
  if (Number(rebateTier) === 0) {
    return 'base tier';
  }
  return `tier ${rebateTier}`;
};

export const rebateTierFilter = membership => {
  if (!membership) {
    return '';
  }
  const { rebateTier, rebateStatus } = membership;

  if (rebateStatus === REBATE_STATUS.UNREGISTERED) {
    return 'Not registered for rebate';
  }

  if (
    rebateStatus === REBATE_STATUS.REGISTERED &&
    (rebateTier === 0 || rebateTier == null)
  ) {
    return 'Base Tier';
  }

  return `Tier ${rebateTier}`;
};

const maybeEmpty = str => (str === ',' ? '' : str);
export const formatAddress = address =>
  address
    ? maybeEmpty(
        `${address.line1 ? address.line1 + ',' : ''} ${address.suburb || ''} ${
          address.state || ''
        } ${address.postcode || ''}`.trim()
      )
    : '';
