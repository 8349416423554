import { useState, useRef, useMemo, useEffect } from 'react';
import {
  createSmartDebounce,
  dropProp,
  isEmptyObject,
  required,
} from '~lib/util';
import { whereEq, path } from 'lodash/fp';
import useLocalStorage from '~lib/hooks/useLocalStorage';

let id = 0;

const generateUniqueName = () => {
  return `form${id++}`;
};

const createPersistedState =
  (key, mapFormBeforePersist, mapFormBeforeRead) => initialState =>
    useLocalStorage(key, initialState, {
      mapFormBeforePersist,
      mapFormBeforeRead,
    });

const mergeStates = (stateA, stateB) => {
  return {
    ...stateA,
    values: {
      ...stateA.values,
      ...stateB.values,
    },
    touched: {
      ...stateA.touched,
      ...stateB.touched,
    },
    errors: {
      ...stateA.errors,
      ...stateB.errors,
    },
  };
};

export const transformInputObject = inputObject => {
  if (
    inputObject &&
    (inputObject.values || inputObject.touched || inputObject.errors)
  ) {
    return {
      errors: {},
      touched: {},
      values: {},
      ...inputObject,
    };
  }

  return {
    values: inputObject || {},
    errors: {},
    touched: {},
  };
};

const ValidateHelper = validate => (values, onValidate) => {
  if (!validate) {
    return undefined;
  }
  const result = validate(values);
  if (result instanceof Promise) {
    return result
      .then(() => ({}))
      .catch(errors => errors)
      .then(errors => (onValidate ? onValidate(errors) : errors));
  }

  return onValidate ? onValidate(result) : result;
};

const FormFactory = ({
  initialForm,
  stateManager,
  changedFieldsManager,
  changeTracker,
  onChange,
  name,
  validate = () => ({}),
  smartDebounce,
}) => {
  const validateHelper = ValidateHelper(validate);
  const handleChange = smartDebounce(
    async (field, formikForm) => {
      let value = transformInputObject({
        [field.name]: field.value,
      });
      if (typeof onChange === 'function') {
        onChange(value.values);
      } else if (onChange) {
        Object.entries(value.values).forEach(([fieldName, fieldValue]) => {
          const listener = onChange[fieldName];
          if (listener) {
            let prevValue = changeTracker[fieldName];
            prevValue =
              prevValue == null
                ? stateManager.state.values[fieldName]
                : prevValue;
            changeTracker[fieldName] = fieldValue;
            const updateFormRequest = listener(
              fieldValue,
              prevValue,
              formikForm,
              name
            );
            if (updateFormRequest) {
              const updateFormRequestTransformed =
                transformInputObject(updateFormRequest);

              Object.entries(updateFormRequestTransformed.values).forEach(
                ([fieldName, fieldValue]) => {
                  formikForm.setFieldValue(fieldName, fieldValue);
                }
              );

              value = mergeStates(value, updateFormRequestTransformed);
            }
          }
        });
      }

      validateHelper(
        {
          ...stateManager.state.values,
          ...value.values,
        },
        errors => {
          stateManager.setState(currentState => {
            return {
              ...currentState,
              isValid: isEmptyObject(errors),
              touched: formikForm
                ? formikForm.touched
                : {
                    ...currentState.touched,
                    ...value.touched,
                  },
              errors,
              values: {
                ...currentState.values,
                ...value.values,
              },
            };
          });
        }
      );
    },
    300,
    {
      dontDebounceIf: ({ currentArgs, previousArgs }) => {
        if (!previousArgs) {
          return false;
        }

        const [currentField] = currentArgs;
        const [previousField] = previousArgs;
        return currentField.name !== previousField.name;
      },
    }
  );

  const setForm = (value, { merge = true } = {}) => {
    value = transformInputObject(value);
    validateHelper(
      {
        ...stateManager.state.values,
        ...value.values,
      },
      errors => {
        stateManager.setState(currentState => {
          const mergedState = merge ? mergeStates(currentState, value) : value;

          const newState = {
            ...mergedState,
            errors,
            isValid: isEmptyObject(errors),
          };

          //TODO: clean this up. it is not needed anymore as changedFields are calculated in Form itself based on the current and incoming state

          // const changedFields = uniq(
          //   Object.values(value)
          //     .map(entry => Object.keys(entry))
          //     .flat()
          // );
          //
          // const changed = changedFields.map(fieldName => ({
          //   name: fieldName,
          //   value: newState.values[fieldName],
          //   errors: newState.errors[fieldName],
          //   touched: newState.touched[fieldName],
          // }));
          //
          // changedFieldsManager.setState(changed);

          return newState;
        });
      }
    );
  };

  const transformValidationSchemaToTouched = validationSchema => {
    return Object.keys(validationSchema).reduce(
      (acc, fieldName) => ({
        ...acc,
        [fieldName]: true,
      }),
      {}
    );
  };

  const setAllTouchedFromValidationSchema = schema => {
    setForm({
      touched: transformValidationSchemaToTouched(schema),
    });
  };

  return {
    ...stateManager.state,
    name,
    setAllTouchedFromValidationSchema,
    changedFields: changedFieldsManager.state,
    handleChange,
    setForm,
    validate,
    clear: () => {
      stateManager.setState(transformInputObject({}));
    },
    reset: () => {
      stateManager.setState(transformInputObject(initialForm));
    },
    get validValues() {
      const { values, errors } = stateManager.state;
      return Object.entries(values)
        .filter(([fieldName, _]) => !path('length')(errors[fieldName]))
        .reduce((acc, [fieldName, fieldValue]) => {
          acc[fieldName] = fieldValue;
          return acc;
        }, {});
    },
  };
};

export const useDynamicForms = (
  initialForm = {},
  {
    onChange,
    validate,
    persistKey,
    mapFormBeforePersist,
    mapFormBeforeRead,
  } = {}
) => {
  const useStateMethod = useMemo(() => {
    return persistKey
      ? createPersistedState(
          persistKey,
          mapFormBeforePersist,
          mapFormBeforeRead
        )
      : useState;
  }, [persistKey]);

  const [formsState, setFormsState] = useStateMethod(() => {
    if (!initialForm) {
      return {};
    }

    return Object.entries(initialForm).reduce((acc, [name, value]) => {
      acc[name] = transformInputObject(value);
      return acc;
    }, {});
  });

  const smartDebounceRef = useRef({});

  const [formsTracker, setFormsTracker] = useState(formsState);

  const [changedFields, setChangedFields] = useState({});

  const changeTrackerRef = useRef({});

  const getStateManager = (name = required('name'), initialValue = {}) => {
    return {
      state: formsState[name] || initialValue,
      setState: newForm => {
        if (typeof newForm === 'function') {
          setFormsState(currentForms => ({
            ...currentForms,
            [name]: newForm(currentForms[name] || initialValue),
          }));
        } else {
          setFormsState({
            ...formsState,
            [name]: newForm,
          });
        }
      },
    };
  };

  const getChangedFieldsManager = (name = required('name')) => {
    return {
      state: changedFields[name] || [],
      setState: newValue => {
        if (typeof newValue === 'function') {
          setChangedFields(currentState => ({
            ...currentState,
            [name]: newValue(currentState[name] || []),
          }));
        } else {
          setChangedFields({
            ...changedFields,
            [name]: newValue,
          });
        }
      },
    };
  };

  const getChangeTracker = (name = required('name')) => {
    changeTrackerRef.current[name] = changeTrackerRef.current[name] || {};
    return changeTrackerRef.current[name];
  };

  const getSmartDebounce = (name = required('name')) => {
    smartDebounceRef.current[name] =
      smartDebounceRef.current[name] || createSmartDebounce();
    return smartDebounceRef.current[name];
  };

  const forms = useMemo(() => {
    return Object.entries(formsTracker).map(([name, initialValue]) => {
      return FormFactory({
        initialForm: initialValue,
        name,
        onChange,
        validate,
        stateManager: getStateManager(name, initialValue),
        changedFieldsManager: getChangedFieldsManager(name),
        changeTracker: getChangeTracker(name),
        smartDebounce: getSmartDebounce(name),
      });
    }, {});
  }, [formsTracker, formsState]);

  //TODO: add validateOnStartup functionality for useDynamicForms as we have one for useForm

  return {
    forms,
    clear: () => {
      forms.forEach(form => {
        form.clear();
      });
    },
    getForm: name =>
      forms.find(
        whereEq({
          name,
        })
      ),
    addForm: (name = generateUniqueName(), initialForm) => {
      const initialValue = transformInputObject(initialForm);
      setFormsTracker(currentState => ({
        ...currentState,
        [name]: initialValue,
      }));
      return name;
    },
    setForms: forms => {
      const preparedForms = forms.reduce((acc, form) => {
        const name = generateUniqueName();
        acc[name] = transformInputObject(form);
        return acc;
      }, {});
      setFormsTracker(preparedForms);
    },
    removeForm: name => {
      setFormsTracker(current => {
        return dropProp(name, current);
      });
      setFormsState(current => {
        return dropProp(name, current);
      });
    },
  };
};

export default (
  initialForm = {},
  {
    onChange,
    mapFormBeforePersist,
    mapFormBeforeRead,
    validate,
    persistKey,
  } = {}
) => {
  const useStateMethod = useMemo(() => {
    return persistKey
      ? createPersistedState(
          persistKey,
          mapFormBeforePersist,
          mapFormBeforeRead
        )
      : useState;
  }, [persistKey]);

  const [state, setState] = useStateMethod(() => {
    return transformInputObject(initialForm);
  });

  const [smartDebounce] = useState(() => {
    return createSmartDebounce();
  });

  const [changedFields, setChangedFields] = useState([]);
  const changeTrackerRef = useRef({});
  const validateHelper = ValidateHelper(validate);

  useEffect(() => {
    validateHelper(state.values, errors => {
      setState({
        ...state,
        errors,
        isValid: isEmptyObject(errors),
      });
    });
  }, []);

  return FormFactory({
    initialForm,
    onChange,
    validate,
    stateManager: {
      state,
      setState,
    },
    changedFieldsManager: {
      state: changedFields,
      setState: setChangedFields,
    },
    changeTracker: changeTrackerRef.current,
    smartDebounce,
  });
};
