import React from 'react';
import {
  InputControl,
  NumberInputControl,
} from '~common/molecules/form-controls';
import { FormPanel } from '../../common/Panel';
import { Flex } from 'rebass';
import MedicareCardTypeControl from './MedicareCardTypeControl';
import DateOfBirth from '~common/molecules/DateOfBirth';
import InfoIcon from '~common/molecules/InfoIcon';
import { StandardText } from '~common/atoms/typography';

const MedicareDetailsForm = ({ Field, values, handleChange }) => {
  return (
    <FormPanel title="Medicare details">
      <StandardText mb={32}>
        The details you provide in this section must reflect what is printed on
        your Medicare card.
      </StandardText>
      <Field
        onChange={handleChange}
        name="cardType"
        label="Card type"
        component={MedicareCardTypeControl}
      />
      <Field
        onChange={handleChange}
        name="cardNumber"
        label="Card number"
        component={NumberInputControl}
        focus
      />
      <Field
        onChange={handleChange}
        name="cardReferenceNumber"
        label={({ LabelContainer }) => (
          <Flex alignItems="center">
            <LabelContainer>Card reference number</LabelContainer>
            <InfoIcon
              ml={2}
              icon={{
                height: '20px',
                width: '20px',
              }}
              tooltip="This is the number to the left of your name on your Medicare card."
            />
          </Flex>
        )}
        maxDigits={1}
        realNumber
        component={NumberInputControl}
      />
      <Field
        onChange={handleChange}
        name="firstName"
        label="First name"
        component={InputControl}
      />
      <Field
        onChange={handleChange}
        name="middleName"
        label="Middle initial"
        component={InputControl}
      />
      <Field
        onChange={handleChange}
        name="lastName"
        label="Last name"
        component={InputControl}
      />

      <DateOfBirth
        label="Valid to"
        labelStyle={{
          fontWeight: 'normal',
        }}
        Field={Field}
        dayField={
          values.cardType !== 'green'
            ? {
                name: 'validToDay',
                label: null,
                onChange: handleChange,
              }
            : false
        }
        monthField={{
          name: 'validToMonth',
          label: null,
          onChange: handleChange,
        }}
        yearField={{ name: 'validToYear', label: null, onChange: handleChange }}
      />
    </FormPanel>
  );
};

export default MedicareDetailsForm;
