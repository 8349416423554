import React from 'react';
import styled from '@emotion/styled';
import { Box } from 'rebass';

const StyledFilterButton = styled(Box)`
  font-size: ${props => props.theme.fontSizes[1]}px;
  width: 75px;
  padding: 8px;
  text-align: center;
  background: ${props => props.theme.colors.iceBlue};
  transition: all 200ms ease-in-out;
  border-radius: 14.5px;
  cursor: pointer;
  ${props =>
    props.active
      ? `
    background: ${props.theme.colors.secondary};
    font-weight: bold;
    color: ${props.theme.colors.white};
  `
      : ''}
`;

const FilterButton = ({ children, active, theme, ...props }) => {
  return (
    <StyledFilterButton theme={theme} active={active} {...props}>
      {children}
    </StyledFilterButton>
  );
};

export default FilterButton;
