import React from 'react';
import styled from '@emotion/styled';
import { useThemeUI } from 'theme-ui';
import Icon from '~common/atoms/Icon';

const RadioButtonContainer = styled('div', {
  shouldForwardProp: propName => !['isSelected']
    .includes(propName)
})`
  cursor: pointer;
  position: relative;
  text-align: center;
  padding: 15px;
  background-color: #f6f6f6;
  border-radius: 5px;
  .radio-button-icon {
    margin: 0 auto;
  }
  p {
    padding-top: 16px;
  }
  ${props =>
    props.isSelected
      ? 'border: 2px solid #569b07'
      : 'border: 1px solid #dfdede'}
`;

const SelectedButton = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
`;

function ImageRadioButton({
  icon,
  text,
  fill,
  isSelected,
  handleSelect,
  index,
  setActiveName,
}) {
  const { theme } = useThemeUI();
  return (
    <RadioButtonContainer
      isSelected={isSelected}
      onClick={() => {
        handleSelect(index);
        setActiveName(text);
      }}
    >
      {isSelected && (
        <SelectedButton>
          <Icon
            name="circle-fill-tick"
            fill={theme.colors.green}
            width="17px"
            height="17px"
          />
        </SelectedButton>
      )}
      <Icon
        className="radio-button-icon"
        name={icon || 'status-single'}
        fill={fill || '#000'}
        width="33px"
        height="52px"
      />
      <p>{text}</p>
    </RadioButtonContainer>
  );
}

export default ImageRadioButton;
