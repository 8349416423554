import React from 'react';
import { Box } from 'rebass';
import { P1, H3 } from '~common/atoms/typography';
import useContactDetails from '~lib/hooks/sanity/useContactDetails';
import Link from '~common/atoms/Link';

const Submitted = () => {
  const sanityContactDetails = useContactDetails();

  return (
    <Box>
      <H3 mb={32}>Your request was successfully submitted</H3>
      <P1 mb={40}>
        We are working on your request and will confirm your account deletion
        within the next 3 business days.
      </P1>
      <P1 mb={40}>
        If you would like to reactive access to your online accounts, you will
        need to re-register via Online Member Services.
      </P1>
      <P1 mb={40}>
        Call us on{' '}
        <strong>
          <Link to={`tel:${sanityContactDetails.phone}`}>
            {' '}
            {sanityContactDetails.phone}
          </Link>
        </strong>{' '}
        if you need to talk.
      </P1>
    </Box>
  );
};

export default Submitted;
