export const tierInfo = {
  featured: {
    // This label will be replaced with the featuredTierTitle
    label: 'Featured tier inclusions',
    color: '#f6f6f6',
    textColor: '#4A4A4A',
  },
  basic: {
    label: 'Basic tier inclusions',
    color: '#f6f6f6',
    textColor: '#4A4A4A',
  },
  bronze: {
    label: 'Bronze tier inclusions',
    color: '#19BAAA',
  },
  silver: {
    label: 'Silver tier inclusions',
    color: '#54A2D9',
    image: 'silver-shield',
  },
  gold: {
    label: 'Gold tier inclusions',
    color: '#234F8C',
    image: 'gold-shield',
  },
  other: {
    label: 'Services and treatments',
    color: '#f6f6f6',
    textColor: '#4A4A4A',
  },
};

export const inclusionColors = {
  included: '#569b07',
  restricted: '#f5a623',
  excluded: '#d0021b',
};

/////
// --------
/////

const basic = ['basic'];
const bronze = [...basic, 'bronze'];
const silver = [...bronze, 'silver'];
const gold = [...silver, 'gold'];

// Used on the OMS 'My Cover' page
// Export as an object of Sets, allowing us to map coverage
// by product tier
export const coverageProductSet = {
  Basic: new Set(basic),
  Bronze: new Set(bronze),
  Silver: new Set(silver),
  Gold: new Set(gold),
  'Entry Level': new Set(['basic']),
  'Mid-Range': new Set(['basic']),
  Comprehensive: new Set(['basic', 'Comprehensive']),
};
