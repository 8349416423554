import React from 'react';
import { withTheme } from 'emotion-theming';
import { Flex } from 'rebass';
import styled from '@emotion/styled';

const StyledFlex = styled(Flex)``;

export const Row = withTheme(({ theme, children, ...props }) => {
  return (
    <StyledFlex
      theme={theme}
      mx={{
        xs: -theme.padding.xs.paddingLeft,
        md: -theme.padding.paddingLeft,
      }}
      flexWrap="wrap"
      {...props}
    >
      {children}
    </StyledFlex>
  );
});

export default withTheme(Row);
