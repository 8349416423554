import React from 'react';
import { parseQueryParams } from '~lib/util';

export default Component => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        queryParams: {},
        location: {},
      };
    }

    componentDidMount() {
      if (!this?.props?.location) {
        return;
      }
      const { search } = this?.props?.location;
      const params = parseQueryParams(search.slice(1));
      this.setState({
        queryParams: params,
        location: this.props.location,
      });
    }

    render() {
      const { children, props } = this.state.queryParams;
      return (
        <Component
          {...props}
          location={this.state.location}
          queryParams={this.state.queryParams}
        >
          {children}
        </Component>
      );
    }
  };
};
