import { useMutation } from '@apollo/client';
import { UpdateMembershipMutation } from 'queries/oms/membership.graphql';
import { DrawerQuery } from 'queries/oms/common.graphql';
import { cloneDeep } from '@apollo/client/utilities';

const updateDrawerQuery = ({ store, updateMembership }) => {
  const currentDrawerQuery = cloneDeep(store.readQuery({ query: DrawerQuery }));
  if (currentDrawerQuery && currentDrawerQuery.oms) {
    currentDrawerQuery.oms.activities.unshift(updateMembership.activity);
    store.writeQuery({ query: DrawerQuery, data: currentDrawerQuery });
  }
};

export default () => {
  const [updateMembership, ...rest] = useMutation(UpdateMembershipMutation);

  const updateMembershipWithUpdate = payload => {
    return updateMembership({
      variables: {
        input: payload,
      },
      update: (store, { data: { updateMembership } }) => {
        if (updateMembership.success) {
          updateDrawerQuery({
            store,
            updateMembership,
          });
        }
      },
    });
  };

  return [updateMembershipWithUpdate, ...rest];
};
