import React from 'react';
import FormField from '~common/molecules/FormField';
import {
  FancyCheckboxControl,
  InputControl,
  MultiCheckBoxControl,
} from '~common/molecules/form-controls';
import defaultYesNoOptions from '../../common/defaultYesNoOptions';
import { SubFormPanel } from '../../common/Panel';
import { StandardText } from '~common/atoms/typography';
import Link from '~common/atoms/Link';
import styled from '@emotion/styled';
import InfoIcon from '~common/molecules/InfoIcon';
import { Flex } from 'rebass';
import useAppFormAvanInformationOptions from '../../state/options/useAppFormAvanInformationOptions';

const StyledMultiCheckBoxControl = styled(MultiCheckBoxControl)`
  [name='${props => props.name}'] {
    margin-top: 10px;
  }
`;

const AvantGroupField = ({ onChange, fieldNames, values, label }) => {
  const avantInfoOptions = useAppFormAvanInformationOptions();
  return (
    <>
      <FormField
        name={fieldNames.question}
        label={({ LabelContainer }) => (
          <Flex alignItems="center">
            <LabelContainer mr={2}>{label}</LabelContainer>
            <InfoIcon tooltip="Avant is Australia’s leading medical defence organisation and medical indemnity insurance provider. Avant offers a range of additional insurance types for both your personal and professional needs as a doctor." />
          </Flex>
        )}
        component={FancyCheckboxControl}
        options={defaultYesNoOptions}
        onChange={onChange}
      />
      {values[fieldNames.question] && (
        <SubFormPanel>
          <FormField
            onChange={onChange}
            name={fieldNames.memberNumber}
            label="Avant member number"
            component={InputControl}
            bottomSpacing={false}
          />
        </SubFormPanel>
      )}

      {values[fieldNames.question] === false && (
        <SubFormPanel>
          <FormField
            name={fieldNames.information}
            label="Would you like to receive information on becoming an Avant member? (Optional)"
            component={StyledMultiCheckBoxControl}
            options={avantInfoOptions}
            onChange={onChange}
          />
          <StandardText fontSize={2}>
            *By ticking the box above you provide consent and agree to receive
            information by email, phone, or SMS about Avant insurance products
            and services. You can view more about the Avant insurance products
            at{' '}
            <Link to="https://avant.org.au/products/" external>
              https://avant.org.au/products/
            </Link>
          </StandardText>
        </SubFormPanel>
      )}
    </>
  );
};

export default AvantGroupField;
