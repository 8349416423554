import styled from '@emotion/styled';
import { themePirmaryDark } from './colors';
import { withTheme } from 'emotion-theming';

// This styled component is SSO page Heading title
const FormTitle = styled.h2`
  font-size: 32px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: ${themePirmaryDark};
  ${props => props.inputCss};
  margin-bottom: ${props => (props.marginReset ? 0 : 24)}px;
`;

export default withTheme(FormTitle);
