import React from 'react';
import Question from '../Question';
import RadioButtonGroup from '../RadioButtonGroup';
import { auStates } from '~lib/constants';
import NavButtons from '../NavButtons';
import { scrollTo } from '~lib/util';
import { useMobileScreenOnly } from '~lib/responsive';

const YourLocation = ({ form, onChange, activeStep, onNext, onBack }) => {
  const isMobile = useMobileScreenOnly();

  return (
    <Question title="Where do you live?">
      <RadioButtonGroup
        data-id="region"
        name="region"
        availableStates={[{ value: undefined, label: '' }].concat(auStates)}
        defaultValue={form.region ? form.region.value : undefined}
        onChange={change => {
          onChange('region', change);
          if (isMobile) {
            scrollTo('nav-buttons', {
              offset: -200,
            });
          }
        }}
      />
      <NavButtons
        nextDisabled={!activeStep.isValid}
        onNext={onNext}
        onBack={onBack}
      />
    </Question>
  );
};

export default YourLocation;
