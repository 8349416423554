import ModalConfirmation from '~common/molecules/ModalConfirmation';
import React from 'react';
import { StandardText } from '~common/atoms/typography';

export default ({ onClose, onConfirm, ...props }) => {
  return (
    <ModalConfirmation
      title="Important!"
      message={
        <>
          <StandardText mb={2} color="light2">
            You don't have any direct credit details nominated on your policy.
            To ensure your claim is paid promptly, you will need to nominate a
            bank account.
          </StandardText>
        </>
      }
      confirmText="Update details"
      cancelText="Proceed to claim"
      onConfirm={onConfirm}
      onCancel={onClose}
      {...props}
    />
  );
};
