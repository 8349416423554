import React from 'react';
import Question from '../Question';
import RadioButtonGroup from '../RadioButtonGroup';
import {
  EFC_MIN_AGE,
  EFC_UPPER_AGE_LIMIT,
  quoteStatuses,
} from '~lib/constants';
import NavButtons from '../NavButtons';
import { StandardText } from '~common/atoms/typography';
import YesNoChoice from '../YesNoChoice';
import AlertQuestion from '../AlertQuestion';
import styled from '@emotion/styled';
import { isEfcEligible, scrollTo } from '~lib/util';
import { Element } from 'react-scroll';
import { useMobileScreenOnly } from '~lib/responsive';

const StyledNavButtons = styled(NavButtons)`
  ${props => (props.isEfcEligible ? 'bottom: unset;' : '')}
`;

const CoverType = ({ form, activeStep, onChange, onNext }) => {
  const isMobile = useMobileScreenOnly();

  return (
    <Question title="Who is the cover for?">
      <RadioButtonGroup
        data-id="status"
        name="status"
        availableStates={[{ value: undefined, label: '' }].concat(
          quoteStatuses
        )}
        hasIcons={true}
        defaultValue={form.status ? form.status.value : undefined}
        onChange={change => {
          onChange('status', change);
          if (isMobile) {
            if (isEfcEligible(change?.value)) {
              scrollTo('question-efc', {
                offset: -50,
              });
            } else {
              scrollTo('nav-buttons', {
                offset: -200,
              });
              onChange('efc', undefined);
            }
          }
        }}
      />

      {isEfcEligible(form?.status?.value) && !!form?.status?.value && (
        <Element name="question-efc">
          <AlertQuestion
            pt={48}
            pb={0}
            alertName="question-efc-alert"
            title={`Do you wish to cover any dependent children aged ${EFC_MIN_AGE} to ${
              EFC_UPPER_AGE_LIMIT - 1
            } who are not studying?`}
            alert={
              !!form?.efc?.value && (
                <StandardText fontSize={1}>
                  By selecting this option, your quote will be calculated based
                  on Extended Family Cover, an optional upgrade available with
                  our Family policies. This means that your children who are not
                  studying can continue to be covered under this policy, until
                  they turn {EFC_UPPER_AGE_LIMIT}.
                </StandardText>
              )
            }
          >
            <YesNoChoice
              name="efc"
              availableStates={[
                { value: undefined, label: '' },
                { label: 'No', value: false },
                { label: 'Yes', value: true },
              ]}
              defaultValue={form?.efc?.value}
              onChange={change => {
                onChange('efc', change);
                if (isMobile) {
                  scrollTo('nav-buttons', {
                    offset: -200,
                  });
                }
              }}
            />
          </AlertQuestion>
        </Element>
      )}
      <StyledNavButtons
        isEfcEligible={isEfcEligible}
        nextDisabled={!activeStep.isValid}
        onNext={onNext}
        showBack={false}
      />
    </Question>
  );
};

export default CoverType;
