import React from 'react';
import useYouthDiscountPercentage from '../useYouthDiscountPercentage';
import useEffectWithPrevValues from '~lib/hooks/useEffectWithPrevValues';
import { openModal } from '../../common/appFormUtills';
import RecalculateInfo from '../../common/components/modals/RecalculateInfo';

export default criteria => {
  const youthDiscountPercentage = useYouthDiscountPercentage(criteria);

  useEffectWithPrevValues(
    ([prevValue]) => {
      if (youthDiscountPercentage && prevValue == null) {
        openModal(
          ({ close }) => (
            <RecalculateInfo
              onConfirm={close}
              message={
                <>
                  Age-based discount of {youthDiscountPercentage}% has been
                  applied. Click ‘OK’ to view your new premium amount and
                  proceed with your application.
                </>
              }
            ></RecalculateInfo>
          ),
          {
            padding: true,
          }
        );
      }
    },
    [youthDiscountPercentage]
  );
};
