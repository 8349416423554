import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

import Section from './Section';
import Heading from './Heading';
import Description from './Description';
import Selects from './Selects';
import HorizontalRule from '../shared/components/HorizontalRule';
import Cards from './Cards';
import DesktopActions from './DesktopActions';
import Callout from './Callout';

const QuoteOverview = ({
  form,
  onApply,
  onEmailQuote,
  prices,
  productType,
  quote,
  selections,
  setProductType,
  setShowFloatingSummary,
  updateForm,
  setFormVisible,
}) => {
  const handleCoverTypeChange = event => {
    setProductType(event.target.value);
  };

  const handleExcessChange = event => {
    updateForm('hospitalProduct', event.target.value);
  };

  const handleFrequencyChange = event => {
    updateForm('paymentFrequency', event.target.value);
  };

  return (
    <Section setShowFloatingSummary={setShowFloatingSummary}>
      <Element name="quote-overview">
        <Heading />
        <Description form={form} setFormVisible={setFormVisible} />
        <Selects
          loading={quote.loading}
          onCoverTypeChange={handleCoverTypeChange}
          onExcessChange={handleExcessChange}
          onFrequencyChange={handleFrequencyChange}
          paymentFrequency={form.paymentFrequency}
          productType={productType}
          selections={selections}
          status={form.status.value}
        />
        <HorizontalRule mb="32" mt="32" />
        <Cards
          form={form}
          onApply={onApply}
          onEmailQuote={onEmailQuote}
          prices={prices}
          quote={quote}
          selections={selections}
          setProductType={setProductType}
        />
        {form.efc?.value ? <Callout /> : null}
        <DesktopActions onApply={onApply} onEmailQuote={onEmailQuote} />
      </Element>
    </Section>
  );
};

QuoteOverview.propTypes = {
  form: PropTypes.object.isRequired,
  onApply: PropTypes.func.isRequired,
  onEmailQuote: PropTypes.func.isRequired,
  prices: PropTypes.object.isRequired,
  productType: PropTypes.string.isRequired,
  quote: PropTypes.object.isRequired,
  selections: PropTypes.object.isRequired,
  setProductType: PropTypes.func.isRequired,
  setShowFloatingSummary: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default QuoteOverview;
