import {
  email,
  FormValidator,
  mobileNumber,
  required,
} from '~lib/formValidation';
import {
  personalDetailsValidationSchema,
  privateHealthDetailsValidationSchema,
} from './yourDetailsValidation';

export const partnersDetailsValidationSchema = {
  ...personalDetailsValidationSchema,
  currentHealthFund: required('Current health fund is required field'),
  membershipNumber: privateHealthDetailsValidationSchema.membershipNumber,
  relationship: required('Relationship is required'),
  email: [required('Email is required'), email()],
  mobileNumber: [
    required('Mobile number is required'),
    mobileNumber('Invalid mobile phone number'),
  ],
};

export default () =>
  FormValidator({
    validation: partnersDetailsValidationSchema,
    transformValidation: (validationArray, form) => {
      return validationArray.filter(([field]) => {
        let pass = true;
        if (!form.isTransferringFromAnotherFund) {
          pass = !['currentHealthFund', 'membershipNumber'].includes(field);
        }
        if (!form.showLhcFlow) {
          return (
            pass && !['lhcStatement', 'firstHospitalCoverAge'].includes(field)
          );
        }
        if (form.lhcStatement) {
          return pass && field !== 'firstHospitalCoverAge';
        }
        return pass;
      });
    },
  });
