import React from 'react';
import Block from '~common/atoms/Block';
import { Box, Flex } from 'rebass';
import { P1, P2 } from '~common/atoms/typography';
import WorkingTime from '~common/molecules/WorkingTime';
import CallUsWithIcon from '~common/molecules/CallUsWithIcon';
import EmailUsWithIcon from '~common/molecules/EmailUsWithIcon';
import RequestCallbackWithIcon from '~common/molecules/RequestCallbackWithIcon';
import { useThemeUI } from 'theme-ui';
import css from '@emotion/css';

export default props => {
  const { theme } = useThemeUI();
  return (
    <Block
      mt={64}
      mb={0}
      pt={40}
      pb={40}
      bg="primary"
      innerProps={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          tabletP: 'row',
        },
        alignItems: {
          xs: 'flex-start',
          tabletP: 'flex-end',
        },
        justifyContent: {
          xs: 'flex-start',
          tabletP: 'space-between',
        },
      }}
      {...props}
    >
      <Flex
        flexDirection="column"
        mb={{
          xs: 40,
          tabletP: 0,
        }}
      >
        <P1 mb="9px" variant="semiBold" color="white">
          Contact member services
        </P1>
        <WorkingTime
          render={workingTime => (
            <P2
              width={{
                xs: 1,
                tabletP: 300,
              }}
              variant="medium"
              color="white"
            >
              {workingTime}
            </P2>
          )}
        />
      </Flex>
      <Box
        css={css`
          display: grid;
          grid-template-columns: 100%;
          grid-row-gap: 30px;
          ${theme.mq.tabletP} {
            grid-template-columns: auto auto auto;
            grid-column-gap: 30px;
          }
        `}
      >
        <CallUsWithIcon
          textProps={{
            variant: 'semiBold',
            as: P2,
          }}
          iconProps={{
            height: '16px',
            width: '16px',
            stroke: theme.colors.white,
          }}
        />
        <EmailUsWithIcon
          textProps={{
            variant: 'semiBold',
            as: P2,
          }}
          iconProps={{
            height: '16px',
            width: '16px',
            stroke: theme.colors.white,
          }}
        />
        <RequestCallbackWithIcon
          textProps={{
            variant: 'semiBold',
            as: P2,
          }}
          iconProps={{
            height: '16px',
            width: '16px',
            stroke: theme.colors.white,
          }}
        />
      </Box>
    </Block>
  );
};
