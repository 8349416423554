import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { space, layout } from 'styled-system';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const linkStyles = props => css`
  ${space(props)};
  ${layout(props)};
  ${props.noStyles
    ? ''
    : `
    cursor: pointer;
    color: ${props.theme.colors.primary};
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
    `}
  line-height: 1.3;
`;

const StyledLink = styled(GatsbyLink, {
  shouldForwardProp: propName => !['noStyles'].includes(propName),
})`
  ${linkStyles}
`;

const StyledExternalLink = styled('a', {
  shouldForwardProp: propName => !['noStyles'].includes(propName),
})`
  ${linkStyles}
`;

const Link = ({
  external,
  to,
  noStyles = false,
  target,
  className,
  children,
  ...props
}) => {
  if (external) {
    return (
      <StyledExternalLink
        noStyles={noStyles}
        className={className}
        href={to}
        target={target || '_blank'}
        rel={target === '_blank' || !target ? 'noreferrer noopener' : ''}
        {...props}
      >
        {children}
      </StyledExternalLink>
    );
  }

  const filteredProps = [
    'activeClassName',
    'activeStyle',
    'partiallyActive',
    'partial',
    'onClick',
    'onHover',
    'onMouseEnter',
    'onMouseLeave',
    'onFocus',
    'onBlur',
    'to',
    'replace',
    'style',
    'css',
    'className',
    'state',
    'data-id',
    'target',
    'data-size',
  ].reduce((obj, key) => {
    if (props.hasOwnProperty(key)) {
      obj[key] = props[key];
    }

    return obj;
  }, {});

  return (
    <StyledLink
      noStyles={noStyles}
      className={className}
      to={to}
      {...filteredProps}
    >
      {children}
    </StyledLink>
  );
};

export default Link;
