import React, { useEffect } from 'react';
import { Box } from 'rebass';
import { Form } from '~common/molecules/Form';
import { Field } from 'formik';
import { Button } from '~common/atoms/Button';
import CenterBox from '~common/atoms/CenterBox';
import useForm from '~lib/hooks/useForm';
import { FORM_MODE } from '~lib/constants';
import { ReadOnlyControl } from '~common/molecules/form-controls';

const ViewMyDetails = ({ initialForm, setMode, ...props }) => {
  const form = useForm(initialForm);

  useEffect(() => {
    if (initialForm) {
      form.setForm(initialForm);
    }
  }, [initialForm]);
  return (
    <Form form={form}>
      {() => (
        <Box {...props}>
          <Field
            name="address"
            label="Address"
            labelPosition="left"
            component={ReadOnlyControl}
          />

          <Field
            name="dob"
            label="Date of birth"
            labelPosition="left"
            component={ReadOnlyControl}
          />
          <Field
            name="email"
            label="Email address"
            labelPosition="left"
            component={ReadOnlyControl}
          />

          <Field
            name="mobileNumber"
            label="Mobile number"
            labelPosition="left"
            component={ReadOnlyControl}
          />
          <CenterBox>
            <Button
              onClick={() => {
                setMode(FORM_MODE.EDIT);
              }}
              variant="primary"
              width={180}
            >
              Edit details
            </Button>
          </CenterBox>
        </Box>
      )}
    </Form>
  );
};

export default ViewMyDetails;
