import { useMemo } from 'react';
import useHealthFunds from '~lib/hooks/sanity/useHealthFunds';

export default () => {
  const healthFunds = useHealthFunds();

  const options = useMemo(() => {
    return healthFunds.map(title => ({
      value: title.id,
      label: title.title,
    }));
  }, [healthFunds]);

  return options;
};
