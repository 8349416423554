import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Icon from '~common/atoms/Icon';

const StyledCard = styled.div`
  align-self: flex-end;
  background-color: transparent;
  padding-bottom: 24px;
`;

const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const StyledListItem = styled.li`
  margin-top: 14px;
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

const LegendCard = ({ type, hat }) => {
  return (
    <StyledCard>
      <StyledList>
        <StyledListItem>
          <StyledIcon
            aria-label="Inclusions"
            name="circle-fill-tick"
            fill="#569b07"
            width="16px"
            height="16px"
          />
          Inclusions
        </StyledListItem>
        {type !== 'extras' ? (
          <StyledListItem>
            <StyledIcon
              aria-label="Restricted"
              name="restricted"
              fill="#f5a623"
              width="16px"
              height="16px"
            />
            Restrictions
            {hat ? '^' : null}
          </StyledListItem>
        ) : null}
        <StyledListItem>
          <StyledIcon
            aria-label="Exclusions"
            name="remove"
            fill="#d0021b"
            width="16px"
            height="16px"
          />
          Exclusions
        </StyledListItem>
      </StyledList>
    </StyledCard>
  );
};

LegendCard.propTypes = {
  type: PropTypes.string.isRequired,
  hat: PropTypes.bool,
};

export default LegendCard;
