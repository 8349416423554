import React from 'react';
import { Flex, Box } from 'rebass';
import useForm from '~lib/hooks/useForm';
import { Form } from '~common/molecules/Form';
import { StandardText } from '~common/atoms/typography';
import { auStates, quoteStatuses, tiers } from '~lib/constants';
import styled from '@emotion/styled';
import FormField from '~common/molecules/FormField';
import { Button } from '~common/atoms/Button';
import { isEfcEligible } from '~lib/util';
import { CheckEfcControl } from './CheckEfcControl';
import EditCoverDropDownControl from './EditCoverDropDownControl';

const StyledSummaryDropDownControl = styled(EditCoverDropDownControl)`
  &&& {
    margin-bottom: 0;
  }
  &&& .error-message {
    display: none;
  }
`;

const StatusDropDownControl = styled(StyledSummaryDropDownControl)`
  &&& {
    width: 150px;
  }
`;

const RegionDropDownControl = styled(StyledSummaryDropDownControl)`
  &&& {
    width: 80px;
  }
`;

const TierDropDownControl = styled(StyledSummaryDropDownControl)`
  &&& {
    width: 150px;
  }
`;

const EditCoverModalContainer = styled(Box)``;

const EditCoverModal = ({ initialForm, onConfirm, onCancel }) => {
  const form = useForm(initialForm, {
    onChange: {
      status(value) {
        if (!isEfcEligible(value) && form.values.efc) {
          return {
            efc: false,
          };
        }

        return undefined;
      },
    },
  });
  return (
    <EditCoverModalContainer>
      <StandardText
        textAlign={{
          xs: 'center',
          tabletP: 'left',
        }}
        fontSize={32}
        mb={{
          xs: 84,
          tablet: 42,
        }}
      >
        Edit Cover
      </StandardText>
      <Form form={form} onSubmit={onConfirm}>
        {({ values, handleSubmit, handleChange }) => (
          <>
            <Flex
              flexDirection={{
                xs: 'column',
                tabletP: 'row',
              }}
              alignItems="center"
            >
              <StandardText
                mr={{
                  xs: 0,
                  tabletP: 10,
                }}
                mb={{
                  xs: 20,
                  tabletP: 0,
                }}
                fontSize={3}
              >
                Based on a
              </StandardText>
              <FormField
                onChange={evt => {
                  handleChange(evt);
                }}
                name="status"
                component={StatusDropDownControl}
                options={quoteStatuses}
              />
              <StandardText
                fontSize={3}
                mx={{
                  xs: 0,
                  tabletP: 10,
                }}
                mt={{
                  xs: 50,
                  tabletP: 0,
                }}
                mb={{
                  xs: 20,
                  tabletP: 0,
                }}
              >
                living in
              </StandardText>
              <FormField
                onChange={handleChange}
                name="region"
                component={RegionDropDownControl}
                options={auStates}
              />
              <StandardText
                fontSize={3}
                mx={{
                  xs: 0,
                  tabletP: 10,
                }}
                mt={{
                  xs: 50,
                  tabletP: 0,
                }}
                mb={{
                  xs: 20,
                  tabletP: 0,
                }}
              >
                with a combined annual income of
              </StandardText>
              <FormField
                onChange={handleChange}
                name="tier"
                component={TierDropDownControl}
                options={tiers[values.status]}
              />
            </Flex>
            {isEfcEligible(values.status) && (
              <Box mt={52}>
                <FormField
                  name="efc"
                  onChange={handleChange}
                  component={CheckEfcControl}
                  labelProps={{
                    fontSize: 16,
                  }}
                  infoIconProps={{
                    height: '18px',
                    width: '18px',
                  }}
                />
              </Box>
            )}

            <Flex
              mt={{
                xs: 60,
                tabletP: 52,
              }}
              flexDirection={{
                xs: 'column',
                tabletP: 'row',
              }}
            >
              <Button
                width={{
                  xs: 1,
                  tabletP: 200,
                }}
                mr={{
                  xs: 0,
                  tabletP: 10,
                }}
                mb={{
                  xs: 10,
                  tabletP: 0,
                }}
                type="button"
                variant="primary"
                onClick={handleSubmit}
              >
                Recalculate
              </Button>
              <Button
                onClick={onCancel}
                width={{
                  xs: 1,
                  tabletP: 200,
                }}
                variant="primaryInverted"
              >
                Cancel
              </Button>
            </Flex>
          </>
        )}
      </Form>
    </EditCoverModalContainer>
  );
};

export default EditCoverModal;
