import useFeatureToggle from './useFeatureToggle';
import moment from 'moment';

export const useRatesToggleDate = () => {
  const { ratesToggleDate } = useFeatureToggle();
  return moment(ratesToggleDate, 'YYYY-MM-DD');
};

export default quoteForm => {
  const { ratesToggle } = useFeatureToggle();
  return ratesToggle;
};
