import { APPLICATION_FORM_STEPS } from '../../constants';
import React, { useCallback } from 'react';
import { openModal } from '../../common/appFormUtills';
import EditCoverModal from '../../QuoteSummary/EditCoverModal';
import { path, whereEq } from 'lodash/fp';
import ChangeOfAddressWarningModal from '../../QuoteSummary/ChangeOfAddressWarningModal';
import { auStates, quoteStatuses, tiers } from '~lib/constants';

export default ({
  stepProvider,
  criteria = {},
  setCriteria,
  setActiveStep,
}) => {
  const yourDetailsContext = stepProvider.get(
    APPLICATION_FORM_STEPS.YOUR_DETAILS.value
  ).context;

  const options = {
    status: quoteStatuses,
    region: auStates,
    tier: tiers,
    efc: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  };

  const applyCoverChanges = useCallback(
    changes => {
      setCriteria(currentCriteria => {
        const newCriteria = Object.entries(changes).reduce(
          (acc, [name, value]) => {
            const availableOptions =
              name === 'tier' ? options.tier[changes.status] : options[name];
            return {
              ...acc,
              [name]: availableOptions.find(
                whereEq({
                  value,
                })
              ),
            };
          },
          currentCriteria
        );

        return newCriteria;
      });
    },
    [setCriteria]
  );

  const pickCriteriaValues = useCallback(
    (propNames = []) => {
      return propNames.reduce((acc, propName) => {
        return {
          ...acc,
          [propName]: path(`${propName}.value`)(criteria),
        };
      }, {});
    },
    [criteria]
  );

  const openEditCoverModal = useCallback(() => {
    const initialForm = pickCriteriaValues(['status', 'region', 'tier', 'efc']);

    openModal(
      ({ close }) => (
        <EditCoverModal
          initialForm={initialForm}
          onCancel={close}
          onConfirm={form => {
            close();

            const contactDetailsForm =
              path(`contactDetailsForm`)(yourDetailsContext);

            if (
              form.region !== initialForm.region &&
              (contactDetailsForm.values.residentialAddress ||
                contactDetailsForm.values.postalAddress)
            ) {
              openModal(({ close: closeChangeOfAddressModal }) => (
                <ChangeOfAddressWarningModal
                  onConfirm={() => {
                    closeChangeOfAddressModal();
                    contactDetailsForm.setForm({
                      values: {
                        residentialAddress: undefined,
                        postalAddress: undefined,
                      },
                      touched: {
                        residentialAddress: true,
                        postalAddress: true,
                      },
                    });

                    setActiveStep(APPLICATION_FORM_STEPS.YOUR_DETAILS);
                    yourDetailsContext.setExpanded('contactDetails', true);
                    yourDetailsContext.goToForm('contactDetails');
                    applyCoverChanges(form);
                  }}
                />
              ));
            } else {
              applyCoverChanges(form);
            }
          }}
        />
      ),
      {
        px: {
          xs: '20px',
          tabletP: '40px',
          tabletL: '60px',
          lg: '80px',
        },
        py: {
          xs: '20px',
          tabletP: '40px',
          tabletL: '60px',
        },
        showCloseIcon: true,
        minWidth: {
          xs: 1,
          tabletP: '1000px',
        },
        width: {
          xs: 1,
          tabletP: '1000px',
        },
      }
    );
  }, [
    path('contactDetailsForm.values')(yourDetailsContext),
    criteria,
    pickCriteriaValues,
  ]);

  return {
    openEditCoverModal,
  };
};
