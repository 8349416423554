import styled from '@emotion/styled';
import React from 'react';
import { Box, Flex } from 'rebass';
import { wordyFormat } from '~lib/dates';
import { StandardText } from '../../../common/atoms/typography';
import SanityRichTextContent from '../../../common/molecules/SanityRichTextContent';
import { Image } from '../../../components/PageBlocks';
import Accordion from '../../../components/PageBlocks/Accordion';
import { TitleBase } from '../../../components/PageBlocks/Title';
import ShareButton from './ShareButton';

const SmallText = styled(StandardText)`
  font-size: 14px;
`;

const ReadingTime = ({ content, ...props }) => {
  const AVERAGE_WORDS_PER_MINUTE = 225;

  return (
    <SanityRichTextContent
      renderRawText={rawText => (
        <SmallText color="blue2" {...props}>
          {Math.ceil(rawText.split(/ +/g).length / AVERAGE_WORDS_PER_MINUTE) ||
            1}{' '}
          min read
        </SmallText>
      )}
      content={content}
    />
  );
};

const Article = React.forwardRef(
  ({ article, nextArticles = [], ...props }, ref) => {
    return (
      <Box ref={ref} {...props}>
        <Box
          width={{
            xs: 1,
            tabletL: 700,
            lg: 790,
            xl: 790,
          }}
        >
          <TitleBase textAlign="left" mb={14}>
            {article.title}
          </TitleBase>
          <Flex
            flexDirection={{
              xs: 'column',
              tabletP: 'row',
            }}
            mb={{
              xs: 60,
              tabletL: 80,
            }}
          >
            <SmallText mr={2} color="light">
              Date published: {wordyFormat(article.date)}
            </SmallText>
            <SmallText mr={2} color="light">
              Author: {article.author.name}
            </SmallText>
            <ReadingTime content={article.content} />
          </Flex>
          {article.image && <Image pr={0} pl={0} data={article.image} />}
          <SanityRichTextContent
            mb={{
              xs: 60,
              tabletL: 80,
            }}
            content={article.content}
          />
          {article.accordion && (
            <Accordion
              style={{ padding: 'inherit' }}
              data={article.accordion}
            />
          )}
          <ShareButton article={article} />
        </Box>
      </Box>
    );
  }
);

export default Article;
