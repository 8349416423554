import Loading from '~common/atoms/Loading';
import MemberCard from '~OMS/my-details/organisms/MemberCard';
import React, { useEffect } from 'react';
import { Element, scroller } from 'react-scroll';

import useMemberCard from '~OMS/my-details/hooks/useMemberCard';

const scrollTo = memberId => {
  scroller.scrollTo(`member-${memberId}`, {
    duration: 1000,
    delay: 0,
    isDynamic: true,
    offset: 0,
    smooth: 'easeInOutQuint',
  });
};

const Members = ({ expandMember }) => {
  const {
    memberCards,
    membersLoading,
    toggleCard,
    inviteMemberWithCheck,
    saveMemberDetails,
    memberAccessAllowed,
  } = useMemberCard(expandMember);

  useEffect(() => {
    if (!membersLoading && expandMember) {
      setTimeout(() => {
        scrollTo(expandMember);
      }, 500);
    }
  }, [expandMember, membersLoading]);

  return membersLoading ? (
    <Loading />
  ) : (
    memberCards.map(card => (
      <Element name={`member-${card.member.id}`} key={card.member.id}>
        <MemberCard
          memberAccessAllowed={memberAccessAllowed}
          onChangeMemberDetails={(form, canBeInvited) => {
            saveMemberDetails(card.member.id, form, canBeInvited);
          }}
          onInvite={form => {
            inviteMemberWithCheck(card.member.id, form);
          }}
          onToggle={() => {
            toggleCard(card.member.id);
          }}
          key={card.member.id}
          {...card}
          mb={24}
          expandedOnLoad={card.isCurrentUser}
        />
      </Element>
    ))
  );
};

export default Members;
