import React from 'react';
import Block from '../../common/atoms/Block';
import { Flex, Box } from 'rebass';
import SanityImage from '../../common/molecules/SanityImage';
import { H3, TextResponsive } from '../../common/atoms/typography';
import Button from './Button';
import { useThemeUI } from 'theme-ui';
import { useMobileScreenOnly } from '~lib/responsive';
import styled from '@emotion/styled';

const Action = ({ action }) =>
  action ? (
    <Box width={153}>
      <Button
        width={1}
        data={{
          ...action,
          inverted: true,
        }}
      />
    </Box>
  ) : null;

const Title = ({ title, mb }) => (
  <H3 color="white" mb={mb}>
    {title}
  </H3>
);

const Subtitle = ({ subtitle, mb }) => (
  <TextResponsive fontSize={14} color="white" lineHeight={1.3} mb={mb}>
    {subtitle}
  </TextResponsive>
);

const StyledImage = styled(SanityImage)`
  margin-bottom: ${props => props.mb}px;
  ${props => props.theme.mq.tabletP} {
    width: 264px;
  }

  ${props => props.theme.mq.tabletL} {
    width: 269px;
  }

  ${props => props.theme.mq.xs} {
    width: 200px;
  }
`;

const PromoBannerContainer = styled(Block)`
  ${props => props.theme.mq.tabletP} {
    transform: translateY(${props => (props.isBelowHeroBanner ? -69 : 0)}px);
    margin-bottom: ${props => (props.isBelowHeroBanner ? -69 : 0)}px;
  }
`;

const PromoBanner = ({ data: { title, subtitle, image, action }, prev }) => {
  const isMobile = useMobileScreenOnly();

  const { theme } = useThemeUI();
  return (
    <PromoBannerContainer
      redefinePl={{
        xs: 0,
      }}
      redefinePr={{
        xs: 0,
      }}
      isBelowHeroBanner={prev._type === 'heroComponent'}
      bg="transparent"
      innerProps={{
        px: 59,
        py: 36,
        pt: 44,
        pb: 44,
        bg: theme.colors.blue600 || theme.colors.secondary,
        as: Flex,
        flexDirection: {
          xs: 'column',
          tabletP: 'row',
        },
        justifyContent: {
          xs: 'space-evenly',
          tabletP: 'flex-start',
        },
        alignItems: {
          xs: 'center',
          tabletP: 'flex-start',
        },
      }}
    >
      {isMobile ? (
        <>
          <Title mb={24} title={title} />
          {image && <StyledImage mb={24} src={image} />}
          {subtitle && <Subtitle mb={25} subtitle={subtitle} />}
          <Action action={action} />
        </>
      ) : (
        <>
          <StyledImage src={image} />
          <Flex
            ml={81}
            flexDirection="column"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              height="100%"
              width={{
                tabletP: 352,
                tabletL: 475,
                lg: 768,
              }}
            >
              <Title mb={16} title={title} />
              <Subtitle mb={26} subtitle={subtitle} />
              <Action action={action} />
            </Flex>
          </Flex>
        </>
      )}
    </PromoBannerContainer>
  );
};

export default PromoBanner;
