import React from 'react';
import { Link } from 'gatsby';
import { withTheme } from 'emotion-theming';
import { Flex, Box, Button } from 'rebass';
import Icon from '~common/atoms/Icon';

import LabelInput from '~common/molecules/LabelInput';
import FormTitle from '~OMS/styles/FormTitle';
import FormText from '~OMS/styles/FormText';
import SSOFormContainer from '~OMS/styles/SSOFormContainer';

// SSO SMS Code sent page wrapper component
function SMSCodeWrapper() {
  return (
    <SSOFormContainer>
      <FormTitle>Check your mobile</FormTitle>
      <Flex>
        <Box width={1} mb="20px" mt="20px">
          <FormText>
            We take your privacy seriously. In order to protect your family
            health information we will verify you via sms from time to time.
          </FormText>
        </Box>
      </Flex>
      <Flex>
        <Box width={1} mb="30px">
          <FormText>
            We've sent a code to your mobile ending <strong>347</strong>.
          </FormText>
        </Box>
      </Flex>
      <form>
        <Flex>
          <Box width={1} mb="30px">
            <LabelInput label="Enter SMS code" type="text" />
          </Box>
        </Flex>
        <Flex>
          <Box width={1 / 2} mb="30px">
            <Link to="/oms/sso/resendCode">Resend Code</Link>
          </Box>
          <Box width={1 / 2} mb="30px">
            <Flex justifyContent="flex-end">
              <span>Did not receive a code</span>
              <Icon
                name="help-circled"
                fill="#87929f"
                width="20px"
                height="20px"
                style={{ marginLeft: '5px' }}
              />
            </Flex>
          </Box>
        </Flex>
        <Flex flexDirection={{ xs: 'column', lg: 'row' }}>
          <Box
            width={{ md: '100%', lg: '48%' }}
            mb="30px"
            mr={{ xs: '0', lg: '10px' }}
          >
            <Button variant="inverted" css={{ width: '100%' }}>
              Back
            </Button>
          </Box>
          <Box
            width={{ md: '100%', lg: '48%' }}
            mb="30px"
            ml={{ xs: '0', lg: '10px' }}
          >
            <Button variant="orange" css={{ width: '100%' }}>
              Login
            </Button>
          </Box>
        </Flex>
      </form>
    </SSOFormContainer>
  );
}

export default withTheme(SMSCodeWrapper);
