import { useEffect, useRef } from 'react';

export default (listener, deps) => {
  const prevValues = useRef([]);

  useEffect(() => {
    listener(prevValues.current);
    prevValues.current = deps;
  }, deps);
};
