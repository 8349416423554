import React from 'react';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';

const StyledXSHidden = styled.div`
  display: none;
  height: 100%;
  ${props => props.mq.sm} {
    display: initial;
  }
`;

export const XSHidden = withTheme(({ theme, children }) => {
  return <StyledXSHidden mq={theme.mq}>{children}</StyledXSHidden>;
});

const StyledLGHidden = styled.div`
  display: inline;
  ${props => props.mq.lg} {
    display: none;
  }
`;

export const LGHidden = withTheme(({ theme, children }) => {
  return <StyledLGHidden mq={theme.mq}>{children}</StyledLGHidden>;
});

const StyledMDHidden = styled.div`
  display: inline;
  ${props => props.mq.md} {
    display: none;
  }
`;

export const MDHidden = withTheme(({ theme, children }) => {
  return <StyledMDHidden mq={theme.mq}>{children}</StyledMDHidden>;
});
