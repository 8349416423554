import { PAYMENT_TYPE } from '~OMS/payments/constants';
import {
  mapAccountType,
  sanitizeCardNumber,
  maskCardNumber,
} from '~OMS/payments/paymentUtils';
import { detectCard } from '~OMS/payments/cardDetector';
import { dropEmpties } from '~lib/util';

export const toQueryParams = query => {
  return Object.entries(query)
    .map(([paramName, paramValue]) => {
      return `${paramName}=${paramValue}`;
    })
    .join('&');
};

const getResultUrl = (form, details) => {
  const resultUrl = details.result;
  const query = {
    resultHash: details.resultHash,
    name: details.name,
    personId: details.personId,
    membershipId: details.membershipId,
  };
  return encodeURI(`${resultUrl}?${toQueryParams(query)}`);
};

const getChangeDetailsPayloadBase = (form, fingerprintDetails) => {
  const { merchantId, timestamp, action, crn, fingerprint } =
    fingerprintDetails;
  return {
    EPS_MERCHANT: merchantId,
    EPS_TIMESTAMP: timestamp,
    EPS_ACTION: action,
    EPS_CRN: crn,
    EPS_FINGERPRINT: fingerprint,
    EPS_RESULTURL: getResultUrl(form, fingerprintDetails),
    EPS_TYPE: 'CRN',
  };
};

const payloadFactory = paymentType =>
  ({
    [PAYMENT_TYPE.INVOICE]: form => {
      return {
        frequency: form.frequency,
        debitAccount: null,
      };
    },
    [PAYMENT_TYPE.BANK_ACCOUNT]: form => {
      return dropEmpties({
        frequency: form.frequency,
        effectiveDate: form.effectiveDate,
        debitAccount: {
          type: 'BANK',
          accountName: form.accountName,
          bankDetails: {
            accountBSB: form.bsb,
            accountNumber: form.accountNumber,
          },
        },
      });
    },
    [PAYMENT_TYPE.CREDIT_CARD]: {
      nab: (form, fingerprintDetails) => {
        const [expiryMonth, expiryYear] = form.expiry.split('/');
        return {
          ...getChangeDetailsPayloadBase(form, fingerprintDetails),
          EPS_CARDNUMBER: sanitizeCardNumber(form.cardNumber),
          EPS_EXPIRYYEAR: expiryYear,
          EPS_EXPIRYMONTH: expiryMonth,
          // EPS_CCV: form.cvv,
        };
      },
      hambs: form => {
        return dropEmpties({
          frequency: form.frequency,
          effectiveDate: form.effectiveDate,
          debitAccount: {
            type: detectCard(form.cardNumber).type,
            accountName: form.cardHolder,
            chargeCardDetails: {
              number: maskCardNumber(sanitizeCardNumber(form.cardNumber)),
              holder: form.cardHolder,
              expiry: form.expiry,
            },
          },
        });
      },
    },
  }[paymentType]);

const getChangeDetailsPayload = type => {
  const mappedType = mapAccountType(type);
  const payLoader = payloadFactory(mappedType);
  if (typeof payLoader === 'function') {
    return (form, fingerprintDetails) => {
      return payLoader(form, fingerprintDetails);
    };
  }

  return payLoader;
};

export default getChangeDetailsPayload;
