import React from 'react';
import { Flex } from 'rebass';
import { currency } from '~lib/filters';
import { P3 } from '~common/atoms/typography';

const WithExcess = ({ excess, ...props }) => {
  return (
    <Flex justifyContent="center" {...props}>
      <P3 variant="medium" color="neutral500">
        {excess ? <>With {currency(excess)} excess</> : 'No Excess'}
      </P3>
    </Flex>
  );
};

export default WithExcess;
