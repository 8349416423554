import React from 'react';
import { useMemo, useState } from 'react';
import { useUpdateAddress } from '~common/hooks/useUpdateAddress';
import {
  OrderCardDetails,
  OrderCardMutation,
} from 'queries/oms/mycover.graphql';
import { DrawerQuery } from 'queries/oms/common.graphql';
import { pathOr, waterfall } from '~lib/util';
import { pipe, map, path } from 'lodash/fp';
import { useMutation, useQuery } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { getAge } from '~lib/dates';
import { PERSON_ROLE } from '~OMS/my-details/contants';
import CallUsNumber from '~common/molecules/CallUsNumber';

const isOrderSucceeded = path('data.addMembershipCard.success');

const errorMessage = (
  <>
    Order card has failed. Please reach our support at{' '}
    <CallUsNumber inline bold /> to get more info.
  </>
);

export const useOrderCard = ({ location }) => {
  const updateAddress = useUpdateAddress();
  const [isOrderCardModalShown, toggleOrderCardModal] = useState(false);
  const [selectedPersons, setSelectedPersons] = useState([]);
  const [showConfirmationPage, toggleConfirmationPage] = useState(false);
  const { data: orderCardDetails } = useQuery(OrderCardDetails);
  const [alert, setAlert] = useState();

  const [orderCardMutation, { loading: orderingCard }] =
    useMutation(OrderCardMutation);

  const closeModal = () => {
    toggleOrderCardModal(false);
    setAlert();
    setTimeout(() => {
      setSelectedPersons([]);
      toggleConfirmationPage(false);
      updateAddress.clearAll();
    }, 0);
  };

  const openModal = () => {
    toggleOrderCardModal(true);
  };

  const orderCardForPerson = person => {
    return orderCardMutation({
      variables: {
        input: {
          personId: person.id,
        },
      },
      update: (store, { data: { addMembershipCard } }) => {
        if (addMembershipCard.success && addMembershipCard.activity) {
          const data = cloneDeep(store.readQuery({ query: DrawerQuery }));
          data.oms.activities.unshift(addMembershipCard.activity);
          store.writeQuery({ query: DrawerQuery, data });
        }
      },
    });
  };

  const orderCard = () => {
    return waterfall(
      selectedPersons.map(
        person => () =>
          orderCardForPerson(person).then(result => ({
            ...result,
            person,
          }))
      )
    )
      .then(results => {
        const allSucceeded = results.every(isOrderSucceeded);
        if (allSucceeded) {
          toggleConfirmationPage(true);
        } else {
          console.error('Ordering new card failed for one or more users');
          setAlert({
            type: 'error',
            message: errorMessage,
          });
        }
      })
      .catch(error => {
        console.error('Ordering failed for one or more users', error);
        setAlert({
          type: 'error',
          message: errorMessage,
        });
      });
  };

  const personButtons = useMemo(() => {
    return pipe(
      pathOr([], 'oms.membership.persons'),
      map(person => ({
        id: person.id,
        label: person.fullName,
        disabled:
          person.role === PERSON_ROLE.DEPENDANT && getAge(person.dob) < 16,
        get tooltip() {
          if (this.disabled) {
            return 'Dependants under the age of 16 aren’t eligible for an individual membership card';
          }
          return '';
        },
      }))
    )(orderCardDetails);
  }, [orderCardDetails]);

  const selectPerson = person => {
    const newState = selectedPersons.find(selected => person.id === selected.id)
      ? selectedPersons.filter(selected => selected.id !== person.id)
      : [...selectedPersons, person];

    setSelectedPersons(newState);
  };

  const selectedPersonsIds = useMemo(
    () => selectedPersons.map(path('id')),
    [selectedPersons]
  );

  return {
    loading: orderingCard,
    address: updateAddress,
    openModal,
    closeModal,
    isOrderCardModalShown,
    selectedPersons,
    selectPerson,
    showConfirmationPage,
    orderCard,
    personButtons,
    selectedPersonsIds,
    toggleOrderCardModal,
    alert,
  };
};
