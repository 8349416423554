import React from 'react';
import { Box } from 'rebass';
import styled from '@emotion/styled';
import SimpleSuccessHeader from '~common/atoms/SimpleSuccessHeader';
import SSOText from '~OMS/SSO/atoms/SSOText';

const SuccessContent = ({ details }) => {
  const BoldEmail = styled.span`
    font-weight: bold;
    display: inline;
  `;

  return (
    <>
      <SimpleSuccessHeader
        withIcon
        title={
          <>
            Your new login is
            <br />
            almost ready
          </>
        }
      />

      <Box mt={3} mb={4}>
        <SSOText>
          We will send an email to
          {details && <BoldEmail> {details.email}</BoldEmail>} to complete your
          account set up, this may take a few minutes.
        </SSOText>
        <SSOText>
          Please click on the link in this email to finalise the set-up of your
          new account.
        </SSOText>
      </Box>
    </>
  );
};

export default SuccessContent;
