import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Loading from '~common/atoms/Loading';

const StyledArticle = styled.article`
  border: 2px solid ${props => props.theme.colors.neutral100};
  background-color: ${props => props.theme.colors.white};
  position: relative;
  height: 127px;

  ${props => props.theme.mq.sm} {
    height: 300px;
  }
`;

const StyledType = styled.p`
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 18px;
  color: ${props => props.theme.colors.neutral500};
  padding-bottom: 8px;
  display: none;

  ${props => props.theme.mq.sm} {
    display: inherit;
    padding-bottom: 0;
    position: absolute;
    top: -26px;
    left: 0;
  }
`;

const LoadingCard = ({ type }) => {
  return (
    <StyledArticle>
      {type ? <StyledType>{type}</StyledType> : null}
      <Loading size="large" />
    </StyledArticle>
  );
};

LoadingCard.propTypes = {
  type: PropTypes.string,
};

export default LoadingCard;
