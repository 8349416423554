import React, { useState } from 'react';
import { KleberAutoCompleteControl } from '~common/molecules/form-controls';
import { StandardText } from '~common/atoms/typography';
import { Flex, Box } from 'rebass';
import { useMobileScreenOnly } from '~lib/responsive';
import Icon from '~common/atoms/Icon';
import { useThemeUI } from 'theme-ui';
import ManualAddressControl from '~common/molecules/form-controls/ManualAddressControl';
import Popover from '../../../../Elements/Popover';
import FormField from '~common/molecules/FormField';

const AutoCompleteControl = ({ enterManuallyElem, onManual, ...props }) => {
  const isMobile = useMobileScreenOnly();

  return (
    <Box>
      <Flex width={1} justifyContent="space-between">
        <StandardText></StandardText>
        {!isMobile && enterManuallyElem}
        {isMobile && (
          <ModeSwitcher onClick={onManual} tooltip="Enter manually" />
        )}
      </Flex>
      <KleberAutoCompleteControl {...props} />
    </Box>
  );
};

const ManualControl = ({ onAuto, ...props }) => {
  const isMobile = useMobileScreenOnly();

  return (
    <Box>
      <Flex width={1} justifyContent="flex-end">
        {!isMobile && (
          <StandardText
            clickable
            onClick={onAuto}
            textDecoration="underline"
            color="blue2"
            fontSize={2}
          >
            Search for address
          </StandardText>
        )}
        {isMobile && (
          <ModeSwitcher onClick={onAuto} tooltip="Search for address" />
        )}
      </Flex>
      <ManualAddressControl {...props} />
    </Box>
  );
};

const ModeSwitcher = ({ tooltip, onClick }) => {
  const { theme } = useThemeUI();

  return (
    <Popover position="top" content={tooltip}>
      <Icon
        name="refine"
        width="18px"
        height="18px"
        fill={theme.colors.primary}
        clickable
        onClick={onClick}
      />
    </Popover>
  );
};

const AddressField = ({
  onChange,
  onRepairing,
  name,
  label,
  labelPosition,
}) => {
  const [mode, setMode] = useState('auto');
  const enterManuallyElem = (
    <StandardText
      clickable
      onClick={() => {
        setMode('manual');
      }}
      textDecoration="underline"
      color="blue2"
      fontSize={2}
    >
      Enter it manually
    </StandardText>
  );

  return mode === 'auto' ? (
    <>
      <FormField
        name={name}
        label={label}
        labelPosition={labelPosition}
        onChange={onChange}
        enterManuallyElem={enterManuallyElem}
        onManual={() => {
          setMode('manual');
        }}
        onRepairing={onRepairing}
        component={AutoCompleteControl}
      />
    </>
  ) : (
    <FormField
      name={name}
      component={ManualControl}
      label={label}
      labelPosition={labelPosition}
      onChange={onChange}
      onAuto={() => {
        setMode('auto');
      }}
    />
  );
};

export default AddressField;
