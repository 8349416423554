import React from 'react';
import PropTypes from 'prop-types';
import { useThemeUI } from 'theme-ui';
import styled from '@emotion/styled';
import Icon from '~common/atoms/Icon';

//TODO: refactor this so common/atoms/Button is reused with variant="primaryInverted"

const StyledLink = styled.a`
  ${props => props.theme.buttons.primaryInverted};
  min-width: 0;

  ${props => props.theme.mq.tinyPhoneInverted} {
    padding: 8px 10px;
  }

  &:hover {
    svg path {
      fill: ${props => props.theme.colors.white};
    }
  }
`;

const StyledIcon = styled(Icon)`
  pointer-events: none;
  margin-right: 10px;

  ${props => props.theme.mq.tinyPhoneInverted} {
    margin-right: 0;
  }
`;

const StyledText = styled.span`
  ${props => props.theme.mq.tinyPhoneInverted} {
    display: none;
  }
`;

const Button = ({ buttonText, iconName, iconWidth, iconHeight }) => {
  const { theme } = useThemeUI();

  return (
    <StyledLink
      aria-label={buttonText}
      href="https://doctorshealthfund.as.me/callbackrequest"
      target="_blank"
      title="Opens in a new window"
      rel="noreferrer noopener"
    >
      <StyledIcon
        aria-hidden
        fill={theme.colors.primary}
        height={iconHeight}
        name={iconName}
        width={iconWidth}
      />
      <StyledText>{buttonText}</StyledText>
    </StyledLink>
  );
};

Button.defaultProps = {
  buttonText: 'Schedule a call',
  iconHeight: 'auto',
  iconName: 'phone',
  iconWidth: '12px',
};

Button.propTypes = {
  buttonText: PropTypes.string.isRequired,
  iconHeight: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  iconWidth: PropTypes.string.isRequired,
};

export default Button;
