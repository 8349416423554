import React from 'react';
import { useThemeUI } from 'theme-ui';
import Icon from '~common/atoms/Icon';
import { StandardText } from '~common/atoms/typography';
import { Button } from '~common/atoms/Button';
import WarningModal from '../common/components/modals/WarningModal';

const ChangeOfAddressWarningModal = ({ onConfirm }) => {
  const { theme } = useThemeUI();
  return (
    <WarningModal>
      {() => (
        <>
          <Icon
            fill={theme.colors.error}
            name="stop-info"
            height="65px"
            width="65px"
          />
          <StandardText fontSize={31} lineHeight={1.2} my={32}>
            Change of address
          </StandardText>

          <StandardText
            textAlign="center"
            lineHeight={1.2}
            fontSize={2}
            mb={32}
          >
            The change of State means we need to re-capture your full address.
          </StandardText>
          <StandardText
            textAlign="center"
            lineHeight={1.2}
            fontSize={2}
            mb={32}
          >
            You will be returned to the ‘Contact Details’ step.
          </StandardText>

          <Button width={427} variant="primary" onClick={onConfirm}>
            OK
          </Button>
        </>
      )}
    </WarningModal>
  );
};

export default ChangeOfAddressWarningModal;
