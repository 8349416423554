import React from 'react';
import AFBlock from '../AFBlock';
import { P3 } from '~common/atoms/typography';
import { Flex, Box } from 'rebass';
import { css } from '@emotion/core';
import { useThemeUI } from 'theme-ui';
import { FirstColumn } from '../common/layout';
import { useMobileScreenOnly } from '~lib/responsive';
import EditDetails from './EditDetails';
import { useApplicationFormContext } from '../state/ApplicationFormProvider';
import { path } from 'lodash/fp';
import { Summary } from '~lib/CoverProducts';
import CenterBox from '~common/atoms/CenterBox';
import Loading from '~common/atoms/Loading';
import useInclusionsModal from '../state/useInclusionsModal';
import Formula from './Formula';
import { useLocale } from '~common/locales';
import styled from '@emotion/styled';

const renderContent = ({
  isMobile,
  criteria,
  hospital,
  extras,
  medicareRebateInactive,
  theme,
  total,
  hospitalTotal,
  extrasTotal,
  onEditDetails,
  openInclusionsModal,
  quoteData,
  getLocaleString,
}) => {
  return (
    <Flex
      bg={theme.colors.primary}
      className="quote-summary-content"
      flexDirection="column"
      alignItems="flex-end"
    >
      <Flex
        alignItems="flex-start"
        ml={{
          xs: 'auto',
        }}
        flexDirection={{
          xs: 'column',
          tabletP: 'row',
        }}
        css={css`
          max-width: 100%;
        `}
      >
        {!isMobile ? (
          <FirstColumn pr={11}>
            <Summary
              prefix="Based on a"
              as={({ children, transform, highlight }) => (
                <P3
                  variant={highlight ? 'semiBold' : 'medium'}
                  color="white"
                  css={css`
                    text-transform: ${transform};
                    display: inline;
                  `}
                >
                  {children}
                </P3>
              )}
              status={criteria.status?.label}
              age={criteria.yourDob?.age}
              region={criteria.region?.label}
              tier={path('tier.value')(criteria)}
            />
            {!isMobile && !medicareRebateInactive && (
              <EditDetails
                onClick={onEditDetails}
                clickable
                fontSize={2}
                mt={15.5}
              >
                Edit details
              </EditDetails>
            )}
          </FirstColumn>
        ) : (
          <Flex
            mb={25}
            css={css`
              max-width: 100%;
            `}
            flexDirection={{
              xs: 'column',
            }}
          >
            <Flex
              css={css`
                display: grid;
                grid-template-columns: auto auto;
                grid-gap: 10px;
              `}
            >
              <Summary
                prefix="Based on a"
                as={({ children, transform, highlight }) => (
                  <P3
                    variant={highlight ? 'semiBold' : 'medium'}
                    color="white"
                    css={css`
                      text-transform: ${transform};
                      display: inline;
                    `}
                  >
                    {children}
                  </P3>
                )}
                css={css`
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  color: #fff;
                `}
                status={criteria.status.label}
                age={criteria.yourDob.age}
                region={criteria.region.label}
                tier={path('tier.value')(criteria)}
              />
              {!medicareRebateInactive && (
                <EditDetails onClick={onEditDetails} fontSize={2}>
                  Edit details
                </EditDetails>
              )}
            </Flex>
          </Flex>
        )}
        <Box width={1}>
          <Formula
            width={1}
            pl={{
              xs: 0,
              tabletP: 30,
            }}
            tier={criteria?.tier?.value}
            efc={path('efc.value')(criteria) === true}
            isMobile={isMobile}
            theme={theme}
            hospital={
              criteria.hospitalProduct &&
              hospital && {
                name: path('product.title')(hospital),
                coverage: hospital?.product?.coverage,
                price: hospitalTotal,
                excess: path('variant.excess')(hospital),
              }
            }
            extras={
              criteria.extrasProduct &&
              extras && {
                name: path('product.title')(extras),
                price: extrasTotal,
                coverage: extras?.product?.coverage,
              }
            }
            total={total}
            medicareRebateInactive={medicareRebateInactive}
            openInclusionsModal={openInclusionsModal}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

const QuoteSummaryContainer = styled(AFBlock)`
  position: sticky;
  top: 0;
  z-index: 99;
`;

const QuoteSummary = React.memo(
  ({
    criteria,
    total,
    hospitalTotal,
    extrasTotal,
    quoteData,
    hospital,
    medicareRebateInactive,
    onEditDetails,
    extras,
    loading,
    ...props
  }) => {
    const { theme } = useThemeUI();
    const isMobile = useMobileScreenOnly();
    const { getLocaleString } = useLocale();

    const { openInclusionsModal } = useInclusionsModal({
      criteria,
      quoteData,
      hospital,
      extras,
      medicareRebateInactive,
    });

    return (
      <QuoteSummaryContainer
        style={{
          width: '100%',
        }}
        bg={theme.colors.primary}
        pt={16}
        pb={16}
        {...props}
      >
        {loading || !criteria ? (
          <CenterBox>
            <Loading color={theme.colors.white} />
          </CenterBox>
        ) : (
          renderContent({
            criteria,
            total,
            hospitalTotal,
            extrasTotal,
            hospital,
            extras,
            medicareRebateInactive,
            isMobile,
            theme,
            onEditDetails,
            openInclusionsModal,
            quoteData,
            getLocaleString,
          })
        )}
      </QuoteSummaryContainer>
    );
  },
  (prevProps, nextProps) => {
    return [
      'hospital',
      'extras',
      'criteria',
      'total',
      'hospitalTotal',
      'extrasTotal',
      'loading',
      'quoteData',
      'medicareRebateInactive',
      'onEditDetails',
      'openInclusionsModal',
    ].every(propName => prevProps[propName] === nextProps[propName]);
  }
);

const QuoteSummaryWrapper = props => {
  const { state, actions } = useApplicationFormContext();
  const hospital = path('productSelections.hospital')(state);
  const extras = path('productSelections.extras')(state);

  return (
    <QuoteSummary
      loading={state.quoteLoading}
      hospital={hospital}
      extras={extras}
      criteria={state.criteria}
      quoteData={state.quoteData}
      total={state.total}
      hospitalTotal={state.hospitalTotal}
      extrasTotal={state.extrasTotal}
      medicareRebateInactive={state.medicareRebateInactive}
      onEditDetails={actions.openEditCoverModal}
      {...props}
    />
  );
};

export default QuoteSummaryWrapper;
