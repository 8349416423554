import { path } from 'lodash/fp';

import {
  CLAIM_STATUS,
  SUBMITTED_CLAIM_RENDERED_ID,
} from '~OMS/claims/constants';

export const columnValue = (item, column) => {
  const value = path(column.name)(item);
  return column.format ? column.format(value) : value;
};

export const formatClaimId = claim =>
  claim?.status === CLAIM_STATUS.SUBMITTED && !claim?.id?.startsWith?.('E')
    ? SUBMITTED_CLAIM_RENDERED_ID
    : claim.id;
