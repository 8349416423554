import React from 'react';
import styled from '@emotion/styled';
import { Flex, Box, Heading, Text, Button } from 'rebass';
import { Formik } from 'formik';
import { useThemeUI } from 'theme-ui';

import Icon from '~common/atoms/Icon';
import spinner from 'images/spinner.gif';
import WorkingModal from '~common/molecules/WorkingModal';
import { css } from '@emotion/core';
import { useLocale } from '~common/locales';

const phoneExpression =
  /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$/;

const QuoteEmail = props => {
  const {
    isOpen,
    onClose,
    onSubmit,
    formVisible,
    mutationError,
    setMutationError,
  } = props;

  const { theme } = useThemeUI();

  return (
    <WorkingModal
      visible={isOpen}
      height="auto"
      title={
        <Heading
          css={css`
            transform: translateY(-20px);
          `}
          fontSize={3}
          fontWeight="bold"
          lineHeight="2.19"
          color="text.dark"
          mb={{ xs: 1, lg: 4 }}
        >
          Email me the quote
        </Heading>
      }
      onClose={onClose}
      width={962}
    >
      <Box bg="white" style={{ position: 'relative' }}>
        {formVisible && !mutationError ? (
          <>
            <QuoteEmailForm
              theme={theme}
              onSubmit={onSubmit}
              setMutationError={setMutationError}
            />
          </>
        ) : (
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            py={{ xs: '20px', lg: '50px' }}
          >
            <Icon
              name={mutationError ? 'remove' : 'circle-fill-tick'}
              width="75px"
              height="75px"
              fill={
                mutationError ? theme.colors.error : theme.colors.alert.success
              }
              style={{ marginTop: '70px', marginBottom: '20px' }}
            />
            <Text color="text.dark" lineHeight="2.19" fontSize={3}>
              {mutationError
                ? 'There was a problem sending sending an email with the quote. Please try again later.'
                : 'An email with the quote has been sent to the email address provided.'}
            </Text>
            <Button
              variant="orange"
              className="button-large"
              onClick={onClose}
              mt="90px"
            >
              OK
            </Button>
          </Flex>
        )}
      </Box>
    </WorkingModal>
  );
};

const Input = styled.input`
  width: 100%;
  border: 2px solid ${props => props.theme.colors.borders};
  line-height: 24px;
  background-color: ${props => props.theme.colors.background.lightest};
  font-size: 24px;
  padding: 8px 13px;
  margin-bottom: 8px;

  &.error {
    border-color: ${props => props.theme.colors.error};
  }
`;

const Checkbox = styled.input`
  margin: 0 10px;
  cursor: pointer;
`;

const CheckLabel = styled.label`
  font-size: ${props => props.theme.fontSizes[1]}px;
  line-height: 1.2;
  color: ${props => props.theme.colors.light};
  flex: 1;
  cursor: pointer;
`;

const Label = styled.label`
  font-size: ${props => props.theme.fontSizes[1]}px;
  line-height: 1.2;
  margin-bottom: 8px;
  font-weight: 400;
  color: ${props => props.theme.colors.light};
`;

const Feedback = styled.div`
  color: ${props => props.theme.colors.error};
  font-size: 14px;
`;

const Form = styled.form``;

const QuoteEmailForm = ({ theme, ...props }) => {
  const { getLocaleString } = useLocale();

  const validate = values => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = 'Please enter your first name.';
    }

    if (!values.lastName) {
      errors.lastName = 'Please enter your last name.';
    }

    if (!values.phone) {
      errors.phone = 'Please enter your phone number.';
    } else if (!values.phone.match(phoneExpression)) {
      errors.phone = 'Please enter a valid australian phone number.';
    }

    if (!values.email) {
      errors.email = 'Please enter your email address';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(values.email)
    ) {
      errors.email = 'Invalid Email Address';
    }

    if (!values.agree) {
      errors.agree = 'You must agree to the terms and conditions';
    }

    return errors;
  };
  return (
    <Formik
      initialValues={{}}
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        props
          .onSubmit(values)
          .then(res => {
            setSubmitting(false);
            props.setMutationError(false);
          })
          .catch(err => {
            console.error(err);
            setSubmitting(false);
            props.setMutationError(true);
          });
      }}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          isValid,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return isSubmitting ? (
          <Flex
            height="200px"
            flex={1}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Heading
              fontSize={3}
              fontWeight="bold"
              color="text.dark"
              mt={5}
              mb={4}
              textAlign="center"
            >
              Sending Quote
            </Heading>
            <img src={spinner} alt="Loading" width="24" height="24" />
          </Flex>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Flex
              flexDirection={{ xs: 'column', lg: 'row' }}
              alignItems="flex-start"
              justifyContent="space-between"
              pb={{ xs: 0, lg: '20px' }}
            >
              <Box width={{ xs: '100%', lg: '48%' }}>
                <Label htmlFor="firstName" style={{ display: 'block' }}>
                  First Name
                  <sup style={{ color: theme.colors.error }}>*</sup>
                </Label>
                <Input
                  id="firstName"
                  placeholder=""
                  type="text"
                  defaultValue={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={true}
                  className={
                    errors.firstName && touched.firstName
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                <Feedback>
                  {errors.firstName && touched.firstName
                    ? errors.firstName
                    : null}
                </Feedback>
              </Box>
              <Box width={{ xs: '100%', lg: '48%' }}>
                <Label htmlFor="lastName" style={{ display: 'block' }}>
                  Last Name<sup style={{ color: theme.colors.error }}>*</sup>
                </Label>
                <Input
                  id="lastName"
                  placeholder=""
                  type="text"
                  defaultValue={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.lastName && touched.lastName
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                <Feedback>
                  {errors.lastName && touched.lastName ? errors.lastName : null}
                </Feedback>
              </Box>
            </Flex>
            <Flex
              flexDirection={{ xs: 'column', lg: 'row' }}
              alignItems="flex-start"
              justifyContent="space-between"
              pb={{ xs: 0, lg: '30px' }}
            >
              <Box width={{ xs: '100%', lg: '48%' }}>
                <Label htmlFor="email" style={{ display: 'block' }}>
                  Email<sup style={{ color: theme.colors.error }}>*</sup>
                </Label>
                <Input
                  id="email"
                  placeholder=""
                  type="text"
                  defaultValue={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.email && touched.email
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                <Feedback>
                  {errors.email && touched.email ? errors.email : null}
                </Feedback>
              </Box>
              <Box width={{ xs: '100%', lg: '48%' }}>
                <Label htmlFor="phone" style={{ display: 'block' }}>
                  Phone<sup style={{ color: theme.colors.error }}>*</sup>
                </Label>
                <Input
                  id="phone"
                  placeholder=""
                  type="text"
                  defaultValue={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.phone && touched.phone
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                <Feedback>
                  {errors.phone && touched.phone ? errors.phone : null}
                </Feedback>
              </Box>
            </Flex>
            <Flex
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Checkbox
                id="agree"
                type="checkbox"
                defaultValue={values.agree}
                onChange={handleChange}
                onBlur={props.handleBlur}
                className={
                  errors.agree && touched.agree
                    ? 'text-input error'
                    : 'text-input'
                }
              />
              <CheckLabel htmlFor="agree">
                I understand that by providing my details,{' '}
                {getLocaleString('common.organisationName')} will contact me by
                phone, email or SMS with information about their health
                insurance products.{' '}
                <sup style={{ color: theme.colors.error }}>*</sup>
              </CheckLabel>
            </Flex>
            <Flex
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                data-id="send-quote-email"
                variant="orange"
                type="submit"
                className="button-large"
                mt={4}
                mb={4}
                disabled={!isValid}
              >
                Send Quote
              </Button>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
};
export default QuoteEmail;
