import React from 'react';
import { CLAIM_STATUS } from '~OMS/claims/constants';
import Icon from '../../../../common/atoms/Icon';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';
import { StandardText } from '../../../../common/atoms/typography';
import { withTheme } from 'emotion-theming';

const StyledDot = styled(Box)`
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background: ${props => props.theme.colors.primary};
`;

const DotContainer = styled(Flex)`
  position: relative;
  top: 2px;
`;

const pendingStatus = {
  label: 'Pending',
  color: 'orange',
  CustomIcon: ({ theme, ...props }) => (
    <DotContainer {...props}>
      <StyledDot mr="2px" theme={theme} />
      <StyledDot mr="2px" theme={theme} />
      <StyledDot theme={theme} />
    </DotContainer>
  ),
};

const statuses = {
  [CLAIM_STATUS.PROCESSED]: {
    label: 'Processed',
    color: 'green',

    icon: {
      name: 'success-circle-tick',
    },
  },
  [CLAIM_STATUS.PENDING]: pendingStatus,
  [CLAIM_STATUS.SUBMITTED]: { ...pendingStatus, label: 'Submitted' },
};

const ClaimStatus = ({ claim, theme, ...props }) => {
  if (!claim) {
    return null;
  }
  const { status } = claim;
  const statusData = statuses[status];
  const { CustomIcon, icon, label, color: statusColor } = statusData;
  const color = theme.colors[statusColor] || statusColor;
  return (
    <Flex alignItems="center" justifyContent={'space-between'} {...props}>
      <StandardText fontSize={1}>Status: &nbsp;</StandardText>
      <StandardText bold color={color} fontSize={1}>
        {label}
      </StandardText>
      {icon ? (
        <Icon ml={2} name={icon.name} fill={color} height="17px" width="17px" />
      ) : (
        <CustomIcon ml="2px" theme={theme} />
      )}
    </Flex>
  );
};

export default withTheme(ClaimStatus);
