import React from 'react';
import { Summary } from '~lib/CoverProducts/index';
import Col from '~common/molecules/Col';
import { Row } from '~common/molecules/Row';
import { H3 } from '../../../../common/atoms/typography';
import { DropDown, Option } from '~common/atoms/DropDown';
import { withTheme } from 'emotion-theming';
import { Box } from 'rebass';
import styled from '@emotion/styled';
import { noop } from '~lib/util';

const coverTypes = [
  {
    label: 'hospital + extras',
    value: 'both',
  },
  {
    label: 'hospital only',
    value: 'hospital',
  },
  process.env.BRAND_NAME !== 'RAMSAY' && {
    label: 'extras only',
    value: 'extras',
  },
].filter(Boolean);

const StyledCompareCoverContainer = styled(Box)`
  text-align: center;
  ${props => props.theme.mq.md} {
    text-align: left;
  }

  .content {
    flex-direction: column;
    ${props => props.theme.mq.md} {
      flex-direction: row;
    }
  }
`;

const CompareCoverSection = ({
  cover,
  selectedType = 'both',
  onChange = noop,
  theme,
}) => {
  return (
    <StyledCompareCoverContainer theme={theme}>
      <H3 variant="semiBold" className="title" pb={20}>
        Compare your cover{' '}
      </H3>
      <Row
        className="content"
        alignItems="center"
        justifyContent="space-between"
      >
        <Col
          mb={{
            xs: 3,
            md: 0,
          }}
          width={2 / 3}
        >
          <Summary
            status={cover.status.label}
            age={cover.dob.age}
            region={cover.region.label}
            tier={cover.tier.value}
          />
        </Col>
        <Col
          width={{
            xs: 1,
            md: '207px',
          }}
        >
          <DropDown round={false} value={selectedType} onChange={onChange}>
            {coverTypes.map(type => (
              <Option key={type.value} value={type.value}>
                {type.label}
              </Option>
            ))}
          </DropDown>
        </Col>
      </Row>
    </StyledCompareCoverContainer>
  );
};

export default withTheme(CompareCoverSection);
