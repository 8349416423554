import React, { useContext, useCallback } from 'react';
import { withTheme } from 'emotion-theming';
import { Box, Flex } from 'rebass';
import FormTitle from '~OMS/styles/FormTitle';
import FormText from '~OMS/styles/FormText';
import SSOFormContainer from '~OMS/styles/SSOFormContainer';
import { Formik, Field } from 'formik';
import {
  PasswordInputControl,
  validators,
} from '~common/molecules/form-controls/index';
import { AuthContext } from 'context/sso';
import AlertBox from '~common/molecules/AlertBox';
import { Button } from '~common/atoms/Button';

const validation = {
  oldPassword: validators.password().required('Old password is required'),
  newPassword: validators.password().required('New password is required'),
  newPasswordConfirm: validators
    .password()
    .required('Password confirm is required'),
};

const validateForm = ({ newPassword, newPasswordConfirm }) => {
  const errors = {};

  try {
    validation.newPassword.validateSync(newPassword);
  } catch (err) {
    errors.newPassword = err.errors;
  }

  try {
    validation.newPasswordConfirm.validateSync(newPasswordConfirm);
  } catch (err) {
    errors.newPasswordConfirm = err.errors;
  }

  if (newPassword !== newPasswordConfirm) {
    errors.newPasswordConfirm = 'Confirm password must match the new password';
  }

  return errors;
};

const initialForm = {
  code: '',
  newPassword: '',
  confirmNewPassword: '',
};

function SetPasswordWrapper({ queryParams }) {
  const { resetPassword, error, loading } = useContext(AuthContext);

  const handleResetPassword = useCallback(
    async form => {
      await resetPassword({
        ...form,
        code: queryParams.code,
        username: queryParams.user,
      });
    },
    [resetPassword, queryParams.code, queryParams.user]
  );

  return (
    <SSOFormContainer>
      {error && (
        <AlertBox
          error={
            error.code === 'ExpiredCodeException'
              ? 'This reset password link has expired'
              : error.message
          }
        />
      )}
      <FormTitle>Set password</FormTitle>
      <Box width={1} mt="20px" mb="30px">
        <FormText>Please enter the password you wish to use to login.</FormText>
      </Box>
      <Formik
        initialValues={initialForm}
        validate={validateForm}
        onSubmit={handleResetPassword}
      >
        {({ handleSubmit, resetForm }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field
                name="newPassword"
                label="Create Password"
                component={PasswordInputControl}
                validateStrength
              />
              <Field
                name="newPasswordConfirm"
                label="Re-enter password"
                component={PasswordInputControl}
              />
              <Flex justifyContent="space-between" width={1} mt={1}>
                <Button
                  type="button"
                  onClick={() => resetForm()}
                  variant="primaryInverted"
                  mr={1}
                >
                  Cancel
                </Button>
                <Button
                  loading={loading}
                  type="submit"
                  variant="primary"
                  css={{ width: '100%' }}
                >
                  Set password
                </Button>
              </Flex>
            </form>
          );
        }}
      </Formik>
    </SSOFormContainer>
  );
}

export default withTheme(SetPasswordWrapper);
