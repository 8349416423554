import React from 'react';
import { Flex } from 'rebass';
import { P3 } from '~common/atoms/typography';
import styled from '@emotion/styled';
import Icon from '../../common/atoms/Icon';
import { useThemeUI } from 'theme-ui';
import Link from '../../common/atoms/Link';

const ProductSwitchContainer = styled(Flex)`
  position: relative;
`;

const NavContainer = styled(Flex)`
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  ${props =>
    props.type === 'prev'
      ? `
    left: 0;
  `
      : `
    right: 0
  `}
`;

const NavIcon = ({ name }) => {
  const { theme } = useThemeUI();
  return (
    <Icon
      clickable
      name={name}
      fill={theme.colors.primary}
      height="15px"
      width="15px"
    />
  );
};

const ProductSwitch = ({ next, prev, ...props }) => {
  return (
    <ProductSwitchContainer justifyContent="space-between" {...props}>
      {prev && prev.content.slug && (
        <NavContainer
          type="prev"
          as={Link}
          to={`/${prev.content.slug.current}`}
        >
          <NavIcon name="arrow-left" />
          <P3 ml={2} color="primary" variant="semiBold">
            Less cover
          </P3>
        </NavContainer>
      )}
      {next && next.content.slug && (
        <NavContainer
          type="next"
          as={Link}
          to={`/${next.content.slug.current}`}
        >
          <P3 variant="semiBold" mr={2} color="primary">
            More cover
          </P3>
          <NavIcon name="arrow-right" />
        </NavContainer>
      )}
    </ProductSwitchContainer>
  );
};

export default ProductSwitch;
