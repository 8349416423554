import React from 'react';
import Layout from 'components/Layout';
import LoginWrapper from '~OMS/SSO/templates/LoginWrapper';
import useBrowser from '~common/hooks/useBrowser';
import CmsPage from '../../../components/Elements/CmsPage';

const Login = props => {
  const { loginMessage } = useBrowser();
  return (
    <CmsPage pageContext={props.pageContext}>
      <Layout>
        {loginMessage()}
        <LoginWrapper location={props.location} />
      </Layout>
    </CmsPage>
  );
};

export default Login;
