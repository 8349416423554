import React from 'react';
import { Flex, Heading, Text } from 'rebass';
import { css } from '@emotion/core';
import { useThemeUI } from 'theme-ui';
import { navigate } from 'gatsby';
import Icon from '~common/atoms/Icon';
import Link from '~common/atoms/Link';
import QuoteBlock from './QuoteBlock';

const navbarStyle = theme => css`
  height: 64px;
  box-shadow: 0px 2px 5px rgba(74, 74, 74, 0.25);
  background-color: ${theme.colors.white};
  z-index: 99;
  position: fixed;
  width: 100%;
  ${theme.mq.tabletP} {
    height: 80px;
  }
`;

const Navbar = props => {
  const { theme } = useThemeUI();
  return (
    <QuoteBlock
      justifyContent="space-between"
      alignItems="center"
      snug={true}
      css={navbarStyle(theme)}
      {...props}
    >
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => {
          navigate('/');
        }}
        css={css`
          cursor: pointer;
        `}
      >
        <Icon
          data-id="go-to-home-page"
          name="arrow-left"
          width={{
            xs: '20px',
            tabletP: '30px',
          }}
          height={{
            xs: '20px',
            tabletP: '30px',
          }}
          fill={theme.colors.primary}
          clickable
        />

        <Heading
          fontSize="20px"
          ml={3}
          lineHeight="1.2"
          alignSelf="center"
          color="text.dark"
        >
          Home
        </Heading>
      </Flex>

      <Link external to="/oms/sso/login?next=/oms/my-cover">
        <Text
          fontSize={3}
          data-id="go-to-login-page"
          color="primary"
          style={{ textDecoration: 'underline' }}
        >
          Existing Members
        </Text>
      </Link>
    </QuoteBlock>
  );
};

export default Navbar;
