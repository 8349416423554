import React from 'react';
import ActionWithIcon from '~common/atoms/ActionWithIcon';
import Link from '~common/atoms/Link';

const EmailUsWithIcon = ({
  isAnomUser = false,
  iconProps,
  iconBoxProps,
  ...props
}) => {
  return (
    <ActionWithIcon
      as={props => <Link {...props} to={`/contact-us`} />}
      text="    Email us"
      iconProps={{
        name: 'oms-letter-outline',
        ...iconProps,
      }}
      {...props}
    />
  );
};

export default EmailUsWithIcon;
