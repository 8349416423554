import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledCard = styled.div``;

const StyledType = styled.p`
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSizes[0]}px;
  line-height: 13px;
  color: ${props => props.theme.colors.white};
`;

const StyledTitle = styled.p`
  padding-top: 4px;
  font-size: ${props => props.theme.fontSizes[2]}px;
  line-height: 18px;
  font-weight: 500;
  color: ${props => props.theme.colors.white};
`;

const StyledPrice = styled.p`
  font-size: ${props => props.theme.fontSizes[3]}px;
  line-height: 21px;
  color: ${props => props.theme.colors.white};
`;

const EmptyCard = ({ title, type }) => {
  return (
    <StyledCard>
      <StyledType>{type}</StyledType>
      <StyledTitle>{title}</StyledTitle>
      <StyledPrice>-</StyledPrice>
    </StyledCard>
  );
};

EmptyCard.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default EmptyCard;
