import React from 'react';
import ModalConfirmation from '~common/molecules/ModalConfirmation';

const LeavePageModal = ({ onConfirm, onCancel, saving }) => {
  return (
    <ModalConfirmation
      confirmLoading={saving}
      cancelDisabled={saving}
      alignItems={{
        xs: 'center',
        tabletP: 'flex-start',
      }}
      title="Before you leave"
      message="Do you want to save your application for later?"
      confirmText="Yes, save form"
      cancelText="No, exit form"
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};

export default LeavePageModal;
