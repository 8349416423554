import formEncoder from '~lib/form-url-encoder';
import * as Sentry from '@sentry/browser';
import { parseQueryParams, readTextStream } from '~lib/util';
import { UnexpectedNabResponseError } from '~common/errors';

const postHtmlForm = (url, payload, options) => {
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    body: formEncoder(payload),
    ...options,
    headers: {
      'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
  });
};

const getQueryParamsFromUri = uri => {
  const [, queryString] = uri.split('?');
  return parseQueryParams(queryString);
};

export const submitNabPayment = payload => {
  return postHtmlForm(process.env.DHF_NAB_API_PAYMENT_URL, payload)
    .then(response => {
      if (!response.redirected) {
        if (!response.body) {
          const unexpectedNabResponseError = new UnexpectedNabResponseError(
            response
          );
          Sentry.captureException(unexpectedNabResponseError);
          return Promise.reject(unexpectedNabResponseError);
        }
        return readTextStream(response.body).then(bodyMessage => {
          return Promise.reject(
            `NAB redirect failed on: ${response.url}.
               Message: ${bodyMessage || 'Unknown error'}`
          );
        });
      }
      return response;
    })
    .then(response => getQueryParamsFromUri(response.url));
};
