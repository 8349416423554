import React from 'react';
import { withTheme } from 'emotion-theming';
import AttachReceipt from '~OMS/claims/organisms/AttachReceipt';
import SubmitClaimConfirmation from '~OMS/claims/molecules/SubmitClaimConfirmation';
import SubmitClaimAlerts from '~OMS/claims/atoms/SubmitClaimAlerts';
import useClaimUploader, { states } from '~OMS/claims/hooks/useClaimUploader';
import { ACCEPTED_FILE_TYPES } from '~OMS/claims/config';
import { StandardText } from '../../../../common/atoms/typography';
import { Box } from 'rebass';
import SubmitClaimTermsAndCondition from '~OMS/claims/molecules/SubmitClaimTermsAndConditions';

const ClaimUploader = ({ theme, ...props }) => {
  const { state, actions } = useClaimUploader();
  const {
    confirmationState,
    alert,
    files,
    totalFilesSize,
    progress,
    loading,
    submitDisabled,
    termsAndConditionsVisible,
    termsAccepted,
  } = state;

  const {
    addFile,
    removeFile,
    startNewClaim,
    submitClaim,
    handleUserAgreement,
    showTermsAndConditions,
    hideTermsAndConditions,
    toggleTerms,
  } = actions;
  return (
    <>
      <SubmitClaimTermsAndCondition
        onClose={hideTermsAndConditions}
        visible={termsAndConditionsVisible}
        onAgree={handleUserAgreement}
      />
      <Box {...props}>
        <StandardText color="dark" fontSize={3} mb={4}>
          Make a claim (or several) by uploading clear photos or scans of any
          receipts.
        </StandardText>
        <AttachReceipt
          showLoader={confirmationState === states.UPLOAD_COMPLETE}
          accept={ACCEPTED_FILE_TYPES}
          showSuccess={confirmationState === states.SUCCESS}
          loading={loading}
          totalFileSize={totalFilesSize}
          alert={
            alert ? (
              <SubmitClaimAlerts
                theme={theme}
                type={alert.type}
                message={alert.message}
              />
            ) : null
          }
          onRemove={removeFile}
          files={files}
          onChange={addFile}
          theme={theme}
        />
        <SubmitClaimConfirmation
          termsAccepted={termsAccepted}
          onTermsToggle={toggleTerms}
          onTermsAndConditions={showTermsAndConditions}
          onStartNewClaim={startNewClaim}
          disableSubmit={submitDisabled}
          state={confirmationState}
          progress={progress}
          onSubmit={submitClaim}
        />
      </Box>
    </>
  );
};

export default withTheme(ClaimUploader);
