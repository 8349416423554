import { getAge, smartFormat, toDate } from '~lib/dates';
import { auStates, quoteStatuses, tiers } from '~lib/constants';
import { whereEq } from 'lodash/fp';
import { useEffect } from 'react';

const findOption = (options, value) =>
  options.find(
    whereEq({
      value,
    })
  );

const deserializeDob = dateString => {
  const date = toDate(dateString);
  return {
    age: getAge(date),
    value: dateString,
    label: smartFormat(dateString),
  };
};

export default ({ retrievedData, setCriteria, allSteps }) => {
  const deserializeSteps = ({ form, quoteInput }) => {
    for (const step of allSteps) {
      const result = step.context.deserialize
        ? step.context.deserialize(form[step.value], form, quoteInput)
        : form[step.value];
      if (result) {
        step.context.form.setForm(result || {}, { merge: false });
      }
    }
  };

  const deserializeCriteria = quoteInput => {
    const dob = deserializeDob(quoteInput.dob);
    const partnerDob =
      quoteInput.partner &&
      quoteInput.partner.dob &&
      deserializeDob(quoteInput.partner.dob);

    const deserializedCriteria = {
      region: findOption(auStates, quoteInput.state),
      status: findOption(quoteStatuses, quoteInput.status),
      tier: findOption(tiers[quoteInput.status], String(quoteInput.tier)),
      // age: dob.age,
      hospitalProduct: quoteInput.hospitalProduct,
      extrasProduct: quoteInput.extrasProduct,
      efc: quoteInput.efc && {
        value: quoteInput.efc,
      },
      // resultType: quoteInput.resultType,
      paymentFrequency: quoteInput.paymentFrequency,
      dob,
      yourDob: dob,
      partnerDob,
      entryAge: quoteInput.entryAge,
      lhcExempt: quoteInput.lhcExempt,
      partner: quoteInput.partner,
    };

    console.log('DESERIALIZED CRITERIA', deserializedCriteria);

    setCriteria(deserializedCriteria);
  };

  const deserialize = data => {
    deserializeCriteria(data.quoteInput);
    deserializeSteps(data);
  };

  useEffect(() => {
    if (retrievedData) {
      deserialize(retrievedData);
    }
  }, []);
};
