import React, { useState, useContext } from 'react';
import { Box, Button, Flex, Heading } from 'rebass';
import { css } from '@emotion/core';
import { withTheme } from 'emotion-theming';
import SSOText from '~OMS/SSO/atoms/SSOText';
import CheckBox from '~common/atoms/CheckBox';
import styled from '@emotion/styled';
import axios from 'axios';
import { AuthContext } from 'context/sso';
import { AVANT_JOIN_URL, AVANT_LOGIN_URL } from '../constants';
import { useLocale } from '~common/locales';
import Logo from 'images/avant-logo-sso.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-width: 100vw;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.4);

  @media screen and (max-width: 992px) {
    width: 100%;
    height: 100%;
  }
`;

const AvantLinkWrapper = props => {
  const { getLocaleString } = useLocale();
  const organisationName = getLocaleString('common.organisationName');
  const [termsAccepted, setTermsAccept] = useState(false);
  const toggleTermsAccept = () => {
    setTermsAccept(!termsAccepted);
  };
  const context = useContext(AuthContext);

  /**
   * This is copied from `Navbar.js`, the only difference is a query
   * string added to the redirect url to indicate that the user has agreed
   * to link accounts. Maybe it is important later to actually record the consent,
   * but we don't know about that yet.
   */
  const goToAvant = async () => {
    try {
      const token = await context.getToken();
      const responsePayload = (
        await axios.get('/sso/goToAvant', {
          headers: { authorization: `Bearer ${token}` },
        })
      ).data;
      window.location.href = `${responsePayload.redirectUrl}&agreed=true`;
    } catch (err) {
      // if anything goes wrong just redirect to avant's login page
      window.location.href = `${AVANT_LOGIN_URL}`;
    }
  };

  return (
    <Container>
      <Flex
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          width={{ xs: '100%', md: '480px' }}
          css={css`
            height: 500px;
            background-color: #fff;
            box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.5);

            @media screen and (max-width: 992px) {
              height: 260px;
            }
          `}
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            css={css`
              width: 100%;
              height: 100%;
            `}
          >
            <img
              src={Logo}
              alt="Avant by doctors for doctors"
              style={{ height: '100px', width: '100%', objectFit: 'contain' }}
            />
            <SSOText color="blue" mt="40px" mb="5px">
              <strong>Not an Avant Mutual member?</strong>
            </SSOText>
            <SSOText color="blue">
              Learn about{' '}
              <a
                href={`${AVANT_JOIN_URL}`}
                target="_blank"
                rel="noopener noreferrer"
                css={css`
                  &,
                  &:visited,
                  &:active {
                    color: ${props.theme.colors.secondary};
                    text-decoration: underline;
                  }
                `}
              >
                our Medical indemnity Insurance
              </a>
            </SSOText>
          </Flex>
        </Box>
        <Box
          width={{ xs: '100%', md: '480px' }}
          css={css`
            height: 740px;
            max-height: 100vh;
            background-color: #f6f6f6;
            box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.5);
            padding: 60px;
            overflow-y: scroll;

            @media screen and (max-width: 992px) {
              height: 100%;
              max-height: none;
              padding: 30px 10px;
              overflow-y: visible;
            }
          `}
        >
          <Heading mb={20}>Link your Avant account</Heading>
          <SSOText>
            You can now link your Avant and {organisationName} accounts and
            easily switch between the two.
          </SSOText>

          <Flex justifyContent={{ xs: 'flex-start', md: 'center' }} mt={20}>
            <CheckBox
              id="agree"
              name="agree"
              value={termsAccepted}
              onChange={toggleTermsAccept}
              color={props.theme.colors.secondary}
            />
            <label htmlFor="agree" style={{ cursor: 'pointer' }}>
              I authorise {organisationName} to link my Avant member login
            </label>
          </Flex>
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mt={16}
          >
            <Button
              variant="blue"
              css={{ width: '100%' }}
              disabled={!termsAccepted}
              onClick={goToAvant}
              mb={20}
            >
              Login to Avant
            </Button>
            <SSOText pb={2}>
              <a
                href="/oms/"
                css={css`
                  color: ${props.theme.colors.light};
                `}
              >
                Cancel
              </a>
            </SSOText>
          </Flex>
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <SSOText>
              To protect your private and confidential information, we recommend
              you do not share your username or password details, as once they
              are linked, the information in both accounts will be accessible*.
            </SSOText>
            <SSOText>
              You can unlink at any time via the 'My Details' section of your{' '}
              {organisationName} account.
            </SSOText>
          </Flex>

          <SSOText style={{ fontSize: '12px' }} mb={0}>
            <p>
              <strong>*Disclaimer</strong>
            </p>
            <p>
              By linking your {organisationName} and Avant Mutual member login
              areas, you accept and acknowledge that anyone with your login ID
              and password will be able to access your personal health
              information and/or your medical indemnity insurance information.
            </p>
            <p style={{ marginTop: '10px' }}>
              Avant Mutual Group Limited ABN 58 123 154 898, and each of its
              related entities including; Avant Insurance Limited ABN 82 003 707
              471 AFSL 238765, The {organisationName} Pty Limited ABN 68 001 417
              527 and Doctors Financial Services Pty Ltd ABN 56 610 510 328,
              will not be held liable for any resulting unauthorised, access,
              release or loss of your personal health information and/or your
              medical indemnity insurance information.
            </p>
          </SSOText>
        </Box>
      </Flex>
    </Container>
  );
};

export default withTheme(AvantLinkWrapper);
