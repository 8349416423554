import React from 'react';
import Layout from 'components/Layout';
import MembershipExistsWrapper from '~OMS/SSO/organisms/MembershipExistsWrapper';

const MembershipExistsPage = props => {
  return (
    <Layout>
      <MembershipExistsWrapper />
    </Layout>
  );
};

export default MembershipExistsPage;
