import { Box } from 'rebass';
import { DropDown, Option } from '~common/atoms/DropDown';
import ExtrasUsagePieChart from '~OMS/claims/molecules/ExtrasUsagePieChart';
import { TextResponsive } from '../../../../common/atoms/typography';
import React from 'react';
import ResponsiveFlex from '~common/atoms/ResponsiveFlex';
import { currency } from '~lib/filters';

const UsageInfo = ({ usage = [], selected = {}, onSelect, ...props } = {}) => (
  <Box {...props}>
    <DropDown fontSize={2} value={selected.id} onChange={onSelect} mb={4}>
      {usage.map(usage => (
        <Option key={usage.id} value={usage.id}>
          {usage.title}
        </Option>
      ))}
    </DropDown>
    <ResponsiveFlex justifyContent="space-between" alignItems="center">
      <Box
        css={{
          minHeight: '151px',
        }}
        width={5 / 12}
        mb={{
          xs: 3,
          md: 0,
        }}
        mr={{
          xs: 0,
          md: 3,
        }}
      >
        {<ExtrasUsagePieChart total={selected.limit} used={selected.used} />}
      </Box>
      <Box
        width={{
          xs: 1,
          md: 7 / 12,
        }}
      >
        {selected.limit === 0 ? (
          <>
            <TextResponsive color="light" mb={1}>
              No limit on general dental.
            </TextResponsive>
            <TextResponsive color="light" mb={2}>
              Fixed benefits on individual services may apply.
            </TextResponsive>
          </>
        ) : (
          <>
            <TextResponsive color="light" mb={2}>
              You have {currency(selected.remaining)} remaining from your total
              limit of {currency(selected.limit)}.
            </TextResponsive>
            <TextResponsive fontSize={1} color="light">
              Total limit of {currency(selected.limit)}
            </TextResponsive>
          </>
        )}
      </Box>
    </ResponsiveFlex>
  </Box>
);

export default UsageInfo;
