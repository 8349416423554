import React from 'react';
import { Box, Flex } from 'rebass';
import { css } from '@emotion/core';

export const formLayout = props => css`
  width: 100%;
  ${props.theme.mq.tabletP} {
    width: 555px;
    max-width: 555px;
  }
`;

export const FirstColumn = props => (
  <Box
    width={{
      xs: 1,
      tabletP: 1 / 4,
    }}
    {...props}
  />
);
export const SecondColumn = props => (
  <Flex
    width={{
      xs: 1,
      tabletP: 3 / 4,
    }}
    {...props}
  />
);
