import { Flex } from 'rebass';
import React from 'react';

const ClaimsHistoryRow = ({ children, ...props }) => (
  <Flex px={3} py={2} {...props}>
    {children}
  </Flex>
);

export default ClaimsHistoryRow;
