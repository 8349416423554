import Icon from '../../common/atoms/Icon';
import WorkingTime from '~common/molecules/WorkingTime';
import React from 'react';
import ContactUsBlock from './ContactUsBlock';
import { Flex } from 'rebass';
import { useMobileScreenOnly } from '~lib/responsive';

const WorkingTimeBlock = ({ theme, ...props }) => {
  const isMobileScreen = useMobileScreenOnly();

  return (
    <ContactUsBlock {...props}>
      <Flex
        alignItems="center"
        justifyContent={{
          xs: 'flex-start',
          tabletP: 'center',
        }}
      >
        {!isMobileScreen && (
          <Icon
            fill={theme.colors.primary}
            name="thin-clock"
            height="32px"
            width="32px"
          />
        )}
        <WorkingTime
          textAlign={{
            xs: 'left',
            tabletP: 'center',
          }}
          render={workingTime => `We're available from ${workingTime}`}
          ml={isMobileScreen ? 0 : 2}
          fontSize={{
            xs: 16,
            tabletP: 20,
          }}
          lineHeight={{
            xs: 1.31,
            tabletP: 1.5,
          }}
        />
      </Flex>
    </ContactUsBlock>
  );
};

export default WorkingTimeBlock;
