import { get } from 'lodash';
import { useQuickQuoteQuery } from '~lib/CoverProducts/useQuoteQuery';
import { minDate } from '~lib/util';

export const useQuickQuote = (cover, { resultType, rateType }) => {
  const shouldSkipQuery =
    !cover ||
    ['region', 'status', 'dob'].some(field => {
      const value = get(cover, `${field}.value`);
      return value == null || value === '';
    });

  const { data, ...rest } = useQuickQuoteQuery(
    {
      state: cover?.region?.value,
      status: cover?.status?.value,
      dob: minDate(cover?.dob?.value, cover?.partnerDob?.value)?.format(
        'YYYY-MM-DD'
      ),
      efc: get(cover, 'efc.value'),
      tier: parseInt(get(cover, 'tier.value') || '0', 10),
      resultType: resultType || cover?.resultType || 'CURRENT',
      freeExtras: cover?.freeExtras,
      subsidisedExtras: cover?.subsidisedExtras,
      rateType: rateType || cover?.rateType || 'BOTH',
      paymentFrequency: cover?.paymentFrequency,
      onSale: true,
    },
    {
      shouldSkipQuery,
    }
  );

  return {
    data: get(data, 'quickQuote'),
    ...rest,
  };
};
