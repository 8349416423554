import React, { useMemo } from 'react';
import { Flex, Box } from 'rebass';
import { withTheme } from 'emotion-theming';
import { useQuery } from '@apollo/client';
import Link from '~common/atoms/Link';
import IconHeading from '~common/atoms/IconHeading';
import Loading from '~common/atoms/Loading';
import { DashboardPaymentsQuery } from 'queries/oms/dashboard';
import { chainedPath } from '~lib/util';
import { isPaymentInArrears, isPayrollPayment } from '~OMS/response-selectors';
import PolicyPaidTo from '~common/molecules/PolicyPaidTo';
import PremiumInfo from '~common/molecules/PremiumInfo';
import useMembership from '~common/hooks/useMembership';
import S2IMembershipPaymentMessage from '~common/molecules/S2IMemebershipPaymentMessage';
import { P3 } from '~common/atoms/typography';
import { Button } from '~common/atoms/Button';

const NoCreditCardDetails = ({ theme, ...props }) => {
  // as per https://airteam.atlassian.net/browse/DWM-657
  return null;
  // return (
  //   <StandardText lineHeight="16px" fontSize={1} {...props}>
  //     Click{' '}
  //     <Link
  //       css={{ color: theme.colors.primary }}
  //       to="/oms/payments?scroll=policy"
  //     >
  //       <strong>Manage Payment ></strong>{' '}
  //     </Link>{' '}
  //     to add credit details for faster payments.{' '}
  //   </StandardText>
  // );
};

const PolicyDetails = ({ debitAccount }) => {
  if (!debitAccount()) {
    return null;
  }

  return debitAccount('details.__typename') === 'BankDetails' ? (
    <>
      <P3 variant="medium">BSB: {debitAccount('details.accountBSB')} </P3>
      <P3 variant="medium">
        Account number: {debitAccount('details.accountNumber')}
      </P3>
    </>
  ) : (
    <P3 variant="medium">
      {debitAccount('details.type')}: {debitAccount('details.number')}
    </P3>
  );
};

const PaymentInfo = ({ arrears, theme, data, isS2IMembership }) => {
  if (isS2IMembership) {
    return <S2IMembershipPaymentMessage fontSize={1} />;
  }
  return arrears ? (
    <P3 variant="medium">
      Payment in arrears.{' '}
      <Link
        to="/oms/payments"
        css={{
          fontWeight: '600',
          color: theme.colors.primary,
        }}
      >
        Please make payment
      </Link>
    </P3>
  ) : (
    <Flex flexDirection="column">
      <PremiumInfo data={data} />
      <PolicyPaidTo
        as={P3}
        data={data}
        dateProps={{
          color: theme.colors.primary,
          bold: true,
        }}
      />
    </Flex>
  );
};

const CardPayments = ({ theme }) => {
  const { loading, error, data } = useQuery(DashboardPaymentsQuery);
  const { isS2IMembership } = useMembership();
  const debitAccount = chainedPath('oms.payments.debitAccount')(data);
  const claimAccount = chainedPath('oms.payments.claimAccount')(data);
  const isPayroll = isPayrollPayment(data);
  const arrears = isPaymentInArrears(data);

  const paymentType = useMemo(() => {
    if (isPayroll) {
      return 'payroll';
    }

    if (isS2IMembership) {
      return 'Fully subsidised';
    }

    return debitAccount() ? 'Direct Debit' : 'Invoice';
    //eslint-disable-next-line
  }, [debitAccount(), isPayroll]);

  if (error) {
    console.error(error);
    return null;
  }

  const renderContent = (
    <Flex flexDirection="column" justifyContent={'space-between'} height="100%">
      <Box>
        <Box>
          <PaymentInfo
            theme={theme}
            data={data}
            arrears={arrears}
            isS2IMembership={isS2IMembership}
          />
        </Box>

        <Box mt="20px">
          <P3 variant="semiBold">How I pay for my policy: {paymentType}</P3>
          <P3 mt="20px" variant="medium">
            Payment debited from
          </P3>
          <PolicyDetails debitAccount={debitAccount} />
          {(!debitAccount() ||
            debitAccount('type').toUpperCase() === 'BANK') && (
            <NoCreditCardDetails mt={2} theme={theme} />
          )}
        </Box>

        {claimAccount() && (
          <Box mt="30px">
            <P3 variant="medium">Benefits credited to</P3>
            <P3 variant="medium"> BSB: {claimAccount('details.accountBSB')}</P3>
            <P3 variant="medium">
              Account number: {claimAccount('details.accountNumber')}
            </P3>
          </Box>
        )}
      </Box>

      <Button
        mt="20px"
        variant="secondary"
        as={Link}
        to="/oms/payments"
        width={1}
        size="medium"
      >
        Manage payment
      </Button>
    </Flex>
  );

  return (
    <Flex
      flexDirection="column"
      css={{ height: '100%', fontSize: 13, lineHeight: '16px' }}
      justifyContent="space-between"
    >
      <Box>
        <IconHeading icon="oms-payments" text="Payments" />
      </Box>

      <Box height="100%">{loading ? <Loading /> : renderContent}</Box>
    </Flex>
  );
};

export default withTheme(CardPayments);
