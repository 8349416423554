import React, { useContext, useEffect } from 'react';
import * as Yup from 'yup';
import { Box } from 'rebass';
import { Formik, Field } from 'formik';
import { get } from 'lodash';
import { noop } from '~lib/util';

import AlertBox from '~common/molecules/AlertBox';
import { Button } from '~common/atoms/Button';
import DateOfBirth from '~common/molecules/DateOfBirth';

import { AuthContext } from 'context/sso';

import isUndefined from 'lodash/isUndefined';
import PersonNameControl from '~common/molecules/form-controls/PersonNameControl';
import { validators } from '~common/molecules/form-controls';

const VerificationStep1Form = ({ user }) => {
  let setFormValues = noop;
  let formValues;
  console.log(user);

  useEffect(() => {
    if (!user) {
      return;
    }

    // the user can come from three different sources
    // 1. PW reset with dob etc.
    // 2. login screen with usernamn and password / basic auth
    // 3. register screen with username only

    let tempUser;

    // if dob, user comes from reset pw screen
    if (user.hasOwnProperty('dob')) {
      // step 1
      tempUser = {
        membershipId: user.membershipId,
        lastname: user.lastName,
        dob: user.dob,
        year: user.year,
        month: user.month,
        day: user.day,
      };
    } else if (isUndefined(user.password)) {
      // step 3
      tempUser = {
        membershipId: user.membershipId,
      };
    } else {
      // step 2
      tempUser = { username: user.username, password: user.password };
    }

    // set form fields and add username, pw for auth or memberdata optional
    setFormValues({
      ...formValues,
      ...tempUser,
    });
    // eslint-disable-next-line
  }, [user]);

  const validation = Yup.object().shape({
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    day: validators.day().required('Day is required'),
    month: validators.month().required('Month is required'),
    year: validators.year().required('Year is required'),
  });

  const initialForm = {
    firstname: '',
    lastname: '',
    day: '',
    month: '',
    year: '',
  };

  const authContext = useContext(AuthContext);
  const { verify, error, loading } = authContext;

  return (
    <Formik
      validationSchema={validation}
      initialValues={initialForm}
      onSubmit={formValues => {
        verify({
          ...formValues,
          email: user.email,
        });
      }}
    >
      {({ handleSubmit, setValues, values }) => {
        setFormValues = setValues;
        formValues = values;

        return (
          <form onSubmit={handleSubmit}>
            <Field
              component={PersonNameControl}
              placeholder="Please enter your first name"
              label="First name"
              name="firstname"
              id="firstname"
            />
            <Field
              component={PersonNameControl}
              placeholder="Please enter your last name"
              label="Last name"
              name="lastname"
              id="lastname"
            />

            <DateOfBirth />

            {error && <AlertBox error={get(error, 'message')} />}

            <Box width={1}>
              <Button
                loading={loading}
                variant="orange"
                css={{ width: '100%' }}
                type="submit"
              >
                Continue
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default VerificationStep1Form;
