import React from 'react';
import Layout from 'components/Layout';
import DHFLinkWrapper from '~OMS/SSO/organisms/DHFLinkWrapper';
import { withTheme } from 'emotion-theming';

const DHFLink = props => {
  return (
    <Layout>
      <DHFLinkWrapper location={props.location} />
    </Layout>
  );
};

export default withTheme(DHFLink);
