import { useState } from 'react';
import useForm from '~lib/hooks/useForm';
import usePersonalDetails from './usePersonalDetails';
import useExpander from './useExpander';
import {
  contactDetailsValidation,
  contactDetailsValidationSchema,
  personalDetailsValidation,
  personalDetailsValidationSchema,
  privateHealthDetailsValidation,
  privateHealthDetailsValidationSchema,
} from '../validation/yourDetailsValidation';
import { isInPast, now } from '~lib/dates';
import { dropProp, dropProps } from '~lib/util';
import { auStates } from '~lib/constants';
import { whereEq } from 'lodash/fp';

const checkStartCoverDate = startCoverDate => {
  if (!startCoverDate) {
    return undefined;
  }
  return isInPast(startCoverDate) ? now() : startCoverDate;
};

export default (
  { isFamilyPackage, criteria, setCriteria, hasLhcApplied },
  { persistKey }
) => {
  const privateHealthDetailsForm = useForm(
    {
      startCoverDate: now(),
    },
    {
      persistKey: persistKey && `${persistKey}:privateHealthDetails`,
      validateOnStartup: true,
      validate: privateHealthDetailsValidation(isFamilyPackage),
      mapFormBeforeRead: form => {
        return {
          ...form,
          values: {
            ...form?.values,
            startCoverDate: checkStartCoverDate(form?.values?.startCoverDate),
          },
        };
      },
      onChange: {
        startCoverDate: value => {
          setCriteria({
            ...criteria,
            startCoverDate: value,
          });
        },
        partnerAndDependentsCovered: value => {
          if (value === true) {
            return {
              partnerAndDependentsTransferringFromAnotherFund: null,
            };
          }
          return undefined;
        },
      },
    }
  );

  const {
    setExpanded,
    toggleExpanded,
    goToNextForm,
    goToForm,
    confirmAllForms,
    expanded,
    confirmed,
  } = useExpander({
    initialExpansion: {
      privateHealthDetails: true,
      personalDetails: false,
      contactDetails: false,
    },
    initialConfirmation: {
      privateHealthDetails: false,
      personalDetails: false,
      contactDetails: false,
    },
    scrollMap: {
      privateHealthDetails: 'personalDetails',
      personalDetails: 'contactDetails',
    },
  });

  const {
    form: personalDetailsForm,
    youthDiscountPercentage,
    lhcEligible,
    showLhcFlow,
    serialize: serializePersonalDetails,
    deserialize: deserializePersonalDetails,
    ...otherPersonalDetails
  } = usePersonalDetails({
    persistKey: persistKey && `${persistKey}:personalDetails`,
    criteria,
    setCriteria,
    validate: personalDetailsValidation,
    dob: criteria.yourDob,
    dobName: 'yourDob',
    isTransferringFromAnotherFund:
      privateHealthDetailsForm.values.isTransferringFromAnotherFund,
    hasLhcApplied,
    entryAge: criteria.entryAge,
  });

  const contactDetailsForm = useForm(
    {},
    {
      onChange: {
        residentialAddress: value => {
          if (value.state && value.state !== criteria.region.value) {
            setCriteria(
              {
                ...criteria,
                region: auStates.find(
                  whereEq({
                    value: value.state,
                  })
                ),
              },
              {
                notifyPriceChange: true,
              }
            );
          }
        },
      },
      persistKey: persistKey && `${persistKey}:contactDetails`,
      validate: contactDetailsValidation,
    }
  );

  const serializePrivateHealthDetails = () => {
    const { validValues: values } = privateHealthDetailsForm;

    let payload = {
      ...values,
    };
    if (!values.isTransferringFromAnotherFund) {
      payload = dropProps(['membershipNumber'], payload);
    }

    if (values.isTransferringFromAnotherFund == null) {
      payload = dropProp('currentHealthFund', payload);
    } else if (values.isTransferringFromAnotherFund === false) {
      payload.currentHealthFund = '';
    }

    if (!isFamilyPackage || !values.isTransferringFromAnotherFund) {
      payload = dropProps(['partnerAndDependentsCovered', ''], payload);
    }

    if (values.partnerAndDependentsCovered) {
      payload = dropProps(
        ['partnerAndDependentsTransferringFromAnotherFund'],
        payload
      );
    }

    return payload;
  };

  const serializeContactDetails = () => {
    const { validValues: values } = contactDetailsForm;
    let payload = {
      ...values,
    };

    if (!values.isPostalAddressDifferent) {
      payload = dropProp('postalAddress', payload);
    }

    return payload;
  };

  const touched = {
    privateHealthDetails: () =>
      privateHealthDetailsForm.setAllTouchedFromValidationSchema(
        privateHealthDetailsValidationSchema
      ),
    personalDetails: () =>
      personalDetailsForm.setAllTouchedFromValidationSchema(
        personalDetailsValidationSchema
      ),
    contactDetails: () =>
      contactDetailsForm.setAllTouchedFromValidationSchema(
        contactDetailsValidationSchema
      ),
  };

  const setAllTouched = () => {
    touched.privateHealthDetails();
    touched.personalDetails();
    touched.contactDetails();
  };

  const handleNextForm = (currentForm, isValid) => {
    touched[currentForm]();
    if (isValid) {
      goToNextForm(currentForm);
    }
  };

  const [isFormSubmitted, setSubmittedRaw] = useState(false);

  const setSubmitted = () => {
    setAllTouched();
    setSubmittedRaw(true);
  };

  return {
    setSubmitted,
    isFormSubmitted,
    handleNextForm,
    confirmAllForms,
    setExpanded,
    toggleExpanded,
    goToNextForm,
    goToForm,
    personalDetailsForm,
    contactDetailsForm,
    privateHealthDetailsForm,
    youthDiscountPercentage,
    lhcEligible,
    showLhcFlow,
    hasLhcApplied: otherPersonalDetails.hasLhcApplied,
    expanded,
    confirmed,
    form: {
      reset: () => {
        personalDetailsForm.reset();
        contactDetailsForm.reset();
        privateHealthDetailsForm.reset();
      },
      isValid:
        contactDetailsForm.isValid &&
        personalDetailsForm.isValid &&
        privateHealthDetailsForm.isValid,
    },
    serialize: () => {
      return {
        privateHealthDetails: serializePrivateHealthDetails(),
        personalDetails: serializePersonalDetails(),
        contactDetails: serializeContactDetails(),
      };
    },
    deserialize: (data, maf, quoteInput) => {
      privateHealthDetailsForm.setForm(data.privateHealthDetails, {
        merge: false,
      });

      deserializePersonalDetails(data.personalDetails, {
        lhcExempt: quoteInput?.lhcExempt,
        entryAge: quoteInput.entryAge,
        dob: quoteInput.dob,
        hospitalProduct: quoteInput.hospitalProduct,
        isTransferringFromAnotherFund: !!(
          data.privateHealthDetails.currentHealthFund ||
          data.privateHealthDetails.membershipNumber
        ),
      });

      contactDetailsForm.setForm(data.contactDetails, { merge: false });
    },
  };
};
