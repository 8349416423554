import React from 'react';
import { Flex, Box } from 'rebass';
import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';
import Icon from '~common/atoms/Icon';
import { TextResponsive } from '~common/atoms/typography';

const StyledClaimsCard = styled(Flex)`
  border: 1px solid ${props => props.theme.colors.borders};
  border-radius: 6px;
  padding: ${props => props.theme.space[4]}px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.1);
`;

const StyledCardText = styled(Box)`
  text-align: center;
  ${props => props.theme.mq.md} {
    text-align: left;
  }
`;

const StyledIcon = styled(Icon)`
  circle {
    fill: ${props => props.theme.colors.primary};
  }
`;

const ClaimsCard = ({ theme, icon, title, message, ...props }) => {
  return (
    <StyledClaimsCard
      alignItems="center"
      flexDirection={{
        xs: 'column',
        md: 'row',
      }}
      theme={theme}
      {...props}
    >
      <StyledIcon
        mb={{
          xs: 3,
          md: 0,
        }}
        name={icon}
        width="65px"
        height="65px"
        originalColorSchema
        mr={3}
      />
      <StyledCardText theme={theme} textAlign="center">
        <TextResponsive mb={1} fontWeight={600} color="light" fontSize={3}>
          {title}
        </TextResponsive>
        <TextResponsive lineHeight="16px">{message}</TextResponsive>
      </StyledCardText>
    </StyledClaimsCard>
  );
};

export default withTheme(ClaimsCard);
