import React from 'react';
import { MyDetailsQuery } from 'queries/oms/myDetails.graphql';
import { get } from 'lodash';
import HeaderLine from '~common/atoms/HeaderLine';
import { Flex, Box } from 'rebass';
import { withTheme } from 'emotion-theming';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';

const MembershipNumber = styled(Box)`
  &&& {
    font-size: 21px;
    font-weight: normal;
  }
`;

const MyDetailsHeader = ({ theme, ...props }) => {
  const { data } = useQuery(MyDetailsQuery);
  return (
    <HeaderLine
      mb={3}
      theme={theme}
      title={
        <Flex
          className="full-width"
          justifyContent="space-between"
          alignItems="center"
          flexDirection={{
            xs: 'column',
            md: 'row',
          }}
        >
          <Box
            mb={{
              xs: 3,
              md: 0,
            }}
          >
            My Details
          </Box>
          <MembershipNumber>
            Membership number {get(data, 'oms.membership.id') || ''}
          </MembershipNumber>
        </Flex>
      }
      {...props}
    />
  );
};

export default withTheme(MyDetailsHeader);
