import React from 'react';
import { Flex, Box } from 'rebass';
import { withTheme } from 'emotion-theming';
import Icon from '~common/atoms/Icon';

import FormTitle from '~OMS/styles/FormTitle';

function SimpleSuccessHeader({ title, theme, withIcon, iconName, ...props }) {
  return (
    <Flex {...props} alignItems="center">
      {withIcon && (
        <Box mr={3}>
          <Icon
            height="50px"
            width="50px"
            name={iconName || 'success-circle-tick'}
            fill={iconName ? theme.colors.text.dark : theme.colors.green}
          />
        </Box>
      )}

      <FormTitle lineHeight="1" marginReset>
        {title}
      </FormTitle>
    </Flex>
  );
}

export default withTheme(SimpleSuccessHeader);
