import { useEffect, useState } from 'react';
import useModalState from '~lib/hooks/useModalState';

export default (rates, quoteForm) => {
  const { modalState, closeModal } = useModalState(false);

  const [popupMessageWasShown, markPopupMessageAsShown] = useState(false);

  useEffect(() => {
    if (popupMessageWasShown || rates.loading || rates.loading == null) {
      return;
    }
    setTimeout(() => {
      markPopupMessageAsShown(true);
    }, 500);

    //eslint-disable-next-line
  }, [rates.loading]);

  useEffect(() => {
    markPopupMessageAsShown(false);
  }, [quoteForm.region.value, quoteForm.status.value]);

  return {
    state: {
      visible: modalState,
    },
    actions: {
      close: closeModal,
    },
  };
};
