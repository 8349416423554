import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  ReferFriend,
  DrawerQuery,
  MembershipQuery,
  PreviewReferFriendEmailQuery,
} from 'queries/oms/common.graphql';
import { MyDetailsQuery } from 'queries/oms/myDetails.graphql';

import { cloneDeep } from '@apollo/client/utilities';
import useModalState from '~lib/hooks/useModalState';
import useSanityReferFriend from '~lib/hooks/sanity/useReferFriend';
import { get } from 'lodash';
import { isReferFriendEligible } from '~OMS/response-selectors';

export const VIEWS = {
  REFER_FRIEND: 'referFriend',
  REFER_FRIEND_SUCCESS: 'referFriendSuccess',
  REFER_FRIEND_FAILURE: 'referFriendFailure',
};

export const useReferFriend = () => {
  const { data: membership } = useQuery(MembershipQuery);
  const {
    openModal,
    closeModal,
    modalState: modalVisible,
  } = useModalState(false);
  const referRef = useRef(null);
  const { loading: loadingMyDetails, data: myDetails } =
    useQuery(MyDetailsQuery);
  const { omsText, omsFormDisclaimer, ...referFriendCmsContent } =
    useSanityReferFriend();
  const referralCode = useMemo(() => {
    return get(myDetails, 'oms.membership.referralCode') || '';
  }, [myDetails]);

  const [state, setState] = useState({
    view: VIEWS.REFER_FRIEND,
    form: undefined,
  });

  useEffect(() => {
    if (
      !loadingMyDetails &&
      referRef?.current &&
      window.location.hash === '#referAFriend'
    ) {
      setTimeout(() => {
        referRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }, 0);
    }
  }, [loadingMyDetails]);

  const [referFriend, { loading: referFriendLoading }] =
    useMutation(ReferFriend);

  const setView = useCallback(
    view => {
      setState({
        ...state,
        view,
      });
    },
    [setState]
  );

  const resetView = useCallback(() => {
    setState({
      form: undefined,
      view: VIEWS.REFER_FRIEND,
    });
  }, [setState]);

  const submitReferFriendForm = async formData => {
    const input = {
      inviteeName: formData.inviteeName,
      inviteeEmail: formData.inviteeEmail,
    };

    try {
      await referFriend({
        variables: {
          input,
        },
        update: (store, { data: { referFriend } }) => {
          if (referFriend && referFriend.success) {
            if (referFriend.activity) {
              try {
                const currentDrawerQuery = cloneDeep(
                  store.readQuery({ query: DrawerQuery })
                );
                if (currentDrawerQuery && currentDrawerQuery.oms) {
                  currentDrawerQuery.oms.activities.unshift(
                    referFriend.activity
                  );
                  store.writeQuery({
                    query: DrawerQuery,
                    data: currentDrawerQuery,
                  });
                }
              } catch (ex) {
                // Drawer available for logged in users only.
              }
            }
            setState({
              view: VIEWS.REFER_FRIEND_SUCCESS,
              form: {
                ...formData,
              },
            });
          } else {
            setState({
              view: VIEWS.REFER_FRIEND_FAILURE,
              form: {
                ...formData,
              },
            });
          }
        },
      });
    } catch (error) {
      setState({
        view: VIEWS.REFER_FRIEND_FAILURE,
        form: {
          ...formData,
        },
      });
    }
  };

  const [
    loadPreviewEmailBasic,
    { data: previewEmailData, loading: loadingPreviewEmail },
  ] = useLazyQuery(PreviewReferFriendEmailQuery);

  const loadPreviewEmail = ({ name, email }) => {
    loadPreviewEmailBasic({
      variables: {
        input: {
          inviteeEmail: email,
          inviteeName: name,
        },
      },
    });
  };

  return {
    state: {
      ...state,
      loading: referFriendLoading,
      referFriendCmsContent,
      referRef,
      referralCode,
      modalVisible,
      referFriendEligible: isReferFriendEligible(membership),
      loadingMyDetails,
      omsText,
      omsFormDisclaimer,
      previewedEmail: previewEmailData?.oms?.previewReferFriendEmail,
      loadingPreviewEmail,
    },
    submitReferFriendForm,
    resetView,
    setView,
    openModal,
    loadPreviewEmail,
    closeModal: () => {
      closeModal();
      resetView();
    },
  };
};
