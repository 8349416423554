import React from 'react';
import styled from '@emotion/styled';
import { useApplicationFormContext } from '../state/ApplicationFormProvider';
import { StandardText } from '~common/atoms/typography';
import CenterBox from '~common/atoms/CenterBox';
import { FirstColumn, SecondColumn } from '~maf/common/layout';
import Stepper from '~common/organisms/Stepper';
import { Flex } from 'rebass';
import Confirmation from '~OMS/my-cover/change-cover/ApplicationForm/Confirmation';
import Loading from '~common/atoms/Loading';

const BackLink = styled.span`
  display: block;
  color: ${props => props.theme.colors.secondary};
  text-decoration: underline;
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 32px;
`;

const ActiveStep = () => {
  const appFormContext = useApplicationFormContext();
  const { state, actions, activeStepProvider, changeCoverContext } =
    appFormContext;

  const onBackClick = changeCoverContext.actions.goToInitialStep;

  const { context: stepContext, Component: StepComponent } =
    activeStepProvider || {
      context: {
        form: {},
      },
      Component: () => (
        <StandardText color="light2" fontSize={21}>
          Yet to be implemented!!!
        </StandardText>
      ),
    };

  const renderContent = () => {
    if (state.loading) {
      return (
        <CenterBox>
          <Loading />
        </CenterBox>
      );
    }
    return !state.applicationSent ? (
      <>
        <FirstColumn
          mb={{
            xs: 55,
            tabletP: 0,
          }}
          pl={20}
          pr={20}
        >
          <BackLink onClick={e => onBackClick()}>&lt; Back</BackLink>
          <Stepper
            onStepClick={evt => actions.selectStep(evt)}
            steps={state.steps}
          />
        </FirstColumn>
        <SecondColumn>
          <StepComponent
            appFormContext={appFormContext}
            context={stepContext}
          />
        </SecondColumn>
      </>
    ) : (
      <CenterBox>
        <Confirmation promotions={appFormContext.state.promotions} />
      </CenterBox>
    );
  };

  return (
    <Flex
      py={42}
      minHeight="calc(100vh - 197px - 200px)"
      alignItems="flex-start"
      flexDirection={{
        xs: 'column',
        tabletP: 'row',
      }}
    >
      {renderContent()}
    </Flex>
  );
};

export default ActiveStep;
