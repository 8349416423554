import useUpdateMembershipMutation from '~common/hooks/useUpdateMembershipMutation';
import { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import { MyDetailsQuery } from 'queries/oms/myDetails.graphql';
import { dropEmpties, pathOr } from '~lib/util';

export default () => {
  const [updateMembership, { loading: updating }] =
    useUpdateMembershipMutation();
  const { data: myDetailsData, loading: myDetailsLoading } =
    useQuery(MyDetailsQuery);

  const [alert, setAlert] = useState();

  const updatePreferences = useCallback(
    ({ billing, policy }) => {
      setAlert(undefined);
      updateMembership({
        preferences: dropEmpties({
          billingCommunication: billing,
          policyCommunication: policy,
        }),
      })
        .then(({ error }) => {
          if (error) {
            setAlert({
              type: 'error',
              message: error.message,
            });
          } else {
            setAlert({
              type: 'success',
              message: 'Communication preferences updated successfully.',
            });
          }
        })
        .catch(error => {
          setAlert({
            type: 'error',
            message: error.message || error,
          });
        });
    },
    [updateMembership]
  );

  return {
    state: {
      myDetailsLoading,
      updating,
      alert,
      preferences: pathOr({}, 'oms.membership.preferences')(myDetailsData),
    },
    actions: {
      updatePreferences,
    },
  };
};
