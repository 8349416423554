import { path } from 'lodash/fp';
import { dropEmpties } from '~lib/util';
import { DEBIT_TYPE } from '../../constants';
import { verifyBsb } from '~common/services/kleberService';
import { detectCard } from '~OMS/payments/cardDetector';
import { maskCardNumber, sanitizeCardNumber } from '~OMS/payments/paymentUtils';
import { PAYMENT_TYPE } from '~OMS/payments/constants';

export default ({ form, usePaymentManage, privateHealthDetailsForm }) => {
  const { manageCard, manageBank, manageClaiming } = usePaymentManage();

  const safeCall =
    fn =>
    (...args) => {
      return fn ? fn(...args) : Promise.resolve();
    };

  const manageCardSafe = safeCall(manageCard);
  const manageBankSafe = safeCall(manageBank);
  const manageClaimingSafe = safeCall(manageClaiming);

  const getCardType = cardNumber => {
    const card = detectCard(cardNumber);

    if (card) {
      return {
        VISA: 'Visa',
        MCARD: 'MCard',
      }[card.type];
    }
    return undefined;
  };

  const serializeCreditCarDetails = async (values, errors) => {
    if (errors.cardNumber || errors.expiry || errors.cvv) {
      return {
        cardHolder: values.cardHolder,
      };
    }

    return {
      ...(await manageCardSafe(
        {
          ...values,
          effectiveDate: privateHealthDetailsForm.values.startCoverDate,
        },
        errors,
        'ADD'
      )),
      cardHolder: values.cardHolder,
      expiry: values.expiry,
      cardNumber: maskCardNumber(sanitizeCardNumber(values.cardNumber)),
      cardType: getCardType(values.cardNumber),
    };
  };

  const getBsbName = bsb => {
    return bsb
      ? verifyBsb(bsb).then(result => {
          return path('results[0].BsbName')(result);
        })
      : undefined;
  };

  const serializers = {
    [DEBIT_TYPE.BANK_ACCOUNT]: async values => {
      return {
        bsb: values.bsb,
        bsbName: await getBsbName(values.bsb),
        accountNumber: values.accountNumber,
        accountName: values.accountName,
        ...(await manageBankSafe(values)),
      };
    },
    [DEBIT_TYPE.CREDIT_CARD]: serializeCreditCarDetails,
  };

  const getClaimDetails = async values => {
    if (values.useBankAccountDetails) {
      const payload = {
        claimBsb: values.bsb,
        claimBsbName: await getBsbName(values.bsb),
        claimAccountNumber: values.accountNumber,
        claimAccountName: values.accountName,
      };

      return {
        ...payload,
        ...(await manageClaimingSafe(payload)),
      };
    }

    {
      const payload = {
        claimBsb: values.claimBsb,
        claimBsbName: await getBsbName(values.claimBsb),
        claimAccountNumber: values.claimAccountNumber,
        claimAccountName: values.claimAccountName,
      };
      return {
        ...payload,
        ...(await manageClaimingSafe({
          ...payload,
          effectiveDate: privateHealthDetailsForm?.values?.startCoverDate,
        })),
      };
    }
  };

  const serialize = async () => {
    const values = form.validValues;
    const debitType = serializers?.[form?.values?.debitType]
      ? await serializers?.[form?.values?.debitType]?.(
          {
            ...values,
            effectiveDate: privateHealthDetailsForm?.values?.startCoverDate,
          },
          form.errors
        )
      : {};
    return dropEmpties({
      ...debitType,
      paymentFrequency: values?.paymentFrequency,
      preferredDebitDay: values?.preferredDebitDay,
      debitType: {
        [PAYMENT_TYPE.CREDIT_CARD]: 'creditCard',
        [PAYMENT_TYPE.BANK_ACCOUNT]: 'bank',
      }[values?.debitType],
      ...(await getClaimDetails({
        ...values,
        effectiveDate: privateHealthDetailsForm?.values?.startCoverDate,
      })),
    });
  };

  return {
    serialize,
  };
};
