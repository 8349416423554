import React from 'react';
import Layout from 'components/Layout';
import SetSuccessWrapper from '~OMS/SSO/organisms/SetSuccessWrapper';
import { withTheme } from 'emotion-theming';

const SetSuccess = props => {
  return (
    <Layout>
      <SetSuccessWrapper />
    </Layout>
  );
};

export default withTheme(SetSuccess);
