import React from 'react';
import { Flex, Box } from 'rebass';
import { StandardText } from '../../../../common/atoms/typography';
import styled from '@emotion/styled';

const lightColor = props => props.theme.colors.white;

const borderRadius = '6px';
const barHeight = 10;

const Bar = styled(Box)`
  background: transparent;
  height: ${barHeight}px;
  position: relative;
  border: 1px solid ${lightColor};
  border-radius: 6px;
`;

const Progress = styled(Box)`
  background: ${lightColor};
  position: absolute;
  left: 0;
  top: -1px;
  height: ${barHeight + 1}px;
  border-radius: ${borderRadius};
  width: ${props => props.progress}%;
`;

const ProgressBar = ({ progress = 20, ...props }) => {
  return (
    <Flex
      width={1}
      justifyContent={{
        xs: 'center',
        md: 'space-between',
      }}
      flexDirection={{
        xs: 'column',
        md: 'row',
      }}
      alignItems="center"
      {...props}
    >
      <StandardText
        textAlign="center"
        color="white"
        fontSize={1}
        mr={2}
        mb={{
          xs: 1,
          md: 0,
        }}
      >
        Uploaded {progress.toFixed(0)}%
      </StandardText>
      <Bar
        width={{
          xs: 1,
          md: 4 / 5,
        }}
      >
        &nbsp;
        <Progress progress={progress} />
      </Bar>
    </Flex>
  );
};

export default ProgressBar;
