import { useState, useEffect } from 'react';
import remove from 'lodash/remove';

const DURATION = 1000;
let distanceY;
const listeners = [];
let state = {
  rAFId: 0,
};

const setState = newState => {
  state = { ...state, rAFId: newState };
  listeners.forEach(listener => {
    listener.handler(state);
  });
};

export default function useScrollTop() {
  const handler = useState()[1];
  const newListener = {
    handler,
    index: listeners.length,
  };

  function scrollStep() {
    if (window.pageYOffset <= 0) {
      return;
    }
    window.scroll(0, Math.round(window.pageYOffset - distanceY));
    const rAFId = window.requestAnimationFrame(scrollStep);
    setState(rAFId);
  }

  function scrollTo() {
    if (window.pageYOffset <= 0) {
      return;
    }
    distanceY = window.pageYOffset / (DURATION / 60);
    const rAFId = window.requestAnimationFrame(scrollStep);
    setState(rAFId);
  }

  useEffect(() => {
    const index = listeners.push(newListener) - 1;
    return () => {
      remove(listeners, e => {
        return e.index === index;
      });
      window.cancelAnimationFrame(state.rAFId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, scrollTo];
}
