import React from 'react';
import { StandardText } from '../../../../common/atoms/typography';
import styled from '@emotion/styled';

const StyledMoreDetailsText = styled(StandardText)`
  font-size: ${props => props.theme.fontSizes[1]}px;
  width: 100%;
  ${props => props.theme.mq.md} {
    font-size: ${props => props.theme.fontSizes[2]}px;
    width: 75%;
  }
`;

const MoreDetailsOnClaimsHistory = props => {
  return (
    <StyledMoreDetailsText color="light" {...props}>
      If you would like to enquire about a claim that was processed over three
      years ago, or if you have a query about the details of your claims, please
      contact us.
    </StyledMoreDetailsText>
  );
};

export default MoreDetailsOnClaimsHistory;
