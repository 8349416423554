import React from 'react';
import CenterBox from '~common/atoms/CenterBox';
import { StandardText } from '../../../../../common/atoms/typography';
import styled from '@emotion/styled';

const StyledNoClaimsFound = styled(CenterBox)`
  height: 80vh;
`;

const NoClaimsFound = props => {
  return (
    <StyledNoClaimsFound {...props}>
      <StandardText fontSize={4} color="light2">
        No claims found
      </StandardText>
    </StyledNoClaimsFound>
  );
};

export default NoClaimsFound;
