import { useCallback, useState } from 'react';

const useReactiveRef = initialValue => {
  const [element, setElement] = useState(initialValue);
  const setRef = useCallback(node => {
    setElement(node);
  }, []);

  return { ref: setRef, element };
};

export default useReactiveRef;
