import React from 'react';
import Layout from 'components/Layout';
import ChangePasswordWrapper from '~OMS/SSO/organisms/ChangePasswordWrapper';
import { withTheme } from 'emotion-theming';
import { useLocation } from '@reach/router';

const ChangePassword = () => {
  const location = useLocation();

  return (
    <Layout>
      <ChangePasswordWrapper location={location} />
    </Layout>
  );
};

export default withTheme(ChangePassword);
