import axios from 'axios';
import { chainedPath, memoizeBy } from '~lib/util';

const BASE_URL = process.env.DHF_KLEBER_ENDPOINT;

class ExpiredKeyError extends Error {
  constructor() {
    super('Temporary kleber key has expired');
  }
}

const extractResponse = rawResponse => {
  const kleberResponse = chainedPath('data.DtResponse')(rawResponse);
  if (kleberResponse('ErrorMessage')) {
    const error = kleberResponse('ErrorMessage');
    if (error === 'Temporary Request Key has expired') {
      throw new ExpiredKeyError();
    }
    throw error;
  }

  return kleberResponse();
};

export const requestTempKey = () => {
  return axios.get('/addressValidation/getKleberKey').then(response => {
    return response.data.kleberKey;
  });
};

let tempKeyPromise;

const getTempKey = () => {
  if (tempKeyPromise) {
    return tempKeyPromise;
  }

  tempKeyPromise = requestTempKey();

  return tempKeyPromise.catch(error => {
    tempKeyPromise = undefined;
    throw error;
  });
};

const kleberRequest = async params => {
  return await axios
    .get(
      BASE_URL,
      {
        params: {
          ...params,
          RequestKey: await getTempKey(),
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(extractResponse)
    .catch(error => {
      if (error instanceof ExpiredKeyError) {
        tempKeyPromise = undefined;
        return kleberRequest(params);
      }
      throw error;
    });
};

// cache the results so no extra cost is charged by kleber
export const verifyBsb = memoizeBy(bsb => bsb)(
  async (bsb, { DepartmentCode, ResultLimit, ...options } = {}) => {
    const data = {
      BSB: bsb,
      Method: 'DataTools.Verify.Bsb.AuApca.VerifyBsb',
      RequestId: `verifyBsb-${bsb}_${Date.now()}`, // this can be anything
      // RequestKey: await getTempKey(),
      OutputFormat: 'json',
      DepartmentCode,
      ResultLimit,
      ...options,
    };

    return kleberRequest(data).then(({ ResultCount, Result }) => ({
      isValid: !!Number(ResultCount),
      results: Result,
    }));
  }
);

export const repairAddress = address => {
  const data = {
    Method: 'DataTools.Repair.Address.AuPaf.RepairAddress',
    AddressLine1: address,
    AddressLine2: '',
    AddressLine3: '',
    AddressLine4: '',
    AddressLine5: '',
    AddressLine6: '',
    Locality: '',
    State: '',
    PrepairAddressostcode: '',
    RequestId: 'repairAddress', // this can be anything
    // RequestKey: apiKey,
    DepartmentCode: '',
    OutputFormat: 'json',
  };

  // when search query is changing, call API
  return kleberRequest(data).then(result => result.Result);
};

export const searchAddress = query => {
  const data = {
    Method: 'DataTools.Capture.Address.Predictive.AuPaf.SearchAddress',
    AddressLine: query,
    ResultLimit: '',
    SearchOption: '',
    RequestId: 'addressSearch', // this can be anything
    // RequestKey: REQUEST_KEY,
    DepartmentCode: '',
    OutputFormat: 'json',
  };

  return kleberRequest(data).then(response => response.Result);
};
