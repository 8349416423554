import useChangePaymentDetails from '~OMS/payments/hooks/useChangePaymentDetails/useChangePaymentDetails';
import usePaymentSerializerBasic from '~maf/state/usePaymentsStep/usePaymentSerializerBasic';
import usePaymentClaim from '~OMS/payments/hooks/usePaymentClaim';

const usePaymentManage = () => {
  const changePaymentDetails = useChangePaymentDetails();
  const paymentClaim = usePaymentClaim();

  const manage = async values => {
    const { fingerprintDetail } =
      await changePaymentDetails.actions.submitDebitDetailsChange({
        ...values,
        type: values.debitType,
      });
    return fingerprintDetail;
  };

  return {
    manageCard: values =>
      manage(values).then(result => ({
        crn: result.crn,
      })),
    manageBank: values => manage(values).then(() => undefined),
    manageClaiming: values =>
      paymentClaim.actions
        .changeDetails({
          accountNumber: values.claimAccountNumber,
          accountName: values.claimAccountName,
          accountBSB: values.claimBsb,
          effectiveDate: values.effectiveDate,
        })
        .then(() => undefined),
  };
};

export default args => {
  return usePaymentSerializerBasic({
    ...args,
    usePaymentManage,
  });
};
