/* eslint-disable react/jsx-no-target-blank */
// members health wants to track referrals
import { Flex } from 'rebass';
import Icon from '~common/atoms/Icon';
import React from 'react';
import ByBrand from '~common/atoms/ByBrand';

const MembersHealthFundLogo = props => (
  <ByBrand
    dhf={() => (
      <Flex {...props}>
        <a href="https://membershealth.com.au" target="_blank" rel="noopener">
          <Icon
            style={{ cursor: 'pointer' }}
            name="members-fund"
            originalColorSchema
            height="22px"
          />
        </a>
      </Flex>
    )}
  />
);

export default MembersHealthFundLogo;
