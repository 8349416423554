import {
  address,
  defined,
  email,
  FormValidator,
  maxLength,
  phoneNumber,
  required,
  asciiOnly,
} from '~lib/formValidation';
import { validators } from '~common/molecules/form-controls';
import { getPersonAgeDuration } from '../common/appFormUtills';
import { isInPast, now, toDate } from '~lib/dates';

export const isStartCoverDateValid = value =>
  !isInPast(value) && !toDate(value).isAfter(now().add(3, 'month'));

export const privateHealthDetailsValidationSchema = {
  startCoverDate: [
    required('Start date is required'),
    value => {
      if (!isStartCoverDateValid(value)) {
        throw 'Start cover date must not be in past';
      }
    },
  ],
  isTransferringFromAnotherFund: defined('Please answer this question'),
  currentHealthFund: required('Current health fund is a required field'),
  membershipNumber: [
    required('Membership number is required field'),
    maxLength(12, 'Membership number is max 13 characters long'),
  ],
  partnerAndDependentsCovered: defined('Please answer this question'),
  partnerAndDependentsTransferringFromAnotherFund: defined(
    'Please answer this question'
  ),
};

export const privateHealthDetailsValidation = isFamilyPackage =>
  FormValidator({
    validation: privateHealthDetailsValidationSchema,
    transformValidation: (validationArray, form) => {
      validationArray = validationArray.filter(([field]) => {
        if (!form.isTransferringFromAnotherFund) {
          return ![
            'currentHealthFund',
            'membershipNumber',
            'partnerAndDependentsCovered',
            'partnerAndDependentsTransferringFromAnotherFund',
          ].includes(field);
        }

        if (!isFamilyPackage) {
          return ![
            'partnerAndDependentsCovered',
            'partnerAndDependentsTransferringFromAnotherFund',
          ].includes(field);
        }

        if (form.partnerAndDependentsCovered) {
          return field !== 'partnerAndDependentsTransferringFromAnotherFund';
        }

        return true;
      });

      return validationArray;
    },
  });

export const personalDetailsValidationSchema = {
  title: required('Title is required field'),
  firstName: [
    required('First name is required field'),
    asciiOnly('First name contains unsupported characters'),
    maxLength(16, 'First name is max 16 characters long'),
  ],
  middleName: [
    asciiOnly('Middle name contains unsupported characters'),
    maxLength(16, 'Middle name is max 16 characters long'),
  ],
  lastName: [
    required('Last name is required field'),
    asciiOnly('Last name contains unsupported characters'),
    maxLength(25, 'Last name is max 25 characters long'),
  ],
  dobDay: [
    required('Day is required'),
    value => {
      validators.day().validateSync(value);
    },
  ],
  dobMonth: [
    required('Month is required'),
    value => {
      validators.month().validateSync(value);
    },
  ],
  dobYear: [
    required('Year is required'),
    value => {
      validators.year().validateSync(value);
    },
    validators.dobValidator,
  ],
  lhcStatement: defined('Please answer this question'),
  firstHospitalCoverAge: [
    required('Age is required field'),
    (value, _, form) => {
      value = Number(value);
      const { _data } = getPersonAgeDuration({
        values: form,
      });
      const { years, months, days, hours, minutes, seconds, milliseconds } =
        _data;

      if (value < 32) {
        throw [
          `If you were 31 years old or younger when you first took out hospital cover, select yes for 'Have you held hospital cover continuously since your 31st birthday OR continuously for the last 10 years?' to proceed.`,
        ];
      }
      if (
        value > years ||
        (value === years &&
          !months &&
          !days &&
          !hours &&
          !minutes &&
          !seconds &&
          !milliseconds)
      ) {
        throw [
          'The age when you took out hospital cover must be less than your current age',
        ];
      }
    },
  ],
  gender: defined('Please select your gender'),
};

export const personalDetailsValidation = () =>
  FormValidator({
    validation: personalDetailsValidationSchema,
    transformValidation: (validationArray, form) => {
      return validationArray.filter(([field]) => {
        if (!form.showLhcFlow) {
          return !['lhcStatement', 'firstHospitalCoverAge'].includes(field);
        }
        if (form.lhcStatement) {
          return field !== 'firstHospitalCoverAge';
        }
        return true;
      });
    },
  });

export const contactDetailsValidationSchema = {
  email: [required('Email is required'), email()],
  phoneNumber: [
    required('Mobile number is required'),
    phoneNumber('Invalid mobile phone number'),
  ],
  residentialAddress: address('Residential address'),
  postalAddress: address('Postal address'),
};

export const contactDetailsValidation = FormValidator({
  validation: contactDetailsValidationSchema,
  transformValidation: (validationArray, form) => {
    if (!form.isPostalAddressDifferent) {
      validationArray = validationArray.filter(
        ([field]) => field !== 'postalAddress'
      );
    }

    return validationArray;
  },
});
