import React from 'react';
import { StandardText } from '../../../../common/atoms/typography';
import { Box } from 'rebass';
import { Button } from '~common/atoms/Button';
import { Link } from 'gatsby';
import Loading from '~common/atoms/Loading';
import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';
import { isEmptyObject } from '~lib/util';

const MARGIN = 3;

const StyledClaimPaymentInfoContainer = styled(Box)`
  text-align: center;
  ${props => props.theme.mq.md} {
    text-align: left;
  }
`;

const ClaimPaymentInfo = ({ loading, details, theme, ...props }) => {
  return loading ? (
    <Loading />
  ) : (
    <StyledClaimPaymentInfoContainer theme={theme} {...props}>
      <StandardText fontSize={3} fontWeight={600} mb={4}>
        How my claim is paid
      </StandardText>
      {isEmptyObject(details) ? (
        <StandardText mb={MARGIN + 1}>
          We currently pay your benefits by cheque. To have your claims paid
          directly into your nominated account please update your bank details.
        </StandardText>
      ) : (
        <>
          <StandardText mb={MARGIN}>
            Account name: {details.accountName}
          </StandardText>
          <StandardText mb={MARGIN}>BSB: {details.accountBSB}</StandardText>
          <StandardText mb={MARGIN + 1}>
            Account number: {details.accountNumber}
          </StandardText>
        </>
      )}

      <Button
        variant="secondaryInverted"
        as={Link}
        to="/oms/payments?scroll=claim"
        width={1}
      >
        Manage payments
      </Button>
    </StyledClaimPaymentInfoContainer>
  );
};

export default withTheme(ClaimPaymentInfo);
