//TODO: split into multiple file

import React from 'react';
import { Box, Flex } from 'rebass';
import styled from '@emotion/styled';
import { StandardText } from '../../common/atoms/typography';
import { shortWordyFormat } from '~lib/dates';
import SanityImage from '../../common/molecules/SanityImage';
import { css } from '@emotion/core';
import BackgroundImage from '../../common/molecules/BackgroundImage';
import { useThemeUI } from 'theme-ui';
import { Link } from 'gatsby';
import { GRID_ROW_HEIGHT } from './constants';

const AlternateBackground = styled(Box)`
  background: ${props =>
    props.alternate
      ? props.theme.colors.primary
      : props.theme.colors.secondary};
`;

const ImageLessCardContainer = styled(AlternateBackground)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

const ImageLessCard = ({ article, index }) => (
  <ImageLessCardContainer alternate={index % 2} p={40}>
    <StandardText
      css={css`
        align-self: flex-start;
      `}
      fontSize={14}
      fontWeight={500}
      mb={10}
      color="white"
    >
      {shortWordyFormat(article.date)}
    </StandardText>
    <StandardText
      lineHeight={1.5}
      mb={14}
      fontSize={24}
      color="white"
      fontWeight="600"
    >
      {article.title}
    </StandardText>
    <StandardText
      lineHeight={1.5}
      color="rgba(255, 255, 255, 0.87)"
      fontSize="14px"
      fontWeight={500}
      maxLines={4}
    >
      {article.excerpt.slice(0, 100) + '...'}
    </StandardText>
  </ImageLessCardContainer>
);

const ImageCard = ({ article }) => {
  return (
    <Box
      css={css`
        height: 100%;
        box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.3);
      `}
    >
      <SanityImage
        style={{
          width: '100%',
          height: '222px',
        }}
        transform={transformer => transformer.width(400)}
        src={article.image}
      />
      <Box p={20}>
        <StandardText
          mb={10}
          fontSize={14}
          fontWeight={500}
          color="rgba(15, 15, 15, 0.87)"
        >
          {shortWordyFormat(article.date)}
        </StandardText>
        <StandardText mb={17} color="dark" fontWeight="600" fontSize={20}>
          {article.title}
        </StandardText>
        <StandardText
          maxLines={2}
          lineHeight={1.5}
          color="rgba(15, 15, 15, 0.87)"
          fontSize="14px"
          fontWeight={500}
        >
          {article.excerpt.slice(0, 110) + '...'}
        </StandardText>
      </Box>
    </Box>
  );
};

const FeaturedCardTitle = styled(StandardText)`
  display: flex;
  align-items: center;
  height: 100%;
`;

const FeaturedCard = ({ article, index }) => {
  const { theme } = useThemeUI();

  const FeaturedCardContainer =
    article.image && !article.hideImageOnIndex
      ? BackgroundImage
      : AlternateBackground;

  return (
    <FeaturedCardContainer
      height="100%"
      flexDirection="column"
      justifyContent="space-between"
      data={article.image}
      as={Flex}
      alternate={index % 2}
      transform={transformer => transformer.height(GRID_ROW_HEIGHT)}
    >
      <FeaturedCardTitle
        fontSize={34}
        fontWeight="600"
        color={article.image ? article.textColor || 'dark' : 'white'}
        p={20}
        lineHeight={1.5}
      >
        {article.title}
      </FeaturedCardTitle>
      <Box
        css={css`
          padding: 20px;
          background: ${theme.colors.orange400};
        `}
      >
        <StandardText fontSize={14} fontWeight={500} color="white" mb={10}>
          {shortWordyFormat(article.date)}
        </StandardText>
        <StandardText fontSize={14} fontWeight={500} color="white">
          {article.excerpt.slice(0, 150) + '...'}
        </StandardText>
      </Box>
    </FeaturedCardContainer>
  );
};

const CardWrapper = styled(Link)`
  display: block;
  text-decoration: none;
  cursor: pointer;
`;

const Card = React.forwardRef(({ to, ...props }, ref) => {
  return <CardWrapper to={`/news/${to}`} ref={ref} {...props} />;
});

const ArticleCard = React.forwardRef(({ article, index, ...props }, ref) => {
  const { theme } = useThemeUI();
  if (article.featured) {
    return (
      <Card
        css={css`
          ${theme.mq.tabletP} {
            grid-column: 1 / span 2;
          }
        `}
        to={article.slug.current}
        ref={ref}
        {...props}
      >
        <FeaturedCard article={article} />
      </Card>
    );
  }

  let card;

  if (article.image && !article.hideImageOnIndex) {
    card = <ImageCard article={article} />;
  } else {
    card = <ImageLessCard index={index} article={article} />;
  }

  return (
    <Card ref={ref} to={article.slug.current} {...props}>
      {card}
    </Card>
  );
});

export default ArticleCard;
