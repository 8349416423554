import React from 'react';
import formModeSwitch from '~lib/hocs/formModeSwitch';
import ViewMyDetails from '~OMS/my-cover/change-cover/MyDetailsSection/ViewMyDetails';
import EditMyDetails from '~OMS/my-cover/change-cover/MyDetailsSection/EditMyDetails';
import CenterBox from '~common/atoms/CenterBox';
import { useChangeCoverContext } from '~OMS/my-cover/change-cover/state/ChangeCoverProvider';
import { formatDate, toDate } from '~lib/dates';
import Loading from '~common/atoms/Loading';
import { formatAddress } from '~lib/filters';

const MyDetailsFormBody = formModeSwitch({
  view: {
    Component: ViewMyDetails,
    mapInitialForm: form => ({
      ...form,
      address: formatAddress(form.address),
      dob: formatDate(form.dob),
    }),
  },
  edit: {
    Component: EditMyDetails,
    mapInitialForm: form => {
      const dob = toDate(form.dob);
      return {
        ...form,
        day: dob.get('date'),
        month: dob.get('month') + 1,
        year: dob.get('year'),
      };
    },
  },
});

const MyDetailsSection = () => {
  const { state, actions } = useChangeCoverContext();

  return (
    <CenterBox
      width={{
        xs: 1,
        tabletL: 480,
      }}
    >
      {state.myDetailsLoading ? (
        <Loading />
      ) : (
        <MyDetailsFormBody
          initialForm={state.myDetails}
          width={1}
          onSave={actions.setMyDetails}
        />
      )}
    </CenterBox>
  );
};

export default MyDetailsSection;
