import React, { useContext, useCallback, useState, useEffect } from 'react';
import { withTheme } from 'emotion-theming';
import { Box } from 'rebass';
import { Link } from 'gatsby';
import { AuthContext } from 'context/sso';
import SSOFormContainer from '~OMS/styles/SSOFormContainer';
import SimpleSuccessHeader from '~common/atoms/SimpleSuccessHeader';
import SimpleErrorHeader from '~common/atoms/SimpleErrorHeader';
import SSOText from '~OMS/SSO/atoms/SSOText';
import { Button } from '~common/atoms/Button';

function VerifyEmailWrapper({ queryParams, details }) {
  const [authTriggered, setAuthTriggered] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { verifyEmail } = useContext(AuthContext);
  const [dataMissing, setDataMissing] = useState(false);

  const { token, source } = queryParams;

  const handleVerifyEmail = useCallback(
    async token => {
      try {
        await verifyEmail(token);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [verifyEmail]
  );

  useEffect(() => {
    if (!authTriggered && token && source) {
      setAuthTriggered(true);
      handleVerifyEmail(token);
    } else {
      setDataMissing(true);
    }
  }, [authTriggered, handleVerifyEmail, source, token]);

  // source = conversion;
  // source = invite;
  // source = update; - email has been updated
  // source = register
  // source = admin

  const renderSuccessContent = () => {
    if (
      source === 'conversion' ||
      source === 'admin' ||
      source === 'verifyReminder'
    ) {
      return (
        <>
          <SimpleSuccessHeader withIcon title="Thanks for verifying!" />
          <Box mt={3} mb={4}>
            <SSOText>You can now login using your details.</SSOText>
          </Box>
          <Button
            variant="orange"
            css={{ width: '100%' }}
            as={Link}
            to="/oms/sso/login"
          >
            Login now
          </Button>
        </>
      );
    }

    if (source === 'update') {
      return (
        <>
          <SimpleSuccessHeader withIcon title="Thanks for verifying!" />
          <Box mt={3} mb={4}>
            <SSOText>Your email address was changed successfully.</SSOText>
            <SSOText>You can now login using your new details.</SSOText>
          </Box>
          <Button
            variant="orange"
            css={{ width: '100%' }}
            as={Link}
            to="/oms/sso/login"
          >
            Login now
          </Button>
        </>
      );
    }

    if (source === 'register') {
      return (
        <>
          <SimpleSuccessHeader withIcon title="Thanks for verifying!" />
          <Box mt={3} mb={4}>
            <SSOText>You can now login using your new details.</SSOText>
          </Box>
          <Button
            variant="orange"
            css={{ width: '100%' }}
            as={Link}
            to="/oms/sso/login"
          >
            Login now
          </Button>
        </>
      );
    }

    if (source === 'invite' || source === 'inviteReminder') {
      return (
        <>
          <SimpleSuccessHeader withIcon title="Membership invite" />
          <Box mt={3} mb={4}>
            <SSOText>You can now login using your details.</SSOText>
          </Box>
          <Button
            variant="primary"
            css={{ width: '100%' }}
            as={Link}
            to="/oms/sso/login"
          >
            Login now
          </Button>
        </>
      );
    } else {
      return <SSOText>Nothing to show</SSOText>;
    }
  };

  const successTemplate = (
    <SSOFormContainer>{renderSuccessContent()}</SSOFormContainer>
  );

  const errorTemplate = (
    <SSOFormContainer>
      <>
        <SimpleErrorHeader withIcon title="Unable to verify" />
        <Box mt={3} mb={4}>
          <SSOText>Your link has expired.</SSOText>
        </Box>

        <Button
          variant="orange"
          css={{ width: '100%' }}
          as={Link}
          to="/oms/sso/login"
        >
          Return to Member Login
        </Button>
      </>
    </SSOFormContainer>
  );

  const loadingContent = (
    <SSOFormContainer>
      <SSOText>Verifying your email address. Please wait.</SSOText>
    </SSOFormContainer>
  );

  const noDataContent = (
    <SSOFormContainer>
      <SSOText>
        You don't have permission to view this page. Verification data is
        missing.
      </SSOText>
    </SSOFormContainer>
  );

  return (
    <>
      {loading && !authTriggered && dataMissing && noDataContent}
      {loading && loadingContent}
      {!loading && !error && successTemplate}
      {!loading && error && errorTemplate}
    </>
  );
}

export default withTheme(VerifyEmailWrapper);
