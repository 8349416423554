import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { DrawerQuery, MarkAlertAsSeenMutation } from 'queries/oms/common';
import useEventListener from '~lib/hooks/useEventListener';
import { isRunningOnClientSide, pathOr } from '~lib/util';
import { pipe, some, whereEq } from 'lodash/fp';

const getClosest = (el, selector) => {
  if (!isRunningOnClientSide()) {
    return null;
  }

  for (; el && el !== document; el = el.parentNode) {
    if (el.matches(selector)) {
      return el;
    }
  }

  return null;
};

export default () => {
  const [isVisible, setIsVisible] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const { loading, error, data } = useQuery(DrawerQuery);
  const [markAsSeenMutation] = useMutation(MarkAlertAsSeenMutation);
  const [alertLoaders, setAlertLoaders] = useState({});

  useEventListener(
    'click',
    e => {
      if (isVisible && !getClosest(e.target, '.drawer-container')) {
        setIsVisible(false);
      }
    },
    isRunningOnClientSide() ? document.querySelector('body') : null
  );

  const setAlertLoader = useCallback((id, loading) => {
    setAlertLoaders(currentConfig => ({
      ...currentConfig,
      [id]: loading,
    }));
  }, []);

  const markAlertAsSeen = useCallback(
    alertId => {
      setAlertLoader(alertId, true);
      markAsSeenMutation({
        variables: {
          isRead: true,
          id: alertId,
        },
      })
        .then(() => {
          setAlertLoader(alertId, true);
        })
        .catch(error => {
          console.error(error);
          setAlertLoader(alertId, false);
        });
    },
    [markAsSeenMutation, setAlertLoader]
  );

  const hasUnseenAlerts = useMemo(() => {
    return pipe(
      pathOr([], 'oms.alerts'),
      some(
        whereEq({
          isRead: false,
        })
      )
    )(data);
  }, [data]);

  return {
    state: {
      activeTab,
      loading,
      error,
      data,
      alertLoaders,
      isVisible,
      hasUnseenAlerts,
    },
    actions: {
      setActiveTab,
      setAlertLoader,
      markAlertAsSeen,
      setIsVisible,
    },
  };
};
