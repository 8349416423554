import React from 'react';
import { withTheme } from 'emotion-theming';
import { Flex, Box } from 'rebass';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import Icon from '~common/atoms/Icon';
import Link from '~common/atoms/Link';
import useMembership from '../hooks/useMembership';
import { P2 } from '~common/atoms/typography';

const Dropdown = styled.div`
  position: absolute;
  width: 180px;
  background: #000;
  top: 55px;
  display: none;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.4);
  background: ${props => props.colors.background.dark};
`;

const Container = styled.div`
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    ${Dropdown} {
      display: block;
    }
  }
`;

const data = [
  {
    label: 'Edit my details',
    href: '/oms/my-details',
    setExpandMember: true,
  },
  {
    label: 'Change password',
    href: '/oms/sso/changePassword',
  },
  {
    label: 'Grant family access',
    href: '/oms/my-details',
    setExpandMember: true,
    hideTypes: ['SINGLE'],
  },
  // {
  //   label: 'Unlink my Avant account',
  //   href: '/',
  // },
];

const linkStyle = props => css`
  display: block;
  background-color: transparent;
  color: ${props.theme.colors.white};
  text-decoration: none;
  border: none;
  width: 100%;
  border-bottom: 1px solid ${props.theme.colors.white};
  padding: 11px 15px;
  font-size: ${props.theme.fontSizes[1]}px;
  line-height: 1;
  text-align: left;
  -webkit-appearance: unset;
  cursor: pointer;

  &:hover {
    background: ${props.theme.colors.black};
  }
  &:last-child {
    border-bottom: none;
  }
`;

const UserAccountDropdown = ({
  theme,
  user,
  person,
  handleLogout,
  location,
}) => {
  const { membership } = useMembership();

  return (
    <Container>
      <Flex alignItems="center" css={{ height: '55px' }}>
        <Box mr={person ? '8px' : 0}>
          <Icon name="avatar" originalColorSchema width="24px" height="24px" />
        </Box>
        <P2 variant="semiBold" color={theme.colors.text2}>
          {person && person.fullName}
        </P2>
        <Box ml="6px" mt="4px">
          <Icon
            name="oms-chevron"
            fill={theme.colors.primary}
            width="12px"
            height="7.2px"
          />
        </Box>
      </Flex>
      <Dropdown {...theme}>
        {data.map((e, i) => {
          if (e.hideTypes && e.hideTypes.includes(membership.type)) {
            return null;
          }

          return (
            <Link
              to={e.href}
              key={i}
              css={linkStyle({ theme })}
              state={{
                prevPath: location ? location.pathname : '/oms',
                membershipId: person.id,
                expandMember: e.setExpandMember
                  ? person
                    ? person.id
                    : null
                  : null,
              }}
            >
              {e.label}
            </Link>
          );
        })}
        <button
          data-id="logout"
          onClick={handleLogout}
          css={linkStyle({ theme })}
        >
          Logout
        </button>
      </Dropdown>
    </Container>
  );
};

export default withTheme(UserAccountDropdown);
