import React from 'react';
import moment from 'moment';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import css from '@emotion/css';
import { Flex, Box } from 'rebass';

import Timeline from './Timeline';
import Loading from '~common/atoms/Loading';
import Link from '~common/atoms/Link';

import CenterBox from '../atoms/CenterBox';
import { Button } from '../atoms/Button';
import { StandardText } from '../atoms/typography';
import { path } from 'lodash/fp';
import LightBox from '../atoms/LightBox';
import { useMobileScreen } from '~lib/responsive';
import MarkSeen from './MarkSeen';
import SanityRichTextContent from './SanityRichTextContent';

const NotificationsPopover = styled.div`
  position: absolute;
  max-width: 100vw;
  width: 338px;
  right: 0;
  top: 38px;
  background: #fff;
  box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.2);
  display: ${props => (props.visible ? 'flex' : 'none')};
  max-height: calc(100vh - 55px);
  flex-direction: column;

  &::before {
    position: absolute;
    content: '';
    top: -7px;
    right: 2px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9.5px 7px 9.5px;
    border-color: transparent transparent #eceded transparent;
  }
`;

const tabVerticalPadding = 10;

const StyledInner = styled.div`
  overflow: auto;
  flex: 1 1 100%;
  ${props => props.theme.mq.mdInverted} {
    height: calc(100% - ${2 * tabVerticalPadding}px - 20px);
  }
`;

const StyledTab = styled.div`
  background: #eceded;
  padding: ${tabVerticalPadding}px 20px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  border-bottom: 1px solid #dfdede;
  font-weight: ${props => (props.isActive ? '700' : '400')};
  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: #000;
    content: '';
    display: ${props => (props.isActive ? 'block' : 'none')};
  }
`;

const StyledNotification = styled(Flex)`
  border-bottom: 1px solid ${props => props.colors.borders};
  padding: 15px 20px;
  line-height: 16px;
  font-size: ${props => props.fontSizes[0]}px;
`;

const StyledActivity = styled.div`
  padding: 15px 20px;
`;

const StyledClose = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
`;

const ActionLink = styled(Link)`
  &&& {
    text-decoration: none;
    border-radius: 30px;
    font-weight: 700;
    padding: 0px 10px;
    font-size: 11px;
    text-transform: none;
  }
`;

const NotificationBody = styled(SanityRichTextContent)`
  wordbreak: break-word;
  font-size: ${props => props.theme.fontSizes[1]}px;
  &&& li,
  p {
    font-size: ${props => props.theme.fontSizes[1]}px;
  }
`;

const Notifications = ({ theme, data = [], onNotificationSeen, loaders }) => {
  return (
    <>
      {data.map((alert, i) => {
        return (
          <StyledNotification
            alignItems="center"
            justifyContent="space-between"
            key={i}
            {...theme}
          >
            <Flex width={1} flexDirection="column">
              <Flex mb={2} alignItems="center" justifyContent="space-between">
                <Box width={67 / 100}>
                  {moment(alert.date, moment.ISO_8601).format(
                    'h:mma DD/MM/YYYY'
                  )}
                </Box>
                <MarkSeen
                  alert={alert}
                  onClick={() => {
                    onNotificationSeen(alert.id);
                  }}
                  loading={loaders[alert.id]}
                />
              </Flex>
              <NotificationBody content={alert.body} width={1} />
              {alert.action.enabled ? (
                <Box mt="10px">
                  <Button
                    width="auto"
                    as={props => (
                      <ActionLink
                        target={alert.action.newTab ? '_blank' : ''}
                        external={alert.action.newTab}
                        {...props}
                      />
                    )}
                    to={alert.action.url}
                    variant="primary"
                    css={css`
                      display: inline-block;
                    `}
                  >
                    {alert.action.label}
                  </Button>
                </Box>
              ) : null}
            </Flex>
          </StyledNotification>
        );
      })}
    </>
  );
};

const OnlineActivity = ({ theme, data }) => {
  return (
    <StyledActivity>
      <Timeline theme={theme} data={data} />
    </StyledActivity>
  );
};

const MobileContainer = ({ children, ...props }) => (
  <LightBox showHeader={false} showShade={false} p={0} {...props}>
    {children}
  </LightBox>
);

const Drawer = ({
  theme,
  loading,
  data,
  error,
  isVisible,
  onClick,
  onNotificationSeen,
  notificationLoaders,
  activeTab,
  onTabChange,
}) => {
  const isMobile = useMobileScreen();

  if (error) {
    console.error(error);
    return null;
  }

  const Container = isMobile ? MobileContainer : NotificationsPopover;

  return (
    <Container
      className="drawer-container"
      onClick={e => e.stopPropagation()}
      visible={isVisible}
    >
      <Flex>
        <Box width={1 / 2}>
          <StyledTab isActive={activeTab === 0} onClick={() => onTabChange(0)}>
            Notifications
          </StyledTab>
        </Box>
        <Box width={1 / 2}>
          <StyledTab isActive={activeTab === 1} onClick={() => onTabChange(1)}>
            Online Activity
          </StyledTab>
        </Box>
      </Flex>
      <StyledInner>
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          css={css`
            position: relative;
            height: 100%;
          `}
        >
          <Box
            css={css`
              position: relative;
              max-height: calc(100vh - 55px - 33px);
              height: 100%;
              overflow-y: scroll;
            `}
            pb={60}
          >
            {!path('oms.alerts.length', data) && !loading && activeTab === 0 && (
              <CenterBox py={30} px={3}>
                <StandardText textAlign="center" fontSize={2}>
                  You don't have any notifications available at the moment
                </StandardText>
              </CenterBox>
            )}
            {loading && <Loading />}
            {!loading && activeTab === 0 && path('oms.alerts.length', data) ? (
              <Notifications
                loaders={notificationLoaders}
                onNotificationSeen={onNotificationSeen}
                data={data.oms.alerts}
                theme={theme}
              />
            ) : null}
            {!loading && activeTab === 1 ? (
              <OnlineActivity data={data.oms.activities} theme={theme} />
            ) : null}
          </Box>

          <StyledClose>
            <Flex justifyContent="center" pb="10px" px="15px">
              <Button
                variant="primaryInverted"
                size="small"
                width={{
                  xs: 1,
                  md: 240,
                }}
                onClick={() => onClick(false)}
              >
                Close
              </Button>
            </Flex>
          </StyledClose>
        </Flex>
      </StyledInner>
    </Container>
  );
};

export default withTheme(Drawer);
