import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Select from '../shared/components/Select';

const COVER_TYPES = [
  {
    label: 'Hospital & Extras',
    value: 'both',
  },
  {
    label: 'Hospital only',
    value: 'hospital',
  },
  process.env.BRAND_NAME !== 'RAMSAY' && {
    label: 'Extras only',
    value: 'extras',
  },
];

const PAYMENT_FREQUENCIES = [
  {
    label: 'Monthly',
    value: 'MONTHLY',
  },
  {
    label: 'Quarterly',
    value: 'QUARTERLY',
  },
  {
    label: 'Half Yearly',
    value: 'HALF_YEARLY',
  },
  {
    label: 'Yearly',
    value: 'YEARLY',
  },
];

const StyledSelectGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 32px;
  margin: -10px 0;

  @media (min-width: 768px) {
    margin: -30px 0;
  }

  > * {
    flex: 0 1 100%;
    margin: 10px 0;

    @media (min-width: 375px) and (max-width: 767.98px) {
      flex: 0 1 calc(50% - 10px);

      &:first-of-type {
        flex: 0 1 100%;
      }
    }

    @media (min-width: 768px) {
      margin: 30px 0;
      flex: 0 1 calc(33.33% - 40px);
    }
  }
`;

const Selects = ({
  loading,
  onCoverTypeChange,
  onExcessChange,
  onFrequencyChange,
  paymentFrequency,
  productType,
  selections,
  status,
}) => {
  // Revise
  const variants = selections?.hospital?.product
    ? selections.hospital.product.variants.filter(v => {
        if (!v.onSale) {
          return false;
        }
        if (v.classes && v.classes.length) {
          return v.classes.includes(status);
        }

        return true;
      })
    : [];

  // Revise
  const excessVariants = {
    display: variants.length > 0 && variants.some(v => v.onSale && v.excess),
    values: [],
  };

  // Revise
  if (excessVariants.display) {
    excessVariants.values = variants
      .sort((a, b) => {
        if (a.excess < b.excess) {
          return -1;
        }
        if (a.excess > b.excess) {
          return 1;
        }
        return 0;
      })
      .map(v => {
        return {
          label: v.excess ? `$${v.excess}` : '$0',
          value: v.code,
        };
      });
  }

  return (
    <StyledSelectGroup>
      <Select
        id="cover"
        label="Cover types"
        name="cover"
        onChange={onCoverTypeChange}
        options={COVER_TYPES}
        value={productType}
      />
      <Select
        disabledText={loading ? 'Loading...' : 'Not applicable'}
        id="excess"
        isDisabled={
          !excessVariants.values.length || loading || productType === 'extras'
        }
        label="Excess"
        name="excess"
        onChange={onExcessChange}
        options={excessVariants.values}
        value={selections.hospital?.variant?.code}
      />
      <Select
        id="frequency"
        label="Paying"
        name="frequency"
        onChange={onFrequencyChange}
        options={PAYMENT_FREQUENCIES}
        value={paymentFrequency}
      />
    </StyledSelectGroup>
  );
};

Selects.propTypes = {
  loading: PropTypes.bool.isRequired,
  onCoverTypeChange: PropTypes.func.isRequired,
  onExcessChange: PropTypes.func.isRequired,
  onFrequencyChange: PropTypes.func.isRequired,
  paymentFrequency: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  selections: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
};

export default Selects;
