import React from 'react';
import PaymentFormWrapper from '~OMS/payments/atoms/PaymentFormWrapper';
import { TextResponsive } from '../../../../../common/atoms/typography';
import Emphasize from '~OMS/payments/atoms/Emphasize';
import useOneOffPayment from '~OMS/payments/hooks/useOneOffPayment';
import PaymentSuccess from '~OMS/payments/molecules/PaymentSuccess';
import OneOffPaymentForm from '~OMS/payments/molecules/OneOffPaymentForm';
import { currency } from '~lib/filters';
import { wordyFormat } from '~lib/dates';
import CenterBox from '~common/atoms/CenterBox';
import Loading from '~common/atoms/Loading';
import { useMobileScreen } from '~lib/responsive';
import { isPaymentInArrears } from '~OMS/response-selectors';
import PolicyPaidTo from '~common/molecules/PolicyPaidTo';
import { useThemeUI } from 'theme-ui';

const PolicyPaidToCustom = ({ payments, theme }) => {
  return (
    <PolicyPaidTo
      payments={payments}
      dateProps={{ bold: true, color: theme.colors.primary }}
    />
  );
};

const PaymentTitle = ({ basicPaymentDetails }) => {
  const isMobile = useMobileScreen();
  const { theme } = useThemeUI();

  return isMobile ? (
    <>
      <TextResponsive color="light" mb={1}>
        <PolicyPaidToCustom theme={theme} payments={basicPaymentDetails} />{' '}
      </TextResponsive>
      <TextResponsive color="light" mb={3}>
        Your standard payment is{' '}
        <Emphasize>{currency(basicPaymentDetails.amount)}</Emphasize>{' '}
      </TextResponsive>
    </>
  ) : (
    <>
      <TextResponsive color="light" mb={4}>
        <PolicyPaidToCustom theme={theme} payments={basicPaymentDetails} />{' '}
        <span className="hide-mobile">|</span> Your standard payment is{' '}
        <Emphasize>{currency(basicPaymentDetails.amount)}</Emphasize>{' '}
      </TextResponsive>
    </>
  );
};

const ProcessPaymentForm = ({ paymentResponse }) => {
  const { state, actions, paymentChecks } = useOneOffPayment(paymentResponse);

  const {
    isFormShown,
    loading,
    paymentAmount,
    success,
    error,
    paymentDetailsLoading,
    basicPaymentDetails,
  } = state;
  const { toggleForm, submitPayment } = actions;
  const arrears = isPaymentInArrears({
    oms: {
      payments: basicPaymentDetails,
    },
  });

  return (
    <>
      {paymentDetailsLoading ? (
        <CenterBox>
          <Loading />
        </CenterBox>
      ) : (
        <>
          {arrears ? (
            <TextResponsive color="light" mb={4}>
              Your policy is in arrears. Please pay the amount of{' '}
              <Emphasize>{currency(basicPaymentDetails.amount)}</Emphasize>{' '}
              which was due on{' '}
              <Emphasize>
                {wordyFormat(basicPaymentDetails.paidToDate)}
              </Emphasize>
              .
            </TextResponsive>
          ) : (
            <PaymentTitle basicPaymentDetails={basicPaymentDetails} />
          )}
        </>
      )}
      <PaymentFormWrapper boxed>
        {success ? (
          <PaymentSuccess amount={paymentAmount} />
        ) : (
          <OneOffPaymentForm
            paymentChecks={paymentChecks}
            coveredUntil={basicPaymentDetails.paidToDate}
            error={error}
            loading={loading}
            onSubmit={submitPayment}
            isFormShown={isFormShown}
            toggleForm={toggleForm}
          />
        )}
      </PaymentFormWrapper>
    </>
  );
};

export default ProcessPaymentForm;
