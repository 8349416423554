import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import gql from 'graphql-tag.macro';
import { Mutation } from '@apollo/client/react/components';
import { Flex, Box, Text, Heading } from 'rebass';
import { scroller } from 'react-scroll';
import useModalState from '~lib/hooks/useModalState';
import Block from '~common/atoms/Block';
import Icon from '~common/atoms/Icon';
import AccessGap from './AccessGap';
import Navbar from './Navbar';
import QuoteEmail from './EmailModal';
import CoverProducts from '~lib/CoverProducts/CoverProducts';
import {
  JoinNowButton,
  MobileTabSwitcher,
  ProductBrowser,
  useCoverProducts,
} from '~lib/CoverProducts/index';
import { get } from 'lodash';
import QuotePopupMessage from './QuotePopupMessage';
import usePopupMessage from './usePopupMessage';
import { Footer } from '../../PageBlocks';
import { isRunningOnClientSide } from '~lib/util';
import { navigate } from 'gatsby';
import { Button } from '~common/atoms/Button';
import Loading from '~common/atoms/Loading';
import { useThemeUI } from 'theme-ui';
import QuoteOverview from '~lib/CoverProducts/QuoteOverview';
import CallUsWithIcon from '~common/molecules/CallUsWithIcon';
import WorkingTime from '~common/molecules/WorkingTime';

const EMAIL_QUOTE_MUTATION = gql`
  mutation EmailQuote($input: EmailQuoteInput!) {
    emailQuote(input: $input) {
      success
    }
  }
`;

const ProductTypeSelect = styled.select`
  display: block;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: ${props => props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors.borders};
  color: ${props => props.theme.colors.dark};
  text-align: center;
  text-align-last: center;
  font-size: 24px;
  font-weight: 700;
  height: 50px;
  padding: 0 60px 0 20px;
  -webkit-padding-start: 20px;
  -webkit-padding-end: 60px;
  background-image: url('/images/chevron-down-black.svg');
  background-position: 96% 55%;
  background-repeat: no-repeat;
  background-size: 24px;
  cursor: pointer;

  option {
    position: relative;
  }

  &::-ms-expand {
    display: none;
  }
`;

const container = css`
  height: calc(100vh - 62px);
  @media screen and (max-width: 992px) {
    height: calc(100vh - 90px);
  }
`;

const QuoteResults = ({ onChange, footer, ...props }) => {
  const {
    products,
    quote,
    selections,
    productType,
    setProductType,
    activeTab,
    setActiveTab,
    hospitalInclisionsOpen,
    toggleHospitalInclusions,
    extrasInclusionsOpen,
    toggleExtrasInclusions,
    selectedProduct,
    isExtrasVisible,
    isHospitalVisible,
    prices,
  } = useCoverProducts(props.form, {
    onProductChange: (type, code) => {
      onChange(`${type}Product`, code);
    },
    onProductTypeChange: (type, rateType) => {
      onChange('rateType', rateType);
      if (type === 'extras') {
        onChange(`hospitalProduct`, undefined);
      } else if (type === 'hospital') {
        onChange('extrasProduct', undefined);
      }
    },
  });

  const { modalState, openModal, closeModal } = useModalState(false);
  const [formVisible, setFormVisible] = useState(true);
  const [mutationError, setMutationError] = useState(false);
  const [isHide] = useState(false);
  const popupMessage = usePopupMessage(quote, props.form);
  const [submittingQuote, setSubmittingQuote] = useState(false);
  const { theme } = useThemeUI();

  const handleApply = async () => {
    props.setStoredForm({
      ...props.form,
      hospitalProduct: selectedProduct.hospital || null,
      extrasProduct: selectedProduct.extras || null,
    });
    setSubmittingQuote(true);

    if (typeof window !== 'undefined') {
      navigate('/application-form');
    }
  };

  const handleOnDetailsClick = () => {
    toggleExtrasInclusions(true);
    toggleHospitalInclusions(true);
    scroller.scrollTo('inclusions', {
      duration: 1000,
      delay: 0,
      isDynamic: true,
      offset: -70,
      smooth: 'easeInOutQuint',
    });
  };

  const renderContent = () => {
    if (quote.error) {
      return `Error: ${quote.error.message}`;
    }

    if (quote.loading) {
      return (
        <Block
          snug={true}
          bg="white"
          w={1}
          pt={{ xs: '62px', md: '90px' }}
          css={container}
          innerProps={{
            width: '970px',
            style: { height: '100%' },
          }}
        >
          <Loading size="90px" />
        </Block>
      );
    }

    return (
      <Mutation
        mutation={EMAIL_QUOTE_MUTATION}
        onCompleted={() => {
          setFormVisible(false);
          setMutationError(false);
        }}
        onError={() => {
          setFormVisible(false);
          setMutationError(true);
        }}
      >
        {(emailQuote, { data }) => (
          <>
            <Block
              id="quote-results"
              snug={true}
              bg="white"
              pt={{
                xs: props.form.efc && props.form.efc.value ? '93px' : '65px',
                md: props.form.efc && props.form.efc.value ? '82px' : '62px',
              }}
              mb={0}
              innerProps={{
                mt: { xs: '50px', md: '80px' },
                mb: { xs: '20px', md: '40px' },
              }}
            >
              <QuoteOverview
                freeProductsEnabled={false}
                onShowEmail={openModal}
                submittingQuote={quote.loading}
                form={props.form}
                updateForm={onChange}
                setFormVisible={props.setFormVisible}
                onApply={handleApply}
                onDetails={handleOnDetailsClick}
                selections={selections}
                prices={prices}
                quote={quote}
                setSelected={onChange.bind(this, 'hospitalProduct')}
              />
            </Block>
            <Block
              snug={true}
              bg="iceBlue"
              mb={0}
              innerProps={{
                mb: { xs: '20px', md: '90px' },
                mt: { xs: '20px', md: '90px' },
              }}
            >
              <Flex
                flexDirection={{ xs: 'column', md: 'row' }}
                justifyContent="center"
                alignItems="center"
                mb={{ xs: 1, md: 2 }}
              >
                <Heading
                  pr={3}
                  fontSize={4}
                  fontWeight="normal"
                  color="text.dark"
                  mb={{ xs: '20px', md: 0 }}
                >
                  Browse
                </Heading>
                <ProductTypeSelect
                  data-id="product-type"
                  id="productType"
                  name="productType"
                  defaultValue={productType}
                  onChange={evt => {
                    setProductType(evt.target.value);
                  }}
                >
                  <option value="both">hospital + extras</option>
                  <option value="hospital">hospital only</option>
                  {process.env.BRAND_NAME !== 'RAMSAY' && (
                    <option value="extras">extras only</option>
                  )}
                </ProductTypeSelect>
                <Heading
                  pl={3}
                  fontSize={4}
                  fontWeight="normal"
                  color="text.dark"
                  className="hide-mobile"
                >
                  covers
                </Heading>
              </Flex>
              {isHospitalVisible && isExtrasVisible && (
                <MobileTabSwitcher
                  activeTab={activeTab}
                  onSelect={setActiveTab}
                />
              )}
              <CoverProducts
                activeTab={activeTab}
                showExtras={isExtrasVisible}
                showHospital={isHospitalVisible}
                hospitalProductsBrowser={
                  <ProductBrowser
                    price={prices?.hospital}
                    products={products.hospital}
                    selectedProduct={selectedProduct.hospital}
                    paymentFrequency={props.form.paymentFrequency}
                    status={props.form.status.value}
                    type="hospital"
                    selected={selections?.hospital?.product}
                    selectedVariant={selections?.hospital?.variant}
                    open={hospitalInclisionsOpen}
                    onInclusionsToggle={() => {
                      toggleHospitalInclusions();
                    }}
                    onChange={onChange.bind(this, 'hospitalProduct')}
                  />
                }
                extrasProductsBrowser={
                  <ProductBrowser
                    price={prices?.extras}
                    products={products.extras}
                    selectedProduct={selectedProduct.extras}
                    paymentFrequency={props.form.paymentFrequency}
                    status={props.form.status.value}
                    type="extras"
                    selected={selections?.extras?.product}
                    selectedVariant={selections?.extras?.variant}
                    open={extrasInclusionsOpen}
                    onInclusionsToggle={() => {
                      toggleExtrasInclusions();
                    }}
                    onChange={onChange.bind(null, 'extrasProduct')}
                  />
                }
              ></CoverProducts>
            </Block>
            <AccessGap />
            <Block
              snug={true}
              bg="white"
              pt={{ xs: '40px', md: '86px' }}
              pb={{ xs: '50px', md: '65px' }}
              px={{ xs: '10px', md: '20px' }}
            >
              <Heading
                fontSize={5}
                fontWeight="bold"
                lineHeight="1.44"
                color="text.dark"
                textAlign="center"
                mb={{ xs: '20px', md: 0 }}
              >
                You're minutes away from joining
              </Heading>
              <Flex
                flexDirection={{ xs: 'column', md: 'row' }}
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  width={{ xs: '100%', md: '140px' }}
                  my={{ xs: '30px', md: '60px' }}
                  mx={{ md: 4 }}
                >
                  <Icon
                    name="minutes"
                    width="54px"
                    height="58px"
                    fill={theme.colors.light}
                    style={{ margin: '0 auto' }}
                  />
                  <Text
                    fontSize={2}
                    color="text.light"
                    textAlign="center"
                    lineHeight="1.31"
                    mt="10px"
                  >
                    5 Minutes of your time
                  </Text>
                </Box>
                <Box
                  width={{ xs: '100%', md: '140px' }}
                  my={{ xs: '30px', md: '60px' }}
                  mx={{ md: 4 }}
                >
                  <Icon
                    name="medicare"
                    width="78px"
                    height="52px"
                    fill={theme.colors.light}
                    style={{ margin: '0 auto' }}
                  />
                  <Text
                    fontSize={2}
                    color="text.light"
                    textAlign="center"
                    lineHeight="1.31"
                    mt="10px"
                  >
                    Your valid medicare card
                  </Text>
                </Box>
                <Box
                  width={{ xs: '100%', md: '140px' }}
                  my={{ xs: '30px', md: '60px' }}
                  mx={{ md: 4 }}
                >
                  <Icon
                    name="credit-card"
                    width="54px"
                    height="54px"
                    fill={theme.colors.light}
                    style={{ margin: '0 auto' }}
                  />
                  <Text
                    fontSize={2}
                    color="text.light"
                    textAlign="center"
                    lineHeight="1.31"
                    mt="10px"
                  >
                    Credit card or bank details
                  </Text>
                </Box>
              </Flex>
              <Flex
                flexDirection={{ xs: 'column', md: 'row' }}
                justifyContent="center"
                alignItems="center"
                mb="60px"
              >
                <Button
                  data-id="email-quote-bottom"
                  variant={theme.emailQuoteButtonStyle}
                  onClick={openModal}
                  mr={{ md: '15px' }}
                  mb={{ xs: '15px', md: 0 }}
                  width={{ xs: '100%', md: 'auto' }}
                  className="button-large"
                >
                  Email quote
                </Button>

                <JoinNowButton
                  loading={submittingQuote}
                  onClick={handleApply}
                />
              </Flex>
              <Flex
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <CallUsWithIcon
                  mr={2}
                  textProps={{
                    color: 'secondary2',
                  }}
                />
                <WorkingTime fontSize={18} className="hide-mobile" />
              </Flex>

              <WorkingTime
                className="show-mobile-block"
                fontSize={3}
                color="light"
                textAlign="center"
                mt={3}
              />
            </Block>
            <Footer data={footer} />

            <QuoteEmail
              isOpen={modalState}
              onClose={() => {
                closeModal();
                setFormVisible(true);
                setMutationError(false);
              }}
              formVisible={formVisible}
              mutationError={mutationError}
              setMutationError={setMutationError}
              onSubmit={formInput => {
                const quoteInput = {
                  state: props.form.region.value,
                  status: props.form.status.value,
                  dob: props.form.yourDob.value,
                  age: props.form.yourDob.age,
                  qualifiesForDiscount: props.form.dob.age < 30,
                  paymentFrequency: props.form.paymentFrequency,
                  resultType: props.form.resultType,
                  tier: parseInt(props.form.tier.value || '0', 10),
                  efc: get(props.form, 'efc.value'),
                };

                const productsInput = {};

                if (
                  selections?.hospital?.variant?.code &&
                  selections?.hospital?.product?.id &&
                  selections?.hospital?.product?.title
                ) {
                  quoteInput.hospitalProduct = selections.hospital.variant.code;
                  productsInput.hospital = {
                    title: selections.hospital.product.title,
                    coverage: selections.hospital.product.coverage || '',
                    code: selections.hospital.variant.code,
                    excess: selections.hospital.variant.excess || 0,
                    link: `/${selections.hospital.product.id}`,
                  };
                }

                if (
                  selections?.extras?.variant?.code &&
                  selections?.extras?.product?.id &&
                  selections?.extras?.product?.title
                ) {
                  quoteInput.extrasProduct = selections.extras.variant.code;
                  productsInput.extras = {
                    title: selections.extras.product.title,
                    coverage: selections.extras.product.coverage || '',
                    code: selections.extras.variant.code,
                    excess: selections.extras.variant.excess || 0,
                    link: `/${selections.extras.product.id}`,
                  };
                }

                return emailQuote({
                  variables: {
                    input: {
                      form: formInput,
                      quote: quoteInput,
                      products: productsInput,
                    },
                  },
                });
              }}
            />
          </>
        )}
      </Mutation>
    );
  };

  useEffect(() => {
    if (isRunningOnClientSide()) {
      window.scrollTo({ top: 0, left: 0 });
    }
  }, []);

  return (
    <>
      {
        <QuotePopupMessage
          quoteForm={props.form}
          visible={popupMessage.state.visible}
          onClose={popupMessage.actions.close}
        />
      }
      {!isHide && (
        <Navbar
          form={props.form}
          setFormVisible={props.setFormVisible}
          updateForm={onChange}
          setForm={props.setForm}
        />
      )}
      {renderContent()}
    </>
  );
};

export default QuoteResults;
