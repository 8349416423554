import React from 'react';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import Icon from '~common/atoms/Icon';
import { useThemeUI } from 'theme-ui';

const getRandomNumber = (min, max) => Math.random() * (max - min) + min;

const heartBeat = keyframes`
  to { transform: scale(${getRandomNumber(1.2, 1.3).toFixed(2)}); }
`;

const AnimatedIcon = props => {
  const { theme } = useThemeUI();

  return (
    <Icon fill={props.color || theme.colors.secondary} name="dhf" {...props} />
  );
};

export default styled(AnimatedIcon)`
  animation: ${heartBeat} 500ms infinite alternate;
`;
