import React from 'react';
import { Text } from 'rebass';
import styled from '@emotion/styled';

import PasswordRequirement from '../atoms/PasswordRequirement';

const PWStrengthWrapper = styled.div`
  position: absolute;
  top: 70px;
  z-index: 10;
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(29, 75, 141, 0.4);
  padding: 10px;

  &:after {
    bottom: 100%;
    left: 10%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;

    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 4px;
    margin-left: -4px;
  }

  &:before {
    bottom: 100%;
    left: 10%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;

    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: rgba(29, 75, 141, 0.4);
    border-width: 5px;
    margin-left: -5px;
  }
`;
// test cases
const hasNumber = pw => /\d/.test(pw);
const hasLength = pw => pw.length >= 8;
const hasUppercaseLetter = pw => /[A-Z]/.test(pw);
const hasLowercaseLetter = pw => /[a-z]/.test(pw);
// eslint-disable-next-line no-useless-escape
const hasSpecialChar = pw => /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(pw);

const PasswordStrengthIndicator = ({ theme, password }) => {
  return (
    <PWStrengthWrapper>
      <Text fontWeight="bold" fontSize={1} mb={2}>
        Password must:
      </Text>

      <PasswordRequirement
        valid={hasLength(password)}
        rule="be at least 8 characters."
      />

      <PasswordRequirement
        valid={hasNumber(password)}
        rule="contain at least one digit (0-9)"
      />

      <PasswordRequirement
        valid={hasSpecialChar(password)}
        rule="contain at least one special character (e.g. !@#$%)"
      />

      <PasswordRequirement
        valid={hasUppercaseLetter(password)}
        rule="contain at least one uppercase letter (A-Z)"
      />

      <PasswordRequirement
        valid={hasLowercaseLetter(password)}
        rule="contain at least one lowercase letter (a-z)"
      />
    </PWStrengthWrapper>
  );
};

export default PasswordStrengthIndicator;
