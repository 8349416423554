import React from 'react';
import PageBlocksRenderer from 'components/PageBlocksRenderer';
import { PageProvider } from '~lib/providers/PageProvider';
import CmsPage from '../components/Elements/CmsPage';

const Page = ({ pageContext }) => {
  return (
    <CmsPage pageContext={pageContext}>
      <PageProvider page={pageContext}>
        <PageBlocksRenderer container={pageContext} />
      </PageProvider>
    </CmsPage>
  );
};

export default Page;
