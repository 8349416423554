import {
  CHANGE_CREDIT_CARD_STATUS,
  CHANGE_DETAILS_ACTION,
  MAX_NUMBER_OF_CREDIT_CARD_RETRIES,
  NON_EXISTING_BSB_MESSAGE,
  PAYMENT_TYPE,
} from '~OMS/payments/constants';
import {
  isCreditCardChangeApproved,
  isNonExistingBsb,
} from '~OMS/payments/paymentUtils';
import { useApolloClient } from '@apollo/client';
import { PaymentMethodFingerprintQuery } from 'queries/oms/payments.graphql';
import { useMemo, useState } from 'react';
import getChangeDetailsPayload from '~OMS/payments/hooks/useChangePaymentDetails/payloader';
import { path } from 'lodash/fp';
import useUpdatePaymentDetailsMutation from '~OMS/payments/hooks/useUpdatePaymentDetailsMutation';
import { postFormDataForm } from '~lib/util';
import sentry from '~lib/sentry';

export default () => {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [unexpectedError, setUnexpectedError] = useState();
  const [response, setResponse] = useState();
  const [updatePayments] = useUpdatePaymentDetailsMutation();

  const reset = () => {
    setUnexpectedError(undefined);
    setResponse(undefined);
    setLoading(false);
  };

  const submitPaymentsChange = async payload => {
    reset();
    setLoading(true);
    try {
      const { data } = await updatePayments({
        variables: {
          input: payload,
        },
      });

      setResponse(data.updatePayments);
      setLoading(false);
      return data.updatePayments;
    } catch (error) {
      console.error(error);
      setUnexpectedError(error);
      setLoading(false);
      return undefined;
    }
  };

  const handleChangeCreditCardRejection = async (
    form,
    response,
    retriesCount
  ) => {
    const { status, message: nabMessage } = response || {};
    console.info('Credit card change rejected. Status:', status);
    const retry = fn => {
      if (retriesCount < MAX_NUMBER_OF_CREDIT_CARD_RETRIES) {
        return fn(retriesCount + 1);
      }

      setLoading(false);
      setResponse({
        success: false,
        message: nabMessage,
      });
      return undefined;
    };

    let data;
    switch (status) {
      case CHANGE_CREDIT_CARD_STATUS.NOT_FOUND: {
        return retry(() =>
          submitCreditCardDetails(
            form,
            CHANGE_DETAILS_ACTION.ADD,
            retriesCount + 1
          )
        );
      }

      case CHANGE_CREDIT_CARD_STATUS.NOT_UNIQUE: {
        return retry(() =>
          submitCreditCardDetails(
            form,
            CHANGE_DETAILS_ACTION.EDIT,
            retriesCount + 1
          )
        );
      }

      case CHANGE_CREDIT_CARD_STATUS.FAILED_HASH: {
        data = {
          message: 'We were not able to verify the credit card change.',
          success: false,
        };
        break;
      }

      default: {
        data = {
          message: nabMessage,
          success: false,
        };
        break;
      }
    }

    setResponse(data);
    setLoading(false);
    return data;
  };

  const submitCreditCardDetails = async (
    form,
    action = CHANGE_DETAILS_ACTION.ADD,
    retriesCount = 0
  ) => {
    console.log('EXECUTING CHANGE CARD DETAILS');
    try {
      reset();
      setLoading(true);
      const { data } = await client.query({
        query: PaymentMethodFingerprintQuery,
        variables: {
          input: {
            action,
            name: form.cardHolder,
          },
        },
      });

      const fingerprintDetail = data.oms.paymentMethodFingerprint;
      const changeDetailsPayload = getChangeDetailsPayload(
        PAYMENT_TYPE.CREDIT_CARD
      ).nab(form, fingerprintDetail);

      console.log('NAB REQUEST:', changeDetailsPayload);
      const responsePayload = await postFormDataForm(
        fingerprintDetail.manageUrl,
        changeDetailsPayload
      ).catch(error => {
        sentry(error);
        throw {
          code: error.status,
          message: `Change details failed with network status: ${error.status}`,
        };
      });

      console.log('Received payload:', responsePayload);
      if (isCreditCardChangeApproved(responsePayload)) {
        const submitCreditChangePayload = getChangeDetailsPayload(
          PAYMENT_TYPE.CREDIT_CARD
        ).hambs(form);
        console.log(submitCreditChangePayload);
        return submitPaymentsChange(submitCreditChangePayload).then(data => ({
          ...data,
          fingerprintDetail,
        }));
      }

      return handleChangeCreditCardRejection(
        form,
        responsePayload,
        retriesCount
      );
    } catch (error) {
      setUnexpectedError(error);
      setLoading(false);
      return undefined;
    }
  };

  const submitBankDetails = form => {
    const payload = getChangeDetailsPayload(form.type)(form);
    return submitPaymentsChange(payload);
  };

  const submitDebitDetailsChange = (form, action) => {
    if (form.type === PAYMENT_TYPE.BANK_ACCOUNT) {
      return submitBankDetails(form);
    }

    return submitCreditCardDetails(form, action);
  };

  const submitInvoiceDetailsChange = form => {
    const payload = getChangeDetailsPayload(PAYMENT_TYPE.INVOICE)(form);
    return submitPaymentsChange(payload);
  };

  const success = useMemo(() => {
    return !loading && !unexpectedError && path('success')(response);
  }, [loading, response, unexpectedError]);

  const nonExistingBsb = isNonExistingBsb(unexpectedError);

  const alert = useMemo(() => {
    if (unexpectedError) {
      return {
        type: 'error',
        message: nonExistingBsb
          ? NON_EXISTING_BSB_MESSAGE
          : unexpectedError.message || unexpectedError,
      };
    }
    if (!response) {
      return undefined;
    }
    if (success) {
      return {
        type: 'success',
        message: 'Your payment details have been successfully updated.',
      };
    }

    return {
      type: 'error',
      message: `Change details failed: ${response.message}`,
    };
  }, [nonExistingBsb, response, success, unexpectedError]);

  return {
    state: {
      nonExistingBsb,
      response,
      loading,
      success,
      alert,
    },
    actions: {
      reset,
      submitInvoiceDetailsChange,
      submitDebitDetailsChange,
    },
  };
};
