import React, { useRef, useState, useEffect } from 'react';
import { withTheme } from 'emotion-theming';
import moment from 'moment';
import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';

import { isRunningOnClientSide } from '~lib/util';
import UsageGraph from '../molecules/UsageGraph';
import { P2, Subheading } from '~common/atoms/typography';
import Icon from '~common/atoms/Icon';
import { useMobileScreenOnly } from '~lib/responsive';
import css from '@emotion/css';

const TabInner = styled.div`
  overflow: hidden;
  padding: 0 20px;
  border-bottom: 1px solid ${props => props.colors.borders};
  ${props => props.mq.sm} {
    padding: 0 0 25px 0;
    border-width: 2px;
  }
`;

const TabContent = styled.div`
  overflow: hidden;
  max-height: ${props => props.maxHeight};
  transition: max-height 0.3s ease-in-out;
  ${props => props.mq.sm} {
    max-height: none;
  }
`;

const Tab = styled.div`
  ${props => props.mq.sm} {
    margin: 0;
    width: 100%;
  }
  &::before {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    content: '';
    ${props => props.mq.sm} {
      display: none;
    }
  }
  &:first-of-type {
    border-top: 1px solid ${props => props.colors.borders};
    ${props => props.mq.sm} {
      border-width: 2px;
    }
  }
  &:last-of-type {
    ${TabInner} {
      border-bottom: none;
    }
  }
`;

const AccordionIcon = styled(Icon)`
  position: absolute;
  right: 10px;
  top: 50%;
  transition: all 300ms ease-in-out;
  transform: rotate(${props => (props.isActive ? '180deg' : '0deg')})
    translateY(${props => (props.isActive ? '50%' : '-50%')});
`;

const UsageAccordion = ({ theme, title, benefits, extrasProductId }) => {
  const content = useRef(null);
  const [height, setHeight] = useState();
  const [isActive, setActive] = useState(false);

  const getHeight = () =>
    content.current ? `${content.current.offsetHeight}px` : '0px';
  const toggleActive = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    function handleResize() {
      setHeight(getHeight());
    }
    if (isRunningOnClientSide()) {
      window.addEventListener('resize', handleResize);
      setHeight(getHeight());
    }
    return () => {
      if (isRunningOnClientSide()) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  const isMobile = useMobileScreenOnly();

  const benefitsWithEndDates = benefits && benefits.filter(b => !!b.endDate);

  return (
    <Tab {...theme}>
      <Flex
        my="20px"
        justifyContent="space-between"
        flexWrap="wrap"
        onClick={toggleActive}
        css={css`
          position: relative;
        `}
      >
        <Box
          width={{
            xs: 1,
            sm: 'auto',
          }}
        >
          <Subheading color="primary" variant="semiBold" fontSize={6}>
            {title}
          </Subheading>
        </Box>
        <Box
          width={{
            xs: 1,
            sm: 'auto',
          }}
        >
          {benefitsWithEndDates &&
          benefitsWithEndDates.length &&
          benefitsWithEndDates[0].endDate ? (
            <P2 color="neutral500" variant="medium">
              Limit Reset Date{' '}
              {moment(benefitsWithEndDates[0].endDate).format('DD/MM/YYYY')}
            </P2>
          ) : null}
        </Box>
        {isMobile && (
          <AccordionIcon
            height="16px"
            width="16px"
            name="chevron-down"
            isActive={isActive}
            fill={theme.colors.primary}
          />
        )}
      </Flex>
      <TabContent {...theme} maxHeight={isActive ? height : 0}>
        <TabInner ref={content} {...theme}>
          {benefits &&
            benefits.map((benefit, i) => (
              <UsageGraph
                mb="20px"
                extrasProductId={extrasProductId}
                key={i}
                {...benefit}
              />
            ))}
        </TabInner>
      </TabContent>
    </Tab>
  );
};

export default withTheme(UsageAccordion);
