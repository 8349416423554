import React, { useEffect, useMemo, useState } from 'react';
import { FORM_MODE } from '~lib/constants';

const getInitialForm = (mapper, initialForm) => {
  return typeof mapper === 'function' ? mapper(initialForm) : initialForm;
};

export default ({ view, edit }) =>
  ({ initialForm, mode = '', ...props }) => {
    const [innerMode, setMode] = useState('');

    useEffect(() => {
      setMode(mode);
    }, [mode]);

    const { Component, formData } = useMemo(() => {
      const { Component, mapInitialForm } =
        innerMode === FORM_MODE.EDIT ? edit : view;

      return {
        Component,
        formData: getInitialForm(mapInitialForm, initialForm),
      };
    }, [initialForm, innerMode]);

    return <Component initialForm={formData} setMode={setMode} {...props} />;
  };
