import React from 'react';
import { Flex } from 'rebass';
import { StandardText } from '../../../../common/atoms/typography';
import { Button } from '~common/atoms/Button';
import UpdateAddressForm from '~common/organisms/UpdateAddressForm';
import { useUpdateAddress, FORM_MODES } from '~common/hooks/useUpdateAddress';
import styled from '@emotion/styled';
import Loading from '~common/atoms/Loading';
import { formatAddress } from '~lib/filters';

const StyledUpdateAddressForm = styled(UpdateAddressForm)`
  border: none;
  padding: 0;
`;

const Address = () => {
  const {
    mode,
    updateAddressWithStateChangeModal,
    toggleUpdateMode,
    hide,
    display,
    isChangeAddressDisplayed,
    current,
    updating,
  } = useUpdateAddress({
    initialMode: FORM_MODES.AUTO,
  });

  if (current.loading) {
    return (
      <Flex width="100%" justifyContent="center">
        <Loading />
      </Flex>
    );
  }

  return !isChangeAddressDisplayed ? (
    <Flex className="full-width" flexDirection="column">
      <StandardText mb={3} fontWeight={600}>
        Residential address:
      </StandardText>
      <Flex
        justifyContent="space-between"
        alignItems={{
          xs: 'flex-start',
          md: 'center',
        }}
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
      >
        <StandardText
          mb={{
            xs: 3,
            md: 0,
          }}
        >
          {formatAddress(current.membershipAddress)}
        </StandardText>
      </Flex>

      {current.postalAddress &&
        current.postalAddressLine !== current.membershipAddressLine && (
          <>
            <StandardText mb={3} mt={4} fontWeight={600}>
              Postal:
            </StandardText>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              flexDirection={{
                xs: 'column',
                md: 'row',
              }}
            >
              <StandardText
                mb={{
                  xs: 3,
                  md: 0,
                }}
              >
                {current.postalAddressLine}
              </StandardText>
            </Flex>
          </>
        )}

      {!current.postalAddress && (
        <>
          <StandardText mb={3} mt={4} fontWeight={600}>
            Postal:
          </StandardText>
          <StandardText fontSize={1}>
            You don't have postal address set. In order to set it up please use
            the button below.
          </StandardText>
        </>
      )}

      <Button
        css={{ marginTop: '32px' }}
        variant="secondaryInverted"
        onClick={display}
        width={{
          xs: 1,
          md: 154,
        }}
      >
        Change
      </Button>
    </Flex>
  ) : (
    <>
      <StyledUpdateAddressForm
        loading={updating}
        showTitle={false}
        mode={mode}
        onSubmit={updateAddressWithStateChangeModal}
        onCancel={hide}
        postalAddressLine={current.postalAddressLine}
        postalAddress={current.postalAddress}
        address={current.membershipAddress}
        addressLine={current.membershipAddressLine}
        onToggleMode={toggleUpdateMode}
        isPostalDifferentAsResidential={
          current.postalAddressLine !== current.membershipAddressLine
        }
      />
    </>
  );
};

export default Address;
