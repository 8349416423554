import { Flex } from 'rebass';
import { StandardText } from '../../atoms/typography';
import React from 'react';
import { CreateControl } from './Control';
import styled from '@emotion/styled';
import { StyledInput } from '../../atoms/Input';

const ReferenceInput = styled(StyledInput)`
  width: calc(100% / 6);
`;

const ReferenceNumber = props => (
  <Flex flexDirection="column">
    <StandardText mb={2} fontSize={1}>
      Tip: to the left of your name on the card
    </StandardText>
    <ReferenceInput type="number" width={1 / 6} {...props} />
  </Flex>
);

export const ReferenceNumberControl = CreateControl(ReferenceNumber);
