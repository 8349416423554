import React from 'react';
import { Flex } from 'rebass';
import { StandardText, Subheading } from '~common/atoms/typography';
import Icon from '~common/atoms/Icon';
import { FormPanel } from '../Panel';
import { useThemeUI } from 'theme-ui';
import { isEmptyObject } from '~lib/util';
import { Element } from 'react-scroll';
import { Button } from '~common/atoms/Button';

const renderHeader = ({
  expanded,
  enableRemove,
  onRemove,
  title,
  name,
  touched,
  confirmed,
  theme,
  isValid,
}) => {
  if (expanded && enableRemove) {
    return (
      <Flex width={1} justifyContent="space-between" alignItems="center">
        <Subheading variant="semiBold" color="primary">
          {title}
        </Subheading>
        <Icon
          onClick={evt => {
            evt.stopPropagation();
            onRemove(name);
          }}
          clickable
          name="thick-close"
          height="11px"
          width="11px"
          stroke={theme.colors.white}
          fill={theme.colors.white}
        />
      </Flex>
    );
  }

  if (!expanded && (!isEmptyObject(touched) || confirmed)) {
    return (
      <Flex width={1} justifyContent="space-between" alignItems="center">
        <Subheading variant="semiBold" color="primary">
          {title}
        </Subheading>
        <Flex alignItems="center">
          {enableRemove && (
            <>
              <StandardText
                onClick={evt => {
                  evt.stopPropagation();
                  onRemove(name);
                }}
                fontSize={2}
                color="blue2"
                textDecoration="underline"
                mr={2}
              >
                remove
              </StandardText>
              <StandardText color="blue2" fontSize={2} mr={2}>
                |
              </StandardText>
            </>
          )}
          <Icon
            name={isValid ? 'green-tick' : 'alert'}
            fill={isValid ? theme.colors.green : theme.colors.error}
            width="16px"
            height="16px"
          />
        </Flex>
      </Flex>
    );
  }

  return undefined;
};

const EditableFormPanel = ({
  expanded,
  onHeaderClick,
  title,
  isValid,
  touched,
  children,
  onNext,
  confirmed,
  name,
  onRemove,
  enableRemove = false,
  ...props
}) => {
  const { theme } = useThemeUI();

  return (
    <Element name={name}>
      <FormPanel
        expanded={expanded}
        onHeaderClick={() => {
          onHeaderClick(name);
        }}
        title={title}
        header={renderHeader({
          expanded,
          isValid,
          touched,
          confirmed,
          onRemove,
          enableRemove,
          theme,
          title,
        })}
        {...props}
      >
        {children}
        {onNext && !confirmed && (
          <Button
            variant="secondaryInverted"
            size="medium"
            onMouseDown={() => {
              onNext(name, isValid);
            }}
          >
            Next
          </Button>
        )}
      </FormPanel>
    </Element>
  );
};

export default EditableFormPanel;
