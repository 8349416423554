import React from 'react';
import { H3, P2 } from '~common/atoms/typography';
import { useLocale } from '~common/locales';

const ChangeCoverIntro = () => {
  const { getLocaleString } = useLocale();
  return (
    <>
      <H3 variant="semiBold" pb={20}>
        Thinking about changing or adding cover?
      </H3>
      <P2 mb={20}>
        It is important to periodically review your level of cover to ensure
        that it continues to meet the health needs of you and your family.
      </P2>
      <P2 mb={20}>
        You can explore our covers below and then use the functions to either
        email us or request a time for us to call you to discuss your cover
        needs.
      </P2>
      <P2 mb={20}>
        There are a few things you should be mindful of before you decide to
        change your cover:
      </P2>
      <P2 mb={20}>
        If you're upgrading to a higher level of cover, you will need to serve
        the relevant waiting periods for the new services that were not
        previously covered.
      </P2>
      <P2>
        If you're adding a partner onto your policy and they are new to private
        health insurance, or if their previous cover is lower than the policy
        they are switching to, then they will need to serve relevant waiting
        periods.
      </P2>
      <P2>
        If your partner has health insurance with another provider, we can
        easily organise to transfer them across to{' '}
        {getLocaleString('common.organisationName')}. It takes just 5 minutes
        over the phone and we take care of all the paperwork for you, including
        contacting their existing fund.
      </P2>
    </>
  );
};

export default ChangeCoverIntro;
