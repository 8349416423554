import React from 'react';
import Icon from '../../../common/atoms/Icon';
import useContactDetails from '~lib/hooks/sanity/useContactDetails';
import { Flex } from 'rebass';
import { useThemeUI } from 'theme-ui';
import { StandardText } from '~common/atoms/typography';

const CallUsBox = ({
  icon,
  text = {
    fontSize: 16,
    lineHeight: 1.2,
    fontWeight: 600,
    color: 'primary',
  },
  ...props
}) => {
  const { theme } = useThemeUI();
  const sanityContactDetails = useContactDetails();

  icon = icon || {
    name: 'phone',
    width: '11.5px',
    height: '16px',
    fill: theme.colors.primary,
  };

  return (
    <Flex
      justifyContent="flex-end"
      alignItems="center"
      as="a"
      href={`tel:${sanityContactDetails?.phone}`}
      style={{ textDecoration: 'none' }}
      {...props}
      cursor="pointer"
    >
      <Icon name="phone" cursor="pointer" mr={2} {...icon} />
      <StandardText color={theme.colors.primary} {...text}>
        {sanityContactDetails?.phone}
      </StandardText>
    </Flex>
  );
};

export default CallUsBox;
