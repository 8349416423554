import { graphql, useStaticQuery } from 'gatsby';

const TITLES_QUERY = graphql`
  query MyQuery {
    sanityApplicationFormMemberTitle {
      _id
      memberPartnerTitleMapping {
        _key
        id
        title
      }
      dependantTitleMapping {
        _key
        id
        title
      }
    }
  }
`;

export default type => {
  const result = useStaticQuery(TITLES_QUERY);
  const {
    memberPartnerTitleMapping: memberPartner,
    dependantTitleMapping: dependant,
  } = result.sanityApplicationFormMemberTitle;

  if (!type) {
    return {
      memberPartner,
      dependant,
    };
  }

  return type === 'memberPartner' ? memberPartner : dependant;
};
