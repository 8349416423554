export const getCardWidth = cardsCount => {
  cardsCount = Number(cardsCount);
  if (cardsCount >= 3) {
    return {
      xs: '100%',
      tabletP: 'calc(33.33% - 15px)',
    };
  }

  if (cardsCount === 2) {
    return {
      xs: '100%',
      tabletP: 'calc(50% - 15px)',
    };
  }

  return '100%';
};
