import React, { useContext, useCallback, useState } from 'react';
import { withTheme } from 'emotion-theming';
import { AuthContext } from 'context/sso';
import { navigate } from 'gatsby';
import axios from 'axios';

function SSOAuthWrapper(props) {
  const [authTriggered, setAuthTriggered] = useState(false);
  const { customAuth } = useContext(AuthContext);
  const handleCustomAuth = useCallback(
    async token => {
      try {
        const responsePayload = (
          await axios.post('/sso/decodeSSOToken', {
            token,
          })
        ).data;
        const sub = responsePayload.sub;
        await customAuth({ token, sub });
      } catch (err) {
        navigate('/oms/sso/login');
      }
    },
    [customAuth]
  );

  React.useEffect(() => {
    const { token } = props.queryParams;
    if (!authTriggered && token) {
      setAuthTriggered(true);
      handleCustomAuth(token);
    }
  }, [authTriggered, handleCustomAuth, props.queryParams]);

  return <></>;
}

export default withTheme(SSOAuthWrapper);
