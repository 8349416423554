import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';

import ResizeObserver from 'resize-observer-polyfill';

export default (element, { delay = 200 } = {}) => {
  const [width, setWidth] = useState();

  const observerRef = useRef(
    new ResizeObserver(
      debounce(changes => {
        setWidth(changes[0].target.clientWidth);
      }, delay)
    )
  );

  useEffect(() => {
    const observer = observerRef?.current;
    if (element) {
      observer.disconnect();
      observer.observe(element);
    }

    return () => {
      observer?.disconnect();
    };
  }, [element]);

  return {
    width,
  };
};
