import React, { useMemo } from 'react';
import Expandable from '~common/molecules/Expandable';
import FormTitle from '~OMS/payments/atoms/FormTitle';
import PaymentFormWrapper from '~OMS/payments/atoms/PaymentFormWrapper';
import { ButtonSwitchControl } from '~common/molecules/form-controls';
import { PAYMENT_METHODS } from '~OMS/payments/constants';
import PaymentDirectDebitForm from '~OMS/payments/molecules/PaymentDirectDebitForm';
import PaymentInvoiceForm from '~OMS/payments/molecules/PaymentInvoiceForm';
import usePaymentPolicy from '~OMS/payments/hooks/usePaymentPolicy';
import Loading from '~common/atoms/Loading';
import PaymentPayrollForm from '~OMS/payments/molecules/PaymentPayrollForm';
import S2IMembershipPaymentMessage from '~common/molecules/S2IMemebershipPaymentMessage';
import CenterBox from '~common/atoms/CenterBox';
import useWhatsCovered from '~OMS/my-cover/hooks/useWhatsCovered';

const PaymentPolicyForm = () => {
  const { state, actions } = usePaymentPolicy();
  const { actions: whatsCoveredActions } = useWhatsCovered();

  const {
    formMode,
    paymentMethod,
    alert,
    data,
    loading,
    changingDetails,
    isActivePaymentMethod,
    nonExistingBsb,
    changePaymentMethodWarningText,
    isPayroll,
    paymentMethods,
    isS2IMembership,
  } = state;
  const {
    viewDetails,
    startEditing,
    setPaymentMethod,
    changeDebitDetails,
    changeInvoiceDetails,
    setPaymentType,
  } = actions;

  const form = useMemo(() => {
    const formProps = {
      initialForm: data,
      onEditDetails: startEditing,
      onCancel: viewDetails,
      mode: formMode,
    };

    /* 
      the onSubmit functions trigger a mutation that doesn't refetch
      the user data stored in the whatsCovered hook. So after the payment
      details have been made we have to refetch the user data to get the
      correct savings amount to render.
    */
    const onChangeDebitDetails = async formData => {
      await changeDebitDetails(formData);
      await whatsCoveredActions?.refetch();
    };

    const onChangeInvoiceDetails = async formData => {
      await changeInvoiceDetails(formData);
      await whatsCoveredActions?.refetch();
    };

    return {
      [PAYMENT_METHODS.PAYROLL]: <PaymentPayrollForm {...formProps} />,
      [PAYMENT_METHODS.INVOICE]: (
        <PaymentInvoiceForm
          isPayroll={isPayroll}
          changePaymentMethodWarningText={changePaymentMethodWarningText}
          isActivePaymentMethod={isActivePaymentMethod}
          onSubmit={onChangeInvoiceDetails}
          alert={alert}
          loading={changingDetails}
          {...formProps}
        />
      ),
      [PAYMENT_METHODS.DIRECT_DEBIT]: (
        <PaymentDirectDebitForm
          changePaymentMethodWarningText={changePaymentMethodWarningText}
          onPaymentTypeChange={evt => {
            setPaymentType(evt.target.value);
          }}
          nonExistingBsb={nonExistingBsb}
          alert={alert}
          isActivePaymentMethod={isActivePaymentMethod}
          onSubmit={onChangeDebitDetails}
          loading={changingDetails}
          {...formProps}
        />
      ),
    }[paymentMethod];
  }, [
    alert,
    changeDebitDetails,
    changeInvoiceDetails,
    changePaymentMethodWarningText,
    changingDetails,
    data,
    formMode,
    isActivePaymentMethod,
    isPayroll,
    nonExistingBsb,
    paymentMethod,
    setPaymentType,
    startEditing,
    viewDetails,
    whatsCoveredActions,
  ]);

  const content = () => {
    return isS2IMembership ? (
      <S2IMembershipPaymentMessage
        style={{
          alignSelf: 'flex-start',
        }}
        color="gray"
      />
    ) : (
      <PaymentFormWrapper alignItems="flex-start" boxed={false}>
        <ButtonSwitchControl
          label="Payment method"
          labelPosition="left"
          field={{
            name: 'paymentMethod',
            value: paymentMethod,
          }}
          width={isPayroll ? 1 : 240}
          onChange={setPaymentMethod}
          buttons={paymentMethods}
        />

        {form}
      </PaymentFormWrapper>
    );
  };

  return (
    <Expandable
      divider={!isS2IMembership}
      expand={true}
      header={<FormTitle>How I pay for my policy</FormTitle>}
      body={
        loading ? (
          <CenterBox>
            <Loading />
          </CenterBox>
        ) : (
          content()
        )
      }
    />
  );
};

export default PaymentPolicyForm;
