import React from 'react';
import styled from '@emotion/styled';
import { H2 } from '~common/atoms/typography';

const StyledHeading = styled(H2)`
  color: ${props => props.theme.colors.primary};
  text-align: center;

  span {
    font-weight: bold;
  }
`;

const Heading = () => {
  return (
    <StyledHeading variant="medium">
      <span>Compare</span> your cover options
    </StyledHeading>
  );
};

export default Heading;
