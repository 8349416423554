import React from 'react';
import { withTheme } from 'emotion-theming';
import styled from '@emotion/styled';

import useMenuState from '../organisms/useMenuState';

const Bars = styled.div`
  width: 46px;
  height: 43px;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  left: 5px;
  z-index: ${props => props.zIndex.burger};
  ${props => props.mq.sm} {
    display: none;
  }
`;

const Bar = styled.div`
  width: 26px;
  left: 10px;
  height: 5px;
  border-radius: 5px;
  position: absolute;
  background: ${props => props.colors.primary};
  margin: auto;
  &:nth-of-type(1) {
    top: 10px;
  }
  &:nth-of-type(2) {
    top: 0;
    bottom: 0;
  }
  &:nth-of-type(3) {
    bottom: 10px;
  }
`;

const Burger = props => {
  const [state, setMenuActive] = useMenuState();
  const { isActive } = state;

  return (
    <Bars
      onClick={() => {
        setMenuActive(!isActive);
      }}
      {...props.theme}
    >
      <Bar {...props.theme} />
      <Bar {...props.theme} />
      <Bar {...props.theme} />
    </Bars>
  );
};

export default withTheme(Burger);
