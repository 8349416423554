import styled from '@emotion/styled';
import React from 'react';

const Column = styled.td`
  font-size: 13px;
  padding: 10px;
  ${props => props.theme.mq.tabletL} {
    padding: 15px;
  }
  word-break: break-word;
  padding-right: 22px;
  border: 1px solid ${props => props.theme.colors.neutral50};
  line-height: 1.3;
  vertical-align: middle;
  background: ${props => props.bg || props.theme.colors.white};
`;

const FirstColumnBase = styled(Column)`
  width: ${props => (props.layout === 'single' ? '100%' : '70%')};
  ${props => props.theme.mq.tabletP} {
    width: ${props => (props.layout === 'single' ? '100%' : '45%')};
  }
`;

export const FirstColumn = props => <FirstColumnBase colspan="2" {...props} />;

export const SecondColumn = styled(Column)`
  max-width: 300px;
  width: 300px;
`;

export const ThirdColumn = styled(Column)`
  color: ${props =>
    props.bg ? props.theme.colors.white : props.theme.colors.light};
  border-bottom-color: ${props =>
    props.isLast
      ? props.theme.colors.neutral50
      : props.theme.colors.neutral100};
  border-top-color: transparent;
`;
